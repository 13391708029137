<template>

    <ReactiveBase
            app="customers"
            :url="ElasticUrl"
            :credentials="Credentials[0] + ':' + Credentials[1]"

    >

    <div class="container-fluid">
        <div class="row">

            <div class="plus-col-main-messages col-12">

                <!--  Page  -->
                <div class="bg-white rounded border">

                    <!-- Page header -->
                    <div class="message-actions sticky-top plus-page-header z-index-fix">

                        <b-container fluid>
                            <b-row class="d-flex justify-content-between align-items-center">

                                <div class="ml-1">
                                    <div id="report">
                                        <h2 class="my-0 text-dark report-text">
                                            <font-awesome-icon :icon="'search'"/>
                                            {{ $t('page.search') }} ({{ $t('reports.customers') }})
                                        </h2>
                                    </div>
                                    <b-tooltip target="report" placement="bottom" triggers="hover">
                                        {{ $t('page.search_customers_desc') }}
                                    </b-tooltip>
                                </div>

                                <!-- Data Search -->
                                <div class="ml-auto pt-2 pl-1 d-flex flex-no-wrap align-items-center">
                                    <DataSearch
                                        componentId="CustomerSearch"
                                        :dataField="['customer_name','customer_surname','customer_username','customer_phone']"
                                        :placeholder="$t('ticket.key_search')"

                                    />
                                    <!-- Account & Integration filter -->
                                    <b-button v-b-toggle.search-customers-collapse class="mx-2 mb-2">
                                        <font-awesome-icon :icon="'filter'" size="lg"/>
                                    </b-button>
                                </div>
                            </b-row>
                            <b-row>
                                <b-collapse id="search-customers-collapse" class="col-12">
                                    <div class="plus-col-main-panel my-2 mb-3 col-12">
                                        <div class="d-sm-flex d-md-block">
                                            <b-row>
                                                <!-- Multi Dropdown List -->
                                                <b-col>
                                                    <div>
                                                        <!-- Multi Dropdown List -->
                                                        <MultiDropdownList  componentId="IntegrationsDropdownList"
                                                                    dataField="integration_id.keyword"
                                                                    :title="$t('page.integrations')"
                                                                    :showSearch="true"
                                                        />
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <!-- Multi List -->
                                                    <MultiDropdownList componentId="AccountsDropdownList"
                                                               dataField="account_name.keyword"
                                                               :title="$t('simple.accounts')"
                                                               :placeholder="$t('simple.select_account')"
                                                               :showSearch="true"
                                                    />
                                                
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>

                                </b-collapse>
                            </b-row>
                        </b-container>
                    </div>
                    <b-container fluid class="my-3">


                        <!-- Reactive List -->
                            <!-- <b-row align-v="center" class="justify-content-md-center">
                                <div class="w-25">
                                </div>
                            </b-row> -->
                        <reactive-list :react="{ and: ['IntegrationsDropdownList'], or: ['AccountsDropdownList', 'CustomerSearch']}"
                                       componentId="SearchResult"
                                       dataField="questions_content"
                                       :pagination="true"
                                       :pages="3"
                                       :size="5"
                                       :showResultStats="true"

                        >
                            <div slot="render" slot-scope="{ data }">
                                <result-list-wrapper>
                                    <result-list
                                            v-bind:key="result._id"
                                            v-for="result in data"
                                            :href="'/account/'+result.integration_id+'/'+result.account_id+'/questions/messages/'+result.customer_id"
                                    >
                                        <!-- Avatar Part Render -->
                                        <inbox-avatar-part :integration_id="result.integration_id"
                                                           :customer="{id: result.customer_id,avatar: result.customer_avatar, customer_type:{is_vip: result.customer_types_vip}}"></inbox-avatar-part>
                                        <result-list-content>

                                            <result-list-title v-if="result.customer_name == null && result.customer_surname == null">
                                                {{ '@' + result.customer_username }}
                                            </result-list-title>

                                            <result-list-title v-else-if="result.customer_name == null">
                                                {{ result.customer_surname + ' (@' + result.customer_username + ')' }}
                                            </result-list-title>

                                            <result-list-title v-else-if="result.customer_surname == null">
                                                {{ result.customer_name + ' (@' + result.customer_username + ')' }}
                                            </result-list-title>

                                            <result-list-title v-else>
                                                {{ result.customer_name +' '+ result.customer_surname + ' (@' + result.customer_username + ')' }}
                                            </result-list-title>

                                            <result-list-description>
                                                <div class="shadow p-3 mb-2 bg-white rounded">
                                                    <p>
                                                        <em><b>{{$t('customer.customer_type') + ': '}}</b> {{result.customer_types_name}}</em>
                                                    </p>
                                                    <p>
                                                        <b>{{$t('customer.phone') + ': '}}</b> {{result.customer_phone}}
                                                    </p>
                                                    <p>
                                                        <b>{{$t('customer.email') + ': '}}</b> {{result.customer_email}}
                                                    </p>
                                                    <p>
                                                        <b>{{$t('simple.account') + ': '}} </b> {{result.account_username}}
                                                    </p>
                                                    <span class="badge badge-primary text-wrap" v-b-tooltip.hover
                                                          :title="moment(result.customer_created_at).format('YYYY-MM-DD HH:ss')"> <b>{{$t('ticket.first_contact') + ': '}} </b> {{moment.utc(result.customer_created_at).fromNow(true) }}</span>
                                                    <span class="badge badge-danger text-wrap" v-b-tooltip.hover
                                                          :title="moment(result.customer_updated_at).format('YYYY-MM-DD HH:ss')"> <b>{{$t('ticket.last_updated') + ': '}} </b> {{moment.utc(result.customer_updated_at).fromNow(true) }}</span>


                                                </div>
                                            </result-list-description>
                                        </result-list-content>
                                    </result-list>
                                </result-list-wrapper>
                            </div>
                        </reactive-list>


                    </b-container>
                </div>

            </div>
        </div>

    </div>


    </ReactiveBase>

</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex';
    Vue.config.productionTip = false

    //Reactivesearch Component
    import ReactiveSearch from "@appbaseio/reactivesearch-vue";
    Vue.use(ReactiveSearch);

    export default {

        mounted: function () {
            // Component mounted
            console.log('Player View component mounted.');
        },

        destroyed() {
            // Component destroyed
            console.log('Player View component destroyed.');
        },

        created: function() {
            // Component created
        },
        

        data: function() {
            return  {

                title: this.$t('page.search'),
                moment: this.$moment,

            }
        },
        
        metaInfo: function() {

            return  {
                title: this.title,
            }
        },

        methods: {},

        computed : {

            // Map Getter
            ...mapGetters({
                // ElasticSearch Credentials
                Credentials: 'Report/getCredentials',
                ElasticUrl: 'Report/getElasticUrl',

            }),

        },
        
    }
</script>