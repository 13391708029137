<template>

    <span class="message-header-data pt-1" v-b-tooltip.hover>

        <font-awesome-icon :icon="'user-tie'" class="text-warning" />

        {{$t('confirmation.requested_by')}} {{ requested_by }}

    </span>

</template>
<script>

    export default {

        props: [
            'requested_by',

        ],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>