const state = {
  team: false,
  teams: [],
  teams_api: '/api/v1/settings/teams/',
  permission:'',

};

// getters
const getters = {

  // Get team api
  getTeamAPI: (state) => {
    // Return team api
    return state.teams_api;
  },

  // Get teams
  getTeams: (state) => {
    // Return team
    return state.teams;
  },

    // Get teams
  getPermission: (state) => {
    // Return permission
    return state.permission;
  },

};

// mutations
const mutations = {

    // Set teams
    setTeams (state,payload){

        state.teams = payload;
  },

};

// actions
const actions = {

  // Fetch teams
  async fetchTeams({commit,dispatch, getters, state}){

      // Start waiting
      dispatch('wait/start', 'teams', { root: true });

        // Get teams
      await axios.get(getters.getTeamAPI)
            .then((response) => {

                if(response.data.error){
                    state.permission = Vue.i18n.translate('permission.read_teams');
                    // Reset Teams
                    commit('setTeams', []);
                    // Stop waiting
                    dispatch('wait/end', 'teams', { root: true });

                }else {
                    // Set Teams
                    commit('setTeams', response.data);


                    // Stop waiting
                    dispatch('wait/end', 'teams', { root: true });
                }
            })
            .catch((error) => {

                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
                console.log(error)


                // Stop waiting
                dispatch('wait/end', 'teams', { root: true });

            });

    },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}