<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ this.status ? $t('simple.close_complaint') : $t('simple.open_complaint') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <v-wait for="complaint_status">

                        <loading slot="waiting"/>

                        <b-alert variant="danger" show> {{ this.status ? $t('simple.close_complaint_confirm') : $t('simple.open_complaint_confirm') }}</b-alert>

                    </v-wait>

                </div>

                <div class="modal-footer">

                    <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>
                    <b-button @click="complaint_status" variant="primary">{{ this.status ? $t('simple.close_complaint_verify') : $t('simple.open_complaint_verify') }}</b-button>

                </div>

            </div>
        </div>
    </div>
</template>
<script>


    export default {

        mounted: function () {
            console.log('Complaint Status Modal mounted.');
        },

        destroyed() {
            console.log('Complaint Status Modal destroyed.');
        },

        data: function() {
            return  {}
        },

        methods: {

            complaint_status: async function(){

                // Loading start
                this.$wait.start('complaint_status');

                    // Change Complaint Status
                    let account_id = this.account_id;
                    let complaint_id = this.complaint_id;
                    let status = this.status;
                    let complaint_status_api = '/api/v1/sikayetvar/complaint/status/';
                    // Change Complaint Status
                    await axios.post(complaint_status_api + account_id, {

                        complaint_id: complaint_id,

                        status: status,

                    }).then((response) => {

                        // Close modal
                        this.$close(true);
                        //console.log(response);

                        // Loading stop
                        this.$wait.end('complaint_status');

                        // Show success
                        Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

                    }).catch((error) => {

                        // Show error
                        Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                        // Log to console
                        console.log(error);

                        // Loading stop
                        this.$wait.end('complaint_status');

                    });



            },
        },
    }
</script>