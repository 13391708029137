<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('page.close_ticket')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

                <form @submit.prevent="validate_add">


                    <div class="form-group">

                        <label for="post_id">{{ $t('ticket.post_id') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="post_id"
                            name="post_id"
                            v-validate="'required|max:255'"
                            v-model="close_post_id"
                        >

                        <small v-show="errors.has('post_id')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('post_id') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="reason">{{ $t('ticket.close_reason') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="reason"
                            name="reason"
                            v-validate="'required|max:255'"
                            v-model="close_reason"
                        >

                        <small v-show="errors.has('reason')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('reason') }}</small>

                    </div>

                  <v-wait for="detect_open_tickets">

                    <loading slot="waiting"/>
                  <b-alert v-if="open_tickets_detail.length" :show="true" variant="info">
                    <font-awesome-icon :icon="'hashtag'" /> {{ $t('ticket.post_id') }} : {{ open_tickets_detail[0].post_id }}
                    <br>
                    <font-awesome-icon :icon="'ticket-alt'" /> {{ $t('ticket.ticket_counts') }} : {{ open_tickets_detail[0].tickets_count }}
                  </b-alert>
                    <b-alert v-if="open_tickets_detail_error.length" :show="true" variant="danger">
                      <font-awesome-icon :icon="'exclamation-circle'" /> {{ open_tickets_detail_error }}
                    </b-alert>
                    <b-button v-if="!open_tickets_detail" @click="validate_data()" variant="primary">{{ $t('simple.detect') }}</b-button>
                  <b-button v-if="open_tickets_detail.length" @click="validate_data()" variant="primary">{{ $t('ticket.close_all') }}</b-button>


                  </v-wait>
                </form>


        </div>

        <div class="modal-footer">

            <b-button @click="closed_details_empty()" variant="secondary">{{ $t('simple.cancel') }}</b-button>

        </div>


        </div>
      </div>
    </div>
</template>
<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import {create} from "vue-modal-dialogs";
import TicketCloseConfirm from "./TicketCloseConfirm.vue";
import UserEdit from "./UserEdit";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Close Ticket Modal mounted');
        },

        destroyed() {
            // Component destroyed
            console.log('Close Ticket Modal destroyed.');
        },

        created: function() {
            // Component created
        },

        data: function() {
            return  {}
        },

        computed: {

          // Map getters
          ...mapGetters({
            open_tickets_detail: 'Ticket/getOpenTicketsDetail',
            close_tickets_post_id: 'Ticket/getClosedTicketsPostID',
            close_tickets_reason: 'Ticket/getClosedTicketsReason',
            open_tickets_detail_error: 'Ticket/getOpenTicketsDetailError',
          }),

          // Close Tickets Post ID
          close_post_id: {

            // getter
            get: function () {
              // Get Close Tickets Post ID
              console.log('Get Close Ticket Post ID: ' + this.close_tickets_post_id);
              return this.close_tickets_post_id;
            },

            // setter
            set: function (value) {
              // Check if we have value?
              if (value) {

                // Update Close Tickets Post ID
                console.log('Set Close Ticket Post ID: ' + this.close_tickets_post_id);
                this.setClosedTicketsPostID(value);
              }
            },

          },

          // Close Tickets Reason
          close_reason: {

            // getter
            get: function () {
              // Get Close Tickets Reason
              return this.close_tickets_reason;
            },

            // setter
            set: function (value) {
              // Check if we have value?
              if (value) {

                // Update Close Tickets Reason
                this.setClosedTicketsReason(value);
              }
            },

          },

        },

        methods: {

          // Map actions
          ...mapActions('Ticket', [
            'detectOpenTickets',
          ]),

          // Map actions
          ...mapMutations('Ticket', [
            'setClosedTicketsPostID',
            'setClosedTicketsReason',
            'setOpenTicketsDetail',
          ]),

          closed_details_empty: function() {
            this.setOpenTicketsDetail('');
            this.setClosedTicketsPostID(null);
            this.setClosedTicketsReason(null);
            this.$close(false);
          },

            close_all: async function() {
              // Show Confirmation For Close Tickets
              const confirm_close_ticket = create(TicketCloseConfirm, 'closeType');

              if ( await confirm_close_ticket('post_id') ){
                // Refresh account list
                  this.setOpenTicketsDetail('');
                  this.setClosedTicketsPostID(null);
                  this.setClosedTicketsReason(null);
                  this.$close(true);
              }

            },

            validate_data: async function () {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result && this.open_tickets_detail) {
                      // Show Confirmation For Close Tickets
                      this.close_all();

                    }else if (result && !this.open_tickets_detail){
                      // Get Open Tickets Details
                      this.detectOpenTickets(this.close_tickets_post_id);
                    }
                });
            },
        },
    }
</script>
