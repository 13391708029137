<template>

    <b-button
            v-b-popover.hover.top="$t('confirmation.customer_profile_source')"
            size="lg"
            variant="light"
            @click="fetch_customer" >

        <font-awesome-icon :icon="'user'" class="text-danger" />


    </b-button>



</template>
<script>

    import {mapActions, mapMutations} from "vuex";


    export default {

        props: ['account','draft'],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {

                active_tab: '',
            };

        },

        computed: {},

        methods: {

            ...mapMutations('Confirmation', [
                'setActiveTabId',
            ]),

            ...mapActions('Customer', [
                'fetchCustomer',
            ]),

            fetch_customer(){
                this.fetchCustomer(this.draft.customer_id);
                this.setActiveTabId(this.draft.customer_id);
            }

        },

    }
</script>