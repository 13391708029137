<template>
    <transition appear name="v-img-fade" style="position: absolute">
        <div class="fullscreen-v-img" @click.self="$close">
            <div class="header-v-img">
        <span class="title-v-img">
          {{ location.address }}
        </span>
                <div class="buttons-v-img">
                    <span @click="$close">
            &times;
          </span>
                </div>
            </div>


            <div class="content-v-img">


                <GmapMap
                        :center="{lat:parseFloat(location.lat),lng:parseFloat(location.lng)}"
                        :zoom="18"
                        style="
                        width: 60%;
                        height: 80%;
                        margin: 0;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          -ms-transform: translate(-50%, -50%);
                          transform: translate(-50%, -50%);"
                >
                    <GmapMarker ref="myMarker" :position="google && new google.maps.LatLng(parseFloat(location.lat), parseFloat(location.lng))" />

                </GmapMap>


            </div>

        </div>
    </transition>
</template>
<script>
    import {gmapApi} from 'vue2-google-maps';
    export default {
        components:{gmapApi},
        name: "MyMap",


        mounted: function () {
            // Component mounted
            console.log('View Map Modal mounted');

        },

        created() {
            console.log('View Map Modal created.');

            window.addEventListener('keyup', e => {
                // esc key and 'q' for quit
                if (e.keyCode === 27 || e.keyCode === 81) this.$close(true);
            });
        },

        destroyed() {
            console.log('View Map Modal destroyed.');
        },

        data: function() { return {
            center: {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lng)
            },
            markers: [{
                position: {
                    lat: parseInt(location.lat),
                    lng: parseInt(location.lng)
                }
            }]
        }},

        computed: {
            google: gmapApi,

        },

        methods: {


        },
    }
</script>


