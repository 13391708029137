<template>

    <div class="container-fluid">

        <div class="row">

            <div class="col-sm-5">

                <h6 class="mb-0">{{ twitter.followers_count }} </h6>

                <span class="text-muted">{{ $t('customer.followers') }}</span>

            </div>

            <div class="col-sm-2">

                <span class="panel-divider"></span>

            </div>

                <div class="col-sm-5">

                    <h6 class="mb-0">{{ twitter.friends_count }} </h6>

                    <span class="text-muted">{{ $t('customer.following') }}</span>

                </div>

        </div>

        <hr>

        <div class="row flex-column">

            <!-- About -->
            <div class="panel-title panel-title pb-2 text-uppercase text-muted">{{ $t('customer.about') }}</div>

            
            <!-- Name -->
            <div class="pb-2">

                <font-awesome-icon :icon="'user'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.name')"/> <span class="font-weight-light ml-2">{{twitter.name }}</span>

            </div>

            <!-- Created -->
            <div class="pb-2">

                <font-awesome-icon :icon="'user-clock'" class="plus__icon" v-b-tooltip.hover :title="$t('simple.created')"/> <span class="font-weight-light ml-2" v-b-tooltip.hover :title="twitter.created_at">{{  moment(twitter.created_at).fromNow() }}</span>

            </div>

            <!-- Statuses -->
            <div class="pb-2">

                <font-awesome-icon :icon="'user-edit'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.statuses')"/> <span class="font-weight-light ml-2">{{ twitter.statuses_count }}</span>

            </div>

            <!-- Verified -->
            <div class="pb-2" v-if="twitter.verified">

                <font-awesome-icon :icon="'user-check'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.verified')"/> <span class="font-weight-light ml-2">{{ $t(twitter_verified) }}</span>

            </div>

            <!-- Protected -->
            <div class="pb-2" v-if="twitter.protected">

                <font-awesome-icon :icon="'user-lock'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.protected')"/> <span class="font-weight-light ml-2">{{ $t(twitter_protected) }}</span>

            </div>

            <!-- Url -->
            <div class="pb-2" v-if="twitter.url">

                <font-awesome-icon :icon="'link'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.url')"/> <b-link :href="twitter.url" class="font-weight-light ml-2" target="_blank" rel="noopener noreferrer">{{ twitter.url }}</b-link>

            </div>


        </div>

    </div>


</template>

<script>

    export default {

        props: ['twitter'],

        mounted: function () {
            // Component mounted
            //console.log('Customer Type Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Type Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {

                moment: this.$moment,

            };

        },

        computed: {


            // Twitter verified
            twitter_verified: function () {

                if(this.twitter.verified){

                    return 'simple.yes';

                } else {

                    return 'simple.no';

                }
            },


            // Twitter protected
            twitter_protected: function () {

                if(this.twitter.protected){

                    return 'simple.yes';

                } else {

                    return 'simple.no';

                }
            },


        },
        
        methods: {},
        
    }
</script>