<template>

    <!-- Name -->
    <div>
        <div class="list-customer-message-name mr-auto" 
            :id="`tooltip-${customer.id}`">{{ customer_name }}</div>
        <b-tooltip :target="`tooltip-${customer.id}`">
            <template v-if="customer.whatsapp">
                <font-awesome-icon :icon="'phone-square-alt'"/> {{ customer.whatsapp.whatsapp_id }}
                <br>
                <font-awesome-icon :icon="'book'"/> {{ customer.whatsapp.name }}
            </template>
            <template v-else>
                {{ customer.username }}
            </template>
        </b-tooltip>
    </div>



</template>

<script>

export default {

    props: ['customer'],

    data: function () {
        return {}
    },

    methods: {},

    computed: {

        customer_name() {
            if (this.customer.whatsapp && this.customer.name) {
                return this.customer.name
            }
            return this.customer.full_name ? this.customer.full_name : this.customer.username

        }
    },
}
</script>