<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="userTouchPerformance.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';


    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
    /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//     import * as am4plugins_annotation from "@amcharts/amcharts4/plugins/annotation";

    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('xy', this.userTouchPerformance)

                let root;

                if (this.userTouchPerformance.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }
                root.setThemes([am5themes_Animated.new(root)]);

                let data = this.userTouchPerformance.length ? this.userTouchPerformance : this.fakeUserTouchPerformance;


                let chart = root.container.children.push(
                        am5xy.XYChart.new(root, {
                                panX: false,
                                panY: false,
                                wheelX: "none",
                                wheelY: "none",
                                paddingLeft: 50,
                                paddingRight: 40
                        })
                );

                let yRenderer = am5xy.AxisRendererY.new(root, {
                        minGridDistance: 20,
                });
                yRenderer.grid.template.set("visible", false);

                let yAxis = chart.yAxes.push(
                        am5xy.CategoryAxis.new(root, {
                                categoryField: "user",
                                renderer: yRenderer,
                                paddingRight: 30
                        })
                );

                let xRenderer = am5xy.AxisRendererX.new(root, {});
                xRenderer.grid.template.set("strokeDasharray", [3]);

                let xAxis = chart.xAxes.push(
                        am5xy.ValueAxis.new(root, {
                                min: 0,
                                renderer: xRenderer
                        })
                );


                let series = chart.series.push(
                        am5xy.ColumnSeries.new(root, {
                                name: "Series",
                                xAxis: xAxis,
                                yAxis: yAxis,
                                valueXField: "value",
                                categoryYField: "user",
                                sequencedInterpolation: true,
                                // calculateAggregates: true,
                                maskBullets: false,
                                tooltip: am5.Tooltip.new(root, {
                                        dy: 0,
                                        pointerOrientation: "vertical",
                                        labelText: "{valueX}"
                                })
                        })
                );


                series.columns.template.setAll({
                        strokeOpacity: 0,
                        cornerRadiusBR: 10,
                        cornerRadiusTR: 10,
                        cornerRadiusBL: 10,
                        cornerRadiusTL: 10,
                        maxHeight: 20,
                        fillOpacity: 0.8
                });

                let currentlyHovered;

                series.columns.template.events.on("pointerover", function (e) {
                        handleHover(e.target.dataItem);
                });

                series.columns.template.events.on("pointerout", function (e) {
                        handleOut();
                });

                function handleHover(dataItem) {
                        if (dataItem && currentlyHovered != dataItem) {
                                handleOut();
                                currentlyHovered = dataItem;
                                let bullet = dataItem.bullets[0];
                                bullet.animate({
                                        key: "locationX",
                                        to: 1,
                                        duration: 600,
                                        easing: am5.ease.out(am5.ease.cubic)
                                });
                        }
                }

                function handleOut() {
                        if (currentlyHovered) {
                                let bullet = currentlyHovered.bullets[0];
                                bullet.animate({
                                        key: "locationX",
                                        to: 0,
                                        duration: 600,
                                        easing: am5.ease.out(am5.ease.cubic)
                                });
                        }
                }


                let circleTemplate = am5.Template.new({});

                series.bullets.push(function (root, series, dataItem) {
                        let bulletContainer = am5.Container.new(root, {});
                        let circle = bulletContainer.children.push(
                                am5.Circle.new(
                                        root,
                                        {
                                                radius: 20
                                        },
                                        circleTemplate
                                )
                        );

                        let maskCircle = bulletContainer.children.push(
                                am5.Circle.new(root, { radius: 25 })
                        );

                        let imageContainer = bulletContainer.children.push(
                                am5.Container.new(root, {
                                        mask: maskCircle
                                })
                        );

                        let image = imageContainer.children.push(
                                am5.Picture.new(root, {
                                        templateField: "pictureSettings",
                                        centerX: am5.p50,
                                        centerY: am5.p50,
                                        width: 40,
                                        height: 40
                                })
                        );

                        return am5.Bullet.new(root, {
                                locationX: 0,
                                sprite: bulletContainer
                        });
                });

                // heatrule
                series.set("heatRules", [
                        {
                                dataField: "valueX",
                                min: am5.color(0xe5dc36),
                                max: am5.color(0x5faa46),
                                target: series.columns.template,
                                key: "fill"
                        },
                        {
                                dataField: "valueX",
                                min: am5.color(0xe5dc36),
                                max: am5.color(0x5faa46),
                                target: circleTemplate,
                                key: "fill"
                        }
                ]);

                series.data.setAll(data);
                yAxis.data.setAll(data);

                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineX.set("visible", false);
                cursor.lineY.set("visible", false);

                cursor.events.on("cursormoved", function () {
                        let dataItem = series.get("tooltip").dataItem;
                        if (dataItem) {
                                handleHover(dataItem)
                        }
                        else {
                                handleOut();
                        }
                })


                if (this.userTouchPerformance.length > 0) {
                        am5plugins_exporting.Exporting.new(root, {
                                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                                dataSource: data,
                                dataFields: {
                                        value: "Value",
                                        user: "User"
                                },
                                filePrefix: "convoTypes",
                        });
                }

                this.root = root;



                // Create chart
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
                // chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                // chart.paddingRight = 40;

                // // If no image, it can be blank / value = total number of posts
                // chart.data = this.userTouchPerformance;

                // let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                // categoryAxis.dataFields.category = "user";
                // categoryAxis.renderer.grid.template.strokeOpacity = 0;
                // categoryAxis.renderer.minGridDistance = 10;
                // categoryAxis.renderer.labels.template.dx = -20;
                // categoryAxis.renderer.minWidth = 120;
                // categoryAxis.renderer.tooltip.dx = -20;

                // let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                // valueAxis.renderer.inside = true;
                // valueAxis.renderer.labels.template.fillOpacity = 0.3;
                // valueAxis.renderer.grid.template.strokeOpacity = 0;
                // valueAxis.min = 0;
                // valueAxis.cursorTooltipEnabled = false;
                // valueAxis.renderer.baseGrid.strokeOpacity = 0;
                // valueAxis.renderer.labels.template.dy = 20;

                // let series = chart.series.push(new am4charts.ColumnSeries);
                // series.dataFields.valueX = "value";
                // series.dataFields.categoryY = "user";
                // series.tooltipText = "{valueX.value}";
                // series.tooltip.pointerOrientation = "vertical";
                // series.tooltip.dy = - 30;
                // series.columnsContainer.zIndex = 100;
                // // Sorting
                // categoryAxis.sortBySeries = series;

                // let columnTemplate = series.columns.template;
                // columnTemplate.height = am4core.percent(40);
                // columnTemplate.maxHeight = 50;
                // columnTemplate.column.cornerRadius(60, 10, 60, 10);
                // columnTemplate.strokeOpacity = 0;

                // series.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueX", min: am4core.color("#e5dc36"), max: am4core.color("#5faa46") });
                // series.mainContainer.mask = undefined;

                // let cursor = new am4charts.XYCursor();
                // chart.cursor = cursor;
                // cursor.lineX.disabled = true;
                // cursor.lineY.disabled = true;
                // cursor.behavior = "none";

                // let bullet = columnTemplate.createChild(am4charts.CircleBullet);
                // bullet.circle.radius = 20;
                // bullet.valign = "middle";
                // bullet.align = "left";
                // bullet.isMeasured = true;
                // bullet.interactionsEnabled = false;
                // bullet.horizontalCenter = "right";
                // bullet.interactionsEnabled = false;

                // let hoverState = bullet.states.create("hover");
                // let outlineCircle = bullet.createChild(am4core.Circle);
                // outlineCircle.adapter.add("radius", function (radius, target) {
                //         let circleBullet = target.parent;
                //         return circleBullet.circle.pixelRadius;
                // })

                // let image = bullet.createChild(am4core.Image);
                // image.width = 40;
                // image.height = 40;
                // image.horizontalCenter = "middle";
                // image.verticalCenter = "middle";
                // image.propertyFields.href = "href";

                // image.adapter.add("mask", function (mask, target) {
                //         let circleBullet = target.parent;
                //         return circleBullet.circle;
                // })

                // let previousBullet;
                // chart.cursor.events.on("cursorpositionchanged", function (event) {
                //         let dataItem = series.tooltipDataItem;

                //         if (dataItem.column) {
                //                 let bullet = dataItem.column.children.getIndex(1);

                //                 if (previousBullet && previousBullet != bullet) {
                //                         previousBullet.isHover = false;
                //                 }

                //                 if (previousBullet != bullet) {

                //                         let hs = bullet.states.getKey("hover");
                //                         hs.properties.dx = dataItem.column.pixelWidth;
                //                         bullet.isHover = true;

                //                         previousBullet = bullet;
                //                 }
                //         }
                // })

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();

                // // Plugin: Annotation
                // chart.plugins.push(new am4plugins_annotation.Annotation());
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoUserTouch";




        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

                if (this.root) {
                        this.root.dispose();
                }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({

                        userTouchPerformance: 'Report/getUserTouchPerformance',
                        fakeUserTouchPerformance: 'Report/getFakeUserTouchPerformance',

                })

        },
    }
</script>