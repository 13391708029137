<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="vipImportant.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>



    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    import * as am5 from "@amcharts/amcharts5";
    import * as am5venn from "@amcharts/amcharts5/venn";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";




    /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import * as am4plugins_venn from "@amcharts/amcharts4/plugins/venn";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('venn', this.vipImportant )


                let root;


                if (this.vipImportant.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([am5themes_Animated.new(root)]);


                let container = root.container.children.push(am5.Container.new(root, {
                        width: am5.p100,
                        height: am5.p100,
                        layout: root.verticalLayout
                }));

                let series = container.children.push(am5venn.Venn.new(root, {
                        categoryField: "name",
                        valueField: "value",
                        intersectionsField: "sets",

                }));

                let data = this.vipImportant.length ? this.vipImportant : this.fakevipImportant;



                series.slices.template.set("tooltipText", "{category}: {value}");

                series.hoverGraphics.setAll({
                        strokeDasharray: [3, 3],
                        stroke: am5.color(0xffffff),
                        strokeWidth: 2
                });

                series.labels.template.setAll({
                        fontSize: 20,
                        text: "{category}",
                });

                series.labels.template.adapters.add("dy", function (dy, target) {
                        if (target.dataItem.dataContext.name == "Both") {
                                return 40;
                        }
                        return dy;
                })

                series.data.setAll(data);


                let uniq_number = this.vipImportant.length ? this.vipImportant[0] : this.fakevipImportant[0]
                
                let title = container.children.push(am5.Label.new(root, {
                        text: this.$t('reports.uniquely') + uniq_number,
                        fontSize: 12,
                        x: am5.percent(50),
                        centerX: am5.percent(50),
                }));

                let legend = container.children.push(
                        am5.Legend.new(root, {
                                centerX: am5.p50,
                                x: am5.p50
                        })
                );
                legend.data.setAll(series.dataItems);


                if( this.vipImportant.length > 0) {
                    am5plugins_exporting.Exporting.new(root, {
                        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                        dataSource: data,
                        filePrefix: "convoTypes",
                    });
                }


                this.root = root;


                // // Create chart
                // var chart = am4core.create(this.$refs.chartdiv, am4plugins_venn.VennDiagram);

                // // Create title
                // let title = chart.titles.create();
                // title.text = this.$t('reports.uniquely') + this.vipImportant[0];
                // title.fontSize = 12;
                // title.marginBottom = 10;

                // // Create and configure series
                // var series = chart.series.push(new am4plugins_venn.VennSeries())
                // series.dataFields.category = "name";
                // series.dataFields.value = "value";
                // series.dataFields.intersections = "sets";
                // series.labels.template.text = "[bold]{value}[/]";
                // series.labels.template.fontSize = 20;

                // series.data = [
                //         { name: this.$t('simple.important'), value: this.vipImportant[1], "color": am4core.color("#a3b48a") },
                //         { name: this.$t('simple.vip'), value: this.vipImportant[2], "color": am4core.color("#e6492d") },
                //         { name: this.$t('reports.both'), value: this.vipImportant[3], "color": am4core.color("#efd9ce"), sets: [this.$t('simple.important'), this.$t('simple.vip')] },
                // ];

                // series.slices.template.propertyFields.fill = "color";

                // // Add legend
                // chart.legend = new am4charts.Legend();

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoImportance";




        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

                if (this.root) {
                        this.root.dispose();
                }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {
                // Map getters
                ...mapGetters({
                        vipImportant: 'Report/getVipImportant',
                        fakevipImportant: 'Report/getFakeVipImportant',

                })
        },
    }
</script>