<template>
        <b-col class="end">
            <!-- Account name -->
            <b-badge pill variant="secondary" class="align-text-bottom" active-class="variant-danger" v-if="customer" v-b-tooltip.hover
                     :title="customer.account.integration_id === 'whatsapp' ? customer.account.user_id : '@' + customer.account.username">
                <font-awesome-icon size="xs" :icon="'angle-double-right'"/> {{ customer.account.name }}
            </b-badge>
        </b-col>


</template>

<script>

    export default {

        props: ['customer'],

        data: function () {
            return {}
        },

        methods: {},

        computed: {},
    }
</script>