var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mb-2",attrs:{"sm":"12","md":"3","lg":"2","title":_vm.title}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('b-col',{attrs:{"sm":"12","md":"5"}},[_c('b-form-radio-group',{staticClass:"mb-3",attrs:{"checked":_vm.value,"options":_vm.radioButtonOptions},on:{"change":_vm.handleRadioButtonChange}})],1)],1),_vm._v(" "),_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"sm":"12","md":"3","lg":"2"}}),_vm._v(" "),_c('b-col',{attrs:{"sm":"12","md":"5"}},[(_vm.value == 'integration')?_c('treeselect',{attrs:{"value":_vm.integration_value,"options":_vm.integration_options,"multiple":true,"value-format":"object","placeholder":_vm.$t('configuration.select_integrations'),"normalizer":_vm.integrationNormalizer},on:{"input":_vm.handleIntegrationChange},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return _c('label',{},[_c('font-awesome-icon',{attrs:{"icon":node.raw.name != 'sikayetvar' ? ['fab', node.raw.icon] : ['fas', node.raw.icon]}}),_vm._v("\n                    "+_vm._s(node.raw.name)+"\n                ")],1)}},{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_c('font-awesome-icon',{attrs:{"icon":node.raw.name != 'sikayetvar' ? ['fab', node.raw.icon] : ['fas', node.raw.icon]}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(node.raw.name))])],1)}}],null,false,432239331)}):_vm._e(),_vm._v(" "),(_vm.value == 'account')?_c('treeselect',{attrs:{"value":_vm.account_value,"options":_vm.account_options,"multiple":true,"value-format":"object","placeholder":_vm.$t('configuration.select_accounts'),"normalizer":_vm.accountNormalizer},on:{"input":_vm.handleAccountChange},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return _c('label',{},[_c('font-awesome-icon',{style:({ color: node.raw.color }),attrs:{"icon":['fab', node.raw.integration.icon]}}),_vm._v("\n                    "+_vm._s(node.raw.name)+"\n                ")],1)}},{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_c('font-awesome-icon',{style:({ color: node.raw.color }),attrs:{"icon":['fab', node.raw.integration.icon]}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(node.raw.name))])],1)}}],null,false,2233061795)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }