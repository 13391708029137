<template>
    <div class="actions-message-menu bg-white">
        <div class="row align-items-center justify-content-center">
            <div class="ml-sm-1">
                <template v-if="windowWidth < 576">
                    <!-- Back to Conversation Menu -->
                    <b-btn 
                        pill variant="light" class="message-actions-shadow d-md-none mr-1" size="sm"
                        v-bind="toggleBindingMessages"
                        @click="changeIsExpandedStatus">
                            <font-awesome-icon :icon="'fast-backward'" class="plus__icon" style="color: #a20b0b"/>
                    </b-btn>
                </template>
                <!-- Reply -->
                <b-btn pill variant="light" class="message-actions-shadow" size="sm" v-b-tooltip.hover :title="$t('macros.reply')"
                       @click="resetActiveTab" :disabled="disabled_areas.messages_list_action_recycle">
                    <font-awesome-icon :icon="'reply'" class="plus__icon"/>
                </b-btn>
                <!-- History -->
                <b-btn pill variant="light" class="ml-1 message-actions-shadow" size="sm" v-b-tooltip.hover :title="$t('simple.history')"
                       @click="$emit('timeline_action')">
                    <font-awesome-icon :icon="'history'" class="plus__icon"/>
                </b-btn>
                <!-- Pixel -->
                <b-btn pill :variant="getPixelImageToJson ? 'danger':'light'" class="ml-1 message-actions-shadow" size="sm" v-b-tooltip.hover :title="$t('pixel.pixel')"
                       @click="pixel">
                    <font-awesome-icon :icon="'image'" class="plus__icon"/>
                </b-btn>
                <!-- Refresh -->
                <b-btn pill variant="light" class="ml-1 message-actions-shadow" size="sm" v-b-tooltip.hover :title="$t('default.refresh')"
                       @click="refreshMessages(customer_id)">
                    <font-awesome-icon :icon="'sync'" class="plus__icon"/>
                </b-btn>
                <!-- Delete -->
                <b-btn :hidden="inbox_type === 'trash'" pill variant="light" class="ml-1 message-actions-shadow" size="sm" v-b-tooltip.hover :title="$t('page.trash')"
                       @click="trash" :disabled="disabled_areas.messages_list_action_trash">
                    <font-awesome-icon :icon="'trash-alt'" class="plus__icon"/>
                </b-btn>
                <!-- Restore -->
                <b-btn v-if="inbox_type === 'trash'" pill variant="light" class="ml-1 message-actions-shadow" size="sm" v-b-tooltip.hover title="Restore"
                       @click="restore" :disabled="disabled_areas.messages_list_action_recycle">
                  <font-awesome-icon :icon="'recycle'" class="plus__icon"/>
                </b-btn>  
                <template v-if="windowWidth < 576">
                    <!-- Back to Conversation Menu -->
                    <b-btn 
                        pill variant="light" class="message-actions-shadow d-md-none ml-2" size="sm"
                        v-bind="toggleBindingMessages"
                        @click="changeIsExpandedStatus">
                            <font-awesome-icon :icon="'times'" class="plus__icon" style="color: #a20b0b"/>
                    </b-btn>
                </template>
            </div>
            <div class="ml-sm-auto mr-sm-1">
                    <transition name="slide-fade">
                        <!-- Pagination-->
                        <b-pagination v-if="total" class="m-1" size="sm" :total-rows="total"
                                      :value="current" @input="updateMessagePage"
                                      :per-page="perpage"
                                      >
                        </b-pagination>
                    </transition>
                </div>   
        </div>
    </div>
</template>

<script>

    // Vuex
    import {mapGetters, mapActions, mapMutations} from 'vuex';

    import PixelRender from '../Module/Pixel/PixelRender.vue';
    import {create} from 'vue-modal-dialogs'
    
    export default ({

        props: ['customer_id', 'disabled_areas'],

        data: function() {
            return {
                same_user: true,
                timeline: false,
                template_image_json: this.getPixelImageToJson,
                inbox_type: this.$route.params.type,
                windowWidth: window.innerWidth,
                pagination: {'current_page': this.current ?? 1, 'total': this.total, 'per_page': this.perpage},
                // timeline: false,
            }
        },

        methods: {
            // Timeline action
            // timeline_action: function () {
            //     // Enable/disable at the same time
            //     // this.timeline = !this.timeline;
            //     return !this.timeline;
            //     // console.log(this.timeline);
            //     // console.log(this.customer_id);                
            // },

            changeIsExpandedStatus() {  
                this.updateIsInboxExpanded(true);
                this.updateIsMessagesExpanded(false);
            },

            onResize() {
                this.windowWidth = window.innerWidth;
            },

            pixel: async function () {

                // Show image modal
                const pixel_render = create(PixelRender, 'template_image_json', 'template_type');

                if(this.getPixelImageToJson) {
                    if (await pixel_render(this.getPixelImageToJson.toJSON(), 1)) {
                        // Nothing here yet
                    }
                }else{
                    if (await pixel_render(null, 2)) {
                        // Nothing here yet
                    }
                }
            },
            // Update message page
            updateMessagePage: async function (page) {

                // Current page
                this.pagination.current_page = page;

                //  Understand for pagination action(paging:this.paging)

                this.updatePage({customer_id: this.customer_id, page: page, same_user:this.same_user});

            },

            // Messages
            ...mapActions('Messages', [
                'refreshMessages',
                'updatePage',
            ]),

            // Reply
            ...mapActions('Reply', [
                'resetActiveTab',
                'updateReplyTo',
            ]),

            // Trash
            ...mapActions('Trash', [
                'createTrash',
                'restoreTrash',
            ]),

            ...mapActions('Inbox', [
                'updateIsInboxExpanded',
                'updateIsMessagesExpanded',
                // 'updateIsPanelExpanded',
            ]),

            trash: function () {

                this.createTrash();

            },

            restore: function () {

              // Restore Trash
              this.restoreTrash(this.customer_id);

            },

        },

        computed: {

            ...mapGetters({
                // Get Pixel Image
                getPixelImageToJson: 'Reply/getPixelImageToJson',
                total: 'Messages/getTotal',
                current: 'Messages/getCurrent',
                perpage: 'Messages/getPerPage',
                is_inbox_expanded: 'Inbox/getIsInboxExpanded',
                is_messages_expanded: 'Inbox/getIsMessagesExpanded',
            }),

            expand_inbox_list: {
              get() { 
                return this.is_inbox_expanded; 
              },
              set(value) { 
                this.updateIsInboxExpanded(value); 
            }
           },

           expand_message_list: {
              get() { 
                return this.is_messages_expanded; 
              },
              set(value) { 
                this.updateIsMessagesExpanded(value); 
            }
           },

           toggleBindingMessages() {
              if (this.windowWidth < 576) {  
              return {
                  'aria-controls' : 'plus-col-main-list-inbox-container plus-col-main-messages-panel-mobile',
                  'aria-expanded' : this.is_messages_expanded ? 'true' : 'false',
                  class : this.is_messages_expanded ? null : 'collapsed',
                }
              }
              return {};
            },

            
            
        },

        mounted() {
            this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
            })
        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },
    

    })

</script>