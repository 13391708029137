<template>
    <b-container fluid class="px-lg-5 px-0">
        <h4>{{ $t('macros.create_template') }}</h4>

        <b-row>
            <b-col>
                <ValidationObserver ref="main">
                    <b-card class="mb-1">
                        <template #header>
                            <div class="d-flex align-items-center">
                                <div><span class="font-weight-bold">{{ $t('macros.template_details') }}</span></div>
                                <b-button variant="info" pill @click="showTicketDetails = !showTicketDetails"
                                    class="ml-auto p-1">
                                    <font-awesome-icon :icon="'caret-down'" class="mr-1" v-if="!showTicketDetails" />
                                    <font-awesome-icon :icon="'caret-up'" class="mr-1" v-else />

                                    {{ $t('macros.ticket_details') }}
                                </b-button>
                            </div>
                        </template>

                        <ValidationProvider rules="required" v-slot="{ errors, valid }">
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="session_message_name" class="font-weight-bold">
                                        {{ $t('macros.template_name') }}<span class="text-danger">*</span>:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input id="session_message_name" name="session_message_name"
                                        v-model="session_message.name" :state="valid"></b-form-input>
                                </b-col>
                            </b-row>
                        </ValidationProvider>
                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="session_message_type" class="font-weight-bold">{{ $t('macros.template_type')
                                }}<span class="text-danger">*</span>:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-select v-model="session_message.type" :options="session_message_type_options"
                                    @change="resetSessionMessage" :disabled="isSessionMessageItemExists"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row class="align-items-center mb-3" v-if="session_message.type === 'quick_reply'">
                            <b-col sm="3">
                                <label for="session_message_quick_reply_type" class="font-weight-bold">{{
                                    $t('macros.quick_reply_type') }}<span class="text-danger">*</span>:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-select id="session_message_quick_reply_type" v-model="session_message.quick_reply.content.type" :options="quick_reply_type_options"
                                    @change="resetSessionMessage" :disabled="isSessionMessageItemExists"></b-form-select>
                            </b-col>
                        </b-row>
                    </b-card>

                </ValidationObserver>

                <b-col class="p-0 mb-1">
                    <b-card no-body class="">
                        <template #header>
                            <div class="d-flex align-items-center">
                                <span class="font-weight-bold">{{ $t('macros.template_variables') }}</span>
                                <b-button variant="info" pill v-b-modal.custom_variable_modal class="ml-auto p-1">
                                    <font-awesome-icon :icon="'plus'" class="mr-1" />{{ $t('macros.custom') }}
                                </b-button>
                            </div>
                        </template>
                        <b-alert class="align-items-center m-2" show>{{ $t('macros.custom_variable_desc') }}</b-alert>

                        <div class="p-2">

                            <b-list-group>
                                <b-list-group-item class="d-flex align-items-center mb-1"
                                    v-for=" (default_variable, default_variable_index) in session_message.session_message_default_variables"
                                    :key="default_variable.name">
                                    <span>{{ default_variable.name }}</span>
                                    <b-button class="ml-auto" variant="outline-secondary" disabled>{{ $t('simple.default') }}
                                    </b-button>
                                </b-list-group-item>
                                <b-list-group-item class="d-flex align-items-center mb-1"
                                    v-for=" (custom_variable, custom_index) in session_message.session_message_custom_variables"
                                    :key="custom_variable.name">
                                    <span>{{ custom_variable.name }}</span>
                                    <b-button class="ml-auto" variant="outline-danger"
                                        @click="removeCustomVariable(custom_index)">{{ $t('default.delete') }}
                                    </b-button>
                                </b-list-group-item>
                            </b-list-group>



                        </div>

                    </b-card>

                </b-col>

                <b-card v-if="showTicketDetails" class="mb-1">
                    <template #header>
                        <div class="d-flex align-items-center">
                            <span class="font-weight-bold">{{ $t('macros.ticket_details') }}</span>
                            <b-button variant="danger" pill @click="resetTicketDetails" class="ml-auto">
                                {{ $t('default.reset') }} {{ $t('ticket.ticket') }}
                            </b-button>
                        </div>
                    </template>

                    <b-container fluid class="p-0">
                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="session_message_ticket_title" class="font-weight-bold">
                                    {{ $t('ticket.title') }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-input id="session_message_ticket_title" name="session_message_ticket_title"
                                    v-model="session_message.ticket.title"></b-form-input>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="session_message_ticket_desc" class="font-weight-bold">{{
                                    $t('ticket.description') }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-textarea id="session_message_ticket_desc"
                                    v-model="session_message.ticket.description" rows="5" max-rows="7"></b-form-textarea>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="session_message_ticket_status" class="font-weight-bold">{{ $t('ticket.status')
                                }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-select v-model="session_message.ticket.status">
                                    <option v-for="status in ticket_statuses" :key="status.id" :value="status.id">{{
                                        $t('ticket.' + status.name) }}
                                    </option>
                                </b-form-select>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="session_message_ticket_categories" class="font-weight-bold">{{
                                    $t('ticket.category_select') }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <treeselect v-model="session_message.ticket.categories" :options="categories"
                                    :multiple="true" value-format="object" :placeholder="$t('ticket.category_select')"
                                    :flat="true" :autoSelectAncestors="true" :autoDeselectDescendants="true"
                                    :flatten-search-results="true" :normalizer="normalizer">
                                    <label slot="option-label"
                                        slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                        :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>

                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>

                                </treeselect>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="session_message_ticket_tags" class="font-weight-bold">{{ $t('ticket.tags')
                                }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <treeselect v-model="session_message.ticket.tags" :options="tags" :multiple="true"
                                    value-format="object" :flatten-search-results="true"
                                    :placeholder="$t('ticket.tag_select')" :normalizer="normalizer">
                                    <label slot="option-label"
                                        slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                        :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>

                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>

                                </treeselect>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="session_message_ticket_users" class="font-weight-bold">{{ $t('default.users')
                                }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <treeselect v-model="session_message.ticket.users" :options="users" :multiple="true"
                                    value-format="object" :placeholder="$t('ticket.select_users')" :normalizer="normalizer">
                                    <label slot="option-label"
                                        slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                        :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>

                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>

                                </treeselect>

                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="session_message_ticket_teams" class="font-weight-bold">{{ $t('default.teams')
                                }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <treeselect v-model="session_message.ticket.teams" :options="teams" :multiple="true"
                                    value-format="object" :placeholder="$t('ticket.select_teams')" :normalizer="normalizer">
                                    <label slot="option-label"
                                        slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                        :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>

                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>

                                </treeselect>

                            </b-col>
                        </b-row>

                    </b-container>
                </b-card>




                <b-card v-if="isSessionMessageTypeChosen">
                    <template #header>
                        <span class="font-weight-bold">{{ $t('macros.type_details') }}</span>
                    </template>
                    <b-container fluid v-if="session_message.type === 'text'" class="p-0">
                        <ValidationObserver ref="text">
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_text_text_area" class="font-weight-bold">{{
                                            $t('pixel.text') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8" class="position-relative">
                                        <b-form-textarea id="session_message_text_text_area"
                                            v-model="session_message.text.text" :state="valid" rows="5" max-rows="7"
                                            :maxlength="session_message_settings.text.text_limit"></b-form-textarea>
                                        <coolpicker class="coolpicker-button" :emojiData="emojiDataAll"
                                            :emojiGroups="emojiGroups" :btnEmojiClasses="['btn-emoji']"
                                            @emojiUnicodeAdded="session_message.text.text += $event"
                                            twemojiPath="https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/"
                                            twemojiExtension=".svg" twemojiFolder="svg"></coolpicker>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>
                        </ValidationObserver>
                    </b-container>

                    <b-container fluid v-if="session_message.type === 'image'" class="p-0">
                        <ValidationObserver ref="image">
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-alert class="align-items-center mb-3" show>{{ $t('macros.whatsapp_image_desc')
                                }}</b-alert>
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_image">{{ $t('default.image') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="4" xl="2" class="mb-3">
                                        <div class="media-upload-container d-flex justify-content-center align-items-center"
                                            :class="{ 'isInvalid': !file }">
                                            <div class="position-absolute">
                                                <div class="d-flex flex-column align-items-center text-center">
                                                    <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-if="!session_message.image.originalUrl">{{
                                                            $t('macros.attach_file') }}</span>
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-else>{{ $t('macros.change_file') }}</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">(JPEG,JPG
                                                        or PNG)</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">
                                                        (Max 5MB)</span>
                                                </div>
                                            </div>
                                            <b-form-file id="session_message_image" v-model="file"
                                                :accept="session_message_settings.image.image_accepted_types" plain
                                                @input="addFile(file, session_message_settings.image.image_size_limit)"
                                                class="media-upload-button h-100 w-100">
                                            </b-form-file>
                                        </div>
                                    </b-col>
                                    <b-col sm="4" v-if="session_message.image.originalUrl" class="mb-3">
                                        <div class="position-relative media-preview-container">
                                            <b-img fluid :src="session_message.image.originalUrl"
                                                class="media-preview" />
                                            <b-btn size="sm" class="preview-remove-button position-absolute"
                                                variant="danger" @click="removeFile">
                                                <font-awesome-icon :icon="'times'" />
                                            </b-btn>
                                        </div>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_image_caption">{{ $t('macros.caption') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8" class="position-relative">
                                        <b-form-textarea id="session_message_image_caption"
                                            v-model="session_message.image.caption" :state="valid" rows="5" max-rows="6"
                                            :maxlength="session_message_settings.image.caption_limit"></b-form-textarea>
                                        <coolpicker class="coolpicker-button" :emojiData="emojiDataAll"
                                            :emojiGroups="emojiGroups" :btnEmojiClasses="['btn-emoji']"
                                            @emojiUnicodeAdded="session_message.image.caption += $event"
                                            twemojiPath="https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/"
                                            twemojiExtension=".svg" twemojiFolder="svg"></coolpicker>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>
                        </ValidationObserver>
                    </b-container>

                    <b-container fluid v-if="session_message.type === 'file'" class="p-0">
                        <ValidationObserver ref="file">
                            <b-alert class="align-items-center mb-3" show>{{ $t('macros.whatsapp_document_desc')
                            }}</b-alert>
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_file">{{ $t('simple.file') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="4" xl="2" class="mb-3">
                                        <div class="media-upload-container d-flex justify-content-center align-items-center"
                                            :class="{ 'isInvalid': !file }">
                                            <div class="position-absolute">
                                                <div class="d-flex flex-column align-items-center text-center">
                                                    <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-if="!session_message.file.url">{{ $t('macros.attach_file')
                                                        }}</span>
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-else>{{ $t('macros.change_file') }}</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">(TXT,
                                                        PDF, DOC, PPT, XLS)</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">
                                                        (Max 100MB)</span>
                                                </div>
                                            </div>
                                            <b-form-file id="session_message_file" v-model="file"
                                                :accept="session_message_settings.file.file_accepted_types" plain
                                                @input="addFile(file, session_message_settings.file.file_size_limit)"
                                                class="media-upload-button h-100 w-100">
                                            </b-form-file>
                                        </div>
                                    </b-col>
                                    <b-col sm="4" class="mb-3" v-if="session_message.file.url">
                                        <div class="position-relative media-preview-container d-flex">
                                            <div class="align-self-center p-1">
                                                <font-awesome-icon :icon="'file'" />
                                                <span>{{ file.name }}</span>
                                            </div>
                                            <b-btn size="sm" class="preview-remove-button position-absolute"
                                                variant="danger" @click="removeFile">
                                                <font-awesome-icon :icon="'times'" />
                                            </b-btn>
                                        </div>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_file_text_area">{{ $t('macros.file_name') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_file_text_area" :state="valid"
                                            v-model="session_message.file.filename"
                                            :maxlength="session_message_settings.file.filename_limit"></b-form-input>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>
                        </ValidationObserver>
                    </b-container>

                    <b-container fluid v-if="session_message.type === 'audio'" class="p-0">
                        <ValidationObserver ref="audio">
                            <b-alert class="align-items-center mb-3" show>{{ $t('macros.whatsapp_audio_desc') }}</b-alert>
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_audio">{{ $t('simple.file') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="12" lg="4" class="mb-3">
                                        <div :class="{ 'isInvalid': !file }"
                                            class="media-upload-container d-flex justify-content-center align-items-center">
                                            <div class="position-absolute">
                                                <div class="d-flex flex-column align-items-center text-center">
                                                    <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-if="!session_message.audio.url">{{ $t('macros.attach_file')
                                                        }}</span>
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-else>{{ $t('macros.change_file') }}</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">(OGG/Opus,
                                                        AMR, 3GP, AAC, MPEG)</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">
                                                        (Max 16MB)</span>
                                                </div>
                                            </div>
                                            <b-form-file id="session_message_audio" v-model="file"
                                                :accept="session_message_settings.audio.audio_accepted_types" plain
                                                @input="addFile(file, session_message_settings.audio.audio_size_limit)"
                                                class="media-upload-button h-100 w-100">
                                            </b-form-file>
                                        </div>
                                    </b-col>
                                    <b-col sm="4" class="mb-3" v-if="session_message.audio.url">
                                        <div class="position-relative media-preview-container d-flex">
                                            <div class="align-self-center p-1">
                                                <font-awesome-icon :icon="'file-audio'" />
                                                <span>{{ file.name }}</span>
                                            </div>
                                            <b-btn size="sm" class="preview-remove-button position-absolute"
                                                variant="danger" @click="removeFile">
                                                <font-awesome-icon :icon="'times'" />
                                            </b-btn>
                                        </div>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>
                        </ValidationObserver>
                    </b-container>

                    <b-container fluid v-if="session_message.type === 'video'" class="p-0">
                        <ValidationObserver ref="video">
                            <b-alert class="align-items-center mb-3" show>{{ $t('macros.whatsapp_video_desc') }}</b-alert>
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_video">{{ $t('default.video') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="12" lg="4" class="mb-3">
                                        <div :class="{ 'isInvalid': !file }"
                                            class="media-upload-container d-flex justify-content-center align-items-center">
                                            <div class="position-absolute">
                                                <div class="d-flex flex-column align-items-center text-center">
                                                    <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-if="!session_message.video.url">{{ $t('macros.attach_file')
                                                        }}</span>
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-else>{{ $t('macros.change_file') }}</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">(MP4,
                                                        3GPP)</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">
                                                        (Max 16MB)</span>
                                                </div>
                                            </div>
                                            <b-form-file id="session_message_video" v-model="file"
                                                :accept="session_message_settings.video.video_accepted_types" plain
                                                @input="addFile(file, session_message_settings.video.video_size_limit)"
                                                class="media-upload-button h-100 w-100">
                                            </b-form-file>
                                        </div>
                                    </b-col>
                                    <b-col sm="4" v-if="session_message.video.url" class="mb-3">
                                        <div class="position-relative media-preview-container">
                                            <video :src="session_message.video.url" class="media-preview"> </video>
                                            <font-awesome-icon :icon="'play-circle'"
                                                class="preview-video-button position-absolute p-0" />
                                            <b-btn size="sm" class="preview-remove-button position-absolute"
                                                variant="danger" @click="removeFile">
                                                <font-awesome-icon :icon="'times'" />
                                            </b-btn>
                                        </div>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_video_caption">{{ $t('macros.caption') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8" class="position-relative">
                                        <b-form-textarea id="session_message_video_caption"
                                            v-model="session_message.video.caption" :state="valid" rows="5" max-rows="6"
                                            :maxlength="session_message_settings.video.caption_limit"></b-form-textarea>
                                        <coolpicker class="coolpicker-button" :emojiData="emojiDataAll"
                                            :emojiGroups="emojiGroups" :btnEmojiClasses="['btn-emoji']"
                                            @emojiUnicodeAdded="session_message.video.caption += $event"
                                            twemojiPath="https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/"
                                            twemojiExtension=".svg" twemojiFolder="svg"></coolpicker>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>
                        </ValidationObserver>
                    </b-container>

                    <b-container fluid v-if="session_message.type === 'sticker'" class="p-0">
                        <ValidationObserver ref="sticker">
                            <b-alert class="align-items-center mb-3" show>{{ $t('macros.whatsapp_sticker_desc') }}</b-alert>
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">

                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_audio">{{ $t('simple.file') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="12" lg="4"  class="mb-3">
                                        <div :class="{ 'isInvalid': !file }"
                                            class="media-upload-container d-flex justify-content-center align-items-center">
                                            <div class="position-absolute">
                                                <div class="d-flex flex-column align-items-center text-center">
                                                    <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-if="!session_message.sticker.url">{{ $t('macros.attach_file')
                                                        }}</span>
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-else>{{ $t('macros.change_file') }}</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">(
                                                        WEBP )</span>
                                                    <span
                                                        class="media-upload-inner-container text-muted font-weight-normal">
                                                        (Max 100KB)</span>
                                                </div>
                                            </div>
                                            <b-form-file id="session_message_audio" v-model="file"
                                                :accept="session_message_settings.sticker.sticker_accepted_types" plain
                                                @input="addFile(file, session_message_settings.sticker.sticker_size_limit)"
                                                class="media-upload-button h-100 w-100">
                                            </b-form-file>
                                        </div>
                                    </b-col>
                                    <b-col sm="4" v-if="session_message.sticker.url" class="mb-3">
                                        <div class="position-relative media-preview-container">
                                            <b-img fluid :src="session_message.sticker.url"
                                                class="media-preview" />
                                            <b-btn size="sm" class="preview-remove-button position-absolute"
                                                variant="danger" @click="removeFile">
                                                <font-awesome-icon :icon="'times'" />
                                            </b-btn>
                                        </div>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>
                        </ValidationObserver>
                    </b-container>

                    <b-container fluid v-if="session_message.type === 'list'" class="p-0">
                        <ValidationObserver ref="list">
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_list_header" class="font-weight-bold">
                                            {{ $t('macros.header') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <div class="position-relative">
                                            <b-form-input id="session_message_list_header"
                                                v-model="session_message.list.title" :state="valid"
                                                :maxlength="session_message_settings.list.header_limit"></b-form-input>
                                            <coolpicker class="coolpicker-button interactive-list" :emojiData="emojiDataAll"
                                                :emojiGroups="emojiGroups" :btnEmojiClasses="['btn-emoji']"
                                                @emojiUnicodeAdded="session_message.list.title += $event"
                                                twemojiPath="https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/"
                                                twemojiExtension=".svg" twemojiFolder="svg"></coolpicker>
                                        </div>
                                        <small class="text-muted"> {{ $t('macros.header_desc', {
                                            header_limit: session_message_settings.list.header_limit
                                        }) }}</small>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3" class="font-weight-bold">
                                        <label for="session_message_list_body">{{ $t('macros.body_text') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <div class="position-relative">
                                            <b-form-textarea id="session_message_list_body"
                                                v-model="session_message.list.body" :state="valid" rows="5" max-rows="6"
                                                :maxlength="session_message_settings.list.body_limit"></b-form-textarea>
                                            <coolpicker class="coolpicker-button interactive-list" :emojiData="emojiDataAll"
                                                :emojiGroups="emojiGroups" :btnEmojiClasses="['btn-emoji']"
                                                @emojiUnicodeAdded="session_message.list.body += $event"
                                                twemojiPath="https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/"
                                                twemojiExtension=".svg" twemojiFolder="svg"></coolpicker>
                                        </div>
                                        <small class="text-muted"> {{ $t('macros.body_desc', {
                                            body_limit: session_message_settings.list.body_limit
                                        }) }}</small>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-4">
                                    <b-col sm="3">
                                        <label for="session_message_list_global_buttons" class="font-weight-bold">
                                            {{ $t('macros.list_global_buttons_title') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_list_global_buttons"
                                            v-model="session_message.list.globalButtons.title" :state="valid"
                                            :maxlength="session_message_settings.list.globalButtons.title_limit"></b-form-input>

                                        <small class="text-muted"> {{ $t('macros.button_description', {
                                            button_limit: session_message_settings.list.globalButtons.title_limit
                                        }) }}</small>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <b-card class="mb-4" no-body>
                                <template #header>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div><span class="font-weight-bold">{{ $t('macros.list_items_header') }}</span>
                                        </div>
                                        <b-btn class="ml-auto" variant="danger" size="sm" @click="addSectionToList"> {{
                                            $t('macros.add_section') }}</b-btn>
                                    </div>
                                </template>
                                <div class="p-1 p-lg-3 ">

                                    <div class="text-muted mb-3"> {{ $t('macros.list_items_desc') }}</div>

                                    <b-card class="interactive-list-section mb-3" border-variant="secondary" no-body
                                        v-for=" (section, section_index) in session_message.list.items"
                                        :key="section_index">
                                        <template #header>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><span class="font-weight-bold">{{ $t('macros.section') }} - {{
                                                    section_index + 1 }}</span></div>
                                                <b-btn class="ml-auto" variant="danger" size="sm"
                                                    @click="removeSectionFromList(section_index)"
                                                    v-if="section_index !== 0"> {{ $t('macros.remove_section') }} </b-btn>
                                            </div>
                                        </template>
                                        <div class="p-1 p-lg-3 ">

                                            <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                                :name="section.title" v-if="totalListSectionNumber > 1">

                                                <b-row class="align-items-center mb-3" v-if="totalListSectionNumber > 1">
                                                    <b-col sm="3">
                                                        <label for="session_message_list_section_title"
                                                            class="font-weight-bold">
                                                            {{ $t('macros.list_section_title') }}<span
                                                                class="text-danger">*</span>:</label>
                                                    </b-col>
                                                    <b-col sm="8">
                                                        <b-form-input id="session_message_list_section_title" :state="valid"
                                                            v-model="session_message.list.items[section_index].title"
                                                            :maxlength="session_message_settings.list.items.title_limit"></b-form-input>
                                                    </b-col>
                                                </b-row>
                                            </ValidationProvider>

                                            <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                                :name="section.title" v-if="totalListSectionNumber > 1">

                                                <b-row class="align-items-center mb-3" v-if="totalListSectionNumber > 1">
                                                    <b-col sm="3">
                                                        <label for="session_message_list_section_subtitle"
                                                            class="font-weight-bold">
                                                            {{ $t('macros.list_section_subtitle') }}<span
                                                                class="text-danger">*</span>:</label>
                                                    </b-col>
                                                    <b-col sm="8">
                                                        <b-form-input id="session_message_list_section_subtitle"
                                                            :state="valid"
                                                            v-model="session_message.list.items[section_index].subtitle"
                                                            :maxlength="session_message_settings.list.items.subtitle_limit"></b-form-input>
                                                    </b-col>
                                                </b-row>
                                            </ValidationProvider>

                                            <b-card class="mb-3 position-relative interactive-list-item" bg-variant="light"
                                                border-variant="info" v-for="(option, option_index) in section.options"
                                                :key="option_index">
                                                <b-container fluid>
                                                    <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                                        :name="option.title">
                                                        <b-row class="align-items-center mb-3">
                                                            <b-col sm="3">
                                                                <label for="session_message_list_item_title"
                                                                    class="font-weight-bold">
                                                                    {{ $t('macros.list_item_title') }}<span
                                                                        class="text-danger">*</span>:</label>
                                                            </b-col>
                                                            <b-col sm="8">
                                                                <b-form-input id="session_message_list_item_title"
                                                                    :state="valid"
                                                                    v-model="session_message.list.items[section_index].options[option_index].title"
                                                                    :maxlength="session_message_settings.list.items.options.title_limit"></b-form-input>
                                                            </b-col>
                                                        </b-row>
                                                    </ValidationProvider>

                                                    <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                                        :name="option.title">
                                                        <b-row class="align-items-center mb-3">
                                                            <b-col sm="3">
                                                                <label for="session_message_list_item_desc"
                                                                    class="font-weight-bold">
                                                                    {{ $t('macros.list_item_desc') }}<span
                                                                        class="text-danger">*</span>:</label>
                                                            </b-col>
                                                            <b-col sm="8">
                                                                <b-form-input id="session_message_list_item_desc"
                                                                    :state="valid"
                                                                    v-model="session_message.list.items[section_index].options[option_index].description"
                                                                    :maxlength="session_message_settings.list.items.options.description_limit"></b-form-input>
                                                            </b-col>
                                                        </b-row>
                                                    </ValidationProvider>


                                                </b-container>
                                                <b-btn size="sm" class="list-item-remove-button position-absolute"
                                                    variant="danger" v-if="option_index !== 0"
                                                    @click="removeItemFromSection(section_index, option_index)">
                                                    <font-awesome-icon :icon="'trash-alt'" />
                                                </b-btn>
                                            </b-card>

                                            <div class="d-flex justify-content-end">
                                                <b-button variant="info" pill class="px-md-5"
                                                    @click="addItemToSection(section_index)"><font-awesome-icon
                                                        :icon="'plus'" class="mr-1" />{{ $t('macros.add_list_item')
                                                        }}</b-button>
                                            </div>
                                        </div>
                                    </b-card>
                                </div>
                            </b-card>
                        </ValidationObserver>
                    </b-container>

                    <b-container fluid v-if="session_message.type === 'quick_reply'" class="p-0">
                        <b-container fluid v-if="session_message.quick_reply.content.type === 'text'" class="p-0">
                            <ValidationObserver ref="quick_reply_text">
                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="session_message_quick_reply_text_header" class="font-weight-bold">
                                                {{ $t('macros.header') }}<span class="text-danger">*</span>:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <b-form-input id="session_message_quick_reply_text_header" :state="valid"
                                                v-model="session_message.quick_reply.content.header"
                                                :maxlength="session_message_settings.quick_reply.header_limit"></b-form-input>
                                            <small class="text-muted"> {{ $t('macros.header_desc', {
                                                header_limit: session_message_settings.quick_reply.header_limit
                                            }) }}</small>
                                        </b-col>
                                    </b-row>
                                </ValidationProvider>
                            </ValidationObserver>
                        </b-container>


                        <b-container fluid v-if="session_message.quick_reply.content.type === 'image'" class="p-0">
                            <ValidationObserver ref="quick_reply_image">
                                <b-alert class="align-items-center mb-3" show>{{ $t('macros.whatsapp_image_desc')
                                }}</b-alert>
                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="session_message_quick_reply_image">{{ $t('default.image') }}<span
                                                    class="text-danger">*</span>:</label>
                                        </b-col>
                                        <b-col sm="12" lg="4" class="mb-3">
                                            <div :class="{ 'isInvalid': !file }"
                                                class="media-upload-container d-flex justify-content-center align-items-center">
                                                <div class="position-absolute">
                                                    <div class="d-flex flex-column align-items-center text-center">
                                                        <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-if="!session_message.quick_reply.content.url">{{
                                                                $t('macros.attach_file') }}</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-else>{{ $t('macros.change_file') }}</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal">(JPEG,JPG
                                                            or PNG)</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal">
                                                            (Max 5MB)</span>
                                                    </div>
                                                </div>
                                                <b-form-file id="session_message_quick_reply_image" v-model="file"
                                                    :accept="session_message_settings.image.image_accepted_types" plain
                                                    @input="addFile(file, session_message_settings.image.image_size_limit)"
                                                    class="media-upload-button h-100 w-100">
                                                </b-form-file>
                                            </div>
                                        </b-col>
                                        <b-col sm="4" v-if="session_message.quick_reply.content.url" class="mb-3">
                                            <div class="position-relative media-preview-container">
                                                <b-img fluid :src="session_message.quick_reply.content.url"
                                                    class="media-preview" />
                                                <b-btn size="sm" class="preview-remove-button position-absolute"
                                                    variant="danger" @click="removeFile">
                                                    <font-awesome-icon :icon="'times'" />
                                                </b-btn>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </ValidationProvider>
                            </ValidationObserver>
                        </b-container>

                        <b-container fluid v-if="session_message.quick_reply.content.type === 'video'" class="p-0">
                            <ValidationObserver ref="quick_reply_video">
                                <b-alert class="align-items-center mb-3" show>{{ $t('macros.whatsapp_video_desc')
                                }}</b-alert>
                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="session_message_quick_reply_video">{{ $t('default.video') }}<span
                                                    class="text-danger">*</span>:</label>
                                        </b-col>
                                        <b-col sm="12" lg="4" class="mb-3">
                                            <div :class="{ 'isInvalid': !file }"
                                                class="media-upload-container d-flex justify-content-center align-items-center">
                                                <div class="position-absolute">
                                                    <div class="d-flex flex-column align-items-center text-center">
                                                        <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-if="!session_message.quick_reply.content.url">{{
                                                                $t('macros.attach_file') }}</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-else>{{ $t('macros.change_file') }}</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal">(MP4,
                                                            3GPP)</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal">
                                                            (Max 16MB)</span>
                                                    </div>
                                                </div>
                                                <b-form-file id="session_message_quick_reply_video" v-model="file"
                                                    :accept="session_message_settings.video.video_accepted_types" plain
                                                    @input="addFile(file, session_message_settings.video.video_size_limit)"
                                                    class="media-upload-button h-100 w-100">
                                                </b-form-file>
                                            </div>
                                        </b-col>
                                        <b-col sm="4" v-if="session_message.quick_reply.content.url" class="mb-3">
                                            <div class="position-relative media-preview-container">
                                                <video :src="session_message.quick_reply.content.url"
                                                    class="media-preview"> </video>
                                                <font-awesome-icon :icon="'play-circle'"
                                                    class="preview-video-button position-absolute p-0" />
                                                <b-btn size="sm" class="preview-remove-button position-absolute"
                                                    variant="danger" @click="removeFile">
                                                    <font-awesome-icon :icon="'times'" />
                                                </b-btn>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </ValidationProvider>
                            </ValidationObserver>
                        </b-container>

                        <b-container fluid v-if="session_message.quick_reply.content.type === 'file'" class="p-0">
                            <ValidationObserver ref="quick_reply_file">

                                <b-alert class="align-items-center mb-3" show>{{ $t('macros.whatsapp_document_desc')
                                }}</b-alert>
                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="session_message_quick_reply_file">{{ $t('simple.file') }}<span
                                                    class="text-danger">*</span>:</label>
                                        </b-col>
                                        <b-col sm="12" lg="4" class="mb-3">
                                            <div :class="{ 'isInvalid': !file }"
                                                class="media-upload-container d-flex justify-content-center align-items-center">
                                                <div class="position-absolute">
                                                    <div class="d-flex flex-column align-items-center text-center">
                                                        <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-if="!session_message.quick_reply.content.url">{{
                                                                $t('macros.attach_file') }}</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-else>{{ $t('macros.change_file') }}</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal">(TXT,
                                                            PDF, DOC, PPT, XLS)</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal">
                                                            (Max 100MB)</span>
                                                    </div>
                                                </div>
                                                <b-form-file id="session_message_quick_reply_file" v-model="file"
                                                    :accept="session_message_settings.file.file_accepted_types" plain
                                                    @input="addFile(file, session_message_settings.file.file_size_limit)"
                                                    class="media-upload-button h-100 w-100">
                                                </b-form-file>
                                            </div>
                                        </b-col>
                                        <b-col sm="4" class="mb-3" v-if="session_message.quick_reply.content.url">
                                            <div class="position-relative media-preview-container d-flex">
                                                <div class="align-self-center p-1">
                                                    <font-awesome-icon :icon="'file'" />
                                                    <span>{{ file.name }}</span>
                                                </div>
                                                <b-btn size="sm" class="preview-remove-button position-absolute"
                                                    variant="danger" @click="removeFile">
                                                    <font-awesome-icon :icon="'times'" />
                                                </b-btn>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </ValidationProvider>

                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="session_message_quick_reply_filename">{{ $t('macros.file_name')
                                            }}<span class="text-danger">*</span>:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <b-form-input id="session_message_quick_reply_filename" :state="valid"
                                                v-model="session_message.quick_reply.content.filename"
                                                :maxlength="session_message_settings.file.filename_limit"></b-form-input>
                                        </b-col>
                                    </b-row>
                                </ValidationProvider>
                            </ValidationObserver>
                        </b-container>

                        <ValidationObserver ref="quick_reply">
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3" class="font-weight-bold">
                                        <label for="session_message_quick_reply_body">{{ $t('macros.body_text') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <div class="position-relative">
                                            <b-form-textarea id="session_message_quick_reply_body" :state="valid"
                                                v-model="session_message.quick_reply.content.text" rows="5" max-rows="6"
                                                :maxlength="session_message_settings.quick_reply.body_limit"></b-form-textarea>
                                        </div>
                                        <small class="text-muted"> {{ $t('macros.body_desc', {
                                            body_limit: session_message_settings.quick_reply.body_limit
                                        }) }}</small>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">

                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_quick_reply_caption">{{ $t('macros.caption') }}<span
                                                class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_quick_reply_text_header" :state="valid"
                                            v-model="session_message.quick_reply.caption"
                                            :maxlength="session_message_settings.quick_reply.caption_limit"></b-form-input>
                                        <small class="text-muted"> {{ $t('macros.caption_desc', {
                                            caption_limit: session_message_settings.quick_reply.caption_limit
                                        }) }}</small>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <b-card class="mb-4">
                                <template #header>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div><span class="font-weight-bold">{{ $t('macros.quick_reply_buttons') }}</span>
                                        </div>
                                        <b-btn class="ml-auto" variant="danger" size="sm" @click="addQuickReplyButton"> {{
                                            $t('macros.add_quick_reply_button') }}</b-btn>
                                    </div>
                                </template>

                                <div class="text-muted mb-3"> {{ $t('macros.quick_reply_buttons_desc') }}</div>

                                <b-card class="interactive-list-section mb-3 position-relative" bg-variant="light"
                                    border-variant="info"
                                    v-for=" (button, button_index) in session_message.quick_reply.options"
                                    :key="button_index">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :name="button.title">
                                        <b-row class="align-items-center mb-3">
                                            <b-col sm="3">
                                                <label for="session_message_quick_reply_button_title"
                                                    class="font-weight-bold">
                                                    {{ $t('macros.quick_reply_button_title') }}<span
                                                        class="text-danger">*</span>:</label>
                                            </b-col>
                                            <b-col sm="8">
                                                <b-form-input id="session_message_quick_reply_button_title" :state="valid"
                                                    v-model="session_message.quick_reply.options[button_index].title"
                                                    :maxlength="session_message_settings.quick_reply.options.title_limit"></b-form-input>
                                                <small class="text-muted"> {{ $t('macros.button_description', {
                                                    button_limit: session_message_settings.quick_reply.options.title_limit
                                                }) }}</small>
                                            </b-col>
                                        </b-row>
                                    </ValidationProvider>
                                    <b-btn size="sm" class="list-item-remove-button position-absolute" variant="danger"
                                        v-if="button_index !== 0" @click="removeQuickReplybutton(button_index)">
                                        <font-awesome-icon :icon="'trash-alt'" />
                                    </b-btn>

                                </b-card>

                            </b-card>
                        </ValidationObserver>

                    </b-container>

                    <b-container fluid v-if="session_message.type === 'location'" class="p-0">
                        <ValidationObserver ref="location">
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="location" class="font-weight-bold">
                                        {{ $t('customer.location') }}<span class="text-danger">*</span>:</label>
                                </b-col>
                                <b-col sm="6">
                                    <gmap-autocomplete @place_changed="setPlace" ref="autocomplete"
                                        :options="{ fields: ['geometry', 'formatted_address', 'address_components', 'name'] }"
                                        :class="locationClasses" class="form-control">
                                    </gmap-autocomplete>
                                </b-col>
                                <b-col sm="2" class="my-1">
                                    <b-button variant="danger" @click="clearAddress">{{ $t('simple.clear') }} </b-button>
                                </b-col>
                            </b-row>
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_location_name" class="font-weight-bold">
                                            {{ $t('default.name') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_location_name" disabled :state="valid"
                                            v-model="session_message.location.name"></b-form-input>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="session_message_location_address" class="font-weight-bold">
                                            {{ $t('customer.address') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_location_address" disabled :state="valid"
                                            v-model="session_message.location.address"></b-form-input>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>
                        </ValidationObserver>
                    </b-container>

                    <b-container fluid v-if="session_message.type === 'contact'" class="p-0">
                        <ValidationObserver ref="contact">
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">

                                <b-row class="align-items-center mb-4 mx-2">
                                    <b-col sm="3">
                                        <label for="session_message_contact_fname" class="font-weight-bold">
                                            {{ $t('customer.name') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_contact_fname" :state="valid"
                                            v-model="session_message.contact.name.firstName"></b-form-input>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-4 mx-2">
                                    <b-col sm="3">
                                        <label for="session_message_contact_lname" class="font-weight-bold">
                                            {{ $t('customer.surname') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_contact_lname" :state="valid"
                                            v-model="session_message.contact.name.lastName"></b-form-input>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">

                                <b-row class="align-items-center mb-4 mx-2">
                                    <b-col sm="3">
                                        <label for="session_message_contact_birthday" class="font-weight-bold">
                                            {{ $t('macros.birthday') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-datepicker id="session_message_contact_birthday" :state="valid"
                                            v-model="session_message.contact.birthday"></b-form-datepicker>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-4 mx-2">
                                    <b-col sm="3">
                                        <label for="session_message_contact_company" class="font-weight-bold">
                                            {{ $t('customer.company') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_contact_company" :state="valid"
                                            v-model="session_message.contact.org.company"></b-form-input>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-4 mx-2">
                                    <b-col sm="3">
                                        <label for="session_message_contact_department" class="font-weight-bold">
                                            {{ $t('customer.department') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_contact_department" :state="valid"
                                            v-model="session_message.contact.org.department"></b-form-input>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-4 mx-2">
                                    <b-col sm="3">
                                        <label for="session_message_contact_company_title" class="font-weight-bold">
                                            {{ $t('macros.company_title') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input id="session_message_contact_company_title" :state="valid"
                                            v-model="session_message.contact.org.title"></b-form-input>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>

                            <b-card class="mb-3 position-relative" border-variant="light" no-body>
                                <template #header>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div><span class="font-weight-bold">{{ $t('customer.addresses') }}:</span></div>
                                        <b-btn class="ml-auto" variant="danger" size="sm" @click="addAddress">{{
                                            $t('simple.add') }} {{ $t('customer.address') }} </b-btn>
                                    </div>
                                </template>

                                <div class="p-1 p-lg-3">
                                    <b-card class="mb-3 position-relative interactive-list-item" bg-variant="light"
                                        border-variant="info"
                                        v-for="(address, address_index) in session_message.contact.addresses"
                                        :key="address_index">
                                        <template #header>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <b-btn size="sm" class="list-item-remove-button position-absolute"
                                                    variant="danger" v-if="address_index !== 0"
                                                    @click="removeAddress(address_index)">
                                                    <font-awesome-icon :icon="'trash-alt'" />
                                                </b-btn>
                                            </div>
                                        </template>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="address.street">

                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_city" class="font-weight-bold">
                                                        {{ $t('customer.city') }}:<span
                                                            class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_city" :state="valid"
                                                        v-model="address.city">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>
                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="address.street">

                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_country" class="font-weight-bold">
                                                        {{ $t('customer.country') }}:<span
                                                            class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_country" :state="valid"
                                                        v-model="address.country">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="address.street">

                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_countryCode"
                                                        class="font-weight-bold">
                                                        {{ $t('customer.country_code') }}:<span
                                                            class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_countryCode" :state="valid"
                                                        v-model="address.countryCode">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="address.street">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_state" class="font-weight-bold">
                                                        {{ $t('customer.state_code') }}:<span
                                                            class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_state" :state="valid"
                                                        v-model="address.state">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="address.street">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_street" class="font-weight-bold">
                                                        {{ $t('customer.street') }}:<span
                                                            class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_street" :state="valid"
                                                        v-model="address.street">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="address.street">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_type" class="font-weight-bold">
                                                        {{ $t('simple.type') }}:<span class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_type"
                                                        :placeholder="$t('customer.work')" :state="valid"
                                                        v-model="address.type">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="address.street">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_zip" class="font-weight-bold">
                                                        {{ $t('customer.postal_code') }}:<span
                                                            class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_zip" :state="valid"
                                                        v-model="address.zip">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>
                                    </b-card>
                                </div>
                            </b-card>


                            <b-card class="mb-3 position-relative" border-variant="light" no-body>
                                <template #header>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div><span class="font-weight-bold">{{ $t('customer.emails') }}:</span></div>
                                        <b-btn class="ml-auto" variant="danger" size="sm" @click="addEmail">{{
                                            $t('simple.add') }} {{ $t('customer.email') }} </b-btn>
                                    </div>
                                </template>

                                <div class="p-1 p-lg-3">
                                    <b-card class="mb-3 position-relative" bg-variant="light" border-variant="info"
                                        v-for="(email, email_index) in session_message.contact.emails" :key="email_index">

                                        <template #header>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <b-btn size="sm" class="list-item-remove-button position-absolute"
                                                    variant="danger" v-if="email_index !== 0"
                                                    @click="removeEmail(email_index)">
                                                    <font-awesome-icon :icon="'trash-alt'" />
                                                </b-btn>
                                            </div>
                                        </template>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="email.email">

                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_email" class="font-weight-bold">
                                                        {{ $t('customer.email') }}:<span
                                                            class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_email" :state="valid"
                                                        v-model="email.email">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="email.email">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_email_type"
                                                        class="font-weight-bold">
                                                        {{ $t('simple.type') }}:<span class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_email_type"
                                                        :placeholder="$t('customer.work')" :state="valid"
                                                        v-model="email.type">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>
                                    </b-card>
                                </div>
                            </b-card>

                            <b-card class="mb-3 position-relative" border-variant="light" no-body>
                                <template #header>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div><span class="font-weight-bold">{{ $t('customer.phones') }}:</span></div>
                                        <b-btn class="ml-auto" variant="danger" size="sm" @click="addPhoneNumber">{{
                                            $t('simple.add') }} {{ $t('customer.phone') }} </b-btn>
                                    </div>
                                </template>

                                <div class="p-1 p-lg-3">
                                    <b-card class="mb-3 position-relative" bg-variant="light" border-variant="info"
                                        v-for="(phone, phone_index) in session_message.contact.phones" :key="phone_index">

                                        <template #header>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <b-btn size="sm" class="list-item-remove-button position-absolute"
                                                    variant="danger" v-if="phone_index !== 0"
                                                    @click="removePhoneNumber(phone_index)">
                                                    <font-awesome-icon :icon="'trash-alt'" />
                                                </b-btn>
                                            </div>
                                        </template>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="phone.phone">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_phone" class="font-weight-bold">
                                                        {{ $t('customer.phone') }}:<span
                                                            class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_phone" :state="valid"
                                                        v-model="phone.phone">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                            :name="phone.phone">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_email_type"
                                                        class="font-weight-bold">
                                                        {{ $t('simple.type') }}:<span class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_email_type"
                                                        :placeholder="$t('customer.work')" :state="valid"
                                                        v-model="phone.type">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>
                                    </b-card>
                                </div>
                            </b-card>

                            <b-card class="mb-3 position-relative" border-variant="light" no-body>
                                <template #header>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div><span class="font-weight-bold">{{ $t('customer.urls') }}:</span></div>
                                        <b-btn class="ml-auto" variant="danger" size="sm" @click="addContactUrl">{{
                                            $t('simple.add') }} {{ $t('customer.url') }} </b-btn>
                                    </div>
                                </template>

                                <div class="p-1 p-lg-3">
                                    <b-card class="mb-3 position-relative" bg-variant="light" border-variant="info"
                                        v-for="(url, url_index) in session_message.contact.urls" :key="url_index">

                                        <template #header>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <b-btn size="sm" class="list-item-remove-button position-absolute"
                                                    variant="danger" v-if="url_index !== 0"
                                                    @click="removeContactUrl(url_index)">
                                                    <font-awesome-icon :icon="'trash-alt'" />
                                                </b-btn>
                                            </div>
                                        </template>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }" :name="url.url">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_url" class="font-weight-bold">
                                                        {{ $t('customer.url') }}:<span class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_url" :state="valid"
                                                        v-model="url.url">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors, valid }" :name="url.url">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="session_message_contact_url_type" class="font-weight-bold">
                                                        {{ $t('simple.type') }}:<span class="text-danger">*</span>:</label>
                                                </b-col>
                                                <b-col sm="8">
                                                    <b-form-input id="session_message_contact_url_type"
                                                        :placeholder="$t('customer.work')" :state="valid"
                                                        v-model="url.type">
                                                    </b-form-input>
                                                </b-col>
                                            </b-row>
                                        </ValidationProvider>
                                    </b-card>
                                </div>
                            </b-card>

                        </ValidationObserver>
                    </b-container>



                    <b-row class="align-items-center justify-content-between mx-2 mt-3">
                        <b-button variant="danger" to="/settings/session-messages">{{ $t('default.cancel') }} </b-button>
                        <b-button variant="success" type="submit" @click.prevent="saveSessionMessage">{{ $t('simple.save')
                        }}</b-button>
                    </b-row>

                </b-card>

            </b-col>
        </b-row>

        <b-modal id="custom_variable_modal" ref="custom_variable_modal" :title="$t('macros.custom')"
            @ok.prevent="checkCustomVariable" @shown="clearModal" centered>
            <b-form-group id="name" :label="$t('default.name')">
                <ValidationObserver ref="custom_variable">
                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                        <b-form-input type="text" placeholder="customer.name" :state="valid"
                            v-model="custom_variable_name" />
                    </ValidationProvider>
                </ValidationObserver>
            </b-form-group>
        </b-modal>

    </b-container>
</template>
<script>

import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { getTicketStatuses } from '../../../utils/ticket.js';
import Treeselect from '@riophae/vue-treeselect';

import {
    TwemojiPicker
} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';




export default {

    // TODO: For editing existing session_message
    props: ['session_message_item'],


    components: {
        'coolpicker': TwemojiPicker,
        ValidationObserver,
        ValidationProvider,
        Treeselect,
    },

    mounted() {
        // this.updateTicketData();
    },

    created() {

        this.updateTicketData();

        // TODO: For editing existing session message, initaliaze session_message data accordingly
    },

    updated() {
    },

    data() {
        return {


            file: null,
            custom_variable_name: '',
            showTicketDetails: false,
            ticket_statuses: getTicketStatuses,

            session_message: {
                name: '',
                type: null,
                session_message_custom_variables: [],
                session_message_default_variables: [
                    { name: 'customer.name' },
                ],

                text: {
                    text: '',
                },

                image: {
                    originalUrl: '',
                    previewUrl: '',
                    caption: '',

                },

                file: {
                    url: '',
                    filename: '',
                },

                audio: {
                    url: '',
                },

                video: {
                    url: '',
                    caption: '',
                },

                sticker: {
                    url: '',
                },

                list: {
                    title: '',
                    body: '',
                    msgid: 'list',
                    globalButtons: [
                        {
                            type: 'text',
                            title: '',
                        }
                    ],
                    items: [
                        {
                            title: '',
                            subtitle: '',
                            options: [
                                {
                                    type: 'text',
                                    title: '',
                                    description: '',
                                    postbackText: 'postback',
                                },
                            ],
                        },
                    ],
                },

                quick_reply: {
                    msgid: '',
                    content: {
                        type: null,
                        text: '',
                        caption: '',
                        header: '', // For Text type
                        url: '',  //For Image, Video and File type
                        filename: '', //For file type
                    },
                    options: [
                        {
                            type: '',
                            title: '',
                            postbackText: 'postback',
                        },
                    ],
                },

                location: {
                    type: 'location',
                    longitude: '',
                    latitude: '',
                    name: '',
                    address: '',
                },

                contact: {
                    addresses: [
                        {
                            city: '',
                            country: '',
                            countryCode: '',
                            state: '',
                            street: '',
                            type: '',
                            zip: '',
                        },
                    ],
                    birthday: '',
                    emails: [
                        {
                            email: '',
                            type: '',
                        }
                    ],
                    name: {
                        firstName: '',
                        formattedName: '',
                        lastName: '',
                    },
                    org: {
                        company: '',
                        department: '',
                        title: '',
                    },
                    phones: [
                        {
                            phone: '',
                            type: '',
                        }
                    ],
                    urls: [
                        {
                            url: '',
                            type: '',
                        }
                    ]
                },

                ticket: {
                    title: '',
                    description: '',
                    status: 1,
                    categories: [],
                    tags: [],
                    users: [],
                    teams: [],
                }

            },


        };
    },

    methods: {

        ...mapActions('Ticket', [
            'updateTicketData',
        ]),

        resetTicketDetails() {

            this.session_message.ticket = {
                title: '',
                description: '',
                status: 1,
                categories: [],
                tags: [],
                users: [],
                teams: [],
            }
        },


        normalizer: function (node) {
            return {
                id: node.id,
                label: node.name,
                children: node.children,
            }
        },

        resetSessionMessage() {
            //TODO: check

            this.file = null;


            this.session_message = {
                name: this.session_message.name,
                type: this.session_message.type,
                session_message_custom_variables: [],
                session_message_default_variables: this.session_message.session_message_default_variables,

                text: {
                    text: '',
                },

                image: {
                    originalUrl: '',
                    previewUrl: '',
                    caption: '',

                },

                file: {
                    url: '',
                    filename: '',
                },

                audio: {
                    url: '',
                },

                video: {
                    url: '',
                    caption: '',
                },

                sticker: {
                    url: '',
                },

                list: {
                    title: '',
                    body: '',
                    msgid: 'list',
                    globalButtons: [
                        {
                            type: 'text',
                            title: '',
                        }
                    ],
                    items: [
                        {
                            title: '',
                            subtitle: '',
                            options: [
                                {
                                    type: 'text',
                                    title: '',
                                    description: '',
                                    postbackText: '',
                                },
                            ],
                        },
                    ],
                },


                quick_reply: {
                    msgid: '',
                    content: {
                        type: this.session_message.quick_reply.content.type,
                        text: '',
                        caption: '',
                        header: '', // For Text type
                        url: '',  //For Image, Video and File type
                        filename: '', //For file type
                    },
                    options: [
                        {
                            type: '',
                            title: '',
                            postbackText: 'postback',
                        },
                    ],
                },

                location: {
                    type: 'location',
                    longitude: '',
                    latitude: '',
                    name: '',
                    address: '',
                },
                contact: {
                    addresses: [
                        {
                            city: '',
                            country: '',
                            countryCode: '',
                            state: '',
                            street: '',
                            type: '',
                            zip: '',
                        },
                    ],
                    birthday: '',
                    emails: [
                        {
                            email: '',
                            type: '',
                        }
                    ],
                    name: {
                        firstName: '',
                        formattedName: '',
                        lastName: '',
                    },
                    org: {
                        company: '',
                        department: '',
                        title: '',
                    },
                    phones: [
                        {
                            phone: '',
                            type: '',
                        }
                    ],
                    urls: [
                        {
                            url: '',
                            type: '',
                        }
                    ]
                },

                ticket: {
                    title: '',
                    description: '',
                    status: 1,
                    categories: [],
                    tags: [],
                    users: [],
                    teams: [],
                }

            };

        },

        setPlace(place) {
            if (!place) return
            this.session_message.location.longitude = place?.geometry?.location.lng();
            this.session_message.location.latitude = place?.geometry?.location.lat();
            this.session_message.location.name = place?.name;
            this.session_message.location.address = place?.formatted_address;


        },

        clearAddress() {
            this.$refs.autocomplete.$el.value = '';
            this.session_message.location.longitude = ''
            this.session_message.location.latitude = ''
            this.session_message.location.name = '';
            this.session_message.location.address = '';
        },

        async saveSessionMessage() {

            const isValid1 = await this.$refs.main.validate();
            if (this.session_message.type !== 'quick_reply') {
                const isValid2 = await this.$refs[this.session_message.type].validate();

                if (!isValid1 || !isValid2) return

            } else {
                const isValid2 = await this.$refs[this.session_message.type].validate();
                const isValid3 = await this.$refs[this.session_message.type + '_' + this.session_message.quick_reply.content.type].validate();

                if (!isValid1 || !isValid2 || !isValid3) return

            }

            if (this.session_message.type !== 'contact') {
                this.session_message.contact.name.formattedName = `${this.session_message.contact.name.firstName} ${this.session_message.contact.name.lastName}`
            }

            alert("Form submitted!");
            console.log(this.session_message)
            // TODO: API request to add 
            // TODO: If successful go back to main session_message page
            this.$router.push({ path: '/settings/session-messages' });
        },

        addFile(file, limit) {
            if (file) {
                if (this.checkFileSize(file, limit)) {
                    let url = URL.createObjectURL(file);
                    this.setMessageURL(url, this.session_message.type);
                } else {
                    Vue.toasted.error(this.$t('macros.size_limit_alert', {
                        size_limit: limit,
                    }));
                    this.setMessageURL('', this.session_message.type);
                    this.file = null;
                }
            }
        },

        setMessageURL(url, type) {
            switch (type) {
                case 'image':
                    this.session_message.image.originalUrl = url;
                    this.session_message.image.previewUrl = url;
                    break;
                case 'file':
                    this.session_message.file.url = url;
                    break;
                case 'quick_reply':
                    this.session_message.quick_reply.content.url = url
                    break;
                case 'audio':
                    this.session_message.audio.url = url;
                    break;
                case 'video':
                    this.session_message.video.url = url;
                    break;
                case 'sticker':
                    this.session_message.sticker.url = url;
                    break;
            }
        },

        checkFileSize(file, size_limit) {
            if (((file.size / 1024) / 1024).toFixed(4) > size_limit) {
                return false
            }

            return true

        },

        removeFile() {
            this.file = null;
            this.setMessageURL('', this.session_message.type)
        },




        addSectionToList() {
            if (this.totalListSectionNumber < this.session_message_settings.list.items.options.total_option_limit) {
                this.session_message.list.items.push({
                    title: '',
                    subtitle: '',
                    options: [
                        {
                            type: 'text',
                            title: '',
                            description: '',
                            postbackText: '',
                        }
                    ],
                })
            } else {
                Vue.toasted.error(this.$t('macros.item_limit_alert', {
                    item_limit: this.session_message_settings.list.items.options.total_option_limit
                }));
            }


        },

        addItemToSection(index) {
            if (this.totalListOptionNumber < this.session_message_settings.list.items.options.total_option_limit) {
                this.session_message.list.items[index].options.push({
                    type: 'text',
                    title: '',
                    description: '',
                    postbackText: '',

                })
            } else {
                Vue.toasted.error(this.$t('macros.item_limit_alert', {
                    item_limit: this.session_message_settings.list.items.options.total_option_limit
                }));
            }
        },

        removeItemFromSection(section_index, option_index) {
            this.session_message.list.items[section_index].options.splice(option_index, 1);
        },

        removeSectionFromList(section_index) {
            this.session_message.list.items.splice(section_index, 1);
            if (this.totalListSectionNumber === 1) {
                this.session_message.list.items[0].title = '';
                this.session_message.list.items[0].subtitle = '';
            }
        },

        addQuickReplyButton() {
            if (this.totalQuickReplyButtonsNumber < this.session_message_settings.quick_reply.options.total_option_limit) {
                this.session_message.quick_reply.options.push({
                    type: "text",
                    title: '',
                })
            } else {
                Vue.toasted.error(this.$t('macros.item_limit_alert', {
                    item_limit: this.session_message_settings.quick_reply.options.total_option_limit
                }));
            }

        },

        removeQuickReplybutton(button_index) {
            this.session_message.quick_reply.options.splice(button_index, 1);
        },

        addAddress() {
            this.session_message.contact.addresses.push({
                city: '',
                country: '',
                countryCode: '',
                state: '',
                street: '',
                type: '',
                zip: '',
            })
        },

        removeAddress(address_index) {
            this.session_message.contact.addresses.splice(address_index, 1);
        },

        addEmail() {
            this.session_message.contact.emails.push(
                {
                    email: '',
                    type: '',
                }
            )
        },

        removeEmail(index) {
            this.session_message.contact.emails.splice(index, 1);
        },

        addPhoneNumber() {
            this.session_message.contact.phones.push(
                {
                    phone: '',
                    type: '',
                }
            )
        },

        removePhoneNumber(index) {
            this.session_message.contact.phones.splice(index, 1);
        },

        addContactUrl() {
            this.session_message.contact.urls.push(
                {
                    url: '',
                    type: '',
                }
            )
        },

        removeContactUrl(index) {
            this.session_message.contact.urls.splice(index, 1);
        },

        async checkCustomVariable() {
            const isModalValid = await this.$refs.custom_variable.validate();

            if (!isModalValid) return

            const session_message_variables = [...this.session_message.session_message_custom_variables, ...this.session_message.session_message_default_variables]

            let variable = session_message_variables.find(ele => ele.name === this.custom_variable_name);

            if (!variable) {
                this.addToCustomVariables();
            } else {
                Vue.toasted.error(this.$t('macros.check_duplicate'))
            }
        },

        addToCustomVariables() {
            this.session_message.session_message_custom_variables.push({
                name: this.custom_variable_name
            })

            this.clearModal();

            this.$nextTick(() => this.$refs.custom_variable_modal.hide())
        },

        clearModal() {
            this.custom_variable_name = ''
        },

        removeCustomVariable(index) {
            this.session_message.session_message_custom_variables.splice(index, 1);
        }


    },

    computed: {

        ...mapGetters({
            session_message_settings: 'Templates/getSessionMessageSettings',
            session_message_type_options: 'Templates/getSessionMessageTypeOptions',
            quick_reply_type_options: 'Templates/getQuickReplyTypeOptions',

            categories: 'Ticket/getCategories',
            tags: 'Ticket/getTags',
            users: 'Ticket/getUsers',
            teams: 'Ticket/getTeams',

        }),

        // Emoji data
        emojiDataAll() {
            return EmojiAllData;
        },
        // Emoji Groups
        emojiGroups() {
            return EmojiGroups;
        },


        isSessionMessageTypeChosen() {
            if (this.session_message.type === null ||
                (this.session_message.type === 'quick_reply' && this.session_message.quick_reply.content.type === null)) {
                return false
            } else {
                return true
            }
        },

        totalListOptionNumber() {
            const options = this.session_message.list.items.reduce((acc, item) => {
                acc.push(item.options);
                return acc.flat()
            }, [])

            return options.length;
        },

        totalListSectionNumber() {
            return this.session_message.list.items.length;
        },

        totalQuickReplyButtonsNumber() {
            return this.session_message.quick_reply.options.length;
        },

        locationClasses() {
            return {
                'is-valid': !!this.session_message.location.longitude,
                'is-invalid': !!!this.session_message.location.longitude,
            }
        },

        isSessionMessageItemExists() {
            return this.session_message_item === undefined ? false : true;
        }

    },


}
</script>