<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.add')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="add">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_add">


                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="name"
                            name="name"
                            v-validate="'required|max:15'"
                            v-model="name"
                        >

                        <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="parent_id">{{ $t('simple.parent') }}</label>

                        <treeselect

                            v-model="parent_id"
                            :multiple="false"
                            :placeholder="$t('ticket.category_parent_select_desc')"
                            :loadOptions="getCategories"
                            :normalizer="normalizer"
                            name="parent_id"
                            :options="categories"
                        />
                        
                    </div>

                </form>

            </v-wait>

        </div>

        <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

            <b-button @click="validate_add()" variant="primary">{{ $t('simple.save') }}</b-button>
            
        </div>


        </div>
      </div>
    </div>

</template>
<script>
    import Treeselect from '@riophae/vue-treeselect'

    export default {

        components: { Treeselect },

        mounted: function () {
            // Component mounted
            console.log('Category Add Modal mounted');
        },

        destroyed() {
            // Component destroyed
            console.log('Category Add Modal destroyed.');
        },

        created: function() {
            // Component created
           //this.getCategories();
        },
        
        data: function() {
            return  {

                categories_api: '/api/v1/settings/category/',

                name: '',
                parent_id: null,
                categories: null,
            }
        },


        methods: {

            // Get Categories
            getCategories: async function({ action, callback }){

                // Loading start
                this.$wait.start('add');

                // Get categories
                await axios.get(this.categories_api)
                  .then((response) => {

                    // Set categories
                    this.categories = response.data;

                    // Callback
                    callback();

                    //console.log(response);

                    // Loading end
                    this.$wait.end('add');

                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    //console.log(error);

                    // Loading end
                    this.$wait.end('add');

                  });

            },

            getChildCategories: async function(parent, callback){

                // Loading start
                this.$wait.start('add');

                // Get child category
                await axios.get(this.categories_api, {
                    params: {
                      parent_id: parent.id
                    }
                })
                  .then((response) => {

                    callback(null, response.data);
                    //console.log(response);

                    // Loading end
                    this.$wait.end('add');

                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    console.log(error);

                    // Loading end
                    this.$wait.end('add');

                  });

            },

            category_add: async function(){

                // Loading start
                this.$wait.start('add');

                // Post request
                await axios.post(this.categories_api, {
                    name: this.name,
                    parent_id: this.parent_id,
                  })
                  .then((response) => {

                    // Show success
                    Vue.toasted.success(this.$t('simple.success_info'));

                    // Close modal
                    this.$close(true);

                    // Loading end
                    this.$wait.end('add');

                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    console.log(error);

                    // Close modal
                    this.$close(true);

                    // Loading end
                    this.$wait.end('add');

                  });

            },

            normalizer: function(node){
                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                }
            },

            validate_add: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.category_add();
                    }
                });
            },
        },
    }
</script>