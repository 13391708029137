<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('simple.edit')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="edit">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_edit">


                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="name"
                            name="name"
                            v-validate="'required|max:15'"
                            v-model="name"
                        >

                        <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="description">{{ $t('simple.desc') }}</label>

                        <input 
                            class="form-control"
                            type="text"
                            id="description"
                            name="description"
                            v-validate="'required|max:50'"
                            v-model="description"
                        >

                        <small v-show="errors.has('description')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('description') }}</small>

                    </div>


                    <div class="form-group d-flex justify-content-start align-items-center">

                        

                        <b-form-checkbox v-model="is_important">
                          <!-- {{ check_important() }} -->
                        </b-form-checkbox>

                        <span for="important">{{ $t('simple.important') }}</span>

                    </div>

                    <div class="form-group d-flex justify-content-start align-items-center">

                        

                        <b-form-checkbox v-model="is_vip">
                          <!-- {{ check_vip() }} -->
                        </b-form-checkbox>

                        <span for="vip">{{ $t('simple.vip') }}</span>

                    </div>

                </form>

            </v-wait>

        </div>

        <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

            <b-button @click="validate_edit()" variant="primary">{{ $t('simple.save') }}</b-button>
            
        </div>


        </div>
      </div>
    </div>
</template>
<script>

    export default {

        mounted: function () {
            // Component mounted
            console.log('Customer Type Edit Modal mounted');

            // Get data to edit
            this.edit(this.id);
        },

        destroyed() {
            console.log('Customer Type Edit Modal destroyed.');
        },

        data: function() {
             return  {
                customer_types_api: '/api/v1/settings/customer/types/',
                name: '',
                description: '',
                is_important: false,
                is_vip: false,
            }
        },

        methods: {

            edit: async function(id) {

                // Loading start
                this.$wait.start('edit');

                // Edit type
                await axios.get(this.customer_types_api + id)
                    .then((response) => {

                        // Update modal data
                        this.name = response.data.name;
                        this.description  = response.data.description;
                        this.is_important = response.data.is_important;
                        this.is_vip = response.data.is_vip;

                        //console.log(response);

                        // Loading end
                        this.$wait.end('edit');


                    })
                    .catch((error) => {

                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));

                        // Log to console
                        console.log(error);

                        // Loading end
                    this.$wait.end('edit');


                    });

            },

            customer_type_edit: async function(){

                // Loading start
                this.$wait.start('edit');


                // Update customer type
                await axios.put(this.customer_types_api + this.id, {
                    name: this.name,
                    description: this.description,
                    is_important: this.is_important,
                    is_vip: this.is_vip,
                  })
                  .then((response) => {

                    // Loading end
                    this.$wait.end('edit');

                    // Show success
                    Vue.toasted.success(this.$t('simple.success_info'));

                    // Close modal
                    this.$close(true);


                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Loading end
                    this.$wait.end('edit');

                    // Log to console
                    console.log(error);

                    // Close modal
                    //this.$close(true);


                  });

            },

            
            // check_important: function() {
            //     if(this.is_important){
            //         return this.$t('simple.yes');
            //     } else {
            //         return this.$t('simple.no');
            //     }
            // },

            // check_vip: function() {
            //     if(this.is_vip){
            //         return this.$t('simple.yes');
            //     } else {
            //         return this.$t('simple.no');
            //     }
            // },

            validate_edit: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.customer_type_edit();
                    }
                });
            },
        },
    }
</script>