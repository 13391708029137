export function getCustomerGender (gender) { 

    // Check if we have status
    if(gender){

        // Switch gender
        switch(gender){

            case 1:
                // Return male icon
                return 'male';

                // Break
                break;

            case 2:
                // Return male icon
                return 'female';

                // Break
                break;

            case 3:
                // Return male icon
                return 'genderless';

                // Break
                break;

            default:
                // Return secret ? Lol
                return 'user-secret';

                // Break
                break;

            }

    } else {
      return false;
    }

}



