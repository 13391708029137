<template functional>
  <!-- Note -->
  <div class="message-container">

    <b-alert show variant="light" class="d-inline-block border-warning">

      <template v-if="props.users.length > 0 || props.teams.length > 0">

        <b class="alert-heading border-bottom border-warning text-uppercase text-danger">{{ props.text.transfer_to }}</b>
        <template v-if="props.users.length > 0">
          <div class="d-flex pt-1">
            <strong>{{ props.text.transfer_users }}:</strong>
            <span class="text-danger ml-1" v-for="(user,index) in props.users">{{ user.ns }}<template
                v-if="index + 1 < props.users.length">, </template></span>
          </div>
        </template>

        <template v-if="props.teams.length > 0">
          <div class="d-flex pt-1">
            <strong>{{ props.text.transfer_teams }}:</strong>
            <span class="text-info ml-1" v-for="(team,index) in props.teams">{{ team.name }}<template
                v-if="index + 1 < props.teams.length">, </template></span>
          </div>
        </template>


      </template>

      <template v-else>
        <div class="alert-heading">{{ props.text.transfer_cancelled }}</div>
      </template>

      <template v-if="props.note">
        <p>{{ props.note }}</p>

      </template>
<div class="border-top border-warning mt-2 small">
  <p class="mb-0">
    {{ props.text.transfer_created_by }} <b>{{ props.user.ns }}</b> <span v-b-tooltip.hover
                                                                          :title="props.date.created"
                                                                          class="text-secondary">({{
      props.date.ago
    }})</span>.
  </p>
</div>

    </b-alert>

  </div>

</template>

<script>
export default {

  mounted: function () {
    // Component mounted
    //console.log('Content render mounted')
  },

  destroyed() {
    // Component destroyed
    //console.log('Content render mounted destroyed.');
  },

  created: function () {
    // Component created
  },

  data: function () {
    return {}
  },

  computed: {},

  methods: {},

}
</script>