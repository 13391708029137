import { render, staticRenderFns } from "./QuestionRender.vue?vue&type=template&id=53c73c67&functional=true&"
import script from "./QuestionRender.vue?vue&type=script&lang=js&"
export * from "./QuestionRender.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports