// import {toBoolean} from "@amcharts/amcharts4/.internal/core/utils/Type";

const state = {
  inbox_list_api: {
    'questions': '/api/v1/inbox/list',
    'transfers': '/api/v1/transfer/list',
    'trash': '/api/v1/trash/list',
    'confirmations': '/api/v1/confirmation/list',
    'player': {
        all_in_one: '/api/v1/play/list',
        inbox_zero: '/api/v1/play/inbox-zero/list',
        gamification: '/api/v1/play/gamification/list',
    },
  },

  customers: [],

  cancel_transfer_customers: [],

  is_customer: true,

  is_ticket: false,

  is_cancel_transfer: false,

  inbox_zero_pagination_status: true,

  // Next page Link
  next_page: '',

  // Previous page Link
  prev_page: '',

  // Canceled transfer Next page Link
  cancel_transfer_next_page: '',

  // Canceled transfer Previous page Link
  cancel_transfer_prev_page: '',

  // Permission
  permission: '',

  // Customer Sort ID
  customer_sort_id:[],

  // Customer Sort
  customer_sort:[],

  //InboxList Mobile Toggle
  is_inbox_expanded: true,

  is_messages_expanded: false,

  check_language: false,

}

// getters
const getters = {
  // Get customers
  getCustomers: state => {
    return state.customers;
  },

  // Get Cancel Transfer customers
  getCancelTransferCustomers: state => {
    return state.cancel_transfer_customers;
  },

  // Get customer sort ID
  getCustomerSortID: state => {
    return state.customer_sort_id;
  },

  // Get customer sort
  getCustomerSort: state => {
    return state.customer_sort;
  },

  // Get messages
  getMessages: state => {
    return state.messages;
  },

  // Get is customer
  getIsCustomer: state => {
    return state.is_customer;
  },

  // Get is ticket
  getIsTicket: state => {
    return state.is_ticket;
  },

  // Return Permission
  getPermission: state => {
    return state.permission;
  },

  // Return Next Page
  getNextPage: state => {
    return state.next_page;
  },

  // Return Prev Page
  getPrevPage: state => {
    return state.prev_page;
  },

  // Return Cancel Transfer Next Page
  getCancelTransferNextPage: (state) => {
    return state.cancel_transfer_next_page;
  },

  // Return Cancel Transfer Prev Page
  getCancelTransferPrevPage: (state) => {
    return state.cancel_transfer_prev_page;
  },

  // Return Is Cancel Transfer
  getIsCancelTransfer: (state) => {
    return state.is_cancel_transfer;
  },

  // Return Inbox Zero Pagination Status
  getInboxZeroPaginationStatus: (state) => {
    return state.inbox_zero_pagination_status;
  },

  // Return API URL
  getApiUrl: (state) => inbox_type => {
    switch (inbox_type) {
      case 'questions':
        return state.inbox_list_api.questions;
      case 'transfers':
        return state.inbox_list_api.transfers;
      case 'trash':
        return state.inbox_list_api.trash;
      case 'confirmations':
        return state.inbox_list_api.confirmations;
      case 'player':
        return state.inbox_list_api.player;
      default:
        return false;
    }
  },

    //InboxList Mobile Toggle
    getIsInboxExpanded (state) {
      return state.is_inbox_expanded;
    },
    getIsMessagesExpanded (state) {
      return state.is_messages_expanded;
    },

    getCheckLanguage (state) {
      return state.check_language;
    },

}

// mutations
const mutations = {
  // Set customers
  setCustomers (state, customers){
    state.customers = customers;
  },

  // Set Cancel Transfer customers
  setCancelTransferCustomers (state, cancel_transfer_customers){
    state.cancel_transfer_customers = cancel_transfer_customers;
  },

  // Set customer sort ID
  setCustomerSortID (state, customer_sort_id){
    state.customer_sort_id = customer_sort_id;
  },

  // Set customer sort
  setCustomerSort (state, customer_sort){
    state.customer_sort = customer_sort;
  },

  // Set messages
  setMessages (state, messages){
    state.messages = messages;
  },

  // Set Permission
  setPermission (state, permission){
    state.permission = permission;
  },

  // Set Next Page Link
  setNextPage (state, next_page){
    state.next_page = next_page;
  },

  // Set Previous Page Link
  setPrevPage (state, prev_page){
    state.prev_page = prev_page;
  },

  // Set Cancel Transfer Next Page Link
  setCancelTransferNextPage (state, cancel_transfer_next_page){
    state.cancel_transfer_next_page = cancel_transfer_next_page;
  },

  // Set Cancel Transfer Previous Page Link
  setCancelTransferPrevPage (state, cancel_transfer_prev_page){
    state.cancel_transfer_prev_page = cancel_transfer_prev_page;
  },

  // Set Is Cancel Transfer
  setIsCancelTransfer (state, is_cancel_transfer){
    state.is_cancel_transfer = is_cancel_transfer;
  },

  // Set Inbox Zero Pagination Status
  setInboxZeroPaginationStatus (state, inbox_zero_pagination_status){
    state.inbox_zero_pagination_status = inbox_zero_pagination_status;
  },

  setActive(state, active){
    if(active == 'customer'){
      // Set customer to true
      state.is_customer = true;

      // Set ticket to false
      state.is_ticket = false;
    }

    if(active == 'ticket'){
      // Set customer to false
      state.is_customer = false;

      // Set ticket to true
      state.is_ticket = true;
    }
  },

  //InboxList Mobile Toggle
  setIsInboxExpanded (state, is_inbox_expanded) {
    state.is_inbox_expanded = is_inbox_expanded;
  },
  setIsMessagesExpanded (state, is_messages_expanded) {
    state.is_messages_expanded = is_messages_expanded;
  },

  setCheckLanguage (state, check_language) {
    state.check_language = check_language;
  },

}

// actions
const actions = {
  // get customers
  async fetchInbox({ dispatch, commit, state, getters, rootGetters }, data){

    // Start waiting
    dispatch('wait/start', 'list', { root: true });
    // Clear Customer Data
    dispatch('Customer/resetCustomer', null, {root: true});

    console.log('fetchInbox dump data');
    console.log(data);
    const sort = state.customer_sort_id ? state.customer_sort_id : '';

    let inbox_type = data.inbox_type;
    let inbox_url = getters.getApiUrl(inbox_type);
    console.log(inbox_url);

    let url;
    if (inbox_type === 'player') {
        url = await dispatch('detectPlayerUrl', inbox_url);
        console.log(url);
    } else {
        url = inbox_url + '?account_id=' + data.account_id + '&sort=' + sort;
    }

    // Get Accounts
    await axios.get(url, { params: { check_language: state.check_language ? state.check_language : null }})

      .then((response) => {
        //console.log(response);
        if(response.data.error){
          commit('setPermission', Vue.i18n.translate('permission.denied'));
          console.log(Vue.i18n.translate('permission.denied'));
          // Reset Inbox list Customers data
          commit('setCustomers', []);
          // Stop waiting
          dispatch('wait/end', 'list', { root: true });
        }else {
          // Set Permission
          commit('setPermission', '');

          // Update Inbox Data
          dispatch('updateInboxData', {response: response.data, inbox_type: inbox_type});

          // Stop waiting
          dispatch('wait/end', 'list', { root: true });
        }
      })
      .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
          // Log to console
          console.log(error);
          // Stop waiting
          dispatch('wait/end', 'list', { root: true });
      });

  },

  // get more customers
  async loadMoreInbox({ dispatch, commit, state }, data){

    // Start waiting
    dispatch('wait/start', 'list', { root: true });

    console.log('Load more Inbox.');

    let sort = state.customer_sort_id ? state.customer_sort_id : '';

    let inbox_type = data.inbox_type;
    let inbox_url = getters.getApiUrl(inbox_type);
    console.log(inbox_url);

    let url;
    if (inbox_type === 'player') {
      url = (data.direction === 'next' ? state.next_page : state.prev_page) + '&sort=' + sort;
    }else{
      url = (data.direction === 'next' ? state.next_page : state.prev_page) + '&account_id=' + data.account_id + '&sort=' + sort;
    }

    // Get Accounts
    await axios.get(url, { params: { check_language: state.check_language ? state.check_language : null }})

      .then((response) => {
        //console.log(response);
        if(response.data.error){

          commit('setPermission', Vue.i18n.translate('permission.denied'));

          console.log(Vue.i18n.translate('permission.denied'));

          // Reset Inbox list Customers data
          commit('setCustomers', []);

          // Stop waiting
          dispatch('wait/end', 'list', { root: true });

        }else {

          // Set Permission
          commit('setPermission', '');

          // Set Customers
          commit('setCustomers', response.data.data.data);

          // Set nextPage Url
          commit('setNextPage', response.data.data.next_page_url);

          // Set prevPage Url
          commit('setPrevPage', response.data.data.prev_page_url);

          // Stop waiting
          dispatch('wait/end', 'list', { root: true });
        }
      })
      .catch((error) => {

          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'list', { root: true });

      });

  },

  async updateSeen({ rootGetters }, data){
    // Post Reply
    let update_api = '/api/v1/inbox/list/update';
    let customer_id = data;
    if(!customer_id || customer_id === 'undefined') {
      customer_id = rootGetters['Customer/getCustomerID'];
    }

    const customer = state.customers.find(customer => customer.id === +customer_id);
    if(customer && customer.is_seen_count) {
      let account = rootGetters['Accounts/getAccountData'];
      await axios.post(update_api, {
        customer_id: +customer_id,
        account: account,
      }).then((response) => {
          console.log(response);
        })
        .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
          // Log to console
          console.log(error);
        });
    }
  },

  // Get inbox messages 
  updateActive({ dispatch, commit, state }, active){
    // Assign customer
    commit('setActive', active);
  },

  // Update Inbox Data
  updateInboxData({ dispatch, commit, state }, data){
    // Questions
    if (data.inbox_type === 'questions' || data.inbox_type === 'trash' || data.inbox_type === 'confirmations' || data.inbox_type === 'player') {
      dispatch('updateInboxQuestions', data.response);
    }

    // Transfers
    if (data.inbox_type === 'transfers') {
      dispatch('updateInboxTransfers', data.response);
    }
  },

  // Update Inbox Questions Data
  updateInboxQuestions({ dispatch, commit, state }, data){
    // Set Customers
    commit('setCustomers', data.data.data);

    // Set nextPage Url
    commit('setNextPage', data.data.next_page_url);

    // Set prevPage Url
    commit('setPrevPage', data.data.prev_page_url);
  },

  // Update Inbox Transfers Data
  updateInboxTransfers({ dispatch, commit, state }, data){
    // Set Customers
    commit('setCustomers', data.customer.data.data);

    // Set Cancel Transfer Customers
    commit('setCancelTransferCustomers', data.closed_customer.data.data);

    // Set nextPage Url
    commit('setNextPage', data.customer.data.next_page_url);

    // Set prevPage Url
    commit('setPrevPage', data.customer.data.prev_page_url);

    // Set nextPage Url
    commit('setCancelTransferNextPage', data.closed_customer.data.next_page_url);

    // Set prevPage Url
    commit('setCancelTransferPrevPage', data.closed_customer.data.prev_page_url);
  },

  // update inbox Customers
  updateInboxList({ dispatch, commit, state, rootGetters }, data){
    if (data.inbox_type === 'questions') {
      dispatch('updateQuestionsList', data.data);
      return;
    }

    if (data.inbox_type === 'transfers') {
      dispatch('updateTransfersList', data.data);
      return;
    }

    if (data.inbox_type === 'trash') {
      dispatch('updateTrashList', data.data);
      return;
    }

    if (data.inbox_type === 'confirmations') {
      dispatch('updateConfirmationList', data.data);
      return;
    }

    if (data.inbox_type === 'player') {
      const player_mode = rootGetters['Configurations/getPlayerMode'];
      const in_play = rootGetters['Player/getPlayer'];
      if (in_play === false) {
        dispatch('updatePlayerList', data.data);
        return;
      }

      if (player_mode === 1) {
        dispatch('updatePlayerInboxZeroList', data.data);
        return;
      }

      if (player_mode === 2) {
        dispatch('updatePlayerGamificationList', data.data);
      }
    }
  },

  // update Question Customers
  updateQuestionsList({ dispatch, commit, state }, updated_customer){
    console.log(updated_customer.customer);
    const customer = state.customers.findIndex(customer => customer.id === updated_customer.customer.id);
    if(customer >= 0){
      console.log('Questions customer:::::' + customer);
      if(updated_customer.force_push || updated_customer.customer.active_trash) {
        state.customers.splice(customer, 1);
      }else{
        state.customers.splice(customer, 1, updated_customer.customer);
      }
    }

    if(!state.prev_page && updated_customer.force_push){
      if(state.customers.length === 15) {
        state.customers.pop();
      }
      state.customers.unshift(updated_customer.customer);
    }
  },

  // update transfer Customers
  updateTransfersList({ dispatch, commit, state }, updated_customer){
    console.log(updated_customer.customer);
    const customer = state.customers.findIndex(customer => customer.id === updated_customer.customer.id);
    if (updated_customer.customer.tickets_active) {
      let teams = 0;
      let users = 0;
      updated_customer.customer.tickets_active.forEach(obj => {
        if (obj.teams.length > 0) {
          teams++;
        }

        if (obj.users.length > 0) {
          users++;
        }
      });

      if (teams || users) {
        if(customer >= 0){
          console.log('Transfer customer:::::' + customer);
          if(updated_customer.force_push) {
            state.customers.splice(customer, 1);
          }else{
            state.customers.splice(customer, 1, updated_customer.customer);
          }
        }

        if(!state.prev_page && (updated_customer.force_push || customer < 0)){
          if(state.customers.length === 15) {
            state.customers.pop();
          }
          state.customers.unshift(updated_customer.customer);
        }
      }else if(customer >= 0) {
        state.customers.splice(customer, 1);
      }

    }
  },

  // update Trash Customers
  updateTrashList({ dispatch, commit, state }, updated_customer){
    console.log(updated_customer.customer);
    const customer = state.customers.findIndex(customer => customer.id === updated_customer.customer.id);
    if (updated_customer.customer.active_trash) {
      if (customer >= 0) {
        console.log('Trash customer:::::' + customer);
        if (updated_customer.force_push) {
          state.customers.splice(customer, 1);
        } else {
          state.customers.splice(customer, 1, updated_customer.customer);
        }
      }

      if (!state.prev_page && (updated_customer.force_push || customer < 0)) {
        if (state.customers.length === 15) {
          state.customers.pop();
        }
        state.customers.unshift(updated_customer.customer);
      }
    }else if(customer >= 0) {
      state.customers.splice(customer, 1);
    }
  },

  // update Confirmations Customers
  updateConfirmationList({ dispatch, commit, state }, updated_customer){
    console.log(updated_customer.customer);
    const customer = state.customers.findIndex(customer => customer.id === updated_customer.customer.id);
    if (updated_customer.customer.active_confirmation_count.length) {
      if (customer >= 0) {
        console.log('Confirmation customer:::::' + customer);
        if (updated_customer.force_push) {
          state.customers.splice(customer, 1);
        } else {
          state.customers.splice(customer, 1, updated_customer.customer);
        }
      }

      if (!state.prev_page && (updated_customer.force_push || customer < 0)) {
        if (state.customers.length === 15) {
          state.customers.pop();
        }
        state.customers.unshift(updated_customer.customer);
      }
    }else if(customer >= 0) {
      state.customers.splice(customer, 1);
    }
  },

  // update Player Customers
  updatePlayerList({ dispatch, commit, state }, updated_customer){
    console.log(updated_customer.customer);
    const customer = state.customers.findIndex(customer => customer.id === updated_customer.customer.id);
    if(customer >= 0){
      console.log('Player customer:::::' + customer);
      if(updated_customer.force_push || updated_customer.customer.active_trash) {
        state.customers.splice(customer, 1);
      }else{
        state.customers.splice(customer, 1, updated_customer.customer);
      }
    }

    if(!state.prev_page && updated_customer.force_push){
      if(state.customers.length === 15) {
        state.customers.pop();
      }
      state.customers.unshift(updated_customer.customer);
    }
  },

  // update Player Inbox Zero Customers
  updatePlayerInboxZeroList({ dispatch, commit, state }, updated_customer){
    console.log(updated_customer.customer);
    const customer = state.customers.findIndex(customer => customer.id === updated_customer.customer.id);
    if (updated_customer.customer.tickets_active_count.length) {
      if (customer >= 0) {
        console.log('Inbox Zero Player customer:::::' + customer);
        if (updated_customer.force_push || updated_customer.customer.active_trash) {
          state.customers.splice(customer, 1);
        } else {
          state.customers.splice(customer, 1, updated_customer.customer);
        }
      }

      if (!state.next_page && updated_customer.force_push) {
        if (state.customers.length < 15) {
          state.customers.push(updated_customer.customer);
        }
      }
    }else if(customer >= 0) {
      state.customers.splice(customer, 1);
    }
  },

  // update Player Gamification Customers
  updatePlayerGamificationList({ dispatch, commit, state }, updated_customer){
    console.log(updated_customer.customer);
    const customer = state.customers.findIndex(customer => customer.id === updated_customer.customer.id);
    if (updated_customer.customer.tickets_active_count.length) {
      if (customer >= 0) {
        console.log('Gamification Player customer:::::' + customer);
        if (updated_customer.force_push || updated_customer.customer.active_trash) {
          state.customers.splice(customer, 1);
        } else {
          state.customers.splice(customer, 1, updated_customer.customer);
        }
      }

      if (!state.prev_page && updated_customer.force_push) {
        if (state.customers.length < 15) {
          // state.customers.push(updated_customer.customer);
          state.customers.unshift(updated_customer.customer);
        }
      }
    }else if(customer >= 0) {
      state.customers.splice(customer, 1);
    }
  },

  // Update Gamification Player Assignment
  updatePlayerGamificationAssignment({ dispatch, rootGetters }, updated_customer){
    if (updated_customer.customer.tickets_active.length) {
      const current_user = rootGetters['User/getUser'];
      const play_mode = rootGetters['Configurations/getPlayerMode'];

      if (current_user.in_play === 1 && play_mode === 2) {
        dispatch('updatePlayerGamificationList', updated_customer);
      }
    }
  },

  // Kick Gamification Agent
  kickGamificationAgent({ dispatch }) {
    dispatch('User/updateUserData', 0, {root:true});
    dispatch('fetchInbox', { account_id: null, inbox_type: 'player' });
  },

  // Set Is Cancel Transfer
  async updateIsCancelTransfer ({commit}, status) {
    commit('setIsCancelTransfer', status);
  },

  // Set customer type id
  async updateCustomerSortID ({commit}, customer_type_id) {
    commit('setCustomerSortID', customer_type_id);
  },

  // Set customer type
  async updateCustomerSort ({commit}, customer_type) {
    commit('setCustomerSort', customer_type);
  },

  // Set Inbox Zero Pagination Status
  async updateInboxZeroPaginationStatus ({commit}, status) {
    commit('setInboxZeroPaginationStatus', status);
  },

  // Update Unread_count On Customer
  updateCustomerUnreadCount({ dispatch, state, commit}, notif) {
    const customer = state.customers.find(customer => customer.id === notif);
    if(customer){
      Object.assign(customer, {'is_seen_count':[]});
    }
  },

  // Update User Assign Data On Customer
  updateCustomerAssign({ dispatch, state, commit}, notif) {
    if (notif['customer']) {
      const customer = state.customers.find(customer => customer.id === notif['customer']['id']);
      if (customer) {
        Object.assign(customer, {
          'user_assigned': notif['user_id'],
          'assigned_at': notif['assigned_at'] ? notif['assigned_at'].date : null,
          'user_assign': notif['customer']['user_assign']
        });
      }
    }
  },

  // Detect Player Type and get URL for List
  async detectPlayerUrl({dispatch, rootGetters}, inbox_url) {
    const player_mode = rootGetters['Configurations/getPlayerMode'];
    const sort = state.customer_sort_id ? state.customer_sort_id : '';
    const user = await rootGetters['User/getUser'];

    console.log('playerrr mode:: ' + player_mode);

    let url;
    if (user.in_play === 0) {
      url = inbox_url['all_in_one'] + '?sort=' + sort;
    } else {
      if (player_mode === 1) {
        url = inbox_url['inbox_zero'] + '?sort=' + sort;
      }

      if (player_mode === 2) {
        url = inbox_url['gamification'] + '?sort=' + sort;
      }
    }

    dispatch('Player/updatePlayerStatus', !!user.in_play, { root: true });

    return url;
  },

  updateIsInboxExpanded({commit}, status) {
    commit('setIsInboxExpanded', status);
  },
  updateIsMessagesExpanded({commit}, status) {
    commit('setIsMessagesExpanded', status);
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}