<template>
    <b-row>
        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover title="Message Type">{{
            $t('configuration.message_type') }}</b-col>
        <b-col sm="12" md="5" lg="4">
            <b-form-checkbox-group class="mb-3" :checked="value" @change="handleChange"
                :options="options"></b-form-checkbox-group>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: ['value', 'options'],

    methods: {
        handleChange(e) {
            this.$emit('handleChange', e);
        }
    }
}
</script>