<template>
        <b-col class="center">
            <!-- Transfer names -->
            <transfer-badge v-if="customer.tickets_active.length > 0" v-bind:transfer="customer.tickets_active"
                            v-bind:question_page="'true'"></transfer-badge>
        </b-col>
</template>

<script>

    export default {

        props: ['customer'],

        data: function () {
            return {}
        },

        methods: {},

        computed: {},
    }
</script>