<template>

    <!-- Reports Area -->
    <div class="convoreport" ref="chartdiv" v-if="categoryReports.length">
    </div>
    <div class="convoreport report-overlay" ref="fakechart" v-else>
    </div>

</template>

<script>

// Vuex
import { mapGetters, mapActions } from 'vuex';

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

/* Imports */
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
// am4core.useTheme(am4themes_animated);
// Themes end


export default {

    // works with categoryReports
    props: ['categoryReports'],


    mounted() {

        console.log('percent', this.categoryReports )

        let root;


        if (this.categoryReports.length > 0) {
            root = am5.Root.new(this.$refs.chartdiv);
        } else {
            root = am5.Root.new(this.$refs.fakechart)
        }

        root.setThemes([
            am5themes_Animated.new(root),
        ]);


        let raw_data = this.categoryReports.length ? this.categoryReports : this.fakeSubCategoryReports;

        let data = raw_data.filter((ele) => ele.value !== 0)


        let chart = root.container.children.push(am5percent.PieChart.new(root, {
            layout: root.verticalLayout
        }));



        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "sub_category"
        }));

        series.slices.template.setAll({
            toggleKey: "none",
            tooltipText: "{category}[/]: ({value})"
        });

        series.data.setAll(data);

        let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            // marginTop: 15,
            // marginBottom: 15
        }));


        legend.data.setAll(series.dataItems);

        if (this.categoryReports.length > 0) {
            am5plugins_exporting.Exporting.new(root, {
                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                dataSource: data,
                filePrefix: "convoTypes",
            });
        }


        this.root = root;





        // // Create chart
        // let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
        // chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        // chart.data = this.categoryReports;

        // let series = chart.series.push(new am4charts.PieSeries());
        // series.dataFields.value = "value";
        // series.dataFields.radiusValue = "value";
        // series.dataFields.category = "sub_category";
        // series.slices.template.cornerRadius = 6;
        // series.colors.step = 3;

        // series.hiddenState.properties.endAngle = -90;

        // // Legend
        // chart.legend = new am4charts.Legend();

        // // Export Menu to chart's
        // chart.exporting.menu = new am4core.ExportMenu();
        // // File names will be myExport.png, myExport.pdf, etc
        // chart.exporting.filePrefix = "convoSub";


    },

    beforeDestroy() {
        // if (this.chart) {
        //     this.chart.dispose();
        // }

        if (this.root) {
            this.root.dispose();
        }
    },


    data: function () {
        return {}
    },


    methods: {},

    computed: {
        ...mapGetters({

            fakeSubCategoryReports: 'Report/getFakeSubCategories',

        }),
    },
}
</script>