<template functional>

    <span class="message-header-data message-status">

        <!-- Check integration -->
        <template v-if="props.message.status">

            <!-- Check if Message Sent -->
            <template v-if="props.message.status === 4">
                <span v-b-tooltip.hover :title="parent.$t('simple.sent')">
                    <font-awesome-icon :icon="'check'" class="text-success" />
                </span>
            </template>

            <!-- Check if Message Sent -->
            <template v-else-if="props.message.status === 5">
                <span v-b-tooltip.hover :title="parent.$t('simple.send_failed') + '\n' + ((props.message.fail && props.message.fail.reason) ? props.message.fail.reason : '')">
                    <b-icon icon="exclamation-circle" animation="fade" class="text-danger"/>
                </span>
            </template>

            <!-- Check if Not Sent -->
            <template v-else-if="props.message.status === 1">
                <template v-if="props.message.confirmation">
                    <template v-if="props.message.confirmation.status == null">
                        <span v-b-tooltip.hover :title="parent.$t('schedule.pending')">
                            <font-awesome-icon :icon="'question-circle'" class="text-secondary" />
                        </span>
                    </template>
                    <!-- Check if Not Sent and Waiting for Schedule-->
                    <template v-else-if="props.message.confirmation.status === 1 && props.message.schedule !== null">
                        <span v-b-tooltip.hover :title="parent.$t('schedule.scheduled_at') + props.message.schedule.scheduled_at">
                            <font-awesome-icon :icon="'clock'" class="text-info" />
                        </span>
                    </template>
                    <!-- Check if Not Sent and pending -->
                    <template v-else-if="props.message.confirmation.status === 1">
                        <span v-b-tooltip.hover :title="parent.$t('simple.sent')">
                            <font-awesome-icon :icon="'check'" class="text-success"/>
                        </span>
                    </template>
                    <!-- Check if Not Sent and Rejected -->
                    <template v-else-if="props.message.confirmation.status === 2">
                        <span v-b-tooltip.hover :title="parent.$t('confirmation.rejected')">
                            <font-awesome-icon :icon="'times'" class="text-danger" />
                        </span>
                    </template>
                </template>
                <template v-else-if="props.message.confirmation_id === 0">

                        <template v-if="props.message.schedule == null">
                            <span v-b-tooltip.hover :title="parent.$t('simple.sent')">
                                <font-awesome-icon :icon="'check'" class="text-success" />
                            </span>
                        </template>
                        <!-- Check if Has Schedule -->
                        <template v-else-if="props.message.schedule !== null">
                            <span v-b-tooltip.hover :title="parent.$t('schedule.scheduled_at') + props.message.schedule.scheduled_at">
                                <font-awesome-icon :icon="'clock'" class="text-info" />
                            </span>
                        </template>

                </template>

            </template>
            <template v-else-if="props.message.status === 2">
                <template v-if="props.message.schedule === null">
                    <span v-b-tooltip.hover :title="parent.$t('simple.sent')">
                        <font-awesome-icon :icon="'check'" class="text-success" />
                    </span>
                </template>
                <!-- Check if Has Schedule -->
                <template v-else-if="props.message.schedule !== null">
                    <span v-b-tooltip.hover :title="parent.$t('schedule.scheduled_at') + props.message.schedule.scheduled_at">
                        <font-awesome-icon :icon="'clock'" class="text-info"/>
                    </span>
                </template>
            </template>


            <!-- Check if it's Unknown -->
            <template v-else>
                Unknown
            </template>

        </template>

    </span>


</template>

<script>

    export default {

    }


</script>