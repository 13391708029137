<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="transferResult.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    import * as am5 from "@amcharts/amcharts5";
    import * as am5percent from "@amcharts/amcharts5/percent";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";


    /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('percent', this.transferResult )


                let root;


                if (this.transferResult.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([
                        am5themes_Animated.new(root),
                ]);

                let chart = root.container.children.push(am5percent.SlicedChart.new(root, {
                        layout: root.verticalLayout
                }));

                let raw_data = this.transferResult.length ? this.transferResult : this.fakeTransferResult;
                let data = raw_data.filter( (ele) => ele.value !== 0)



                let series = chart.series.push(am5percent.PictorialStackedSeries.new(root, {
                        alignLabels: true,
                        orientation: "vertical",
                        valueField: "value",
                        categoryField: "category",
                        svgPath: "M421.976,136.204h-23.409l-0.012,0.008c-0.19-20.728-1.405-41.457-3.643-61.704l-1.476-13.352H5.159L3.682,74.507 C1.239,96.601,0,119.273,0,141.895c0,65.221,7.788,126.69,22.52,177.761c7.67,26.588,17.259,50.661,28.5,71.548  c11.793,21.915,25.534,40.556,40.839,55.406l4.364,4.234h206.148l4.364-4.234c15.306-14.85,29.046-33.491,40.839-55.406  c11.241-20.888,20.829-44.96,28.5-71.548c0.325-1.127,0.643-2.266,0.961-3.404h44.94c49.639,0,90.024-40.385,90.024-90.024  C512,176.588,471.615,136.204,421.976,136.204z M421.976,256.252h-32c3.061-19.239,5.329-39.333,6.766-60.048h25.234  c16.582,0,30.024,13.442,30.024,30.024C452,242.81,438.558,256.252,421.976,256.252z"
                }));

                series.labelsContainer.set("width", 100);
                series.ticks.template.set("location", 0.4);
                series.slices.template.setAll({
                        tooltipText: "{category}: {value}",
                });

                series.labels.template.setAll({
                        text: "{category}: {value}"
                });

                series.data.setAll(data);


                let legend = chart.children.moveValue(am5.Legend.new(root, {
                        paddingBottom: 15,
                        paddingTop: 15,
                        x: am5.percent(50),
                        centerX: am5.p50
                }), 0);

                legend.markers.template.setAll({ width: 30, height: 30 });
                legend.markerRectangles.template.setAll({
                        cornerRadiusBL: 20,
                        cornerRadiusBR: 20,
                        cornerRadiusTL: 20,
                        cornerRadiusTR: 20
                });

                legend.data.setAll(series.dataItems);


                if( this.transferResult.length > 0) {
                    am5plugins_exporting.Exporting.new(root, {
                        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                        dataSource: data,
                        filePrefix: "convoTypes",
                    });
                }


                this.root = root;

                // // Create chart instance
                // let iconPath = "M421.976,136.204h-23.409l-0.012,0.008c-0.19-20.728-1.405-41.457-3.643-61.704l-1.476-13.352H5.159L3.682,74.507 C1.239,96.601,0,119.273,0,141.895c0,65.221,7.788,126.69,22.52,177.761c7.67,26.588,17.259,50.661,28.5,71.548  c11.793,21.915,25.534,40.556,40.839,55.406l4.364,4.234h206.148l4.364-4.234c15.306-14.85,29.046-33.491,40.839-55.406  c11.241-20.888,20.829-44.96,28.5-71.548c0.325-1.127,0.643-2.266,0.961-3.404h44.94c49.639,0,90.024-40.385,90.024-90.024  C512,176.588,471.615,136.204,421.976,136.204z M421.976,256.252h-32c3.061-19.239,5.329-39.333,6.766-60.048h25.234  c16.582,0,30.024,13.442,30.024,30.024C452,242.81,438.558,256.252,421.976,256.252z"

                // let chart = am4core.create(this.$refs.chartdiv, am4charts.SlicedChart);
                // chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

                // // Data
                // chart.data = [{
                //         "name": this.$t('ticket.status_replied'),
                //         "value": this.transferResult[0],
                //         "disabled":true
                // }, {
                //         "name": this.$t('ticket.status_awaiting'),
                //         "value": this.transferResult[1]
                // }];

                // let series = chart.series.push(new am4charts.PictorialStackedSeries());
                // series.dataFields.value = "value";
                // series.dataFields.category = "name";
                // series.alignLabels = true;
                // // this makes only A label to be visible
                // series.labels.template.propertyFields.disabled = "disabled";
                // series.ticks.template.propertyFields.disabled = "disabled";


                // series.maskSprite.path = iconPath;
                // series.ticks.template.locationX = 1;
                // series.ticks.template.locationY = 0;

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoTransfer";
        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

                if (this.root) {
                        this.root.dispose();
                }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {
                // Map getters
                ...mapGetters({
                        transferResult: 'Report/getTransferResult',
                        fakeTransferResult: 'Report/getFakeTransferResult',

                })
        },
    }
</script>