<template>

    <span class="message-header-data pt-1" v-b-tooltip.hover :title=" type ? 'Super Admin' : 'admin' ">

        <font-awesome-icon :icon="'user-tie'" class="text-info" />

        {{$t('confirmation.review_by')}} {{ review_by }}

    </span>

</template>
<script>

    export default {

        props: [
            'review_by',
            'type'

        ],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>