<template>


    <b-navbar toggleable="lg" type="light" class="bg-white border-bottom plus-navbar">

        <b-navbar-toggle v-if="showSidebarButton" target="plus-col-accounts-sidebar-mobile" class="plus-sidebar-toggler d-lg-none">
            <template #default="{ expanded }">
                <font-awesome-icon v-if="expanded" :icon="'align-left'" size="lg"/>
                <font-awesome-icon v-else :icon="'align-justify'" size="lg"/>
            </template>
        </b-navbar-toggle>

        <!-- Logo -->
        <b-navbar-brand class="plus-navbar-brand"  to="/"><b-img alt="Convo Logo" src="/images/convo.png" width="120" class="plus-navbar-brand-img"></b-img></b-navbar-brand>

        <b-navbar-toggle target="plus-header-nav-collapse-tablet">
            <template #default="{ expanded }">
                <font-awesome-icon v-if="expanded" :icon="'indent'" size="lg"/>
                <font-awesome-icon v-else :icon="'outdent'" size="lg"/>
            </template>
        </b-navbar-toggle>

        <b-collapse is-nav class="d-none d-lg-block">

            <!-- <b-navbar-nav class="mr-auto">

                <li class="nav-divider  mr-4"></li>


                <li class="ml-4"></li> -->

             <!-- Publish Post Area
                <b-btn
                        to="/post"
                        class="bg-info border-0 btn-sm"
                >
                    <font-awesome-icon :icon="'pen'" />
                    Publish Post
                </b-btn>-->

            <!-- </b-navbar-nav> -->

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto  align-items-center">


                <!-- Search -->
                <b-nav-item-dropdown>

                    <!-- Content -->
                    <template slot="button-content">
                        <font-awesome-icon :icon="'search'" /> {{$t('page.search')}}
                    </template>

                    <plus-header-search></plus-header-search>

                </b-nav-item-dropdown>
                <!-- End of Search -->

                <!-- Announcements -->
                <!--<b-nav-item-dropdown right>-->

                <!-- Using button-content slot -->
                <!--<template slot="button-content">

                  <font-awesome-icon :icon="'globe'" size="lg" class="nav-icon"/>

                </template>-->

                <!-- User Announcements -->
                <!--<template v-for="user_announcement in user_announcements">

                  <b-dropdown-item @click="announcement_show(user_announcement.id)">

                    <h6 class="u-mb-zero">{{ user_announcement.title }}</h6>
                    <p class="u-text-mute">{{ user_announcement.desc }}</p>

                  </b-dropdown-item>

                </template>-->

                <!--</b-nav-item-dropdown>-->


                <!-- Confirmation -->
                <b-nav-item-dropdown right>

                    <!-- Content -->
                    <template slot="button-content">
                        <font-awesome-icon :icon="'check'" />
                        {{$t('page.confirmations')}}
                    </template>

                    <plus-header-confirmation></plus-header-confirmation>

                </b-nav-item-dropdown>
                <!-- End of Confirmation -->


                <!-- Transfers
                <b-nav-item to="/transfers"><font-awesome-icon :icon="'exchange-alt'" /> {{ $t('page.transfers') }}</b-nav-item> -->
                
                <!-- Notifications -->
                <plus-header-notification></plus-header-notification>
                <!-- End of Notifications -->

                <!-- Settings -->
                <b-nav-item-dropdown right>

                    <!-- Content -->
                    <template slot="button-content">
                        <font-awesome-icon :icon="'cogs'" /> {{ $t('page.settings') }}
                    </template>

                    <plus-header-settings></plus-header-settings>

                </b-nav-item-dropdown>
                <!-- End of Settings -->


                <!-- Report -->
                <plus-header-reports></plus-header-reports>
                <!-- End of Report -->

                <!-- User Avatar -->
                <b-nav-item-dropdown class="z-index-fix-1000" right no-caret v-if="user">
                    <!-- Using button-content slot -->
                    <template slot="button-content">
                        <avatar :username="user.ns" :size="32"></avatar>
                    </template>
                    <plus-header-user-avatar :user="user"></plus-header-user-avatar>
                </b-nav-item-dropdown>
                <!-- End of User Avatar -->


            </b-navbar-nav>

        </b-collapse>

        <b-sidebar no-header shadow right id="plus-header-nav-collapse-tablet" class="d-lg-none">

        <!-- <b-navbar-nav class="mr-auto">
        
            <li class="nav-divider  mr-4"></li>
        
        
            <li class="ml-4"></li> -->
        
         <!-- Publish Post Area
            <b-btn
                    to="/post"
                    class="bg-info border-0 btn-sm"
            >
                <font-awesome-icon :icon="'pen'" />
                Publish Post
            </b-btn>-->
        
        <!-- </b-navbar-nav> -->
        
        <!-- Right aligned nav items -->

            <div class="accordion plus-header-accordion-container" role="tablist">

                <!-- User Avatar-->
                <b-card no-body v-if="user" class="plus-header-accordion-card text-center align-items-center" >
                    <b-card-header header-tag="header" class="plus-header-accordion-header" role="tab">
                        <b-button v-b-toggle.accordion-header-user-info class="plus-header-accordion-button m-0">
                            <avatar :username="user.ns" :size="32"></avatar>
                        </b-button>
                    </b-card-header>
                    <b-collapse is-nav id="accordion-header-user-info" class="plus-header-accordion-collapse-menu" accordion="plus-header-accordion" role="tabpanel">
                        <b-card-body>
                            <b-navbar-nav>
                                <plus-header-user-avatar class="d-flex flex-column" :user="user"></plus-header-user-avatar>
                            </b-navbar-nav>
                        </b-card-body>
                    </b-collapse>                    
                </b-card>
                <!-- End of User Avatar -->

                <!-- Report -->
                <b-card no-body class="plus-header-accordion-card text-center align-items-center" >
                
                    <b-card-header header-tag="header" class="plus-header-accordion-header" role="tab">
                        <plus-header-reports></plus-header-reports>
                    </b-card-header>
                </b-card>  
                <!-- End of Report -->



                <!-- Search -->
                <b-card no-body class="plus-header-accordion-card text-center align-items-center" >
                
                    <b-card-header header-tag="header" class="plus-header-accordion-header" role="tab">
                    
                        <b-button v-b-toggle.accordion-header-search class="plus-header-accordion-button m-0">
                            <font-awesome-icon :icon="'search'" /> {{$t('page.search')}}
                            <font-awesome-icon :icon="'caret-down'" size="sm"/>
                        </b-button>
                    </b-card-header>
                    <b-collapse  id="accordion-header-search" class="plus-header-accordion-collapse-menu" accordion="plus-header-accordion" role="tabpanel">
                        <b-card-body>
                            <b-navbar-nav>

                                <plus-header-search class="d-flex flex-column"></plus-header-search>

                            </b-navbar-nav>

                        </b-card-body>
                    </b-collapse>
                </b-card>
                <!-- End of Search -->


                <!-- Confirmation -->
                <b-card no-body class="plus-header-accordion-card text-center align-items-center" >
                
                    <b-card-header header-tag="header" class="plus-header-accordion-header" role="tab">
            
                        <b-button v-b-toggle.accordion-header-confirmation class="plus-header-accordion-button m-0">
                            <font-awesome-icon :icon="'check'" />
                            {{$t('page.confirmations')}}
                            <font-awesome-icon :icon="'caret-down'" size="sm"/>
                        </b-button>
                    </b-card-header>
                    <b-collapse  id="accordion-header-confirmation" class="plus-header-accordion-collapse-menu" accordion="plus-header-accordion" role="tabpanel">
                        <b-card-body>
                            <b-navbar-nav>
                                <plus-header-confirmation class="d-flex flex-column"></plus-header-confirmation>
                            </b-navbar-nav>

                        </b-card-body>
                    </b-collapse>
                </b-card>
                <!-- End of Confirmation -->


                <!-- Notifications -->
                <b-card no-body class="plus-header-accordion-card text-center align-items-center" >
                
                    <b-card-header header-tag="header" class="plus-header-accordion-header" role="tab">
                        <plus-header-notification></plus-header-notification>
                    </b-card-header>

                </b-card>  
                <!-- End of Notifications -->

                
                <!-- Settings -->
                <b-card no-body class="plus-header-accordion-card text-center align-items-center" >
                
                    <b-card-header header-tag="header" class="plus-header-accordion-header" role="tab">
                        <b-button v-b-toggle.accordion-header-settings class="plus-header-accordion-button m-0">
                            <font-awesome-icon :icon="'cogs'" /> {{ $t('page.settings') }}
                            <font-awesome-icon :icon="'caret-down'" size="sm"/>
                        </b-button>
                    </b-card-header>
                    <b-collapse  id="accordion-header-settings" class="plus-header-accordion-collapse-menu" accordion="plus-header-accordion" role="tabpanel">
                        <b-card-body>
                            <b-navbar-nav>
                                <plus-header-settings class="d-flex flex-column"></plus-header-settings>
                            </b-navbar-nav>

                        </b-card-body>
                    </b-collapse>
                </b-card>
                <!-- End of Settings -->
            
            </div>
        </b-sidebar>
    </b-navbar>


</template>

<script>

    // User Announcements
    //import UserAnnouncementShow from './User/Modal/UserAnnouncementShow.vue'

    // Vuex
    import {mapActions, mapGetters} from 'vuex';

    import { create } from 'vue-modal-dialogs'
    import UserEdit from "./Settings/Modal/UserEdit";
    // User password modal
    import UserPassword from './Settings/Modal/UserPassword.vue'

    export default {

        mounted: function () {
            console.log('Account header mounted.')
        },

        data: function() {
            return  {
                title: 'Title',
                user_announcements: [],
            }
        },

        created: function() {
            // Component created
            // this.getUserAnnouncements();
            // this.$i18n.set(user.language);
        },

        methods: {

            logout: async function() {
              this.userLogout();
            },

            edit: async function(id) {
              // Show User edit modal
              const user_edit = create(UserEdit, 'id' , 'is_user');

              if (await user_edit(id, true) ){
                //
              }

            },

            password: async function() {

                // Show user logout modal
                const user_password = create(UserPassword);

                if (await user_password() ){

                }

            },

            getUserAnnouncements: async function(){
                await axios.get('/api/v1/user/announcements/')
                    .then((response) => {
                        // response data
                        this.user_announcements = response.data;
                        //console.log(response);

                    })
                    .catch((error) => {
                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));

                        // Log to console
                        console.log(error);
                    });
            },

            announcement_show: async function(id) {
                // Show Announcement edit modal
                const user_announcement_show = create(UserAnnouncementShow, 'id');

                if (await user_announcement_show(id) ){
                    // Refresh Announcement list
                    this.getUserAnnouncements();
                }
            },

            ...mapActions('User', [
              'userLogout'
            ]),

        },
        computed: {

            // Map getters
            ...mapGetters({

                user: 'User/getUser',

            }),
            showSidebarButton() {
                return this.$route.meta.showSidebarButton;
            },
        },

    }
</script>