<template>

    <div class="modal fade show d-block ps--scrolling-y" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.json')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="add">

                <loading slot="waiting"/>

                <form>
                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                      <file-pond
                          name="file"
                          :server="{
                            url: `/api/v1/googlePlay/json/${id}`,
                            process: {
                              headers: {
                                'X-CSRF-TOKEN': csrfToken,
                              }
                            }
                          }"
                          :files="myFiles"
                          ref="pond"
                          allowFileSizeValidation="true"
                          allowFileTypeValidation="true"
                          allowReplace="false"
                          allowRevert="false"
                          instantUpload="false"
                          @processfile="processFile"
                          allowMultiple="false"
                          :label-idle="$t('page.upload_file')"
                          v-on:init="handleFilePondInit"
                      />


                    </div>
                </form>

            </v-wait>

          </div>
        </div>
      </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";
    // Import Vue FilePond
    import vueFilePond, { setOptions }  from 'vue-filepond';

    // Import validate type
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

    // Import validate size
    import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

    export default {
      components: {
          FilePond
        },
        mounted: function () {
            // Component mounted
            console.log('Account Add Modal mounted');

        },

        destroyed() {
            console.log('Account Add Modal destroyed.');
        },

        data: function() {
             return  {
               name : ''
            }
        },

        methods: {
          handleFilePondInit: function() {
            console.log('FilePond has initialized');
            // FilePond instance methods are available on `this.$refs.pond`
          },
          processFile: function (error, file) {
            if (file.status == 5 ){
              this.$close(true);
            }

          }
        },
        computed: {
            // Map getters
            ...mapGetters({
                accounts: 'Accounts/getAccounts'
            }),
        },
    }
</script>


