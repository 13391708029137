<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('default.edit_whatsapp_profile') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <v-wait for="whatsapp_profile">
                        <loading slot="waiting" />

                        <v-wait for="whatsapp_profile_picture">
                        <loading slot="waiting" />

                            <b-row class="flex-column align-items-center position-relative px-3">
                                <b-avatar :src="profile_picture" size="14vh"></b-avatar>

                                <b-form-file :accept="settings.profile_picture.accepted_image_types" v-model="file"
                                    ref="whatsappBusinessAvatar" id="whatsappBusinessAvatar"
                                    @input="addFile(file, settings.profile_picture.max_image_file_size)" class="d-none">
                                </b-form-file>

                                <div class="d-flex justify-content-center align-items-center my-1">
                                    <template v-if="!file">
                                        <b-button variant="info" class="whatsapp-business profile-picture mr-1"
                                            @click="$refs.whatsappBusinessAvatar.$el.childNodes[0].click()">
                                            <font-awesome-icon :icon="'edit'" />{{ $t('default.change') }}
                                        </b-button>
                                        <b-button variant="danger" class="whatsapp-business profile-picture ml-1"
                                            @click="whatsapp_remove_avatar">
                                            <font-awesome-icon :icon="'trash'" />{{ $t('default.remove') }}
                                        </b-button>

                                    </template>
                                    <template v-else>
                                        <b-button variant="info" class="whatsapp-business profile-picture mr-1"
                                            @click="whatsapp_save_avatar(file)">
                                            <font-awesome-icon :icon="'edit'" />{{ $t('default.save') }}
                                        </b-button>
                                        <b-button variant="danger" class="whatsapp-business profile-picture ml-1"
                                            @click="cancel_avatar_change">
                                            <font-awesome-icon :icon="'trash'" /> {{ $t('default.cancel') }}
                                        </b-button>
                                    </template>
                                </div>

                                <small class="text-muted"> {{ $t('default.whatsapp_picture_desc', {
                                    min: settings.profile_picture.min_image_width + 'px',
                                    max: settings.profile_picture.max_image_width + 'px',
                                    file_size: settings.profile_picture.max_image_file_size
                                }) }}</small>

                            </b-row>
                        </v-wait>

                    

                    <hr>
                        <b-row class="px-3">
                            <h6 class="w-100 m-0 p-0">{{ $t('default.business_desc') }}</h6>
                            <b-form-textarea v-model="whatsapp.description" :placeholder="$t('simple.desc')" rows="4"
                                no-resize :maxlength="settings.profile.desc_limit"></b-form-textarea>
                            <small class="text-muted"> {{ $t('macros.max_char_desc', {
                                char_limit:
                                    settings.profile.desc_limit
                            })
                            }}</small>
                        </b-row>
                        <hr>
                        <b-row class="my-2">
                            <h6 class="w-100 m-0 p-0 px-3">{{ $t('default.contact_info') }}</h6>
                            <b-col md="6" class="my-1">
                                <b-form-input type="url" v-model="whatsapp.website_1" placeholder="Website #1"
                                    :maxlength="settings.profile.website_limit"></b-form-input>
                            </b-col>
                            <b-col md="6" class="my-1">
                                <b-form-input type="url" v-model="whatsapp.website_2" placeholder="Website #2"
                                    :maxlength="settings.profile.website_limit"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="9">
                                <b-form-input type="email" v-model="whatsapp.profile_email"
                                    :placeholder="$t('default.email')" :maxlength="settings.profile.email_limit">
                                </b-form-input>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="px-3">
                            <h6 class="w-100 m-0 p-0">{{ $t('customer.about') }}</h6>
                            <b-form-textarea v-model="whatsapp.about" rows="4" no-resize
                                :maxlength="settings.about.text_limit"></b-form-textarea>
                            <small class="text-muted"> {{ $t('macros.max_char_desc', {
                                char_limit: settings.about.text_limit
                            })
                            }}</small>
                        </b-row>
                        <hr>
                        <b-row>
                            <h6 class="w-100 m-0 p-0 px-3">{{ $t('default.business_details') }}</h6>
                            <b-col sm="12" class="my-1">
                                <b-form-select required v-model="whatsapp.vertical" :options="vertical_options">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled> Vertical<span
                                                class="text-danger">*</span></b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-col>
                            <b-col sm="12" class="my-1">
                                <b-form-input v-model="whatsapp.address_1"
                                    :placeholder="$t('customer.address') + ' 1'"></b-form-input>
                            </b-col>
                            <b-col sm="12" class="my-1">
                                <b-form-input v-model="whatsapp.address_2"
                                    :placeholder="$t('customer.address') + ' 2'"></b-form-input>
                            </b-col>
                            <b-col md="6" class="my-1">
                                <b-form-input v-model="whatsapp.city" placeholder="City"></b-form-input>
                            </b-col>
                            <b-col md="6" class="my-1">
                                <b-form-input v-model="whatsapp.state" placeholder="State"></b-form-input>
                            </b-col>
                            <b-col md="6" class="my-1">
                                <b-form-input v-model="whatsapp.pin_code" placeholder="Pincode"></b-form-input>
                            </b-col>
                            <b-col md="6" class="my-1">
                                <b-form-input v-model="whatsapp.country" placeholder="Country"></b-form-input>
                            </b-col>
                        </b-row>
                    </v-wait>

                </div>

                <div class="modal-footer">

                    <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

                    <b-button @click="update_whatsapp_profile" variant="primary" :disabled="is_disabled">{{
                        $t('simple.save')
                    }}</b-button>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'


export default {

    mounted() {
        console.log('Whatsapp Edit mounted')
        this.fetchWhatsappProfile(this.id)

    },

    destroyed() {
        console.log('Whatsapp Edit destroyed')
    },


    data() {
        return {
            file: null,
            whatsapp_profile_api: '/api/v1/whatsapp/profile/',

            profile_picture: '',
            whatsapp: {}
        }
    },

    methods: {

        async fetchWhatsappProfile(id) {

            this.$wait.start('whatsapp_profile');


            await axios.get(this.whatsapp_profile_api + id)
                .then((response) => {
                    this.whatsapp = response.data.data
                    if(this.whatsapp.about === ' ') {
                        this.whatsapp.about = ''
                    }
                    this.profile_picture = response.data.data.profile_picture

                    this.$wait.end('whatsapp_profile');
                })
                .catch((error) => {
                    this.$wait.end('whatsapp_profile');
                    Vue.toasted.error(this.$t('simple.error_info'));
                });
        },


        addFile(file, limit) {
            if (file) {
                if (this.checkFileSize(file, limit)) {
                    this.checkFileDimensions(file);

                } else {
                    Vue.toasted.error(this.$t('macros.size_limit_alert', {
                        size_limit: limit,
                    }));

                    this.file = null;
                }
            }
        },


        checkFileSize(file, size_limit) {
            if (((file.size / 1024) / 1024).toFixed(4) > size_limit) {
                return false
            }

            return true
        },

        checkFileDimensions(file) {

            let image = new Image();

            image.onload = () => {
                if ( (image.width <= this.settings.profile_picture.max_image_width && 
                    image.height <= this.settings.profile_picture.max_image_height) && 
                    (image.width >= this.settings.profile_picture.min_image_width && 
                    image.height >= this.settings.profile_picture.min_image_height )) {
                    this.profile_picture = URL.createObjectURL(file);
                }
                else {
                    Vue.toasted.error('Image should be ' + this.settings.profile_picture.max_image_width + 'px x ' + this.settings.profile_picture.max_image_height + 'px');
                    this.cancel_avatar_change()
                }
            };

            image.src = URL.createObjectURL(file);
        },

        async whatsapp_save_avatar(file) {

            const fd = new FormData();
            fd.append("image", file);

            this.$wait.start('whatsapp_profile_picture');

            await axios.post(this.whatsapp_profile_api + this.id + '/picture', fd)
                .then((response) => {
                    Vue.toasted.success(this.$t('simple.success_info'))
                    this.file = null;
                    this.$wait.end('whatsapp_profile_picture');
                })
                .catch((error) => {
                    this.$wait.end('whatsapp_profile_picture');
                    Vue.toasted.error(this.$t('simple.error_info'));

            });
          
        },


        async whatsapp_remove_avatar() {

            this.$wait.start('whatsapp_profile_picture');
            
            await axios.delete(this.whatsapp_profile_api + this.id + '/picture')
                .then((response) => {
                    console.log('Picture removed');
                    this.profile_picture = '';
                    this.$wait.end('whatsapp_profile_picture');
                })
                .catch((error) => {
                    this.$wait.end('whatsapp_profile_picture');
                    Vue.toasted.error(this.$t('simple.error_info'));
            });
        },

        cancel_avatar_change() {
            this.profile_picture = this.whatsapp.profile_picture
            this.file = null;
        },

        async update_whatsapp_profile() {

            if (this.whatsapp.vertical !== null && this.whatsapp.vertical !== '') {

                // console.log(this.whatsapp)
                
                this.$wait.start('whatsapp_profile');

                await axios.post(this.whatsapp_profile_api + this.id, this.whatsapp)
                    .then((response) => {
                        this.$wait.end('whatsapp_profile');
                        Vue.toasted.success(this.$t('simple.success_info'));
                        this.$close(true);

                    })
                    .catch((error) => {
                        this.$wait.end('whatsapp_profile');
                        Vue.toasted.error(this.$t('simple.error_info'));
                    });
            }

        },




    },

    computed: {

        ...mapGetters({
            vertical_options: 'Accounts/getWhatsappProfileVerticalOptions',
            settings: 'Accounts/getWhatsappProfileSettings',
        }),

        is_disabled() {
            if (this.whatsapp.vertical === null || this.whatsapp.vertical === '') {
                return true;
            } else {
                return false;
            }
        }


    },
}
</script>