
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue';
window.Vue = Vue;


/**
 * Import router
 *
 */
import router from './router.js'


/**
 * Import store
 *
 */
import { store } from './store';


/**
 * Translation library (great)
 *
 */
import vuexI18n from 'vuex-i18n';
import Locales from './vue-i18n-locales.generated.js';

Vue.use(vuexI18n.plugin, store);

Vue.i18n.add('en', Locales.en);
Vue.i18n.add('tr', Locales.tr);

// set the start locale to use
Vue.i18n.set('en');

/**
 * Bootstrap vue (great)
 *
 */

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);


/**
 * Meta library (great)
 *
 */

import Meta from 'vue-meta'
Vue.use(Meta);


/**
 * Font Awesome (great)
 *
 */

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

library.add(fas, fab);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

/**
 * vue-datetime (great)
 *
 */

import Datetime from 'vue-datetime'

Vue.use(Datetime);

/**
 * MultiSelect (great)
 *
 */
import Multiselect from 'vue-multiselect';

import vSelect from 'vue-select';

Vue.component('v-select', vSelect);


/**
 * Vue Validate (great)
 *
 */

import * as VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields',
  classes: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

/**
 * Vue Video (great)
 *
 */

import VueVideoPlayer from 'vue-video-player'

Vue.use(VueVideoPlayer);

/**
 * Avatar (great)
 *
 */
import Avatar from 'vue-avatar'

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


/**
 * Vue Font Awesome (great)
 *
 */
Vue.component(FontAwesomeIcon.name, FontAwesomeIcon);


/**
 * Vue MultiSelect
 *
 */
Vue.component('multiselect', Multiselect);


/**
 * Vue Modal Dialog
 *
 */

import * as ModalDialogs from 'vue-modal-dialogs'

// Initialization
Vue.use(ModalDialogs);


/**
 * Vue Moment (great)
 *
 */
//import moment from 'moment'
import VueMoment from 'vue-moment'


// Get language files
// require('moment/locale/es')
// change locale
// this.$moment.locale(myNewLocale)

//Vue.use(VueMoment, {moment})
Vue.use(VueMoment);

/**
 * Vue Konva (great)
 *
 */
//import VueKonva from 'vue-konva'

//Vue.use(VueKonva)

/**
 * Vue Wait
 *
 */
import VueWait from 'vue-wait'

Vue.use(VueWait); // add VueWait as Vue plugin



/**
 * Vue Cookies
 *
 */
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)


/**
 * Vue Toasted (great)
 *
 */

import VueToasted from 'vue-toasted';

Vue.use(VueToasted, {
	theme: 'outline',
  position: 'top-center',
	duration : 2000,
    iconPack : 'fontawesome',
});


/****/

////////////////////
///////////////////
// Pixel /////////

// Text
import Text from './components/Module/Pixel/Child/Text.vue'
Vue.component('pixel-text', Text);

// Draw
import Draw from './components/Module/Pixel/Child/Draw.vue'
Vue.component('pixel-draw', Draw);

// Stroke
import Stroke from './components/Module/Pixel/Child/Stroke.vue'
Vue.component('pixel-stroke', Stroke);

// Opacity
import Opacity from './components/Module/Pixel/Child/Opacity.vue'
Vue.component('pixel-opacity', Opacity);

// Shadow
import Shadow from './components/Module/Pixel/Child/Shadow.vue'
Vue.component('pixel-shadow', Shadow);

import PixelImage from './components/Module/Pixel/Child/Image.vue'
Vue.component('pixel-image', PixelImage);

// Avatar
Vue.component('avatar', Avatar);

// Antd
import Antd from 'ant-design-vue';
import cell from 'ant-design-vue/dist/antd.css';
Vue.component('accounts', cell);
Vue.use(Antd);
////////////////////
///////////////////
////////////////////
///////////////////


//Notice: We have been using Map key in Reports - HeatMapView.vue
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDpIk7PKY7AM66Pbk7dZT8mOJL7Sdq7fYU',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  },

});

// Vue audio and video player (we use audio)
import VuePlyr from 'vue-plyr';
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false }
  },
  emit: ['ended']
})

////////////
///////////////////
////////////////////
///////////////////



// Gallery Select
import GallerySelect from './components/Module/Gallery/GallerySelect.vue'
Vue.component('gallery-select', GallerySelect);

// Gallery Upload
import GalleryUpload from './components/Module/Gallery/GalleryUpload.vue'
Vue.component('gallery-upload', GalleryUpload);


////////////////////
///////////////////
////////////////////
///////////////////

import { PixelSpinner } from 'epic-spinners'
Vue.component('pixel-spinner', PixelSpinner);

import { LoopingRhombusesSpinner } from 'epic-spinners'
Vue.component('circles-spinner', LoopingRhombusesSpinner);

import Loading from './components/Module/Loading.vue'
Vue.component('loading', Loading);

import LoadingMessages from './components/Module/LoadingMessages.vue'
Vue.component('loading-messages', LoadingMessages);

////////////////////
///////////////////
////////////////////
///////////////////


//////////////
// Accounts //

// Accounts View Player Render
Vue.component('accounts-view-player', require('./components/Accounts/AccountsViewPlayer.vue').default);
Vue.component('accounts-view-list', require('./components/Accounts/AccountsViewList.vue').default);



//////////////
// Customer //

// Customer render
Vue.component('customer-render', require('./components/Customer/CustomerRender.vue').default);

// Customer Type Render
Vue.component('customer-type', require('./components/Customer/CustomerType.vue').default);

// Customer Changes Render
Vue.component('customer-changes', require('./components/Customer/CustomerChanges.vue').default);

// Customer Location Render
Vue.component('customer-location', require('./components/Customer/CustomerLocation.vue').default);

// Customer Info Render
Vue.component('customer-info', require('./components/Customer/CustomerInfo.vue').default);

// Customer Edit Render
Vue.component('customer-edit', require('./components/Customer/CustomerEdit.vue').default);

// Customer twitter
Vue.component('customer-twitter', require('./components/Customer/CustomerTwitter.vue').default);

// Customer instagram
Vue.component('customer-instagram', require('./components/Customer/CustomerInstagram.vue').default);

// Customer whatsapp
Vue.component('customer-whatsapp', require('./components/Customer/CustomerWhatsapp.vue').default);

// Customer linkedin
Vue.component('customer-linkedin', require('./components/Customer/CustomerLinkedin.vue').default);

//////////////
// Transfer //

// Transfer badge
Vue.component('transfer-badge', require('./components/Transfer/TransferBadge.vue').default);

// Customer //

// Customer render
Vue.component('right-panel', require('./components/Message/RightPanelRender.vue').default);

//////////////
// Message //

// Question render
Vue.component('question-render', require('./components/Message/QuestionRender.vue').default);

// Answer render
Vue.component('answer-render', require('./components/Message/AnswerRender.vue').default);

// Draft render
Vue.component('draft-render', require('./components/Message/DraftRender.vue').default);

// Note render
Vue.component('note-render', require('./components/Message/NoteRender.vue').default);

// Transfer render
Vue.component('transfer-render', require('./components/Message/TransferRender.vue').default);

// Trash Render
Vue.component('trash-render', require('./components/Message/TrashRender.vue').default);

// Message Type Render
Vue.component('message-type', require('./components/Message/MessageType.vue').default);

// Message Integration Render
Vue.component('message-integration', require('./components/Message/MessageIntegration.vue').default);

// Message Source Render
Vue.component('message-source', require('./components/Message/MessageSource.vue').default);

// Message Source Render
Vue.component('message-status', require('./components/Message/MessageStatus.vue').default);

// Message Sender Render
Vue.component('message-sender', require('./components/Message/MessageSender.vue').default);

// Message Attachments Render
Vue.component('message-attachments', require('./components/Message/MessageAttachments.vue').default);

// Message Whatsapp Template Buttons
Vue.component('message-template-buttons', require('./components/Message/MessageTemplateButtons.vue').default);

// Message Date Render
Vue.component('message-date', require('./components/Message/MessageDate.vue').default);

// Message Ticket Render
Vue.component('message-ticket', require('./components/Message/MessageTicket.vue').default);

// Message Ticket List
Vue.component('message-tickets', require('./components/Message/MessageTickets.vue').default);

// Message Confirmation Buttons
Vue.component('message-confirmation', require('./components/Message/MessageConfirmation.vue').default);

// Message Text Panel
Vue.component('messages-list-render', require('./components/Message/MessagesListRender.vue').default);

// Message Action Buttons
Vue.component('messages-render-action-buttons', require('./components/Message/MessagesRenderActionButtons.vue').default);

//Message Main Panel
Vue.component('messages-main-panel', require('./components/Message/MessagesMainPanel.vue').default);

/////////////
// Reply //

// Reply Render
Vue.component('reply-render', require('./components/Reply/ReplyRender.vue').default);

// Reply Text Render
Vue.component('reply-text', require('./components/Reply/ReplyText.vue').default);

// Reply Note Render
Vue.component('reply-note', require('./components/Reply/ReplyNote.vue').default);

// Reply Transfer
Vue.component('reply-transfer', require('./components/Reply/ReplyTransfer.vue').default);

// Reply Schedule Render
Vue.component('reply-schedule', require('./components/Reply/ReplySchedule.vue').default);

// Reply Upload Render
Vue.component('reply-upload', require('./components/Reply/ReplyUpload.vue').default);

// Mentions Render
Vue.component('reply-user-mentions', require('./components/Reply/ReplyUserMentions.vue').default);


//////////////////
// Confirmation //

// Confirmation Draft Render
Vue.component('confirmation-account', require('./components/Confirmation/ConfirmationAccount.vue').default);

// Confirmation Draft Render
Vue.component('confirmation-draft', require('./components/Confirmation/ConfirmationDraft.vue').default);

// Confirmation Logs Render
Vue.component('confirmation-logs', require('./components/Confirmation/ConfirmationLogs.vue').default);

// Confirmation Schedule Render
Vue.component('confirmation-schedule', require('./components/Confirmation/ConfirmationSchedule.vue').default);

// Confirmation Integration Render
Vue.component('confirmation-integration', require('./components/Confirmation/ConfirmationIntegration.vue').default);

// Confirmation Type Render
Vue.component('confirmation-type', require('./components/Confirmation/ConfirmationType.vue').default);

// Confirmation Date Render
Vue.component('confirmation-date', require('./components/Confirmation/ConfirmationDate.vue').default);

// Confirmation Source Link Render
Vue.component('confirmation-source', require('./components/Confirmation/ConfirmationSource.vue').default);

// Confirmation Customer Render
Vue.component('confirmation-customer', require('./components/Confirmation/ConfirmationCustomer.vue').default);

// Confirmation Date Review
Vue.component('confirmation-review', require('./components/Confirmation/ConfirmationReview.vue').default);

// Confirmation Review By
Vue.component('confirmation-by', require('./components/Confirmation/ConfirmationReviewBy.vue').default);

// Confirmation Review By
Vue.component('confirmation-requested-by', require('./components/Confirmation/ConfirmationRequestiedBy.vue').default);

// Confirmation Note
Vue.component('confirmation-note', require('./components/Confirmation/ConfirmationNote.vue').default);

// Ticket
// Ticket render
Vue.component('ticket-render', require('./components/Ticket/TicketRender.vue').default);

// Ticket Location Render
Vue.component('ticket-location', require('./components/Ticket/TicketLocation.vue').default);

// Inbox List
Vue.component('inbox-list', require('./components/Inbox/InboxList.vue').default);
Vue.component('inbox-avatar-part', require('./components/Inbox/InboxAvatarPart.vue').default);
Vue.component('inbox-counter-part', require('./components/Inbox/InboxCounterPart.vue').default);
Vue.component('inbox-list-content', require('./components/Inbox/InboxListContent.vue').default);
Vue.component('inbox-list-name', require('./components/Inbox/InboxListName.vue').default);
Vue.component('inbox-list-time', require('./components/Inbox/InboxListTime.vue').default);
Vue.component('inbox-list-transfer', require('./components/Inbox/InboxListTransferBadge.vue').default);
Vue.component('inbox-list-assigned', require('./components/Inbox/InboxListAssigned.vue').default);
Vue.component('inbox-list-player', require('./components/Inbox/InboxListPlayer.vue').default);
Vue.component('inbox-list-main-menu', require('./components/Inbox/InboxListMainMenu.vue').default);

// Player List Account Badge
Vue.component('player-list-account', require('./components/Player/PlayerListAccountBadge').default);

// Plus
Vue.component('plus', require('./components/Plus.vue').default);
Vue.component('plus-menu', require('./components/PlusMenu.vue').default);
Vue.component('plus-header', require('./components/PlusHeader.vue').default);
Vue.component('plus-header-search', require('./components/PlusHeader/PlusHeaderSearch.vue').default);
Vue.component('plus-header-confirmation', require('./components/PlusHeader/PlusHeaderConfirmation.vue').default);
Vue.component('plus-header-notification', require('./components/PlusHeader/PlusHeaderNotification.vue').default);
Vue.component('plus-header-settings', require('./components/PlusHeader/PlusHeaderSettings.vue').default);
Vue.component('plus-header-reports', require('./components/PlusHeader/PlusHeaderReports.vue').default);
Vue.component('plus-header-user-avatar', require('./components/PlusHeader/PlusHeaderUserAvatar.vue').default);


//Settings
Vue.component('accounts-action-buttons', require('./components/Settings/AccountsActionButtons.vue').default);


// Post List
Vue.component('post-list', require('./components/Post/Posts.vue').default);

/////////////
// Reports //

// Mentions / DM / Key Render
Vue.component('question-types', require('./components/Reports/Charts/QuestionTypes.vue').default);
// Mentions / DM / Key Render ( GENDER)
Vue.component('question-gender', require('./components/Reports/Charts/QuestionGender.vue').default);
// Incoming/Outgoing Messages
Vue.component('incoming-outgoing', require('./components/Reports/Charts/IncomingOutgoing.vue').default);
// Language
Vue.component('lang-incoming-outgoing', require('./components/Reports/Charts/LanguageIncomingOutgoing.vue').default);
// Frequency Map
Vue.component('frequency-map', require('./components/Reports/Charts/FrequencyMap.vue').default);
// External Source Messages(Content recorded by link)
Vue.component('external-source', require('./components/Reports/Charts/ExternalSource.vue').default);
// Customer Types (Content recorded by link)
Vue.component('customer-types', require('./components/Reports/Charts/CustomerTypes.vue').default);
// Process Types (Transfer/Confirmation/Trash)
Vue.component('process-types', require('./components/Reports/Charts/ProcessTypes.vue').default);
// Confirmation Results (confirmed/Rejected/Waiting)
Vue.component('confirmation-results', require('./components/Reports/Charts/ConfirmationResults.vue').default);
// Schedule Results (Completed/Rejected/Waiting)
Vue.component('schedule-results', require('./components/Reports/Charts/ScheduleResults.vue').default);
// Transfer Result Reports Area
Vue.component('transfer-results', require('./components/Reports/Charts/TransferResults.vue').default);
// Tags Reports
Vue.component('tags', require('./components/Reports/Charts/Tags.vue').default);
// Tags Reports
Vue.component('tags-process', require('./components/Reports/Charts/TagsProcess.vue').default);
// Importance Category Reports - VIP/Important
Vue.component('importance-category', require('./components/Reports/Charts/ImportanceCategory.vue').default);
// Main Categories Reports
Vue.component('primary-categories', require('./components/Reports/Charts/PrimaryCategories.vue').default);
// Second Categories Reports
Vue.component('secondary-categories', require('./components/Reports/Charts/SecondaryCategories.vue').default);
// Other Categories Reports
Vue.component('sub-categories', require('./components/Reports/Charts/SubCategories.vue').default);
// Status Process Reports
Vue.component('status-process', require('./components/Reports/Charts/StatusProcess.vue').default);
// User Touch Performance Reports
Vue.component('user-touch-performance', require('./components/Reports/Charts/UserTouchPerformance.vue').default);
// User Time Table Reports
Vue.component('user-time-table', require('./components/Reports/Charts/UserTimeTable.vue').default);
// User Time Table Reports
Vue.component('team-time-table', require('./components/Reports/Charts/TeamTimeTable.vue').default);
// Team Touch Performance Reports
Vue.component('team-touch-performance', require('./components/Reports/Charts/TeamTouchPerformance.vue').default);
// Account Activities
Vue.component('account-activities', require('./components/Reports/Charts/AccountActivities.vue').default);
// Risk Heatmap (Status Processes x Time)
Vue.component('risk-heatmap', require('./components/Reports/Charts/RiskHeatmap.vue').default);
// Risk Heatmap (Status Processes x Time)
Vue.component('heat-map-view', require('./components/Reports/Maps/HeatMapView.vue').default);
// Information Reports
Vue.component('information', require('./components/Reports/Charts/Information.vue').default);
// Location Reports
Vue.component('location-reports', require('./components/Reports/Charts/LocationReports.vue').default);


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!!!VueCookies.get('access_token')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!!VueCookies.get('access_token')) {
      next('/accounts');
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
});

/**
 * Main App file
 *
 */
Vue.config.devtools = true;
Vue.config.performance = true;
const app = new Vue({
    el: '#app',
    store,
    router,
      wait: new VueWait({
        // Defaults values are following:
        useVuex: true,              // Uses Vuex to manage wait state
        vuexModuleName: 'wait',      // Vuex module name

        registerComponent: true,     // Registers `v-wait` component
        componentName: 'v-wait',     // <v-wait> component name, you can set `my-loader` etc.

        registerDirective: false,     // Registers `v-wait` directive
        directiveName: 'wait',       // <span v-wait /> directive name, you can set `my-loader` etc.

      }),

});
