<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ $t('default.add')}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <v-wait for="add">

                    <loading slot="waiting"/>

                    <form @submit.prevent="validate_add">


                        <div class="form-group">

                            <label for="track">{{ $t('simple.track') }}</label>

                            <input
                                class="form-control"
                                type="text"
                                id="track"
                                name="track"
                                v-validate="'required|max:50'"
                                v-model="track"
                            >

                            <small v-show="errors.has('track')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('track') }}</small>

                        </div>

                        <div class="form-group">

                            <label for="description">{{ $t('simple.desc') }}</label>

                            <input 
                                class="form-control"
                                type="text"
                                id="description"
                                name="description"
                                v-validate="'required|max:50'"
                                v-model="description"
                            >

                            <small v-show="errors.has('description')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('description') }}</small>

                            <b-alert>{{ $t('simple.track_info') }}</b-alert>
                            
                        </div>

                    </form>

                </v-wait>

            </div>

            <div class="modal-footer">

                <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

                <b-button @click="validate_add()" variant="primary">{{ $t('simple.save') }}</b-button>
                
            </div>

        </div>
      </div>
    </div> 
</template>
<script>


    export default {


        mounted: function () {
            // Component mounted
            console.log('Track Add Modal mounted');
        },
        
        destroyed() {
            // Component destroyed
            console.log('Track Add Modal destroyed.');
        },

        data: function() {
            return  {
                tracks_api: '/api/v1/twitter/tracks/',

                track: '',
                description: '',
            }
        },

        methods: {
            track_add: async function(){

                // Loading start
                this.$wait.start('add');
                
                // Post request
                await axios.post(this.tracks_api, {

                    account_id: this.id,
                    track: this.track,
                    description: this.description,

                  })
                  .then((response) => {

                    // Show success
                    Vue.toasted.success(this.$t('simple.success_info'));

                    // Loading end
                    this.$wait.end('add');

                    // Close modal
                    this.$close(true);


                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Loading end
                    this.$wait.end('add');

                    // Log to console
                    console.log(error);

                    // Close modal
                    this.$close(true);

                  });

            },

            validate_add: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account add function
                        this.track_add();
                    }
                });
            },
        },
    }
</script>
