export function getTicketVariant (status) { 

    // Check if we have status
    if(status){

      // Switch move
      switch(status){
        // Open
        case 1:
            return 'success';
            // Break
            break;

        // Replied
        case 2:
            return 'info';
            // Break
            break;

        // Transferred
        case 3:
            return 'warning';
            // Break
            break;

        // Awaiting
        case 4:
            return 'primary';
            // Break
            break;

        // Closed
        case 5:
            return 'secondary';
            // Break
            break;
        
        //  Unknown
        default:
            return 'danger';
            break;

      }

    } else {
      return false;
    }

}


export const getTicketStatuses = [
    { id: 1, name: 'status_open' },
    { id: 2, name: 'status_replied' },
    { id: 3, name: 'status_transferred' },
    { id: 4, name: 'status_awaiting' },
    { id: 5, name: 'status_closed' },
    { id: 6, name: 'status_unknown' },
];
