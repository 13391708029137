<template>

    <div class="message-header-data">

        <!-- Check integration -->
        <template>

            <!-- Show Integration Type  -->
            <font-awesome-icon :icon="['fab', integration]" />
            {{ integration }}

        </template>

    </div>

</template>
<script>

    export default {

        props: ['integration'],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>