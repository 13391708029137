<template>
  <div>
    <div :key="$route.params.type" class="row flex-md-nowrap">
      <!-- Inbox list-->
      <template v-if="$route.params.type === 'player'">
        <inbox-list></inbox-list>
      </template>
      <template v-else>
        <inbox-list :key="account"></inbox-list>
      </template>

      <!-- Children router view -->
      <div class="plus-col-main-message col-xl-9 ">
        <router-view :key="account"></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {

  mounted: function () {
    // Component mounted
    console.log('Inbox View component mounted.');
  },

  destroyed() {
    // Component destroyed
    console.log('Inbox View component destroyed.');
  },

  data: function() {
    return  {
      title: this.$t('page.inboxes'),
    }
  },

  metaInfo: function() {
    return  {
      title: this.title,
    }
  },

  methods: {

    // Reply
    ...mapActions('Reply', [
      'resetActiveTab',
      'resetText',
      'resetReplyData'
    ]),

    // Messages
    ...mapActions('Messages', [
      'clearMessages',
    ]),


    // Leave
    leave_echo: async function (customer_id) {
      console.log('Messages leave echo - InboxView ' + customer_id);

      Echo.leave('inbox.customer.' + customer_id);
      Echo.leave('confirmation-' + this.account);

    },

  },

  computed : {

    // Map getters
    ...mapGetters({

      account: 'Accounts/getAccount',
      customer_id: 'Customer/getCustomerID',

    }),
  },

  watch: {
    // whenever question changes, this function will run
    customer_id: function (customer_id, previous_customer_id) {
      // If previous customer isn't false
      if(previous_customer_id){
        this.leave_echo(previous_customer_id);
        // Reset active tab
        // this.resetActiveTab();
        // this.resetText();
        this.resetReplyData();
        this.clearMessages();
      }
    }
  },
}
</script>