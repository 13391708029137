<template>
    <b-row>

        <div class="col pt-2">

            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <div class="w-75">
                            <h4>{{ $t('macros.session_messages') }}</h4>
                            <div class="text-muted template-header-info"> {{ $t('macros.session_messages_desc') }}</div>
                        </div>

                        <div class="ml-auto align-self-center">

                            <b-button :variant="'primary'" :to="{
                                name: 'session_message_setup'
                            }" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add') }}
                            </b-button>

                        </div>

                    </div>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-2">

                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                        <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <b-table stacked="md" responsive bordered hover :items="session_messages"
                            :fields="session_message_fields" :filter="filter" class="vh-25">

                            <!-- Actions -->
                            <template slot="cell(actions)" slot-scope="cell">

                                <b-dropdown right :text="$t('simple.actions')" size="sm">

                                    <b-dropdown-item :to="{
                                        name: 'session_message_setup',
                                        params: { session_message_item: cell.item }
                                    }">{{ $t('simple.edit') }}</b-dropdown-item>


                                    <!-- TODO: Add remove functionality  -->
                                    <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete')
                                    }}</b-dropdown-item>

                                </b-dropdown>

                            </template>

                            <!-- Created at -->
                            <template slot="cell(created_at)" slot-scope="cell">

                                {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                            </template>

                            <!-- Updated at -->
                            <template slot="cell(updated_at)" slot-scope="cell">

                                {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                            </template>

                        </b-table>

                        <v-wait for="session_messages">
                            <loading slot="waiting" />
                        </v-wait>

                </div>


            </div>

        </div>

    </b-row>
</template>
<script>

export default {
    metaInfo: function () {

        return {
            title: 'Session Messages',
        }

    },

    data() {
        return {

            //TODO: get session messages from database
            session_messages: [],

            // TODO: will change according to coming data
            session_message_fields: [
                { key: 'type', label: this.$t('simple.type'), sortable: true },
                { key: 'name', label: this.$t('simple.name'), sortable: true },
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                { key: 'actions', label: this.$t('simple.actions') },
            ],
            filter: null,
        }
    }
}
</script>