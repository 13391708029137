<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ this.status ? $t('simple.message_hide') : $t('simple.message_unhide') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <v-wait for="message_hide">

                        <loading slot="waiting"/>

                        <b-alert variant="danger" show> {{ this.status ? $t('simple.message_hide_confirm') : $t('simple.message_unhide_confirm') }}</b-alert>

                    </v-wait>

                </div>

                <div class="modal-footer">

                    <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>
                    <b-button @click="message_hide" variant="primary">{{ this.status ? $t('simple.hide_verify') : $t('simple.unhide_verify') }}</b-button>

                </div>

            </div>
        </div>
    </div>
</template>
<script>


    export default {

        mounted: function () {
            console.log('Hide Message Modal mounted.');
        },

        destroyed() {
            console.log('Hide Message Modal destroyed.');
        },

        data: function() {
            return  {}
        },

        methods: {

            message_hide: async function(){

                // Loading start
                this.$wait.start('message_hide');

                // Message Delete
                    let account_id = this.account_id;
                    let message_id = this.message_id;
                    let status = this.status;
                    let delete_api = '/api/v1/inbox/answer/hide';
                    // Delete Message
                    await axios.post(delete_api, {

                        account_id: account_id,

                        message_id: message_id,

                        status: status,

                    }).then((response) => {

                        // Close modal
                        this.$close(true);
                        //console.log(response);

                        // Loading stop
                        this.$wait.end('message_hide');

                        // Show success
                        Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

                    }).catch((error) => {

                        // Show error
                        Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                        // Log to console
                        console.log(error);

                        // Loading stop
                        this.$wait.end('message_hide');

                    });



            },
        },
    }
</script>