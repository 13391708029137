<template>

    <div>
        <b-container>
            <b-row>
                <div>
                    <!-- Avatars -->
                    <template v-if="customer.customer_type && customer.customer_type.is_vip">
                        <b-avatar
                                v-if="integration.id == 'twitter' || integration.id == 'facebook' || integration.id == 'instagram' || integration.id == 'sikayetvar' || integration.id == 'youtube' || integration.id == 'googlereview' || integration.id == 'linkedin'"
                                :src="customer.avatar"
                                :variant="customer.customer_type.is_vip ? 'danger' : integration.color"
                                data-holder-rendered="true"
                                size="2.75em"
                                :badge-variant="customer.customer_type.is_vip ? 'danger' : integration.color"
                                class="list-customer-message-avatar">
                            <!-- Avatars's Badge -->
                            <template v-slot:badge>
                                <font-awesome-icon :icon="[integration.id !== 'sikayetvar' ? 'fab' : 'fas', integration.icon]"/>
                            </template>
                        </b-avatar>
                        <b-avatar v-if="integration.id == 'whatsapp' || integration.id == 'googleplay'"
                                  :src="'/images/default_profile_normal.png'"
                                  :variant="customer.customer_type.is_vip ? 'danger' : integration.color"
                                  data-holder-rendered="true"
                                  size="2.75em"
                                  :badge-variant="customer.customer_type.is_vip ? 'danger' : integration.color"
                                  class="list-customer-message-avatar">
                            <!-- Avatars's Badge -->
                            <template v-slot:badge>
                                <font-awesome-icon :icon="['fab', integration.icon]"/>
                            </template>
                        </b-avatar>
                    </template>
                    <template v-else>
                        <b-avatar
                                v-if="integration.id == 'twitter' || integration.id == 'facebook' || integration.id == 'instagram' || integration.id == 'sikayetvar' || integration.id == 'youtube' || integration.id == 'googlereview' || integration.id == 'linkedin'"
                                :src="customer.avatar"
                                :variant="integration.color"
                                data-holder-rendered="true"
                                size="2.75em"
                                :badge-variant="integration.color"
                                class="list-customer-message-avatar">
                            <!-- Avatars's Badge -->
                            <template v-slot:badge>
                                <font-awesome-icon :icon="[integration.id !== 'sikayetvar' ? 'fab' : 'fas', integration.icon]"/>
                            </template>
                        </b-avatar>
                        <b-avatar v-if="integration.id == 'whatsapp' || integration.id == 'googleplay'"
                                  :src="'/images/default_profile_normal.png'"
                                  :variant="integration.color"
                                  data-holder-rendered="true"
                                  size="2.75em"
                                  :badge-variant="integration.color"
                                  class="list-customer-message-avatar">
                            <!-- Avatars's Badge -->
                            <template v-slot:badge>
                                <font-awesome-icon :icon="['fab', integration.icon]"/>
                            </template>
                        </b-avatar>
                    </template>
                </div>
            </b-row>
            <b-row>
                <div>
                    <!-- Awaiting Confirmation -->
                    <font-awesome-icon v-if="confirmation_count !== 0 && !filtered_words_detected"
                                       :icon="'question-circle'" class="text-secondary"
                                       v-b-tooltip.hover
                                       :title="$t('confirmation.awaiting_confirmation')"/>

                </div>
                <div class="ml-1">
                    <!-- Customer Type Render -->
                    <customer-type v-bind:customer="customer"></customer-type>
                </div>
            </b-row>
        </b-container>
    </div>

</template>

<script>

    import {mapGetters, mapMutations} from "vuex";

    export default {

        props: ['customer','integration_id'],

        created() {
            this.setIntegrationID(this.integration_id);

            this.integration = this.getIntegration;
        },


        data: function () {
            return {

                integration: [],
            }
        },

        methods: {
            ...mapMutations('Accounts', [
                'setIntegrationID'
            ]),

        },

        computed: {
            // Map getters
            ...mapGetters({
                // Get account Integration id
                getIntegration: 'Accounts/getIntegration',

            }),

            confirmation_count() {
                if( this.customer && this.customer.active_confirmation_count) {
                    return this.customer.active_confirmation_count.length
                }
                return 0
            },

            filtered_words_detected() {
                return this.customer.last_message.draft_id && this.customer.last_message.draft.invalid_terms
            }

        },
    }
</script>