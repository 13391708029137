<template>
  <div class="plus-col-main-list-inbox-main-menu">
      <!-- Actions main-menu -->
      <div class="actions-main-menu">
          <b-row v-if="inbox_type === 'player'">
            <b-col>
              <b-container>
                <b-row class="align-items-center justify-content-center">
                  <b-col cols="6">
                      <inbox-list-player></inbox-list-player>
                  </b-col>
                
                  <b-col cols="6">
                      <b-button block pill v-b-toggle.actions-menu
                        variant="light"
                        class="text-secondary message-actions-shadow"
                        size="sm"
                      >{{ $t('default.actions') }}
                      </b-button>
                    </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <b-container>
                <b-row class="align-items-center justify-content-center">
                  <b-col cols="2">
                    <font-awesome-icon :icon="'play'" class="text-secondary" size="lg"/>
                  </b-col>
                  <b-col cols="8">
                      <b-button block pill v-b-toggle.actions-menu
                          variant="light"
                          class="text-secondary message-actions-shadow"
                          size="sm"
                      >{{ $t('default.actions') }}
                      </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row>
              <b-collapse id="actions-menu" class="my-2">
                  <!-- Actions sub-menu -->
                  <b-col>
                      <b-container>
                          <b-row align-h="center" align-v="center">
                              <b-col cols="3" class="mt-1">
                                  <b-button-toolbar key-nav aria-label="Toolbar with button groups">
                                      <b-button-group>
                                        <!-- Add Tweet By ID -->
                                        <b-btn v-if="integration && integration.id === 'twitter' && inbox_type === 'questions'" size="sm" variant="light" v-b-tooltip.hover
                                               :title="$t('simple.add_message')"
                                               @click="addMessage">
                                          <font-awesome-icon :icon="'link'" class="plus__icon"/>
                                        </b-btn>
                                        <b-form-checkbox v-if="inbox_type === 'player' && in_play === true && player_mode === 1" v-model="pagination_activator" v-b-tooltip.hover
                                                         :title="pagination_activator ? $t('simple.deactivate_pagination') : $t('simple.activate_pagination')">
                                        </b-form-checkbox>
                                          <!-- Refresh -->
                                          <b-btn size="sm" variant="light" class="ml-1" v-b-tooltip.hover
                                                 :title="$t('default.refresh')"
                                                 @click="updatePage">
                                              <font-awesome-icon :icon="'sync'" class="plus__icon"/>
                                          </b-btn>
                                      </b-button-group>
                                  </b-button-toolbar>
                              </b-col>
                              <b-col cols="9">
                                  <!-- Sort  -->
                                  <template v-if="customer_types" class="small">
                                      <treeselect
                                              v-model="customer_type"
                                              :options="customer_types"
                                              :multiple="true"
                                              value-format="object"
                                              :placeholder="$t('customer.type_select')"
                                              :normalizer="normalizer"
                                      >
                                          <label slot="option-label"
                                                 slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                                 :class="labelClassName">
                                              {{ node.raw.name }}
                                          </label>
                                          <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>
                                      </treeselect>
                                  </template>
                              </b-col>
                          </b-row>
                          <b-row v-if="inbox_type === 'transfers'">
                            <b-col cols="3" class="mt-1 d-flex justify-content-center align-items-end">
                              <b-form-checkbox switch v-model="is_canceled_transfer"></b-form-checkbox>
                            </b-col>
                            <b-col cols="9" class="mt-2">
                                <template v-if="is_cancel_transfer">
                                  <span>{{ $t('ticket.canceled_transfers') }}</span>
                                </template>
                                <template v-else>
                                  <span>{{ $t('ticket.active_transfers') }}</span>
                                </template>
                            </b-col>
                          </b-row>

                          <b-row v-if="inbox_type === 'player' || inbox_type === 'questions'" class="align-items-center mt-2">
                            <b-col cols="2">
                              <b-form-checkbox switch v-model="check_language" ></b-form-checkbox>
                            </b-col>
                            <b-col cols="10">
                              <small class="list-customer-message-time" >{{ check_language ? $t('simple.show_preferred_lang_messages') : $t('simple.show_all_lang_messages') }}</small>
                            </b-col>
                          </b-row>
                      </b-container>
                  </b-col>
              </b-collapse>
          </b-row>
      </div>


      <b-alert show 
      v-if="integration && 
      integration.id === 'twitter' && 
      twitter_limits &&
      account &&
      twitter_limits.dm &&
      $route.params.int" 
      :variant="twitterLimitVariant" 
      class="mb-0">
        <small class="d-block" v-b-tooltip.hover 
          :title="$t('default.twitter_dm_limit_desc', {
            reset_time: $moment($moment.utc(twitter_limits.dm.user_limit_reset)).local().format('YYYY-MM-DD HH:mm')
        })"
        >
        <span class="font-weight-bold">{{ $t('default.twitter_dm_limit') }}</span> {{ twitter_limits.dm.user_limit_remaining }} / {{ twitter_limits.dm.user_limit }} 
          <font-awesome-icon :icon="'question-circle'"/>
        </small>
      </b-alert>

        <v-wait for="list" class="plus-navbar-left-customer-message">
            <loading slot="waiting"/>
            <b-list-group class="list-customer-message" ref="list">
            <div class="alert-danger" v-if="permission_error">
                {{ permission_error }}
            </div>

                <b-list-group-item

                        v-if="customers || cancel_transfer_customers"

                        v-for="customer in (is_cancel_transfer && inbox_type === 'transfers' ? cancel_transfer_customers : customers)"

                        v-bind:key="customer.id"

                        :to="getDestination(customer)"

                        active-class="active"

                        v-bind="toggle_binding_inbox"

                        @click.prevent="changeIsExpanded"
                  >

                      <div class="media">
                          <!-- Avatar Part Render -->
                          <inbox-avatar-part :integration_id="getIntegration(customer)"
                                             :customer="customer"></inbox-avatar-part>

                          <!-- Some kind of info -->
                          <div class="media-body mb-0 small d-flex flex-column">
                              <!-- Name and Time -->
                              <div class="list-customer-message-header d-flex w-100 justify-content-between">
                                  <!-- Name and Time -->
                                  <inbox-list-name :customer="customer"></inbox-list-name>
                                  <!-- Active Trash Icon -->
                                  <font-awesome-icon v-if="customer.active_trash" :icon="'user-times'" class="text-danger mr-1" v-b-tooltip.hover :title="customer.active_trash.trash_adder.ns"/>
                                  <inbox-list-time v-if="customer.last_message"
                                                   :date_time="$moment($moment.utc(customer.last_message.created_at)).local().format('YYYY-MM-DD HH:mm:ss')"
                                                   :time_difference="$moment.utc(customer.last_message.created_at).fromNow(true)"
                                  ></inbox-list-time>

                              </div>
                              <!-- Some kind of text info -->
                              <div class="list-customer-message-content d-flex justify-content-between">

                                  <!-- Text -->
                                  <inbox-list-content :customer="customer"></inbox-list-content>

                                  <!-- Counter -->
                                  <inbox-counter-part :integration_id="getIntegration(customer)"
                                                      :customer="customer"></inbox-counter-part>

                              </div>
                          </div>
                      </div>
                    <b-row no-gutters v-if="inbox_type === 'player'">
                      <b-col class="d-flex justify-content-start align-items-center">
                        <font-awesome-icon :style="{color: getAccount(customer).color}" :icon="[getAccount(customer).integration.id !== 'sikayetvar' ? 'fab' : 'fas', getAccount(customer).integration.icon]"/>
                        <small class="list-customer-message-time ml-1" >{{ getAccount(customer).name }}</small>
                      </b-col>
                    </b-row>
                    <b-row>
                        <!-- Transfer Badge -->
                        <inbox-list-transfer :customer="customer"></inbox-list-transfer>
                        <!-- User Assigned Icon -->
                        <inbox-list-assigned class="mr-1" :customer="customer"></inbox-list-assigned>
                    </b-row>


                </b-list-group-item>
            
            </b-list-group>
        </v-wait>
        <!-- Pagination -->
        <div class="center py-2 mt-auto plus-col-main-list-pagination-button">
            <b-button v-if="in_play && player_mode === 1 && !pagination_activator" :disabled="!next_page || customers.length >= 15" @click="updatePage">
              <font-awesome-icon :icon="'sync'" class="plus__icon"/>
              {{ $t('default.load_more') }}
            </b-button>
            <b-button-group :hidden="in_play && player_mode === 1 && !pagination_activator">
              <b-button :disabled="!prev_page" @click="loadMore('prev')"><<</b-button>
              <b-button :disabled="!next_page" @click="loadMore('next')">>></b-button>
            </b-button-group>
        </div>
  </div>

</template>
<script>
import {DateTime} from "luxon";


    // Scrollbar
    import PerfectScrollbar from 'perfect-scrollbar';

    // Vuex
    import { mapActions, mapGetters, mapMutations } from "vuex";

    // TreeSelect
    import Treeselect from '@riophae/vue-treeselect';
    import {create} from "vue-modal-dialogs";
    import MessageAdd from "../Inbox/Modal/MessageAdd";

    export default {

        components: { Treeselect },

        
        mounted: function () {
            // Component mounted
            console.log('Inbox List Main component mounted.');

            // Mount perfect scrollbar
            if(this.$refs.list){
              this.ps = new PerfectScrollbar(this.$refs.list);
            }

            // Update Customer Type
            this.UpdateCustomerTypes();
            
            // Get Inbox
            this.fetchInbox({account_id: this.account_id, inbox_type: this.inbox_type});

            this.eventController();

            console.log('type:::' + this.$route.params.type);

            this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
            });
        },



        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        destroyed() {
            // Component destroyed
            console.log('Inbox List Main component destroyed.');
            if (this.inbox_type === 'player') {
              Array.from(this.accounts).forEach(account => {
                this.leave_echo(account.id);
              });
            }
            // this.updateIsInboxExpanded(true);
            // this.updateIsMessagesExpanded(false);
        },

        data: function () {
            return {
                ps: null,
                inbox_type: this.$route.params.type,
                window_width: window.innerWidth,
            }
        },

        methods: {       

            ...mapActions('Reply', [
              'updateSessionActive'
            ]),

            // Map Mutations
            ...mapMutations('Inbox', [
              'setCheckLanguage'
            ]),

            changeIsExpanded() {
              if(this.window_width < 576) {
                this.updateIsInboxExpanded(false);
                this.updateIsMessagesExpanded(true);
              } else {
                return null;
              }

            },

            onResize() {
                this.window_width = window.innerWidth;
            },

            // Scroll top
            scrollTop: async function () {

                if (this.ps) {
                  // Scroll update
                  this.ps.update();
                }

                if (this.$refs.list) {
                  // Scroll to top
                  this.$refs.list.scrollTop = 0;
                }

            },

            // Listen
            start_echo: async function (account_id) {

                Echo.private('inbox.account.' + account_id)
                    .listen('InboxChange', (e) => {
                        console.log('inbox change',e);
                        console.log('new inbox event came!');
                        if (e['status']) {
                            this.updateCustomerAssignData(e)
                            this.updateCustomerAssign(e)
                        } else if(e['user_limit']) {
                          console.log('inbox change-user_limit',e)
                          this.updateTwitterLimitation(e)
                        }
                        else {
                            this.updateInboxList({data: e, inbox_type: this.inbox_type});
                        }
                        if(this.$route.params.int == 'whatsapp' && this.$route.params.id == e.customer.account_id && this.$route.params.customer_id == e.customer.id && e.customer.last_message?.question_id) {
                          const time = DateTime.utc()
                          const session_inactive = time.diff(DateTime.fromISO(e.customer.last_message?.question?.created_at), 'hours').toObject().hours > 24 && this.$route.params.int === 'whatsapp'
                          this.updateSessionActive(!session_inactive)
                          // this.updateSessionActive(true)
                        }
                    });
            },

            // Leave Listener
            leave_echo: async function (account_id) {

              console.log('leave echo ' + account_id);

              Echo.leave('inbox.account.' + account_id);

            },

            // Update message page
            updatePage: async function () {
              if (this.inbox_type !== 'player') {
                await this.$router.push({
                  name: 'inbox_list',
                  params: {id: this.account_id, int: this.integration.id, type: String(this.inbox_type)}
                }).catch(err => {});
              }
              if (this.inbox_type === 'player') {
                await this.$router.push({
                  name: String(this.inbox_type)
                }).catch(err => {});
              }
              await this.fetchInbox({ account_id: this.account_id, inbox_type: this.inbox_type });
              await this.scrollTop();
            },

            normalizer: function (node) {
                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                }
            },

            addMessage: async function() {
              // Show Add Message by URL modal
              const message_add = create(MessageAdd, 'integration');

              if (await message_add(this.integration.id) ){
                // Refresh Inbox list
                this.close(true);
              }
            },

            loadMore: function(direction) {
                this.loadMoreInbox({ account_id: this.account_id, direction: direction, inbox_type: this.inbox_type });
            },

            eventController: function() {
                if (this.inbox_type === 'player') {
                  if (!this.accounts.length) {
                    this.fetchAccounts().then(() => {
                      Array.from(this.accounts).forEach(account => {
                        this.leave_echo(account.id);
                      });

                      // Listen
                      Array.from(this.accounts).forEach(account => {
                        this.start_echo(account.id);
                      });
                    });
                  }else{
                    Array.from(this.accounts).forEach(account => {
                      this.leave_echo(account.id);
                    });

                    // Listen
                    Array.from(this.accounts).forEach(account => {
                      this.start_echo(account.id)
                    });
                  }
                }else{
                  // Listen
                  this.start_echo(this.account_id);
                }
            },

            getAccount(customer){
              return this.accounts.filter((account) => account.id === customer.account_id)[0]
            },

            getIntegration: function(customer) {
                if (this.inbox_type === 'player') {
                  if (customer.twitter !== null) {
                    return 'twitter';
                  }
                  if(customer.instagram !== null){
                    return 'instagram';
                  }
                  if(customer.facebook !== null){
                    return 'facebook';
                  }
                  if(customer.whatsapp !== null){
                    return 'whatsapp';
                  }
                  if(customer.sikayetvar !== null){
                    return 'sikayetvar';
                  }
                  if(customer.youtube !== null){
                    return 'youtube';
                  }
                  if(customer.googleplay !== null){
                    return 'googleplay';
                  }
                }else{
                  return this.$route.params.int;
                }
            },

            getDestination: function(customer) {
                if (this.inbox_type === 'player') {
                  let integration = this.getIntegration(customer);
                  return {
                    name: 'player_messages',
                    params: { id: customer.account_id, int: integration, customer_id: customer.id }
                  }
                }else{
                  // return {
                  //   name: this.inbox_type+'_messages',
                  //   params: { customer_id: customer.id }
                  // }
                  return {
                    name: 'messages',
                    params: { customer_id: customer.id }
                  }
                }
            },

            // Map actions
            ...mapActions('Inbox', [
                'fetchInbox',
                'updateInboxList',
                'loadMoreInbox',
                'updateCustomerAssign',
                'updateCustomerSort',
                'updateCustomerSortID',
                'updateIsCancelTransfer',
                'updateInboxZeroPaginationStatus',
                'updateIsInboxExpanded',
                'updateIsMessagesExpanded',
                // 'updateIsPanelExpanded',
            ]),

            // Map actions
            ...mapActions('Customer', [
                'UpdateCustomerTypes',
                'updateCustomerAssignData',
            ]),

            ...mapActions('Accounts', [
              'fetchAccounts',
              'updateTwitterLimitation'
            ]),

        },


        computed: {

            // Map getters
            ...mapGetters({

                // Customers
                customers: 'Inbox/getCustomers',

                // Cancel transfer Customers
                cancel_transfer_customers: 'Inbox/getCancelTransferCustomers',

                // Customer ID
                customer: 'Customer/getCustomerID',

                costumer_data: 'Customer/getCustomer',

                // Get account ID
                account_id: 'Accounts/getAccount',

                accounts : 'Accounts/getAccounts',

                // Get account Integration id
                integration: 'Accounts/getIntegration',

                // Next Page URL
                next_page: 'Inbox/getNextPage',

                // Prev Page URL
                prev_page: 'Inbox/getPrevPage',

                // Permission
                getPermission: 'Inbox/getPermission',

                // Options for tree select
                customer_types: 'Customer/getCustomerTypes',

                // Inbox Customer Sort
                customer_sort: 'Inbox/getCustomerSort',

                // Inbox Customer Sort ID
                customer_sort_id: 'Inbox/getCustomerSortID',

                // Get Is Cancel Transfer
                is_cancel_transfer: 'Inbox/getIsCancelTransfer',

                // Get Player Status
                in_play: 'Player/getPlayer',

                // Get Player Mode
                player_mode: 'Configurations/getPlayerMode',

                // Get Inbox Zero Pagination Status
                inbox_zero_pagination_status: 'Inbox/getInboxZeroPaginationStatus',

                //
                is_inbox_expanded: 'Inbox/getIsInboxExpanded',
                is_messages_expanded: 'Inbox/getIsMessagesExpanded',

                account: 'Accounts/getAccountData',

                checkLanguage: 'Inbox/getCheckLanguage',

            }),

            check_language: {
              get() { 
                return this.checkLanguage; 
              },
              set(value) { 
                this.setCheckLanguage(value); 
                this.updatePage()
            }
           },

            expand_inbox_list: {
              get() { 
                return this.is_inbox_expanded; 
              },
              set(value) { 
                this.updateIsInboxExpanded(value); 
            }
           },

           expand_messages_list: {
              get() { 
                return this.is_messages_expanded; 
              },
              set(value) { 
                this.updateIsMessagesExpanded(value); 
            }
           },



            // Permission Error
            permission_error: {

                // getter
                get: function () {
                    // Get Schedule Data
                    return this.getPermission;
                },

            },

            toggle_binding_inbox() {
              if (this.window_width < 576) {  
              return {
                  'aria-controls' : 'plus-col-main-list-inbox-container plus-col-main-messages-panel-mobile',
                  'aria-expanded' : this.is_inbox_expanded ? 'true' : 'false',
                  class : this.is_inbox_expanded ? null : 'collapsed',
                }
              }
              return {};
            },

            // Customer Types
            customer_type: {

                // getter
                get: function () {
                    // Get Customer Gender
                    return this.customer_sort;
                },

                // setter
                set: function (value) {
                    // Check if we have value?
                    if (value) {

                        let sort_array = [];
                        value.forEach(obj => {
                            sort_array.push(obj.id);
                        });
                        console.log(sort_array);

                        // Update Customer Type
                        this.updateCustomerSortID(sort_array);
                        this.updateCustomerSort(value);

                        console.log('Customer Sort: ' + this.customer_sort_id);

                        this.fetchInbox({
                            account_id: this.account_id,
                            inbox_type: this.$route.params.type,
                            sort: this.customer_sort_id
                        });
                    }
                },

            },

            // Is VIP
            vip_sort: {

                // getter
                get: function () {
                    // Get VIP Sort Data
                    return this.vip_sort;

                },

            },

            // Is Canceled transfer
            is_canceled_transfer: {

                // getter
                get: function () {
                    // Get Schedule Data
                    return this.is_cancel_transfer;
                },

                // setter
                set: function (value) {
                    this.updateIsCancelTransfer(value);
                }

            },

            // Pagination Status for inbox Zero in Play Mode
            pagination_activator: {

                // getter
                get: function () {
                    // Get Schedule Data
                    return this.inbox_zero_pagination_status;
                },

                // setter
                set: function (value) {
                    this.updateInboxZeroPaginationStatus(value);
                }

            },

            // Order Customer List
            orderedUsers: function () {
                return _.orderBy(this.customers.customer_type, 'is_vip', '!==', false)

            },

            sortedArray: function () {
                function sortChange(a, b) {
                    if (a.customer_type && b.customer_type && a.customer_type.is_vip > b.customer_type.is_vip) return -1;
                    if (a.customer_type && b.customer_type && a.customer_type.is_vip < b.customer_type.is_vip) return 1;
                    if (a.customer_type && b.customer_type && a.customer_type.is_important > b.customer_type.is_important) return -1;
                    if (a.customer_type && b.customer_type && a.customer_type.is_important < b.customer_type.is_important) return 1;
                    return 0;
                }

                return this.customers.sort(sortChange); // shallow clone + sort
            },

            twitterLimitVariant() {
              
                  if( this.twitter_limits?.dm?.user_limit_remaining > (Math.floor(this.twitter_limits?.dm?.user_limit / 2))) {
                  return 'success'
                } else if (this.twitter_limits?.dm?.user_limit_remaining === '0' ) {
                  return 'danger'
                } else {
                  return 'warning'
                }
              
            },

            twitter_limits() {
              if(this.account && this.account.additional_data) {
                return JSON.parse(this.account.additional_data)?.twitter ?? {}
              }
            }

        },

        

    }

</script>