<template>
    <div class="message-list plus-main-message-list bg-white" ref="messages"
        v-chat-scroll="{always: true, smooth: false, scrollonremoved:true, smoothonremoved: false}"
    >
        <v-wait for="messages" class="row no-gutters"
                transition="slide-fade"
                mode="out-in"
        >
        <loading-messages slot="waiting"/>
            <template v-for="message in messages">
                <template v-if="message.question">
                    <!-- Question Render -->
                    <question-render
                            v-bind:disabled_areas="disabled_areas"
                            v-bind:account="account"
                            v-bind:ticket="message.ticket"
                            v-bind:message="message.question"
                            v-bind:inbox_id="message.id"
                            :languages = "languages"
                            v-bind:customer="customer"
                            v-bind:user.sync="user"
                            v-bind:date="{ ago: $moment.utc(message.created_at).fromNow(true), created: $moment($moment.utc(message.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }"
                            v-on:updateLast="updateTo(customer, message.question)"
                            v-on:updateTicketID="updateTicketID"
                        />
                    {{ message.created }}
                </template>
                <template v-if="message.answer">
                    <answer-render
                            v-bind:disabled_areas="disabled_areas"
                            v-bind:account="account"
                            v-bind:ticket="message.ticket"
                            v-bind:message="message.answer"
                            v-bind:status="message"
                            :languages = "languages"
                            v-bind:user.sync="user"
                            v-bind:customer="customer"
                            v-bind:date="{ ago: $moment.utc(message.created_at).fromNow(true), created: $moment($moment.utc(message.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }"
                            v-on:updateLast="updateTo(customer, message.answer)"
                            v-on:updateTicketID="updateTicketID"
                        />
                </template>
                <template v-if="message.draft">
                    <draft-render
                            v-bind:disabled_areas="disabled_areas"
                            v-bind:account="account"
                            v-bind:ticket="message.ticket"
                            v-bind:page="current"
                            v-bind:customer="customer"
                            v-bind:message="message.draft"
                            v-bind:inbox_id="message.id"
                            v-bind:confirmation_note="message.draft"
                            v-bind:status="message"
                            :languages = "languages"
                            v-bind:thread_customer="message.thread_customer"
                            v-bind:user.sync="user"
                            v-on:updateLast="updateTo(customer, message.question)"
                            v-bind:timeline="timeline"
                            v-on:updateTicketID="updateTicketID"
                        />
                </template>
                <template v-if="message.note">
                    <note-render
                            v-bind:ticket="message.ticket"
                            v-bind:message="message.note"
                            v-bind:user="message.note.user"
                            v-bind:date="{ ago: $moment.utc(message.note.created_at).fromNow(), created: $moment($moment.utc(message.note.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }"
                            v-on:updateTicketID="updateTicketID"
                        />
                </template>
                <template v-if="message.transfer">
                    <transfer-render
                            v-bind:user="message.transfer.user"
                            v-bind:message="message.transfer"
                            v-bind:users="message.transfer.users"
                            v-bind:teams="message.transfer.teams"
                            v-bind:note="message.transfer.note"
                            v-bind:date="{ ago: $moment.utc(message.transfer.created_at).fromNow(), created: $moment($moment.utc(message.transfer.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }"
                            v-bind:text="{
                                transfer_to: $t('ticket.transfer_to'),
                                transfer_cancelled: $t('ticket.transfer_cancelled'),
                                transfer_created_by: $t('ticket.transfer_created_by'),
                                transfer_users: $t('default.users'),
                                transfer_teams: $t('default.teams')
                                }"
                    />
                </template>
                <template v-if="message.trash">
                    <trash-render
                            v-bind:adder="message.trash.trash_adder"
                            v-bind:trash="message.trash"
                            v-bind:remover="message.trash.remover_user"
                            v-bind:note="message.trash.note"
                            v-bind:date="{ ago: $moment.utc(message.trash.created_at).fromNow(), created: $moment($moment.utc(message.trash.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }"
                            v-bind:text="{
                                moved_to_trash: $t('customer.moved_to_trash'),
                                trash_restored: $t('customer.trash_restored'),
                                trash_created_by: $t('customer.trash_created_by'),
                                trash_restored_by: $t('customer.trash_restored_by')
                                }"
                    />
                </template>
            </template>
        </v-wait>
    </div>
</template>

<script>

    // Vuex
    import {mapGetters, mapActions, mapMutations} from 'vuex';
    import Vue from 'vue';

    //scroll to bottom
    import VueChatScroll from 'vue-chat-scroll';

    Vue.use(VueChatScroll);

    export default {

        props: ['timeline'],
        
        mounted: function () {},



        data: function () {
            return {
            }
        },

        methods: {

            updateTo: function (customer, message) {
                this.updateReplyTo({customer: customer, message: message});

                // Assign Customer
                // this.assignCustomer('set');

            },

            ...mapActions('Ticket', [
                'updateTicketID',
            ]),
            // Reply
            ...mapActions('Reply', [
                'updateReplyTo',
            ]),

            // Customer
            ...mapActions('Customer', [
                'assignCustomer',
            ]),

        },
        
        computed: {
        // Map getters
        ...mapGetters({
            customer: 'Customer/getCustomer',
            user: 'User/getUser',
            messages: 'Messages/getMessages',
          // Get account
            account: 'Accounts/getAccountData',
            disabled_areas: 'Accounts/getDisabledAreas',

            current: 'Messages/getCurrent',

            languages: 'Messages/getLanguages',

        }),

      },

    }



</script>