<template>

    <div class="message-header-data" v-b-tooltip.hover :title="date">

        <font-awesome-icon :icon="'radiation'" :class="'text-'+alert+ ' fas fa-spin'" />

        {{$t('confirmation.created')}} {{ date | moment("from", "now") }}
    </div>

</template>
<script>

    import {mapActions, mapGetters} from "vuex";

    export default {

        props: ['date'],


        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
                this.timeAlert(this.date);
        },

        data: function() {

            return {};

        },

        computed: {

            // Map Getters
            ...mapGetters({

                alert: 'Confirmation/getAlert'

            })
        },

        methods: {


            // Actions
            ...mapActions('Confirmation', [
                'timeAlert'
            ]),

        },
    }
</script>