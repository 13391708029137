<template>

    <div class="row">

        <div class="col pt-2">


            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.tags') }}</h4>

                        <div class="ml-auto align-self-center">

                            <b-button :variant="'primary'" @click="add" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add')}}
                            </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.tags_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-3">


                    <v-wait for="tags">


                        <loading slot="waiting"/>


                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                      <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <b-table stacked="md" responsive bordered striped hover :items="tags" :fields="tag_fields" :filter="filter" class="vh-25">


                            <!-- Actions -->
                            <template slot="cell(actions)" slot-scope="cell">

                              <b-dropdown right :text="$t('simple.actions')" size="sm">

                                <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>

                                <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>


                              </b-dropdown>

                            </template>

                            <!-- Created at -->
                            <template slot="cell(created_at)" slot-scope="cell">

                                 {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                 
                            </template>

                            <!-- Updated at -->
                            <template slot="cell(updated_at)" slot-scope="cell">

                                 {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                            </template>

                        </b-table>


                    </v-wait>

                </div>
                    

            </div>


        </div>

    </div>

</template>


<script>

    import TagAdd from './Modal/TagAdd.vue'
    import TagEdit from './Modal/TagEdit.vue'
    import TagDelete from './Modal/TagDelete.vue'

    import { create } from 'vue-modal-dialogs'

    export default {

        mounted: function () {
            // Component mounted
            console.log('Tags component mounted.')
        },

        destroyed() {
            // Component destroyed
            console.log('Tags component destroyed.');
        },

        created: function() {
            // Component created
           this.getTags();
        },
        

        data: function() {
            return  {
                tags_api: '/api/v1/settings/tags/',
                tags: [],
                tag_fields: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true  },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true  },
                    { key: 'created_at', label: this.$t('simple.created'), sortable: true  },
                    { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                filter: null,
            }
        },
        
        metaInfo: function() {

            return {
                title: this.$t('page.tags'),
            }
            
        },

        methods: {

            getTags: async function() {

                // Start waiting
                this.$wait.start('tags');

                // Send request
                await axios.get(this.tags_api)

                  .then((response) => {
                    
                    // response data
                    this.tags = response.data;

                    //console.log(response);
                    // End waiting
                    this.$wait.end('tags');

                  })

                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    //console.log(error);

                    // End waiting
                    this.$wait.end('tags');

                  });

            },


            add: async function() {
                // Show tag add modal
                const tag_add = create(TagAdd);

                if (await tag_add() ){
                    // Refresh account list
                    this.getTags();
                }

            },

            edit: async function(id) {
                // Show tag edit modal
                const tag_edit = create(TagEdit, 'id');

                if (await tag_edit(id) ){
                    // Refresh tag list
                    this.getTags();
                }

            },

            
            remove: async function(id) {
                
                // Show tag delete modal
                const tag_delete = create(TagDelete, 'id');

                if (await tag_delete(id) ){
                    // Refresh tag list
                    this.getTags();
                }

            },

        },
        
    }
</script>