<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('ticket.change_ticket') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="ticket_update">

                <loading slot="waiting"/>

                <form id="edit" @submit.prevent="validate_add">


                    <div class="form-group">

                        <label for="edit">{{ $t('ticket.ticket')}} : {{ case_id }}</label>
                      <div>
                        <b-table
                            :items="customerTickets"
                            :fields="fields"
                            select-mode="single"
                            responsive="sm"
                            ref="selectableTable"
                            small
                            sticky-header="150px"
                            selectable
                            @row-selected="onRowSelected"
                            :tbody-tr-class="closedTicket"
                            selected-variant="primary"
                        >
                        </b-table>
                      </div>

                        <small v-show="errors.has('ticket_update')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('selectableTable') }}</small>

                    </div>

                </form>

              <p v-if="selected !== false">
                {{ $t('ticket.selected_ticket') }}<br>
                {{ selected.case_id }}
              </p>


            </v-wait>

        </div>

        <div class="modal-footer">


          <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

          <b-button :disabled="selected == false" @click="customerTicketEdit(selected.id, this_inbox_id)" variant="primary">{{ $t('simple.save') }}</b-button>

          <b-button :disabled="selected !== false" @click="customerTicketEdit(null, this_inbox_id)" variant="primary">{{ $t('ticket.save_as_new_ticket') }}</b-button>

        </div>

          <b-alert class="center" show variant="danger">{{ $t('ticket.ticket_edit_confirm') }}</b-alert>
        </div>
      </div>
    </div>

</template>
<script>

import {mapActions, mapGetters} from "vuex";

    export default {


        mounted: function () {
            // Component mounted
            console.log('Category Add Modal mounted');
        },

        destroyed() {
            // Component destroyed
            console.log('Category Add Modal destroyed.');
        },

        created: function() {
            // Component created
           //this.getCategories();
        },
        
        data: function() {
            return  {

              this_inbox_id: this.inbox_id,

              // Ticket 'status' is left out and will not appear in the rendered table
              fields: [

                {
                  key: 'title',
                  label: this.$t('ticket.title'),
                  sortable: true
                },
                {
                  key: 'case_id',
                  label: this.$t('ticket.case_id'),
                  sortable: true,
                },

              ],

              selected: false,

            }
        },

        computed: {
          // Map getters
          ...mapGetters({
            customerTickets: 'Ticket/getCustomerTickets',
          }),
        },

        methods: {

          ...mapActions('Ticket', [
            'updateInboxTicket',
          ]),

          // Row Select
          onRowSelected(customer_tickets) {
            if(customer_tickets.length) {
              this.selected = customer_tickets[0];
            }else{
              this.selected = false;
            }
          },


          customerTicketEdit: async function(ticket_id = null, inbox_id) {

          // UpdateTicketID
          await this.updateInboxTicket({ticket_id: ticket_id, inbox_id: inbox_id});

          // Close the modal
          this.$close(true);

          },

          validate_add: async function() {
            // validate all fields
            await this.$validator.validateAll().then((result) => {
              if (result) {
                // Send it to account edit function
                this.category_add();
              }
            });
          },


          // Row Color for Closed Ticket
          closedTicket(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'closed') return 'table-secondary'
          },

        },
    }
</script>