<template>

    <span>
        <template v-if="customer">
            <template v-if="customer.length > 0">
                <span v-b-toggle.history><font-awesome-icon :icon="'history'" class="plus__icon" />
                    <span class="ml-2">{{ $t('customer.history') }}</span></span>

                <b-collapse id="history" class="mt-2">
                    <div v-for="(change, index) in customer">
                        <p class="mb-0 mt-0 ml-2"><strong>{{ index + 1 }}. </strong>{{ '@' + change.old_screen_name }} :
                            {{ change.old_name }}</p>
                    </div>
                </b-collapse>
            </template>
            <template v-else>
                <span><font-awesome-icon :icon="'history'" class="plus__icon" />
                    <span class="ml-2">{{ $t('customer.no_history') }}</span></span>
            </template>

        </template>

    </span>


</template>

<script>

export default {

    props: ['customer'],

    mounted: function () {
        // Component mounted
        //console.log('Customer Type Render mounted')
    },

    destroyed() {
        // Component destroyed
        //console.log('Customer Type Render mounted destroyed.');
    },

    created: function () {
        // Component created
    },

    data: function () {

        return {};

    },

    computed: {},

    methods: {},

}
</script>