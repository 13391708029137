<template>

    <div class="col-6 mt-2">

        <div class="form-group">
            <label for="cn">Confirmation Note: </label>
                        <textarea
                                id="cn"
                                ref="textarea"
                                class="form-control"
                                rows="3"
                                placeholder="Just type out your confirmation note here!"
                        ></textarea>

        </div>

    </div>

</template>
<script>

    export default {


        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },


        computed: {},

        methods: {},

    }
</script>