<template>
    <div class="row">

        <div class="col pt-2">


            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.customer_genders') }}</h4>

                        <div class="ml-auto align-self-center">

                            <b-button :variant="'primary'" @click="add" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add')}}
                            </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.customer_genders_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-2">


                    <v-wait for="customer_genders">


                        <loading slot="waiting"/>

                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                      <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <b-table stacked="md" responsive bordered hover :items="customer_genders" :fields="customer_gender_fields" :filter="filter" class="vh-25">


                            <!-- Actions -->
                            <template slot="cell(actions)" slot-scope="cell" v-if="cell.item.name !== 'Male' && cell.item.name !== 'Female' && cell.item.name !== 'Unknown'">

                              <b-dropdown right :text="$t('simple.actions')" size="sm">

                                <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>

                                <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>


                              </b-dropdown>

                            </template>

                            <!-- Created at -->
                            <template slot="cell(created_at)" slot-scope="cell">

                                 {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                 
                            </template>

                            <!-- Updated at -->
                            <template slot="cell(updated_at)" slot-scope="cell">

                                 {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                            </template>


                        </b-table>

                    </v-wait>

                </div>

            </div>

        </div>

    </div>
</template>

<script>

    import CustomerGenderAdd from './Modal/CustomerGenderAdd.vue'
    import CustomerGenderEdit from './Modal/CustomerGenderEdit.vue'
    import CustomerGenderDelete from './Modal/CustomerGenderDelete.vue'

    import { create } from 'vue-modal-dialogs'

    export default {

        mounted: function () {
            // Component mounted
            console.log('Customer Genders component mounted.')
        },

        destroyed() {
            // Component destroyed
            console.log('Customer Genders component destroyed.');
        },

        created: function() {
            // Component created
           this.getCustomerGenders();
        },


        data: function() {
            return  {
                customer_genders_api: '/api/v1/settings/customer/genders/',
                customer_genders: [],
                customer_gender_fields: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true },
                    { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                    { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                filter: null,
            }
        },

        metaInfo: function() {

            return {
                title: this.$t('page.customer_genders'),
            }

        },

        methods: {

            getCustomerGenders: async function(){

                // Start waiting
                this.$wait.start('customer_genders');

                // Get customer types
                await axios.get(this.customer_genders_api)

                  .then((response) => {
                    // response data
                    this.customer_genders = response.data;

                    //console.log(response);
                    // End waiting
                    this.$wait.end('customer_genders');

                  })
                  .catch((error) => {

                    console.log(error);

                    // End waiting
                    this.$wait.end('customer_genders');

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));


                  });

            },


            add: async function() {
                // Show Customer Gender add modal
                const customer_gender_add = create(CustomerGenderAdd);

                if (await customer_gender_add() ){
                    // Refresh account list
                    this.getCustomerGenders();
                }

            },

            edit: async function(id) {
                // Show customer gender edit modal
                const customer_gender_edit = create(CustomerGenderEdit, 'id');

                if (await customer_gender_edit(id) ){
                    // Refresh customer gender list
                    this.getCustomerGenders();
                }

            },

            remove: async function(id) {

                // Show customer Gender remove modal
                const customer_gender_remove = create(CustomerGenderDelete, 'id');

                if (await customer_gender_remove(id) ){
                    // Refresh Customer Gender list
                    this.getCustomerGenders();
                }

            },

        },

    }
</script>