<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('page.close_ticket')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="close_open_tickets">

                <loading slot="waiting"/>

                <b-alert variant="danger" show v-if="closeType === 'post_id'"> {{ $t('ticket.ticket_close_confirm') }}</b-alert>
                <b-alert variant="danger" show v-else> {{ $t('ticket.ticket_close_all_confirm') }}</b-alert>

            </v-wait>

          </div>

          <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>
            <b-button @click="close_all(closeType)" variant="primary">{{ $t('simple.yes') }}, {{ $t('ticket.close_all') }}</b-button>
            
          </div>

        </div>
      </div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Close Ticket Confirmation Modal mounted');
        },

        destroyed() {
            // Component Destroyed
            console.log('Close Ticket Confirmation Modal destroyed.');
            this.fetchClosedTickets({post_id:undefined});

        },

        data: function() {
             return  {

                users_api: '/api/v1/settings/users/',
               // post_id: this.post_id,
               // reason: this.reason


             }
        },

        computed: {

          // Map getters
          ...mapGetters({
            close_tickets_post_id: 'Ticket/getClosedTicketsPostID',
            close_tickets_reason: 'Ticket/getClosedTicketsReason',
          }),
        },

        methods: {

            // Map actions
            ...mapActions('Ticket', [
              'closeOpenTickets',
              'closeAllOpenTickets',
              'fetchClosedTickets'
            ]),

            close_all: async function(closeType){

              if(closeType === 'post_id') {
                if (this.closeOpenTickets({post_id: this.close_tickets_post_id, reason: this.close_tickets_reason})) {

                  this.$close(true);

                }
              }else if (closeType === 'all') {
                if (await this.closeAllOpenTickets()) {

                  this.$close(true);

                }else{
                  this.$close(false);
                }
              }
            },
        },
    }
</script>