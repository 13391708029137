<template>

  <main class="container-fluid d-flex flex-column align-items-center justify-content-center login-plus-main">
    <div class="row">


      <div class="bg-white rounded p-4 shadow-widget border login-plus-main-card">
        <div class="center">
          <b-img src="/images/convo.png" alt="convoplus"></b-img>
          <hr>
        </div>

              <h5 class="font-weight-light text-muted mb-3">{{ $t('default.login_description') }}</h5>

          <form method="POST" @submit.prevent="login">
            <!--        @csrf-->
            <div class="form-group">
              <label for="email" class="text-uppercase small">{{ $t('simple.email') }}</label>
              <input v-model="username" type="email" class="form-control form-control-lg" id="email" name="email" value="" required autofocus>

              <!--          @if ($errors->has('email'))-->
              <!--          <small class="form-text text-danger">{{ $errors->first('email') }}</small>-->
              <!--          @endif-->
            </div>



            <div class="form-group font-weight-light">
              <label class="text-uppercase small">{{ $t('simple.password') }}</label>
              <input v-model="password" type="password" class="form-control form-control-lg" id="password" name="password" required>
              <!--          @if ($errors->has('password'))-->
              <!--          <small class="form-text text-danger">{{ $errors->first('password') }}</small>-->
              <!--          @endif-->

            </div>

            <div class="form-group form-check font-weight-light">
              <input type="checkbox" class="form-check-input" name="remember"  id="remember"  >
              <label class="form-check-label" for="remember">{{ $t('auth.remember_me') }}</label>
            </div>

            <button type="submit" class="btn btn-success btn-block btn-lg">{{ $t('auth.login') }}</button>

          </form>

        <div class="col-5 mt-3 form-control-sm mx-auto login-form-language" >

          <b-form-select
              id="lang"
              name="language"
              v-model="language"
              :options="this.language_list"
          >
          </b-form-select>

          <small v-show="errors.has('language')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('language') }}</small>
        </div>

      </div>


    </div>
  </main>

</template>
<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {

        data: function () {

            return {
                username: '',
                password: '',
            }
        },

        methods: {

            login() {
              this.userLogin({username: this.username, password: this.password});
            },

            ...mapActions('User', [
                'userLogin'
            ]),
        },

        computed: {

            // Map getter
            ...mapGetters({
                language_list: 'User/getLanguageList',
            }),

            language: {
                // getter
                get: function () {
                    return 'en';
                },

                // setter
                set: function (value) {
                  this.$i18n.set(value);
                }
            },

        },
    }
</script>