<template>
  <div class="plus-col-main-list">
    <!-- Actions main-menu -->
    <b-container class="sticky-top message-actions-shadow">
      <b-row class="py-2" align-h="center" align-v="center">
        <b-col cols="8">
          <b-button block pill v-b-toggle.actions-menu
                    variant="light"
                    class="text-secondary message-actions-shadow"
                    size="sm"
          >{{ $t('default.actions') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-collapse id="actions-menu" class="my-2">
          <!-- Actions sub-menu -->
          <b-col class="py-2">
            <b-container>
              <b-row align-h="center" align-v="center">
                <b-col cols="1">
                  <b-button-toolbar key-nav aria-label="Toolbar with button groups">
                    <b-button-group>
                      <!-- Add Post -->
                      <b-btn size="md" variant="light" v-b-tooltip.hover
                             :title="$t('simple.add_message')"
                             :to="{name : 'new_post'}"
                      >
                        <font-awesome-icon :icon="'plus'" class="plus__icon"/>
                      </b-btn>
                      <!-- Refresh -->
                      <b-btn size="md" variant="light" v-b-tooltip.hover
                             :title="$t('default.refresh')"
                      >
                        <font-awesome-icon :icon="'sync'" class="plus__icon"/>
                      </b-btn>
                    </b-button-group>
                  </b-button-toolbar>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-collapse>
      </b-row>
    </b-container>

    <v-wait for="list">
      <loading slot="waiting"/>
      <b-list-group class="list-customer-message pb-2" ref="list">
        <b-list-group-item
            active-class="active"
            v-for="item in list"
            :key="item.id"
            :to="{name:'show_post', params:{postId: item.id, int : $route.params.int}}"
        >
          <b-row class="mx-2">
            <p class="list-customer-message-text">{{ item.status }}</p>
          </b-row>
          <b-row class="mx-2">
            <font-awesome-icon v-if="item.send" :icon="'check'" class="text-success mx-2 mt-1" v-b-tooltip.hover :title="$t('simple.sent')"/>
            <span v-if="item.schedule_at">
                  {{ item.schedule_at  | moment("from", "now") }}
            </span>
            <span v-else>
                  {{ item.created_at  | moment("from", "now") }}
            </span>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </v-wait>
  </div>
<!--  <div class="plus-col-main-message h-100">-->
<!--    <div class="message-container" >-->
<!--      <div class="message-wrapper-customer">-->
<!--        <div class="">-->
<!--          <b-row class=" mb-2 justify-content-start">-->
<!--            <b-button v-b-tooltip.hover :title="$t('page.new')" class="btn btn-success" :to="{name : 'new_post'}">-->
<!--              <font-awesome-icon  :icon="'plus'" class="text-white" v-b-tooltip.hover :title="$t('page.new')" />-->
<!--            </b-button>-->
<!--          </b-row>-->
<!--          <router-view></router-view>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Posts",
  computed: {
    ...mapGetters({
      account: 'Accounts/getAccount',
      getList: 'Post/getList'
    }),

    list: {

      // getter
      get: function () {
        return this.getList;
      },

    },
  },
  mounted() {
    this.getPostList(this.$route.params.id)
  },
  methods:{

    ...mapActions('Post', [
      'getPostList'
    ]),

  },

}
</script>

<style scoped>

</style>