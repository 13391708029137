var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade show d-block ps--scrolling-y",attrs:{"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t('default.json')))]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.$close()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[_c('v-wait',{attrs:{"for":"add"}},[_c('loading',{attrs:{"slot":"waiting"},slot:"waiting"}),_vm._v(" "),_c('form',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('simple.name')))]),_vm._v(" "),_c('file-pond',{ref:"pond",attrs:{"name":"file","server":{
                        url: ("/api/v1/googlePlay/json/" + _vm.id),
                        process: {
                          headers: {
                            'X-CSRF-TOKEN': _vm.csrfToken,
                          }
                        }
                      },"files":_vm.myFiles,"allowFileSizeValidation":"true","allowFileTypeValidation":"true","allowReplace":"false","allowRevert":"false","instantUpload":"false","allowMultiple":"false","label-idle":_vm.$t('page.upload_file')},on:{"processfile":_vm.processFile,"init":_vm.handleFilePondInit}})],1)])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }