<template>

    <span>
        <template v-if="question_page && (assigned_users.length > 0 || assigned_teams.length > 0)">
            <b-badge pill variant="warning" class="align-text-bottom mr-3" v-b-tooltip.hover.html="assigned_names">
                <font-awesome-icon size="xs" :icon="'reply'" /> {{ $t('ticket.transferred') }}
            </b-badge>
        </template>

        <template v-else-if="transfer_page && (assigned_users.length > 0 || assigned_teams.length > 0)">
            <b-badge pill variant="warning" class="align-text-bottom mr-3" v-b-tooltip.hover
                :title="assigned_names">
                {{ assigned_teams.length > 0 && assigned_users.length === 0 ? $t('ticket.team_transfer') :
                    assigned_users.length > 0 && assigned_teams.length === 0 ? $t('ticket.team_transfer') :
                        $t('ticket.team_user_transfer')}}
            </b-badge>
        </template>
    </span>

</template>

<script>

export default {

    props: [
        'transfer',
        'transfer_page',
        'question_page'
    ],

    mounted: function () {
        // Component mounted
    },

    destroyed() {
        // Component destroyed
    },

    created: function () {
        // Component created
    },

    data: function () {
        return {

        }
    },

    computed: {
        assigned_users() {
            const temp_users = []
            this.transfer.forEach(ticket => {
                if (ticket.users.length > 0) {
                    ticket.users.forEach(user => {
                        temp_users.push(user.ns)
                    })
                }
            })
            return temp_users
        },

        assigned_teams() {
            const temp_teams = []
            this.transfer.forEach(ticket => {
                if (ticket.teams.length > 0) {
                    ticket.teams.forEach(team => {
                        temp_teams.push(team.name)
                    })
                }
            })
            return temp_teams
        },

        assigned_names() {
            let users = ''
            if (this.assigned_users.length > 0) {
                users = this.$t('ticket.user_list')
                this.assigned_users.forEach((userName, index) => {
                    users += `${index === 0 ? '' : ','} ${userName}`
                })
            }
            let teams = ''
            if (this.assigned_teams.length > 0) {
                teams = this.$t('ticket.team_list')
                this.assigned_teams.forEach((teamName, index) => {
                    teams += `${index === 0 ? '' : ','} ${teamName}`
                })
            }

            return { title: `${users} <br> ${teams}` }

        }
    },

    methods: {

        // Team check
        // getName: function (val) {

        //     if (val) {

        //         if (val[0].teams.length) {
        //             this.arrayTeams = [];
        //             val[0].teams.forEach(obj => {
        //                 this.arrayTeams.push(obj.name);
        //             });

        //             this.team = this.arrayTeams.toString().split(", ");
        //             this.res = this.$t('ticket.team_list') + this.team;
        //         }
        //         if (val[0].users.length) {
        //             this.arrayUsers = [];
        //             val[0].users.forEach(obj => {
        //                 this.arrayUsers.push(obj.ns);
        //             });

        //             this.users = this.arrayUsers.toString().split(", ");
        //             this.res = this.$t('ticket.user_list') + this.users;
        //         }

        //         if (this.arrayTeams && this.arrayUsers) {
        //             this.res = this.$t('ticket.user_list') + this.users + ' \n ' + this.$t('ticket.team_list') + this.team;
        //         }

        //         return this.res;

        //     }

        // },

    },

}
</script>
