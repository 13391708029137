<template>
  <div class="container-fluid">
    <div :key="$route.params.type" class="row">
      <!-- Inbox list-->
      <post-list :key="account"></post-list>

      <!-- Children router view -->
      <div class="plus-col-main-message">
        <router-view :key="account"></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {

  mounted: function () {
    // Component mounted
    console.log('Post View component mounted.');
  },

  destroyed() {
    // Component destroyed
    console.log('Post View component destroyed.');
  },

  data: function() {
    return  {
      title: this.$t('page.posts'),
    }
  },

  metaInfo: function() {
    return  {
      title: this.title,
    }
  },

  methods: {

    // Reply
    ...mapActions('Reply', [
      'resetActiveTab',
      'resetText',
    ]),

  },

  computed : {

    // Map getters
    ...mapGetters({

      account: 'Accounts/getAccount',

    }),
  },
}
</script>