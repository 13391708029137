<template>

    <b-button block pill
              :pressed.sync="player"
              variant="light"
              size="sm"
              :active="true"
              class="message-actions-shadow"
              active-class="font-weight-bold text-danger"

    >
        <font-awesome-icon
                :icon=" player ? 'pause' : 'play'"
                class="logo-color"
        />
        <template v-if="player">{{ $t('player.pause') }}</template>
        <template v-else>{{ $t('player.play') }}</template>
    </b-button>

</template>

<script>

    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Player component mounted.');
            if(this.playerMode === 2) {
              // Listen
              if (this.getPlayer) {
                this.start_echo();
              } else {
                this.leave_echo(this.user_id);
              }
            }

        },

        destroyed() {
            // Component destroyed
            console.log('Player component destroyed.');
            this.leave_echo(this.user_id);
        },

        data: function () {
            return {}
        },

        methods: {
            ...mapMutations('Player', [
                'setPlayer'
            ]),

            ...mapActions('Player', [
                'gamificationActions',
            ]),

            ...mapActions('Inbox', [
                'kickGamificationAgent',
                'updatePlayerGamificationAssignment',
            ]),

            // Listen
            start_echo: async function () {

                console.log('start echo: ' + this.user_id);
                Echo.private('gamification.' + this.user_id)
                    //.subscribe('InboxList')
                    .listen('GamificationEvent', (e) => {
                        console.log(e);
                        console.log('new gamification event came!');
                        if (e['kick_agent']) {
                          this.kickGamificationAgent();
                        }

                        if (e['customer']) {
                          this.updatePlayerGamificationAssignment(e);
                        }
                    });
            },

            // Leave Listener
            leave_echo: async function (user_id) {

                console.log('leave echo ' + user_id);
                Echo.leave('gamification.' + user_id);

            },

        },

        computed: {

            // Map getters
            ...mapGetters({
                // Player
                getPlayer: 'Player/getPlayer',
                user_id: 'User/getUserID',
                playerMode: 'Configurations/getPlayerMode',
            }),

            // For Player Actions
            player: {

                // getter
                get: function () {
                    // Get Player Data
                    return this.getPlayer;
                },

                // setter
                set: async function (value) {
                    this.setPlayer(value);
                    // console.log('set-player', value);
                    await this.$router.push({
                        name: 'player',
                    }).catch((err) => {})
                    this.gamificationActions(value);
                    if(this.playerMode === 2) {
                      if (value) {
                        this.start_echo();
                      } else {
                        this.leave_echo(this.user_id);
                      }
                    }
                }
            },


        },
    }
</script>