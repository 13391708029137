<template>
  <div class="container-fluid message-reply-container">
    <div class="row">
      <b-card no-body class="message-reply-form" id="reply">
        <b-tabs card>
          <b-tab
              no-body
              :aria-controls="'collapse-reply'"
          >
            <template slot="title">
              <font-awesome-icon :icon="'reply'" class="plus__icon"/>
              {{$t('default.reply')}}
            </template>
            <b-collapse :id="'collapse-reply'" v-model="visible" class="mt-2">
              <div class="container-fluid">
                <div class="row">
                  <div class="col mt-2">
                    <div class="form-group">
                      <b-form-textarea
                          ref="textarea"
                          class="form-control message-reply-textarea"
                          :rows="2"
                          :max-rows="8"
                          v-model="form.status"
                          :placeholder="$t('default.reply_desc')"
                      ></b-form-textarea>
                    </div>
                  </div>

                  <!-- Spacing -->
                  <div class="w-100 border-bottom"></div>

                  <!-- Text area form -->
                  <div class="row mx-2" style="width: 100%">
                    <div class="container-fluid">
                      <div class="align-items-center my-1">
                        <div>
                          <b-button-toolbar key-nav aria-label="Toolbar with button groups">
                            <b-button-group class="m-1">
                              <!-- Reply -->
                              <b-btn variant="light"
                                     @click="submit"
                                     :disabled="form.status.length < 2"
                                     >
                                <font-awesome-icon :icon="'pencil-alt'" class="plus__icon"/>
                                {{ $t('default.reply') }}
                              </b-btn>
                            </b-button-group>
                            <b-button-group class="m-1">

                              <!-- Attachment -->
                              <b-btn variant="light" @click="attachment = !attachment">
                              <font-awesome-icon :icon="'paperclip'" class="plus__icon"/>
                                {{ $t('page.attachment') }}
                              </b-btn>

                            </b-button-group>
                            <b-button-group class="m-1">

                              <!-- Reset-->
                              <b-btn variant="light"
                                     v-b-tooltip.hover>
                                <font-awesome-icon :icon="'redo'" class="plus__icon"/>
                                {{ $t('default.reset') }}
                              </b-btn>

                            </b-button-group>
                            <coolpicker
                                :emojiData="emojiDataAll"
                                :emojiGroups="emojiGroups"
                                :btnEmojiClasses="['btn-emoji']"
                                @emojiUnicodeAdded="add_emoji"></coolpicker>
                          </b-button-toolbar>
                          <div v-if="attachment">
                            <div>
                              <hr>
                              <b-container>
                                <b-row class="align-items-center">
                                  <b-col cols="9">

                                    <file-pond

                                        name="files"
                                        :files="form.images"


                                        ref="pond"

                                        allowFileSizeValidation="true"
                                        allowFileTypeValidation="true"
                                        allowReplace="false"
                                        allowRevert="false"
                                        instantUpload="false"


                                        :allowMultiple="getSettings.allowMultiple"
                                        :maxFileSize="getSettings.maxFileSize"
                                        :maxFiles="getSettings.maxFiles"
                                        :acceptedFileTypes="getSettings.acceptedFileTypes"

                                        @addfile="addFile"
                                        @addfilestart="addFileCheck"

                                        @removefile="removeFileCheck"

                                        :label-idle="$t('page.upload_file')"

                                        v-on:init="handleFilePondInit"

                                    />

                                  </b-col>


                                </b-row>
                              </b-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </b-collapse>
          </b-tab>


          <b-tab>

            <template v-slot:title>
              <div>
                <font-awesome-icon :icon="'clock'" :class="'plus__icon'"/>
                {{ $t('ticket.schedule') }}
              </div>

            </template>

            <form>
              <b-form-group label="Schedule Date:" horizontal>

                <datetime

                    ref="date-picker"
                    type="datetime"
                    v-model="form.schedule_at"
                    :min-datetime="schedule_start_date"
                    format="yyyy-MM-dd HH:mm"
                    input-class="form-control"
                    class="theme-message"
                ></datetime>

              </b-form-group>

              <b-form-group horizontal>
                <b-button-toolbar key-nav  aria-label="Toolbar with button groups" class="mt-2">


                  <b-button-group class="mx-1">

                    <!-- Reset -->
                    <b-btn variant="light" v-b-tooltip.hover title="Reset" @click="resetScheduleDate"> <font-awesome-icon :icon="'redo'" class="plus__icon" />
                      Reset
                    </b-btn>

                  </b-button-group>


                </b-button-toolbar>
              </b-form-group>


            </form>

          </b-tab>

        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  TwemojiPicker
} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';
import {DateTime} from "luxon";
import {mapActions, mapGetters} from "vuex";

// Import Vue FilePond
import vueFilePond from 'vue-filepond';

// Import validate type
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import validate size
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


export default {

  components: {

    'coolpicker': TwemojiPicker,
    FilePond

  },
  name: "NewPost",
  data(){
    return{
      settings: {
        attachments: true,
        allowMultiple: true,
        maxFileSize: '5mb',
        maxFiles: 4,
        acceptedFileTypes: 'image/jpeg, image/png, image/gif, video/mp4'
      },
      visible: true,
      attachment:false,
      form:{
        status : '',
        schedule_at: '',
        files: null,
        account_id: this.$route.params.id,
        type : this.$route.params.int
      },
      schedule_start_date: DateTime.local().plus({ minutes: 1 }).toISO(),
      images :[],
    }
  },
  methods: {
    handleFilePondInit: function() {
      console.log('FilePond has initialized');

      // FilePond instance methods are available on `this.$refs.pond`
    },
    add_emoji(emoji){
      let textarea = this.$refs.textarea
      let cursorPosition = textarea.selectionStart
      var output = [this.form.status.slice(0, cursorPosition), emoji, this.form.status.slice(cursorPosition)].join('');
      this.form.status = output
    },
    resetScheduleDate(){
      this.form.schedule_date = ''
    },
    submit(){
      if (this.$refs.pond){
        this.form.files = this.$refs.pond.getFiles()
      }
      this.storePost(this.form)
    },



    addFile: function(error, file) {


      console.log('File added');
      console.log(file);
      // file.setMetadata('account_id', this.getCustomer.account_id);

      console.log('Hook New file has been added ');
      // Check files
      //this.checkFile();
      //Set Images to Array
      //this.myFiles = files(files => files.file);
      console.log(this.$refs.pond.getFiles());
      //console.log(file.getMetadata('account_id'));


      /*//Assign all fields to formData to send via post
      const formData = new FormData();

      //Set all files to formData
      for (let i = 0; i < this.myFiles.length; i++) {
          let file = this.myFiles[i];
          formData.append("files[" + i + "]", file);
      }

      this.$store
          .dispatch("Reply/updateAttachFiles", formData)
          .then(response => {
              this.$snotify.success("Success");
          })
          .catch(error => {
              this.$snotify.error("Error");
              //console.log(error);

          });*/
      //console.log(this.$refs.pond.status);
    },

    addFileCheck: function(file) {

      console.log('File started to load');
      console.log(file);



      console.log('Hook New file trying to be added ');

      // Check files
      this.checkFile(file);
    },

    handleProcessFile: function(error, file) {
      console.log('done', file.serverId);
      this.updateAttachFiles(file.serverId);
      console.log('attached files: ', this.totalFiles);
    },




    checkFile: function(file){

      let total = this.checkTotalFiles();
      // Add Pixel/Makro Photo in Counts
      if (this.getPixelImage){
        this.settings.maxFiles = this.integration_type.attachment_max_file_count - 1;
        total = total + 1;
      }
      if (this.getPixelImage && this.getSettings.maxFiles === 1){
        this.removeFiles();
      }

      console.log('total files' + total);

      if(this.$route.params.int == 'twitter'){

        console.log('file type is ' + file.fileType);

        if(file.fileType == 'video/mp4' || file.fileType === 'image/gif')
        {

          this.settings.acceptedFileTypes = 'video/mp4';

          this.settings.maxFiles = 1;

          // Total files more than one
          // Remove all files detka
          if(total > 1){

            this.removeFiles();
          }

        } else {

          //alert('attachment type is image');
          this.settings.maxFiles = 4;
          this.settings.acceptedFileTypes = 'image/jpeg, image/png';

        }


      }

      //}

    },


    checkTotalFiles: function(){

      return this.$refs.pond.getFiles().length;

    },

    removeFiles: function(){


      return this.$refs.pond.removeFiles();

    },

    removeFileCheck: function(){

      let total = this.checkTotalFiles();

      if(!total){

        // Reset datas
        this.settings.acceptedFileTypes = null;
        this.settings.maxFiles = null;

      }

    },


    ...mapActions('Post', [
      'storePost'
    ]),
  },
  computed: {


    getSettings: function(){

      return {
        attachments: this.settings.attachments,
        allowMultiple: this.settings.allowMultiple,
        maxFileSize: this.settings.maxFileSize,
        maxFiles: this.settings.maxFiles,
        acceptedFileTypes: this.settings.acceptedFileTypes

      };

    },
    // Emoji data
    emojiDataAll() {
      return EmojiAllData;
    },
    // Emoji Groups
    emojiGroups() {
      return EmojiGroups;
    }
  }
}
</script>

<style scoped>

</style>