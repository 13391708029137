const state = {

    trash_list_api: '/api/v1/trash/list',
    trash_restore_api: '/api/v1/trash/restore',

    customers: [],

    is_customer: true,

    is_ticket: false,

    // Total
    total: false,
    // Current page
    current: false,
    // Per page
    perpage: false,

    // Permission
    permission: '',

};

// getters
const getters = {

    // Get customers
    getCustomers: state => {
        // Return customers
        return state.customers;
    },

    // Get messages
    getMessages: (state) => {
        // Return messages
        return state.messages;
    },

    // Get is customer
    getIsCustomer: (state) => {

        return state.is_customer;

    },

    // Get is ticket
    getIsTicket: (state) => {

        return state.is_ticket;

    },

    // Return total page
    getTotal: (state) => {
        return state.total;
    },

    // Return current page
    getCurrent: (state) => {
        return state.current;
    },

    // Return per page
    getPerPage: (state) => {
        return state.perpage;
    },

    // Return Permission
    getPermission: (state) => {
        return state.permission;
    },

};

// mutations
const mutations = {

    // Set total
    setTotal (state, total){
        state.total = total;
    },

    // Set total
    setCurrent (state, current){
        state.current = current;
    },

    // Set per page
    setPerPage (state, perpage){
        state.perpage = perpage;
    },


    // Set customers
    setCustomers (state, customers){
        state.customers = customers;
    },

    // Set messages
    setMessages (state, messages){
        state.messages = messages;
    },

    // Set Permission
    setPermission (state, permission){
        state.permission = permission;
    },

    setActive(state, active){

        if(active === 'customer'){

            // Set customer to true
            state.is_customer = true;

            // Set ticket to false
            state.is_ticket = false;
        }

        if(active === 'ticket'){

            // Set customer to false
            state.is_customer = false;

            // Set ticket to true
            state.is_ticket = true;
        }

    },

};

// actions
const actions = {

    // get customers
    async fetchInbox({ dispatch, commit, state }, data){

        // Start waiting
        dispatch('wait/start', 'list', { root: true });

        console.log('dump data');
        console.log(data);

        // Start waiting
        dispatch('wait/start', 'list', { root: true });

        // Get Accounts
        await axios.get(state.trash_list_api + '?account_id=' + data.account_id + '&page=' + data.page)

            .then((response) => {
                //console.log(response);
                if(response.data.error){
                    commit('setPermission', Vue.i18n.translate('permission.denied'));
                    console.log(Vue.i18n.translate('permission.denied'));
                    commit('setCustomers', []);
                    // Stop waiting
                    dispatch('wait/end', 'list', { root: true });
                }else {
                    commit('setPermission', '');
                    // Assign accounts
                    commit('setCustomers', response.data.data);

                    // Set current
                    commit('setCurrent', response.data.current_page);

                    // Set perpage
                    commit('setPerPage', response.data.per_page);

                    // Set total
                    commit('setTotal', response.data.total);

                    // Stop waiting
                    dispatch('wait/end', 'list', { root: true });
                }
            })
            .catch((error) => {

                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'list', { root: true });

            });

    },

    // Update customers
    async updateInbox({ dispatch, commit, state }, data){

        console.log('dump data');
        console.log(data);


        // Get Accounts
        await axios.get(state.trash_list_api + '?account_id=' + data.account_id + '&page=' + data.page)

            .then((response) => {

                // Assign accounts
                commit('setCustomers', response.data.data);

                // Set current
                commit('setCurrent', response.data.current_page);

                // Set perpage
                commit('setPerPage', response.data.per_page);

                // Set total
                commit('setTotal', response.data.total);

            })
            .catch((error) => {

                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);


            });

    },



     async createTrash({ rootGetters }){
        // Create Trash
        //console.log(data);
        let trash_api = '/api/v1/trash/store';
        let account_id = rootGetters['Accounts/getAccountID'];
        let user_id = rootGetters['User/getUserID'];
        let customer_id = rootGetters['Customer/getCustomerID'];
        await axios.post(trash_api, {
            account_id: account_id,
            user_id: user_id,
            customer_id: customer_id,
        }).then((response) => {

                console.log(response);


                // Show success
                Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

            }).catch((error) => {
                // Show error
                Vue.toasted.error(error.response.data.message,{duration:5000});

                // Log to console
                console.log(error);

            });
    },

    async restoreTrash({ rootGetters, dispatch, state }, data){
        // Restore Trash
        let restore_api = '/api/v1/trash/restore';
        let account_id = rootGetters['Accounts/getAccountID'];
        let user_id = rootGetters['User/getUserID'];
        let customer_id = rootGetters['Customer/getCustomerID'];
        // Start waiting
        dispatch('wait/start', 'restore', { root: true });

        // Post request
        await axios.post(restore_api, {
            account_id: account_id,
            user_id: user_id,
            customer_id: customer_id,
            removed_by: user_id,
        }).then((response) => {

                // Show success
                Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

                // Stop waiting
                dispatch('wait/start', 'restore', { root: true });


            }).catch((error) => {

                // Show error
                Vue.toasted.error(error.response.data.message,{duration:5000});

                // Stop waiting
                dispatch('wait/end', 'restore', { root: true });


            });
    },

    // Get inbox messages
    updateActive({ dispatch, commit, state }, active){

        // Assign customer
        commit('setActive', active);

    },


};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}