<template>

    <b-form-group>

        <!--&lt;!&ndash; Mode &ndash;&gt;-->
        <!--<b-form-group label-for="font"-->
                      <!--:label="$t('pixel.mode')">-->

            <!--<b-form-select v-model="mode" class="mb-3" @input="update">-->

                <!--<option value="pencil">{{ $t('pixel.pencil') }}</option>-->
                <!--<option value="Circle">{{ $t('pixel.circle') }}</option>-->
                <!--<option value="Spray">{{ $t('pixel.spray') }}</option>-->
                <!--<option value="Pattern">{{ $t('pixel.pattern') }}n</option>-->
                <!--<option value="hline">{{ $t('pixel.hline') }}</option>-->
                <!--<option value="vline">{{ $t('pixel.vline') }}</option>-->
                <!--<option value="square">{{ $t('pixel.square') }}</option>-->
                <!--<option value="diamond">{{ $t('pixel.diamond') }}</option>-->
                <!--<option value="texture">{{ $t('pixel.texture') }}</option>-->

            <!--</b-form-select>-->
            <!--<b-form-select v-model="config.fontFamily" :options="fonts" text-field="name" @input="update"/>-->

        <!--</b-form-group>-->

        <!-- Line width -->
        <b-form-group label-for="line_width"
                      :label="$t('pixel.line_width')">

            <VueSlideBar
                    id="line_width"
                    v-model="width"
                    :min="0"
                    :max="150"
                    @input="update_width"
            >
            </VueSlideBar>

        </b-form-group>

        <!-- Line color -->
        <b-form-group label-for="line_color"
                      :label="$t('pixel.line_color')">

            <input type="color" v-model="color" @input="update_color">

        </b-form-group>

        <!--&lt;!&ndash; Line shadow &ndash;&gt;-->
        <!--<b-form-group label-for="line_shadow"-->
                      <!--:label="$t('pixel.line_shadow')">-->

            <!--<VueSlideBar-->
                    <!--id="line_shadow"-->
                    <!--v-model="config.line_shadow"-->
                    <!--:min="0"-->
                    <!--:max="50"-->
                    <!--@input="update"-->
            <!--&gt;-->
            <!--</VueSlideBar>-->

        <!--</b-form-group>-->

    </b-form-group>

</template>

<script>

    import VueSlideBar from 'vue-slide-bar';

    import { Sketch } from 'vue-color';

    export default {
        
        components: {

          VueSlideBar,

         'sketch-picker': Sketch,

        },

        props: ['width', 'color'],

        mounted: function () {
            // Component mounted
            console.log('Pixel Draw mounted');
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },
        

        data: function() {
            return  { }
        },

        methods: {

            // Width
            update_width: function(){
                // Sent to parent
                this.$emit('update_draw', 'line_width', this.width());
            },

            // Color
            update_color: function(){
                // Sent to parent
                this.$emit('update_draw', 'line_color', this.color);
            },

        },
        computed: {
        },
        
    }
</script>