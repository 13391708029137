var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr'),_vm._v(" "),_c('b-container',[_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"cols":"9"}},[(_vm.getSettings.attachments)?_c('file-pond',{ref:"pond",attrs:{"name":"files","server":{
                        url: '/api/v1/inbox/answer/upload',
                        process: {
                            headers: {
                                'X-CSRF-TOKEN': _vm.csrfToken
                            }
                        }
                    },"files":_vm.myFiles,"allowFileSizeValidation":"true","allowFileTypeValidation":"true","allowReplace":"false","allowRevert":"false","instantUpload":"false","allowMultiple":_vm.getSettings.allowMultiple,"maxFileSize":_vm.getSettings.maxFileSize,"maxFiles":_vm.getSettings.maxFiles,"acceptedFileTypes":_vm.getSettings.acceptedFileTypes,"label-idle":_vm.$t('page.upload_file'),"beforeAddFile":_vm.beforeAddFile},on:{"init":_vm.handleFilePondInit,"processfile":_vm.handleProcessFile,"addfile":_vm.addFile,"addfilestart":_vm.addFileCheck,"removefile":_vm.removeFileCheck}}):_vm._e()],1),_vm._v(" "),(_vm.getPixelImage)?_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"shadow border border-danger mb-3 pointer",on:{"click":_vm.pixel}},[_c('b-img',{attrs:{"thumbnail":"","fluid":"","src":_vm.getPixelImage}})],1)]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }