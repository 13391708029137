<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('macros.send_template_message') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <v-wait for="whatsapp_campaign_add_user">
                    <loading slot="waiting" />
                    <div class="modal-body">
                        <b-row class="align-items-center mb-3">
                            <b-col sm="2">
                                <label for="campaign_audience">{{ $t('simple.file') }}<span
                                        class="text-danger"></span>:</label>
                            </b-col>
                            <b-col sm="12" lg="4" class="mb-3">
                                <div :class="{ 'isInvalid': !file }"
                                    class="media-upload-container d-flex justify-content-center align-items-center">
                                    <div class="position-absolute">
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                            <span class="media-upload-inner-container text-muted font-weight-normal"
                                                v-if="!file">{{ $t('macros.attach_file') }}</span>
                                            <span class="media-upload-inner-container text-muted font-weight-normal"
                                                v-else>{{ $t('macros.change_file') }}</span>
                                            <span
                                                class="media-upload-inner-container text-muted font-weight-normal">(CSV
                                                or Excel)</span>
                                            <span class="media-upload-inner-container text-muted font-weight-normal">
                                                (Max 50MB)</span>
                                        </div>
                                    </div>
                                    <b-form-file id="campaign_audience" v-model="file" plain
                                        :accept="whatsapp_campaign_settings.document_accepted_types"
                                        @input="addFile(file, whatsapp_campaign_settings.document_size_limit)"
                                        class="media-upload-button h-100 w-100">
                                    </b-form-file>
                                </div>
                            </b-col>
                            <b-col sm="4" v-if="file" class="mb-3">
                                <div class="position-relative media-preview-container d-flex">
                                    <div class="align-self-center p-1">
                                        <font-awesome-icon :icon="'file'" />
                                        <span>{{ file.name }}</span>
                                    </div>
                                    <b-btn size="sm" class="preview-remove-button position-absolute" variant="danger"
                                        @click="removeFile">
                                        <font-awesome-icon :icon="'times'" />
                                    </b-btn>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="align-items-center ml-3 mb-3">
                            <b-col>

                                <ul>
                                    <li class="text-muted"><a href="/documents/campaign_add_users.xls" download>Download
                                            Sample File</a></li>
                                    <li class="text-muted">{{ $t('simple.audience_col_desc') }}</li>
                                    <li class="text-muted">{{ $t('simple.audience_phone_desc') }}</li>
                                </ul>
                            </b-col>
                        </b-row>
                        <b-row class="align-items-center p-2 mb-3">
                            <b-col>
                                <b-form-checkbox v-model="authorizationConfirmation" class="font-weight-bold">{{
                                    $t('simple.audience_auth') }}
                                    <span class="text-danger">*</span></b-form-checkbox>
                            </b-col>
                        </b-row>
                    </div>

                    <div class="modal-footer">

                        <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

                        <b-button variant="success" @click.prevent="uploadAudience"
                            :disabled="!(authorizationConfirmation && file)">{{ $t('simple.add_users') }}</b-button>

                    </div>
                </v-wait>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';


export default {

    async beforeDestroy() {
        await this.fetchWhatsappCampaingUsers({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id })
    },
    data() {
        return {
            file: null,
            authorizationConfirmation: true,

        }
    },
    methods: {

        ...mapActions('Templates', [
            'addUserToWhatsappCampaign',
            'fetchWhatsappCampaingUsers'
        ]),
        addFile(file, limit) {
            if (file && !this.checkFileSize(file, limit)) {
                Vue.toasted.error(this.$t('macros.size_limit_alert', {
                    size_limit: limit,
                }));
                this.removeFile();
            }

        },

        checkFileSize(file, size_limit) {
            if (((file.size / 1024) / 1024).toFixed(4) > size_limit) {
                return false
            }

            return true
        },

        removeFile() {
            this.file = null;
        },

        async uploadAudience() {
            if (this.file) {
                const formData = new FormData();
                formData.append('excel', this.file);

                await this.addUserToWhatsappCampaign({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id, file: formData })

                this.$close(true);

            }

        },
    },

    computed: {
        ...mapGetters({
            whatsapp_campaign_settings: 'Templates/getWhatsappCampaignSettings',
        }),
    }
}
</script>