<template>

    <div class="container-fluid">

        <div class="row" v-if="instagram.followers && instagram.following">

            <div class="col-sm-5">

                <h6 class="mb-0">{{ instagram.followers }} </h6>

                <span class="text-muted">{{ $t('customer.followers') }}</span>

            </div>

            <div class="col-sm-2">

                <span class="panel-divider"></span>

            </div>

                <div class="col-sm-5">

                    <h6 class="mb-0">{{ instagram.following }} </h6>

                    <span class="text-muted">{{ $t('customer.following') }}</span>

                </div>

        </div>

        <hr>

        <div class="row flex-column">

            <!-- About -->
            <div class="panel-title panel-title pb-2 text-uppercase text-muted">{{ $t('customer.about') }}</div>

            
            <!-- Name -->
            <div class="pb-2">

                <font-awesome-icon :icon="'user'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.name')"/> <span class="font-weight-light ml-2">{{instagram.name }}</span>

            </div>

          <!-- Biography -->
            <div class="pb-2" v-if="instagram.biography">

                <font-awesome-icon :icon="'signature'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.biography')"/> <span class="font-weight-light ml-2">{{instagram.biography }}</span>

            </div>

            <!-- Media Count -->
            <div class="pb-2" v-if="instagram.media_count">

                <font-awesome-icon :icon="'user-edit'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.media_count')"/> <span class="font-weight-light ml-2">{{ instagram.media_count }}</span>

            </div>

            <!-- Verified -->
            <div class="pb-2" v-if="instagram.verified">

                <font-awesome-icon :icon="'user-check'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.verified')"/> <span class="font-weight-light ml-2">{{ $t('customer.verified') }}</span>

            </div>

            <!-- Url -->
            <div class="pb-2" v-if="instagram.username">

                <font-awesome-icon :icon="'link'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.url')"/> <span><b-link :href="'https://instagram.com/' + instagram.username" class="font-weight-light ml-2" target="_blank" rel="noopener noreferrer">{{ $t('simple.view_profile') }}</b-link></span>

            </div>


        </div>

    </div>


</template>

<script>

    export default {

        props: ['instagram'],

        mounted: function () {
            // Component mounted
            //console.log('Customer Type Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Type Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {

                moment: this.$moment,

            };

        },

        computed: {


            // Instagram verified
            instagram_verified: function () {

                if(this.instagram.verified){

                    return 'simple.yes';

                } else {

                    return 'simple.no';

                }
            },


            // Instagram protected
            instagram_protected: function () {

                if(this.instagram.protected){

                    return 'simple.yes';

                } else {

                    return 'simple.no';

                }
            },


        },
        
        methods: {},
        
    }
</script>