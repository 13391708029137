<template>
    <div>
        <template v-if="accounts.length">
        <li
            v-for="account in accounts"

            :key="account.id"

            :class="{ 'c-sidebar__item': true, 'has-submenu': true, 'is-open' : check_menu(account.id) }"

            >

            <a
                :class="{ 'c-sidebar__link' : true, 'is-active' : check_menu(account.id)}"

                :href="'#' + account.id"

                data-toggle="collapse"

                aria-expanded="false"

                aria-controls="siebar-menu"

                @click="getSubMenu(account.id)"

                >

                        <span class="c-sidebar__icon ">
                            <font-awesome-icon :icon="['fab', account.integration.icon]" />
                        </span>

                        <div class="c-tooltip c-tooltip--top" :aria-label="account.name">
                            {{ account.name | truncate(22) }}
                        </div>

            </a>
            <ul
                :class="{

                        'c-sidebar__submenu' : true,

                        'show' :  check_menu(account.id),

                        'collapse' : true

                        }"

                :id="account.id"

                >

                <!-- Questions -->
                <li>
                    <router-link

                        class="c-sidebar__link"

                        :to="{
                            name: 'questions',
                            params: { id: account.id },
                        }"

                        >
                        <span class="c-sidebar__icon">
                            <font-awesome-icon :icon="'inbox'" />
                        </span>
                            {{  $t('page.questions') }}
                    </router-link>
                </li>

                <!-- Answers -->
                <li>
                    <router-link
                        class="c-sidebar__link"

                        :to="{
                            name: 'account',
                            params: { id: account.id },
                            query: { view: 'answers' }
                        }"
                        >
                        <span class="c-sidebar__icon">
                            <font-awesome-icon :icon="'comments'" />
                        </span>
                            {{  $t('page.answers') }}
                    </router-link>
                </li>

                <!-- Transfers -->
                <li>
                    <router-link
                        class="c-sidebar__link"

                        :to="{
                            name: 'account',
                            params: { id: account.id },
                            query: { view: 'transfers' }
                        }">
                        <span class="c-sidebar__icon">
                            <font-awesome-icon :icon="'angle-double-right'" />
                        </span>
                            {{  $t('page.transfers') }}
                    </router-link>
                </li>

                <!-- Tickets -->
                <li>
                    <router-link
                        class="c-sidebar__link"

                        :to="{
                            name: 'tickets',
                            params: { id: account.id }
                        }">
                        <span class="c-sidebar__icon">
                            <font-awesome-icon :icon="'ticket-alt'" />
                        </span>
                            {{  $t('page.tickets') }}
                    </router-link>
                </li>

                <!-- Confirmations -->
                <!--<li>
                    <router-link
                        class="c-sidebar__link"

                        :to="{
                            name: 'account',
                            params: { id: account.id },
                            query: { view: 'confirmations' }
                        }">
                        <span class="c-sidebar__icon">
                            <font-awesome-icon :icon="'check-circle'" />
                        </span>
                            {{  $t('page.confirmations') }}
                    </router-link>
                </li>-->

                <!-- Spam -->
                <!--<li>
                    <router-link
                        class="c-sidebar__link"

                        :to="{
                            name: 'account',
                            params: { id: account.id },
                            query: { view: 'spam' }
                        }"
                        >
                        <span class="c-sidebar__icon">
                            <font-awesome-icon :icon="'trash'" />
                        </span>
                            {{  $t('page.spam') }}
                    </router-link>
                </li>-->

                <!-- Trash -->
                <li>
                    <router-link
                        class="c-sidebar__link"

                        :to="{
                            name: 'account',
                            params: { id: account.id },
                            query: { view: 'spam' }
                        }"
                        >
                        <span class="c-sidebar__icon">
                            <font-awesome-icon :icon="'trash'" />
                        </span>
                            {{  $t('page.trash') }}
                    </router-link>
                </li>

                <!-- Twitter only -->
                <template v-if="account.integration_id == 'twitter'">

                    <!-- Track list -->
                    <li>
                        <router-link
                            class="c-sidebar__link"

                            :to="{
                                name: 'tracks',
                                params: { id: account.id }
                            }"
                            >
                            <span class="c-sidebar__icon">
                                <font-awesome-icon :icon="'anchor'" />
                            </span>
                                {{  $t('page.tracks') }}
                        </router-link>
                    </li>

                    <!-- Mute List -->
                    <li>
                        <router-link
                            class="c-sidebar__link"

                            :to="{
                                name: 'mutes',
                                params: { id: account.id }
                            }"
                            >
                            <span class="c-sidebar__icon">
                                <font-awesome-icon :icon="'microphone-slash'" />
                            </span>
                                {{  $t('page.mutes') }}
                        </router-link>
                    </li>

                    <!-- Block List -->
                    <li>
                        <router-link
                            class="c-sidebar__link"

                            :to="{
                                name: 'blocks',
                                params: { id: account.id }
                            }"
                            >
                            <span class="c-sidebar__icon">
                                <font-awesome-icon :icon="'ban'" />
                            </span>
                                {{  $t('page.blocks') }}
                        </router-link>
                    </li>

                </template>

            </ul>
        </li>
        </template>
        <!-- Management (only visible to admins) -->
        <li
            :class="{
                    'c-sidebar__item' : true,
                    'has-submenu' : true,
                    'is-open' : check_menu('management')
                }"
            >

            <a
                :class="{
                    'c-sidebar__link' : true,
                    'is-active' : check_menu('management')
                }"
                :href="'#configuration'"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="siebar-menu"

             @click="(result) => { getSubMenu('management') }">

                        <span class="c-sidebar__icon ">
                            <font-awesome-icon :icon="'lock'"/>
                        </span>
                        {{  $t('page.management') }}
            </a>

            <ul
                id="configuration"

                :class="{
                    'c-sidebar__submenu' : true,
                    'show' :  check_menu('configuration'),
                    'collapse' : true
                }"
                >
                <!-- Configurations -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/configurations">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'cogs'" />
                    </span> {{ $t('page.configurations') }}</router-link>
                </li>

                <!-- Accounts -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/accounts">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'users'" />
                    </span> {{ $t('page.accounts') }}</router-link>
                </li>

                <!-- Announcements -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/announcements">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'bullhorn'" />
                    </span> {{ $t('page.announcements') }}</router-link>
                </li>

                <!-- Categories -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/categories">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'book'" />
                    </span> {{ $t('page.categories') }}</router-link>
                </li>

                <!-- Category Types -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/category/types">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'briefcase'" />
                    </span> {{ $t('page.categorytypes') }}</router-link>
                </li>

                <!-- Customer Types -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/customer/types">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'user-circle'" />
                    </span> {{ $t('page.customertypes') }}</router-link>
                </li>

                <!-- Customer Genders -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/customer/genders">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'user-circle'" />
                    </span> {{ $t('page.customer_genders') }}</router-link>
                </li>

                <!-- Gallery -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/gallery">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'images'" />
                    </span> {{ $t('page.gallery') }}</router-link>
                </li>

                <!-- Tags -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/tags">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'tags'" />
                    </span> {{ $t('page.tags') }}</router-link>
                </li>

                <!-- Users -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/users">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'users-cog'" />
                    </span> {{ $t('page.users') }}</router-link>
                </li>

                <!-- Teams -->
                <li>
                    <router-link class="c-sidebar__link" to="/management/teams">
                    <span class="c-sidebar__icon">
                        <font-awesome-icon :icon="'user-shield'" />
                    </span> {{ $t('page.teams') }}</router-link>
                </li>

            </ul>
        </li>
        <!-- End Management -->

    </div>

</template>

<script>

    export default {
        mounted: function () {
            console.log('Account sidebar mounted.')
        },

        created: function() {
          console.log('Account sidebar created.')
           this.getAccounts();
        },

        data: function() {
             return  {
               accounts: [],
               active_menu: ''
             }
        },

        methods: {

            getSubMenu: function(id){
                this.active_menu = id;
            },

            check_menu: function(id){
                if(id === this.active_menu){
                    return true;
                } else {
                    return false;
                }
            },

            getAccounts: async function(){

                // Loading start
                this.$eventLoading.$emit('start');

                // Get Accounts
                await axios.get('/api/v1/user/accounts/')
                  .then((response) => {
                    // response data
                    this.accounts = response.data;
                    //console.log(response);

                    // Loading stop
                    this.$eventLoading.$emit('stop');

                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    console.log(error);

                    // Loading stop
                    this.$eventLoading.$emit('stop');

                  });

            },

        },
    }
</script>