const state = {

  total: false,
  current: false,
  perpage: false,
  requests: [],
  note: false,
  log_requests: [],
  confirmations: [],
  confirmation_logs: [],
  permission: '',
  alert: '',
  active_tab_id: '',

};

// getters
const getters = {

  // Get Alert
  getAlert: (state) => {
    return state.alert;
  },

  // Get requests
  getRequests: (state) => {
    return state.requests;
  },

  // Get Log requests
  getLogRequests: (state) => {
    return state.log_requests;
  },

  // Get confirmations
  getConfirmations: (state) => {
    return state.confirmations;
  },

  // Get confirmation logs
  getConfirmationLogs: (state) => {
    return state.confirmation_logs;
  },

  // Return total
  getTotal: (state) => {
    return state.total;
  },

  // Get note
  getNote: (state) => {
    return state.note;
  },

  // Return current
  getCurrent: (state) => {
    return state.current;
  },

  // Return per page
  getPerPage: (state) => {
    return state.perpage;
  },

  // Return Permission
  getPermission: (state) => {
    return state.permission;
  },

  // Return Active Tab Id
  getActiveTabId: (state) => {
    return state.active_tab_id;
  },

};

// mutations
const mutations = {

  // Set total
  setTotal (state, total){
    state.total = total;
  },

  // Set total
  setCurrent (state, current){
    state.current = current;
  },

  // Set per page
  setPerPage (state, perpage){
    state.perpage = perpage;
  },

  // Set requests
  setRequests (state, requests){
    state.requests = requests;
  },

  // Set Note
  setNote (state, note) {
    state.note = note;
  },

  // Set log requests
  setLogRequests (state, requests){
    state.log_requests = requests;
  },

  // Set confirmations
  setConfirmations (state, confirmations){
    state.confirmations = confirmations;
  },

  // Set confirmation Logs
  setConfirmationLogs (state, confirmation_logs){
    state.confirmations = confirmation_logs;
  },

  // Set Permission
  setPermission (state, permission){
    state.permission = permission;
  },

  // Set Alert
  setAlert (state, data){
    state.alert = data;
  },

  // Set Active Tab Id
  setActiveTabId (state, payload){
    state.active_tab_id = payload;
  },

};

// actions
const actions = {

  // Refresh Messages
  refreshRequests( { dispatch, state }){

    dispatch('fetchConfirmations', state.current);

  },

  resetPermission( { commit, state }){

    commit('setPermission', '');

  },

  // Get confirmation requests
  async fetchConfirmations({ dispatch, commit}, page){

    // Check if we have page set or set it to default?
    let current_page = page ? page : 1;

    commit("setRequests", []);

    // Start waiting
    dispatch('wait/start', 'confirmations', { root: true });

    let confirmations_api = '/api/v1/inbox/confirmations';

    // Clear pagination data
    dispatch('clearPagination');

    // Get Confirmations
    await axios.get(confirmations_api + '?page=' + current_page)
        .then((response) => {

          if (response.data.error) {
            commit("setPermission", response.data.message);

          } else {
            // Assign messages
            commit("setRequests", response.data.data);

            // Assign messages
            commit("setNote", null);

            // Set current
            commit("setCurrent", response.data.current_page);

            // Set perpage
            commit("setPerPage", response.data.per_page);

            // Set total
            commit("setTotal", response.data.total);
          }
        
          // Stop waiting
          dispatch('wait/end', 'confirmations', { root: true });

        })
        .catch((error) => {

          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'messages', { root: true });

        });

  },

  // Refresh Log Messages
  async refreshLogRequests( { dispatch, state }){

    // Refresh Log messages
    dispatch('fetchConfirmationLogs', state.current);

  },

  // Get confirmation logs
  async fetchConfirmationLogs({ dispatch, commit, rootGetters}, page){

    // Check if we have page set or set it to default?
    let current_page = page ? page : 1;

    // Start waiting
    dispatch('wait/start', 'confirmation_logs', { root: true });

    let confirmations_api = '/api/v1/inbox/confirmations/log';

    // Clear pagination data
    dispatch('clearPagination');

    // Get Confirmations
    await axios.get(confirmations_api + '?page=' + current_page)
        .then((response) => {

          if (response.data.error) {
            commit("setPermission", response.data.message);

          } else {
            // Assign messages
            commit("setLogRequests", response.data.data);

            // Set current
            commit("setCurrent", response.data.current_page);

            // Set perpage
            commit("setPerPage", response.data.per_page);

            // Set total
            commit("setTotal", response.data.total);
          }
          

          // Stop waiting
          dispatch('wait/end', 'confirmation_logs', { root: true });

        })
        .catch((error) => {

          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'messages', { root: true });

        });

  },

  // Update page
  updatePage({ dispatch }, data) {

    // Update confirmations
    dispatch('fetchConfirmations', data);

  },

  // Update logs page
  updateLogPage({ dispatch }, data) {

    // Update confirmation logs
    dispatch('fetchConfirmationLogs', data);

  },

  // Set Note Text
  async updateNote ( {commit}, note) {
    commit('setNote', note);
  },

  // Clear pagination data
  clearPagination({ commit }) {

    // Set total to false
    commit('setTotal', false);

    // Set current to false
    commit('setCurrent', false);

    // Set per page to false
    commit('setPerPage', false);

  },

  // update Confirmation
  async updateConfirmation({ dispatch, commit, state, getters, rootGetters}, confirmation){

    // Start waiting
    dispatch('wait/start', 'confirmations', { root: true });

    let confirmations_api = '/api/v1/inbox/confirmations/update';
    let note_content = getters.getNote;

    // Update confirmation
    await axios.put(confirmations_api, {

      confirmation_id: confirmation.id,
      status: confirmation.status,
      note: note_content,

      })
        .then(() => {

          // Show success
          Vue.toasted.success(Vue.i18n.translate('simple.success_info'));
          dispatch('fetchConfirmations');

        })
        .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

        });

    // Stop waiting
    dispatch('wait/end', 'confirmations', { root: true });

  },

  // Time Alert Setting
  timeAlert: function({commit, state}, data){

    // Unix Timestamp Conversion
    let timeNode = Vue.moment(data).valueOf();
    let nowNode = Vue.moment().valueOf();
    // Time Alert
    let actionNode = nowNode - timeNode;
    if(actionNode<86400){
      // Set per page to false
      commit('setAlert', 'success');
    } else if(86400>actionNode>86400){
      commit('setAlert', 'warning');
    } else {
      commit('setAlert', 'danger');
    }
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}