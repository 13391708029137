const state = {

  images: [],

  gallery_api: '/api/v1/system/gallery',

  total: 0,

  current: 1,

  per_page: false,

};

// getters
const getters = {

  // Get Images
  getImages: (state) => {
    // Return fields
    return state.images;
  },

  // Get Current
  getCurrent: (state) => {
    // Return fields
    return state.current;
  },

  // Get Per Page
  getPerPage: (state) => {
    // Return fields
    return state.per_page;
  },

  // Get Total
  getTotal: (state) => {
    // Return fields
    return state.total;
  },

};

// mutations
const mutations = {

  // Set Images
  setImages (state, images) {
    state.images = images;
  },

  // Set Current Page
  setCurrent (state, current) {
    state.current = current;
  },

  // Set Per Page
  setPerPage (state, per_page) {
    state.per_page = per_page;
  },

  // Set Total
  setTotal (state, total) {
    state.total = total;
  },

};

// actions
const actions = {

  // Update Macro
  async updateMacrosData({ dispatch, state }){

    // Check if don't have macros
    if(!state.macros_options){

      dispatch('fetchMacros');

    }

  },

  async fetchMacros({ dispatch, commit}){

    let macros = '/api/v1/system/macros';

    // Get categories
    await axios.get(macros)
        .then((response) => {

          // Set categories
          commit('setMacrosOptions', response.data);

          // Log to console
          console.log('Macros Data: ' + response.data);

        })

        .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

        });
  },

  // Get images
  async fetchImages({ dispatch, commit, state},page){

    // Check if we have page set or set it to default?
    let current_page = page ? page : 1;

    // Start waiting
    dispatch('wait/start', 'imagesData', { root: true });

    // Get gallery images
    await axios.get(state.gallery_api + '?page=' + current_page)

        .then((response) => {

          // response data
          commit('setImages', response.data.data);

          // Set current
          commit('setCurrent', response.data.current_page);

          // Per page
          commit('setPerPage', response.data.per_page);

          // Total
          commit('setTotal', response.data.total);

          // Stop waiting
          dispatch('wait/end', 'imagesData', { root: true });

        })
        .catch((error) => {

          // Stop waiting
          dispatch('wait/end', 'imagesData', { root: true });

          // Show error
          Vue.toasted.error(this.$t('simple.error_info'));

          // Log to console
          console.log(error);

        });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};