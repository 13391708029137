var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col pt-2"},[_c('div',{staticClass:"bg-white rounded py-2 shadow-widget border"},[_c('div',{staticClass:"message-actions notification-header sticky-top plus-page-header z-index-fix"},[_c('div',{staticClass:"container-fluid py-2"},[_c('h4',{staticClass:"notification-header-text"},[_vm._v(_vm._s(_vm.$t('page.notifications')))]),_vm._v(" "),_c('span',{staticClass:"text-muted font-weight-light"},[_vm._v(_vm._s(_vm.$t('page.notifications_desc')))])]),_vm._v(" "),_c('div',{staticClass:"mx-3 d-flex flex-column justify-contents-center align-items-center"},[(_vm.total)?_c('b-pagination',{attrs:{"hide-goto-end-buttons":true,"size":"sm","total-rows":_vm.total,"value":_vm.current,"per-page":_vm.perpage},on:{"input":_vm.updatePage}}):_vm._e(),_vm._v(" "),_c('b-button',{staticClass:"btn-sm",attrs:{"variant":'primary'},on:{"click":_vm.refreshNotifications}},[_c('font-awesome-icon',{attrs:{"icon":'sync'}}),_vm._v("\n                            "+_vm._s(_vm.$t('default.refresh'))+"\n                        ")],1)],1)]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"px-5"},[_c('v-wait',{attrs:{"for":"notifications"}},[_c('loading',{attrs:{"slot":"waiting"},slot:"waiting"}),_vm._v(" "),_c('ul',{staticClass:"notifications border"},[_vm._l((_vm.notifications),function(notification){return [_c('li',{staticClass:"notification"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body"},[(notification.data.notification_type == 'confirmation')?[_c('strong',{staticClass:"notification-title"},[_vm._v(_vm._s(_vm.$t('confirmation.title')))]),_vm._v(" "),_c('p',{staticClass:"notification-desc"},[_vm._v("\n                                            "+_vm._s(_vm.$t('confirmation.database_notify', {
                                                    id: notification.data.confirmation_id,
                                                    status: notification.data.confirmation_status,
                                                    reviewed_by: notification.data.confirmation_reviewed_by,
                                                    reviewed_at: _vm.$moment(_vm.$moment.utc(notification.data.confirmation_reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                                                }))+"\n                                            ")])]:_vm._e(),_vm._v(" "),(notification.data.notification_type == 'confirmation_request')?[_c('strong',{staticClass:"notification-title"},[_vm._v(_vm._s(_vm.$t('confirmation.request')))]),_vm._v(" "),_c('p',{staticClass:"notification-desc"},[_vm._v("\n                                                "+_vm._s(_vm.$t('confirmation.database_request', {
                                                        id: notification.data.confirmation_id,
                                                        requested_by: notification.data.confirmation_requested_by,
                                                        created_at: _vm.$moment(_vm.$moment.utc(notification.data.confirmation_created_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                                                    }))+"\n                                            ")])]:_vm._e(),_vm._v(" "),(notification.data.notification_type == 'confirmation_restricted_word')?[_c('strong',{staticClass:"notification-title"},[_vm._v(_vm._s(_vm.$t('confirmation.restricted_word_title')))]),_vm._v(" "),_c('p',{staticClass:"notification-desc"},[_vm._v("\n                                            "+_vm._s(_vm.$t('confirmation.restricted_word_database_notify', {
                                                    id: notification.data.confirmation_id,
                                                    status: notification.data.confirmation_status,
                                                    reviewed_by: notification.data.confirmation_reviewed_by,
                                                    reviewed_at: _vm.$moment(_vm.$moment.utc(notification.data.confirmation_reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                                                }))+"\n                                            ")])]:_vm._e(),_vm._v(" "),(notification.data.notification_type == 'confirmation_restricted_word_request')?[_c('strong',{staticClass:"notification-title"},[_vm._v(_vm._s(_vm.$t('confirmation.restricted_word_title')))]),_vm._v(" "),_c('p',{staticClass:"notification-desc"},[_vm._v("\n                                                "+_vm._s(_vm.$t('confirmation.restricted_word_database_request', {
                                                        id: notification.data.confirmation_id,
                                                        requested_by: notification.data.confirmation_requested_by,
                                                        created_at: _vm.$moment(_vm.$moment.utc(notification.data.confirmation_created_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                                                    }))+"\n                                            ")])]:_vm._e(),_vm._v(" "),(notification.data.notification_type == 'transfer')?[_c('strong',{staticClass:"notification-title"},[_vm._v(_vm._s(_vm.$t('ticket.transfer')))]),_vm._v(" "),_c('p',{staticClass:"notification-desc"},[_vm._v("\n                                                "+_vm._s(_vm.$t('ticket.transfer_database_notify', {
                                                    user: notification.data.user,
                                                    case_id: notification.data.case_id,
                                                }))+"\n                                            ")])]:_vm._e(),_vm._v(" "),(notification.data.notification_type == 'twitter_limit')?[_c('strong',{staticClass:"notification-title"},[_vm._v(_vm._s(_vm.$t('default.twitter_limit_title')))]),_vm._v(" "),_c('p',{staticClass:"notification-desc"},[_vm._v("\n                                                "+_vm._s(_vm.$t('default.twitter_limit_notify',{
                                                        limit: notification.data.account.limit,
                                                        tweet_count: notification.data.account.tweet_count,
                                                    }
                                                ))+"\n                                            ")])]:_vm._e(),_vm._v(" "),(notification.data.notification_type == 'question')?[_c('strong',{staticClass:"notification-title"},[_vm._v(_vm._s(_vm.$t('default.new_message_arrived')))]),_vm._v(" "),_c('p',{staticClass:"notification-desc"},[_vm._v("\n                                                "+_vm._s(_vm.$t('default.new_message_broadcast',{
                                                       customer: notification.data.username,
                                                       content: ((notification.data.content) + ".."),
                                                    }
                                                ))+"\n                                            ")])]:_vm._e(),_vm._v(" "),(notification.data.notification_type == 'vip')?[_c('strong',{staticClass:"notification-title"},[_vm._v(_vm._s(_vm.$t('default.new_vip_account_message_arrived')))]),_vm._v(" "),_c('p',{staticClass:"notification-desc"},[_vm._v("\n                                          "+_vm._s(_vm.$t('default.new_vip_account_message_broadcast',{
                                              customer: notification.data.username  ,
                                              content:((notification.data.content) + "..") ,
                                                }
                                            ))+"\n                                        ")])]:_vm._e(),_vm._v(" "),(notification.data.notification_type == 'popular')?[_c('strong',{staticClass:"notification-title"},[_vm._v(_vm._s(_vm.$t('default.new_popular_message_arrived')))]),_vm._v(" "),_c('p',{staticClass:"notification-desc"},[_vm._v("\n                                          "+_vm._s(_vm.$t('default.new_popular_message_broadcast',{
                                              customer: notification.data.username  ,
                                              content:((notification.data.content) + "..") ,
                                                }
                                            ))+"\n                                        ")])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"notification-meta"},[_vm._v("\n\n                                            "+_vm._s(notification.data.notification_type)+"\n                                            "),_c('small',{staticClass:"timestamp"},[_vm._v(" Created: "+_vm._s(_vm.$moment(_vm.$moment.utc(notification.created_at)).local().format('YYYY-MM-DD HH:mm:ss'))+" | Read: "+_vm._s(_vm.$moment(_vm.$moment.utc(notification.read_at)).local().format('YYYY-MM-DD HH:mm:ss')))])])],2)])])]})],2)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }