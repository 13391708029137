<template>
    <div>
        <b-row>
            <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover :title="title">{{ label }}</b-col>
            <b-col sm="12" md="5">
                <b-form-radio-group class="mb-3" :checked="value" @change="handleRadioButtonChange"
                    :options="radioButtonOptions"></b-form-radio-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" md="3" lg="2" class="mb-2"></b-col>
            <b-col sm="12" md="5">
                <treeselect v-if="value == 'integration'" :value="integration_value" @input="handleIntegrationChange"
                    :options="integration_options" :multiple="true" value-format="object"
                    :placeholder="$t('configuration.select_integrations')" :normalizer="integrationNormalizer">
                    <label slot="option-label" slot-scope="{ node }">
                        <font-awesome-icon
                            :icon="node.raw.name != 'sikayetvar' ? ['fab', node.raw.icon] : ['fas', node.raw.icon]" />
                        {{ node.raw.name }}
                    </label>
                    <div slot="value-label" slot-scope="{ node }">
                        <font-awesome-icon
                            :icon="node.raw.name != 'sikayetvar' ? ['fab', node.raw.icon] : ['fas', node.raw.icon]" />
                        <span>{{ node.raw.name }}</span>
                    </div>
                </treeselect>
                <treeselect v-if="value == 'account'" :value="account_value" @input="handleAccountChange"
                    :options="account_options" :multiple="true" value-format="object"
                    :placeholder="$t('configuration.select_accounts')" :normalizer="accountNormalizer">
                    <label slot="option-label" slot-scope="{ node }">
                        <font-awesome-icon :style="{ color: node.raw.color }"
                            :icon="['fab', node.raw.integration.icon]" />
                        {{ node.raw.name }}
                    </label>
                    <div slot="value-label" slot-scope="{ node }">
                        <font-awesome-icon :style="{ color: node.raw.color }"
                            :icon="['fab', node.raw.integration.icon]" />
                        <span>{{ node.raw.name }}</span>
                    </div>
                </treeselect>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";

export default {
    components: {
        Treeselect,
    },

    props: [
        'label',
        'value',
        'title',
        'radioButtonOptions',
        'integration_value',
        'account_value',
    ],

    mounted() {
        this.generateIntegrations();
    },

    data: function () {
        return {
            integration_options: null,
        }
    },

    methods: {

        handleRadioButtonChange(e) {
            this.$emit('handleRadioButtonChange', e);
        },

        handleIntegrationChange(e) {
            this.$emit('handleIntegrationChange', e);
        },

        handleAccountChange(e) {
            this.$emit('handleAccountChange', e);
        },

        integrationNormalizer(node) {
            return {
                id: node.id,
                label: node.name,
                children: node.children,
            }
        },

        accountNormalizer(node) {
            return {
                id: node.id,
                label: node.name,
                children: node.children,
            }
        },

        generateIntegrations() {
            const integrations = [];
            this.integrations.forEach((integration) => {
                integrations.push({
                    id: integration.id,
                    name: integration.id,
                    icon: integration.icon,
                })
            })
            this.integration_options = integrations
        }

    },

    computed: {
        // Map getters
        ...mapGetters({
            account_options: 'Accounts/getAccounts',
            integrations: 'Accounts/getIntegrations',
        })
    },
}
</script>