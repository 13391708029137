import { integer, numeric } from "vee-validate/dist/rules.esm";
import router from '../../router'


const state = {
  configuration_api: "/api/v1/settings/configurations/",
  email_test_api: "/api/v1/test-email",
  player_mode: 1,
  gamification_counter: 0,
  gamification_last_reply_counter: 0,
  warn_message_count: 0,
  warn_popular_account_count: 0,
  email_active: 0,
  email_address: "",
  email_host: "",
  email_username: "",
  email_password: "",
  email_port: "",
  
  send_for_options: [
    { text: "All", value: "all" },
    { text: "Selected Integrations", value: "integration" },
    { text: "Selected Accounts", value: "account" },
  ],

  email_send_for: "all",
  email_selected_integrations: [],
  email_selected_accounts: [],
  message_type_options: [
    { text: "DM", value: 1 },
    { text: "Mention", value: 2 },
    // { text: 'Key', value: 3 }
  ],
  email_message_type: [1, 2, 3],
  email_time_from: "00:00",
  email_time_to: "23:59",

  email_verified: false,

    notification_active: false,
    notification_send_for: 'all',
    notification_selected_integrations: [],
    notification_selected_accounts: [],
    notification_message_type: [1,2],
    notification_time_from: '00:00',
    notification_time_to: '23:59',

    whatsapp_active: false,
    whatsapp_send_for: 'all',
    whatsapp_selected_integrations: [],
    whatsapp_selected_accounts: [],
    whatsapp_message_type: [1,2],
    whatsapp_time_from: '00:00',
    whatsapp_time_to: '23:59',

    translation_active: false,
    translation_api_key: '',

    ticket_reminder_active: false,
    ticket_reminder_ticket_status: [],
    ticket_reminder_cycle: [],

    report_notify_active: false,
    report_notify_type: [],
    report_notify_interval: [],
    report_notify_type_options: [
      { text: 'accounts', value: "accounts"},
      { text: 'customers', value: "customers"},
      { text: 'processes', value: "processes"},
      { text: 'categories', value: "categories"},
      { text: 'status', value: "status"},
      { text: 'users', value: "users"},
      { text: 'teams', value: "teams"},
      { text: 'locations', value: "locations"},
    ],

    report_notify_interval_options: [
      {text: 'daily', value: "daily"},
      {text: 'weekly', value: "weekly"},
      {text: 'monthly', value: "monthly"}
    ],



};

// getters
const getters = {

    // Get Player Mode
    getPlayerMode: (state) => {
        return state.player_mode;
    },

    // Get Gamification Counter
    getGamificationCounter: (state) => {
        return state.gamification_counter;
    },

    // Get Gamification Last Reply Counter
    getGamificationLastReplyCounter: (state) => {
        return state.gamification_last_reply_counter;
    },

    // Get Warn Message Count
    getWarnMessageCount: (state) => {
        return state.warn_message_count;
    },

    // Get Warn Popular Account Count
    getWarnPopularAccountCount: (state) => {
        return state.warn_popular_account_count;
    },
    getEmail: (state) => {
        return state.email_address;
    },

  getEmailActive: (state) => {
    return state.email_active;
  },

  getEmailVerified: (state) => {
    return state.email_verified;
  },

  getEmailUsername: (state) => {
    return state.email_username;
  },
  getEmailHost: (state) => {
    return state.email_host;
  },
  getEmailPassword: (state) => {
    return state.email_password;
  },
  getEmailPort: (state) => {
    return state.email_port;
  },
  getSendForOptions: (state) => {
    return state.send_for_options;
  },
  getEmailSendFor: (state) => {
    return state.email_send_for;
  },
  getEmailSelectedIntegrations: (state) => {
    return state.email_selected_integrations;
  },
  getEmailSelectedAccounts: (state) => {
    return state.email_selected_accounts;
  },
  getMessageTypeOptions: (state) => {
    return state.message_type_options;
  },
  getEmailMessageType: (state) => {
    return state.email_message_type;
  },
  getEmailTimeFrom: (state) => {
    return state.email_time_from;
  },
  getEmailTimeTo: (state) => {
    return state.email_time_to;
  },
  getNotificationActive: (state) => {
    return state.notification_active;
  },
  getNotificationSendFor: (state) => {
    return state.notification_send_for;

    },
  getNotificationSelectedIntegrations: (state) => {
    return state.notification_selected_integrations;
  },
  getNotificationSelectedAccounts: (state) => {
    return state.notification_selected_accounts;
  },

  getNotificationMessageType: (state) => {
      return state.notification_message_type;
  },
  getNotificationTimeFrom: (state) => {
      return state.notification_time_from;
  },
  getNotificationTimeTo: (state) => {
      return state.notification_time_to;
  },

  getWhatsappActive: (state) => {
      return state.whatsapp_active;
  },
  getWhatsappSendFor: (state) => {
      return state.whatsapp_send_for;
  },
  getWhatsappSelectedIntegrations: (state) => {
      return state.whatsapp_selected_integrations;
  },
  getWhatsappSelectedAccounts: (state) => {
      return state.whatsapp_selected_accounts;
  },

  getWhatsappMessageType: (state) => {
      return state.whatsapp_message_type;
  },
  getWhatsappTimeFrom: (state) => {
      return state.whatsapp_time_from;
  },
  getWhatsappTimeTo: (state) => {
      return state.whatsapp_time_to;
  },

  getTranslationActive: (state) => {
      return state.translation_active;
  },
  getTranslationApiKey: (state) => {
      return state.translation_api_key;
  },

  getTicketReminderActive: (state) => {
    return state.ticket_reminder_active;
  },

  getTicketReminderTicketStatus: (state) => {
      return state.ticket_reminder_ticket_status;
  },
  getTicketReminderCycle: (state) => {
      return state.ticket_reminder_cycle;
  },

  getReportNotifyActive: (state) => {
      return state.report_notify_active;
  },
  getReportNotifyType: (state) => {
      return state.report_notify_type;
  },
  getReportNotifyTypeOptions: (state) => {
      return state.report_notify_type_options;
  },
  getReportNotifyInterval: (state) => {
      return state.report_notify_interval;
  },
  getReportNotifyIntervalOptions: (state) => {
      return state.report_notify_interval_options;
  },

};

// mutations
const mutations = {

    // Set Player Mode
    setPlayerMode(state, player_mode) {
        state.player_mode = player_mode;
    },

    // Set Gamification Counter
    setGamificationCounter(state, gamification_counter) {
        state.gamification_counter = gamification_counter;
    },

    // Set Gamification Last Reply Counter
    setGamificationLastReplyCounter(state, gamification_last_reply_counter) {
        state.gamification_last_reply_counter = gamification_last_reply_counter;
    },

    // Set Warn Message Count
    setWarnMessageCount(state, warn_message_count) {
        state.warn_message_count = warn_message_count;
    },

    // Set Warn Popular Account Count
    setWarnPopularAccountCount(state, warn_popular_account_count) {
        state.warn_popular_account_count = warn_popular_account_count;
    },

    setEmail(state, email) {
        state.email_address = email;
    },

    setEmailActive(state, email_active) {
      state.email_active = email_active;
    },

    setEmailVerified(state, email_verified) {
      state.email_verified = email_verified;
    },

    setEmailHost(state, email_host) {
      state.email_host = email_host;
    },
    setEmailUsername(state, email_username) {
      state.email_username = email_username;
    },
    setEmailPassword(state, email_password) {
      state.email_password = email_password;
    },
    setEmailPort(state, email_port) {
      state.email_port = email_port;
    },
    setEmailSendFor(state, email_send_for) {
      state.email_send_for = email_send_for;
    },
    setEmailSelectedIntegrations(state, email_selected_integrations) {
      state.email_selected_integrations = email_selected_integrations;
    },
    setEmailSelectedAccounts(state, email_selected_accounts) {
      state.email_selected_accounts = email_selected_accounts;
    },
    setEmailMessageType(state, email_message_type) {
      state.email_message_type = email_message_type;
    },
    setEmailTimeFrom(state, email_time_from) {
      state.email_time_from = email_time_from;
    },
    setEmailTimeTo(state, email_time_to) {
      state.email_time_to = email_time_to;
    },
    setNotificationActive(state, notification_active) {
      state.notification_active = notification_active;
    },
    setNotificationSendFor(state, notification_send_for) {
      state.notification_send_for = notification_send_for;
    },

    setNotificationSelectedIntegrations(state, notification_selected_integrations) {
        state.notification_selected_integrations = notification_selected_integrations;
    },
    setNotificationSelectedAccounts(state, notification_selected_accounts) {
        state.notification_selected_accounts = notification_selected_accounts;
    },

    setNotificationMessageType(state, notification_message_type) {
        state.notification_message_type = notification_message_type;
    },
    setNotificationTimeFrom(state, notification_time_from) {
        state.notification_time_from = notification_time_from;
    },
    setNotificationTimeTo(state, notification_time_to) {
        state.notification_time_to = notification_time_to;
    },

    setWhatsappActive(state, whatsapp_active) {
        state.whatsapp_active = whatsapp_active;
    },
    setWhatsappSendFor(state, whatsapp_send_for) {
        state.whatsapp_send_for = whatsapp_send_for;
    },

    setWhatsappSelectedIntegrations(state, whatsapp_selected_integrations) {
        state.whatsapp_selected_integrations = whatsapp_selected_integrations;
    },
    setWhatsappSelectedAccounts(state, whatsapp_selected_accounts) {
        state.whatsapp_selected_accounts = whatsapp_selected_accounts;
    },

    setWhatsappMessageType(state, whatsapp_message_type) {
        state.whatsapp_message_type = whatsapp_message_type;
    },
    setWhatsappTimeFrom(state, whatsapp_time_from) {
        state.whatsapp_time_from = whatsapp_time_from;
    },
    setWhatsappTimeTo(state, whatsapp_time_to) {
        state.whatsapp_time_to = whatsapp_time_to;
    },

    setTranslationActive(state, translation_active) {
        state.translation_active = translation_active;
    },
    setTranslationApiKey(state, translation_api_key) {
        state.translation_api_key = translation_api_key;
    },

    setTicketReminderActive(state, ticket_reminder_active) {
        state.ticket_reminder_active = ticket_reminder_active;
    },

    setTicketReminderTicketStatus(state, ticket_reminder_ticket_status) {
        state.ticket_reminder_ticket_status = ticket_reminder_ticket_status;
    },
    setTicketReminderCycle(state, ticket_reminder_cycle) {
        state.ticket_reminder_cycle = ticket_reminder_cycle;
    },

    setReportNotifyActive(state, report_notify_active) {
        state.report_notify_active = report_notify_active;
    },
    setReportNotifyType(state, report_notify_type) {
        state.report_notify_type = report_notify_type;
    },
    setReportNotifyInterval(state, report_notify_interval) {
        state.report_notify_interval = report_notify_interval;
    },
};

// actions
const actions = {

    // get Configurations
    async fetchConfigurations({dispatch, commit, rootGetters}) {

        // Start waiting
        dispatch('wait/start', 'configurations', {root: true});


        // Get Ticket
        await axios.get(state.configuration_api)
            .then((response) => {

                const accounts = rootGetters['Accounts/getAccounts'];
                const integrations = rootGetters['Accounts/getIntegrations'];

                if (response.data.error) {
                    commit('setPermission', Vue.i18n.translate('permission.read-transfer'));
                    console.log(Vue.i18n.translate('permission.denied'));
                }else {

                    // Set Player Mode
                    commit('setPlayerMode', response.data.player_mode);

                    // Set Gamification Counter
                    commit('setGamificationCounter', response.data.gmf_count);

                    // Set Gamification Last Reply Count
                    commit('setGamificationLastReplyCounter', response.data.gmf_last_reply_count);

                    // Set Warn Message Count
                    commit('setWarnMessageCount', response.data.warn_message_count);

                    // Set Warn Popular Account Incoming Message Count
                    commit('setWarnPopularAccountCount', response.data.warn_popular_account_count);

                    commit("setEmail", response.data.email);
                    commit("setEmailActive", response.data.email_active);

                    if(response.data.email_active && router.currentRoute.path === '/settings/configurations'){
                        dispatch("verifyEmailAddress");
                    }
                    commit("setEmailUsername", response.data.email_username);
                    commit("setEmailPassword", response.data.email_password);
                    commit("setEmailPort", response.data.email_port);
                    commit("setEmailHost", response.data.email_host);
                    commit("setEmailVerified", response.data.delivery_status);

                    commit('setWhatsappActive', response.data.whatsapp_active);

                    commit("setNotificationActive", response.data.notification_active);

                    commit("setTranslationActive", response.data.detect_lang);
                    commit("setTranslationApiKey", response.data.google_translate_key);

                    commit('setTicketReminderActive', response.data.ticket_reminder_status);

                    commit('setReportNotifyActive', response.data.report_notify_active);

                    if (response.data.email_config ) {
                        let email_config = JSON.parse(response.data.email_config)
                        commit('setEmailMessageType', email_config.message_type ?? []);
                        commit('setEmailTimeFrom', email_config.time.from);
                        commit('setEmailTimeTo', email_config.time.to);


                        if (email_config.account_ids.length > 0) {

                            let account_list = []
                            email_config.account_ids.forEach((element) => {
                                const account = accounts.find((account) => account.id == element)
                                account_list.push(account)
                            })
                            commit('setEmailSelectedAccounts', account_list)
                            commit('setEmailSendFor', 'account')
                        } else if (email_config.integration_ids.length > 0) {

                            let integration_list = []
                            email_config.integration_ids.forEach((element) => {
                                const integration = integrations.find((integration) => integration.id == element)
                                integration_list.push({
                                    id: integration.id,
                                    name: integration.id,
                                    icon: integration.icon,
                                })
                            })
                            commit('setEmailSelectedIntegrations', integration_list)
                            commit('setEmailSendFor', 'integration')
                        } else {
                            commit('setEmailSendFor', 'all')
                        }
                    }

                    if (response.data.notification_config ) {
                        let notification_config = JSON.parse(response.data.notification_config)
                        commit('setNotificationMessageType', notification_config.message_type ?? []);
                        commit('setNotificationTimeFrom', notification_config.time.from);
                        commit('setNotificationTimeTo', notification_config.time.to);


                        if (notification_config.account_ids.length > 0) {
                          let account_list = [];
                          notification_config.account_ids.forEach((element) => {
                            const account = accounts.find(
                              (account) => account.id == element
                            );
                            account_list.push(account);
                          });
                          commit("setNotificationSelectedAccounts", account_list);
                          commit("setNotificationSendFor", "account");
                        } else if (notification_config.integration_ids.length > 0) {
                          let integration_list = [];
                          notification_config.integration_ids.forEach((element) => {
                            const integration = integrations.find(
                              (integration) => integration.id == element
                            );
                            integration_list.push({
                              id: integration.id,
                              name: integration.id,
                              icon: integration.icon,
                            });
                          });
                          commit("setNotificationSelectedIntegrations", integration_list);
                          commit("setNotificationSendFor", "integration");
                        } else {
                          commit("setNotificationSendFor", "all");
                        }
                    }
                    if (response.data.whatsapp_config ) {
                        let whatsapp_config = JSON.parse(response.data.whatsapp_config)
                        commit('setWhatsappMessageType', whatsapp_config.message_type ?? []);
                        commit('setWhatsappTimeFrom', whatsapp_config.time.from);
                        commit('setWhatsappTimeTo', whatsapp_config.time.to);


                        if (whatsapp_config.account_ids.length > 0) {

                            let account_list = []
                            whatsapp_config.account_ids.forEach((element) => {
                                const account = accounts.find((account) => account.id == element)
                                account_list.push(account)
                            })
                            commit('setWhatsappSelectedAccounts', account_list)
                            commit('setWhatsappSendFor', 'account')
                        } else if (whatsapp_config.integration_ids.length > 0) {

                            let integration_list = []
                            whatsapp_config.integration_ids.forEach((element) => {
                                const integration = integrations.find((integration) => integration.id == element)
                                integration_list.push({
                                    id: integration.id,
                                    name: integration.id,
                                    icon: integration.icon,
                                })
                            })
                            commit('setWhatsappSelectedIntegrations', integration_list)
                            commit('setWhatsappSendFor', 'integration')
                        } else {
                            commit('setWhatsappSendFor', 'all')
                        }
                    }

                    if (response.data.ticket_reminder_config ) {
                      let ticket_reminder_config = JSON.parse(response.data.ticket_reminder_config)
                      commit('setTicketReminderTicketStatus', ticket_reminder_config.ticket_status );
                      const normalizedCycle = ticket_reminder_config.reminder_cycle.map(item => {
                          return {
                              ...item,
                              hour: +item.hour < 10 ? `0${item.hour}:00` : `${item.hour}:00`
                          }
                      })

                      commit('setTicketReminderCycle', normalizedCycle );
                    }

                    if (response.data.report_notify_active ) {
                      let report_notify_config = JSON.parse(response.data.report_notify_config)
                      commit('setReportNotifyType', report_notify_config.report_type );

                      commit('setReportNotifyInterval', report_notify_config.report_interval );
                    }

                }

                // Stop waiting
                dispatch('wait/end', 'configurations', {root: true});

            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'configurations', {root: true});

            });

    },

    // Update Configurations
    async updateConfigurations({dispatch, state}) {

        // Start waiting
        dispatch('wait/start', 'configurations', {root: true});

        let selected_accounts_email = [];
        let selected_integrations_email = [];
        if (state.email_selected_accounts || state.email_selected_integrations) {
            state.email_selected_accounts.forEach((element) => {
                selected_accounts_email.push(element.id)
            })
            state.email_selected_integrations.forEach((element) => {
                selected_integrations_email.push(element.name)
            })
        }

        let selected_accounts_notifications = [];
        let selected_integrations_notifications = [];
        if (state.notification_selected_accounts || state.notification_selected_integrations) {
            state.notification_selected_accounts.forEach((element) => {
                selected_accounts_notifications.push(element.id)
            })
            state.notification_selected_integrations.forEach((element) => {
                selected_integrations_notifications.push(element.name)
            })
        }

    let selected_accounts_whatsapp = [];
        let selected_integrations_whatsapp = [];
        if (state.whatsapp_selected_accounts || state.whatsapp_selected_integrations) {
            state.whatsapp_selected_accounts.forEach((element) => {
                selected_accounts_whatsapp.push(element.id)
            })
            state.whatsapp_selected_integrations.forEach((element) => {
                selected_integrations_whatsapp.push(element.name)
            })
        }// Get Ticket

    const normalizedCycle =  state.ticket_reminder_cycle.map(item => {
        return {
            ...item,
            hour: parseInt(item.hour, 10).toString()
        }
    })

    await axios
      .put(state.configuration_api + 1, {
        player_mode: state.player_mode,
        gmf_count: state.gamification_counter,
        gmf_last_reply_count: state.gamification_last_reply_counter,
        warn_message_count: state.warn_message_count,
        warn_popular_account_count: state.warn_popular_account_count,
        email: state.email_address,
        email_active: state.email_active,
        email_host: state.email_host,
        email_username: state.email_username,
        email_password: state.email_password,
        email_port: state.email_port,
        email_time_from: state.email_time_from,
        email_time_to: state.email_time_to,
        email_message_type: state.email_message_type,
        email_integration_ids: selected_integrations_email,
        email_account_ids: selected_accounts_email,
        delivery_status: state.email_verified,
        notification_active: state.notification_active,
        notification_time_from: state.notification_time_from,
        notification_time_to: state.notification_time_to,
        notification_message_type: state.notification_message_type,
        notification_integration_ids: selected_integrations_notifications,
        notification_account_ids: selected_accounts_notifications,whatsapp_active: state.whatsapp_active,
        whatsapp_time_from: state.whatsapp_time_from,
        whatsapp_time_to: state.whatsapp_time_to,
        whatsapp_message_type: state.whatsapp_message_type,
        whatsapp_integration_ids: selected_integrations_whatsapp,
        whatsapp_account_ids: selected_accounts_whatsapp,
        detect_lang: state.translation_active,
        google_translate_key: state.translation_api_key,
        ticket_reminder_status: state.ticket_reminder_active,
        ticket_status: state.ticket_reminder_ticket_status,
        reminder_cycle: normalizedCycle,
        report_notify_active: state.report_notify_active,
        report_type: state.report_notify_type,
        report_interval: state.report_notify_interval,
      })
      .then((response) => {
        // Re fetch Configurations
        dispatch("fetchConfigurations");

            // Stop waiting
            dispatch('wait/end', 'configurations', {root: true});

            // Show success
            Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch("wait/end", "configurations", {root: true});
            });
    },

    async verifyEmailAddress({dispatch, state, commit}, email_data = {}) {
        dispatch("wait/start", "test_email", { root: true });
    await axios
      .post(state.email_test_api, email_data)
      .then(async (response) => {
        if (response.data.status) {
          commit("setEmailVerified", "sent");
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        commit("setEmailVerified", "error");
        console.log(error);
      });

    dispatch("wait/end", "test_email", { root: true });

  },

  appendTicketReminderCycle({dispatch, commit, state}, cycle) {
    commit('setTicketReminderCycle', [...state.ticket_reminder_cycle, cycle])
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
