<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('simple.delete') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="user_delete">

                <loading slot="waiting"/>

                <b-alert variant="danger" show> {{ $t('simple.delete_confirm') }}</b-alert>

            </v-wait>

          </div>

          <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>
            <b-button @click="user_remove" variant="primary">{{ $t('simple.delete') }}</b-button>
            
          </div>

        </div>
      </div>
    </div>
</template>
<script>

    export default {

        mounted: function () {
            // Component mounted
            console.log('User Remove Modal mounted');
        },

        destroyed() {
            // Component Destroyed
            console.log('User Remove Modal destroyed.');
        },

        data: function() {
             return  {

                users_api: '/api/v1/settings/users/',


             }
        },

        methods: {

            user_remove: async function(){

                // Loading start
                this.$wait.start('user_delete');

                // Delete user
                await axios.delete(this.users_api + this.id)
                    .then((response) => {

                      if(response.data.error){

                        // Show error
                        Vue.toasted.error(Vue.i18n.translate('permission.delete_users'),{duration:5000});
                        // Loading stop
                        this.$wait.end('user_delete');
                        // Close modal
                        this.$close(true);
                      }else {
                        // Show success
                        Vue.toasted.success(this.$t('simple.deleted_confirmed'));

                        // Loading stop
                        this.$wait.end('user_delete');

                        // Close modal
                        this.$close(true);
                        //console.log(response);
                      }

                    }
                )
                    .catch((error) => {
                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));

                        // Loading stop
                        this.$wait.end('user_delete');

                        // Log to console
                        console.log(error);

                        // Close modal
                        this.$close(true);

                    }
                );

            },
        },
    }
</script>