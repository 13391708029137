import { DateTime } from "luxon";
import {isNull} from "bootstrap-vue/esm/utils/inspect";

const state = {

  active_tab: 0,

  // This is a text?
  text: '',

  // Reply overlay
  isReply: false,

  // needed information after reply
  reply_data: '',

  // This is a thread_id?
  thread: null,

  // This is a note?
  note: '',

  // Schedule
  schedule_date: '',

  // Reply type
  reply_type: '',

  // Reply mention
  reply_mention: '',

  // Attachments
  attachment_status: false,

  // Pixel Gallery
  pixel_image: false,

  // Pixel Json
  pixel_image_to_json: false,

  // Files
  files: [],

  // Macros Variables
  macros_variables: [],

  // Macros Status
  macros_status: false,

  selected:[],

  is_session_active: false,

}

// getters
const getters = {
  
  // Get active tab
  getActiveTab: (state, getters) => {
    // Return active tab
    return state.active_tab;
  },

  // Get reply text
  getText: (state, getters) => {
    // Return text
    return state.text;
  },

  // Get reply thread_id
  getThreadId: (state, getters) => {
    // Return text
    return state.thread;
  },

  // Get reply thread_id
  getIsReply: (state, getters) => {
    // Return text
    return state.isReply;
  },

  // Get reply thread_id
  getReplyData: (state, getters) => {
    // Return text
    return state.reply_data;
  },


  // Get reply note
  getNote: (state, getters) => {
    // Return note
    return state.note;
  },

  // Get Schedule Date
  getScheduleDate: (state, getters) => {
    // Return date
    return state.schedule_date;
  },

  getSelectedUsers: (state, getters) => {
    return state.selected
  },

  // Get attachment status
  getAttachmentStatus: (state, getters) => {
    // Return attachment status

    console.log(state.attachment_status);
    console.log('state.attachment_status');
    return state.attachment_status;
  },

  // Get Pixel Image
  getPixelImage: (state, getters) => {
    // Return Files
    return state.pixel_image;
  },

  // Get Pixel Image Json
  getPixelImageToJson: (state, getters) => {
    // Return Files
    return state.pixel_image_to_json;
  },

  // Get files
  getAttachFiles: (state, getters) => {
    // Return Files
    return state.files;
  },

  getMacrosStatus: (state, getters) => {
    return state.macros_status;
  },

  getSessionActive: (state, getters) => {
    return state.is_session_active;
  },
}

// mutations
const mutations = {

  setSelected(state,ids){
    state.selected = ids;
  },

  // Set active tab
  setActiveTab (state, active_tab) {
    state.active_tab = active_tab;
  },

  // Set Reply Text
  setText (state, text) {
    state.text = text;
  },

  // Set Reply Thread_id
  setThreadId (state, thread) {
    state.thread = thread;
  },

  // Set isReply
  setIsReply (state, isReply) {
    state.isReply = isReply;
    console.log('set is reply: ' + isReply);
  },

  // Set reply_data
  setReplyData (state, reply_data) {
    state.reply_data = reply_data;
    console.log('set reply data: ' + reply_data);
  },

  // Set Reply Note
  setNote (state, note) {
    state.note = note;
  },

  // Set schedule date
  setScheduleDate (state, schedule_date) {
    state.schedule_date = schedule_date;
  },

  // Set Attachment Status
  setAttachmentStatus (state, status) {
    state.attachment_status = status;
  },

  // Set Pixel Image
  setPixelImage (state, pixel_image) {
    state.pixel_image = pixel_image;
    console.log('set pixel image: ' + state.pixel_image);
  },

  // Set Pixel Image Json
  setPixelImageToJson (state, pixel_image_to_json) {
    state.pixel_image_to_json = pixel_image_to_json;
    console.log('set pixel image json: ' + state.pixel_image_to_json);
  },

  // Reset Pixel Image
  setPixelResetImage (state) {
    state.pixel_image = false;
    console.log('reset pixel image: ' + state.pixel_image);
  },

  // Reset Pixel Image
  setPixelResetImageToJson (state) {
    state.pixel_image_to_json = false;
    console.log('reset pixel image Json: ' + state.pixel_image_to_json);
  },

  // Set Files
  setAttachFiles (state, files) {
    state.files.push(files);
    console.log('set attach files: ' + state.files);
  },

  // Set Files
  setAttachResetFiles (state, files) {
    state.files = [];
    console.log('reset attach files: ' + state.files);
  },

  // Set Macros Variables
  setMacrosVariables (state, payload) {
    state.macros_variables = payload;
  },

  // Set Macros Status
  setMacrosStatus (state, payload) {
    state.macros_status = payload;
  },

  setSessionActive (state, is_session_active) {
    state.is_session_active = is_session_active;
  },

}

// actions
const actions = {
  async selectedUsers({ commit }, ids) {
    commit("setSelected", ids);
  },

  // Reset active tab
  async resetActiveTab({ commit }) {
    commit("setActiveTab", 0);
  },

  // Set active tab
  async updateActiveTab({ commit }, active_tab) {
    commit("setActiveTab", active_tab);
  },

  // Set active tab
  async updatePixelImage({ commit }, pixel_image) {
    commit("setPixelImage", pixel_image);
    commit("setAttachmentStatus", true);
  },

  // Reset Reply Text
  resetText({ dispatch, commit }) {
    commit("setIsReply", false);
    commit("setText", "");
    commit("setThreadId", null);
    dispatch("resetNote");
    dispatch("resetScheduleDate");
    commit("setPixelResetImage", false);
    commit("setPixelResetImageToJson", false);
    commit("setMacrosStatus", false);
    dispatch("updateAttachmentStatus", false);

    // Log to console
    console.log("ThreadId completely Reset.");
    // Reset Active Tab to Reply
  },

  // Reset Files
  async resetFiles({ dispatch, commit }) {
    commit("setAttachResetFiles");
    console.log("files Array Reset.");
  },

  // Reset Thread Id
  async unassignCustomer({ dispatch, commit, rootGetters }) {
    commit("setIsReply", false);
    commit("setThreadId", null);
    dispatch("Messages/updateQA", false, { root: true });
    dispatch("Customer/assignCustomer", "unset", { root: true });
    console.log("Reply ThreadId Reset.");
  },
  // Reset Thread Id
  async resetThreadId({ dispatch, commit, rootGetters }) {
    commit("setIsReply", false);
    commit("setThreadId", null);
    dispatch("Messages/updateQA", false, { root: true });
    console.log("Reply ThreadId Reset.");
  },

  // Set Reply Text
  async updateText({ commit }, text) {
    commit("setText", text);
  },

  async appendToText({ commit }, text) {
    commit("setText", state.text + text);
  },

  // Set Reply message_id
  async updateThreadId({ commit }, thread) {
    commit("setThreadId", thread);
  },

  // Send Answer
  async sendText({ dispatch, commit, state, getters, rootGetters }) {
    // Start waiting
    dispatch("wait/start", "answer", { root: true });

    //dispatch('uploadAttachment');

    let answer_api = "/api/v1/inbox/answer";

    let account_id = rootGetters["Accounts/getAccount"];

    let sender_id = rootGetters["Accounts/getSenderAccount"];

    let customer_id = rootGetters["Customer/getCustomerID"];

    let customer = rootGetters["Customer/getCustomer"];

    let customer_username = rootGetters["Customer/getCustomerUsername"];

    let type = rootGetters["Accounts/getIntegrationTypeID"];

    let integration = rootGetters["Accounts/getIntegration"];

    let whatsapp_template_id = rootGetters["Templates/getSelectedTemplateId"];

    let whatsapp_template_variables =
      rootGetters["Templates/getSelectedTemplateVariables"];

    if ((type === 2 || type === 3) && !getters.getThreadId) {
      let messages = rootGetters["Messages/getMessages"];
      let last_message = messages
        .reverse()
        .find((messages) => messages.type === 2 || messages.type === 3);
      console.log(last_message);
      this.thread_mmid = null;
      if (last_message) {
        if (!isNull(last_message.question)) {
          this.thread_mmid = last_message.question.message_id;
        } else if (
          !isNull(last_message.draft) &&
          last_message.draft.type === 2
        ) {
          this.thread_mmid = last_message.draft.thread_id;
        } else if (
          !isNull(last_message.answer) &&
          last_message.answer.type === 2
        ) {
          this.thread_mmid = last_message.answer.thread_id;
        }
      }
      state.thread = this.thread_mmid;
    }

    console.log("integraaaaatiiionnnn:" + integration.id);
    if (integration.id == "sikayetvar" && !getters.getThreadId) {
      let last_message = customer.question;
      console.log(last_message);
      this.thread_mmid = null;
      if (last_message) {
        if (!isNull(last_message)) {
          this.thread_mmid = last_message.post_id;
        }
      }
      state.thread = this.thread_mmid;
    }
    let thread = getters.getThreadId;

    let text =
      type === 2 &&
      !getters.getThreadId &&
      integration !== "facebook" &&
      integration !== "youtube" &&
      integration !== "linkedin"
        ? "@" + customer_username + " " + getters.getText
        : getters.getText;

    let schedule_at = getters.getScheduleDate;

    let selected = getters.getSelectedUsers;

    let note = getters.getNote ? getters.getNote : false;

    let attachment_id = getters.getAttachFiles ? getters.getAttachFiles : null;

    let pixel_image = getters.getPixelImage ? getters.getPixelImage : null;

    // Post Reply
    await axios
      .post(answer_api, {
        account_id: account_id,

        sender_id: sender_id,

        customer_id: customer_id,

        type: type,

        content: text,

        thread_id: thread,

        schedule_at: schedule_at,

        note: note,

        attachment_id: attachment_id,

        selected: selected,

        pixel_image: state.attachment_status ? pixel_image : false,

        whatsapp_template_id: whatsapp_template_id,

        content_variables: whatsapp_template_variables,
      })

      .then((response) => {

        dispatch('resetReplyData', response.data.last_ticket_id)
        // // Reset Active Tab to Reply
        // dispatch('resetActiveTab');
        // dispatch('updateAttachmentStatus', false);
        // dispatch('resetFiles');
        // dispatch('Templates/resetSelectedTemplateDetails', null, {root: true});

        // // Macros Ticket Update
        // if(state.macros_status){

        //   commit('Ticket/setTicketUpdateId', response.data.last_ticket_id, {root: true});

        //   dispatch('Ticket/updateTicket', null, {root: true});

        // }

        // // Clear Text/Note/Schedule
        // dispatch('resetText');

        // if(sender_id){
        //   dispatch('Accounts/updateSenderAccount', null, {root: true});
        // }

        // Stop waiting
        dispatch("wait/end", "answer", { root: true });

        // Refresh Messages?
        // dispatch('Messages/fetchLastConfirm', customer_id, {root:true} );

        // Show success
        Vue.toasted.success(Vue.i18n.translate("simple.success_info"));
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "answer", { root: true });
      });

    // Stop waiting
    dispatch("wait/end", "answer", { root: true });
  },

  resetReplyData({ dispatch, commit, state, getters, rootGetters }, last_ticket_id = null) {
    // Reset Active Tab to Reply
    dispatch("resetActiveTab");
    dispatch("updateAttachmentStatus", false);
    dispatch("resetFiles");
    dispatch("Templates/resetSelectedTemplateDetails", null, { root: true });

    // Macros Ticket Update

    let macros_status = rootGetters["Reply/getMacrosStatus"];

    if (macros_status) {
      commit("Ticket/setTicketUpdateId", last_ticket_id, {root: true,});

      dispatch("Ticket/updateTicket", null, { root: true });
    }

    // Clear Text/Note/Schedule
    dispatch("resetText");

    let sender_id = rootGetters["Accounts/getSenderAccount"];

    if (sender_id) {
      dispatch("Accounts/updateSenderAccount", null, { root: true });
    }
  },

  // Upload Attachment
  async uploadAttachment({ dispatch, commit, state, getters, rootGetters }) {
    dispatch("wait/start", "answer", { root: true });

    // Start waiting
    let upload_api = "/api/v1/inbox/answer/upload";

    let account_id = rootGetters["Accounts/getAccount"];

    let customer_id = rootGetters["Customer/getCustomerID"];

    let files = getters.getAttachFiles;

    // Post Note
    await axios
      .post(upload_api, {
        account_id: account_id,

        customer_id: customer_id,

        files: files,
      })

      .then((response) => {
        console.log("upload response goes here");
        console.log(response);

        // Stop waiting
        dispatch("wait/end", "answer", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "answer", { root: true });
      });

    // Stop waiting
    dispatch("wait/end", "answer", { root: true });
  },

  async uploadFile({ dispatch, commit, state, getters, rootGetters }, files ) {
    dispatch("wait/start", "answer", { root: true });

    let upload_api = "/api/v1/inbox/answer/upload";


    // Post Note
    await axios
      .post(upload_api, files)

      .then((response) => {
        console.log("upload response goes here");
        dispatch("updateAttachFiles", response.data);

        // Stop waiting
        dispatch("wait/end", "answer", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "answer", { root: true });
      });
  },

  // Send Note
  async sendNote({ dispatch, commit, state, getters, rootGetters }) {
    // Start waiting
    dispatch("wait/start", "answer", { root: true });

    let note_api = "/api/v1/inbox/note";

    let account_id = rootGetters["Accounts/getAccount"];

    let customer_id = rootGetters["Customer/getCustomerID"];

    let attachment_id = getters.getAttachFiles ? getters.getAttachFiles : null;

    let text = getters.getNote;

    // Post Note
    await axios
      .post(note_api, {
        account_id: account_id,

        customer_id: customer_id,

        attachment_id: attachment_id,

        content: text,
      })

      .then((response) => {
        console.log("send note response goes here");
        console.log(response);

        // Stop waiting
        dispatch("wait/end", "answer", { root: true });

        // Reset note
        dispatch("resetNote");

        // Reset Active Tab to Reply
        dispatch("resetActiveTab");

        // Refresh Messages
        dispatch("Messages/refreshMessages", customer_id, { root: true });

        // Show success
        Vue.toasted.success(Vue.i18n.translate("simple.success_info"));
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "answer", { root: true });
      });

    // Stop waiting
    dispatch("wait/end", "answer", { root: true });

    // Refresh messages
    //dispatch('Messages/refreshMessages', customer_id, { root: true });
  },

  // Get Tracks
  async checkCanComment(
    { dispatch, commit, rootGetters },
    { integration, sender_account, comment_id }
  ) {
    // Loading start
    dispatch("wait/start", "sender_account", { root: true });

    let check_comment_api = "/api/v1/" + integration + "/comment/status";

    // Check can comment
    await axios
      .get(
        check_comment_api +
          "?account_id=" +
          sender_account +
          "&comment_id=" +
          comment_id
      )
      .then((response) => {
        // Set Can Comment Status
        dispatch("Accounts/updateCanComment", response.data.can_comment, {
          root: true,
        });

        // End waiting
        dispatch("wait/end", "sender_account", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // End waiting
        dispatch("wait/end", "sender_account", { root: true });
      });
  },

  // Set Reply Text with
  async updateReplyTo({ dispatch, commit, state }, data) {
    commit("setIsReply", "true");
    let result = data.message_id;
    if (data.message) {
      // Update last message
      dispatch("Messages/updateQA", data.message, { root: true });

      //dispatch('updateTextAdd', 'Welcome to ALPHA. Replying to ID ' + data.message.message_id);
      result = data.message.message_id;
      // Set text
      //dispatch('updateReplyText', result);
      dispatch("updateThreadId", result);

      // ?? When we click to reply button that's part working in same time. Do we need that?
      // dispatch('Customer/fetchCustomer', data.message.customer_id, { root: true });
      commit("setReplyData", data);
    } else {
      // Set text
      dispatch("updateThreadId", result);
      dispatch("Customer/fetchCustomer", data.message.customer_id, {
        root: true,
      });
      commit("setReplyData", data);
    }

    // Reset Active Tab to Reply
    dispatch("resetActiveTab");
  },

  // Set Reply Text with
  async updateTextAdd(
    { dispatch, commit, state, rootGetters },
    { text, pixel }
  ) {
    let customer = rootGetters["Customer/getCustomer"];

    if (state.macros_variables) {
      state.macros_variables.forEach((buckets) => {
        text = text.replace(buckets["name"], buckets["value"]);
      });
    }
    text = text.replace(/customer.username/g, customer.username);
    text = text.replace(/customer.identification/g, customer.identification);
    text = text.replace(/customer.name/g, customer.name);
    text = text.replace(/customer.surname/g, customer.surname);
    text = text.replace(/customer.phone/g, customer.phone);
    text = text.replace(/customer.email/g, customer.email);

    console.log("---UPDATE TEXT---");
    console.log(pixel);
    if (typeof pixel == "undefined") {
      // Set text to text area
      dispatch("appendToText", text);
    }
    // Reset Active Tab to Reply
    dispatch("resetActiveTab");
    return text;
  },

  // Reset Reply Note
  async resetNote({ commit }) {
    commit("setNote", null);
  },

  // Set Reply Note
  async updateNote({ commit }, note) {
    commit("setNote", note);
  },

  // Set active tab
  async updateScheduleDate({ commit }, schedule_date) {
    commit("setScheduleDate", schedule_date);
  },

  // Reset Schedule date
  async resetScheduleDate({ dispatch, commit }) {
    commit("setScheduleDate", null);
  },

  // Set Attachment Status
  async updateAttachmentStatus({ dispatch, commit }, status) {
    console.log("update attachment status to " + status);
    // Set attachment status
    commit("setAttachmentStatus", status);
  },

  // Set Attach files
  async updateAttachFiles({ dispatch, commit }, files) {
    console.log("update attachment files : " + files);
    // Set attachment status
    commit("setAttachFiles", files);
  },

  updateSessionActive( {commit,state }, is_session_active) {
    commit("setSessionActive", is_session_active);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}