<template>

    <div class="alert alert-danger" v-if="permission_error">
        {{ permission_error }}
    </div>


    <v-wait for="transfer" v-else>

        <loading slot="waiting"/>

        <form>

            <!-- Users  -->
            <b-form-group :label="$t('default.users')" v-if="users" horizontal>

                <treeselect
                        v-model="ticket_users"
                        :options="users"
                        :multiple="true"

                        value-format="object"
                        :placeholder="$t('ticket.select_users')"
                        :normalizer="user_normalizer"
                >
                    <label slot="cell(option-label)" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                        {{ node.raw.ns }}
                    </label>

                    <div slot="cel(value-label)" slot-scope="{ node }"> {{ node.raw.ns }} </div>

                </treeselect>

            </b-form-group>

            <!-- Teams  -->
            <b-form-group :label="$t('default.teams')" v-if="teams" horizontal>

                <treeselect
                        v-model="ticket_teams"
                        :options="teams"
                        :multiple="true"
                        value-format="object"
                        :placeholder="$t('ticket.select_teams')"
                        :normalizer="team_normalizer"
                >
                    <label slot="cell(option-label)" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                        {{ node.raw.name }}
                    </label>

                    <div slot="cell(value-label)" slot-scope="{ node }"> {{ node.raw.name }} </div>

                </treeselect>

            </b-form-group>

            <!-- Note  -->
            <b-form-group :label="$t('default.note')" horizontal>

                <b-form-input v-model="note"
                              type="text"
                              :placeholder="$t('ticket.input_note')"></b-form-input>

            </b-form-group>

            <b-form-group horizontal>
                <b-button-toolbar key-nav  aria-label="Toolbar with button groups" class="mt-2">

                    <b-button-group class="mx-1">

                        <!-- Save -->
                        <b-btn variant="light" v-b-tooltip.hover :title="$t('default.save')" @click="updateTransfer">
                            <font-awesome-icon :icon="'check'" class="plus__icon" />
                           {{ $t('default.save') }}
                        </b-btn>

                    </b-button-group>

                    <b-button-group class="mx-1">

                        <!-- Reset -->
                        <b-btn variant="light" v-b-tooltip.hover :title="$t('default.reset')" @click="refreshTransfer">
                            <font-awesome-icon :icon="'redo'" class="plus__icon" />
                            {{ $t('default.reset') }}
                        </b-btn>

                    </b-button-group>


                </b-button-toolbar>
            </b-form-group>

        </form>
    </v-wait>

</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    import Treeselect from '@riophae/vue-treeselect';

    export default {

        components: { Treeselect },

        mounted: function () {
            // Component mounted
            if(this.lm){

                if(this.lm.ticket_id){

                    this.fetchTransfer(this.lm.ticket_id);

                }
            }
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {};

        },

        computed: {

            // Map getters
            ...mapGetters({

                // Get last message
                lm: 'Messages/getMessageLast',

                // Get ticket
                ticket: 'Ticket/getTicket',
                users: 'Ticket/getUsers',
                teams: 'Ticket/getTeams',

                // Ticket data
                getTransferUsers: 'Ticket/getTransferUsers',
                getTransferTeams: 'Ticket/getTransferTeams',
                getTransferNote: 'Ticket/getTransferNote',

                // User
                getUser: 'User/getUser',

                //Team
                getTeam: 'User/getUserTeams',

                // Permission
                getPermission: 'Ticket/getPermission',

            }),



            // Ticket users
            ticket_users: {

                // getter
                get: function () {
                    return this.getTransferUsers;
                },

                // setter
                set: function (value) {
                    // Update transfer users
                    this.updateTransferUsers(value);
                },

            },

            // Ticket teams
            ticket_teams: {

                // getter
                get: function () {
                    return this.getTransferTeams;
                },

                // setter
                set: function (value) {
                    // Update transfer teams
                    this.updateTransferTeams(value);
                },

            },

            // Note
            note: {

                // getter
                get: function () {
                   // return this.getTransferNote;
                },

                // setter
                set: function (value) {
                    // Update note
                    this.updateTransferNote(value);
                },

            },

            // Check Permission
            permission_error: {

                // getter
                get: function () {
                    // Get Schedule Data
                    return this.getPermission;
                },

            },

            // Ticket teams
            user_teams: {

                // getter
                get: function () {
                    return this.getTeams;
                },


            },

            // reply check
            reply_check: {

                // getter
                get: function () {
                    return this.transfer_check;
                },


            },

        },
        
        methods: {

            // Some actions
            ...mapActions('Ticket', [
                'fetchTransfer',
                'updateTransfer',
                'updateTransferUsers',
                'updateTransferTeams',
                'updateTransferNote',
            ]),

            ...mapActions('Messages', [
                'refreshMessages',
            ]),

            // Reset Ticket Data and Transfer Data from database
            refreshTransfer () {
                this.fetchTransfer(this.ticket.id);
            },

            user_normalizer: function(node){

                /*// check for active or deactivate transfers users
                if(this.getUser.is_super || node.id == this.getUser.id || !this.ticket_users){
                    this.user_result = false;
                }else if(node.id !== this.getUser.id && this.ticket_users){
                    this.user_result = true;
                }*/

                let user_ticket = [];
                if(this.getTransferUsers) {
                    // check for active or deactivate transfers users
                    // let user_ticket = [];
                    this.getTransferUsers.forEach(obj => {
                        user_ticket.push(obj.id);
                    });
                    let user_loggedIn = [];
                    user_loggedIn.push(node.id);
                    //console.log('a22 user result : ' + this.user_ticket);
                    /*console.log('transfer_users result : ' + JSON.stringify(this.customer));*/
                    let a11 = user_ticket.some((val) => user_loggedIn.indexOf(val) !== -1);
                    if (!this.getUser.is_super && !a11 && node.id !== this.getUser.id && user_ticket.length) {
                        this.user_result = true;
                    } else if (this.getUser.is_super || a11 && node.id == this.getUser.id || !user_ticket) {
                        // It means disable = truee
                        this.user_result = false;
                    }
                }

                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                    isDisabled: this.user_result,
                }
            },

            team_normalizer: function(node){

                // check for active or deactivate transfers teams
                let team_ticket = [];
                team_ticket.push(node.id);
                let a1 = team_ticket.some((val) => this.getTeam.indexOf(val) == -1);
                if(this.getUser.is_super || !this.reply_check || !team_ticket){
                    this.team_result = false;
                }else if(!this.getUser.is_super && !a1 && team_ticket){
                    this.team_result = true;
                }

                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                    isDisabled: this.team_result,
                }
            },

            transfer_check: function(){

                // check for active or deactivate transfers teams

                if(this.getTransferTeams || this.getTransferUsers){

                    let team_ticket = [];
                    if(this.getTransferTeams){
                        this.getTransferTeams.forEach( obj => {
                            team_ticket.push(obj.id);
                        });
                        let a1 = team_ticket.some((val) => this.getTeam.indexOf(val) !== -1);
                        if(!this.getUser.is_super && !a1 || team_ticket){
                            this.team_result = false;
                        }else if(this.getUser.is_super || a1 || !team_ticket.length){
                            // It means disable = true
                            this.team_result = true;
                        }
                        console.log('team result : ' + this.team_result);
                    }

                    let user_ticket = [];
                    if(this.getTransferUsers) {
                        // check for active or deactivate transfers users
                        // let user_ticket = [];
                        this.getTransferUsers.forEach(obj => {
                            user_ticket.push(obj.id);
                        });
                        let user_loggedIn = [];
                        user_loggedIn.push(this.getUser.id);
                        //console.log('a22 user result : ' + this.user_ticket);
                        /*console.log('transfer_users result : ' + JSON.stringify(this.customer));*/
                        let a11 = user_ticket.some((val) => user_loggedIn.indexOf(val) !== -1);
                        if (this.getUser.is_super || a11 || !user_ticket.length) {
                            this.user_result = true;
                        } else if (!this.getUser.is_super && !a11 || user_ticket) {
                            // It means disable = true
                            this.user_result = false;
                        }
                    }

                    console.log('user result : ' + this.user_result);

                }
                console.log('last result : ' + !(!this.team_result && !this.user_result));
                if(this.team_result || this.user_result){
                    return false;
                }else {
                    return true;
                }

            },

        },

        watch: {
            // whenever last message changes, this function will run
            lm: function (lm) {


              if(lm){

                  this.fetchTransfer(lm.ticket_id);
                  console.log(lm.ticket_id);
                  console.log(lm);
                  console.log('lm ------------');

              }
            }
        },
        
    }
</script>