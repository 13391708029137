<template>

    <b-tabs pills card v-model="tab_index">

        <b-tab :title="$t('default.select')" active>
            <!-- Gallery select -->
            <gallery-select
                    v-on:image-select="image_select"
                    class="container"
            ></gallery-select>
        </b-tab>

        <b-tab :title="$t('default.upload')">

            <!-- Gallery upload -->
            <gallery-upload
                    v-on:upload-complete="tab_select(0)"
            ></gallery-upload>

        </b-tab>

    </b-tabs>

</template>

<script>

    export default {

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
        },

        data: function() {
            return  {

                tab_index: 0,
            }
        },

        computed: {},

        methods: {

            // Select tab
            tab_select: function(tab){
                this.tab_index = tab;
            },

            // Image select
            image_select: function(src){

                // Src is image source
                this.$emit('image-add', 'image', src);
            },



        },
        
    }
</script>