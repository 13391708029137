<template>

    <div class="container-fluid">

        <div class="row flex-column">

            <!-- About -->
            <div class="panel-title panel-title pb-2 text-uppercase text-muted">{{ $t('customer.about') }}</div>

            
            <!-- Name -->
            <div class="pb-2">

                <font-awesome-icon :icon="'user'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.name')"/> <span class="font-weight-light ml-2">{{linkedin.name }}</span>

            </div>

            <!-- Biography -->
            <div class="pb-2" v-if="linkedin.biography">

                <font-awesome-icon :icon="'signature'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.biography')"/> <span class="font-weight-light ml-2">{{linkedin.biography }}</span>

            </div>

            <!-- Url -->
            <div class="pb-2" v-if="linkedin.username">

                <font-awesome-icon :icon="'link'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.url')"/> <span><b-link :href="'https://linkedin.com/in/' + linkedin.username" class="font-weight-light ml-2" target="_blank" rel="noopener noreferrer">{{ $t('simple.view_profile') }}</b-link></span>

            </div>


        </div>

    </div>


</template>

<script>

    export default {

        props: ['linkedin'],

        mounted: function () {
            // Component mounted
            //console.log('Customer Type Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Type Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {

                moment: this.$moment,

            };

        },

        computed: {},
        
        methods: {},
        
    }
</script>