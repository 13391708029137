<template>
<!-- List Actions -->
    <div class="plus-col-main-messages-panel">
        <!-- Actions menu -->
        <messages-render-action-buttons :customer_id="customer_id" v-on:timeline_action="timeline = !timeline" :disabled_areas="disabled_areas"></messages-render-action-buttons>
        <!-- List messages -->
        <messages-list-render :timeline="timeline"></messages-list-render>
        <!-- Message Reply Render -->
        <b-alert show variant="danger" v-if="customer.user_assign && customer.user_assigned !== user.id">
            <span class="assigned-alignment">
                {{ $t('customer.is_replied_by', {
                    user_name: customer.user_assign.ns
                }) }}
            </span>
            <b-btn variant="warning" v-if="user.is_super" @click="unassignCustomer" class="float-right" >
                {{ $t('customer.unassigned') }}
                <font-awesome-icon :icon="'times'" class="plus__icon text-danger" size="lg"/>
            </b-btn>
        </b-alert>
        <reply-render :disabled_areas="disabled_areas" v-if="(customer.user_assigned === null || customer.user_assigned === user.id || user.is_super) && inbox_type !== 'trash'" :key="customer_id" v-on:updateLast="user_assign('set')"></reply-render>
    </div>
</template>

<script>

import {mapGetters, mapActions, mapMutations} from 'vuex';

export default ({
    props: ['customer_id'],
    data: function() {
        return {
            inbox_type: this.$route.params.type,
            timeline: false,
        }
    },
    methods: {
        // Reply
        ...mapActions('Reply', [
            'unassignCustomer',
        ]),
    },

    computed: {
        ...mapGetters({
            customer: 'Customer/getCustomer',
            user: 'User/getUser',
            disabled_areas: 'Accounts/getDisabledAreas',

        })
    }

})

</script>