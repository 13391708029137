<template>
    <div>
        <template v-if="window_width >= 992">

            <!-- Edit Profile -->
            <b-dropdown-item @click="edit(user.id)">{{ $t('simple.edit') }}</b-dropdown-item>

            <!-- Password -->
            <b-dropdown-item  @click="password()">{{  $t('user.password') }}</b-dropdown-item>

            <!-- Logout -->
            <b-dropdown-item @click="logout()">{{  $t('auth.logout') }}</b-dropdown-item>

        </template>

        <template v-else>

            <!-- Edit Profile -->
            <b-link class="plus-header-accordion-links" @click="edit(user.id)">{{ $t('simple.edit') }}</b-link>
            <!-- Password -->
            <b-link class="plus-header-accordion-links"  @click="password()">{{  $t('user.password') }}</b-link>
            <!-- Logout -->
            <b-link class="plus-header-accordion-links" @click="logout()">{{  $t('auth.logout') }}</b-link>


        </template>
    </div>

</template>
<script>

import {mapActions, mapGetters} from 'vuex';

import { create } from 'vue-modal-dialogs'
import UserEdit from "../Settings/Modal/UserEdit";
import UserPassword from '../Settings/Modal/UserPassword.vue'


export default {

    props: ['user'],

    mounted() {
        this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
    },

    data: function () {
        return {
            window_width: window.innerWidth,
        }
    },

    methods: {
        onResize() {
            this.window_width = window.innerWidth;
        },  

        logout: async function() {
          this.userLogout();
        },
        edit: async function(id) {
          // Show User edit modal
          const user_edit = create(UserEdit, 'id' , 'is_user');
          if (await user_edit(id, true) ){
            //
          }
        },
        password: async function() {
            // Show user logout modal
            const user_password = create(UserPassword);
            if (await user_password() ){
            }
        },


        ...mapActions('User', [
          'userLogout'
        ]),
    },

        // computed: {
        // // Map getters
        // ...mapGetters({
        //     user: 'User/getUser',
        // }),
        
        // },


}

</script>