<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="frequencyMaps.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";


    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('xy', this.frequencyMaps )

                
                let root;


                if ( this.frequencyMaps.length > 0) {
                    root = am5.Root.new(this.$refs.chartdiv);
                } else {
                    root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([am5themes_Animated.new(root)]);

                let data = this.frequencyMaps.length ? this.frequencyMaps : this.fakeFrequencyMaps;

                let chart = root.container.children.push(am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "none",
                        wheelY: "none",
                        layout: root.verticalLayout
                }));

                let yRenderer = am5xy.AxisRendererY.new(root, {
                        visible: false,
                        minGridDistance: 20,
                        inversed: true
                });

                yRenderer.grid.template.set("visible", false);

                let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                        maxDeviation: 0,
                        renderer: yRenderer,
                        categoryField: "hour"
                }));

                let xRenderer = am5xy.AxisRendererX.new(root, {
                        visible: false,
                        minGridDistance: 20,
                        opposite: true
                });

                xRenderer.grid.template.set("visible", false);

                let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                        renderer: xRenderer,
                        categoryField: "weekday"
                }));

                let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                        calculateAggregates: true,
                        stroke: am5.color(0xffffff),
                        clustered: false,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        categoryXField: "weekday",
                        categoryYField: "hour",
                        valueField: "value"
                }));
                series.columns.template.setAll({
                        tooltipText: "{value}",
                        strokeOpacity: 1,
                        strokeWidth: 2,
                        width: am5.percent(100),
                        height: am5.percent(100)
                });

                series.columns.template.events.on("pointerover", function (event) {
                        let di = event.target.dataItem;
                        if (di) {
                                heatLegend.showValue(di.get("value", 0));
                        }
                });

                series.events.on("datavalidated", function () {
                        heatLegend.set("startValue", series.getPrivate("valueHigh"));
                        heatLegend.set("endValue", series.getPrivate("valueLow"));
                });

                series.set("heatRules", [{
                        target: series.columns.template,
                        min: am5.color(0xfffb77),
                        max: am5.color(0xfe131a),
                        dataField: "value",
                        key: "fill"
                }]);

                series.data.setAll(data);

                let heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
                        orientation: "horizontal",
                        endColor: am5.color(0xfffb77),
                        startColor: am5.color(0xfe131a)
                }));

                xAxis.data.setAll([
                        { weekday: "Monday" },
                        { weekday: "Tuesday" },
                        { weekday: "Wednesday" },
                        { weekday: "Thursday" },
                        { weekday: "Friday" },
                        { weekday: "Saturday" },
                        { weekday: "Sunday" },
                ]);

                yAxis.data.setAll([
                        { hour: "0:00" },
                        { hour: "1:00" },
                        { hour: "2:00" },
                        { hour: "3:00" },
                        { hour: "4:00" },
                        { hour: "5:00" },
                        { hour: "6:00" },
                        { hour: "7:00" },
                        { hour: "8:00" },
                        { hour: "9:00" },
                        { hour: "10:00" },
                        { hour: "11:00" },
                        { hour: "12:00" },
                        { hour: "13:00" },
                        { hour: "14:00" },
                        { hour: "15:00" },
                        { hour: "16:00" },
                        { hour: "17:00" },
                        { hour: "18:00" },
                        { hour: "19:00" },
                        { hour: "20:00" },
                        { hour: "21:00" },
                        { hour: "22:00" },
                        { hour: "23:00" },
                ]);

                if( this.frequencyMaps.length > 0) {
                    am5plugins_exporting.Exporting.new(root, {
                        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                        dataSource: data,
                        filePrefix: "convoTypes",
                    });
                }

                this.root = root;








//                 // Create chart
//                 let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
//                 chart.maskBullets = false;

//                 let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
//                 let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

//                 xAxis.dataFields.category = "weekday";
//                 yAxis.dataFields.category = "hour";

//                 xAxis.renderer.grid.template.disabled = true;
//                 xAxis.renderer.minGridDistance = 40;

//                 yAxis.renderer.grid.template.disabled = true;
//                 yAxis.renderer.inversed = true;
//                 yAxis.renderer.minGridDistance = 30;

//                 let series = chart.series.push(new am4charts.ColumnSeries());
//                 series.dataFields.categoryX = "weekday";
//                 series.dataFields.categoryY = "hour";
//                 series.dataFields.value = "value";
//                 series.sequencedInterpolation = true;
//                 series.defaultState.transitionDuration = 3000;

//                 let bgColor = new am4core.InterfaceColorSet().getFor("background");

//                 let columnTemplate = series.columns.template;
//                 columnTemplate.strokeWidth = 1;
//                 columnTemplate.strokeOpacity = 0.2;
//                 columnTemplate.stroke = bgColor;
//                 columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";
//                 columnTemplate.width = am4core.percent(100);
//                 columnTemplate.height = am4core.percent(100);

//                 series.heatRules.push({
//                         target: columnTemplate,
//                         property: "fill",
//                         min: am4core.color(bgColor),
//                         max: chart.colors.getIndex(0)
//                 });

// // heat legend
//                 let heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
//                 heatLegend.width = am4core.percent(100);
//                 heatLegend.series = series;
//                 heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
//                 heatLegend.valueAxis.renderer.minGridDistance = 30;

// // heat legend behavior
//                 series.columns.template.events.on("over", function(event) {
//                         handleHover(event.target);
//                 })

//                 series.columns.template.events.on("hit", function(event) {
//                         handleHover(event.target);
//                 })

//                 function handleHover(column) {
//                         if (!isNaN(column.dataItem.value)) {
//                                 heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
//                         }
//                         else {
//                                 heatLegend.valueAxis.hideTooltip();
//                         }
//                 }

//                 series.columns.template.events.on("out", function(event) {
//                         heatLegend.valueAxis.hideTooltip();
//                 })

//                 chart.data = this.frequencyMaps;


        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }
            if (this.root) {
                this.root.dispose();
            }
        },



        data: function () {
            return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({

                        frequencyMaps: 'Report/getFrequencyMaps',
                        fakeFrequencyMaps: 'Report/getFakeFrequencyMaps',


                })

        },
    }
</script>