<template>
    <div class="row">

        <div class="col pt-2">


            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.customertypes') }}</h4>

                        <div class="ml-auto align-self-center">

                            <b-button :variant="'primary'" @click="add" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add')}}
                            </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.customertypes_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-2">


                    <v-wait for="customer_types">


                        <loading slot="waiting"/>

                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                      <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <template v-if="window_width >= 1200">                        
                            <b-table responsive bordered hover :items="customer_types" :fields="customer_type_fields" :filter="filter">

                                <!-- Is important -->
                                <template slot="is_important" slot-scope="data">

                                    <template v-if="data.value">
                                       <b-badge variant="success">{{ $t('simple.yes') }}</b-badge>
                                    </template>
                                    <template v-else>
                                        <b-badge variant="danger">{{ $t('simple.no') }}</b-badge>
                                    </template>

                                </template>

                                <!-- Is VIP -->
                                <template slot="is_vip" slot-scope="data">

                                    <template v-if="data.value">
                                       <b-badge variant="success">{{ $t('simple.yes') }}</b-badge>
                                    </template>
                                    <template v-else>
                                        <b-badge variant="danger">{{ $t('simple.no') }}</b-badge>
                                    </template>

                                </template>

                                <!-- Actions -->
                                <template slot="cell(actions)" slot-scope="cell">

                                  <b-dropdown right :text="$t('simple.actions')" size="sm">

                                    <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>

                                    <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>


                                  </b-dropdown>

                                </template>

                                <!-- Created at -->
                                <template slot="cell(created_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                                <!-- Updated at -->
                                <template slot="cell(updated_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                            </b-table>
                        </template>

                        <template v-else>
                            <b-table 
                                stacked="md"
                                small
                                responsive
                                bordered 
                                hover
                                :items="customer_types"
                                :fields="customer_type_fields_collapsed"
                                :filter="filter"
                            >
                            <!-- Is important -->
                            <template slot="is_important" slot-scope="data">

                                <template v-if="data.value">
                                   <b-badge variant="success">{{ $t('simple.yes') }}</b-badge>
                                </template>
                                <template v-else>
                                    <b-badge variant="danger">{{ $t('simple.no') }}</b-badge>
                                </template>

                                </template>

                                <!-- Is VIP -->
                                <template slot="is_vip" slot-scope="data">
                                
                                    <template v-if="data.value">
                                       <b-badge variant="success">{{ $t('simple.yes') }}</b-badge>
                                    </template>
                                    <template v-else>
                                        <b-badge variant="danger">{{ $t('simple.no') }}</b-badge>
                                    </template>

                                </template>

                                    <!-- Actions -->
                                <template slot="cell(actions)" slot-scope="cell">

                                    <b-button size="sm" @click="cell.toggleDetails()">
                                        {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
                                    </b-button>

                                    <b-dropdown right :text="$t('simple.actions')" size="sm" class="py-1">

                                        <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>
                                    
                                        <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>
                                    
                                    </b-dropdown>

                                </template>

                                <template #row-details="row">
                                        <b-table
                                            small
                                            stacked="md"
                                            class="d-xl-none"
                                            responsive
                                            bordered
                                            hover
                                            :items="[row.item]"
                                            :fields="customer_type_fields_not_collapsed"
                                        >
                                            <!-- Created at -->
                                            <template slot="cell(created_at)" slot-scope="cell">
                                            
                                                 {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                            </template>
                                        
                                            <!-- Updated at -->
                                            <template slot="cell(updated_at)" slot-scope="cell">
                                            
                                                 {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                            
                                            </template>
                                        
                                        </b-table>
                                </template>

                            </b-table>
                        </template>
                    </v-wait>

                </div>

            </div>

        </div>

    </div>
</template>

<script>

    import CustomerTypeAdd from './Modal/CustomerTypeAdd.vue'
    import CustomerTypeEdit from './Modal/CustomerTypeEdit.vue'
    import CustomerTypeDelete from './Modal/CustomerTypeDelete.vue'

    import { create } from 'vue-modal-dialogs'

    export default {

        mounted: function () {
            // Component mounted
            console.log('Customer Types component mounted.')

            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        destroyed() {
            // Component destroyed
            console.log('Customer Types component destroyed.');
        },

        created: function() {
            // Component created
           this.getCustomerTypes();
        },


        data: function() {
            return  {
                window_width: window.innerWidth,                
                customer_types_api: '/api/v1/settings/customer/types/',
                customer_types: [],
                filter: null,
                customer_type_fields: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true },
                    { key: 'is_important', label: this.$t('simple.important'), sortable: true },
                    { key: 'is_vip', label: this.$t('simple.vip'), sortable: true },
                    { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                    { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                customer_type_fields_collapsed: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true },
                    { key: 'is_important', label: this.$t('simple.important'), sortable: true },
                    { key: 'is_vip', label: this.$t('simple.vip'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                customer_type_fields_not_collapsed: [
                    { key: 'created_at', label: this.$t('simple.created') },
                    { key: 'updated_at', label: this.$t('simple.updated') },
                ],
                
            }
        },

        metaInfo: function() {

            return {
                title: this.$t('page.customertypes'),
            }

        },

        methods: {

            getCustomerTypes: async function(){

                // Start waiting
                this.$wait.start('customer_types');

                // Get customer types
                await axios.get(this.customer_types_api)

                  .then((response) => {
                    // response data
                    this.customer_types = response.data;

                    //console.log(response);
                    // End waiting
                    this.$wait.end('customer_types');

                  })
                  .catch((error) => {

                    console.log(error);

                    // End waiting
                    this.$wait.end('customer_types');

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));


                  });

            },


            add: async function() {
                // Show CustomerType add modal
                const customer_type_add = create(CustomerTypeAdd);

                if (await customer_type_add() ){
                    // Refresh account list
                    this.getCustomerTypes();
                }

            },

            edit: async function(id) {
                // Show customer type edit modal
                const customer_type_edit = create(CustomerTypeEdit, 'id');

                if (await customer_type_edit(id) ){
                    // Refresh customer type list
                    this.getCustomerTypes();
                }

            },

            remove: async function(id) {

                // Show customer type remove modal
                const customer_type_remove = create(CustomerTypeDelete, 'id');

                if (await customer_type_remove(id) ){
                    // Refresh Customer Type list
                    this.getCustomerTypes();
                }

            },

            onResize() {
                this.window_width = window.innerWidth;
            },  

        },

    }
</script>