<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="confirmations.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    import * as am5 from "@amcharts/amcharts5";
    import * as am5percent from "@amcharts/amcharts5/percent";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
    import am5themes_Material from "@amcharts/amcharts5/themes/Material";


//     /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('percent', this.confirmations )


                let root;


                if (this.confirmations.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([
                        am5themes_Animated.new(root),
                        am5themes_Material.new(root),
                ]);

                let chart = root.container.children.push(
                        am5percent.PieChart.new(root, {
                                layout: root.horizontalLayout,
                                radius: am5.percent(80),
                                innerRadius: am5.percent(60)
                        })
                );

                let data = this.confirmations.length ? this.confirmations : this.fakeConfirmations;

                let series = chart.series.push(
                        am5percent.PieSeries.new(root, {
                                name: "Series",
                                valueField: "value",
                                categoryField: "category"
                        })
                );

                series.labels.template.set("forceHidden", true);
                series.ticks.template.set("forceHidden", true);

                series.slices.template.set("toggleKey", "none");

                series.slices.template.setAll({
                        tooltipText: "{category}: {value}",
                });

                var label = series.children.push(am5.Label.new(root, {
                        text: this.total,
                        fontSize: 20,
                        centerX: am5.percent(50),
                        centerY: am5.percent(50)
                }));

                series.data.setAll(data);

                var legend = chart.children.push(am5.Legend.new(root, {
                        centerY: am5.percent(50),
                        y: am5.percent(50),
                        layout: root.verticalLayout
                }));

                legend.data.setAll(series.dataItems);

                if( this.confirmations.length > 0) {
                    am5plugins_exporting.Exporting.new(root, {
                        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                        dataSource: data,
                        filePrefix: "convoTypes",
                    });
                }

                this.root = root;



                // // Create chart instance
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
                // chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

                // // Need Time Based Data
                // chart.data = [{
                //         action: this.$t('confirmation.accepted'),
                //         value: this.confirmations[1],
                //         "color": am4core.color("#67b7dc"),
                // }, {
                //         action: this.$t('confirmation.rejected'),
                //         value: this.confirmations[2],
                //         "color": am4core.color("#b4a08a"),
                // }, {
                //         action: this.$t('confirmation.awaiting'),
                //         value: this.confirmations[0],
                //         "color": am4core.color("#67dc9c"),
                // }];

                // let series = chart.series.push(new am4charts.PieSeries());
                // series.dataFields.value = "value";
                // series.dataFields.category = "action";

                // series.slices.template.propertyFields.fill = "color";


                // series.ticks.template.disabled = true;
                // series.labels.template.disabled = true;

                // series.hiddenState.properties.endAngle = -90;

                // chart.legend = new am4charts.Legend();

                // chart.innerRadius = am4core.percent(60);

                // let label = series.createChild(am4core.Label);
                // label.text = this.$t('reports.total') + "\n[bold]{values.value.sum}[/]";
                // label.horizontalCenter = "middle";
                // label.verticalCenter = "middle";
                // label.fontSize = 25;

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoConfirmation";




        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

                if (this.root) {
                        this.root.dispose();
                }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({

                        confirmations: 'Report/getConfirmations',
                        fakeConfirmations: 'Report/getFakeConfirmations'
                        
                }),

                total() {
                        let array = this.confirmations.length ? this.confirmations : this.fakeConfirmations;
                        let sum = array.reduce( (a,b) =>{
                                return a + b.value;
                        },0);
                        return sum;
                }

        },
    }
</script>