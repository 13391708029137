<template>


    <div class="row flex-md-nowrap">

        <!-- Toggleable sidebar view for tablet and smaller screens -->
        <template v-if=" window_width < 992">
            <b-sidebar visible no-header shadow id="plus-col-accounts-sidebar-mobile" class="z-index-fix" bg-variant="white" >
                <div class="plus-col-accounts-mobile-view border-bottom-0 px-3 py-2" >
                    <accounts-view-list></accounts-view-list>

                </div>
            </b-sidebar>
        </template>

        <!-- Toggleable view for screens bigger than tablet-->
        <template v-else-if="window_width >= 992 && window_width < 1200 "> 
            <b-collapse visible id="plus-col-accounts-sidebar" class="plus-col-accounts col-lg-3 col-xl-2 col-12 border-bottom-0" >
                <accounts-view-list></accounts-view-list>
            </b-collapse>
        </template>

        <template v-else>
            <div class="plus-col-accounts col-lg-3 col-xl-2 col-12 border-bottom-0" >
                <accounts-view-list></accounts-view-list>
            </div>
        </template>



        <!-- Chilren router view -->

        <div class="plus-col-main col col-xl-10">

            <transition name="plus-fade">

                <router-view></router-view>

            </transition>


        </div>


    </div>


</template>
<script>
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    export default {

        mounted: function () {
          // Mounted
          console.log('Accounts View mounted.');

          this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
            })
        },

        created: function () {
          // Mounted
          console.log('Accounts View created.');

        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },


        destroyed() {
          // Component destroyed
          console.log('Accounts View destroyed.');
        },

        data: function () {

            return {
                window_width: window.innerWidth,

            }
        },

        methods: {
            onResize() {
            this.window_width = window.innerWidth;
            },  
        },

        computed: {

        },

        watch: {
 
        },


    }
</script>