<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('default.add')}}
                    </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">

                  <v-wait for="add">

                    <loading slot="waiting"/>

                    <form @submit.prevent="validate_add">

                                <!-- Name -->
                                <div class="form-group">
                                    <label for="name">
                                        {{ $t('simple.name') }}
                                    </label>

                                    <input
                                        class="form-control"
                                        type="text"
                                        id="name"
                                        name="name"
                                        v-validate="'required|max:15'"
                                        v-model="name">

                                    <small v-show="errors.has('name')" class="c-field__message u-color-danger">
                                        <font-awesome-icon :icon="'times-circle'" />
                                        {{ errors.first('name') }}
                                    </small>
                                </div>

                                <!-- Dispplay Name -->
                                <div class="form-group">
                                    <label for="display_name">
                                        {{ $t('simple.display_name') }}
                                    </label>

                                    <input
                                        class="form-control"
                                        type="text"
                                        id="display_name"
                                        name="display_name"
                                        v-validate="'required|max:50'"
                                        v-model="display_name">

                                    <small v-show="errors.has('display_name')" class="c-field__message u-color-danger">
                                        <font-awesome-icon :icon="'times-circle'" />
                                        {{ errors.first('display_name') }}
                                    </small>
                                </div>

                                <!-- Description -->
                                <div class="form-group">
                                    <label for="description">
                                        {{ $t('simple.desc') }}
                                    </label>

                                    <input
                                        class="form-control"
                                        type="text"
                                        id="description"
                                        name="description"
                                        v-validate="'required|max:50'"
                                        v-model="description">

                                    <small v-show="errors.has('description')" class="c-field__message u-color-danger">
                                        <font-awesome-icon :icon="'times-circle'" />
                                        {{ errors.first('description') }}
                                    </small>
                                </div>

                            </form>

                  </v-wait>
                    </div>
                    <div class="modal-footer">
                        <b-button @click="$close(false)" variant="secondary">
                            {{ $t('simple.cancel') }}
                        </b-button>
                        <b-button @click="validate_add" variant="primary">
                            {{ $t('simple.save') }}
                        </b-button>
                    </div>
            </div><!-- // .c-modal__content -->
        </div><!-- // .c-modal__dialog -->
    </div><!-- // .c-modal -->
</template>
<script>

    export default {

        mounted: function () {
            // Component mounted
            console.log('Team Add Modal mounted');
        },
        
        destroyed() {
            // Component destroyed
            console.log('Team Add Modal destroyed.');
        },

        created: function() {
            // Component created
            console.log('Team Add component created.');
        },
        data: function() {
            return  {
                name: '',
                description: '',
                display_name: '',
            }
        },

        methods: {
            team_add: async function(){

                // Loading start
                this.$wait.start('add');

                // Post request
                await axios.post('/api/v1/settings/teams', {
                    name: this.name,
                    display_name: this.display_name,
                    description: this.description,
                  })
                  .then((response) => {

                    // Show success
                    Vue.toasted.success(this.$t('simple.success_info'));

                    // Loading end
                    this.$wait.end('add');

                    // Close modal
                    this.$close(true);

                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    console.log(error);

                    // Loading end
                    this.$wait.end('add');

                  });

            },

            validate_add: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {

                        // Send it to account edit function
                        this.team_add();

                    }
                });
            },
        },
    }
</script>
