const state = {
  user: false,
  users: [],
  user_api: '/api/v1/settings/users/',
  permission:'',

};

// getters
const getters = {

  // Get User api
  getUserAPI: (state) => {
    // Return Users api
    return state.user_api;
  },

  // Get user
  getUsers: (state) => {
    // Return user
    return state.users;
  },

    // Get users
  getPermission: (state) => {
    // Return permission
    return state.permission;
  },

};

// mutations
const mutations = {

    // Set users
    setUsers (state,payload){

        state.users = payload;
  },

};

// actions
const actions = {

  // Fetch Users
  async fetchUsers({commit,dispatch, getters, state}){

      // Start waiting
      dispatch('wait/start', 'users', { root: true });

        // Get Users
      await axios.get(getters.getUserAPI)
            .then((response) => {

                if(response.data.error){
                    state.permission = Vue.i18n.translate('permission.read_users');
                    // Reset Users
                    commit('setUsers', []);
                    // Stop waiting
                    dispatch('wait/end', 'users', { root: true });

                }else {
                    // Set Users
                    commit('setUsers', response.data);

                    // Stop waiting
                    dispatch('wait/end', 'users', { root: true });
                }
            })
            .catch((error) => {

                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'users', { root: true });

            });

    },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}