<template>

    <!-- Confirmation card -->
    <b-col cols="12" class="mb-3">
        <b-card :class="active_tab_id == draft.customer_id ? 'border border-primary shadow' : ''" no-body>
            <b-card-text class="align-items-center d-flex" slot="header">
                <div>

                    <!-- Confirmation Customer -->
                    <confirmation-customer v-bind:account="account"
                                           v-bind:draft="draft"></confirmation-customer>

                    <span>{{ draft.customer.full_name }}</span>

                    <!-- Confirmation Account -->
                    <confirmation-account v-bind:account="account"></confirmation-account>
                </div>

                <div class="message-header-info" style="display: block !important;">


                    <!-- Confirmation Integration -->
                    <confirmation-integration v-bind:integration="account.integration_id"></confirmation-integration>

                    <!-- Confirmation Type Render -->
                    <confirmation-type v-bind:integration="account.integration_id"
                                       v-bind:type="draft.type"></confirmation-type>

                    <!-- Confirmation Schedule -->
                    <template v-if="draft.schedule">

                        <confirmation-schedule v-bind:schedule="$moment($moment.utc(draft.schedule.scheduled_at)).local().format('YYYY-MM-DD HH:mm:ss')"></confirmation-schedule>

                    </template>
                </div>
                <div class="Confirmation-time-fix">
                <!-- Confirmation Created -->
                <confirmation-date v-bind:date="$moment($moment.utc(draft.created_at)).local().format('YYYY-MM-DD HH:mm:ss')"></confirmation-date>
                </div>
            </b-card-text>

            <b-card-body>

                <span>{{ draft.content }}</span>

            </b-card-body>

            <div class="mb-2 ml-3">
                <!-- Message Attachments -->
                <message-attachments v-if="draft.attachment_id"
                                     v-bind:attachments="draft.attachments"></message-attachments>
            </div>

            <div class="col-6 mt-2 ml-2">
                <div>
                    <b-btn
                            size="sm"
                            class="mb-2 bg-dark"
                            :class="visible ? null : 'collapsed'"
                            :aria-expanded="visible ? 'true' : 'false'"
                            :aria-controls="'collapse-'+confirmation_id"
                            @click="visible = !visible"
                    >
                        <font-awesome-icon :icon="'comment-dots'"/>
                        Add Note
                    </b-btn>
                    <b-collapse :id="'collapse-'+confirmation_id" v-model="visible" class="mt-2">

                        <div class="form-group">
                            <label for="cn">Confirmation Note: </label>
                            <textarea
                                    id="cn"
                                    v-model="note"
                                    ref="textarea"
                                    class="form-control"
                                    rows="3"
                                    placeholder="Just type out your confirmation note here!"
                            ></textarea>

                        </div>

                    </b-collapse>
                </div>

            </div>

            <!-- Draft Note -->
            <template v-if="draft.draft_note">
                <b-card-body class="border-top">

                    <b-list-group class="d-inline">
                        <b-list-group-item class="d-inline border-warning">
                            <em> {{ draft.draft_note.content }}</em>
                        </b-list-group-item>
                    </b-list-group>

                </b-card-body>
            </template>

            <b-button-toolbar key-nav slot="footer">

                <!-- Confirmation Source Link -->
                <confirmation-source class="mx-1" v-bind:account="account" v-bind:draft="draft"></confirmation-source>


                <b-button-group class="mx-1">
                    <b-btn size="sm" class="mx-1" variant="outline-success" @click="update(1, note)">
                        <font-awesome-icon :icon="'check'"/>
                        Confirm
                    </b-btn>
                    <b-btn size="sm" class="mx-1" variant="outline-danger" @click="update(2, note)">
                        <font-awesome-icon :icon="'times'"/>
                        Cancel
                    </b-btn>
                    <b-btn size="sm" class="mx-1" variant="outline-secondary">
                        <font-awesome-icon :icon="'undo'"/>
                        Cancel and Return
                    </b-btn>
                </b-button-group>

                <div class="message-header-info">

                <!-- Confirmation Requested By -->
                <confirmation-requested-by v-bind:requested_by="user.ns"></confirmation-requested-by>

                </div>



            </b-button-toolbar>

        </b-card>

    </b-col>

</template>

<script>
    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    export default {

        props: [
            'account',
            'confirmation',
            'confirmation_id',
            'draft',
            'ticket',
            'user',
        ],

        mounted: function () {
            // Component mounted
            //console.log('Content render mounted')
            this.start_echo();
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
            this.leave_echo();
        },

        created: function () {
            // Component created
        },

        data: function () {
            return {
                visible: false,
            }
        },

        computed: {

            // Map getters
            ...mapGetters({

                // Get Active Tab Id
                active_tab_id: 'Confirmation/getActiveTabId'
            }),
            // Text
            note: {
                // getter
                get: function () {
                    // Get Schedule Data
                    return null;
                },
                // setter
                set: function (value) {
                    // Check if we have value?
                    if(value){

                        // Update Reply Text
                        this.updateNote(value);
                    }else {
                        return null;
                    }
                },

            },
        },

        methods: {

            // Actions
            ...mapActions('Confirmation', [
                'updateConfirmation',
                'updateNote',
                'sendNote',
                'getNote',
            ]),

            // Listen
            start_echo: async function () {

                Echo.private('confirmation-' + this.account.id)
                    //.subscribe('ConfirmationEvent');
                    .listen('ConfirmationEvent', (e) => {
                        console.log(e);
                        console.log('new confirmation came!');
                    });
            },

            // Leave
            leave_echo: async function (customer_id) {

                console.log('leave echo ' + customer_id);


                Echo.leave('inbox.customer.' + customer_id);
                Echo.leave('confirmation-' + this.account.id);

            },

            // Update confirmation
            update: function (status, note) {

                // Update text with emoji
                let data = {
                    id: this.confirmation_id,
                    status: status,
                    note: note,
                };

                this.updateConfirmation(data);

            },


        },

    }
</script>