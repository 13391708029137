<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.add')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="add">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_add">


                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="name"
                            name="name"
                            v-validate="'required|max:255'"
                            v-model="name"
                        >

                        <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="surname">{{ $t('simple.surname') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="surname"
                            name="surname"
                            v-validate="'required|max:255'"
                            v-model="surname"
                        >

                        <small v-show="errors.has('surname')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('surname') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="email">{{ $t('simple.email') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="email"
                            name="email"
                            v-validate="'required|email|max:255'"
                            v-model="email"
                        >

                        <small v-show="errors.has('email')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('email') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="phone">{{ $t('simple.phone') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="phone"
                            name="phone"
                            v-validate="'required|numeric|length:12'"
                            v-model="phone"
                        >

                        <small v-show="errors.has('phone')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('phone') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="password">{{ $t('simple.password') }}</label>

                        <input
                            class="form-control"
                            type="password"
                            id="password"
                            name="password"
                            v-validate="'required|min:8'"
                            v-model="password"
                        >

                        <small v-show="errors.has('password')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('password') }}</small>

                    </div>


                    <div class="form-group">

                        <label for="description">{{ $t('simple.super') }}</label>

                        <b-form-checkbox v-model="is_super">
                          {{ check_super() }}
                        </b-form-checkbox>

                        <b-alert variant="danger" :show="is_super">PLEASE BE WARNED THIS WILL BE SUPER ADMIN!!!</b-alert>

                    </div>

                </form>

            </v-wait>

        </div>

        <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

            <b-button @click="validate_add()" variant="primary">{{ $t('simple.save') }}</b-button>
            
        </div>


        </div>
      </div>
    </div>
</template>
<script>

    export default {

        mounted: function () {
            // Component mounted
            console.log('User Add Modal mounted');
        },
        
        destroyed() {
            // Component destroyed
            console.log('User Add Modal destroyed.');
        },

        created: function() {
            // Component created
        },

        data: function() {
            return  {

                users_api: '/api/v1/settings/users/',

                name: '',
                surname: '',
                email: '',
                phone: '',
                password: '',
                is_super: false,
            }
        },

        methods: {

            check_super: function() {
                if(this.is_super){
                    return this.$t('simple.yes');
                } else {
                    return this.$t('simple.no');
                }
            },

            user_add: async function(){


                // Loading start
                this.$wait.start('add');

                // Post request
                await axios.post(this.users_api, {
                    name: this.name,
                    surname: this.surname,
                    email: this.email,
                    phone: this.phone,
                    password: this.password,
                    super: this.is_super,
                  })
                  .then((response) => {

                    // Show success
                    Vue.toasted.success(this.$t('simple.success_info'));

                    // Loading end
                    this.$wait.end('add');

                    // Close modal
                    this.$close(true);


                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    console.log(error);

                    // Loading end
                    this.$wait.end('add');

                    // Close modal
                    //this.$close(true);


                  });

            },

            validate_add: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.user_add();
                    }
                });
            },
        },
    }
</script>
