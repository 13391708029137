<template>
    <!-- Reports Area -->
    <div class="convoreport" ref="chartdiv" v-if="accountActivities.length">
    </div>
    <div class="convoreport report-overlay" ref="fakechart" v-else>
    </div>
</template>

<script>

// Vuex
import { mapGetters, mapActions } from 'vuex';

/* Imports */
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";


import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";



/* Chart code */
// Themes begin
// am4core.useTheme(am4themes_animated);
// Themes end


export default {

    mounted() {

        console.log('xy', this.accountActivities )

        let root;


        if (this.accountActivities.length > 0) {
            root = am5.Root.new(this.$refs.chartdiv);
        } else {
            root = am5.Root.new(this.$refs.fakechart)
        }

        root.setThemes([
            am5themes_Animated.new(root),
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            layout: root.verticalLayout
        }));


        let raw_data = this.accountActivities.length ? this.accountActivities : this.fakeAccountActivities;

        let data = raw_data.filter( (ele) => ele.incoming + ele.outgoing !== 0)



        let xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 70
        });

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "account",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {
                themeTags: ["axis"],
                animationDuration: 200
            })
        }));
        xRenderer.labels.template.setAll({
            rotation: -45,
            centerY: am5.p50,
            centerX: am5.p100,
            fontSize: 7,
            oversizedBehavior: "wrap",
            maxWidth: 120,

        });

        xRenderer.grid.template.setAll({
            location: 1
        })

        xAxis.data.setAll(data);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1
            })
        }));

        let series0 = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Incoming",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "incoming",
            categoryXField: "account",
            clustered: false,
            tooltip: am5.Tooltip.new(root, {
                labelText: "Incoming: {valueY}"
            })
        }));

        series0.columns.template.setAll({
            width: am5.percent(80),
            tooltipY: 0,
            strokeOpacity: 0
        });


        series0.data.setAll(data);


        let series1 = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Outgoing",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "outgoing",
            categoryXField: "account",
            clustered: false,
            tooltip: am5.Tooltip.new(root, {
                labelText: "Outgoing: {valueY}"
            })
        }));

        series1.columns.template.setAll({
            width: am5.percent(50),
            tooltipY: 0,
            strokeOpacity: 0
        });


        series1.data.setAll(data);

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));

        let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
        }));

        legend.data.setAll(chart.series.values);

        if (this.accountActivities.length > 0) {
            am5plugins_exporting.Exporting.new(root, {
                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                dataSource: data,
                filePrefix: "convoTypes",
            });
        }


        this.root = root;






        // /* Create chart instance */
        // let chart = am4core.create(this.$refs.chartdiv, am4charts.RadarChart);

        // /* Add data */
        // // Twitter = TW, Facebook = FB, Instagram = IN, Whatsapp = WP
        // chart.data = this.accountActivities;

        // /* Create axes */
        // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        // categoryAxis.dataFields.category = "account";

        // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // /* Create and configure series */
        // let series = chart.series.push(new am4charts.RadarSeries());
        // series.dataFields.valueY = "outgoing";
        // series.dataFields.categoryX = "account";
        // series.name = this.$t('reports.outgoing');
        // series.strokeWidth = 3;
        // series.zIndex = 2;
        // series.sequencedInterpolation = true;
        // series.sequencedInterpolationDelay = 100;
        // series.tooltipText = this.$t('reports.activity') + ": {name}\n" + this.$t('simple.account') + ": {categoryX}\n" + this.$t('reports.value') + ": {valueY}";


        // let series2 = chart.series.push(new am4charts.RadarColumnSeries());
        // series2.dataFields.valueY = "incoming";
        // series2.dataFields.categoryX = "account";
        // series2.name = this.$t('reports.incoming');
        // series2.strokeWidth = 0;
        // series2.sequencedInterpolation = true;
        // series2.sequencedInterpolationDelay = 100;
        // series2.tooltipText = this.$t('reports.activity') + ": {name}\n" + this.$t('simple.account') + ": {categoryX}\n" + this.$t('reports.value') + ": {valueY}";


        // /* Add legend */
        // chart.legend = new am4charts.Legend();

        // /* Add cursor */
        // chart.cursor = new am4charts.RadarCursor();

        // // Export Menu to chart's
        // chart.exporting.menu = new am4core.ExportMenu();
        // // File names will be myExport.png, myExport.pdf, etc
        // chart.exporting.filePrefix = "convoAccount";


    },

    beforeDestroy() {
        // if (this.chart) {
        //     this.chart.dispose();
        // }

        if (this.root) {
                this.root.dispose();
        }
    },


    data: function () {
        return {}
    },


    methods: {},

    computed: {


        // Map getters
        ...mapGetters({

            accountActivities: 'Report/getAccountActivities',
            fakeAccountActivities: 'Report/getFakeAccountActivities',

        })

    },
}
</script>