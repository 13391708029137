<template>

    <div class="container-fluid">

        <div class="row">

            <!-- Text area form -->
            <div class="col mt-2">

                <div class="form-group">

                    <textarea
                        class="form-control message-reply-textarea"
                        v-model="note"
                        rows="6"
                        placeholder="Just type out your awesome note here!"></textarea>
                        
                </div>

            </div>

            <!-- Spacing -->
            <div class="w-100 border-bottom"></div>

            <!-- Text area form -->
            <div class="col my-3">

                <div class="container-fluid">

                    <div class="row">

                        <div class="col">

                            <b-button-toolbar key-nav  aria-label="Toolbar with button groups">

                                <b-button-group class="mx-1">

                                    <!-- Reply -->
                                    <b-btn variant="light" @click="sendNote">
                                        <font-awesome-icon :icon="'comment'" class="plus__icon" />
                                        {{ $t('default.add_note') }}
                                    </b-btn>

                                </b-button-group>

                                <b-button-group class="m-1">

                                  <!-- Attachment -->
                                  <b-btn variant="light"
                                         :pressed.sync="attachment_status">
                                    <font-awesome-icon :icon="'paperclip'" class="plus__icon"/>
                                    {{ $t('page.attachment') }}
                                  </b-btn>

                                </b-button-group>

                                <b-button-group class="mx-1">

                                    <!-- Reset-->
                                    <b-btn variant="light" v-b-tooltip.hover :title="$t('default.reset_note_desc')" @click="resetNote">
                                        <font-awesome-icon :icon="'redo'" class="plus__icon" />
                                        {{ $t('default.reset_note') }}
                                    </b-btn>

                                </b-button-group>
                               

                            </b-button-toolbar>

                        </div>

                    </div>

                </div>

                <!-- Upload Render -->
                <reply-upload v-if="attachment_status"></reply-upload>

            </div>

        </div>

    </div>

</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    export default {

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {};

        },

        computed: {

            // Map getters
            ...mapGetters({
                // Reply
                getNote: 'Reply/getNote',

                // User
                getUser: 'User/getUser',

                // Get Transfers Team & Users
                getTransferTeams: 'Ticket/getTransferTeams',
                getTransferUsers: 'Ticket/getTransferUsers',

                // Get Attacment Answer
                getAttachmentStatus: 'Reply/getAttachmentStatus',

                // Team
                getTeam: 'User/getUserTeams',

            }),

            // Note
            note: {

                // getter
                get: function () {
                    // Get Schedule Data
                    return this.getNote;
                },

                // setter
                set: function (value) {
                    // Check if we have value?
                    if(value){

                        // Update Reply Note
                        this.updateNote(value);
                    }
                },

            },

            // Active Attachment Status
            attachment_status: {

              // getter
              get: function () {
                // Get Attachment Status
                return this.getAttachmentStatus;
              },

              // setter
              set: function (value) {

                // Update Reply Text
                this.updateAttachmentStatus(value);
              },

            },

            // Ticket teams
            user_teams: {

                // getter
                get: function () {
                    return this.getTeams;
                },


            },

        },
        
        methods: {

            // Some actions detka
            ...mapActions('Reply', [

                'resetNote',
                'updateNote',
                'updateAttachmentStatus',
                'sendNote',

            ]),


        },
        
    }
</script>