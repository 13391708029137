<template>

    <div class="container-fluid p-0">

        <div class="row">

            <div class="col pt-2">

                <!--  Page  -->
                <div class="bg-white rounded py-2 shadow-widget border">

                    <!-- Page header -->
                    <div class="px-3">

                        <div class="d-flex">

                            <h4>{{ $t('page.mutes') }}</h4>

                        </div>

                        <span class="text-muted font-weight-light">{{ $t('page.mutes_desc') }}</span>

                    </div>

                    <hr>

                    <!-- Page content -->
                    <div class="px-2">

                        <!-- Wait -->
                        <v-wait for="mutes">

                            <loading slot="waiting"/>

                            <b-row>
                                <b-col md="4" class="my-2 ml-auto">
                                    <b-input-group>
                                        <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                        <b-input-group-append>
                                          <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                            </b-row>

                            <b-table stacked="lg" striped responsive bordered hover :items="mutes" :fields="mute_fields" :filter="filter" class="vh-25">

                                <!-- Actions -->
                                <template slot="cell(actions)" slot-scope="cell">

                                  <b-dropdown right :text="$t('simple.actions')" size="sm">

                                    <b-dropdown-item @click="remove(cell.item.id_str)">{{ $t('simple.delete') }}</b-dropdown-item>

                                  </b-dropdown>

                                </template>

                                <!-- Created at -->
                                <template slot="cell(created_at)" slot-scope="cell">

                                    {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>
                            

                            </b-table>

                        </v-wait>

                    </div>
                        

                </div>


            </div>

        </div>


    </div>


</template>

<script>

    import MuteRemove from './Modal/MuteRemove.vue'


    import { create } from 'vue-modal-dialogs'

    export default {

        props: ['id'],

        mounted: function () {
            // Component mounted
            console.log('Mute list component mounted.')
        },

        destroyed() {
            // Component destroyed
            console.log('Mute list component destroyed.');
        },

        created: function() {
            // Component created
            this.getMutes();
        },
        

        data: function() {
            return  {
                mutes_api: '/api/v1/twitter/mutes/',
                mutes: [],
                mute_fields: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'screen_name', label: this.$t('simple.username'), sortable: true },
                    { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                filter: null,

            }
        },
        
        metaInfo: function() {

            return {
               title: this.$t('page.mutes'),
            }
            
        },

        methods: {

            getMutes: async function(){

                // Start waiting
                this.$wait.start('mutes');

                // Get mutes
                await axios.get(this.mutes_api + this.id)

                  .then((response) => {
                    // response data
                    this.mutes = response.data.users;
                    //console.log(response);

                    // End waiting
                    this.$wait.end('mutes');


                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    console.log(error);

                    // End waiting
                    this.$wait.end('mutes');

                });

            },

            remove: async function(user_id) {
                
                // Show account remove modal
                const mute_remove = create(MuteRemove, 'id', 'user_id');

                if (await mute_remove(this.id, user_id) ){
                    // Refresh account list
                    this.getMutes();
                }

            },

        },
    }
</script>