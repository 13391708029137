import {DateTime} from "luxon";
import router from '../../router'



const state = {
    list : [],
    visible: true,
    attachment:false,
    form:{
        status : '',
        schedule_at: '',
        video: null,
        images :[],
        account_id: ''
    },
    schedule_start_date: DateTime.local().plus({ minutes: 1 }).toISO(),
    images :[],
    post : []
}

// getters
const getters = {

    // Get post
    getPost: state => {
        return state.post;
    },

    getList: state => {
        return state.list;
    },
}

// mutations
const mutations = {

    setPost (state, post){
        state.post = post;
    },

    setList (state, list){
        state.list = list;
    },

}

// actions
const actions = {

    async Post({ dispatch, state,commit },id){
        // Start waiting
        dispatch('wait/start', 'post', { root: true });
        await axios.get(`/api/v1/twitter/post/${id}`, )
            .then((data) =>{
                commit('setPost',data.data )
                // Stop waiting
                dispatch('wait/end', 'post', { root: true });
            })
            .catch((error) => {
                // Show error
                Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'post', { root: true });

            });
    },

    async removePost({ dispatch, state }, id){
        await axios.delete(`/api/v1/twitter/post/delete`,{
            data: {
                post_id: id
            }
        })
            .then(() =>{
                Vue.toasted.success(this.$t('simple.deleted_confirmed'));
            })
            .catch(()=>{
                Vue.toasted.error(this.$t('simple.error_info'));
            })
    },

    async getPostList({dispatch, state,commit},account_id){
        console.log()
        await axios.get('/api/v1/twitter/post', {
            params: {
                account_id : account_id
            }
        })
            .then((data) =>{
                commit('setList',data.data.data)
            })
            .catch()
    },

    async storePost({ dispatch, state,commit },payload){
        if (payload.type == 'twitter'){
            const formData = new FormData()
            formData.append('status',payload.status)
            formData.append('account_id',payload.account_id)
            formData.append('schedule_at',payload.schedule_at)



            if (payload.files){
                for (let i = 0; i < payload.files.length; i++){
                    if (payload.files[i].fileType == 'video/mp4' || payload.files[i].fileType == 'image/gif'){
                            formData.append('video',payload.files[i].file)
                    }else{
                            formData.append('images[]',payload.files[i].file)
                    }
                }
            }

            await axios.post('/api/v1/twitter/post', formData,{headers: {'Content-Type': 'multipart/form-data'}})
                .then((response) => {
                    Vue.toasted.success(Vue.i18n.translate('simple.created'));
                    setTimeout(() => {
                        router.push({
                            name : 'show_post',
                            params : {
                                postId : response.data.data.id
                            }
                        })
                    }, 2000);
                })
                .catch((error) => {
                    // Show error
                    Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
                    // Log to console
                    console.log(error);
                });
        }

    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}