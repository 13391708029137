<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.add')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="edit">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_edit">


                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="name"
                            name="name"
                            v-validate="'required|max:15'"
                            v-model="name"
                        >

                        <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="description">{{ $t('simple.desc') }}</label>

                        <input 
                            class="form-control"
                            type="text"
                            id="description"
                            name="description"
                            v-validate="'required|max:50'"
                            v-model="description"
                        >

                        <small v-show="errors.has('description')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('description') }}</small>

                    </div>

                </form>

            </v-wait>

        </div>

        <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

            <b-button @click="validate_edit()" variant="primary">{{ $t('simple.save') }}</b-button>
            
        </div>


        </div>
      </div>
    </div>
</template>
<script>

    export default {

        mounted: function () {
            // Component mounted
            console.log('Tag Edit Modal mounted');

            // Get data to edit
            this.edit(this.id);
        },

        destroyed() {
            console.log('Tag Edit Modal destroyed.');
        },

        data: function() {
             return  {
                tags_api: '/api/v1/settings/tags/',
                name: '',
                description: ''
            }
        },

        methods: {

            edit: async function(id) {

                // Loading start
                this.$wait.start('edit');

                // Get tags
                await axios.get(this.tags_api + id)
                    .then((response) => {

                        // Update modal data
                        this.name = response.data.name;
                        this.description  = response.data.description;

                        // Loading stop
                        this.$wait.end('edit');

                    })
                    .catch((error) => {

                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));

                        // Loading stop
                        this.$wait.end('edit');

                        // Close modal
                        this.$close(true);

                    });

            },

            tag_edit: async function(){

                // Loading start
                this.$wait.start('edit');

                await axios.put(this.tags_api + this.id, {
                    name: this.name,
                    description: this.description
                  })
                  .then((response) => {

                    // Show success
                    Vue.toasted.success(this.$t('simple.success_info'));

                    // Loading stop
                    this.$wait.end('edit');

                    // Close modal
                    this.$close(true);


                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Loading stop
                    this.$wait.end('edit');

                    // Close modal
                    //this.$close(true);

                  });

            },

            validate_edit: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.tag_edit();
                    }
                });
            },
        },
    }
</script>