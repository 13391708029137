<template>

    <span class="message-header-data" v-b-tooltip.hover :title="schedule">

        <font-awesome-icon :icon="'clock'" class="text-warning" />

        Schedule {{ schedule | moment("from", "now") }}

    </span>

</template>
<script>

    export default {

        props: ['schedule'],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>