<template>

<div>

  <div class="mt-2">
    <b-tabs small>

      <b-tab :active="is_customer === true">

        <template #title>
          <font-awesome-icon
              :icon="'user'"/>
        </template>
        <!-- Check if show customer is true -->
        <template>
          <!-- Show customer info end edit -->
          <customer-render :customer="customer"></customer-render>
        </template>

      </b-tab>
      <b-tab :disabled="customer == false" @click="getCustomerTicketData(customer.id)" :active="is_ticket === true">
        <template #title>
          <font-awesome-icon
              :icon="'ticket-alt'"/>
        </template>

        <template v-if="is_ticket">

          <!-- Show selected ticket info -->
          <ticket-render></ticket-render>

        </template>
        <template v-if="!is_ticket">

          <!-- Show ticket info -->
          <message-tickets></message-tickets>

        </template>


      </b-tab>
      <template #tabs-end >
        <li role="presentation" class="nav-item align-self-center ml-auto">
          <div tabindex="0" role="button" class="d-xl-none" 
          v-b-toggle="['user-info-sidebar-right-tablet', 'plus-col-accounts-sidebar','plus-col-main-list-inbox-tablet', 'plus-col-main-messages-panel-mobile']"
          >
              <font-awesome-icon :icon="'times'" class="plus__icon" />
            </div>
        </li>
       </template>

    </b-tabs>

  </div>

  </div>

</template>

<script>

    // Vuex
    import {mapActions, mapGetters} from 'vuex';

    export default {


        mounted: function () {
            // Component mounted
            //console.log('Content render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
            // this.getCustomerTicketData(this.customer.id);
        },
        
        data: function() { return {} },

        computed: {
          // Map getters
          ...mapGetters({

          customer: 'Customer/getCustomer',
          is_customer: 'Inbox/getIsCustomer',
          is_ticket: 'Inbox/getIsTicket',
          intActive: 'Integrations/getIntActive',

          }),

        },
        
        methods: {

          // Map actions
          ...mapActions('Ticket', [
            'fetchCustomerTickets',
            'fetchCustomerTasks',
            'updateTicketID',
          ]),


          // Customer
          ...mapActions('Customer', [
            'setCustomer',
          ]),

          // Get Customer Tickets
          getCustomerTicketData: async function (customer_id) {

            // Fetch Tickets
           this.fetchCustomerTickets(customer_id);

            // If CRM Integration is active Fetch CRM Data
            if(this.intActive){
             this.fetchCustomerTasks(customer_id);
            }

            console.log('başarılı başarılı başarılı');
            console.log(customer_id);
            return true;
          },

        },
        
    }
</script>