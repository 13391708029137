<template>
<div>

  <v-wait for="customer_tickets">
    <loading slot="waiting"/>
    <div class="font-weight-bold mt-2 mb-1">{{ $t('ticket.user_tickets') }}</div>
    <b-table striped hover small sticky-header="250px" :items="customerTickets" :fields="fields" :tbody-tr-class="closedTicket">

      <template #cell(case_id)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <a @click="updateTicket(data.item.id)">{{ data.item.case_id }}</a>
      </template>

      <!-- A custom formatted header cell for field 'ticket' and 'title' -->
      <template #head(ticket)="data">
        <span class="text-info">{{ $t('ticket.ticket') }}</span>
      </template>

      <template #head(title)="data">
        <span class="text-info">{{ $t('ticket.title') }}</span>
      </template>

    </b-table>

    </v-wait>



    <template v-if="intActive">
      <v-wait for="customer_tasks">
      <div class="font-weight-bold mb-1">{{ $t('ticket.user_integrations') }}</div>
      <b-table striped hover small sticky-header="250px" :items="customerTasks" :fields="fields2" :tbody-tr-class="closedTask">

        <template #cell(case_id)="data">
          <!-- `data.value` is the value after formatted by the Formatter -->
          <a @click="updateTicket(data.item.id)">{{ data.item.case_id }}</a>
        </template>

        <!-- A custom formatted header cell for field 'ticket' and 'title' -->
        <template #head(ticket)="data">
          <span class="text-info">{{ $t('ticket.ticket') }}</span>
        </template>

        <template #head(title)="data">
          <span class="text-info">{{ $t('ticket.title') }}</span>
        </template>

      </b-table>
      </v-wait>
    </template>


</div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";

    export default {


        mounted: function () {
            // Component mounted
            //console.log('Customer Type Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Type Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {

              // Ticket 'status' is left out and will not appear in the rendered table
              fields: [

                {
                  key: 'title',
                  label: this.$t('ticket.title'),
                  sortable: true
                },
                  {
                  key: 'case_id',
                  label: this.$t('ticket.ticket'),
                  sortable: true,
                },

              ],

              // Task 'status' is left out and will not appear in the rendered table
              fields2: [

                {
                  key: 'title',
                  label: this.$t('ticket.title'),
                  sortable: true
                },
                {
                  key: 'case_id',
                  label: this.$t('ticket.ticket'),
                  sortable: true,
                },

              ],

            };

        },

        computed: {

          // Map getters
          ...mapGetters({
            customerTickets: 'Ticket/getCustomerTickets',
            customerTasks: 'Ticket/getCustomerTasks',
            intActive: 'Integrations/getIntActive',
            intCustomerTasks: 'Integrations/getCustomerTasks',

          }),


        },
        
        methods: {
          // Ticket Update
          ...mapActions('Ticket', [
            'updateTicketID',
          ]),

          // Customer
          ...mapActions('Customer', [
            'setCustomer',
          ]),

          // Row Color for Closed Ticket
          closedTicket(item, type) {
            if (!item || type !== 'row') return
            if (item.status === '5') return 'table-info'
          },

          // Row Color for Closed Task
          closedTask(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'closed') return 'table-secondary'
          },

          // Get Selected Ticket Data
          updateTicket(ticket_id) {
            this.updateTicketID(ticket_id);
          },


        },
        
    }
</script>