<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('simple.message_delete') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <v-wait for="message_delete">

                        <loading slot="waiting"/>

                        <b-alert variant="danger" show> {{ $t('simple.message_delete_confirm') }}</b-alert>

                    </v-wait>

                </div>

                <div class="modal-footer">

                    <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>
                    <b-button @click="message_delete" variant="primary">{{ $t('simple.delete_verify') }}</b-button>

                </div>

            </div>
        </div>
    </div>
</template>
<script>


    export default {

        mounted: function () {
            console.log('Block Customer Modal mounted.');
        },

        destroyed() {
            console.log('Block Customer Modal destroyed.');
        },

        data: function() {
            return  {}
        },

        methods: {

            message_delete: async function(){

                // Loading start
                this.$wait.start('message_delete');

                // Message Delete
                    let account_id = this.account_id;
                    let message_id = this.message_id;
                    let place = this.place;
                    let delete_api = '/api/v1/inbox/answer/remove';
                    // Delete Message
                    await axios.post(delete_api, {

                      account_id: account_id,

                      message_id: message_id,

                      place: place,

                    }).then((response) => {

                        // Close modal
                        this.$close(true);
                        //console.log(response);

                        // Loading stop
                        this.$wait.end('message_delete');

                        // Show success
                        Vue.toasted.success(Vue.i18n.translate('simple.success_info'));

                    }).catch((error) => {

                        // Show error
                        Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                        // Log to console
                        console.log(error);

                        // Loading stop
                        this.$wait.end('message_delete');

                    });



            },
        },
    }
</script>