<template>

    <!-- Confirmation card -->
    <b-col cols="12" class="mb-3">
        <b-card :class="active_tab_id == draft.customer_id ? 'border border-primary shadow' : ''" no-body>
            <b-card-text class="align-items-center d-flex" slot="header">
                <div>

                    <!-- Confirmation Customer -->
                    <confirmation-customer class="mx-1" v-bind:account="account"
                                           v-bind:draft="draft"></confirmation-customer>


                    <span>{{ draft.customer.full_name }}</span>

                    <b-badge class="badge-pill text-white ml-2"
                          v-bind:class=" status !== 2 ? 'badge-success' : 'badge-danger' ">{{ status !== 2 ? $t('confirmation.accepted') : $t('confirmation.rejected') }}</b-badge>
                        <!-- Confirmation Account -->
                        <confirmation-account class="mx-1" v-bind:account="account"></confirmation-account>



                    </div>
                    <div class="message-header-info" style="display: block !important;">


                        <!-- Confirmation Integration -->
                        <confirmation-integration
                                v-bind:integration="account.integration_id"></confirmation-integration>

                        <!-- Confirmation Type Render -->
                        <confirmation-type v-bind:integration="account.integration_id"
                                           v-bind:type="draft.type"></confirmation-type>

                        <!-- Confirmation Schedule -->
                        <template v-if="draft.schedule">

                            <confirmation-schedule
                                    v-bind:schedule="$moment($moment.utc(draft.schedule.scheduled_at)).local().format('YYYY-MM-DD HH:mm:ss')"></confirmation-schedule>

                        </template>

                        <!-- Confirmation Created -->
                        <confirmation-date v-bind:date="$moment($moment.utc(draft.created_at)).local().format('YYYY-MM-DD HH:mm:ss')"></confirmation-date>
                    </div>
            </b-card-text>

            <b-card-body class="ml-1">
                <strong>{{ $t('page.content') }}:</strong>
                <p>{{ draft.content }}</p>
                <template v-if="confirmation_note">
                    <b-dropdown-divider></b-dropdown-divider>
                    <strong>{{ $t('page.conf_note') }}:</strong>
                    <p v-html="confirmation_note.content"></p>
                </template>

            </b-card-body>

            <div v-if="draft.attachment_id" class="mb-2 ml-3">
                <p class="ml-2"><strong>{{ $t('page.attachments') }}:</strong></p>
                <!-- Message Attachments -->
                <message-attachments v-bind:attachments="draft.attachments"></message-attachments>
            </div>

            <!-- Draft Note -->
            <template v-if="draft.draft_note">
                <b-card-body class="border-top">

                    <b-list-group class="d-inline">
                        <b-list-group-item class="d-inline border-warning">
                            <em>&#151; {{ draft.draft_note.content }}</em>
                        </b-list-group-item>
                    </b-list-group>

                </b-card-body>
            </template>

            <b-button-toolbar key-nav slot="footer">

                <!-- Confirmation Source Link -->
                <confirmation-source class="mx-1" v-bind:account="account" v-bind:draft="draft"></confirmation-source>
                <div class="message-header-info">

                    <!-- Confirmation Requested By -->
                    <confirmation-requested-by v-bind:requested_by="user.ns"></confirmation-requested-by>

                    <!-- Confirmation Reviewed By -->
                    <confirmation-by v-bind:review_by="user.ns" v-bind:type="user.is_super"></confirmation-by>

                    <!-- Confirmation Reviewed At -->
                    <confirmation-review v-bind:review="$moment($moment.utc(review)).local().format('YYYY-MM-DD HH:mm:ss')"></confirmation-review>
                </div>


            </b-button-toolbar>

        </b-card>

    </b-col>

</template>

<script>
    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    export default {

        props: [
            'account',
            'confirmation',
            'confirmation_id',
            'confirmation_note',
            'review',
            'draft',
            'status',
            'ticket',
            'user',
        ],

        mounted: function () {
            // Component mounted
            //console.log('Content render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function () {
            // Component created
        },

        data: function () {
            return {}
        },

        computed: {
            // Map getters
            ...mapGetters({
            // Get Active Tab Id
            active_tab_id: 'Confirmation/getActiveTabId'
            }),
        },

        methods: {

            // Actions
            ...mapActions('Confirmation', [
                'updateConfirmation'
            ]),

            // Update confirmation
            update: function (status) {

                // Update text with emoji
                let data = {
                    id: this.confirmation_id,
                    status: status
                };

                this.updateConfirmation(data);

            },


        },

    }
</script>