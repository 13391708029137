<template>
    <b-row>
        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover title="Time Range">{{ $t('configuration.time_range') }}</b-col>
        <b-col sm="12" md="5" class="d-md-flex">
            <span class="mr-2 pt-1 mb-2">{{ $t('simple.from') }}</span>
            <datetime type="time" :value="time_from" @input="handleTimeFrom" format="HH:mm" input-class="form-control"
                class="theme-message mr-2"></datetime>
            <span class="mr-2 pt-1 mb-2">{{ $t('simple.to') }}</span>
            <datetime type="time" :value="time_to" @input="handleTimeTo" format="HH:mm" input-class="form-control"
                class="theme-message"></datetime>
        </b-col>
    </b-row>
</template>
<script>
export default {
    props: [
        'label',
        'title',
        'time_from',
        'time_to'
    ],

    methods: {
        handleTimeFrom(e) {
            this.$emit('handleTimeFrom', e);
        },

        handleTimeTo(e) {
            this.$emit('handleTimeTo', e);
        },
    }
}
</script>