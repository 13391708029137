<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.edit')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="edit">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_add">

                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="name"
                            name="name"
                            v-validate="'required'"
                            v-model="name"
                        >

                        <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                    </div>

                    <div v-if="integration === 'twitter' || integration === 'instagram' || integration === 'facebook' || integration === 'googleplay'" class="form-group">

                        <label for="username">{{ $t('simple.username') }}</label>

                        <input 
                            class="form-control"
                            type="text"
                            id="username"
                            name="username"
                            v-validate="'required|excluded:'+ this.accounts.filter(o => o.integration_id == integration && o.id !== this.id).map(i => i.username.toLowerCase())"
                            v-model="username"
                            @input="username = $event.target.value.toLowerCase()"
                        >

                        <small v-show="errors.has('username')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('username') }}</small>

                    </div>

                    <div v-else-if="integration === 'whatsapp'" class="form-group">

                        <label for="phone">{{ $t('simple.phone_number') }}</label>

                        <input
                            class="form-control"
                            type="number"
                            id="phone"
                            name="phone"
                            v-validate="'required'"
                            v-model="phone"
                        >

                        <small v-show="errors.has('username')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('username') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="confirmation" v-b-tooltip.hover :title="$t('confirmation.confirmation_process_desc')">{{ $t('confirmation.confirmation_process') }}:</label>

                        <b-form-checkbox v-model="confirmation" switch>
                            {{ check_confirmation() }}
                        </b-form-checkbox>


                    </div>

                </form>

            </v-wait>

          </div>

          <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

            <b-button @click="validate_edit()" variant="primary">{{ $t('simple.save') }}</b-button>
            
          </div>

        </div>
      </div>
    </div>
</template>
<script>

    import {mapGetters} from "vuex";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Account Edit Modal mounted');

            // Get data to edit
            this.edit(this.id);
        },

        destroyed() {
            console.log('Account Edit Modal destroyed.');
        },

        data: function() {

             return  {

                accounts_api: '/api/v1/settings/accounts/',
                name: '',
                integration: '',
                username: '',
                phone: '',
                confirmation: false,

            }

        },

        methods: {

            edit: async function(id) {

                // Loading start
                this.$wait.start('edit');

                // Account edit data
                await axios.get(this.accounts_api + id)
                    .then((response) => {

                        // Update modal data
                        this.name = response.data.name;
                        this.username = response.data.username;
                        this.phone = response.data.phone;
                        this.integration = response.data.integration_id;
                        this.confirmation = response.data.confirmation;

                        // Loading stop
                        this.$wait.end('edit');

                    })
                    .catch((error) => {

                        // Loading stop
                        this.$wait.end('edit');

                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));
                    });

            },

            account_edit: async function(){

                // Loading start
                this.$wait.start('edit');

                // Update account
                await axios.put(this.accounts_api + this.id, {

                    name: this.name,
                    username: this.username,
                    phone: this.phone,
                    confirmation: this.confirmation,

                  })
                  .then((response) => {

                    // Loading stop
                    this.$wait.end('edit');

                    // Show success
                    Vue.toasted.success(this.$t('simple.success_info'));

                    // Close modal
                    this.$close(true);

                  })
                  .catch((error) => {

                    // Loading stop
                    this.$wait.end('edit');

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Close modal
                    //this.$close(true);


                  });

            },

            check_confirmation: function() {
                if(this.confirmation){
                    return this.$t('default.enabled');
                } else {
                    return this.$t('default.disabled');
                }
            },

            validate_edit: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.account_edit();
                    }
                });
            },
        },

        computed: {
            // Map getters
            ...mapGetters({
                accounts: 'Accounts/getAccounts'
            })
        },
    }
</script>


