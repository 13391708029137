<template>

    <div class="row">

        <div class="col pt-2">

            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.macros') }}</h4>

                        <div class="ml-auto align-self-center">

                            <b-button :variant="'primary'" :to="{
                                      name: 'macro_setup'}" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add')}}
                            </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.macros_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-2">

                    <v-wait for="macros">

                        <loading slot="waiting"/>

                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                  <b-form-input v-model="filterInput" :placeholder="$t('simple.type_to_search')" type="search"></b-form-input>
                                    <b-input-group-append>
                                      <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <template v-if="window_width >= 1200">
                            <b-table responsive bordered hover :items="macro_items" :fields="macro_fields" :filter="filter">

                                <!-- Actions -->
                                <template slot="cell(actions)" slot-scope="cell">

                                  <b-dropdown right :text="$t('simple.actions')" size="sm">

                                    <b-dropdown-item :to="{
                                            name: 'macro_setup',
                                            params: { macro: cell.item }
                                        }">{{ $t('simple.edit') }}</b-dropdown-item>

                                    <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>

                                  </b-dropdown>

                                </template>

                                <!-- Created at -->
                                <template slot="cell(created_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                                <!-- Updated at -->
                                <template slot="cell(updated_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                            </b-table>
                        </template>

                        <template v-else>
                            <b-table 
                                stacked="md"
                                small
                                responsive
                                bordered 
                                hover
                                :items="macro_items"
                                :fields="macro_fields_collapsed"
                                :filter="filter"
                            >

                            <!-- Actions -->
                            <template slot="cell(actions)" slot-scope="cell">

                                <b-button size="sm" @click="cell.toggleDetails()">
                                        {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
                                </b-button>

                                <b-dropdown right :text="$t('simple.actions')" size="sm" class="py-1">
                                
                                  <b-dropdown-item :to="{
                                          name: 'macro_setup',
                                          params: { macro: cell.item }
                                      }">{{ $t('simple.edit') }}</b-dropdown-item>

                                  <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>
                                  
                                </b-dropdown>

                            </template>

                            <template #row-details="row">
                                <b-table
                                    small
                                    stacked="md"
                                    class="d-xl-none"
                                    responsive
                                    bordered
                                    hover
                                    :items="[row.item]"
                                    :fields="macro_fields_not_collapsed"
                                >
                                <!-- Created at -->
                                <template slot="cell(created_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                                <!-- Updated at -->
                                <template slot="cell(updated_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>
                                </b-table>
                            </template>

                            </b-table>
                        </template>


                    </v-wait>

                </div>
                    

            </div>

        </div>

    </div>

</template>


<script>

    import MacrosDelete from './Modal/MacrosDelete.vue';

    import { create } from 'vue-modal-dialogs'
    import {mapActions, mapGetters} from "vuex";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Macro component mounted.');
            this.fetchMacrosItems();

            this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })

        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        destroyed() {
            // Component destroyed
            console.log('Macro component destroyed.');
        },

        created: function() {
            // Component created
            this.fetchMacrosCategories();
        },
        

        data: function() {
            return  {

                window_width: window.innerWidth,

                // Macro fields
                macro_fields: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true  },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true  },
                    { key: 'macros_category', label: this.$t('macros.category'), sortable: true  },
                    { key: 'created_at', label: this.$t('simple.created'), sortable: true  },
                    { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],

                macro_fields_collapsed: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true  },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true  },
                    { key: 'macros_category', label: this.$t('macros.category'), sortable: true  },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],

                macro_fields_not_collapsed: [
                    { key: 'created_at', label: this.$t('simple.created') },
                    { key: 'updated_at', label: this.$t('simple.updated') },
                ],

                // Filter
                filter: null,

                // Filter Input
                filterInput: null,
            }
        },
        
        metaInfo: function() {

            return {
                title: this.$t('page.macros'),
            }
            
        },

        methods: {

            remove: async function(id) {
                
                // Show macros delete modal
                const macros_delete = create(MacrosDelete, 'id');

                if (await macros_delete(id) ){
                    // Refresh macros list
                    this.fetchMacrosItems();
                }

            },

            ...mapActions('Macro', [
                'fetchMacrosCategories',
                'fetchMacrosItems',
            ]),

            onResize() {
                this.window_width = window.innerWidth;
            },  

        },

        watch: {
          filterInput(newVal, oldVal) {
            clearTimeout(this.$_timeout)
            this.$_timeout = setTimeout(() => {
              this.filter = newVal
            }, 150) // set this value to your preferred debounce timeout
          }
        },

        computed: {

            // Map getters
            ...mapGetters({

                macro_items: 'Macro/getMacrosItems'
            })

        },
        
    }
</script>