<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('simple.edit') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body modal-user-edit">

                    <v-wait for="edit">

                        <loading slot="waiting" />

                        <form @submit.prevent="validate_edit">


                            <div class="form-group">

                                <label for="name">{{ $t('simple.name') }}</label>

                                <input class="form-control" type="text" id="name" name="name"
                                    v-validate="'required|max:255'" v-model="name">

                                <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon
                                        :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                            </div>

                            <div class="form-group">

                                <label for="surname">{{ $t('simple.surname') }}</label>

                                <input class="form-control" type="text" id="surname" name="surname"
                                    v-validate="'required|max:255'" v-model="surname">

                                <small v-show="errors.has('surname')" class="form-text text-danger"><font-awesome-icon
                                        :icon="'times-circle'" /> {{ errors.first('surname') }}</small>

                            </div>

                            <div class="form-group">

                                <label for="email">{{ $t('simple.email') }}</label>

                                <input class="form-control" type="text" id="email" name="email"
                                    v-validate="'required|email|max:255'" v-model="email">

                                <small v-show="errors.has('email')" class="form-text text-danger"><font-awesome-icon
                                        :icon="'times-circle'" /> {{ errors.first('email') }}</small>

                            </div>

                            <div class="form-group">

                                <label for="phone">{{ $t('simple.phone') }}</label>

                                <input class="form-control" type="text" id="phone" name="phone"
                                    v-validate="'required|numeric|length:12'" v-model="phone">

                                <small v-show="errors.has('phone')" class="form-text text-danger"><font-awesome-icon
                                        :icon="'times-circle'" /> {{ errors.first('phone') }}</small>

                            </div>

                            <div class="form-group">

                                <label for="preferred_lang">{{ $t('user.preffered_message_languages') }}</label>
                                <treeselect v-model="preferred_languages" :multiple="true"
                                    :placeholder="$t('default.select')" :options="preferred_language_options" 
                                    >
                                </treeselect>

                                <small v-show="errors.has('preferred_lang')" class="form-text text-danger"><font-awesome-icon
                                        :icon="'times-circle'" /> {{ errors.first('preferred_lang') }}</small>

                            </div>

                            <div class="form-group">

                                <label for="language">{{ $t('simple.language') }}</label>

                                <b-form-select id="language" name="language" v-model="language"
                                    :options="this.language_list">
                                </b-form-select>

                                <small v-show="errors.has('language')" class="form-text text-danger"><font-awesome-icon
                                        :icon="'times-circle'" /> {{ errors.first('language') }}</small>

                            </div>


                            <div class="row">
                                <div v-if="!user_profile" class="form-group col-6">

                                    <label for="description">{{ $t('simple.super') }}</label>

                                    <b-form-checkbox v-model="is_super" switch
                                        :disabled="!user.is_super || (id === user.id && user.is_super)">
                                        {{ check_super() }}
                                    </b-form-checkbox>

                                    <b-alert variant="danger" :show="errors.has('is_super')">PLEASE BE WARNED THIS WILL
                                        BE SUPER ADMIN!!!</b-alert>

                                </div>

                                <div v-if="!user_profile" class="form-group col-6">

                                    <label for="status">{{ $t('simple.status') }}</label>

                                    <b-form-checkbox :disabled="user.id === id" v-model="status" switch>
                                        {{ check_status() }}
                                    </b-form-checkbox>

                                    <small v-show="errors.has('status')"
                                        class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{
                                        errors.first('status') }}</small>

                                </div>

                                <div class="form-group col-6">

                                    <label for="email_notify">{{ $t('simple.email_notification') }}</label>

                                    <b-form-checkbox v-model="email_notify" switch>
                                        {{ check_email_notify() }}
                                    </b-form-checkbox>

                                    <small v-show="errors.has('email_notify')"
                                        class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{
                                        errors.first('email_notify') }}</small>

                                </div>

                                <div class="form-group col-6">

                                    <label for="whatsapp_notify">{{ $t('simple.whatsapp_notification') }}</label>

                                    <b-form-checkbox v-model="whatsapp_notify" switch>
                                        {{ check_whatsapp_notify() }}
                                    </b-form-checkbox>

                                    <small v-show="errors.has('whatsapp_notify')"
                                        class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{
                                        errors.first('whatsapp_notify') }}</small>

                                </div>

                                <div class="form-group col-6">

                                    <label for="report_notify">{{ $t('simple.report_notification') }}</label>

                                    <b-form-checkbox v-model="report_notify" switch>
                                        {{ check_report_notify() }}
                                    </b-form-checkbox>

                                    <small v-show="errors.has('report_notify')"
                                        class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{
                                        errors.first('report_notify') }}</small>

                                </div>

                                <div class="form-group col-6" v-if="getIntActive">

                                    <label for="crm_show_name">{{ $t('simple.crm_show_name') }}</label>

                                    <b-form-checkbox v-model="crm_show_name" switch>
                                        {{ check_crm_show_name() }}
                                    </b-form-checkbox>

                                    <small v-show="errors.has('crm_show_name')"
                                        class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{
                                        errors.first('crm_show_name') }}</small>

                                </div>
                            </div>

                        </form>

                    </v-wait>

                </div>

                <div class="modal-footer">

                    <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

                    <b-button @click="validate_edit()" variant="primary">{{ $t('simple.save') }}</b-button>

                </div>


            </div>
        </div>
    </div>
</template>
<script>


import { mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";


export default {

    components: { Treeselect },

    mounted: function () {
        // Component mounted
        console.log('User Edit Modal mounted');
    },

    destroyed() {
        // Component destroyed
        console.log('User Edit Modal destroyed.');
    },

    created: async function () {

        // Get data to edit
        await this.edit(this.id);


    },

    data: function () {
        return {

            users_api: '/api/v1/settings/users/',

            name: '',
            surname: '',
            email: '',
            phone: '',

            is_super: false,

            user_profile: this.is_user,

            status: false,

            email_notify: false,
            whatsapp_notify: false,
            report_notify: false,

            crm_show_name: true,

            permission: '',

            language: '',

            preferred_languages: null,

        }
    },

    methods: {

        check_super: function () {
            if (this.is_super) {
                return this.$t('simple.yes');
            } else {
                return this.$t('simple.no');
            }
        },

        check_status: function () {
            if (this.status) {
                return this.$t('default.enabled');
            } else {
                return this.$t('default.disabled');
            }
        },

        check_email_notify: function () {
            if (this.email_notify) {
                return this.$t('default.enabled');
            } else {
                return this.$t('default.disabled');
            }
        },

        check_whatsapp_notify: function () {
            if (this.whatsapp_notify) {
                return this.$t('default.enabled');
            } else {
                return this.$t('default.disabled');
            }
        },

        check_report_notify: function () {
            if (this.report_notify) {
                return this.$t('default.enabled');
            } else {
                return this.$t('default.disabled');
            }
        },

        check_crm_show_name: function () {
            if (this.crm_show_name) {
                return this.$t('default.enabled');
            } else {
                return this.$t('default.disabled');
            }
        },

        edit: async function (id) {

            // Loading start
            this.$wait.start('edit');

            // Get edit
            await axios.get(this.users_api + id)
                .then((response) => {

                    // Update modal data
                    this.name = response.data.name;
                    this.surname = response.data.surname;
                    this.email = response.data.email;
                    this.phone = response.data.phone;
                    this.is_super = response.data.is_super;
                    this.status = response.data.status === 1;
                    this.email_notify = response.data.email_notify === 1;
                    this.whatsapp_notify = response.data.whatsapp_notify === 1;
                    this.report_notify = response.data.report_notify === 1;
                    this.crm_show_name = response.data.crm_show_name === 1;
                    this.language = response.data.language;
                    this.preferred_languages = JSON.parse(response.data.preferred_languages)

                    // console.log('User Status' + this.status);

                    // Loading end
                    this.$wait.end('edit');

                })
                .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    console.log(error);

                    // Loading end
                    this.$wait.end('edit');

                }
                );

        },

        user_edit: async function () {

            // Loading start
            this.$wait.start('edit');

            // Post request
            await axios.put(this.users_api + this.id, {
                name: this.name,
                surname: this.surname,
                email: this.email,
                phone: this.phone,
                super: this.is_super,
                status: this.status,
                email_notify: this.email_notify,
                whatsapp_notify: this.whatsapp_notify,
                report_notify: this.report_notify,
                crm_show_name: this.crm_show_name,
                language: this.language,
                preferred_languages: this.preferred_languages
            })
                .then((response) => {

                    if (this.id === this.user.id) {
                        this.$i18n.set(this.language);
                    }

                    if (response.data.error) {
                        this.permission = Vue.i18n.translate('permission.update_users');
                        // Show error
                        Vue.toasted.error(Vue.i18n.translate('permission.update_users'), { duration: 5000 });
                        // Loading end
                        this.$wait.end('edit');
                        // Close modal
                        this.$close(true);
                    } else {

                        // Show success
                        Vue.toasted.success(this.$t('simple.success_info'));

                        // Loading end
                        this.$wait.end('edit');

                        // Close modal
                        this.$close(true);
                    }

                })
                .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    //console.log(error);

                    // Close modal
                    //this.$close(true);

                    // Loading end
                    this.$wait.end('edit');

                });

        },

        validate_edit: async function () {
            // validate all fields
            await this.$validator.validateAll().then((result) => {
                if (result) {
                    // Send it to account edit function
                    this.user_edit();
                }
            });
        },

    },

    computed: {


        // Map getters
        ...mapGetters({

            // User
            user: 'User/getUser',
            // Language List
            language_list: 'User/getLanguageList',
            // Integration Active
            getIntActive: 'Integrations/getIntActive',

            preferredLanguageOptions: 'Messages/getLanguages',

        }),

        preferred_language_options() {
            const languages = Object.entries(this.preferredLanguageOptions).map((ele) => ( { id: ele[0].toUpperCase(), label: ele[1] } ))
            return languages
        }
    }
}
</script>
