<template>

    <div class="player-all-in-one d-flex justify-content-around align-items-center">

        <!-- TODO: disable account is not ready -->
        <!-- <div>
            <b-button pill v-if="showDisabledAccounts" variant="light" v-b-tooltip.hover.bottom title="Hide Disabled Acccounts"
                class="text-secondary message-actions-shadow" active-class="font-weight-bold text-dark" size="sm"
                @click="$emit('setShowDisabledAccounts')">
                <font-awesome-icon :icon="'eye-slash'" class="logo-color" />
            </b-button>
            <b-button pill v-else variant="light" v-b-tooltip.hover.bottom title="Show Disabled Acccounts"
                class="text-secondary message-actions-shadow" active-class="font-weight-bold text-dark" size="sm"
                @click="$emit('setShowDisabledAccounts')">
                <font-awesome-icon :icon="'eye'" class="logo-color" />
            </b-button>
        </div> -->

        <b-button pill v-b-toggle="'account_0'" variant="light" class="text-secondary message-actions-shadow"
            active-class="font-weight-bold text-dark" size="sm" :key="0" :active="is_account === '0'" :to="{
                name: 'player',
                params: { type: 'player' }
            }">
            <font-awesome-icon :icon="getPlayer ? 'cog' : 'play'" :class="getPlayer ? 'fas fa-spin' : ''"
                class="logo-color" />
            {{ $t('player.player') }}
        </b-button>
        <!-- New Message Icon
                <span v-if="account.unread_count" class="badge badge-primary py-1">{{ $t('page.new') }}</span>
                <span class="nav-count">{{ account.unread_count }}</span>-->
        <!-- Confirmation Icon -->
        <!-- Questions -->
    </div>


</template>

<script>

import { mapGetters, mapMutations } from "vuex";

export default {

    props: ['is_account', 'showDisabledAccounts'],

    data: function () {
        return {}
    },

    methods: {
        ...mapMutations('Player', [
            'setPlayer'
        ]),

    },

    computed: {

        // Map getters
        ...mapGetters({
            // Player
            getPlayer: 'Player/getPlayer',
        }),
    },
}
</script>