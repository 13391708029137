<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.add')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="add">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_add">


                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="name"
                            name="name"
                            v-validate="'required|max:15'"
                            v-model="name"
                        >

                        <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="description">{{ $t('simple.display_name') }}</label>

                        <input 
                            class="form-control"
                            type="text"
                            id="display_name"
                            name="display_name"
                            v-validate="'required|max:50'"
                            v-model="display_name"
                        >

                        <small v-show="errors.has('display_name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('display_name') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="description">{{ $t('simple.desc') }}</label>

                        <input 
                            class="form-control"
                            type="text"
                            id="description"
                            name="description"
                            v-validate="'required|max:50'"
                            v-model="description"
                        >

                        <small v-show="errors.has('description')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('description') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="parent_id">{{ $t('simple.permission') }}</label>

                        <treeselect

                            v-model="permission"
                            :multiple="true"
                            :normalizer="normalizer"
                            placeholder="Select permissions"
                            :loadOptions="getPermissions"
                            name="permissions"
                            :options="permissions"
                        >

                        <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                            {{ node.raw.display_name }} <font-awesome-icon :icon="'question-circle'" v-b-tooltip.hover :title="node.raw.description" :size="'xs'"  class="ml-1"/>
                        </label>

                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.display_name }} <font-awesome-icon :icon="'question-circle'" v-b-tooltip.hover :title="node.raw.description" :size="'xs'"  class="ml-1"/></div>

                        </treeselect>
                        
                    </div>

                </form>

            </v-wait>

        </div>

        <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

            <b-button @click="validate_add()" variant="primary">{{ $t('simple.save') }}</b-button>
            
        </div>


        </div>
      </div>
    </div>

</template>
<script>
    import Treeselect from '@riophae/vue-treeselect'

    export default {

        components: { Treeselect },

        mounted: function () {
            // Component mounted
            console.log('Category Add Modal mounted');
        },

        destroyed() {
            // Component destroyed
            console.log('Category Add Modal destroyed.');
        },

        created: function() {
            // Component created
           //this.getCategories();
        },
        
        data: function() {
            return  {

                roles_api: '/api/v1/settings/roles/',
                permissions_api: '/api/v1/settings/permissions/',

                name: '',
                display_name: '',
                description: '',
                permission: null,
                permissions: null,
                normalizer(node) {
                    return {
                        label: node.name,
                    }
                },
            }
        },



        methods: {

            // Get permissions
            getPermissions: async function({ action, callback }){

                // Loading start
                this.$wait.start('add');

                // Get categories
                await axios.get(this.permissions_api)
                  .then((response) => {

                    // Set categories
                    this.permissions = response.data;

                    // Callback
                    callback();

                    console.log(response);
                    // Loading end
                    this.$wait.end('add');

                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    console.log(error);

                    // Loading end
                    this.$wait.end('add');

                  });

            },


            role_add: async function(){

                // Loading start
                this.$wait.start('add');

                // Post request
                await axios.post(this.roles_api, {
                    name: this.name,
                    display_name: this.display_name,
                    description: this.description,
                    permissions: this.permission,
                  })
                  .then((response) => {

                      if(response.data.error){
                          // Show error
                          Vue.toasted.error(Vue.i18n.translate('permission.create_roles'),{duration:5000});
                          // Loading stop
                          this.$wait.end('add');
                          // Close modal
                          this.$close(true);
                      }else {

                          // Show success
                          Vue.toasted.success(this.$t('simple.success_info'));

                          // Close modal
                          this.$close(true);

                          // Loading end
                          this.$wait.end('add');
                      }

                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    console.log(error);

                    // Close modal
                    //this.$close(true);

                    // Loading end
                    this.$wait.end('add');

                  });

            },

            validate_add: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.role_add();
                    }
                });
            },
        },
    }
</script>