<template>
    <b-alert variant="danger" v-if="permission_error" show>{{ permission_error }}</b-alert>
    <b-container fluid class="bg-white rounded py-2 shadow-widget border" v-else-if="requests.length !== 0 && !permission_error">
        <template v-if="permission_error">
            <b-alert variant="danger" show>{{ permission_error }}</b-alert>
        </template>
        <template v-else>
            <b-row>
                <b-col>
                    <!-- Page header -->
                    <div class="message-actions confirmation-requests-header sticky-top plus-page-header z-index-fix">

                        <div class="container-fluid py-2">

                            <h4 class="confirmation-header-text">{{ $t('page.confirmations') }}</h4>
                            <span class="text-muted font-weight-light">{{ $t('page.confirmations_desc') }}</span>
                        </div>
                        <div class="mx-3">

                            <!-- Pagination-->
                            <b-pagination v-if="total" :hide-goto-end-buttons="true" size="sm" :total-rows="total"
                                :value="current" @input="updatePage" :per-page="perpage"></b-pagination>

                            <!-- Refresh -->
                            <b-button :variant="'primary'" @click="refreshRequests" class="btn-sm d-flex justify-content-center align-items-center">
                                <font-awesome-icon :icon="'sync'"  class="mr-1"/>
                                {{ $t('default.refresh') }}
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>

                <b-col class="plus-col-main-messages pt-1 d-md-block" :class="{ 'd-none' : customer_id}">

                    <!-- List messages -->
                    <div class="message-list" ref="requests"
                        v-chat-scroll="{ always: false, smooth: false, scrollonremoved: true, smoothonremoved: false }">

                        <div>
                            <v-wait for="confirmations" class="row">
                                <loading-messages slot="waiting" />

                                <template v-for="data in requests">

                                    <confirmation-draft v-bind:account="data.account" v-bind:confirmation="data.confirmation"
                                        v-bind:confirmation_id="data.confirmation_id" v-bind:draft="data.confirmation.draft"
                                        v-bind:user="data.user"></confirmation-draft>
                                </template>

                            </v-wait>
                        </div>

                    </div>

                </b-col>

                <b-col class="col-12 col-md-3 relative plus-col-main-panel border panel-right position-initial-converter mt-1 z-index-fix" v-if="customer_id && is_customer">
                    <b-btn size="sm" class="list-item-remove-button position-absolute" variant="danger" @click="resetCustomer">
                        <font-awesome-icon :icon="'times'" />
                    </b-btn>
                    <!-- Show customer info end edit -->
                    <customer-render :customer="customer"></customer-render>
                </b-col>
            </b-row>
        </template>
    </b-container>
</template>
<script>

// Vuex
import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue';

//scroll
import VueChatScroll from 'vue-chat-scroll';

Vue.use(VueChatScroll);


export default {

    mounted: function () {
        // Component mounted
        console.log('Confirmations component mounted.');
        // Listen
        //this.start_echo();

        this.resetCustomer()

    },

    beforeDestroy() {
        this.resetPermission()
    },

    destroyed() {
        // Component destroyed
        console.log('Confirmations component destroyed.');
        // Listen
        //this.leave_echo(this.account_id);
    },

    created: async function () {
        // Component created
        await this.fetchConfirmations();
    },


    data: function () {
        return {
        }
    },

    metaInfo: function () {

        return {
            title: this.$t('page.confirmations'),
        }

    },

    methods: {

        // Actions
        ...mapActions('Confirmation', [
            'refreshRequests',
            'fetchConfirmations',
            'updatePage',
            'resetPermission'
        ]),
        ...mapActions('Customer', [
            'resetCustomer',
        ]),


    },

    computed: {

        // Map getters
        ...mapGetters({

            requests: 'Confirmation/getRequests',
            // Get account
            account_id: 'Accounts/getAccount',
            confirmations: 'Confirmation/getConfirmations',
            total: 'Confirmation/getTotal',
            current: 'Confirmation/getCurrent',
            perpage: 'Confirmation/getPerPage',

            // Customer - Inbox Control
            is_customer: 'Inbox/getIsCustomer',
            is_ticket: 'Inbox/getIsTicket',

            customer: 'Customer/getCustomer',
            customer_id: 'Customer/getCustomerID',

            permission_error: 'Confirmation/getPermission',



        }),
    },
}
</script>