<template>
    <div>
        <template v-if="window_width >= 992">

            <!-- Configurations -->
            <b-dropdown-item :to="settings_configurations_route" active-class="active"><font-awesome-icon :icon="'cogs'" /> {{ $t('page.configurations') }}</b-dropdown-item>

            <!-- Accounts -->
            <b-dropdown-item :to="settings_accounts_route" active-class="active"><font-awesome-icon :icon="'users'" /> {{ $t('page.accounts') }}</b-dropdown-item>

            <!-- Announcements
            <b-dropdown-item to="/settings/announcements"><font-awesome-icon :icon="'bullhorn'" /> {{ $t('page.announcements') }}</b-dropdown-item> -->
            
            <b-dropdown-divider></b-dropdown-divider>
            
            <!-- Categories -->
            <b-dropdown-item :to="settings_categories_route" active-class="active"><font-awesome-icon :icon="'book'" /> {{ $t('page.categories') }}</b-dropdown-item>
            
            <!-- Category Types
            <b-dropdown-item to="/settings/category/types"><font-awesome-icon :icon="'briefcase'" /> {{ $t('page.categorytypes') }}</b-dropdown-item> -->
            
            <!-- Tags -->
            <b-dropdown-item :to="settings_tags_route" active-class="active"><font-awesome-icon :icon="'tags'" /> {{ $t('page.tags') }}</b-dropdown-item>
            
            <b-dropdown-divider></b-dropdown-divider>
            
            <!-- Customer Types -->
            <b-dropdown-item :to="settings_types_route" active-class="active"><font-awesome-icon :icon="'user-circle'" /> {{ $t('page.customertypes') }}</b-dropdown-item>
            
            <!-- Customer Genders -->
            <b-dropdown-item :to="settings_genders_route" active-class="active"><font-awesome-icon :icon="'user-friends'" /> {{ $t('page.customer_genders') }}</b-dropdown-item>
            
            <b-dropdown-divider></b-dropdown-divider>
            
            <!-- Close Tickets -->
            <b-dropdown-item :to="settings_close_tickets_route" active-class="active"><font-awesome-icon :icon="'ticket-alt'" /> {{ $t('page.close_tickets') }}</b-dropdown-item>
            
            <b-dropdown-divider></b-dropdown-divider>
            
            <!-- Macro -->
            <b-dropdown-item :to="settings_macros_route" active-class="active"><font-awesome-icon :icon="'robot'" /> {{ $t('page.macros') }}</b-dropdown-item>
            
            <!-- Macro Category -->
            <b-dropdown-item :to="settings_macros_categories_route" active-class="active"><font-awesome-icon :icon="'bookmark'" /> {{ $t('page.macros_categories') }}</b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>

            <!-- TODO: Uncomment after API integration is complete -->
            <!-- Whatsapp START -->

            <!-- <b-dropdown-item :to="settings_whatsapp_session_messages_route"
            v-if="showWhatsappSettings"
            active-class="active">
            <font-awesome-icon :icon="['fab', 'whatsapp-square']" size="lg"/> {{ $t('macros.set_session_messages') }}</b-dropdown-item> -->

            <b-dropdown-item :to="settings_whatsapp_template_messages_route" :disabled="disabled_areas.whatsapp_templates && whatsapp_accounts_disabled"
            v-if="showWhatsappSettings"
            active-class="active">
                <font-awesome-icon :icon="['fab', 'whatsapp-square']" size="lg"/> {{ $t('macros.set_template_messages') }}
            </b-dropdown-item>

            <b-dropdown-item :to="settings_whatsapp_campaigns_route" :disabled="disabled_areas.whatsapp_campaigns && whatsapp_accounts_disabled" v-if="showWhatsappSettings" active-class="active">
                <font-awesome-icon :icon="['fas', 'bullhorn']" /> {{ $t('simple.whatsapp_campaigns') }}
            </b-dropdown-item>
            
            <b-dropdown-divider></b-dropdown-divider>

            <!-- Whatsapp END -->

            
            <!-- Gallery -->
            <b-dropdown-item :to="settings_gallery_route" active-class="active"><font-awesome-icon :icon="'images'" /> {{ $t('page.gallery') }}</b-dropdown-item>
            
            <b-dropdown-divider></b-dropdown-divider>
            
            <!-- Users -->
            <b-dropdown-item :to="settings_users_route" active-class="active"><font-awesome-icon :icon="'users-cog'" /> {{ $t('page.users') }}</b-dropdown-item>
            
            <!-- Teams -->
            <b-dropdown-item :to="settings_teams_route" active-class="active"><font-awesome-icon :icon="'user-shield'" /> {{ $t('page.teams') }}</b-dropdown-item>
            
            <!-- Roles -->
            <b-dropdown-item :to="settings_roles_route" active-class="active"><font-awesome-icon :icon="'user-lock'" /> {{ $t('page.roles') }}</b-dropdown-item>
            
            
            <b-dropdown-divider></b-dropdown-divider>
            
            
            <!-- CRM Integration's Configurations -->
            <b-dropdown-item :to="settings_crm_configurations_route" active-class="active"><font-awesome-icon :icon="'link'" /> {{ $t('configuration.crm_integration') }}</b-dropdown-item>

        </template>

        <template v-else>

            <!-- Configurations -->
            <b-link class="plus-header-accordion-links" :to="settings_configurations_route" active-class="active"><font-awesome-icon :icon="'cogs'" /> {{ $t('page.configurations') }}</b-link>

            <!-- Accounts -->
            <b-link class="plus-header-accordion-links" :to="settings_accounts_route" active-class="active"><font-awesome-icon :icon="'users'" /> {{ $t('page.accounts') }}</b-link>

            <hr>

            <!-- Categories -->
            <b-link class="plus-header-accordion-links" :to="settings_categories_route" active-class="active"><font-awesome-icon :icon="'book'" /> {{ $t('page.categories') }}</b-link>

            <!-- Category Types
            <b-dropdown-item to="/settings/category/types"><font-awesome-icon :icon="'briefcase'" /> {{ $t('page.categorytypes') }}</b-dropdown-item> -->
            
            <!-- Tags -->
            <b-link class="plus-header-accordion-links" :to="settings_tags_route" active-class="active"><font-awesome-icon :icon="'tags'" /> {{ $t('page.tags') }}</b-link>
            
            <hr>
            
            <!-- Customer Types -->
            <b-link class="plus-header-accordion-links" :to="settings_types_route" active-class="active"><font-awesome-icon :icon="'user-circle'" /> {{ $t('page.customertypes') }}</b-link>
            
            <!-- Customer Genders -->
            <b-link class="plus-header-accordion-links" :to="settings_genders_route" active-class="active"><font-awesome-icon :icon="'user-friends'" /> {{ $t('page.customer_genders') }}</b-link>
            
            <hr>

            <!-- Close Tickets -->
            <b-link class="plus-header-accordion-links" :to="settings_close_tickets_route" active-class="active"><font-awesome-icon :icon="'ticket-alt'" /> {{ $t('page.close_tickets') }}</b-link>
            
            <hr>
            
            <!-- Macro -->
            <b-link class="plus-header-accordion-links" :to="settings_macros_route" active-class="active"><font-awesome-icon :icon="'robot'" /> {{ $t('page.macros') }}</b-link>
            
            <!-- Macro Category -->
            <b-link class="plus-header-accordion-links" :to="settings_macros_categories_route" active-class="active"><font-awesome-icon :icon="'bookmark'" /> {{ $t('page.macros_categories') }}</b-link>

            <hr>

            <!-- TODO: Uncomment after API integration is complete -->
            <!-- Whatsapp START -->
            
            <!-- <b-link 
                class="plus-header-accordion-links" 
                :to="settings_whatsapp_session_messages_route"
                v-if="showWhatsappSettings"
                active-class="active">
                <font-awesome-icon :icon="['fab', 'whatsapp-square']" size="lg"/> {{ $t('macros.set_session_messages') }}
            </b-link> -->

            <b-link 
                class="plus-header-accordion-links" 
                :to="settings_whatsapp_template_messages_route"
                :disabled="disabled_areas.whatsapp_templates && whatsapp_accounts_disabled"
                v-if="showWhatsappSettings"
                active-class="active">
                <font-awesome-icon :icon="['fab', 'whatsapp-square']" size="lg"/> {{ $t('macros.set_template_messages') }} 
            </b-link>

            <b-link 
                class="plus-header-accordion-links" 
                :disabled="disabled_areas.whatsapp_campaigns && whatsapp_accounts_disabled"
                :to="settings_whatsapp_campaigns_route"
                v-if="showWhatsappSettings"
                active-class="active">
                <font-awesome-icon :icon="['fas', 'bullhorn']" /> {{ $t('simple.whatsapp_campaigns') }} 
            </b-link>

            <hr>
            <!-- Whatsapp END -->

            
            <!-- Gallery -->
            <b-link class="plus-header-accordion-links" :to="settings_gallery_route" active-class="active"><font-awesome-icon :icon="'images'" /> {{ $t('page.gallery') }}</b-link>
            
            <hr>
            
            <!-- Users -->
            <b-link class="plus-header-accordion-links" :to="settings_users_route" active-class="active"><font-awesome-icon :icon="'users-cog'" /> {{ $t('page.users') }}</b-link>
            
            <!-- Teams -->
            <b-link class="plus-header-accordion-links" :to="settings_teams_route" active-class="active"><font-awesome-icon :icon="'user-shield'" /> {{ $t('page.teams') }}</b-link>
            
            <!-- Roles -->
            <b-link class="plus-header-accordion-links" :to="settings_roles_route" active-class="active"><font-awesome-icon :icon="'user-lock'" /> {{ $t('page.roles') }}</b-link>
            
            <hr>            
            
            <!-- CRM Integration's Configurations -->
            <b-link class="plus-header-accordion-links" :to="settings_crm_configurations_route" active-class="active"><font-awesome-icon :icon="'link'" /> {{ $t('configuration.crm_integration') }}</b-link>
            
        </template>
    </div>

</template>
<script>

import {mapActions, mapGetters, mapMutations} from "vuex";


export default {
    mounted() {
        this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
        })

        this.fetchAccounts().then( response => {
            this.generateIntegrationOptions();
        });
    },

    beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
    },

    data: function () {
        return {
            window_width: window.innerWidth,
            settings_configurations_route: '/settings/configurations',
            settings_accounts_route: '/settings/accounts',
            settings_categories_route: '/settings/categories',
            settings_tags_route: '/settings/tags',
            settings_types_route: '/settings/customer/types',
            settings_genders_route: '/settings/customer/genders',
            settings_close_tickets_route: '/settings/close-tickets',
            settings_macros_route: '/settings/macros',
            settings_macros_categories_route: '/settings/macros-categories',
            settings_whatsapp_session_messages_route: '/settings/session-messages',
            settings_whatsapp_template_messages_route: '/settings/template-messages',
            settings_whatsapp_campaigns_route: '/settings/whatsapp-campaigns',
            settings_gallery_route: '/settings/gallery',
            settings_users_route: '/settings/users',
            settings_teams_route: '/settings/teams',
            settings_roles_route: '/settings/roles',
            settings_crm_configurations_route: '/settings/crm-configurations',
            showWhatsappSettings: '',
        }
    },

    methods: {
        ...mapActions('Accounts', [
            'fetchAccounts',
        ]),

        generateIntegrationOptions() {

            let integrationList = this.accounts.flatMap(account => account.integration.id);

            this.showWhatsappSettings = integrationList.includes('whatsapp');

        },

        onResize() {
            this.window_width = window.innerWidth;
        },  
    },

    computed: {
        ...mapGetters({
            accounts: 'Accounts/getAccounts',
            disabled_areas: 'Accounts/getDisabledAreas',

        }),

        whatsapp_accounts_disabled() {
            const whatsapp_accounts = this.accounts.filter( account => account.integration_id === 'whatsapp')

            return whatsapp_accounts.every( whatsapp_account => whatsapp_account.is_active === false)
        }
    }


}

</script>