<template>

    <div class="container-fluid">



        <div class="row flex-column">

            <!-- About -->
            <div class="panel-title panel-title pb-2 text-uppercase text-muted">{{ $t('customer.about') }}</div>


            <!-- Name -->
            <div class="pb-2">

                <font-awesome-icon :icon="'user'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.name')"/>
                <span class="font-weight-light ml-2">{{whatsapp.name }}</span>

            </div>

            <!-- Created -->
            <div class="pb-2">

                <font-awesome-icon :icon="'user-clock'" class="plus__icon" v-b-tooltip.hover :title="$t('simple.created')"/> <span class="font-weight-light ml-2" v-b-tooltip.hover :title="whatsapp.created_at">{{  moment(whatsapp.created_at).fromNow() }}</span>

            </div>

            <!-- Statuses -->
            <div class="pb-2">

                <font-awesome-icon :icon="'phone'" class="plus__icon" v-b-tooltip.hover :title="$t('simple.phone')"/> <span class="font-weight-light ml-2">{{ whatsapp.whatsapp_id }}</span>

            </div>

            <!-- Verified -->
            <div class="pb-2" v-if="whatsapp.verified">

                <font-awesome-icon :icon="'user-check'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.verified')"/> <span class="font-weight-light ml-2">{{ $t(whatsapp_verified) }}</span>

            </div>

            <!-- Protected -->
            <div class="pb-2" v-if="whatsapp.protected">

                <font-awesome-icon :icon="'user-lock'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.protected')"/> <span class="font-weight-light ml-2">{{ $t(whatsapp_protected) }}</span>

            </div>

            <!-- Url -->
            <div class="pb-2" v-if="whatsapp.url">

                <font-awesome-icon :icon="'link'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.url')"/> <b-link :href="whatsapp.url" class="font-weight-light ml-2" target="_blank" rel="noopener noreferrer">{{ whatsapp.url }}</b-link>

            </div>


        </div>

    </div>


</template>

<script>

    export default {

        props: ['whatsapp'],

        mounted: function () {
            // Component mounted
            //console.log('Customer Type Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Type Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {

                moment: this.$moment,

            };

        },

        computed: {


            // Whatsapp verified
            whatsapp_verified: function () {

                if(this.whatsapp.verified){

                    return 'simple.yes';

                } else {

                    return 'simple.no';

                }
            },


            // Whatsapp protected
            whatsapp_protected: function () {

                if(this.whatsapp.protected){

                    return 'simple.yes';

                } else {

                    return 'simple.no';

                }
            },


        },

        methods: {},

    }
</script>