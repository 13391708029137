var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col pt-2"},[_c('div',{staticClass:"bg-white rounded py-2 shadow-widget border"},[_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"d-flex"},[_c('h4',[_vm._v(_vm._s(_vm.$t('page.macros')))]),_vm._v(" "),_c('div',{staticClass:"ml-auto align-self-center"},[_c('b-button',{staticClass:"ml-auto",attrs:{"variant":'primary',"to":{
                                  name: 'macro_setup'}}},[_c('font-awesome-icon',{attrs:{"icon":'plus'}}),_vm._v("\n                            "+_vm._s(_vm.$t('default.add'))+"\n                        ")],1)],1)]),_vm._v(" "),_c('span',{staticClass:"text-muted font-weight-light"},[_vm._v(_vm._s(_vm.$t('page.macros_desc')))])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"px-2"},[_c('v-wait',{attrs:{"for":"macros"}},[_c('loading',{attrs:{"slot":"waiting"},slot:"waiting"}),_vm._v(" "),_c('b-row',[_c('b-col',{staticClass:"my-2 ml-auto",attrs:{"md":"4"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('simple.type_to_search'),"type":"search"},model:{value:(_vm.filterInput),callback:function ($$v) {_vm.filterInput=$$v},expression:"filterInput"}}),_vm._v(" "),_c('b-input-group-append',[_c('b-btn',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(_vm._s(_vm.$t('simple.clear')))])],1)],1)],1)],1),_vm._v(" "),(_vm.window_width >= 1200)?[_c('b-table',{attrs:{"responsive":"","bordered":"","hover":"","items":_vm.macro_items,"fields":_vm.macro_fields,"filter":_vm.filter},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(cell){return [_c('b-dropdown',{attrs:{"right":"","text":_vm.$t('simple.actions'),"size":"sm"}},[_c('b-dropdown-item',{attrs:{"to":{
                                        name: 'macro_setup',
                                        params: { macro: cell.item }
                                    }}},[_vm._v(_vm._s(_vm.$t('simple.edit')))]),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.remove(cell.item.id)}}},[_vm._v(_vm._s(_vm.$t('simple.delete')))])],1)]}},{key:"cell(created_at)",fn:function(cell){return [_vm._v("\n\n                                 "+_vm._s(_vm.$moment(_vm.$moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss'))+"\n\n                            ")]}},{key:"cell(updated_at)",fn:function(cell){return [_vm._v("\n\n                                 "+_vm._s(_vm.$moment(_vm.$moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss'))+"\n\n                            ")]}}],null,false,1075818392)})]:[_c('b-table',{attrs:{"stacked":"md","small":"","responsive":"","bordered":"","hover":"","items":_vm.macro_items,"fields":_vm.macro_fields_collapsed,"filter":_vm.filter},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(cell){return [_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return cell.toggleDetails()}}},[_vm._v("\n                                    "+_vm._s(cell.detailsShowing ? 'Hide' : 'Show')+" Details\n                            ")]),_vm._v(" "),_c('b-dropdown',{staticClass:"py-1",attrs:{"right":"","text":_vm.$t('simple.actions'),"size":"sm"}},[_c('b-dropdown-item',{attrs:{"to":{
                                      name: 'macro_setup',
                                      params: { macro: cell.item }
                                  }}},[_vm._v(_vm._s(_vm.$t('simple.edit')))]),_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.remove(cell.item.id)}}},[_vm._v(_vm._s(_vm.$t('simple.delete')))])],1)]}},{key:"row-details",fn:function(row){return [_c('b-table',{staticClass:"d-xl-none",attrs:{"small":"","stacked":"md","responsive":"","bordered":"","hover":"","items":[row.item],"fields":_vm.macro_fields_not_collapsed},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(cell){return [_vm._v("\n\n                                 "+_vm._s(_vm.$moment(_vm.$moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss'))+"\n\n                            ")]}},{key:"cell(updated_at)",fn:function(cell){return [_vm._v("\n\n                                 "+_vm._s(_vm.$moment(_vm.$moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss'))+"\n\n                            ")]}}],null,true)})]}}])})]],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }