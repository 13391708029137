import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Login from './components/Login.vue'
// Account
import AccountsView from './components/AccountsView.vue';

import Account from './components/Account.vue'
import Blocks from './components/Twitter/Blocks.vue'
import Mutes from './components/Twitter/Mutes.vue'
import Tracks from './components/Twitter/Tracks.vue'
import Post from './components/Post/PostView.vue'
import Posts from './components/Post/Posts.vue'
import ShowPosts from './components/Post/ShowPosts.vue'
import NewPost from './components/Post/NewPost.vue'

// Inbox View
import Inbox from './components/Inbox/InboxView.vue'
import InboxMessages from './components/Message/MessagesRender.vue'
import Confirmations from './components/Confirmation/ConfirmationRequests.vue'
import ConfirmationHistory from './components/Confirmation/ConfirmationHistory.vue'
import Tickets from './components/Ticket/TicketRender.vue'

import Notifications from './components/Notification/NotificationsRender.vue'

// Publish Post
// import Post from './components/Post/PostRender.vue'

// Gallery View
import GalleryView from './components/Module/Gallery/GalleryView.vue'

// Reports View
import Reports from './components/Reports/ReportsView.vue'

// Reports Views
import SearchQuestions from './components/Search/SearchQuestions.vue'
import SearchAnswers from './components/Search/SearchAnswers.vue'
import SearchCustomers from './components/Search/SearchCustomers.vue'
import SearchTickets from './components/Search/SearchTickets.vue'

// Settings
import Configurations from './components/Settings/Configurations.vue'
import CrmConfigurations from './components/Settings/CrmConfigurations.vue'
import Accounts from './components/Settings/Accounts.vue'
import Announcements from './components/Settings/Announcements.vue'
import Categories from './components/Settings/Categories.vue'
import CategoryTypes from './components/Settings/CategoryTypes.vue'
import CustomerTypes from './components/Settings/CustomerTypes.vue'
import CustomerGenders from './components/Settings/CustomerGenders.vue'
import CloseTickets from './components/Settings/CloseTickets.vue'
import Tags from './components/Settings/Tags.vue'
import Users from './components/Settings/Users.vue'
import Teams from './components/Settings/Teams.vue'
import Roles from './components/Settings/Roles.vue'
import Macros from "./components/Settings/Macros";
import MacrosSetup from "./components/Settings/MacrosSetup";
import MacrosCategories from "./components/Settings/MacrosCategories";


import SessionMessages from "./components/Settings/Whatsapp/SessionMessages";
import SessionMessageSetup from "./components/Settings/Whatsapp/SessionMessageSetup";
import TemplateMessages from "./components/Settings/Whatsapp/TemplateMessages";
import TemplateMessageSetup from "./components/Settings/Whatsapp/TemplateMessageSetup";

import WhatsappCampaigns from "./components/Settings/Whatsapp/WhatsappCampaigns";
import WhatsappCampaignSetup from "./components/Settings/Whatsapp/WhatsappCampaignSetup";
import WhatsappCampaignEdit from "./components/Settings/Whatsapp/WhatsappCampaignEdit";
import WhatsappCampaignDetails from "./components/Settings/Whatsapp/WhatsappCampaignDetails";


const routes = [

	// Default route
	{ path: '/login', component: Login, meta: { requiresGuest: true }},

	{ path: '/', redirect: 'accounts', meta: { requiresAuth: true, showSidebarButton: true }},

    { path: '/accounts', component: AccountsView, name: 'accounts', meta: { requiresAuth: true, showSidebarButton: true }},

    { path: '/tickets', component: Tickets, name: 'tickets', meta: { requiresAuth: true }},

    { path: '/account/:int/:id', component: AccountsView, props: true, meta: { requiresAuth: true, showSidebarButton: true },

        children: [
            // Account
            { path: '', component: Account, name: 'account', meta: { requiresAuth: true, showSidebarButton: true } },

            // Inbox List
            { path: ':type', component: Inbox, props: true, meta: { requiresAuth: true, showSidebarButton: true },

                children: [
                    // Account
                    { path: '', component: Account, name: 'inbox_list', meta: { requiresAuth: true, showSidebarButton: true } },
                    { path: 'messages/:customer_id', component: InboxMessages, name: 'messages', props: true, meta: { requiresAuth: true, showSidebarButton: true } },
                ]
            },

            // Twitter only
            { path: 'mutes', component: Mutes, name: 'mutes', props: true, meta: { requiresAuth: true, showSidebarButton: true } },
            { path: 'blocks', component: Blocks, name: 'blocks', props: true, meta: { requiresAuth: true, showSidebarButton: true } },
            { path: 'tracks', component: Tracks, name: 'tracks', props: true, meta: { requiresAuth: true, showSidebarButton: true } },
            { path: 'posts', component: Post, props: true, meta: { requiresAuth: true, showSidebarButton: true } ,
                children: [
                    // { path: '', component: Posts, name: 'posts', props: true, meta: { requiresAuth: true } },
                    { path: '', component: Account, name: 'post_list', meta: { requiresAuth: true, showSidebarButton: true } },
                    // new post
                    { path: 'new', component: NewPost, name: 'new_post', meta: { requiresAuth: true, showSidebarButton: true } },
                    // show posts
                    { path: ':postId', component: ShowPosts, name: 'show_post', meta: { requiresAuth: true, showSidebarButton: true } },
                ]
            },

        ],

    },

    // Player
    { path: '/play', component: AccountsView, props: true, meta: { requiresAuth: true, showSidebarButton: true },

        children: [
            // Player List
            { path: ':type', component: Inbox, props: true, meta: { requiresAuth: true, showSidebarButton: true },

                children: [
                    // Account
                    { path: '', component: Account, name: 'player', meta: { requiresAuth: true, showSidebarButton: true } },
                    { path: 'messages/:id/:int/:customer_id', component: InboxMessages, name: 'player_messages', props: true, meta: { requiresAuth: true, showSidebarButton: true } },
                ]
            },

        ],
    },



    // User
    { path: '/notifications', component: Notifications, meta: { requiresAuth: true } },
    { path: '/profile', component: Notifications, meta: { requiresAuth: true } },

    // Confirmations
    { path: '/confirmations/requests', component: Confirmations, name: 'confirmations', meta: { requiresAuth: true } },
    { path: '/confirmations/history', component: ConfirmationHistory, name: 'confirmations_history', meta: { requiresAuth: true } },

    // Confirmations?
    { path: '/post', component: Post, meta: { requiresAuth: true } },

    // Settings
    { path: '/settings/configurations', component: Configurations, meta: { requiresAuth: true } },
    { path: '/settings/crm-configurations', component: CrmConfigurations, meta: { requiresAuth: true } },
    { path: '/settings/accounts', component: Accounts, meta: { requiresAuth: true } },
    { path: '/settings/announcements', component: Announcements, meta: { requiresAuth: true } },
    { path: '/settings/categories/', component: Categories, meta: { requiresAuth: true } },
    { path: '/settings/category/types', component: CategoryTypes, meta: { requiresAuth: true } },
    { path: '/settings/customer/types', component: CustomerTypes, meta: { requiresAuth: true } },
    { path: '/settings/customer/genders', component: CustomerGenders, meta: { requiresAuth: true } },
    { path: '/settings/close-tickets', component: CloseTickets, meta: { requiresAuth: true } },
    { path: '/settings/gallery', component: GalleryView, meta: { requiresAuth: true } },
    { path: '/settings/tags', component: Tags, meta: { requiresAuth: true } },
    { path: '/settings/users', component: Users, meta: { requiresAuth: true } },
    { path: '/settings/teams', component: Teams, meta: { requiresAuth: true } },
    { path: '/settings/roles', component: Roles, meta: { requiresAuth: true } },

    { path: '/settings/macros', component: Macros, meta: { requiresAuth: true } },
    { path: '/settings/macros/setup', component: MacrosSetup, name: 'macro_setup', props: true, meta: { requiresAuth: true } },
    { path: '/settings/macros-categories', component: MacrosCategories, meta: { requiresAuth: true } },

    { path: '/settings/session-messages', component: SessionMessages, meta: { requiresAuth: true } },
    { path: '/settings/session-messages/setup', component: SessionMessageSetup, name: 'session_message_setup', props: true, meta: { requiresAuth: true } },

    { path: '/settings/template-messages', component: TemplateMessages, meta: { requiresAuth: true } },
    { path: '/settings/template-messages/setup', component: TemplateMessageSetup, name: 'template_message_setup', props: true, meta: { requiresAuth: true } },
    
    { path: '/settings/whatsapp-campaigns', component: WhatsappCampaigns, meta: { requiresAuth: true } },
    { path: '/settings/whatsapp-campaigns/setup', component: WhatsappCampaignSetup, name: 'whatsapp_campaign_setup', props: true, meta: { requiresAuth: true}},
    { path: '/settings/whatsapp-campaigns/setup/:account_id/:campaign_id', component: WhatsappCampaignEdit, name: 'whatsapp_campaign_edit', props: true, meta: { requiresAuth: true}},
    { path: '/settings/whatsapp-campaigns/details/:account_id/:campaign_id', component: WhatsappCampaignDetails, name: 'whatsapp_campaign_details', props: true, meta: { requiresAuth: true}},


    // Reports View
    { path: '/reports', component: Reports, name: 'reports', meta: { requiresAuth: true } },

    // Search Views
    { path: '/search/questions', component: SearchQuestions, name: 'search_questions', meta: { requiresAuth: true } },
    { path: '/search/answers', component: SearchAnswers, name: 'search_answers', meta: { requiresAuth: true } },
    { path: '/search/customers', component: SearchCustomers, name: 'search_customers', meta: { requiresAuth: true } },
    { path: '/search/tickets', component: SearchTickets, name: 'search_tickets', meta: { requiresAuth: true } },

];


export default new VueRouter({

    // Refresh Problem with 'History'

    mode: 'history',
    routes

});