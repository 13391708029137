<template>

        <!-- Reports Area -->
        <div class="convoreport external-source" ref="chartdiv">
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';

//     /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                // Create chart instance
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);


                // Add data
                // chart.data = [{
                //         "date": "2013-01-20",
                //         "external_source": 501,

                // }, {
                //         "date": "2013-01-21",
                //         "external_source": 301,

                // }, {
                //         "date": "2013-01-22",
                //         "external_source": 201,

                // }, {
                //         "date": "2013-01-23",
                //         "external_source": 165,

                // }, {
                //         "date": "2013-01-24",
                //         "external_source": 139,

                // }, {
                //         "date": "2013-01-25",
                //         "external_source": 128,

                // }, {
                //         "date": "2013-01-26",
                //         "external_source": 99,

                // }, {
                //         "date": "2013-01-27",
                //         "external_source": 60,

                // }, {
                //         "date": "2013-01-28",
                //         "external_source": 50,

                // }];

                // Set input format for the dates
                // chart.dateFormatter.inputDateFormat = "yyyy-MM-dd-HH";

                // chart.colors.list = [
                //         am4core.color("#e7c6bc"),

                // ];


                // // Create axes
                // let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                // dateAxis.dataFields.date = "date";

                // let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                // valueAxis.title.text = this.$t('reports.external_source');


                // // Create series (Followers)
                // let series = chart.series.push(new am4charts.ColumnSeries());
                // series.dataFields.valueY = "external_source";
                // series.dataFields.dateX = "date";
                // series.name = this.$t('reports.external_source');
                // series.tooltipText = "{name}: [bold]{valueY}[/]";
                // series.stacked = true;




                // // Add cursor
                // chart.cursor = new am4charts.XYCursor();

                // dateAxis.keepSelection = true;

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoExternal";

                // chart.marginRight = 400;


        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

        },
    }
</script>