<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('simple.add_message_by_url') + ' (' + this.integration + ')' }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closed_details_empty()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

                <form @submit.prevent="validate_add">


                    <div class="form-group">

                        <label for="message_url">{{ $t('simple.url') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="message_url"
                            name="message_url"
                            v-validate="'required|max:255'"
                            v-model="add_message_url"
                        >

                        <small class="form-text text-info"><font-awesome-icon :icon="'info'" /> {{ $t('simple.add_url_desc') }}</small>
                        <small v-show="errors.has('message_url')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('message_url') }}</small>

                    </div>

                  <v-wait for="detect_message_by_url">

                    <loading slot="waiting"/>
                  <b-alert v-if="detected_message_detail.data" :show="true" variant="info">
                    <font-awesome-icon :icon="'link'" /> {{ $t('simple.url') }} : {{ add_message_url }}
                    <br>
                    <font-awesome-icon :icon="'user'" /> {{ $t('simple.sender') }} : {{ detected_message_detail.includes.users[0].name }}
                    <br>
                    <font-awesome-icon :icon="'comment-dots'" /> {{ $t('pixel.text') }} : {{ detected_message_detail.data.text }}
                  </b-alert>
                    <b-alert v-if="detected_message_detail_error.length" :show="true" variant="danger">
                      <font-awesome-icon :icon="'exclamation-circle'" /> {{ detected_message_detail_error }}
                    </b-alert>
                    <b-button v-if="!detected_message_detail" @click="validate_data(false)" variant="primary">{{ $t('simple.detect') }}</b-button>
                  <b-button v-if="detected_message_detail.data" @click="validate_data(true)" variant="primary">{{ $t('simple.add_message') }}</b-button>


                  </v-wait>
                </form>


        </div>

        <div class="modal-footer">

            <b-button @click="closed_details_empty()" variant="secondary">{{ $t('simple.cancel') }}</b-button>

        </div>


        </div>
      </div>
    </div>
</template>
<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import {create} from "vue-modal-dialogs";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Add Message By URL Modal Mounted');
        },

        destroyed() {
            // Component destroyed
            console.log('Add Message By URL Modal Destroyed.');
        },

        created: function() {
            // Component created
        },

        data: function() {
            return  {}
        },

        computed: {

          // Map getters
          ...mapGetters({
            message_url: 'Messages/getMessageUrl',
            detected_message_detail: 'Messages/getDetectedMessageDetail',
            detected_message_detail_error: 'Messages/getDetectedMessageDetailError',
          }),

          // Add Message By URL
          add_message_url: {

            // getter
            get: function () {
              // Get Message URL
              console.log('Get Message URL: ' + this.message_url);
              return this.message_url;
            },

            // setter
            set: function (value) {
              // Check if we have value?
              if (value) {

                // Update Message URL
                console.log('Set Message URL: ' + this.message_url);
                this.setMessageUrl(value);
              }
            },

          },

        },

        methods: {

          // Map actions
          ...mapActions('Messages', [
            'detectMessageByUrl',
          ]),

          // Map actions
          ...mapMutations('Messages', [
            'setMessageUrl',
            'setDetectedMessageDetail',
            'setDetectedMessageDetailError',
          ]),

          closed_details_empty: function() {
            this.setDetectedMessageDetail('');
            this.setMessageUrl('');
            this.setDetectedMessageDetailError('');
            this.$close(false);
          },

            validate_data: async function (confirm) {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result && this.detected_message_detail && confirm === true) {
                      // Show Confirmation For Close Tickets
                      this.detectMessageByUrl(confirm);
                      this.closed_details_empty();

                    }else if (result && confirm === false){
                      // Get Open Tickets Details
                      this.setDetectedMessageDetailError('')
                      this.detectMessageByUrl(confirm);
                    }
                });
            },
        },
    }
</script>
