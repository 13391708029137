<template functional>
    <!-- Answer -->
    <div class="message-container">

        <div class="message-wrapper-agent">

            <div class="message-header message-header-agent">


                <b-img :src="props.account.avatar ? props.account.avatar : '/images/default_profile_normal.png'" alt="Account image" class="message-header-avatar"></b-img>


                <span class="message-header-name">{{ props.account.name }}</span>


                <div class="message-header-info">

                    <span v-if="props.message.lang !== 'Unknown'" class="message-header-data font-weight-bold text-uppercase" v-b-tooltip.hover :title="parent.$t('simple.content') + ' ' + parent.$t('simple.language') + ': ' + props.languages[props.message.lang.toLowerCase()]" > {{ props.message.lang }}</span>

                    <!-- Message Type Render -->
                    <message-type v-bind:integration="props.account.integration_id" v-bind:type="props.message.type" v-bind:status_id="props.message.status_id"></message-type>

                    <!-- Message Created -->
                    <message-date v-bind:username="props.account.name" v-bind:id="props.message.message_id" v-bind:type="props.message.type" v-bind:integration="props.account.integration_id" v-bind:date="props.date" v-bind:created="props.message.created_at"></message-date>

                    <!-- Message Source -->
                    <message-source v-if="props.message.source" v-bind:source_id="props.message.id" v-bind:source="props.message.source"></message-source>

                    <!-- Message Ticket -->
                    <message-ticket v-if="props.ticket" v-bind:ticket="props.ticket" v-on="listeners" 
                    v-b-toggle="ticket_toggle"
                    ></message-ticket>

                    <!-- Here goes our buttons -->
                    <div class="dropdown message-header-data">

                        <b-dropdown size="sm" right variant="light" :disabled="props.disabled_areas.answer_options">
                            <b-dropdown-item v-on:click="listeners.updateLast(true)"><font-awesome-icon :icon="'reply'" class="mr-2" />{{ parent.$t('default.reply') }}</b-dropdown-item>
                        </b-dropdown>

                    </div>

                </div>

            </div>

            <div class="message message-answer shadow">
                <message-attachments v-if="props.message.attachments.length > 0  && props.message.is_whatsapp_template"
                    v-bind:attachments="props.message.attachments"
                    :is_whatsapp_template="props.message.is_whatsapp_template"></message-attachments>
                <h6 v-if="props.message.whatsapp_template_header" class="py-1 px-2 bg-light" :style="{ borderRadius: '10px'}">
                    {{ props.message.whatsapp_template_header }}</h6>
                <div class="message-content" :class="/^[\u0621-\u064A\u0660-\u0669 ]+$/.test(props.message.content) ? 'text-right' : ''" :dir="/^[\u0621-\u064A\u0660-\u0669 ]+$/.test(props.message.content) ? 'rtl' : 'ltr'">{{ props.message.content }}</div>
            </div>

            <!-- Message Attachments -->
            <message-template-buttons v-if="props.message.whatsapp_template_buttons "
                :buttons="props.message.whatsapp_template_buttons"></message-template-buttons>

            <message-attachments v-if="props.message.attachments.length > 0 && !props.message.is_whatsapp_template"
                v-bind:attachments="props.message.attachments"></message-attachments>

        </div>
    </div>
</template>

<script>


    export default {

        mounted: function () {
            // Component mounted
            //console.log('Content render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() { return {} },

        computed: {
            ticket_toggle() {
                if(props.disabled_areas.ticket_edit) {
                    return ['']
                }
                return ['user-info-sidebar-right-tablet', 'plus-col-accounts-sidebar','plus-col-main-list-inbox-tablet', 'plus-col-main-messages-panel-mobile']
            }
        },
        
        methods: {},
        
    }
</script>