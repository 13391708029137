<template>
    <!-- User Assigned Icon -->
    <div v-if="customer.user_assigned" v-b-tooltip.hover
          :title="$t('customer.is_replied_by', { user_name: customer.user_assign.ns })"><font-awesome-icon
            :icon="'lock'" class="text-danger mr-3 mb-1"/></div>

</template>

<script>


    export default {

        props: ['customer'],

        data: function () {
            return {}
        },

        methods: {

        },

        computed: {},
    }
</script>