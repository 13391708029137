<template>
    <div class="container-fluid">
        <v-wait for="answer">

            <loading slot="waiting" />

            <div class="row">
                <div v-bind:class="{ replyOverlay: isReply }"></div>
                <!-- Text area form -->
                <div class="col mt-2 mb-0">

                    <div class="form-group position-relative">

                        <b-form-textarea ref="textarea" class="form-control message-reply-textarea" v-model="text"
                            :dir="rtl ? 'rtl' : 'ltr'" :class="rtl ? 'text-right' : ''" :rows="2" :max-rows="7"
                            :placeholder="$t('default.reply_desc')"
                            :maxlength="integration_type_limit"></b-form-textarea>

                        <div class="position-absolute d-flex align-items-center coolpicker-button-reply-area">
                            <coolpicker :emojiData="emojiDataAll" :emojiGroups="emojiGroups" pickerPlacement="top-end"
                                :btnEmojiClasses="['btn-emoji']" @emojiUnicodeAdded="add_emoji"
                                twemojiPath="https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/"
                                twemojiExtension=".svg" twemojiFolder="svg">
                            </coolpicker>
                            <a-progress class="mx-1" type="circle" :percent="calculate_total" :showInfo="false"
                                :width="25" :status="get_variant" v-b-tooltip.hover
                                :title="$t('simple.left_characters') + ' : ' + count_total.toString()" />
                            <b-form-checkbox switch v-model="rtl" class="mx-2 font-weight-bolder">
                                {{ rtl ? 'rtl' : 'ltr' }}
                            </b-form-checkbox>
                        </div>
                    </div>


                </div>

                <b-alert show v-if="integration &&
                    integration.id === 'twitter' &&
                    active_integration_type === 1 &&
                    twitter_limits &&
                    twitter_limits.dm &&
                    twitter_limits.dm.user_limit_remaining &&
                    twitter_limits.dm.user_limit_remaining === '0' &&
                    account_data" variant="danger" class="m-0 w-100">
                    <small>{{ $t("default.twitter_dm_limit_alert", {
                        remaining_limit: twitter_limits.dm.user_limit_remaining,
                        reset_time: $moment($moment.utc(twitter_limits.dm.user_limit_reset)).local().format('YYYY-MM-DD HH:mm')}) }}
                    </small>
                </b-alert>

                <!-- Spacing -->
                <div class="w-100 border-bottom"></div>


                <reply-user-mentions v-if="user_mentions" v-bind:mentions="user_mentions"
                    class="col m-3"></reply-user-mentions>

                <div v-if="user_mentions" class="w-100 border-bottom"></div>

                <!-- Text area form -->
                <div class="mx-2 w-100">

                    <b-container fluid>

                        <b-row class="align-items-center my-1">


                            <!-- Reply -->
                            <b-btn variant="light" :disabled="text.length < 2 || !get_can_comment" @click="sendText"
                                class="mr-1">
                                <font-awesome-icon :icon="'pencil-alt'" class="plus__icon" />
                                {{ $t('default.reply') }}
                            </b-btn>

                            <!-- Attachment -->
                            <b-btn variant="light" :pressed.sync="attachment_status" class="mr-1">
                                <font-awesome-icon :icon="'paperclip'" class="plus__icon" />
                                {{ $t('page.attachment') }}
                            </b-btn>

                            <!-- Schedule -->
                            <b-btn variant="light" @click.prevent="schedule" :disabled="getTicketPermission === 'false'"
                                class="mr-1">
                                <div v-if="getScheduleDate">
                                    <b-spinner variant="warning" type="grow" small></b-spinner>
                                    <font-awesome-icon :icon="'clock'" :class="'nav-settings-scheduled'" />
                                    {{ $t('ticket.schedule') }}
                                </div>
                                <div v-else>
                                    <font-awesome-icon :icon="'clock'" :class="'plus__icon'" />
                                    {{ $t('ticket.schedule') }}
                                </div>
                            </b-btn>
                            <datetime ref="datepicker" type="datetime" v-model="schedule_date"
                                :min-datetime="schedule_start_date" format="yyyy-MM-dd HH:mm" hiddenName="test"
                                placeholder="Schedule Post" input-class="btn btn-outline-dark"
                                input-style="width : 140px; display: none;" class="theme-message">
                            </datetime>


                            <!-- Reset-->
                            <b-btn variant="light" v-b-tooltip.hover :title="$t('default.reset_desc')"
                                @click="resetAll">
                                <font-awesome-icon :icon="'redo'" class="plus__icon" />
                                {{ $t('default.reset') }}
                            </b-btn>



                            <b-form class="d-flex flex-md-column align-items-center ml-auto mr-4">
                                <template v-if="active_integration_type === 2 && integration_id !== 'instagram'">
                                    <v-wait for="sender_account" class="w-100">
                                        <loading slot="waiting" />
                                        <b-form-select :class="!get_can_comment ? 'border-danger' : ''" size="sm"
                                            v-model="sender_account" :options="integration_list[integration_id]"
                                            text-field="name" value-field="id" @change="can_comment"
                                            class="py-1 my-1" />
                                    </v-wait>
                                </template>
                                <b-form-select size="sm" v-model="active_integration_type"
                                    :options="integration_types || []" text-field="name" value-field="type"
                                    @change="change_integration" class="py-1 ml-md-0 ml-1" />
                            </b-form>
                        </b-row>

                        <b-alert variant="warning" show class="d-flex align-items-center py-1" v-if="schedule_date">
                            <div>
                                {{ $t('schedule.schedule_desc', {
                                    schedule_at: $moment($moment.utc(schedule_date)).local().format('DD-MM-YYYY HH:mm'),
                                }) }}
                            </div>
                            <b-button variant="outline-danger" class="ml-auto" @click.prevent="resetScheduleDate">{{
                                $t('default.reset')
                            }}</b-button>
                        </b-alert>

                        <!-- Upload Render -->
                        <reply-upload v-if="attachment_status"></reply-upload>
                    </b-container>

                </div>

            </div>

        </v-wait>

    </div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex';

import {
    TwemojiPicker
} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';

import { DateTime } from "luxon";


export default {

    components: {

        'coolpicker': TwemojiPicker

    },

    mounted: function () {
        // Component mounted
        console.log("----Reply Text Component Mounted");

    },

    destroyed() {
        // Component destroyed
        console.log("-----Reply Text Component Destroyed");


    },

    created: function () {
        // Component created
        console.log("----Reply Text Component Created");
    },

    data: function () {

        return {

            tabIndex: true,
            schedule_start_date: DateTime.local().plus({ minutes: 1 }).toISO(),
            rtl: false,

        };

    },

    computed: {

        // Map getters
        ...mapGetters({

            // Customer
            customer: 'Customer/getCustomer',

            // Reply
            getText: 'Reply/getText',

            // is Reply
            getIsReply: 'Reply/getIsReply',

            // thread ID
            thread_id: 'Reply/getThreadId',

            // is Reply
            getPixelImage: 'Reply/getPixelImage',

            // Account
            account: 'Accounts/getAccount',

            // Integration list
            integration_list: 'Accounts/getIntegrationAccountList',

            // Sender Account
            sender_id: 'Accounts/getSenderAccount',

            // Can Comment
            get_can_comment: 'Accounts/getCanComment',

            // Get account Integration
            integration_id: 'Accounts/getIntegrationID',

            // Integration type
            integration_type: 'Accounts/getIntegrationType',

            // Integration types
            integration_types: 'Accounts/getIntegrationTypes',

            // Integration types id
            integration_type_id: 'Accounts/getIntegrationTypeID',

            // Integration types limit
            integration_type_limit: 'Accounts/getIntegrationTypeLimit',

            // Get Question Answer
            qa: 'Messages/getActiveQA',

            // Get Attacment Answer
            getAttachmentStatus: 'Reply/getAttachmentStatus',

            // Get DM Status
            getDmStatus: 'Customer/getCustomerDmStatus',

            integration: 'Accounts/getIntegration',

            account_data: 'Accounts/getAccountData',

            getScheduleDate: 'Reply/getScheduleDate',

            getTicketPermission: 'Ticket/getTicketPermission',


        }),

        // Schedule Date
        schedule_date: {

            // getter
            get: function () {
                // Get Schedule Data
                return this.getScheduleDate;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {

                    // Update Active tab
                    this.updateScheduleDate(value);
                }
            },

        },

        // Text
        text: {

            // getter
            get: function () {
                // Get Schedule Data
                return this.getText;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value || value === '') {

                    this.updateText(value);

                }

            },

        },

        // Is Reply
        isReply: {

            // getter
            get: function () {
                // Get Schedule Data
                return this.getIsReply;
            },

        },


        // Active integration type
        active_integration_type: {

            // getter
            get: function () {
                // Get Integration Type
                if (this.integration_id === 'twitter') {

                    let check_comment = this.customer.cm_count;

                    // Check Comment
                    this.integration_types[1]['disabled'] = !check_comment;

                    this.integration_types[0]['disabled'] = this.getDmStatus === false;

                    if (this.integration_type_id === 3) {
                        this.integration_type_id = 3;
                    }

                } else if (this.integration_id === 'facebook' || this.integration_id === 'instagram') {

                    let check_dm = this.customer.dm_count;

                    let check_comment = this.customer.cm_count;

                    // Check DM
                    this.integration_types[0]['disabled'] = !check_dm;

                    // Check Comment
                    this.integration_types[1]['disabled'] = !check_comment;

                } else if (this.integration_id === 'sikayetvar') {

                    // Check DM
                    this.integration_types[0]['disabled'] = false;

                }

                return this.integration_type_id;

            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Check type or make it default detka
                    let type = value ? value : this.customer.question.type;

                    // reset sender ID
                    if (type === 1) {
                        this.updateSenderAccount(null);
                    }

                    // Update Reply Text
                    this.setIntegrationType(type);
                }
            },

        },

        // Sender Account
        sender_account: {

            // getter
            get: function () {

                // Return Current Account
                return this.sender_id ? this.sender_id : this.account;

            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Check type or make it default detka
                    let id = value ? value : this.account;

                    // Update Sender Account
                    this.updateSenderAccount(id);
                }
            },

        },

        // Active Attachment Status
        attachment_status: {

            // getter
            get: function () {
                // Get Attachment Status
                return this.getAttachmentStatus;
            },

            // setter
            set: function (value) {

                // Update Reply Text
                this.updateAttachmentStatus(value);
            },

        },

        // Rewrite this to using message get user mentions
        // Ok ?
        // 7.12.2018 00.36
        user_mentions: function () {

            if (this.qa) {

                if (this.qa.user_mentions && this.qa.user_mentions.length > 0) {

                    return JSON.parse(this.qa.user_mentions);
                }
            } else {

                return false;
            }

        },


        // Calculate percentage
        calculate_total() {
            // Calculate
            let result = (this.text_total / this.integration_type_limit) * 100;

            // Return result
            return result;

        },

        // Count total
        count_total() {

            // Return result
            return (this.integration_type_limit - this.text_total);
        },

        text_total() {

            // Return text length
            return this.text ? this.text.length : 0;

        },

        // Ticket teams
        user_teams: {

            // getter
            get: function () {
                return this.getTeams;
            },


        },

        get_variant() {

            if (this.count_total > 0) {

                return 'normal';

            } else if (this.count_total == 0) {

                return 'success'
            } else {

                return 'exception';
            }

        },

        // Emoji data
        emojiDataAll() {
            return EmojiAllData;
        },
        // Emoji Groups
        emojiGroups() {
            return EmojiGroups;
        },

        twitter_limits() {
            return JSON.parse(this.account_data.additional_data)?.twitter ?? {}
        }

    },

    methods: {
        // Some actions detka
        ...mapActions('Reply', [
            'resetFiles',
            'resetText',
            'updateText',
            'sendText',
            'updateAttachmentStatus',
            'updateTextAdd',
            'checkCanComment',
            'updateScheduleDate',
            'resetScheduleDate'

        ]),

        ...mapActions('Accounts', [

            'setIntegrationType',
            'updateSenderAccount',
            'updateCanComment',

        ]),

        ...mapActions('Messages', [

            'updateQA',

        ]),


        // Add emoji
        add_emoji: function (emoji) {

            // Update text with emoji
            this.updateTextAdd({ text: emoji });

        },

        // Check selected account can comment
        can_comment: function () {

            let comment_id = null;
            if (this.integration_id === 'facebook') {
                if (this.getIsReply) {
                    comment_id = this.thread_id;
                } else {
                    comment_id = this.customer.last_comment.message_id;
                }

                // Check Can Comment for Facebook
                this.checkCanComment({ integration: this.integration_id, sender_account: this.sender_account, comment_id: comment_id });
            }

        },

        // Check selected account can comment
        change_integration: function () {

            let comment_id = null;
            if (this.active_integration_type === 1) {
                // restart can comment status
                this.updateCanComment(true);
            }
        },

        schedule() {
            const datePicker = this.$refs.datepicker;
            datePicker.isOpen = true;
        },

        resetAll() {
            this.resetFiles()
            this.resetText()
        }
    },

    watch: {
        // whenever ml changes, this function will run
        qa: function (qa) {

            this.updateCanComment(true);

            this.updateSenderAccount(this.account);

            let type = qa.type ? qa.type : this.customer.question.type;

            if (!qa.type && this.customer) {
                this.updateQA(this.customer.question);
            }

            if (this.integration_id === 'twitter') {

                this.integration_types[0]['disabled'] = this.getDmStatus === false;

                // Check Comment
                this.integration_types[1]['disabled'] = !this.customer.cm_count;

                if (type == 3 || qa.type == 3) {

                    type = 2;

                }

            }

            if (this.integration_id === 'facebook' || this.integration_id === 'instagram') {

                // Check DM
                this.integration_types[0]['disabled'] = !this.customer.dm_count;

                // Check Comment
                this.integration_types[1]['disabled'] = !this.customer.cm_count;

            }

            if (this.integration_id === 'sikayetvar') {

                // Check Message
                this.integration_types[0]['disabled'] = false;

                type = 1;

            }

            // Check if we have message last type
            if (type) {
                // Set account
                this.setIntegrationType(type);
            }

        }

    },

}
</script>