<template>

    <!-- Reports Area -->
    <div class="convoreport" ref="chartdiv" v-if="languageIncomingOutgoing.length > 0">
    </div>
    <div class="convoreport report-overlay" ref="fakechart" v-else>
    </div>

</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export default {
    mounted() {

        console.log('xy', this.languageIncomingOutgoing )

        let root;

        if (this.languageIncomingOutgoing.length > 0) {
            root = am5.Root.new(this.$refs.chartdiv);
        } else {
            root = am5.Root.new(this.$refs.fakechart)
        }

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            layout: root.verticalLayout,
        }));

        let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
        }));

        let data = this.languageIncomingOutgoing.length > 0 ? this.languageIncomingOutgoing : this.fakeLanguageIncomingOutgoing;

        let xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: {
                    timeUnit: this.languageIncomingOutgoing.length > 0 ? this.calendar_interval : 'year',
                    count: 1,
                },
                renderer: am5xy.AxisRendererX.new(root, {
                }),
            })
        );

        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        

        let makeSeries = (name, fieldName) => {
            let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    valueXField: "date",
                    stacked: true,
                    clustered: false,
                    tooltip: am5.Tooltip.new(root, {}),
                })
            );

            series.data.processor = am5.DataProcessor.new(root, {
                dateFields: ["date"],
                dateFormat: "i"
            });

            series.get("tooltip").label.set("text", "{name}: [bold]{valueY}");

            series.data.setAll(data);
        }

        let items = []
        data.forEach(obj => {
            for (let element in obj) {
                if (element !== 'date') {
                    if (items.indexOf(element) === -1) {
                        items.push(element)
                    }
                }
            }
        });

        items.forEach(item => makeSeries(this.languages[item.toLowerCase()] || 'Not Detected', item))

        legend.data.setAll(chart.series.values);

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomXY",
        }));
        cursor.lineY.set("visible", false);

        if (this.languageIncomingOutgoing.length > 0) {
            am5plugins_exporting.Exporting.new(root, {
                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                dataSource: data,
                dateFields: ["date"],
                dateFormat: "yyyy-MM-dd",
                filePrefix: "convoTypes",
            });
        }


        this.root = root;

    },

    beforeDestroy() {

        if (this.root) {
            this.root.dispose();
        }
    },

    computed: {

        // Map getters
        ...mapGetters({

            languageIncomingOutgoing: 'Report/getLanguageIncomingOutgoing',
            fakeLanguageIncomingOutgoing: 'Report/getFakeLanguageIncomingOutgoing',
            calendar_interval: 'Report/getCalenderInterval',
            languages: 'Messages/getLanguages',

        })

    },
}


</script>