<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="categoryProcess.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';


    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Plugin: Annotation */
//     import * as am4plugins_annotation from "@amcharts/amcharts4/plugins/annotation";


    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('xy', this.categoryProcess)


                let root;


                if (this.categoryProcess.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([
                        am5themes_Animated.new(root),
                ]);

                let raw_data = this.categoryProcess.length ? this.categoryProcess : this.fakeCategoryProcess;

                let primary_data = raw_data.map(obj => {
                        Object.keys(obj).forEach((key) => {
                                if(obj[key] === 0) {
                                        delete obj[key]
                                }
                        });
                        return obj
                })

                let data = primary_data.filter( obj => Object.keys(obj).length !== 1).map( obj => {
                        let total = Object.values(obj).filter(val => typeof val === 'number').reduce((p, c) => p + c)
                        return {
                                ...obj,
                                total
                        }
                }).sort( (a,b) => a.total - b.total)

                let chart = root.container.children.push(am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "none",
                        wheelY: "none",
                        layout: root.verticalLayout
                }));


                chart.get("colors").set("colors", [
                        am5.color('#003f5c'),
                        am5.color('#58508d'),
                        am5.color('#bc5090'),
                        am5.color('#ff6361'),
                        am5.color('#ffa600')
                ]);

                let yRenderer = am5xy.AxisRendererY.new(root, {
                        minGridDistance: 12,
                });
                let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
                        // maxDeviation: 0.3,
                        categoryField: "category",
                        renderer: yRenderer,
                        tooltip: am5.Tooltip.new(root, {})
                }));

                yRenderer.grid.template.setAll({
                        location: 0
                })


                yAxis.data.setAll(data);

                let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
                        min: 0,
                        renderer: am5xy.AxisRendererX.new(root, {
                                strokeOpacity: 0.1
                        }),
                        
                }));


                let legend = chart.children.push(am5.Legend.new(root, {
                        centerX: am5.p50,
                        x: am5.p50
                }));


                function makeSeries(fieldName, name) {
                        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                                name: name,
                                stacked: true,
                                xAxis: xAxis,
                                yAxis: yAxis,
                                baseAxis: yAxis,
                                sequencedInterpolation: true,
                                valueXField: fieldName,
                                categoryYField: "category"
                        }));

                        series.columns.template.setAll({
                                tooltipText: "{name}, {categoryY}: {valueX}",
                                tooltipY: am5.percent(90)
                        });
                        series.data.setAll(data);


                        series.bullets.push(function () {
                                return am5.Bullet.new(root, {
                                        sprite: am5.Label.new(root, {
                                                text: "{valueX}",
                                                fill: root.interfaceColors.get("alternativeText"),
                                                centerY: am5.p50,
                                                centerX: am5.p50,
                                                populateText: true
                                        })
                                });
                        });

                        legend.data.push(series);
                }


                makeSeries("status_open", this.$t("ticket.status_open"));
                makeSeries("status_replied", this.$t("ticket.status_replied"));
                makeSeries("status_transferred", this.$t("ticket.status_transferred"));
                makeSeries("status_awaiting", this.$t("ticket.status_awaiting"));
                makeSeries("status_closed", this.$t("ticket.status_closed"));

                chart.set("cursor", am5xy.XYCursor.new(root, {
                        behavior: "zoomXY"
                }));

                if(this.categoryProcess.length > 0) {
                    am5plugins_exporting.Exporting.new(root, {
                        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                        dataSource: data,
                        filePrefix: "convoTypes",
                    });
                }



                this.root = root;


                // // Create chart instance
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

                // // Add data | Should be percent %100 - Open/Awaiting/Waiting(Negative)
                // chart.data = this.categoryProcess;

                // console.log("--------------------------");
                // console.log(this.categoryProcess);

                // // Create axes
                // let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                // categoryAxis.dataFields.category = "category";
                // categoryAxis.renderer.grid.template.location = 0;
                // categoryAxis.renderer.inversed = true;
                // categoryAxis.renderer.minGridDistance = 20;
                // categoryAxis.renderer.axisFills.template.disabled = false;
                // categoryAxis.renderer.axisFills.template.fillOpacity = 0.05;


                // let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                // valueAxis.min = 0;

                // valueAxis.renderer.minGridDistance = 50;
                // valueAxis.renderer.ticks.template.length = 5;
                // valueAxis.renderer.ticks.template.disabled = false;
                // valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
                // valueAxis.renderer.labels.template.adapter.add("text", function(text) {
                //         return text;
                // })

                // // Legend
                // chart.legend = new am4charts.Legend();
                // chart.legend.position = "right";

                // // Use only absolute numbers
                // chart.numberFormatter.numberFormat = "#.#s";

                // // Create series
                // function createSeries(field, name, color) {
                //         let series = chart.series.push(new am4charts.ColumnSeries());
                //         series.dataFields.valueX = field;
                //         series.dataFields.categoryY = "category";
                //         series.stacked = true;
                //         series.name = name;
                //         series.stroke = color;
                //         series.fill = color;

                //         let label = series.bullets.push(new am4charts.LabelBullet);
                //         label.label.text = "{valueX}";
                //         label.label.fill = am4core.color("#fff");
                //         label.label.strokeWidth = 0;
                //         label.label.truncate = false;
                //         label.label.hideOversized = true;
                //         label.locationX = 0.5;
                //         return series;
                // }

                // let interfaceColors = new am4core.InterfaceColorSet();
                // let positiveColor = interfaceColors.getFor("positive");
                // let negativeColor = interfaceColors.getFor("negative");

                // createSeries("status_open", this.$t("ticket.status_open"), negativeColor);
                // createSeries("status_replied", this.$t("ticket.status_replied"), negativeColor.lighten(0.3));
                // createSeries("status_transferred", this.$t("ticket.status_transferred"), negativeColor.lighten(0.6));
                // createSeries("status_awaiting", this.$t("ticket.status_awaiting"), positiveColor);
                // createSeries("status_closed", this.$t("ticket.status_closed"), positiveColor.lighten(0.5));


                // chart.legend.events.on("layoutvalidated", function(event){
                //         chart.legend.itemContainers.each((container)=>{
                //                 if(container.dataItem.dataContext.name == "Never"){
                //                         container.toBack();
                //                 }
                //         })
                // })

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoStatus";

                // // Plugin: Annotation
                // chart.plugins.push(new am4plugins_annotation.Annotation());

                // // Add cursor
                // chart.cursor = new am4charts.XYCursor();


        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

                if (this.root) {
                        this.root.dispose();
                }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({

                        categoryProcess: 'Report/getCategoryProcess',
                        fakeCategoryProcess: 'Report/getFakeCategoryProcess',


                })
        },
    }
</script>