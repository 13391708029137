<template>

    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.add')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="add">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_add">

                    <div class="form-group">

                        <label for="integration">{{ $t('simple.user') }}</label>

                        <v-wait for="users">

                            <loading slot="waiting"/>

                            <multiselect
                                v-validate="'required'"
                                data-vv-name="user_id"
                                data-vv-value-path="user_id"
                                v-model="user_id"
                                :options="users"
                                :allow-empty="false"
                                :searchable="false"
                                :show-labels="false"
                                track-by="name"
                                label="name"
                                placeholder="Select user"
                                name="user_id"
                                id="user_id"
                            ></multiselect>

                        </v-wait>

                        <small v-show="errors.has('user')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('user') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="integration">{{ $t('simple.role') }}</label>

                        <v-wait for="roles">

                            <loading slot="waiting"/>

                            <multiselect
                                v-validate="'required'"
                                data-vv-name="role_id"
                                data-vv-value-path="role_id"
                                v-model="role_id"
                                :options="roles"
                                :allow-empty="false"
                                :searchable="false"
                                :show-labels="false"
                                track-by="name"
                                label="name"
                                placeholder="Select role"
                                name="role_id"
                                id="role_id"
                            ></multiselect>

                        </v-wait>

                        <small v-show="errors.has('role')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('role') }}</small>

                    </div>

                </form>

            </v-wait>

          </div>

        <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

            <b-button @click="validate_add()" variant="primary">{{ $t('simple.save') }}</b-button>
            
        </div>

        </div>
      </div>
    </div>
</template>

<script>

    export default {

        mounted: function () {
            // Component mounted
            console.log('Team User Add Modal mounted');

            // Get roles
            this.getRoles();

            // Get users
            this.getUsers();
        },
        
        destroyed() {
            console.log('Team User Add Modal destroyed.');
        },

        data: function() {

             return  {

                teamuser_api: '/api/v1/settings/teamuser/',
                roles_api: '/api/v1/system/roles/',
                users_api: '/api/v1/system/users/',

                users: [],
                roles: [],

                user_id: '',
                role_id: '',

            }

        },

        methods: {

            getRoles: async function() {

                // Start waiting
                this.$wait.start('roles');

                // Send request
                await axios.get(this.roles_api)

                  .then((response) => {
                    
                    // response data
                    this.roles = response.data;

                    //console.log(response);

                    // End waiting
                    this.$wait.end('roles');

                  })

                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    //console.log(error);

                    // End waiting
                    this.$wait.end('roles');

                  });

            },

            getUsers: async function() {

                // Start waiting
                this.$wait.start('users');

                // Send request
                await axios.get(this.users_api)

                  .then((response) => {
                    
                    // response data
                    this.users = response.data;

                    //console.log(response);

                    // End waiting
                    this.$wait.end('users');

                  })

                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    //console.log(error);

                    // End waiting
                    this.$wait.end('users');

                  });

            },

            user_add: async function(){

                // Loading start
                this.$wait.start('add');

                // Add account
                await axios.post(this.teamuser_api, {
                    user_id: this.user_id.id,
                    team_id: this.team_id,
                    role_id: this.role_id.id
                  })
                  .then((response) => {

                      if(response.data.error){
                          // Show error
                          Vue.toasted.error(Vue.i18n.translate('permission.update_teamuser'),{duration:5000});
                          // Loading stop
                          this.$wait.end('add');
                          // Close modal
                          this.$close(true);
                      }else {

                          // Loading stop
                          this.$wait.end('add');

                          // Show success
                          Vue.toasted.success(this.$t('simple.success_info'));

                          // Close modal
                          this.$close(true);
                      }


                  })
                  .catch((error) => {

                    // Loading stop
                    this.$wait.end('add');

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Close modal
                    //this.$close(true);


                  });

            },

            validate_add: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.user_add();
                    }
                });
            },
        },
    }
</script>


