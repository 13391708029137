<template>

	<div>

		<plus-header v-if="loggedIn"></plus-header>

		<!-- MR Edited - Deleted From Main New  h-100  -->

		<main class="container-fluid">

		   	<!-- Main Content -->
			  <router-view></router-view>
			  <!-- End Main Content -->
      
        <dialogs-wrapper wrapper-name="default" />
		</main>

	</div>

</template>

<script>

	import { mapGetters, mapActions } from 'vuex';

    export default {

        mounted: function () {
            console.log('Plus mounted.');
        },

		created: function () {
			console.log('Plus created.');

            if (this.loggedIn) {
                // Set user
                this.updateUser();
                if (this.$route.path != '/settings/configurations') {
                    this.fetchConfigurations();
                }
            }
		},

        data: function() {
             return  {}
        },
        
        metaInfo: function() {

        	return {
	      		// if no subcomponents specify a metaInfo.title, this title will be used
             	title: this.$t('page.homepage'),
	      		// all titles will be injected into this template
	      		titleTemplate: '%s | Convo+'
	      	}
	      	
	    },

      methods: {

        ...mapActions('User', [
          'updateUser',
        ]),

        ...mapActions('Configurations', [
          'fetchConfigurations',
        ]),
      },

      computed: {
        // Map getters
        ...mapGetters({
          loggedIn: 'User/getLoggedIn',
        }),
      },
    }
</script>