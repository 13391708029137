<template>
    <div>
        <template v-if="window_width >= 992">

            <!-- Confirmations List -->
            <b-dropdown-item :to="confirmation_requests_route" active-class="active"><font-awesome-icon :icon="'check'" /> {{ $t('page.confirmations_list') }}</b-dropdown-item>
            <!-- Confirmations History -->
            <b-dropdown-item :to="confirmation_history_route" active-class="active"><font-awesome-icon :icon="'history'" /> {{ $t('page.confirmations_history') }}</b-dropdown-item>
       
        </template>

        <template v-else>

            <!-- Confirmations List -->
            <b-link class="plus-header-accordion-links" :to="confirmation_requests_route" active-class="active"><font-awesome-icon :icon="'check'" /> {{ $t('page.confirmations_list') }}</b-link>
            <!-- Confirmations History -->
            <b-link class="plus-header-accordion-links" :to="confirmation_history_route" active-class="active"><font-awesome-icon :icon="'history'" /> {{ $t('page.confirmations_history') }}</b-link>     

        </template>
    </div>

</template>
<script>

export default {
    mounted() {
        this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
    },

    data: function () {
        return {
            window_width: window.innerWidth,
            confirmation_requests_route: '/confirmations/requests',
            confirmation_history_route: '/confirmations/history',
        }
    },

    methods: {
        onResize() {
            this.window_width = window.innerWidth;
        },  
    },


}

</script>