<template>
    <b-container fluid>
        <b-row>
            <b-col class="pt-2">
                <div class="px-3">
                    <h4>{{ $t('simple.create_campaign') }}</h4>
                </div>
            </b-col>
        </b-row>
        <b-row class="px-2">
            <b-container fluid>
                <v-wait for="whatsapp_campaign_setup">
                    <loading slot="waiting" />
                    <b-row>
                        <b-col>
                            <ValidationObserver ref="campaign" v-slot="{ invalid }">
                                <b-card>
                                    <template #header>
                                        <span class="font-weight-bold"> {{ $t('simple.campaign_details') }}</span>
                                    </template>
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="2">
                                            <label for="campaign_name" class="font-weight-bold">
                                                {{ $t('simple.campaign_name') }}<span class="text-danger">*</span>:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <ValidationProvider rules="required|alpha_dash|small_letter" v-slot="{ errors, valid }">
                                            <b-form-input id="campaign_name" name="campaign_name"
                                                v-model="campaign_name" :state="valid"></b-form-input>
                                            <small class="text-muted"> {{ $t('macros.template_elementName_desc') }}</small>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="2">
                                            <label for="campaign_account" class="font-weight-bold">
                                                {{ $t('simple.account') }}<span class="text-danger">*</span>:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <treeselect 
                                                v-model="campaign_account"
                                                :options="whatsapp_accounts" 
                                                :multiple="false" 
                                                value-format="object"
                                                :placeholder="$t('simple.account')" 
                                                :normalizer="normalizer"
                                                >
                                                <label slot="option-label" slot-scope="{ node }">
                                                    <font-awesome-icon :style="{ color: node.raw.color }"
                                                        :icon="['fab', node.raw.integration.icon]" />
                                                    {{ node.raw.name }}
                                                </label>
                                                <div slot="value-label" slot-scope="{ node }">
                                                    <font-awesome-icon :style="{ color: node.raw.color }"
                                                        :icon="['fab', node.raw.integration.icon]" />
                                                    <span>{{ node.raw.name }}</span>
                                                </div>
                                            </treeselect>
                                        </b-col>
                                    </b-row>
                                    <template #footer>
                                        <b-row class="align-items-center">
                                            <b-col class="d-flex">
                                                <b-button variant="danger" to="/settings/whatsapp-campaigns">{{ $t('default.cancel') }} </b-button>
                                                <b-button variant="success" class="ml-auto" 
                                                    @click="createCampaign"
                                                    :disabled="!idCampaignValid || invalid">
                                                    {{ $t('simple.create_campaign') }}  & {{ $t('simple.edit') }}
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </template>
                                </b-card>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </v-wait>
            </b-container>
        </b-row>
    </b-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Treeselect from '@riophae/vue-treeselect';
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';


Validator.extend('small_letter', (value) => {
    return /^[a-z0-9_]+$/.test(value)
});

export default {
    components: {
        Treeselect,
        ValidationObserver,
        ValidationProvider,
    },
    async mounted() {
        await this.fetchAccounts()
    },
    
    data() {
        return {

            campaign_name: '',
            campaign_account: null,
        }
    },

    methods: {
        ...mapActions('Accounts', [
            'fetchAccounts',
        ]),
        ...mapActions('Templates', [
            'createWhatsappCampaign',
        ]),


        normalizer: function (node) {
            return {
                id: node.id,
                label: node.name,
                children: node.children,
            }
        },

        async createCampaign() {
            const isValid = await this.$refs.campaign.validate();
            if (!isValid) return
        
            await this.createWhatsappCampaign({id: this.campaign_account.id, name: this.campaign_name })
                .then( (data) => {
                    this.$router.push({ path: `/settings/whatsapp-campaigns/setup/${data.account_id}/${data.id}` });
                })

        },

    },
    computed: {
        ...mapGetters({
            accounts: 'Accounts/getAccounts',
        }),


        whatsapp_accounts() {
            return this.accounts.filter(account => account.integration_id === 'whatsapp')
        },


        idCampaignValid() {
            return this.campaign_name && this.campaign_account
        },
    }
}
</script>