<template>

    <b-form-group>

        <!-- Opacity -->
        <b-form-group :label="$t('pixel.opacity') + ' : ' + config.opacity">

            <b-form-input v-model="config.opacity" type="range" min="0" max="1" step="0.1"  @input="update" />

        </b-form-group>

        <hr>

    </b-form-group>

</template>

<script>

    export default {


        props: ['default_opacity'],

        mounted: function () {
            // Component mounted

            // Let me see config
            //console.log(this.config);
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },
        

        data: function() {

            return  {

                config: {

                    opacity: this.default_opacity,

                },

            }

        },


        methods: {

            // Update config
            update: function(){

                // Config
                //console.log(this.config);

                // Sent to parent
                this.$emit('update', this.config);
            },

        },
        
    }
</script>