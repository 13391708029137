<template functional>
    <!-- Note -->
    <div class="message-container">

        <a-timeline mode="right">
            <a-timeline-item color="green"><strong>{{ props.user.ns }}</strong> posted a note <span v-b-tooltip.hover :title="props.date.created" class="text-primary">{{ props.date.ago }}</span></a-timeline-item>
            <a-timeline-item>
                <div class="alert alert-warning d-inline-block" role="alert">
                    {{ props.message.content }}
                </div>
                <!-- note Attachments -->
                <message-attachments v-if="props.message.attachments" v-bind:attachments="props.message.attachments"></message-attachments>
            </a-timeline-item>
        </a-timeline>

    </div>

</template>

<script>
    export default {

        mounted: function () {
            // Component mounted
            //console.log('Content render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() { return {} },

        computed: {},
        
        methods: {},
        
    }
</script>