<template>

    <div class="row">

        <div class="col pt-2">

            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.macros_categories') }}</h4>

                        <div class="ml-auto align-self-center">

                            <b-button :variant="'primary'" @click="add" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add')}}
                            </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.macros_categories_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-2">


                    <v-wait for="macros_categories">


                        <loading slot="waiting"/>


                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                      <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <b-table stacked="md" responsive bordered hover :items="macros_categories" :fields="macros_categories_fields" :filter="filter" class="vh-25">
                            
                            <!-- Actions -->
                            <template slot="cell(actions)" slot-scope="cell">

                              <b-dropdown right :text="$t('simple.actions')" size="sm">

                                <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>

                                <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>

                              </b-dropdown>

                            </template>

                            <!-- Created at -->
                            <template slot="cell(created_at)" slot-scope="cell">

                                 {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                 
                            </template>

                            <!-- Updated at -->
                            <template slot="cell(updated_at)" slot-scope="cell">

                                 {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                            </template>

                        </b-table>


                    </v-wait>

                </div>
                    

            </div>

        </div>

    </div>

</template>


<script>

    import MacrosCategoryAdd from './Modal/MacrosCategoryAdd.vue';
    import MacrosCategoryEdit from './Modal/MacrosCategoryEdit.vue';
    import MacrosCategoryDelete from './Modal/MacrosCategoryDelete.vue';

    import { create } from 'vue-modal-dialogs'

    export default {

        mounted: function () {
            // Component mounted
            console.log('Macro Categories component mounted.')
        },

        destroyed() {
            // Component destroyed
            console.log('Macro Categories component destroyed.');
        },

        created: function() {
            // Component created
           this.get_macros_categories();
        },
        

        data: function() {
            return  {
                macros_categories_api: '/api/v1/settings/macros/categories/',
                macros_categories: [],
                macros_categories_fields: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true  },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true  },
                    { key: 'created_at', label: this.$t('simple.created'), sortable: true  },
                    { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                filter: null,
            }
        },
        
        metaInfo: function() {

            return {
                title: this.$t('page.macros_categories'),
            }
            
        },

        methods: {

            get_macros_categories: async function() {

                // Start waiting
                this.$wait.start('macros_categories');

                // Send request
                await axios.get(this.macros_categories_api)

                  .then((response) => {
                    
                    // response data
                    this.macros_categories = response.data;

                    //console.log(response);
                    // End waiting
                    this.$wait.end('macros_categories');

                  })

                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    //console.log(error);

                    // End waiting
                    this.$wait.end('macros_categories');

                  });

            },


            add: async function() {
                // Show macros add modal
                const macros_category_add = create(MacrosCategoryAdd);

                if (await macros_category_add() ){
                    // Refresh account list
                    this.get_macros_categories();
                }

            },

            edit: async function(id) {
                // Show macros edit modal
                const macros_category_edit = create(MacrosCategoryEdit, 'id');

                if (await macros_category_edit(id) ){
                    // Refresh macros list
                    this.get_macros_categories();
                }

            },

            
            remove: async function(id) {
                
                // Show macros delete modal
                const macros_category_delete = create(MacrosCategoryDelete, 'id');

                if (await macros_category_delete(id) ){
                    // Refresh macros list
                    this.get_macros_categories();
                }

            },

        },
        
    }
</script>