const state = {
  customer: false,
  customer_id: false,
  customer_types: false,
  customer_genders: false,
  customer_edit: false,
  location: false,
  location_country: false,
  location_country_code: false,
  location_county: false,
  location_state: false,
  location_district: false,
  location_zip: false,
  customer_dm_status: false,
};

// getters
const getters = {
  
  // Get customer
  getCustomer: (state, getters) => {
    // Return customer
    return state.customer;
  },

  // Get customer id
  getCustomerID: (state, getters) => {
    // Return customer id
    return state.customer_id;
  },

  // Get customer
  getCustomerGenders: (state, getters) => {
    // Return customer
    return state.customer_genders;
  },

  // Get customer id
  getCustomerTypes: (state, getters) => {
    // Return customer id
    return state.customer_types;
  },

  getCustomerProfile: (state, getters) => {

    if(state.customer){

      if(state.customer.twitter){

        return 'https://twitter.com/' + state.customer.twitter.screen_name;

      }

    } else {

      return false;

    }

  },

  // Get customer edit
  getCustomerEdit: (state, getters) => {
    // Return customer
    return state.customer_edit;
  },


  // Get customer edit
  getCustomerIdentification: (state, getters) => {
    // Return customer identification
    return state.customer.identification;
  },

  // Get customer name
  getCustomerUsername: (state, getters) => {
    // Return customer name
    return state.customer.username;
  },

  // Get customer name
  getCustomerName: (state, getters) => {
    // Return customer name
    return state.customer.name;
  },

  // Get customer surname
  getCustomerSurname: (state, getters) => {
    // Return customer surname
    return state.customer.surname;
  },

  // Get customer phone
  getCustomerPhone: (state, getters) => {
    // Return customer phone
    return state.customer.phone;
  },

  // Get customer phone
  getCustomerEmail: (state, getters) => {
    // Return customer email
    return state.customer.email;
  },

  // Get customer location
  getCustomerLocation: (state, getters) => {
    // Return customer location
    return state.location;
  },

  // Get customer country
  getCustomerCountry: (state, getters) => {
    // Return customer country
    return state.location_country;
  },

  // Get customer country code
  getCustomerCountryCode: (state, getters) => {
    // Return customer country_code
    return state.location_country_code;
  },

  // Get customer state
  getCustomerState: (state, getters) => {
    // Return customer state
    return state.location_state;
  },

  // Get customer postal_code
  getCustomerPostalCode: (state, getters) => {
    // Return customer postal_code
    return state.location_zip;
  },

  // Get customer county
  getCustomerCounty: (state, getters) => {
    // Return customer county
    return state.location_county;
  },

  // Get customer district
  getCustomerDistrict: (state, getters) => {
    // Return customer district
    return state.location_district;
  },

  // Get customer gender
  getCustomerGender: (state, getters) => {

    if(state.customer_genders){
      // Return customer gender
      return state.customer_genders.find(gender => gender.id === state.customer.customer_gender_id);

    } else {
      // Return false
      return false;

    }
  },

  // Get customer type id
  getCustomerType: (state, getters) => {

    if(state.customer_types){
      // Return customer type
      return state.customer_types.find(gender => gender.id === state.customer.customer_type_id);
    } else {
      // Return false
      return false;
    }
  },

  // Get customer DM Status
  getCustomerDmStatus: (state, getters) => {
    // Return customer DM Status
    return state.customer_dm_status;
  },

}

// mutations
const mutations = {
  
  // Set customer id
  setCustomerID (state, customer_id) {
    state.customer_id = customer_id;
  },

  // Set customer
  setCustomerData (state, customer) {
    state.customer = customer;
  },

  // Set customer genders
  setCustomerGenders(state, customer_genders){
    state.customer_genders = customer_genders;
  },

  // Set customer types
  setCustomerTypes(state, customer_types){
    state.customer_types = customer_types;
  },

  // Set customer edit
  setCustomerEdit (state, customer_edit) {
    state.customer_edit = customer_edit;
  },

  // Set customer identification
  setCustomerIdentification (state, customer_identification) {
    state.customer.identification = customer_identification;
  },

  // Set customer name
  setCustomerName (state, customer_name) {
    state.customer.name = customer_name;
  },

  // Set customer surname
  setCustomerSurname (state, customer_surname) {
    state.customer.surname = customer_surname;
  },

  // Set customer phone
  setCustomerPhone (state, customer_phone) {
    state.customer.phone = customer_phone;
  },

  // Set customer email
  setCustomerEmail (state, customer_email) {
    state.customer.email = customer_email;
  },

  // Set customer location ID
  setCustomerLocationID (state, customer_location_id) {
    state.customer.location_id = customer_location_id;
  },

  // Set customer location
  setCustomerLocation (state, customer_location) {
      state.location = customer_location;

  },

  // Set customer country
  setCustomerCountry (state, customer_country) {
    state.location_country = customer_country;
  },

  // Set customer country code
  setCustomerCountryCode (state, customer_country_code) {
      state.location_country_code = customer_country_code;
  },

  // Set customer state
  setCustomerState (state, customer_state) {
    state.location_state = customer_state;
  },

  // Set customer postal code
  setCustomerPostalCode (state, customer_postal_code) {
    state.location_zip = customer_postal_code;
  },

  // Set customer county
  setCustomerCounty (state, customer_county) {
    state.location_county = customer_county;
  },

  // Set customer district
  setCustomerDistrict (state, customer_district) {
    state.location_district = customer_district;
  },

  // Set customer type id
  setCustomerTypeID (state, customer_type_id) {
    state.customer.customer_type_id = customer_type_id;
  },

  // Set customer gender id
  setCustomerGenderID (state, customer_gender_id) {
    state.customer.customer_gender_id = customer_gender_id;
  },

  // Set customer DM Status
  setCustomerDmStatus (state, customer_dm_status) {
    state.customer_dm_status = customer_dm_status;
  },


}

// actions
const actions = {

  updateCustomerRefresh({ dispatch, state }) {

    // Update ticket
    dispatch("updateCustomer");

    // Refresh messages
    dispatch("Messages/refreshMessages", state.customer_id, { root: true });
  },

  // update customer
   async updateCustomer({ dispatch, commit, state}){

    // Start waiting
    dispatch('wait/start', 'customer', { root: true });

    let customer_api = '/api/v1/inbox/customer/';

    // Get Ticket
    await axios.put(customer_api + state.customer.id, {

        identification: state.customer.identification,
        name: state.customer.name,
        surname: state.customer.surname,
        phone: state.customer.phone,
        email: state.customer.email,
        location_id: state.customer.location_id ?? false,
        customer_gender_id: state.customer.customer_gender_id,
        customer_type_id: state.customer.customer_type_id,

      })

      .then((response) => {

        // Stop waiting
        dispatch('wait/end', 'customer', { root: true });

        // Show success
        Vue.toasted.success(Vue.i18n.translate('simple.success_info'));



        // Return back to customer info
        dispatch('fetchCustomer', state.customer.id);
        dispatch('updateCustomerEdit', false);


      })
      .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'customer', { root: true });

      });

    // Stop waiting
    dispatch('wait/end', 'customer', { root: true });

  },

  // Assign Customer to User
   async assignCustomer({ dispatch, commit, state}, status){

    let customer_api = '/api/v1/inbox/customer/assign/';

    // Get Ticket
    await axios.post(customer_api + status, {

        customer_id: state.customer_id,

      })

      .then((response) => {

        console.log(response.data[0]);
        // Return back to customer info
        dispatch('Inbox/updateCustomerAssign', response.data[0], {root:true});


      })
      .catch((error) => {

          // Log to console
          console.log(error);


      });

  },

  // update customer location
  async updateLocation({ dispatch, commit, state}){

    // Start waiting
    dispatch('wait/start', 'customer', { root: true });

    let customer_api = '/api/v1/inbox/customer/location/update/';

    // Get Ticket
    await axios.put(customer_api + state.customer.location_id, {

      location: state.location,
      country: state.location_country,
      country_code: state.location_country_code,
      state: state.location_state,
      postal_code: state.location_zip,
      county: state.location_county,
      district: state.location_district,


      })

      .then((response) => {

        // Stop waiting
        dispatch('wait/end', 'customer', { root: true });


        // Return back to customer info
        dispatch('updateCustomerEdit', false);


      })
      .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'customer', { root: true });

      });

    // Stop waiting
    dispatch('wait/end', 'customer', { root: true });

  },

  // update customer location
  async createCustomerLocation({ dispatch, commit, state}){

    // Start waiting
    dispatch('wait/start', 'customer', { root: true });

    let customer_api = '/api/v1/inbox/customer/location/create';

    // Get Ticket
    await axios.post(customer_api , {

        location: state.location,
        country: state.location_country,
        country_code: state.location_country_code,
        state: state.location_state,
        postal_code: state.location_zip,
        county: state.location_county,
        district: state.location_district,


      })

      .then((response) => {

        // Stop waiting
        dispatch('wait/end', 'customer', { root: true });

        if(response.data.success !== false) {
          // Get customer location ID
          commit('setCustomerLocationID', response.data);
        }


      })
      .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'customer', { root: true });

      });

    // Stop waiting
    dispatch('wait/end', 'customer', { root: true });

  },

  // get customer
  async fetchCustomer({ dispatch, commit }, customer_id){

    // Start waiting
    dispatch('wait/start', 'customer', { root: true });

    let customer_api = '/api/v1/inbox/customer';

    // Reset Customer
    dispatch('resetCustomer');

    // Get Ticket
    await axios.get(customer_api + '?customer_id=' + customer_id)
      .then((response) => {

        // Set customer data
        commit('setCustomerData', response.data);

        // Set customer id
        commit('setCustomerID', customer_id);

        if(response.data.location) {

          // Set customer location
          commit('setCustomerLocation', response.data.location.formatted_address);

          // Set customer Country
          commit('setCustomerCountry', response.data.location.country);

          // Set customer Country Code
          commit('setCustomerCountryCode', response.data.location.country_code);

          // Set customer County
          commit('setCustomerCounty', response.data.location.county);

          // Set customer State
          commit('setCustomerState', response.data.location.state);

          // Set customer Postal Code
          commit('setCustomerPostalCode', response.data.location.zip);

          // Set customer District
          commit('setCustomerDistrict', response.data.location.district);

        }

        // Stop waiting
        dispatch('wait/end', 'customer', { root: true });

      })
      .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'customer', { root: true });

      });

  },

  // Customer Refresh
  async refreshCustomer({ dispatch, commit, state },data){
    dispatch('wait/start', 'customer', { root: true });

    // Start waiting
    let refresh_api = '/api/v1/inbox/customer/refresh';

    let account_id = data.account_id;

    let customer_id = data.customer_id;


    // Post Note
    await axios.post(refresh_api, {

      account_id: account_id,

      customer_id: customer_id,

    })

        .then((response) => {

          console.log('Customer refresh response goes here');
          console.log(response);

          // Stop waiting
          dispatch('wait/end', 'customer', { root: true });


        })
        .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'customer', { root: true });

        });

    // Stop waiting
    dispatch('wait/end', 'customer', { root: true });

  },

  // Customer DM Status
  async checkCustomerDmStatus({ dispatch, commit, state },data){
    dispatch('wait/start', 'customer-dm-status', { root: true });

    // Start waiting
    let customer_dm_status_api = '/api/v1/inbox/customer/dmStatus';

    // Post Note
    await axios.get(customer_dm_status_api + '?customer_id=' + data)

        .then((response) => {

          commit('setCustomerDmStatus', response.data.status);

          console.log('Customer DM Status goes here');
          console.log(response);

          // Stop waiting
          dispatch('wait/end', 'customer-dm-status', { root: true });


        })
        .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'customer-dm-status', { root: true });

        });

    // Stop waiting
    dispatch('wait/end', 'customer-dm-status', { root: true });

  },

  // Set customer id
  async setCustomer({ dispatch, commit, state }, customer_id){

    // Check if don't have customer genders
    if(!state.customer_genders){

      await dispatch('getCustomerGenders');

    }

    // Check if don't have customer types
    if(!state.customer_types){

      await dispatch('getCustomerTypes');

    }

    // Fetch customer
    await dispatch('fetchCustomer', customer_id);

    // Dispatch to inbox
    dispatch('Inbox/updateActive', 'customer', {root:true} );

  },

  // Get customer gender
  async getCustomerGenders({ dispatch, commit, state }){

    let customer_genders_api = '/api/v1/system/customer/genders';

    // Get customer types
    await axios.get(customer_genders_api)
      .then((response) => {

        // Set customer types
        commit('setCustomerGenders', response.data);

      })
      
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
                    
        // Log to console
        console.log(error);


      });

  },

  // Get customer types
  async getCustomerTypes({ dispatch, commit, state }){

    let customer_types_api = '/api/v1/system/customer/types';

    // Get customer types
    await axios.get(customer_types_api)
      .then((response) => {

        // Set customer types
        commit('setCustomerTypes', response.data);

      })
      
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
                    
        // Log to console
        console.log(error);


      });

  },

  // Get customer types
  async UpdateCustomerTypes({ commit }){

    let customer_types_api = '/api/v1/system/customer/types';

    // Get customer types
    await axios.get(customer_types_api)
        .then((response) => {

          // Set customer types
          commit('setCustomerTypes', response.data);

        })

        .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);


        });

  },

  // Set customer edit
  async updateCustomerEdit ({ dispatch, commit, state }, customer_edit) {

    // Refetch customer
   // dispatch('fetchCustomer', state.customer.id);

    commit('setCustomerEdit', customer_edit);
  },


  // Set customer identification
  async updateCustomerIdentification ({commit}, customer_identification) {
    commit('setCustomerIdentification', customer_identification);
  },

  // Set customer name
  async updateCustomerName ({commit}, customer_name) {
    commit('setCustomerName', customer_name);
  },

  // Set customer surname
  async updateCustomerSurname ({commit}, customer_surname) {
    commit('setCustomerSurname', customer_surname);
  },

  // Set customer phone
  async updateCustomerPhone ({commit}, customer_phone) {
    commit('setCustomerPhone', customer_phone);
  },

  // Set customer email
  async updateCustomerEmail ({commit}, customer_email) {
    commit('setCustomerEmail', customer_email);
  },

  // Set customer location
  async updateCustomerLocation ({commit}, customer_location) {
    commit('setCustomerLocation', customer_location);
  },

  // Set customer country
  async updateCustomerCountry ({commit}, customer_country) {
    commit('setCustomerCountry', customer_country);
  },

  // Set customer country code
  async updateCustomerCountryCode ({commit}, customer_country_code) {
    commit('setCustomerCountryCode', customer_country_code);
  },

  // Set customer state
  async updateCustomerState ({commit}, customer_state) {
    commit('setCustomerState', customer_state);
  },

  // Set customer postal code
  async updateCustomerPostalCode ({commit}, customer_postal_code) {
    commit('setCustomerPostalCode', customer_postal_code);
  },

  // Set customer county
  async updateCustomerCounty ({commit}, customer_county) {
    commit('setCustomerCounty', customer_county);
  },

  // Set customer district
  async updateCustomerDistrict ({commit}, customer_district) {
    commit('setCustomerDistrict', customer_district);
  },

  // Set customer type id
  async updateCustomerTypeID ({commit}, customer_type) {
    const id = customer_type ? customer_type.id : null
    commit('setCustomerTypeID', id);
  },

  // Set customer gender
  async updateCustomerGenderID ({commit}, customer_gender) {
    const id = customer_gender ? customer_gender.id : null
    commit('setCustomerGenderID', id);
  },

  // Update User Assign Data On Customer
  updateCustomerAssignData({ dispatch, state, commit}, notif) {

    if(state.customer && state.customer.id === notif['customer']['id']){
      Object.assign(state.customer, {'user_assigned': notif['user_id'], 'assigned_at': notif['assigned_at'] ? notif['assigned_at'].date : null, 'user_assign':notif['customer']['user_assign']});
      console.log(state.customer);
    }

    // const index = state.customers.indexOf(notif['customer']['id']);
    //
    // if (index !== -1) {
    //   state.customer[index] = notif['customer'];
    // }

  },

  // Reset Customer
  async resetCustomer ({commit}) {
    // Clear Customer Data
    commit('setCustomerData', []);
    // Clear Customer ID
    commit('setCustomerID', false);
    },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}