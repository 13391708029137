<template>

    <span>
            <!-- Check if Ticket has location -->
            <template>
                <font-awesome-icon id="location" size="xs" :icon="'map-marker-alt'" class="plus__icon"/>
                <b-tooltip v-if="location" target="location">

                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.address') }}: </strong>{{ location.formatted_address }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.country') }}: </strong>{{ location.country }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.country_code') }}: </strong>{{ location.country_code }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.county') }}: </strong>{{ location.county }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.district') }}: </strong>{{ location.district }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.postal_code') }}: </strong>{{ location.zip }}</p>

                </b-tooltip>
                <b-tooltip v-else target="location">
                <span>{{ $t('customer.no_location') }}</span>
            </b-tooltip>

            </template>

    </span>

</template>

<script>

    export default {

        props: ['location'],

        mounted: function () {
            // Component mounted
            //console.log('Ticket Location Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Ticket Location mounted destroyed.');
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>