<template>
    <b-row>

        <div class="col pt-2">

            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <div>
                            <h4>{{ $t('macros.template_messages') }}</h4>
                            <div class="text-muted template-header-info"> {{ $t('macros.template_messages_desc') }}</div>
                        </div>

                        <div class="ml-auto align-self-center d-flex">

                            <b-button :variant="'info'" class="btn-sm mr-2 px-3" :to="{
                                name: 'template_message_setup'
                            }">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add') }}
                            </b-button>

                            <b-button :variant="'outline-info'" @click="refreshTemplateMessages({whatsapp_accounts: whatsapp_accounts})"
                                class="btn-sm">
                                <font-awesome-icon :icon="'sync'" />
                                {{ $t('default.refresh') }}
                            </b-button>

                        </div>

                    </div>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-2">
                        <b-row>
                            <b-col>
                                <b-pagination v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    hide-goto-end-buttons
                                    size="sm" class="my-0"></b-pagination>
                            </b-col>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                        <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        
                        <b-table stacked="lg" responsive bordered hover  
                            :current-page="currentPage"
                            :per-page="perPage"
                            :items="template_messages"
                            :fields="window_width >= 1200 ? template_message_fields : template_message_fields_collapsed"
                            :filter="filter"
                            @filtered="onFiltered">

                            <!-- Actions -->
                            <template slot="cell(actions)" slot-scope="cell">

                                <b-button size="sm" @click="cell.toggleDetails()" v-if="window_width < 1200">
                                    {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
                                </b-button>

                                <b-dropdown right :text="$t('simple.actions')" size="sm"
                                    v-if="cell.item.can_update || cell.item.can_delete">

                                    <b-dropdown-item v-if="cell.item.can_update" @click="templateMessageEdit(cell.item)">{{
                                        $t('simple.edit') }}</b-dropdown-item>

                                    <b-dropdown-item v-if="cell.item.can_delete"
                                        @click="deleteTemplate(cell.item.account_id, cell.item.id)">{{
                                            $t('simple.delete') }}</b-dropdown-item>

                                </b-dropdown>

                            </template>

                            <template #row-details="row">
                                <b-table small stacked="md" class="d-xl-none" responsive bordered hover :items="[row.item]"
                                    :fields="template_message_fields_not_collapsed">
                                    <!-- Created at -->
                                    <template slot="cell(created_at)" slot-scope="cell">

                                        {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm') }}

                                    </template>

                                    <!-- Updated at -->
                                    <template slot="cell(updated_at)" slot-scope="cell">

                                        {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm') }}

                                    </template>

                                </b-table>
                            </template>

                            <!-- Created at -->
                            <template slot="cell(created_at)" slot-scope="cell">

                                {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm') }}

                            </template>
                            <!-- Updated at -->
                            <template slot="cell(updated_at)" slot-scope="cell">

                                {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm') }}

                            </template>

                            <!-- Account -->
                            <template slot="cell(account_name)" slot-scope="cell">
                                <span v-b-tooltip.hover :title="cell.item.account_username">{{ cell.item.account_name }}
                                    <font-awesome-icon :icon="'question-circle'" size="sm" /></span>
                            </template>

                            <template slot="cell(content)" slot-scope="cell">
                                <span class="font-weight-bold" v-if="cell.item.header">{{ cell.item.header }} | </span>{{ cell.item.content }}
                            </template>

                            <!-- Buttons  -->
                            <template slot="cell(buttons)" slot-scope="cell">
                                <span 
                                    class="d-inline-block text-truncate"
                                    :style="{ maxWidth: '150px' }" 
                                    v-b-tooltip.hover 
                                    :title="template_button_normalize(cell.item.buttons)">
                                    {{ template_button_normalize(cell.item.buttons) }}
                                </span>
                            </template>

                            <!-- Status -->
                            <template slot="cell(status)" slot-scope="cell">
                                <span v-b-tooltip.hover :title="cell.item.reject_reason ? cell.item.reject_reason : ''"
                                    :class="{ 'text-danger': cell.item.status === 'rejected', 'text-warning': cell.item.status === 'pending', 'text-success': cell.item.status === 'approved' }">
                                    {{ cell.item.status.toUpperCase() }}
                                    <font-awesome-icon v-if="cell.item.reject_reason" :icon="'exclamation-circle'"
                                        size="sm" /> </span>

                            </template>


                        </b-table>

                        <v-wait for="template_messages">

                            <loading slot="waiting" />

                        </v-wait>

                </div>


            </div>

        </div>

    </b-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TemplateMessageEdit from '../Modal/TemplateMessageEdit.vue'
import { create } from 'vue-modal-dialogs'




export default {
    metaInfo: function () {
        return {
            title: 'Templates Messages',
        }
    },

    async created() {
        await this.fetchAccounts()
        await this.fetchTemplateMessages({whatsapp_accounts:this.whatsapp_accounts})

        this.totalRows = this.template_messages.length
        
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })

    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    data() {
        return {
            window_width: window.innerWidth,
            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,

            template_message_fields: [
                { key: 'account_name', label: this.$t('simple.account'), sortable: true },
                { key: 'name', label: this.$t('macros.template_name'), sortable: true },
                { key: 'content', label: this.$t('simple.content'), sortable: true, thStyle: { width: "20%" }, },
                { key: 'buttons', label: this.$t('macros.buttons') },
                { key: 'category', label: this.$t('default.category'), sortable: true },
                { key: 'template_type', label: this.$t('macros.template_type'), sortable: true },
                { key: 'status', label: this.$t('simple.status'), sortable: true },
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                { key: 'actions', label: this.$t('simple.actions') },
            ],

            template_message_fields_collapsed: [
                { key: 'account_name', label: this.$t('simple.account'), sortable: true },
                { key: 'name', label: this.$t('macros.template_name'), sortable: true },
                { key: 'content', label: this.$t('simple.content'), sortable: true, thStyle: { width: "20%" }, },
                { key: 'buttons', label: this.$t('macros.buttons'), thStyle: { 'max-width': "10%" }, },
                { key: 'status', label: this.$t('simple.status'), sortable: true },
                { key: 'actions', label: this.$t('simple.actions') },
            ],

            template_message_fields_not_collapsed: [
                { key: 'category', label: this.$t('default.category'), sortable: true },
                { key: 'template_type', label: this.$t('macros.template_type'), sortable: true },
                { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
            ],

        }
    },

    methods: {
        ...mapActions('Templates', [
            'fetchTemplateMessages',
            'deleteTemplateMessage',
            'refreshTemplateMessages',
            'updateCurrentPage'
        ]),
        ...mapActions('Accounts', [
            'fetchAccounts',
        ]),

        async templateMessageEdit(template) {
            // Show account edit modal
            const template_message_edit = create(TemplateMessageEdit, 'template');

            await template_message_edit(template)
        },

        async deleteTemplate(account_id, template_id) {
            await this.deleteTemplateMessage({ account_id: account_id, template_id: template_id })
                .then(() => {
                    this.refreshTemplateMessages({whatsapp_accounts: this.whatsapp_accounts})
                })
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        onResize() {
            this.window_width = window.innerWidth;
        },

        template_button_normalize(buttons) {
            if(buttons) {
                return buttons.flatMap(button => {
                    if( button.type === 'QUICK_REPLY') {
                        return `${button.type}(${button.text}) `
                    }
                    return `${button.type}(${button.phone_number}) `
                })
            }
            return '-'
        }
    },

    computed: {
        ...mapGetters({
            accounts: 'Accounts/getAccounts',
            template_messages: 'Templates/getTemplateMessages',
            // total: 'Templates/getTotal',
            // getCurrent: 'Templates/getCurrent',
            // perpage: 'Templates/getPerPage',
        }),

        current: {
            get() {
                return this.getCurrent
            },
            set(value) {
                this.updateCurrentPage(value)
                this.fetchTemplateMessages({whatsapp_accounts:this.whatsapp_accounts, page:value})
            }
        },

        whatsapp_accounts() {
            return this.accounts.filter(account => account.integration_id === 'whatsapp')
        },


    },
}
</script>