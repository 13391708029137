<template>
    <b-row>
        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover :title="title">{{ label }}</b-col>
        <b-col sm="12" md="5">
            <div>
                <b-input-group>
                    <b-input :type="showPasswordCheck ? 'text' : 'password'" :value="value" @input="handlePasswordChange"></b-input>
                    <b-input-group-append>
                        <b-button @click="showPasswordCheck = !showPasswordCheck" size="sm" variant="outline-dark">
                            <font-awesome-icon v-if="showPasswordCheck" :icon="'eye-slash'" />
                            <font-awesome-icon v-else :icon="'eye'" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: ['label', 'value', 'title'],

    data() {
        return {
            showPasswordCheck: false
        }
    },

    methods: {
        handlePasswordChange(e) {
            this.$emit('handlePasswordChange', e);
        }
    }
}
</script>