<template>
    <transition appear name="v-editor-fade">
        
        <div class="fullscreen-v-editor">

            <template v-if="windowWidth >= 768">
                <b-navbar toggleable="md" variant="light">
                    <!-- Navbar brand -->
                    <b-navbar-brand><font-awesome-icon :icon="'plus'" class="text-danger"/>  Pixel </b-navbar-brand>

                    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

                    <b-collapse is-nav id="nav_collapse">

                        <b-navbar-nav class="ml-auto d-none d-md-flex">

                            <!-- Layer up -->
                            <b-nav-item @click="action_move('up')"><font-awesome-icon :icon="'arrow-up'"/></b-nav-item>

                            <!-- Layer down -->
                            <b-nav-item @click="action_move('down')"><font-awesome-icon :icon="'arrow-down'" /></b-nav-item>

                            <!-- Align left -->
                            <b-nav-item @click="action_align('left')"><font-awesome-icon :icon="'align-left'" /></b-nav-item>

                            <!-- Align center -->
                            <b-nav-item @click="action_align('center')"><font-awesome-icon :icon="'align-center'" /></b-nav-item>

                            <!-- Align right -->
                            <b-nav-item  @click="action_align('right')"><font-awesome-icon :icon="'align-right'" /></b-nav-item>

                            <!-- Align top -->
                            <b-nav-item @click="action_align('top')"><font-awesome-icon :icon="'sort-up'" /></b-nav-item>

                            <!-- Align bottom -->
                            <b-nav-item @click="action_align('bottom')"><font-awesome-icon :icon="'sort-down'" /></b-nav-item>

                            <!-- Clone -->
                            <!--<b-nav-item @click="action_manage('copy')"><font-awesome-icon :icon="'clone'" /></b-nav-item>-->

                            <!-- Delete -->
                            <b-nav-item @click="action_manage('delete')"><font-awesome-icon :icon="'trash'" /></b-nav-item>

                        </b-navbar-nav>

                        <!-- Right aligned nav items -->
                        <b-navbar-nav class="ml-auto ">

                            <b-button size="sm" @click="save" variant="outline-primary" class="mb-1"><font-awesome-icon :icon="'check'" /> {{ $t('default.save') }}</b-button>

                            <!--<b-button size="sm" @click="load_json" variant="outline-success"><font-awesome-icon :icon="'sync'" /> Load back</b-button>-->

                            <b-button size="sm" @click="image_view(attachment)"  class="mb-1" variant="outline-success"><font-awesome-icon :icon="'images'" /> {{ $t('default.preview') }}</b-button>

                            <b-button size="sm" @click="$close" variant="outline-danger"  class="mb-1"><font-awesome-icon :icon="'times'" /> {{ $t('default.close') }}</b-button>

                        </b-navbar-nav>

                    </b-collapse>
                </b-navbar>

                <div class="container-fluid mt-2">
                    <b-row class="justify-content-center align-items-center d-md-none mx-1">
                         <!-- Layer up -->
                         <b-button  @click="action_move('up')" variant="outline-dark" class="mt-1"><font-awesome-icon :icon="'arrow-up'" size="sm"/></b-button>

                        <!-- Layer down -->
                        <b-button  @click="action_move('down')" variant="outline-dark" class="ml-1 mt-1"><font-awesome-icon :icon="'arrow-down'" size="sm" /></b-button>

                        <!-- Align left -->
                        <b-button  @click="action_align('left')" variant="outline-dark" class="ml-1 mt-1"><font-awesome-icon :icon="'align-left'" size="sm" /></b-button>

                        <!-- Align center -->
                        <b-button  @click="action_align('center')" variant="outline-dark" class="ml-1 mt-1"><font-awesome-icon :icon="'align-center'" size="sm" /></b-button>

                        <!-- Align right -->
                        <b-button   @click="action_align('right')" variant="outline-dark" class="ml-1 mt-1"><font-awesome-icon :icon="'align-right'" size="sm" /></b-button>

                        <div class="d-flex justify-content-center align-items-center mt-1">
                            <!-- Align top -->
                            <b-button  @click="action_align('top')" variant="outline-dark" class="ml-1"><font-awesome-icon :icon="'sort-up'" size="sm" /></b-button>

                            <!-- Align bottom -->
                            <b-button  @click="action_align('bottom')" variant="outline-dark" class="ml-1"><font-awesome-icon :icon="'sort-down'"  size="sm"/></b-button>

                            <!-- Delete -->
                            <b-button  @click="action_manage('delete')" variant="outline-dark" class="ml-1"><font-awesome-icon :icon="'trash'"  size="sm"/></b-button>
                        </div>
                    </b-row>
                    <hr class="d-md-none">

                    <b-row>

                        <div class="col-lg-3 pixel-tools-sticky">

                            <b-card no-body :header="$t('pixel.tools')"  ref="panel" class="pixel-tools-panel d-none d-lg-block" >
                            <b-list-group flush>

                                <!-- Add -->
                                <b-list-group-item href="#" v-b-toggle.add class="text-dark">
                                    <font-awesome-icon :icon="'plus'" /> {{ $t('default.add') }}
                                </b-list-group-item>

                                <!-- Add collapsed -->
                                <b-collapse id="add">

                                    <b-form class="m-3">

                                        <!-- Add -->
                                        <b-form-group>

                                            <!--&lt;!&ndash; Text &ndash;&gt;-->
                                            <!--<b-button variant="outline-primary" size="sm" @click="add('text')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.text') }} </b-button>-->

                                            <!--&lt;!&ndash; IText &ndash;&gt;-->
                                            <!--<b-button variant="outline-primary" size="sm" @click="add('itext')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.itext') }} </b-button>-->

                                            <!-- Text -->
                                            <b-button variant="outline-primary" size="sm" @click="add('textbox')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.text') }} </b-button>

                                            <!-- Rectangle -->
                                            <b-button variant="outline-primary" size="sm" @click="add('rectangle')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.rectangle') }} </b-button>

                                            <!-- Triangle -->
                                            <b-button variant="outline-primary" size="sm" @click="add('triangle')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.triangle') }} </b-button>

                                            <!-- Line -->
                                            <b-button variant="outline-primary" size="sm" @click="add('line')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.line') }} </b-button>

                                            <!-- Draw -->
                                            <b-button :variant="is_draw ? 'outline-danger' : 'outline-primary'" size="sm" @click="update_draw('mode')" class="m-1"><font-awesome-icon :icon="is_draw ? 'times' : 'plus'" /> {{ $t('pixel.draw') }} </b-button>

                                            <!--&lt;!&ndash; Polygon &ndash;&gt;-->
                                            <!--<b-button variant="outline-primary" size="sm" @click="add('polygon')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.polygon') }} </b-button>-->


                                        </b-form-group>

                                    </b-form>

                                </b-collapse>


                                <!-- Properties -->
                                <b-list-group-item href="#" v-b-toggle.properties class="text-dark">
                                    <font-awesome-icon :icon="'wrench'" /> {{ $t('pixel.properties') }}
                                </b-list-group-item>

                                <!-- Properties collapsed -->
                                <b-collapse id="properties">

                                    <b-form class="m-3">


                                        <!-- Check if we have selected -->
                                        <template v-if="selected">

                                            <template v-if="selected.type === 'text' || selected.type === 'i-text' || selected.type === 'textbox'">

                                                <pixel-text
                                                        v-bind:key="'text-' + selected.id"
                                                        v-bind:default="selected"
                                                        v-on:update="update"
                                                >
                                                </pixel-text>

                                            </template>

                                            <!-- Draw Config -->
                                            <pixel-draw
                                                    v-if="is_draw"
                                                    v-bind:width="getDrawingLineWidth"
                                                    v-bind:color="getDrawingLineColor"
                                                    v-on:update-draw="update_draw"
                                            ></pixel-draw>


                                        </template>

                                    </b-form>

                                </b-collapse>




                                <!-- Images -->
                                <b-list-group-item href="#" v-b-toggle.images class="text-dark">
                                    <font-awesome-icon :icon="'images'" /> {{ $t('pixel.images') }}
                                </b-list-group-item>

                                <!-- Images collapsed -->
                                <b-collapse id="images">

                                    <!-- Image -->
                                    <pixel-image

                                            v-on:image-add="add"

                                    ></pixel-image>

                                </b-collapse>

                                <!-- Filters -->
                                <b-list-group-item href="#" v-b-toggle.filters class="text-dark">
                                    <font-awesome-icon :icon="'sliders-h'" /> {{ $t('pixel.filters') }}
                                </b-list-group-item>

                                <!-- Filters collapsed -->
                                <b-collapse id="filters">

                                    <template v-if="selected">

                                        <b-form class="m-3">

                                            <!-- Stroke -->
                                            <pixel-stroke
                                                    v-bind:key="'stroke-' + selected.id"
                                                    v-bind:default_stroke="selected.stroke"
                                                    v-bind:default_width="selected.strokeWidth"
                                                    v-on:update="update"
                                            ></pixel-stroke>

                                            <!-- Opacity -->
                                            <pixel-opacity
                                                    v-bind:key="'opacity-' + selected.id"
                                                    v-bind:default_opacity="selected.opacity"
                                                    v-on:update="update"
                                            ></pixel-opacity>

                                            <!-- Shadow -->
                                            <pixel-shadow
                                                    v-bind:key="'shadow-' + selected.id"
                                                    v-bind:default_status="selected.shadow"
                                                    v-on:update="update"
                                            ></pixel-shadow>

                                        </b-form>

                                    </template>

                                </b-collapse>


                            </b-list-group>
                            </b-card>

                            <b-card no-body ref="panel" class="pixel-tools-panel d-lg-none mb-2">
                            <b-card-header @click="tools_collapsed = !tools_collapsed">
                                {{ $t('pixel.tools') }}
                                <template v-if="tools_collapsed">
                                    <font-awesome-icon :icon="'caret-down'" size="sm"/>
                                </template>
                                <template v-else>
                                    <font-awesome-icon :icon="'caret-up'" size="sm"/>
                                </template>
                            </b-card-header>
                            <template v-if="!tools_collapsed">
                                <b-list-group flush>

                                    <!-- Add -->
                                    <b-list-group-item href="#" v-b-toggle.add class="text-dark">
                                        <font-awesome-icon :icon="'plus'" /> {{ $t('default.add') }}
                                    </b-list-group-item>

                                    <!-- Add collapsed -->
                                    <b-collapse id="add">

                                        <b-form class="m-3">

                                            <!-- Add -->
                                            <b-form-group>

                                                <!--&lt;!&ndash; Text &ndash;&gt;-->
                                                <!--<b-button variant="outline-primary" size="sm" @click="add('text')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.text') }} </b-button>-->

                                                <!--&lt;!&ndash; IText &ndash;&gt;-->
                                                <!--<b-button variant="outline-primary" size="sm" @click="add('itext')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.itext') }} </b-button>-->

                                                <!-- Text -->
                                                <b-button variant="outline-primary" size="sm" @click="add('textbox')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.text') }} </b-button>

                                                <!-- Rectangle -->
                                                <b-button variant="outline-primary" size="sm" @click="add('rectangle')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.rectangle') }} </b-button>

                                                <!-- Triangle -->
                                                <b-button variant="outline-primary" size="sm" @click="add('triangle')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.triangle') }} </b-button>

                                                <!-- Line -->
                                                <b-button variant="outline-primary" size="sm" @click="add('line')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.line') }} </b-button>

                                                <!-- Draw -->
                                                <b-button :variant="is_draw ? 'outline-danger' : 'outline-primary'" size="sm" @click="update_draw('mode')" class="m-1"><font-awesome-icon :icon="is_draw ? 'times' : 'plus'" /> {{ $t('pixel.draw') }} </b-button>

                                                <!--&lt;!&ndash; Polygon &ndash;&gt;-->
                                                <!--<b-button variant="outline-primary" size="sm" @click="add('polygon')" class="m-1"><font-awesome-icon :icon="'plus'" /> {{ $t('pixel.polygon') }} </b-button>-->


                                            </b-form-group>

                                        </b-form>

                                    </b-collapse>


                                    <!-- Properties -->
                                    <b-list-group-item href="#" v-b-toggle.properties class="text-dark">
                                        <font-awesome-icon :icon="'wrench'" /> {{ $t('pixel.properties') }}
                                    </b-list-group-item>

                                    <!-- Properties collapsed -->
                                    <b-collapse id="properties">

                                        <b-form class="m-3">


                                            <!-- Check if we have selected -->
                                            <template v-if="selected">

                                                <template v-if="selected.type === 'text' || selected.type === 'i-text' || selected.type === 'textbox'">

                                                    <pixel-text
                                                            v-bind:key="'text-' + selected.id"
                                                            v-bind:default="selected"
                                                            v-on:update="update"
                                                    >
                                                    </pixel-text>

                                                </template>

                                                <!-- Draw Config -->
                                                <pixel-draw
                                                        v-if="is_draw"
                                                        v-bind:width="getDrawingLineWidth"
                                                        v-bind:color="getDrawingLineColor"
                                                        v-on:update-draw="update_draw"
                                                ></pixel-draw>


                                            </template>

                                        </b-form>

                                    </b-collapse>




                                    <!-- Images -->
                                    <b-list-group-item href="#" v-b-toggle.images class="text-dark">
                                        <font-awesome-icon :icon="'images'" /> {{ $t('pixel.images') }}
                                    </b-list-group-item>

                                    <!-- Images collapsed -->
                                    <b-collapse id="images">

                                        <!-- Image -->
                                        <pixel-image

                                                v-on:image-add="add"

                                        ></pixel-image>

                                    </b-collapse>

                                    <!-- Filters -->
                                    <b-list-group-item href="#" v-b-toggle.filters class="text-dark">
                                        <font-awesome-icon :icon="'sliders-h'" /> {{ $t('pixel.filters') }}
                                    </b-list-group-item>

                                    <!-- Filters collapsed -->
                                    <b-collapse id="filters">

                                        <template v-if="selected">

                                            <b-form class="m-3">

                                                <!-- Stroke -->
                                                <pixel-stroke
                                                        v-bind:key="'stroke-' + selected.id"
                                                        v-bind:default_stroke="selected.stroke"
                                                        v-bind:default_width="selected.strokeWidth"
                                                        v-on:update="update"
                                                ></pixel-stroke>

                                                <!-- Opacity -->
                                                <pixel-opacity
                                                        v-bind:key="'opacity-' + selected.id"
                                                        v-bind:default_opacity="selected.opacity"
                                                        v-on:update="update"
                                                ></pixel-opacity>

                                                <!-- Shadow -->
                                                <pixel-shadow
                                                        v-bind:key="'shadow-' + selected.id"
                                                        v-bind:default_status="selected.shadow"
                                                        v-on:update="update"
                                                ></pixel-shadow>

                                            </b-form>

                                        </template>

                                    </b-collapse>


                                </b-list-group>
                            </template>
                            </b-card>

                        </div>

                        <div class="col-lg-9">
                            <b-row>
                                <div class="col-md-12">         
                                    <canvas id="canvas" ref="canvas" :width="width" :height="height"></canvas>
                                </div>

                            </b-row>
                        </div>

                    </b-row><!-- // .row -->
                </div>
            </template>

            <template v-else>
                <b-navbar toggleable="md" variant="light">
                    <!-- Navbar brand -->
                    <b-navbar-brand><font-awesome-icon :icon="'plus'" class="text-danger"/>  Pixel </b-navbar-brand>

                    <b-button size="sm" @click="$close" variant="outline-danger"  class="mb-1 ml-auto"><font-awesome-icon :icon="'times'" /> {{ $t('default.close') }}</b-button>
                </b-navbar>

                <div class="container-fluid mt-2 d-flex justify-content-center">
                    <b-alert show variant="danger">{{ $t('pixel.alert') }}</b-alert>
                    
                </div>
            </template>
        </div>
    </transition>
</template>
<script>

    import ImageView from '../../Modal/ImageView.vue';

    import { create } from 'vue-modal-dialogs'

    import {mapMutations, mapActions} from "vuex";


    import PerfectScrollbar from 'perfect-scrollbar';

    // Fabric
    import { fabric } from 'fabric';

    // Random uuidv4
    const uuidv4 = require('uuid/v4');

    export default {

        props: ['template_image_json', 'template_variables_default', 'template_variables_custom', 'template_type'],

        mounted: function () {
            // Component mounted
            console.log('View Fabric Modal mounted');

            // Transparent corners
            fabric.Object.prototype.transparentCorners = true;

            // Create new canvas
            this.pixel = new fabric.Canvas("canvas", { preserveObjectStacking: true });

            // Controls above overlay
            this.pixel.controlsAboveOverlay = false;

            // Skip off screen
            this.pixel.skipOffscreen = true;

            // Retina scaling
            this.pixel.enableRetinaScaling = true;

            // Make background white
            this.pixel.setBackgroundColor('#ffffff');

            this.pixel.on("selection:created", this.selection_created);

            this.pixel.on("selection:updated", this.selection_updated);

            this.pixel.on("selection:cleared", this.selection_cleared);

            this.pixel.on("object:modified", this.object_modified);

            this.pixel.renderAll();

            // Check if we have json?
            if(this.template_image_json){
                // Load json
                this.load(this.template_image_json);
            }

            this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
            })

        },

        created() {
            console.log('View Fabric Modal created.');

            window.addEventListener('keyup', e => {
              // esc key and 'q' for quit
              if (e.keyCode === 27 || e.keyCode === 81) this.$close(true);
            });

        },

        destroyed() {
            console.log('View Fabric Modal destroyed.');
        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        data: function() {
            return  {

                // Attachment
                attachment: {
                    name: null,
                    url: { public: null },
                },

                // Width
                width: 736,

                // Height
                height: 451,

                pixel: null,

                // Draw
                is_draw: false,

                // Selected
                selected: null,

                tools_collapsed: true,

                windowWidth: window.innerWidth,

            }


        },

        methods: {

            // Images
            ...mapMutations('Reply', [
                'setPixelImageToJson'
            ]),

            // Images
            ...mapActions('Reply', [
                'updatePixelImage'
            ]),

            // Selection created
            selection_created: function(options){

                console.log('selection created');

                this.selected = options.target;

            },

            // Selection updated
            selection_updated: function(options){

                console.log('selection updated');

                this.selected = options.target;

            },

            // Selection cleared
            selection_cleared: function(options){

                console.log('selection cleared');

                this.selected = null;

            },

            // Object was modified
            object_modified: function(options){

                console.log('object modified');

                //this.draw();
            },

            // Object moving
            object_moved: function(options){

                console.log('object moved');

                //this.redraw();
            },

            //////////////////////
            // add to canvas     //
            //////////////////////
            add_to_canvas: function(object){

                // Add
                this.pixel.add(object);

                // Draw
                this.draw();

            },

            // Render
            draw: function(){

                // Render all
                this.pixel.renderAll();
            },

            // Action move
            action_move: function(move){

                // Check if we have a target
                if(this.selected){

                    // Switch move
                    switch(move){

                        // Move up
                        case 'up':

                            // Move up
                            this.selected.bringForward();

                            // Break
                            break;

                        // Move down
                        case 'down':

                            // Move up
                            this.selected.sendBackwards(true);

                            // Break
                            break;

                    }

                    // Redraw
                    //this.redraw();

                } else {

                    // Show error
                    Vue.toasted.error( this.$t('pixel.empty_move') );

                }

            },

            // Action align
            action_align: function(param){

                // Check if we have target
                if(this.selected){

                    // Switch param
                    switch(param){

                        // Left
                        case 'left':

                            // Set left to 0
                            this.selected.set({
                                left: 0
                            });

                            // Break
                            break;

                        // Top
                        case 'top':

                            // Set left to 0
                            this.selected.set({
                                top: 0
                            });

                            // Break
                            break;

                        // Bottom
                        case 'bottom':

                            this.selected.set({
                                top: this.pixel.height - (this.selected.height * this.selected.scaleY)
                            });

                            // Break
                            break;


                        // Right
                        case 'right':

                            this.selected.set({
                                left: this.pixel.width - (this.selected.width * this.selected.scaleX)
                            });

                            // Break
                            break;

                        // Center
                        case 'center':

                            this.selected.set({
                                left: (this.pixel.width / 2) - ((this.selected.width * this.selected.scaleX) / 2)
                            });

                            // Break
                            break;

                    }

                    console.log('set coords');

                    // Set coords
                    this.selected.setCoords();

                    this.draw();

                } else {

                    // Show error
                    Vue.toasted.error( this.$t('pixel.empty_align') );

                }

            },

            action_manage: function(action){

                if(this.selected){

                    switch(action){

                        // Copy
                        case 'copy':

                            let object = this.selected.clone();

                            // Discard active objects
                            this.pixel.discardActiveObject();

                            // Clone and Add to canvas
                            this.add_to_canvas(object);

                            // Redraw
                            this.draw();

                            // Break
                            break;

                        // Delete
                        case 'delete':

                            // Get active objects
                            let activeObjects = this.pixel.getActiveObjects();

                            // Discard active objects
                            this.pixel.discardActiveObject();

                            // Check if we have objects
                            if (activeObjects.length) {
                               this.pixel.remove.apply(this.pixel, activeObjects);
                            }

                            // Redraw
                            //this.draw();

                            // Break
                            break;

                        // Clear
                        case 'clear':

                            // Clear canvas
                            this.pixel.clear();

                            // Break
                            break;
                    }

                    // Redraw layer
                    this.draw();

                } else {

                    // Show error
                    Vue.toasted.error( this.$t('pixel.empty_manage') );

                }


            },


            // Add
            add: function(type, data){

                // Switch type
                switch(type){

                    // Text
                    case 'text':

                        let text = new fabric.Text(this.$t('pixel.sample_text'), {
                            left: 1,
                            top: 1,
                            fontFamily: 'helvetica',
                            fill: this.getRandomColor(),
                            fontWeight: '',
                            originX: 'left',
                            hasRotatingPoint: false,
                            centerTransform: false,

                            hasControls: false,
                            hasBorders: false,
                        });

                        text.id = uuidv4();

                        // Add to canvas
                        this.add_to_canvas(text);

                        // Break
                        break;

                        // iText
                        case 'itext':

                            let itext = new fabric.IText(this.$t('pixel.sample_text'), {
                                left: 1,
                                top: 1,
                                fontFamily: 'helvetica',
                                fontSize: 20,
                                fill: this.getRandomColor(),
                                fontWeight: '',
                                originX: 'left',
                                hasRotatingPoint: true,
                                centerTransform: true,

                                hasControls: false,
                                hasBorders: false,
                            });

                            itext.id = uuidv4();

                            // Add to canvas
                            this.add_to_canvas(itext);

                            // Break
                            break;

                        // TextBox
                        case 'textbox':

                            let textbox = new fabric.Textbox(this.$t('pixel.sample_text'), {
                                left: 1,
                                top: 1,
                                fontFamily: 'helvetica',
                                fontSize: 20,
                                fill: this.getRandomColor(),
                                width: 220,
                                height: 22,
                                fontWeight: '',
                                originX: 'left',
                                centerTransform: true,
                                hasRotatingPoint: false,
                                lockScalingY: true,

                            });

                            textbox.id = uuidv4();

                            // Add to canvas
                            this.add_to_canvas(textbox);

                            // Break
                            break;

                        // Rectangle
                        case 'rectangle':

                            let rect = new fabric.Rect({
                                id: uuidv4(),
                                left: 1,
                                top: 1,
                                fill: this.getRandomColor(),
                                width: 50,
                                height: 50,
                                opacity: 0.8
                            });

                            rect.id = uuidv4();

                            // Add to canvas
                            this.add_to_canvas(rect);

                            // Break
                            break;

                        // Triangle
                        case 'triangle':

                            let triangle = new fabric.Triangle({
                                left: 1,
                                top: 1,
                                fill: this.getRandomColor(),
                                width: 50,
                                height: 50,
                                opacity: 0.8
                            });

                            // Set id
                            triangle.id = uuidv4();

                            // Add to canvas
                            this.add_to_canvas(triangle);

                            // Break
                            break;

                        // Line
                        case 'line':

                            let line = new fabric.Line([ 50, 100, 200, 200], {
                                left: 1,
                                top: 1,
                                stroke: this.getRandomColor()
                            });

                            line.id = uuidv4();

                            // Add to canvas
                            this.add_to_canvas(line);

                            // Break
                            break;

                        // Polygon
                        case 'polygon':

                            let polygon = new fabric.Polygon([

                                {x: 185, y: 0},
                                {x: 250, y: 100},
                                {x: 385, y: 170},
                                {x: 0, y: 245} ], {
                                left: 1,
                                top: 1,
                                fill: this.getRandomColor(),
                            });

                            polygon.id = uuidv4();

                            // Add to canvas
                            this.add_to_canvas(polygon);

                            // Break
                            break;

                        // Image
                        case 'image':

                            let add_to_canvas = this.add_to_canvas;

                            fabric.Image.fromURL(data, function(img) {

                                img.hasControls = true;
                                img.hasBorders = true;

                                add_to_canvas(img);

                            }, {crossOrigin:'Anonymous'});

                            // Break
                            break;

                }

                // Draw
                this.draw();

            },

            // Get random color
            getRandomColor: function() {
                // Generate a color!
                let color = '#'+Math.random().toString(16).slice(-6);

                // Return color
                return color;
            },

            // Update draw
            update_draw: function(type, value){

                // Switch type
                switch(type){

                    // Mode
                    case 'mode':

                        // Enable or disable
                        this.pixel.isDrawingMode = !this.pixel.isDrawingMode;

                        this.is_draw = this.pixel.isDrawingMode;

                        // Redraw
                        //this.draw();

                        // Break
                        break;

                    // Line width
                    case 'line_width':

                        // Set line width
                        this.pixel.freeDrawingBrush.width = parseInt(value, 10) || 1;

                        // Redraw
                        //this.draw();

                        // Break
                        break;

                    // Line color
                    case 'line_color':

                        // Set line color
                        this.pixel.freeDrawingBrush.color = value;

                        // Redraw
                        //this.draw();

                        // Break
                        break;

                }

            },


            // Update
            update: function(attrs){

                // Check if we have selection? ok
                if(this.selected){

                    this.selected.set(attrs);

                    // Set coords
                    this.selected.setCoords();

                    // Selected
                    this.selected = this.pixel.getActiveObject();


                    // Redraw
                    this.draw();

                } else {

                    // Return false
                    return false;

                }

                // Redraw layer
                //this.draw();

            },


            // Load data
            load: function(json){

                // Load from json
                this.pixel.loadFromJSON(json, this.pixel.renderAll.bind(this.pixel), function(o, object) {
                    // `o` = json object
                    // `object` = fabric.Object instance
                    // ... do some stuff ...
                    console.log('load data from json');
                });

            },

            // Export data
            save: function(){

                if(this.template_type == 1){

                    // Json
                    let json = JSON.stringify(this.pixel.toJSON(['id']));

                    console.log('this is type 1  json ');

                    this.setPixelImageToJson(this.pixel);


                    setTimeout(() => {

                        let last_image = this.pixel.toDataURL("image/png");
                        console.log(last_image);
                        // Set Pixel Image
                        this.updatePixelImage(last_image);
                    }, 2000);

                    // Close modal
                    this.$close(json);

                } else {

                    // Prepare image
                    let image =  this.pixel.toDataURL({
                        format: "jpeg",
                        quality: 1,
                    });

                    console.log('this is type 2 the image');

                    // Set Pixel Image
                    this.updatePixelImage(image);

                    // Return image
                    this.$close(image);

                }

            },

            download: function(){

                // Attachment name
                this.attachment.name = this.$t('pixel.preview');

                // Url
                this.attachment.url.public = this.pixel.toDataURL({
                    format: "jpeg",
                    quality: 1,
                });

            },

            image_view: async function (attachment){

                // Prepare image
                await this.download();

                // Show image modal
                const image_view = create(ImageView, 'attachment');

                if (await image_view(attachment) ){
                    // Nothing here yet

                }
            },

            onResize() {
                this.windowWidth = window.innerWidth;
            },

        },

        computed: {

            // Draw blocks
            // Get if it's drawing mode
            getFreeDrawingMode: function () {

                if(this.pixel){

                    // Return drawing mode
                    return this.pixel.isDrawingMode ? this.pixel.isDrawingMode : false;

                } else {

                    // Return false
                    return false;

                }

            },

            // Get if drawing line color
            getDrawingLineWidth: function () {

                if(this.getFreeDrawingMode){

                    return this.pixel.freeDrawingBrush.width;
                }

            },

            // Get if drawing line color
            getDrawingLineColor: function () {

                if(this.getFreeDrawingMode){

                    return this.pixel.freeDrawingBrush.color;
                }

            },

        }
    }
</script>