<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.add')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="add">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_add">


                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="name"
                            name="name"
                            v-validate="'required|max:15'"
                            v-model="name"
                        >

                        <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="description">{{ $t('simple.desc') }}</label>

                        <input 
                            class="form-control"
                            type="text"
                            id="description"
                            name="description"
                            v-validate="'required|max:50'"
                            v-model="description"
                        >

                        <small v-show="errors.has('description')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('description') }}</small>

                    </div>

                </form>

            </v-wait>

        </div>

        <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

            <b-button @click="validate_add()" variant="primary">{{ $t('simple.save') }}</b-button>
            
        </div>


        </div>
      </div>
    </div>
</template>
<script>

    export default {

        mounted: function () {
            // Component mounted
            console.log('Category Type Add Modal mounted');
        },
        
        destroyed() {
            // Component destroyed
            console.log('Category Type Add Modal destroyed.');
        },

        data: function() {
            return  {
                category_types_api: '/api/v1/settings/category/types/',
                name: '',
                description: ''
            }
        },

        methods: {
            category_type_add: async function(){

                // Loading start
                this.$wait.start('add');

                // Post request
                await axios.post(this.category_types_api, {
                    name: this.name,
                    description: this.description
                  })
                  .then((response) => {

                    // Loading end
                    this.$wait.end('add');

                    // Show success
                    Vue.toasted.success(this.$t('simple.success_info'));

                    // Close modal
                    this.$close(true);


                  })
                  .catch((error) => {

                    // Loading end
                    this.$wait.end('add');

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Close modal
                    //this.$close(true);


                  });

            },

            validate_add: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.category_type_add();
                    }
                });
            },
        },
    }
</script>