<template>

    <div class="container-fluid p-0">

        <div class="row">

            <div class="col pt-2">

                <!--  Page  -->
                <div class="bg-white rounded py-2 shadow-widget border">

                    <!-- Page header -->
                    <div class="px-3">

                        <div class="d-flex">

                            <h4>{{ $t('page.tracks') }}</h4>

                            <div class="ml-auto align-self-center">

                                <template v-if="this.tracks_limit > this.tracks.length">
                                    <b-button :variant="'primary'" @click="add" class="ml-auto">
                                        <font-awesome-icon :icon="'plus'" />
                                        {{ $t('default.add')}}
                                    </b-button>
                                </template>

                                <template v-else>
                                    <b-alert show variant="secondary">
                                        {{ $t('page.tracks_limit')}}
                                    </b-alert>
                                </template>
                            </div>

                        </div>

                        <span class="text-muted font-weight-light">{{ $t('page.tracks_desc') }}</span>

                    </div>

                    <hr>

                    <!-- Page content -->
                    <div class="px-2">

                        <v-wait for="tracks">

                            <loading slot="waiting"/>


                            <b-row>
                                
                                <b-col md="8" class="my-auto mr-auto">
                                    {{ $t('page.tracks_remaining') }} : {{ this.tracks_limit - this.tracks.length }}
                                </b-col>

                                <b-col md="4" class="my-2 ml-auto">
                                    <b-input-group>
                                        <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                        <b-input-group-append>
                                          <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                            </b-row>

                            <b-table stacked="lg" striped responsive bordered hover :items="this.tracks" :fields="track_fields" :filter="filter" class="vh-25">

                                <!-- Actions -->
                                <template slot="cell(actions)" slot-scope="cell">

                                  <b-dropdown right :text="$t('simple.actions')" size="sm">

                                    <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>

                                    <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>


                                  </b-dropdown>

                                </template>

                                <!-- Created at -->
                                <template slot="cell(created_at)" slot-scope="cell">

                                    {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                                <!-- Updated at -->
                                <template slot="cell(updated_at)" slot-scope="cell">
                                    
                                    {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                
                                </template>

                            </b-table>

                        </v-wait>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import TrackAdd from './Modal/TrackAdd.vue'
    import TrackEdit from './Modal/TrackEdit.vue'
    import TrackRemove from './Modal/TrackRemove.vue'

    import { create } from 'vue-modal-dialogs'
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {

        props: ['id'],

        mounted: function () {
            // Component mounted
            console.log('Tracks component mounted.')
        },

        destroyed() {
            // Component destroyed
            console.log('Tracks component destroyed.');
        },

        created: function() {
            // Component created
            this.fetchTracks(this.id);
        },
        

        data: function() {
            return  {
                track_fields: [
                    { key: 'track', label: this.$t('simple.name'), sortable: true },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true },
                    { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                    { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                filter: null,
            }
        },
        
        metaInfo: function() {

            return {
                title: this.$t('page.tracks'),
            }
            
        },

        methods: {

            ...mapActions('Accounts', [
                'fetchTracks',
            ]),


            add: async function() {
                // Show Track add modal
                const track_add = create(TrackAdd, 'id');

                if (await track_add(this.id) ){
                    // Refresh account list
                    this.fetchTracks(this.id);
                }

            },

            edit: async function(id) {
                // Show Track edit modal
                const track_edit = create(TrackEdit, 'id');

                if (await track_edit(id) ){
                    // Refresh Track list
                    this.fetchTracks(this.id);
                }

            },

            
            remove: async function(id) {
                
                // Show Track remove modal
                const track_remove = create(TrackRemove, 'id');

                if (await track_remove(id) ){
                    // Refresh Track list
                    this.fetchTracks(this.id);
                }

            },

        },

        computed: {
            // Map getters
            ...mapGetters({

                tracks: 'Accounts/getTracks',
                tracks_limit: 'Accounts/getTracksLimit'

            }),
        },
        
    }
</script>