<template>

    <!-- Ticket -->
    <span class="message-header-data">
      <small>

        <b-badge href="#" v-on:click="updateTicketID(ticket.id)" :variant="$options.methods.variant(ticket.status)" :disabled="disabled_areas.ticket_edit">#{{ ticket.case_id }}</b-badge>

        <b-spinner v-if="ticket.status !== 5 && loading" variant="danger" small style="vertical-align: middle" slot="waiting"></b-spinner>
        <b-button v-if="ticket.status !== 5 && !loading" v-on:click="updateLoading" variant="link" size="sm" style="vertical-align: middle" :disabled="disabled_areas.ticket_edit" ><font-awesome-icon :icon="'times-circle'" class="plus__icon text-danger pointer"/></b-button>
      </small>
    </span>

</template>

<script>

    import { getTicketVariant } from '../../utils/ticket.js'
    import {mapActions, mapGetters} from "vuex";

    export default {

        props: ['ticket'],

        mounted: function () {
            // Component mounted
            //console.log('Customer Type Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Type Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {
              loading: false
            };

        },

        computed: {
          ...mapGetters({
            disabled_areas: 'Accounts/getDisabledAreas',
        }),

        },

        methods: {

            variant: getTicketVariant,

          // Map actions
          ...mapActions('Ticket', [
            'closeTicket',
            'updateTicketID',
          ]),

          updateLoading:async function (){
              this.loading = true;
              // this.closeTicket();
            if (await this.closeTicket(this.ticket.id) ){
              // Refresh account list
              // this.loading = false;
              //

            }
          }

        },
        
    }
</script>