<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('simple.delete') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="customer_gender_delete">

                <loading slot="waiting"/>

                <b-alert variant="danger" show> {{ $t('simple.delete_confirm') }}</b-alert>

            </v-wait>

          </div>

          <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>
            <b-button @click="customer_gender_delete" variant="primary">{{ $t('simple.delete') }}</b-button>
            
          </div>

        </div>
      </div>
    </div>
</template>
<script>

    export default {

        mounted: function () {
            // Component mounted
            console.log('Customer Gender Remove Modal mounted');
        },

        destroyed() {
            // Component Destroyed
            console.log('Customer Gender Remove Modal destroyed.');
        },

        data: function() {
             return  {
                customer_genders_api: '/api/v1/settings/customer/genders/',
             }
        },

        methods: {

            customer_gender_delete: async function(){

                // Loading start
                this.$wait.start('customer_gender_delete');

                await axios.delete(this.customer_genders_api + this.id)

                .then((response) => {

                    // Loading stop
                    this.$wait.end('customer_gender_delete');

                    // Show success
                    Vue.toasted.success(this.$t('simple.deleted_confirmed'));

                    // Close modal
                    this.$close(true);

                })
                .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    console.log(error);

                    // Loading stop
                    this.$wait.end('customer_gender_delete');

                    // Close modal
                    this.$close(true);


                });

            },
        },
    }
</script>