<template>
        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="tagProcess.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';


import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
/* Imports */
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import {mapGetters} from "vuex";

/* Chart code */
// Themes begin
// am4core.useTheme(am4themes_animated);
// Themes end

export default {

        mounted() {



                let root;

                console.log('xy', this.tagProcess)


                if (this.tagProcess.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([am5themes_Animated.new(root)]);

                let raw_data = this.tagProcess.length ? this.tagProcess : this.fakeTagProcess;
                let data = raw_data.map(obj => {
                        Object.keys(obj).forEach((key) => {
                                if (obj[key] === 0) {
                                        delete obj[key]
                                }
                        });
                        return obj
                })

                let chart = root.container.children.push(am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "none",
                        wheelY: "none",
                        layout: root.verticalLayout
                }));

                let xRenderer = am5xy.AxisRendererX.new(root, {});
                let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                        categoryField: "tags",
                        renderer: xRenderer,
                        tooltip: am5.Tooltip.new(root, {})
                }));

                xRenderer.grid.template.setAll({
                        location: 1
                })

                xAxis.data.setAll(data);

                let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                        min: 0,
                        renderer: am5xy.AxisRendererY.new(root, {
                                strokeOpacity: 0.1
                        })
                }));

                let legend = chart.children.push(am5.Legend.new(root, {
                        centerX: am5.p50,
                        x: am5.p50
                }));

                function makeSeries(name, fieldName) {
                        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                                name: name,
                                stacked: true,
                                xAxis: xAxis,
                                yAxis: yAxis,
                                valueYField: fieldName,
                                categoryXField: "tags"
                        }));

                        series.columns.template.setAll({
                                tooltipText: "{name}, {categoryX}: {valueY}",
                                tooltipY: am5.percent(10)
                        });


                        series.bullets.push(function () {
                                return am5.Bullet.new(root, {
                                        sprite: am5.Label.new(root, {
                                                text: "{valueY}",
                                                fill: root.interfaceColors.get("alternativeText"),
                                                centerY: am5.p50,
                                                centerX: am5.p50,
                                                populateText: true
                                        })
                                });
                        });
                        series.data.setAll(data);
                        legend.data.push(series);
                }

                makeSeries(this.$t("ticket.status_open"), "status_open");
                makeSeries(this.$t("ticket.status_replied"), "status_replied");
                makeSeries(this.$t("ticket.status_transferred"), "status_transferred");
                makeSeries(this.$t("ticket.status_awaiting"), "status_awaiting");
                makeSeries(this.$t("ticket.status_closed"), "status_closed");
                makeSeries(this.$t("ticket.status_unknown"), "status_unknown");

                if (this.tagProcess.length > 0) {
                        am5plugins_exporting.Exporting.new(root, {
                                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                                dataSource: data,
                                filePrefix: "convoTypes",
                        });
                }


                this.root = root;


                // Data Model = "Tags": { "Open": 18, "Replied": 14, "Transferred": 30, "Awaiting": 53, "Closed": 51},


                // let data = this.tagProcess;

                // Create chart instance
                // let chart = am4core.create("chartdiv", am4charts.XYChart);

                // // console.log("-----------this this-----------");
                // // console.log(this.tagProcess);
                // // Add data
                // chart.data = this.tagProcess;

                // // Create axes
                // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                // categoryAxis.dataFields.category = "tags";
                // categoryAxis.renderer.grid.template.location = 0;


                // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                // valueAxis.renderer.inside = true;
                // valueAxis.renderer.labels.template.disabled = true;
                // valueAxis.min = 0;

                // // Create series
                // function createSeries(field, name) {

                //         // Set up series
                //         let series = chart.series.push(new am4charts.ColumnSeries());
                //         series.name = name;
                //         series.dataFields.valueY = field;
                //         series.dataFields.categoryX = "tags";
                //         series.sequencedInterpolation = true;

                //         // Make it stacked
                //         series.stacked = true;

                //         // Configure columns
                //         series.columns.template.width = am4core.percent(60);
                //         series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

                //         // Add label
                //         let labelBullet = series.bullets.push(new am4charts.LabelBullet());
                //         labelBullet.label.text = "{valueY}";
                //         labelBullet.locationY = 0.5;
                //         labelBullet.label.hideOversized = true;

                //         return series;
                // }

                // createSeries("status_open", this.$t("ticket.status_open"));
                // createSeries("status_replied", this.$t("ticket.status_replied"));
                // createSeries("status_transferred", this.$t("ticket.status_transferred"));
                // createSeries("status_awaiting", this.$t("ticket.status_awaiting"));
                // createSeries("status_closed", this.$t("ticket.status_closed"));
                // createSeries("status_unknown", this.$t("ticket.status_unknown"));

                // // Legend
                // chart.legend = new am4charts.Legend();


                // // Add cursor
                // chart.cursor = new am4charts.XYCursor();


                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoTagProcess";





        },

        beforeDestroy() {
                //     if (this.chart) {
                //         this.chart.dispose();
                //     }

                if (this.root) {
                        this.root.dispose();
                }
        },


        data: function () {
                return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({

                        tagProcess: 'Report/getTagProcess',
                        fakeTagProcess: 'Report/getFakeTagProcess'

                })
        },
}
</script>