<template>

    <div class="container-fluid">

        <div class="row">
                    
            <form>

                <b-form-group label="Mention:" horizontal v-if="mentions">

                    <b-form-checkbox-group v-model="selected" @change="change">
                                
                        <b-form-checkbox v-for="mention in mentions"  :value="mention.id" :key="mention.id">@{{ mention.screen_name }}</b-form-checkbox>

                    </b-form-checkbox-group>

                </b-form-group>

            </form>

        </div>

    </div>

    <!--Selected : {{ selected }}-->

</template>

<script>

    import {mapActions} from "vuex";

    export default {

        props: ['mentions'],

        mounted: function () {
            this.mentions.forEach((val)=>{
              this.selected.push(val.id)
            })
          this.selectedUsers(this.selected)
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Type Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {

                selected: [],

            };

        },

        computed: {



        },
        
        methods: {
          ...mapActions('Reply', [
            'selectedUsers',
          ]),


          change(){
            this.selectedUsers(this.selected)
          }
        },
        
    }
</script>