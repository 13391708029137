<template>
    <!-- Reply Render -->
    <div class="container-fluid" >
        <div v-if="getIsReply">

            <b-card no-body class="bg-cream"
                style="border-bottom-left-radius: 0;border-bottom-right-radius: 0; border-bottom: 0;">
                <p class="bg-light pt-2 pl-3 pt-0 pb-3">
                    <font-awesome-icon :icon="'reply'" class="plus__icon text-success pt-2 pr-2"
                        style="font-size: 25px;" />
                    <span v-html="'<strong> To :</strong> '"></span>
                    <span class="text-danger"
                        v-text="getReplyData.customer.username ? getReplyData.customer.username : 'You'"></span>
                    <span @click="resetThreadId" class="float-right pr-3">
                        <font-awesome-icon :icon="'times'" class="plus__icon text-danger pt-2"
                            style="font-size: 25px;" />
                    </span>
                </p>
                <div style="padding: 15px; padding-bottom: 0; padding-top: 0;">
                    <p v-text="getReplyData.message.content ? getReplyData.message.content : getReplyData"></p>
                </div>
            </b-card>
        </div>

        <div class="row">

            <b-card no-body class="message-reply-form" id="reply">

                <b-tabs card v-model="active_tab" nav-class="flex-nowrap">

                    <b-tab :disabled="transfer_check() === 'false' || disabled_areas.reply_area" no-body :class="visible ? null : 'collapse'"
                        :aria-expanded="visible ? 'true' : 'false'" :aria-controls="'collapse-reply'"
                        @click="visible_changer" class="test1">

                        <template slot="title">
                            <font-awesome-icon :icon="'reply'" class="plus__icon" />
                            {{ $t('default.reply') }}
                        </template>

                        <b-collapse :id="'collapse-reply'" v-model="visible" class="mt-2">

                            <b-container fluid v-if="session_deactive" class="p-3">
                                <b-card border-variant="danger" bg-variant="light" align="center">
                                    <b-card-text class="font-weight-bold"> {{
                                        $t('customer.whatsapp_session_alert') }}</b-card-text>
                                    <b-btn size="sm" @click="sendTemplate" variant="danger"> {{
                                        $t('macros.send_template_message')
                                        }}</b-btn>
                                </b-card>
                            </b-container>

                            <reply-text v-else></reply-text>

                        </b-collapse>
                    </b-tab>



                    <b-tab :disabled="getTicketPermission === 'false'|| disabled_areas.reply_area" no-body @click="[counter = 0, visible = true]">

                        <template slot="title">
                            <font-awesome-icon :icon="'comments'" class="plus__icon" />
                            {{ $t('ticket.notes') }}
                        </template>

                        <reply-note></reply-note>

                    </b-tab>

                    <template v-if="visible" slot="tabs-end">
                        <transition name="slide-fade">
                            <!-- TODO: Add Session Template Options for Whatsapp -->

                            <li role="presentation" v-if="macros_options && !session_deactive"
                                class="nav-item d-flex flex-column flex-xl-row align-self-center align-items-xl-center ml-auto">
                                <b-btn v-if="$route.params.int === 'whatsapp'" size="sm" @click="sendTemplate" variant="danger" class="mb-1 mb-xl-0 mr-xl-2">
                                    <font-awesome-icon icon="lira-sign" />
                                    {{ $t('macros.send_template_message') }}</b-btn>
                                <a-cascader size="small" style="padding: 0 !important;" :matchInputWidth="false"
                                    :fieldNames="fields" :showSearch="{ filter }" :options="macros_options"
                                    expandTrigger="hover" @change="cascader" :placeholder="$t('macros.title')">
                                    <font-awesome-icon slot="suffixIcon" icon="robot" />
                                </a-cascader>
                            </li>

                        </transition>
                    </template>

                    <b-tab :disabled="getTicketPermission === 'false'|| disabled_areas.reply_area" @click="[counter = 0, visible = true]">
                        <template v-slot:title>
                            <div
                                v-if="(ticket_teams !== 'undefined' && ticket_teams.length) || (ticket_users !== 'undefined' && ticket_users.length)">
                                <b-spinner variant="danger" type="grow" small></b-spinner>
                                <font-awesome-icon :icon="'exchange-alt'" :class="'nav-settings-assigned'" />

                                {{ $t('ticket.transfer') }}
                            </div>
                            <div v-else>
                                <font-awesome-icon :icon="'exchange-alt'" :class="'plus__icon'" />
                                {{ $t('ticket.transfer') }}
                            </div>

                        </template>
                        <b-collapse v-model="visible">
                            <reply-transfer></reply-transfer>
                        </b-collapse>
                    </b-tab>

                    <!-- <b-tab :disabled="getTicketPermission==='false'" @click="[counter = 0, visible = true]">

                        <template v-slot:title>
                            <div v-if="getScheduleDate">
                                <b-spinner variant="warning" type="grow" small></b-spinner>
                                <font-awesome-icon :icon="'clock'" :class="'nav-settings-scheduled'"/>

                                {{ $t('ticket.schedule') }}
                            </div>
                            <div v-else>
                                <font-awesome-icon :icon="'clock'" :class="'plus__icon'"/>
                                {{ $t('ticket.schedule') }}
                            </div>

                        </template>

                        <reply-schedule></reply-schedule>

                    </b-tab> -->

                    <!--<template slot="tabs">

                        <span class="ml-auto"><b-form-select v-model="selected" :options="getAccountsByType('twitter')" size="sm"></b-form-select></span>

                    </template>-->

                </b-tabs>

            </b-card>

        </div>

    </div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex';
import { DateTime } from "luxon";

// Fabric
import { fabric } from 'fabric';
import { create } from "vue-modal-dialogs";
import MacrosVariables from './Modal/MacrosVariables.vue'
import WhatsappTemplatePost from '../Message/Modal/WhatsappTemplatePost.vue'

export default {

    props: ['ticket', 'disabled_areas'],

    mounted: function () {
        // Component mounted
        console.log("-----Reply Render Component Mounted");
        this.updateMacrosData();
        this.updateTicketAssigned();

    },

    destroyed() {
        // Component destroyed
        console.log("-----Reply Render Component Destroyed");
    },

    created: function () {
        // Component created
        console.log("-----Reply Render Component Created");
    },

    data: function () {

        return {

            counter: 1,

            reply_active: false,

            visible: false,

            popoverShow: false,

            componentKey: 0,

            pixel: null,

            cas: [],
        };

    },

    computed: {


        // Map getters
        ...mapGetters({

            // Get Account
            getAccount: 'Accounts/getAccount',

            //Integration
            integration: 'Accounts/getIntegration',

            // Active tab
            getActiveTab: 'Reply/getActiveTab',

            // is Reply
            getIsReply: 'Reply/getIsReply',

            // Reply Data
            getReplyData: 'Reply/getReplyData',

            // Thread Id
            getThreadId: 'Reply/getThreadId',

            // Ticket assigned
            getTicketAssigned: 'Ticket/getTicketAssigned',

            // Schedule
            getScheduleDate: 'Reply/getScheduleDate',

            // Macro fields
            fields: 'Macro/getFields',

            // Macro options
            macros_options: 'Macro/getMacrosOptions',

            // Customer
            customer: 'Customer/getCustomer',

            // User
            getUser: 'User/getUser',

            getTransferTeams: 'Ticket/getTransferTeams',
            getTransferUsers: 'Ticket/getTransferUsers',

            // Team
            getTeam: 'User/getUserTeams',

            // Get ticket
            ticket_id: 'Ticket/getTicketID',

            // Transfer Status
            getTicketPermission: 'Ticket/getTicketPermission',

            messages: 'Messages/getMessages',

            is_session_active: 'Reply/getSessionActive'


        }),


        // Active Tab
        active_tab: {

            // getter
            get: function () {
                // Get Active Tab
                return this.getActiveTab;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {

                    // Update Reply Active tab
                    this.updateActiveTab(value);
                }
            },

        },

        // Ticket teams
        user_teams: {

            // getter
            get: function () {
                return this.getTeams;
            },


        },

        // Ticket Users
        ticket_users: {

            // getter
            get: function () {
                if (this.getTransferUsers) {
                    return this.getTransferUsers;
                } else {
                    return [];
                }
            },
        },

        // Ticket Teams
        ticket_teams: {

            // getter
            get: function () {
                if (this.getTransferTeams) {
                    return this.getTransferTeams;
                } else {
                    return [];
                }
            },
        },

        last_question_id() {
            return this.customer.question?.id
        },

        session_deactive() {
            return !this.is_session_active && this.$route.params.int === 'whatsapp'
        }


    },


    methods: {


        // Some actions detka
        ...mapActions('Reply', [
            'resetThreadId',
            'resetActiveTab',
            'updateActiveTab',
            'updateTextAdd',
            'updatePixelImage',
            'resetText',
            'updateSessionActive'
        ]),


        // Some actions macros
        ...mapActions('Macro', [

            'updateMacrosData',

        ]),

        ...mapActions('Ticket', [
            'updateTicketAssigned',
            'updateTicketMacroData',
            'transferCheck',
            'updateTicketID',
            'fetchTransfer',
        ]),


        ...mapActions('Inbox', [
            'updateActive',
        ]),

        // Images
        ...mapMutations('Reply', [
            'setPixelImageToJson',
            'setAttachmentStatus',
        ]),

        // Customer
        ...mapActions('Customer', [
            'assignCustomer',
        ]),


        visible_changer: function () {
            this.counter += 1;
            if (this.counter > 1) {
                this.visible = !this.visible;
            }
            this.user_assign();
        },

        user_assign: function () {

            if (this.visible) {
                this.status = 'set';
            } else {
                this.status = 'unset';
            }
            // Assign Customer
            this.assignCustomer(this.status);

        },

        cascader(value) {

            if (value.length) {
                console.log('cascader reported this value');
                this.updateActive('ticket');
                // Reset Ticket Data and Transfer Data from database
                let selected_macro = this.macros_options.find(macros_options => macros_options.id === value[0]);
                this.cas = selected_macro.children.find(children => children.id === value[1]);
                this.macros_variables_edit(this.cas.variables);
                this.updateTicketMacroData(this.cas);

            } else {
                // Reset Ticket Data and Transfer Data from database(Same with updateTicketID())
                this.fetchTransfer(this.ticket_id);
                this.updateActive('customer');
                this.resetText();
            }
        },



        transfer_check() {

            // Update Transfer Status
            this.transferCheck();
            // Get Transfer Status
            // getter
            return this.getTicketPermission;
        },


        filter: function (inputValue, path) {

            const locales = ['tr', 'TR', 'tr-TR', 'tr-u-co-search', 'tr-x-turkish'];

            return (path.some(option => (option.name).toLocaleLowerCase(locales).indexOf(inputValue.toLocaleLowerCase(locales)) > -1));

        },

        macros_variables_edit: async function (cas_variables) {

            if (cas_variables == "null" || cas_variables == "[]") {
                // Edit Content Text Variables
                this.updateTextAdd({ text: this.cas.content });

                if (this.cas.image_status) {
                    // Edit Pixel Text Variables
                    this.pixel_variables_edit();

                }
            } else {
                // Show Macros Variables add modal
                const macros_variables = create(MacrosVariables, 'cas_variables');

                if (await macros_variables(cas_variables)) {
                    // Edit Content Text Variables
                    this.updateTextAdd({ text: this.cas.content });
                    if (this.cas.image_status) {
                        // Edit Pixel Text Variables
                        this.pixel_variables_edit();
                    }
                }
            }




        },

        pixel_variables_edit: function () {

            // Create new canvas
            fabric.Object.prototype.transparentCorners = true;
            this.pixel = new fabric.Canvas('canvas');
            // Controls above overlay
            this.pixel.controlsAboveOverlay = false;

            // Skip off screen
            this.pixel.skipOffscreen = true;

            // Retina scaling
            this.pixel.enableRetinaScaling = true;
            this.pixel.width = 850;
            this.pixel.height = 522;
            let json = this.cas.image;

            this.pixel.loadFromJSON(json, this.pixel.renderAll.bind(this.pixel), function (o, object) {
            });

            setTimeout(() => {
                this.pixel._objects.forEach(buckets => {
                    // Edit Pixel Text Variables
                    if (buckets.text) {
                        this.updateTextAdd({ text: buckets.text, pixel: 'pixel' }).then(data => {
                            buckets.text = data;
                        });
                    }
                });
            }, 2000);

            this.setPixelImageToJson(this.pixel);

            setTimeout(() => {
                let last_image = this.pixel.toDataURL("image/png");
                // Set Pixel Image
                this.updatePixelImage(last_image);
            }, 2000);

            let url = this.pixel.toDataURL();

            // Prepare image
            let image = this.pixel.toDataURL({
                format: "jpeg",
                quality: 1,
            });
        },

        async sendTemplate() {
            const send_template = create(WhatsappTemplatePost)

            await send_template()
        },

        get_session_time() {
            const time = DateTime.utc()
            const session_inactive = time.diff(DateTime.fromISO(this.customer?.question?.created_at), 'hours').toObject().hours > 24 && this.$route.params.int === 'whatsapp'
            this.updateSessionActive(!session_inactive)

        },

    },

    watch: {

        last_question_id: {
            handler(newVal, oldVal) {
                if (this.$route.params.int === 'whatsapp') {
                    this.get_session_time()
                }
            },
            deep: true,

            immediate: true
        }
    }

}
</script>