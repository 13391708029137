<template>
    <div :class="is_whatsapp_template ? '' : 'message-attachments'">

        <template v-for="attachment in attachments">

            <template v-if="attachment.type == 'image'">

                <!-- Image -->
                <div class="position-relative mb-1 mr-1 d-inline-block" :class="mediaContainer">
                    <a href="#" @click="image_view(attachment)">
                        <b-img-lazy :src="attachment.url.public" class="media-preview p-1" > </b-img-lazy>
                    </a>
                </div>

            </template>

            <template v-else-if="attachment.type == 'video'">

                <template>
                    <!-- Video -->
                    <div class="position-relative mb-1 mr-1 d-inline-block" :class="mediaContainer">
                        <a href="#" @click="video_view(attachment)">
                            <video :src="attachment.url.public" class="media-preview p-1"> </video>
                            <font-awesome-icon :icon="'play-circle'" class="preview-video-button position-absolute p-0" />
                        </a>
                    </div>

                </template>
            </template>

            <template v-else-if="attachment.type == 'gif'">

                <template v-if="attachment.preview">

                    <!-- Video -->
                    <div class="position-relative mb-1 mr-1 d-inline-block" :class="mediaContainer">
                        <a href="#" @click="video_view(attachment)">
                            <video :src="attachment.url.public" class="media-preview p-1"> </video>
                            <font-awesome-icon :icon="'play-circle'" class="preview-video-button position-absolute p-0" />
                        </a>
                    </div>

                </template>
            </template>

            <template v-else-if="attachment.type == 'ptt' || attachment.type == 'audio'">

                <template v-if="attachment.url">
                    <!-- Voice -->
                    <!--                    <a href="#" @click="video_view(attachment)"><b-img :src="attachment.preview.public" class="rounded img-fluid mr-1" /></a>-->
                    <!-- audio element -->
                    <vue-plyr class="audio-player">
                        <audio>
                            <source :src="attachment.url.public" type="audio/ogg" />
                        </audio>
                    </vue-plyr>

                </template>
            </template>

            <template v-else-if="attachment.type == 'pdf'">

                <template v-if="attachment.url">

                    <!-- PDF -->
                    <a :href="attachment.url.public" target="_blank"><b-img src="/images/pdf-icon.png" width="130"
                            class="rounded img-fluid mr-1" /></a>
                </template>
            </template>
          <template v-else-if="attachment.type == 'file'">

            <template v-if="attachment.url">

              <!-- File -->
              <a :href="attachment.url.public" target="_blank"><b-img src="/images/file-icon.png" width="130"
                                                                      class="rounded img-fluid mr-1" /></a>
            </template>
          </template>

            <template v-else-if="location">

                <template>

                    <!-- Location -->
                    <a href="#" @click="map_view(location)"><b-img :src="attachment.preview.public"
                            class="rounded img-fluid mr-1" /></a>


                </template>
            </template>

            <template v-else>
                Something coming soon!
            </template>


        </template>

        <template v-if="location">

            <template>

                <!-- Location -->
                <!--                    <a href="#" @click="map_view(location)"><b-img :src="attachment.preview.public" class="rounded img-fluid mr-1" /></a>-->
                <a href="#" @click="map_view(location)">
                    <img class="map_view"
                        :src="'https://maps.googleapis.com/maps/api/staticmap?center=' + location.address + '&zoom=15&size=200x200&maptype=roadmap&markers=color:red%7C' + location.lat + ',' + location.lng + '&key=AIzaSyDpIk7PKY7AM66Pbk7dZT8mOJL7Sdq7fYU'" />
                </a>

            </template>
        </template>

    </div>
</template>


<script>


import ImageView from '../Modal/ImageView.vue';
import VideoView from '../Modal/VideoView.vue';
import MapView from '../Modal/MapView.vue';
import { gmapApi } from 'vue2-google-maps';
import VuePlyr from 'vue-plyr';


import { create } from 'vue-modal-dialogs'

export default {

    components: { gmapApi, VuePlyr },
    props: ['attachments', 'location', 'is_whatsapp_template'],

    mounted: function () {
        // Component mounted
        //console.log('Customer Type Render mounted')
    },

    destroyed() {
        // Component destroyed
        //console.log('Customer Type Render mounted destroyed.');
    },

    created: function () {
        // Component created
    },

    data: function () {

        return {
            // map_data: "https://maps.googleapis.com/maps/api/staticmap?center="+this.location.address+"&zoom=15&size=200x200&maptype=roadmap&markers=color:red%7C"+this.location.lat+","+this.location.lng+"&key=AIzaSyAQyNOWSpnmV9gnTov7bk5h60Re8PSHm3U"
        }

    },

    computed: {

        mediaContainer() {
            if(this.is_whatsapp_template) {
                return 'media-preview-container-header text-center'
            } else {
                return 'media-preview-container'
            }
        }
    },

    methods: {

        getUrl: function (url) {
            console.log(url);
        },

        // Show image
        image_view: async function (attachment) {

            // Show image modal
            const image_view = create(ImageView, 'attachment');

            if (await image_view(attachment)) {
                // Nothing here yet

            }
        },

        // Play video
        video_view: async function (attachment) {

            // Show video modal
            const video_view = create(VideoView, 'attachment');

            if (await video_view(attachment)) {
                // Nothing here yet

            }
        },

        // Show Map
        map_view: async function (location) {

            // Show video modal
            const map_view = create(MapView, 'location');

            if (await map_view(location)) {
                // Nothing here yet

            }
        },

    },

}
</script>