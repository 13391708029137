<template>


    <!-- Page -->
    <div class="row mt-4">

        <!-- Panel for smaller screens-->
        <template v-if="!(macros_variable_status | macros_ticket)">
            <div  class="col d-md-none mb-1">

                <!-- Right  -->
                <div class="card shadow-widget">
                
                    <h5 class="card-header" tabindex="0" role="button" v-b-toggle.macros-setting-collapsable @click="settings_collapsed = !settings_collapsed"> 
                        {{ $t('macros.settings') }}
                        <template v-if="settings_collapsed">
                            <font-awesome-icon :icon="'caret-down'" size="sm"/>
                        </template>
                        <template v-else>
                            <font-awesome-icon :icon="'caret-up'" size="sm"/>
                        </template>
                    </h5>
                
                    <b-collapse class="card-body" id="macros-setting-collapsable">
                    
                        <v-wait for="add">
                        
                            <loading slot="waiting"/>
                        
                            <form @submit.prevent="macro_validate">
                            
                                <!-- Name -->
                                <div class="form-group">
                                
                                    <label for="macros_name">{{ $t('default.name') }}</label>
                                
                                    <input
                                            class="form-control"
                                            type="text"
                                            id="macros_name"
                                            name="macros_name"
                                            v-validate="'required|max:15'"
                                            v-model="macros_name"
                                    >
                                
                                    <small v-show="errors.has('macros_name')" class="form-text text-danger">
                                        <font-awesome-icon :icon="'times-circle'"/>
                                        {{ errors.first('macros_name') }}</small>
                                    
                                </div>
                            
                                <div class="form-group">
                                
                                    <label for="macros_category">{{ $t('default.category') }}</label>
                                
                                    <v-wait for="macros_categories">
                                    
                                        <loading slot="waiting"/>
                                    
                                        <multiselect
                                                v-validate="'required'"
                                                data-vv-name="macros_category"
                                                data-vv-value-path="macros_category"
                                                v-model="macros_category"
                                                :options="macros_categories"
                                                :allow-empty="false"
                                                :searchable="false"
                                                :show-labels="false"
                                                track-by="name"
                                                label="name"
                                                placeholder="Select one"
                                                name="macros_category"
                                                id="macros_category"
                                        />
                                    
                                        <small v-show="errors.has('macros_category')" class="form-text text-danger">
                                            <font-awesome-icon :icon="'times-circle'"/>
                                            {{ errors.first('macros_category') }}</small>
                                        
                                    </v-wait>
                                </div>
                            
                                <!-- Status -->
                                <div class="form-group">
                                
                                    <label for="macros_status">{{ $t('default.status') }}</label>
                                
                                    <b-form-select v-model="macros_status" class="form-control">
                                        <option :value="false">{{ $t('default.inactive') }}</option>
                                        <option :value="true">{{ $t('default.active') }}</option>
                                    </b-form-select>
                                
                                    <small v-show="errors.has('macros_status')" class="form-text text-danger">
                                        <font-awesome-icon :icon="'times-circle'"/>
                                        {{ errors.first('macros_status') }}</small>
                                    
                                </div>
                            
                                <!-- Image -->
                                <div class="form-group">
                                
                                    <label for="macros_status">{{ $t('default.image') }}</label>
                                
                                    <b-form-select v-model="macros_image_status" class="form-control">
                                        <option :value="false">{{ $t('default.disable') }}</option>
                                        <option :value="true">{{ $t('default.enable') }}</option>
                                    </b-form-select>
                                
                                    <small v-show="errors.has('macros_image_status')" class="form-text text-danger">
                                        <font-awesome-icon :icon="'times-circle'"/>
                                        {{ errors.first('macros_image_status') }}</small>
                                    
                                </div>
                            
                                <div class="form-group">
                                    <b-button block variant="success" @click="macro_validate">{{ $t('default.save') }}
                                    </b-button>
                                    <b-button block variant="primary" to="/settings/macros">{{ $t('default.cancel') }}
                                    </b-button>
                                </div>
                            
                            </form>
                        
                        </v-wait>
                    
                    </b-collapse>
                
                </div>

            </div>
        </template>
        <!-- Panel for smaller screens End -->

        <!-- Content for smaller screens -->
        <template v-if="!(macros_variable_status | macros_ticket)">
            <div  class="col-md-8 col-12 d-md-none">
                <!--  Reply  -->
                <div class="card shadow-widget mb-2">

                    <h5 class="card-header">{{ $t('macros.reply') }}</h5>

                    <div class="card-body">

                        <form>

                            <div class="form-group">

                                <b-form-textarea
                                        :rows="8"
                                        :max-rows="10"
                                        id="macros_content"
                                        name="macros_content"
                                        maxlength="2200"
                                        v-model="macros_content">
                                </b-form-textarea>

                                <small v-show="errors.has('macros_content')" class="form-text text-danger">
                                    <font-awesome-icon :icon="'times-circle'"/>
                                    {{ errors.first('macros_content') }}</small>

                            </div>

                        </form>

                        <!-- Character Limit Progress -->
                        <div class="my-2">
                            <b-progress :value="text_total"
                                        :max="max" variant="info"
                                        show-value
                                        animated
                                        v-b-tooltip.hover
                                        :title="$t('macros.character_limit')"></b-progress>
                        </div>
                        <div>
                            <b-progress show-value striped :max="max" class="mb-3">
                                <b-progress-bar variant="secondary"
                                                :value="text_total > 280 ? 280: text_total"
                                                v-b-tooltip.hover
                                                :title="$t('macros.twitter_mention_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total < 1000" variant="success"
                                                :value="text_total > 280 ? text_total - 280 : 0"
                                                v-b-tooltip.hover
                                                :title="$t('macros.twitter_DM_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total >= 1000"
                                                variant="success"
                                                :value="720"
                                                v-b-tooltip.hover
                                                :title="$t('macros.facebook_comment_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total < 2000"
                                                variant="primary"
                                                :value="text_total > 1000 ? text_total - 1000 : 0"
                                                v-b-tooltip.hover
                                                :title="$t('macros.facebook_comment_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total >= 2000"
                                                variant="primary"
                                                :value="1000"
                                                v-b-tooltip.hover
                                                :title="$t('macros.facebook_messenger_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total < 2200"
                                                variant="warning"
                                                :value="text_total > 2000 ? text_total - 2000 : 0"
                                                v-b-tooltip.hover
                                                :title="$t('macros.facebook_messenger_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total >= 2200"
                                                variant="warning"
                                                :value="200"
                                                v-b-tooltip.hover
                                                :title="$t('macros.instagram_comment_limit')"></b-progress-bar>

                            </b-progress>
                        </div>


                        <!-- Show variables -->
                        <b-button variant="outline-secondary" @click="change_macros_variable_status" class="mb-1">
                            <font-awesome-icon :icon="'code'"/>
                            {{ $t('macros.variables') }}
                        </b-button>

                        <!-- Show ticket -->
                        <b-button variant="outline-secondary" @click="change_macros_ticket" class="mb-1">
                            <font-awesome-icon :icon="'check-double'"/>
                            {{ $t('macros.ticket') }}
                        </b-button>

                        <!-- Show image template -->
                        <b-button v-if="macros_image_status" v-b-tooltip.hover title="Pixel" @click="pixel"
                                  variant="outline-secondary" class="mb-1">
                            <font-awesome-icon :icon="'image'"/>
                            {{ $t('macros.image') }}
                        </b-button>
                    </div>

                </div>
                <!-- Reply End -->

            </div>
        </template>
        <!-- Content for smaller screens End -->

        <!-- Macros -->
        <template v-if="macros_variable_status">
            <div class="card shadow-widget d-md-none">
            
                <h5 class="card-header d-flex">


                    
                    {{ $t('macros.variables') }}
                
                    <b-button :variant="'primary'" v-b-modal.macrosvariablemodal class="ml-auto my-2">
                        <font-awesome-icon :icon="'plus'"/>
                        {{ $t('default.add')}}
                    </b-button>

                    <b-button class="ml-1 my-2" :variant="'danger'" @click="change_macros_variable_status">
                        <font-awesome-icon :icon="'times'" />
                    </b-button>


                
                </h5>
            
                <div class="card-body">
                
                    <!-- Search -->
                    <b-row>
                        <b-col class="my-2">
                            <b-input-group>
                                <b-form-input v-model="macros_filter" :placeholder="$t('simple.type_to_search')"/>
                                <b-input-group-append>
                                    <b-btn :disabled="!macros_filter" @click="macros_filter = ''">{{
                                        $t('simple.clear') }}
                                    </b-btn>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <!-- Search end -->
                
                
                    <h5>{{ $t('macros.default') }}</h5>
                
                    <!-- Default variables list -->
                    <b-table responsive bordered hover show-empty :items="macros_variables_default"
                             :fields="macros_variables_default_fields" :filter="macros_filter" class="vh-25">
                
                        <!-- Name -->
                        <template slot="name" slot-scope="data">
                            <span v-pre>{{</span>{{ data.value }}<span v-pre>}}</span>
                        </template>
                    
                    </b-table>
                    <!-- Default variables End -->
                
                
                    <!-- Custom variables start-->
                    <h5>{{ $t('macros.custom') }}</h5>
                
                    <!-- Custom variables list -->
                    <b-table responsive bordered hover show-empty :items="macros_variables_custom"
                             :fields="macros_variables_custom_fields" :filter="macros_filter" class="vh-25">
                
                        <!-- Name -->
                        <template slot="name" slot-scope="data">
                            <span v-pre>{{</span>{{ data.value }}<span v-pre>}}</span>
                        </template>
                    
                        <!-- Delete -->
                        <template slot="cell(actions)" slot-scope="cell">
                            <b-button variant="outline-danger" @click="macros_variable_remove(cell.item.name)">{{
                                $t('default.delete') }}
                            </b-button>
                        </template>
                        <!-- Delete End -->
                    
                    </b-table>
                    <!-- Custom variables list end -->
                
                    <!-- Custom variables end -->
                
                </div>
            
            </div>
        </template>
        <!-- Macros End -->

        <!--  Ticket data  -->
        <template v-if="macros_ticket" >

            <div class="card shadow-widget mt-3 d-md-none">
        
                <h5 class="card-header d-flex">
                    

                    {{ $t('macros.ticket') }}
                    
                    <b-button class="ml-1 my-2 ml-auto" :variant="'danger'" @click="change_macros_ticket">
                        <font-awesome-icon :icon="'times'" />
                    </b-button>
                </h5>
        
                <div class="card-body">
            
                    <!-- Ticket form -->
                    <form @submit.prevent="validate_add">
                    
                        <!-- Title -->
                        <div class="form-group">
                        
                            <label for="macros_ticket_title" class="text-body">{{ $t('ticket.title') }}</label>
                            <input
                        
                                    class="form-control"
                                    type="text"
                                    id="macros_ticket_title"
                                    name="macros_ticket_title"
                                    v-model="macros_ticket_title"
                            >
                            <small v-show="errors.has('macros_ticket_title')" class="form-text text-danger">
                                <font-awesome-icon :icon="'times-circle'"/>
                                {{ errors.first('macros_ticket_title') }}</small>
                            
                        </div>
                    
                        <!-- Description -->
                        <div class="form-group">
                        
                            <label for="macros_ticket_desc" class="text-body">{{ $t('ticket.desc') }}</label>
                        
                            <b-form-textarea
                                    :rows="3"
                                    :max-rows="6"
                                    id="macros_ticket_desc"
                                    name="macros_ticket_desc"
                                    v-model="macros_ticket_desc">
                            </b-form-textarea>
                        
                            <small v-show="errors.has('macros_ticket_desc')" class="form-text text-danger">
                                <font-awesome-icon :icon="'times-circle'"/>
                                {{ errors.first('macros_ticket_desc') }}</small>
                            
                        </div>
                    
                        <!-- Status -->
                        <div class="form-group">
                        
                            <label for="macros_ticket_status" class="text-body">{{ $t('ticket.status') }}</label>
                        
                            <b-form-select v-model="macros_ticket_status">
                                <option v-for="status in macros_ticket_statuses" :key="status.id"
                                        :value="status.id">{{ $t('ticket.' + status.name) }}
                                </option>
                            </b-form-select>
                        </div>
                    
                    
                        <!-- Categories  -->
                        <div class="form-group">
                        
                            <label for="category" class="text-body">{{ $t('ticket.category') }}</label>
                        
                            <template v-if="categories">
                            
                                <treeselect
                                        v-model="macros_ticket_categories"
                                        :options="categories"
                                        :multiple="true"
                                        value-format="object"
                                        :placeholder="$t('ticket.category_select')"
                                        :flatten-search-results="true"
                                        :normalizer="normalizer"
                                >
                                    <label slot="option-label"
                                           slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                           :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>
                                
                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>
                                
                                </treeselect>
                            
                            </template>
                        
                        
                        </div>
                    
                        <!-- Tags  -->
                        <div class="form-group">
                        
                            <label for="categories" class="text-body">{{ $t('ticket.tags') }}</label>
                        
                            <template v-if="tags">
                            
                            
                                <treeselect
                                        v-model="macros_ticket_tags"
                                        :options="tags"
                                        :multiple="true"
                                        value-format="object"
                                        :flatten-search-results="true"
                                        :placeholder="$t('ticket.tag_select')"
                                        :normalizer="normalizer"
                                >
                                    <label slot="option-label"
                                           slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                           :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>
                                
                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>
                                
                                </treeselect>
                            
                            </template>
                        
                        </div>
                    
                        <!-- Users  -->
                        <div class="form-group">
                        
                            <label for="categories" class="text-body">{{ $t('default.users') }}</label>
                        
                            <template v-if="users">
                            
                            
                                <treeselect
                                        v-model="macros_ticket_users"
                                        :options="users"
                                        :multiple="true"
                                        value-format="object"
                                        :placeholder="$t('ticket.select_users')"
                                        :normalizer="normalizer"
                                >
                                    <label slot="option-label"
                                           slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                           :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>
                                
                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>
                                
                                </treeselect>
                            
                            </template>
                        
                        </div>
                    
                        <!-- Teams  -->
                        <div class="form-group">
                        
                            <label for="categories" class="text-body">{{ $t('default.teams') }}</label>
                        
                            <template v-if="teams">
                            
                                <treeselect
                                        v-model="macros_ticket_teams"
                                        :options="teams"
                                        :multiple="true"
                                        value-format="object"
                                        :placeholder="$t('ticket.select_teams')"
                                        :normalizer="normalizer"
                                >
                                    <label slot="option-label"
                                           slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                           :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>
                                
                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>
                                
                                </treeselect>
                            
                            </template>
                        
                        </div>
                    
                    
                    </form>
            
                </div>
        
            </div>
        </template>
        <!-- Ticket data End -->

        <!-- Content -->
        <div class="col-md-8 col-12 d-none d-md-block" >

                <!--  Reply  -->
                <div class="card shadow-widget mb-2">
                
                    <h5 class="card-header">{{ $t('macros.reply') }}</h5>
                
                    <div class="card-body">
                    
                        <form>
                        
                            <div class="form-group">
                            
                                <b-form-textarea
                                        :rows="8"
                                        :max-rows="10"
                                        id="macros_content"
                                        name="macros_content"
                                        maxlength="2200"
                                        v-model="macros_content">
                                </b-form-textarea>
                            
                                <small v-show="errors.has('macros_content')" class="form-text text-danger">
                                    <font-awesome-icon :icon="'times-circle'"/>
                                    {{ errors.first('macros_content') }}</small>
                                
                            </div>
                        
                        </form>
                    
                        <!-- Character Limit Progress -->
                        <div class="my-2">
                            <b-progress :value="text_total"
                                        :max="max" variant="info"
                                        show-value
                                        animated
                                        v-b-tooltip.hover
                                        :title="$t('macros.character_limit')"></b-progress>
                        </div>
                        <div>
                            <b-progress show-value striped :max="max" class="mb-3">
                                <b-progress-bar variant="secondary"
                                                :value="text_total > 280 ? 280: text_total"
                                                v-b-tooltip.hover
                                                :title="$t('macros.twitter_mention_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total < 1000" variant="success"
                                                :value="text_total > 280 ? text_total - 280 : 0"
                                                v-b-tooltip.hover
                                                :title="$t('macros.twitter_DM_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total >= 1000"
                                                variant="success"
                                                :value="720"
                                                v-b-tooltip.hover
                                                :title="$t('macros.facebook_comment_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total < 2000"
                                                variant="primary"
                                                :value="text_total > 1000 ? text_total - 1000 : 0"
                                                v-b-tooltip.hover
                                                :title="$t('macros.facebook_comment_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total >= 2000"
                                                variant="primary"
                                                :value="1000"
                                                v-b-tooltip.hover
                                                :title="$t('macros.facebook_messenger_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total < 2200"
                                                variant="warning"
                                                :value="text_total > 2000 ? text_total - 2000 : 0"
                                                v-b-tooltip.hover
                                                :title="$t('macros.facebook_messenger_limit')"></b-progress-bar>
                                <b-progress-bar v-if="text_total >= 2200"
                                                variant="warning"
                                                :value="200"
                                                v-b-tooltip.hover
                                                :title="$t('macros.instagram_comment_limit')"></b-progress-bar>
                                
                            </b-progress>
                        </div>
                    
                    
                        <!-- Show variables -->
                        <b-button variant="outline-secondary" @click="change_macros_variable_status" >
                            <font-awesome-icon :icon="'code'"/>
                            {{ $t('macros.variables') }}
                        </b-button>
                    
                        <!-- Show ticket -->
                        <b-button variant="outline-secondary" @click="change_macros_ticket">
                            <font-awesome-icon :icon="'check-double'"/>
                            {{ $t('macros.ticket') }}
                        </b-button>
                    
                        <!-- Show image template -->
                        <b-button v-if="macros_image_status" v-b-tooltip.hover title="Pixel" @click="pixel"
                                  variant="outline-secondary">
                            <font-awesome-icon :icon="'image'"/>
                            {{ $t('macros.image') }}
                        </b-button>
                    </div>
                
                </div>
                <!-- Reply End -->

                <!-- Macros -->
                <template v-if="macros_variable_status" class="d-md-none">
                    <div class="card shadow-widget">
                    
                        <h5 class="card-header d-flex">{{ $t('macros.variables') }}
                        
                            <b-button :variant="'primary'" v-b-modal.macrosvariablemodal class="ml-auto my-2">
                                <font-awesome-icon :icon="'plus'"/>
                                {{ $t('default.add')}}
                            </b-button>
                        
                        </h5>
                    
                        <div class="card-body">
                        
                            <!-- Search -->
                            <b-row>
                                <b-col class="my-2">
                                    <b-input-group>
                                        <b-form-input v-model="macros_filter" :placeholder="$t('simple.type_to_search')"/>
                                        <b-input-group-append>
                                            <b-btn :disabled="!macros_filter" @click="macros_filter = ''">{{
                                                $t('simple.clear') }}
                                            </b-btn>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                            </b-row>
                            <!-- Search end -->
                        
                        
                            <h5>{{ $t('macros.default') }}</h5>
                        
                            <!-- Default variables list -->
                            <b-table responsive bordered hover :items="macros_variables_default"
                                     :fields="macros_variables_default_fields" :filter="macros_filter" class="vh-25">
                        
                                <!-- Name -->
                                <template slot="name" slot-scope="data">
                                    <span v-pre>{{</span>{{ data.value }}<span v-pre>}}</span>
                                </template>
                            
                            </b-table>
                            <!-- Default variables End -->
                        
                        
                            <!-- Custom variables start-->
                            <h5>{{ $t('macros.custom') }}</h5>
                        
                            <!-- Custom variables list -->
                            <b-table responsive bordered hover :items="macros_variables_custom"
                                     :fields="macros_variables_custom_fields" :filter="macros_filter" class="vh-25">
                        
                                <!-- Name -->
                                <template slot="name" slot-scope="data">
                                    <span v-pre>{{</span>{{ data.value }}<span v-pre>}}</span>
                                </template>
                            
                                <!-- Delete -->
                                <template slot="cell(actions)" slot-scope="cell">
                                    <b-button variant="outline-danger" @click="macros_variable_remove(cell.item.name)">{{
                                        $t('default.delete') }}
                                    </b-button>
                                </template>
                                <!-- Delete End -->
                            
                            </b-table>
                            <!-- Custom variables list end -->
                        
                            <!-- Custom variables end -->
                        
                        </div>
                    
                    </div>
                </template>
                <!-- Macros End -->

                <!--  Ticket data  -->
                <template v-if="macros_ticket">
                
                    <div class="card shadow-widget mt-3">
                    
                        <h5 class="card-header">{{ $t('macros.ticket') }}</h5>
                    
                        <div class="card-body">
                        
                            <!-- Ticket form -->
                            <form @submit.prevent="validate_add">
                            
                                <!-- Title -->
                                <div class="form-group">
                                
                                    <label for="macros_ticket_title" class="text-body">{{ $t('ticket.title') }}</label>
                                    <input
                                
                                            class="form-control"
                                            type="text"
                                            id="macros_ticket_title"
                                            name="macros_ticket_title"
                                            v-model="macros_ticket_title"
                                    >
                                    <small v-show="errors.has('macros_ticket_title')" class="form-text text-danger">
                                        <font-awesome-icon :icon="'times-circle'"/>
                                        {{ errors.first('macros_ticket_title') }}</small>
                                    
                                </div>
                            
                                <!-- Description -->
                                <div class="form-group">
                                
                                    <label for="macros_ticket_desc" class="text-body">{{ $t('ticket.desc') }}</label>
                                
                                    <b-form-textarea
                                            :rows="3"
                                            :max-rows="6"
                                            id="macros_ticket_desc"
                                            name="macros_ticket_desc"
                                            v-model="macros_ticket_desc">
                                    </b-form-textarea>
                                
                                    <small v-show="errors.has('macros_ticket_desc')" class="form-text text-danger">
                                        <font-awesome-icon :icon="'times-circle'"/>
                                        {{ errors.first('macros_ticket_desc') }}</small>
                                    
                                </div>
                            
                                <!-- Status -->
                                <div class="form-group">
                                
                                    <label for="macros_ticket_status" class="text-body">{{ $t('ticket.status') }}</label>
                                
                                    <b-form-select v-model="macros_ticket_status">
                                        <option v-for="status in macros_ticket_statuses" :key="status.id"
                                                :value="status.id">{{ $t('ticket.' + status.name) }}
                                        </option>
                                    </b-form-select>
                                </div>
                            
                            
                                <!-- Categories  -->
                                <div class="form-group">
                                
                                    <label for="category" class="text-body">{{ $t('ticket.category') }}</label>
                                
                                    <template v-if="categories">
                                    
                                        <treeselect
                                                v-model="macros_ticket_categories"
                                                :options="categories"
                                                :multiple="true"
                                                value-format="object"
                                                :placeholder="$t('ticket.category_select')"
                                                :flatten-search-results="true"
                                                :normalizer="normalizer"
                                        >
                                            <label slot="option-label"
                                                   slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                                   :class="labelClassName">
                                                {{ node.raw.name }}
                                            </label>
                                        
                                            <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>
                                        
                                        </treeselect>
                                    
                                    </template>
                                
                                
                                </div>
                            
                                <!-- Tags  -->
                                <div class="form-group">
                                
                                    <label for="categories" class="text-body">{{ $t('ticket.tags') }}</label>
                                
                                    <template v-if="tags">
                                    
                                    
                                        <treeselect
                                                v-model="macros_ticket_tags"
                                                :options="tags"
                                                :multiple="true"
                                                value-format="object"
                                                :flatten-search-results="true"
                                                :placeholder="$t('ticket.tag_select')"
                                                :normalizer="normalizer"
                                        >
                                            <label slot="option-label"
                                                   slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                                   :class="labelClassName">
                                                {{ node.raw.name }}
                                            </label>
                                        
                                            <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>
                                        
                                        </treeselect>
                                    
                                    </template>
                                
                                </div>
                            
                                <!-- Users  -->
                                <div class="form-group">
                                
                                    <label for="categories" class="text-body">{{ $t('default.users') }}</label>
                                
                                    <template v-if="users">
                                    
                                    
                                        <treeselect
                                                v-model="macros_ticket_users"
                                                :options="users"
                                                :multiple="true"
                                                value-format="object"
                                                :placeholder="$t('ticket.select_users')"
                                                :normalizer="normalizer"
                                        >
                                            <label slot="option-label"
                                                   slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                                   :class="labelClassName">
                                                {{ node.raw.name }}
                                            </label>
                                        
                                            <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>
                                        
                                        </treeselect>
                                    
                                    </template>
                                
                                </div>
                            
                                <!-- Teams  -->
                                <div class="form-group">
                                
                                    <label for="categories" class="text-body">{{ $t('default.teams') }}</label>
                                
                                    <template v-if="teams">
                                    
                                        <treeselect
                                                v-model="macros_ticket_teams"
                                                :options="teams"
                                                :multiple="true"
                                                value-format="object"
                                                :placeholder="$t('ticket.select_teams')"
                                                :normalizer="normalizer"
                                        >
                                            <label slot="option-label"
                                                   slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                                   :class="labelClassName">
                                                {{ node.raw.name }}
                                            </label>
                                        
                                            <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>
                                        
                                        </treeselect>
                                    
                                    </template>
                                
                                </div>
                            
                            
                            </form>
                        
                        </div>
                    
                    </div>
                </template>
                <!-- Ticket data End -->

        </div>
        <!-- Content End -->

        <!-- Panel -->
        <div class="col-md-4 col d-none d-md-block">
            <!-- Right  -->
            <div class="card shadow-widget">
            
                <h5 class="card-header">{{ $t('macros.settings') }}</h5>
            
                <div class="card-body">
                
                    <v-wait for="add">
                    
                        <loading slot="waiting"/>
                    
                        <form @submit.prevent="macro_validate">
                        
                            <!-- Name -->
                            <div class="form-group">
                            
                                <label for="macros_name">{{ $t('default.name') }}</label>
                            
                                <input
                                        class="form-control"
                                        type="text"
                                        id="macros_name"
                                        name="macros_name"
                                        v-validate="'required|max:15'"
                                        v-model="macros_name"
                                >
                            
                                <small v-show="errors.has('macros_name')" class="form-text text-danger">
                                    <font-awesome-icon :icon="'times-circle'"/>
                                    {{ errors.first('macros_name') }}</small>
                                
                            </div>
                        
                            <div class="form-group">
                            
                                <label for="macros_category">{{ $t('default.category') }}</label>
                            
                                <v-wait for="macros_categories">
                                
                                    <loading slot="waiting"/>
                                
                                    <multiselect
                                            v-validate="'required'"
                                            data-vv-name="macros_category"
                                            data-vv-value-path="macros_category"
                                            v-model="macros_category"
                                            :options="macros_categories"
                                            :allow-empty="false"
                                            :searchable="false"
                                            :show-labels="false"
                                            track-by="name"
                                            label="name"
                                            placeholder="Select one"
                                            name="macros_category"
                                            id="macros_category"
                                    />
                                
                                    <small v-show="errors.has('macros_category')" class="form-text text-danger">
                                        <font-awesome-icon :icon="'times-circle'"/>
                                        {{ errors.first('macros_category') }}</small>
                                    
                                </v-wait>
                            </div>
                        
                            <!-- Status -->
                            <div class="form-group">
                            
                                <label for="macros_status">{{ $t('default.status') }}</label>
                            
                                <b-form-select v-model="macros_status" class="form-control">
                                    <option :value="false">{{ $t('default.inactive') }}</option>
                                    <option :value="true">{{ $t('default.active') }}</option>
                                </b-form-select>
                            
                                <small v-show="errors.has('macros_status')" class="form-text text-danger">
                                    <font-awesome-icon :icon="'times-circle'"/>
                                    {{ errors.first('macros_status') }}</small>
                                
                            </div>
                        
                            <!-- Image -->
                            <div class="form-group">
                            
                                <label for="macros_status">{{ $t('default.image') }}</label>
                            
                                <b-form-select v-model="macros_image_status" class="form-control">
                                    <option :value="false">{{ $t('default.disable') }}</option>
                                    <option :value="true">{{ $t('default.enable') }}</option>
                                </b-form-select>
                            
                                <small v-show="errors.has('macros_image_status')" class="form-text text-danger">
                                    <font-awesome-icon :icon="'times-circle'"/>
                                    {{ errors.first('macros_image_status') }}</small>
                                
                            </div>
                        
                            <div class="form-group">
                                <b-button block variant="success" @click="macro_validate">{{ $t('default.save') }}
                                </b-button>
                                <b-button block variant="primary" to="/settings/macros">{{ $t('default.cancel') }}
                                </b-button>
                            </div>
                        
                        </form>
                    
                    </v-wait>
                
                </div>
            
            </div>
        </div>
        <!-- Panel End -->



        <!-- Modal Component -->
        <b-modal
                id="macrosvariablemodal"
                ref="modal"
                :title="$t('macros.variable')"
                @ok="macros_variable_check"
                @shown="macros_variable_clear"
                centered
        >
            <!-- Form -->
            <b-form>

                <!-- Variables -->
                <b-form-group
                        id="name"
                        :label="$t('default.name')"
                >
                    <b-form-input type="text" required :placeholder="$t('default.name')"
                                  v-model="macros_variable_name"/>
                </b-form-group>

                <!-- Description -->
                <b-form-group
                        id="desc"
                        :label="$t('default.desc')"
                >
                    <b-form-input type="text" required :placeholder="$t('default.desc')"
                                  v-model="macros_variable_description"/>
                </b-form-group>
                <!-- Description End -->

            </b-form>
            <!-- Form End -->

        </b-modal>

    </div>

</template>


<script>

    import {mapGetters, mapActions} from 'vuex';

    import Treeselect from '@riophae/vue-treeselect';

    import {getTicketStatuses} from '../../utils/ticket.js';

    // Pixel
    import PixelRender from '../Module/Pixel/PixelRender.vue';

    // Create
    import {create} from 'vue-modal-dialogs'

    export default {

        props: ['macro'],

        components: {Treeselect},

        mounted: function () {
            // Component mounted
            console.log('Macros Setup component mounted.');

            // Update ticket data
            this.updateTicketData();
        },

        destroyed() {
            // Component destroyed
            console.log('Macros Setup component destroyed.');
        },

        created: function () {
            // Component created
            console.log('Macros Setup component created.');
            console.log(this.macro);
            console.log('this.macro');

            if (this.macros_categories) {
                this.fetchMacrosCategories();
            }

            if (this.macro) {

                // Macro Edit
                this.macros_name = this.macro.name;
                this.macros_category = this.macros_categories.find(messages => messages.id == this.macro.macros_category_id);
                console.log(this.macros_category);
                console.log(this.macros_categories);
                this.macros_status = this.macro.status;

                // Contents
                this.macros_content = this.macro.content;

                // Variables
                this.macros_variables_custom = JSON.parse(this.macro.variables);

                // Image
                this.macros_image_status = this.macro.image_status;
                this.macros_image = this.macro.image;

                // Ticket
                this.macros_ticket_title = this.macro.ticket_title;
                this.macros_ticket_desc = this.macro.ticket_desc;
                this.macros_ticket_status = this.macro.ticket_status;
                this.macros_ticket_categories = this.macro.categories;
                this.macros_ticket_tags = this.macro.tags;
                this.macros_ticket_users = this.macro.users;
                this.macros_ticket_teams = this.macro.teams;

            }

        },


        data: function () {
            return {
                macros_api: '/api/v1/settings/macros/',

                macros_category: [],
                macros_name: '',
                macros_content: '',
                macros_status: false,


                macros_filter: null,
                macros_variable_status: false,
                macros_variable_name: '',
                macros_variable_description: '',

                macros_variables_default: [

                    {
                        name: 'customer.id',
                        desc: this.$t('customer.id_desc')
                    },

                    {
                        name: 'customer.username',
                        desc: this.$t('customer.username_desc')
                    },

                    {
                        name: 'customer.identification',
                        desc: this.$t('customer.identification_desc')
                    },

                    {
                        name: 'customer.name',
                        desc: this.$t('customer.name_desc')
                    },

                    {
                        name: 'customer.surname',
                        desc: this.$t('customer.surname_desc')
                    },

                    {
                        name: 'customer.phone',
                        desc: this.$t('customer.phone_desc')
                    },

                    {
                        name: 'customer.email',
                        desc: this.$t('customer.email_desc')
                    },

                    {
                        name: 'ticket.case_id',
                        desc: this.$t('ticket.case_id_desc')
                    },

                    {
                        name: 'ticket.title',
                        desc: this.$t('ticket.title_desc')
                    },

                    {
                        name: 'ticket.desc',
                        desc: this.$t('ticket.description')
                    },

                    {
                        name: 'ticket.created_at',
                        desc: this.$t('ticket.created_at')
                    },

                    {
                        name: 'ticket.updated_at',
                        desc: this.$t('ticket.updated_at')
                    },

                ],

                macros_variables_default_fields: [
                    {key: 'name', label: this.$t('default.name'), sortable: true},
                    {key: 'desc', label: this.$t('default.desc'), sortable: true}
                ],


                macros_variables_custom: [],

                macros_variables_custom_fields: [
                    {key: 'name', label: this.$t('default.name'), sortable: true},
                    {key: 'desc', label: this.$t('default.desc'), sortable: true},
                    {key: 'actions', label: this.$t('default.actions')},
                ],

                // Image
                macros_image: '',
                macros_image_status: false,

                // Ticket
                macros_ticket: false,
                macros_ticket_statuses: getTicketStatuses,
                macros_ticket_title: '',
                macros_ticket_desc: '',
                macros_ticket_status: 1,
                macros_ticket_categories: [],
                macros_ticket_tags: [],
                macros_ticket_users: [],
                macros_ticket_teams: [],
                // Maximum Macros Content Character length
                max: 2200,

                settings_collapsed: true,

            }
        },

        metaInfo: function () {
            return {
                title: this.$t('page.macros'),
            }
        },

        methods: {

            ...mapActions('Ticket', [
                'updateTicketData',
            ]),

            ...mapActions('Macro', [
                'fetchMacrosCategories',
            ]),

            // Macro Add
            macro_add: async function () {

                // Loading start
                this.$wait.start('add');

                // Post request
                await axios.post(this.macros_api, {

                    // Default
                    name: this.macros_name,
                    category_id: this.macros_category.id,
                    status: this.macros_status,

                    content: this.macros_content,

                    // Variables
                    variables: JSON.stringify(this.macros_variables_custom),

                    // Image
                    image_status: this.macros_image_status,
                    image: this.macros_image,

                    // Ticket
                    ticket_title: this.macros_ticket_title,
                    ticket_desc: this.macros_ticket_desc,
                    ticket_status: this.macros_ticket_status,
                    ticket_categories: this.macros_ticket_categories,
                    ticket_tags: this.macros_ticket_tags,
                    ticket_users: this.macros_ticket_users,
                    ticket_teams: this.macros_ticket_teams,

                })
                    .then((response) => {

                        // Show success
                        Vue.toasted.success(this.$t('simple.success_info'));

                        // Loading end
                        this.$wait.end('add');

                        // Close modal
                        this.$router.push({path: '/settings/macros'});


                    })
                    .catch((error) => {

                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));

                        // Log to console
                        console.log(error);


                        // Loading end
                        this.$wait.end('add');

                    });

            },


            macro_update: async function (id) {

                // Loading start
                this.$wait.start('add');

                // Post request
                await axios.put(this.macros_api + id, {

                    // Default
                    name: this.macros_name,
                    category_id: this.macros_category.id,
                    status: this.macros_status,

                    content: this.macros_content,

                    // Variables
                    variables: JSON.stringify(this.macros_variables_custom),

                    // Image
                    image_status: this.macros_image_status,
                    image: this.macros_image,

                    // Ticket
                    ticket_title: this.macros_ticket_title,
                    ticket_desc: this.macros_ticket_desc,
                    ticket_status: this.macros_ticket_status,
                    ticket_categories: this.macros_ticket_categories,
                    ticket_tags: this.macros_ticket_tags,
                    ticket_users: this.macros_ticket_users,
                    ticket_teams: this.macros_ticket_teams,

                })
                    .then((response) => {

                        // Show success
                        Vue.toasted.success(this.$t('simple.success_info'));

                        // Loading end
                        this.$wait.end('add');

                        // Close modal
                        this.$router.push({path: '/settings/macros'});


                    })
                    .catch((error) => {

                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));

                        // Log to console
                        console.log(error);


                        // Loading end
                        this.$wait.end('add');

                    });

            },


            // Normalizer
            normalizer: function (node) {
                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                }
            },

            // Validate forms
            macro_validate: async function () {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        if (this.macro) {
                            this.macro_update(this.macro.id)
                        } else {
                            this.macro_add();
                        }
                    }
                    console.log(result);
                });
            },

            // Macros check
            macros_variable_check: function (event) {

                // Prevent default
                event.preventDefault();

                // Check name and desc
                if (!this.macros_variable_name || !this.macros_variable_description) {
                    alert(this.$t('macros.check_missing'));
                } else {
                    // Try to find it in array
                    let result = this.macros_variables_custom.find(mv => mv.name === this.macros_variable_name);
                    // Check if we have result?
                    if (!result) {
                        // We don't have it in array, so push it
                        this.macros_variable_add();

                    } else {
                        // Inform user about error
                        alert(this.$t('macros.check_duplicate'));
                    }
                }

            },

            // Macros add
            macros_variable_add: function () {

                // Push macros to variables
                this.macros_variables_custom.push(
                    {
                        name: this.macros_variable_name,
                        desc: this.macros_variable_description,
                        value: '',
                    },
                );

                // Clear
                this.macros_variable_clear();

                // Hide it
                this.$nextTick(() => {
                    // Wrapped in $nextTick to ensure DOM is rendered before closing
                    this.$refs.modal.hide()
                })
            },

            // Remove macros from array
            macros_variable_remove: function (name) {
                // Search and remove it
                this.macros_variables_custom = this.macros_variables_custom.filter(mv => mv.name !== name);
            },

            // Clear macros?
            macros_variable_clear: function () {
                // Set macros to default
                this.macros_variable_name = '';
                this.macros_variable_description = '';
            },

            // Pixel
            pixel: async function () {

                // Show pixel modal
                const pixel_render = create(PixelRender, 'template_image_json', 'template_variables_default', 'template_variables_custom', 'template_type');

                // Result
                this.macros_image = await pixel_render(this.macros_image, this.macros_variables_default, this.macros_variables_custom, 1);

                // Macros image variable
                console.log(this.macros_image);
            },

            change_macros_variable_status() {
                this.macros_variable_status = !this.macros_variable_status;
                this.macros_ticket = false;
            },

            change_macros_ticket() {
                this.macros_ticket = !this.macros_ticket;
                this.macros_variable_status = false;
            },

        },

        computed: {

            // Map getters
            ...mapGetters({

                categories: 'Ticket/getCategories',
                tags: 'Ticket/getTags',
                users: 'Ticket/getUsers',
                teams: 'Ticket/getTeams',
                macros_categories: 'Macro/getMacrosCategories',

                // Integration types limit
                integration_type_limit: 'Accounts/getIntegrationTypeLimit',

            }),

            // Count total
            count_total() {

                // Return result
                return (2200 - this.text_total);
            },

            text_total() {

                // Return text length
                return this.macros_content ? this.macros_content.length : 0;

            },


            get_variant() {

                if (this.count_total > 0) {

                    return 'normal';

                } else if (this.count_total == 0) {

                    return 'success'
                } else {

                    return 'exception';
                }

            },


        },

    }
</script>