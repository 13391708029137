<template>
    <b-container fluid class="px-lg-5 px-0">
        <h4>{{ $t('macros.create_template') }}</h4>

        <v-wait for="template_message_setup">
            <loading slot="waiting" />
        <b-row>
            <b-col>
                
                        
                <ValidationObserver ref="main">
                    <b-card class="mb-1">
                        <template #header>
                            <div class="d-flex align-items-center">
                                <div><span class="font-weight-bold">{{ $t('macros.template_details') }}</span></div>
                                <!-- <b-button variant="info" pill @click="showTicketDetails = !showTicketDetails"
                                    class="ml-auto p-1">
                                    <font-awesome-icon :icon="'caret-down'" class="mr-1" v-if="!showTicketDetails" />
                                    <font-awesome-icon :icon="'caret-up'" class="mr-1" v-else />
                                    {{ $t('macros.ticket_details') }}
                                </b-button> -->
                            </div>
                        </template>
                        <ValidationProvider rules="required" v-slot="{ errors, valid }">
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_language" class="font-weight-bold">
                                        {{ $t('simple.account') }}<span class="text-danger">*</span>:</label>
                                </b-col>
                                <b-col sm="8">
                                    <treeselect 
                                        v-model="selected_account"
                                        :options="whatsapp_accounts" 
                                        :multiple="false" 
                                        value-format="object"
                                        :placeholder="$t('simple.account')" 
                                        :normalizer="normalizer">
                                        <label slot="option-label" slot-scope="{ node }">
                                            <font-awesome-icon :style="{ color: node.raw.color }"
                                                :icon="['fab', node.raw.integration.icon]" />
                                            {{ node.raw.name }}
                                        </label>
                                        <div slot="value-label" slot-scope="{ node }">
                                            <font-awesome-icon :style="{ color: node.raw.color }"
                                                :icon="['fab', node.raw.integration.icon]" />
                                            <span>{{ node.raw.name }}</span>
                                        </div>
                                    </treeselect>
                                </b-col>
                            </b-row>
                        </ValidationProvider>
                        <ValidationProvider rules="required|alpha_dash|small_letter" v-slot="{ errors, valid }">
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_name" class="font-weight-bold">
                                        {{ $t('macros.template_name') }}<span class="text-danger">*</span>:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input id="template_message_name" name="template_message_name"
                                        v-model="template_message.name" :state="valid"></b-form-input>
                                    <small class="text-muted"> {{ $t('macros.template_elementName_desc') }}</small>
                                </b-col>
                            </b-row>
                        </ValidationProvider>
                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="template_message_category" class="font-weight-bold">{{ $t('default.category')
                                }}<span class="text-danger">*</span>:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-select v-model="template_message.category" :options="template_message_categories"
                                    @change="resetTemplateMessage"></b-form-select>
                            </b-col>
                        </b-row>
                        <b-row class="align-items-center mb-3">
                            <b-col sm="3"></b-col>
                            <b-col sm="8">
                                <b-form-checkbox v-model="template_message.allow_category_change"
                                    class="font-weight-bold">{{ $t('macros.template_category_change_desc')
                                    }}</b-form-checkbox>
                            </b-col>
                        </b-row>
                        <ValidationProvider rules="required" v-slot="{ errors, valid }">
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_language" class="font-weight-bold">
                                        {{ $t('simple.language') }}<span class="text-danger">*</span>:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-select id="template_message_language" name="template_message_language"
                                        v-model="template_message.language_code" :options="language_list"
                                        :state="valid"></b-form-select>
                                </b-col>
                            </b-row>
                        </ValidationProvider>
                        <ValidationProvider rules="required" v-slot="{ errors, valid }">
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_vertical" class="font-weight-bold">
                                        {{ $t('macros.template_vertical') }}<span class="text-danger">*</span>:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input id="template_message_vertical" name="template_message_vertical"
                                        :state="valid" v-model="template_message.vertical"
                                        :maxlength="template_message_settings.vertical_limit"></b-form-input>
                                    <small class="text-muted"> {{ $t('macros.template_vertical_desc') }}</small>
                                </b-col>
                            </b-row>
                        </ValidationProvider>
                    </b-card>
                </ValidationObserver>


                <!-- <b-card v-if="showTicketDetails" class="mb-1">
                    <template #header>
                        <div class="d-flex align-items-center">
                            <span class="font-weight-bold">{{ $t('macros.ticket_details') }}</span>
                            <b-button variant="danger" pill @click="resetTicketDetails" class="ml-auto">
                                {{ $t('default.reset') }} {{ $t('ticket.ticket') }}
                            </b-button>
                        </div>
                    </template>

                    <b-container fluid class="p-0">
                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="ticket_title" class="font-weight-bold">
                                    {{ $t('ticket.title') }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-input id="ticket_title" name="ticket_title"
                                    v-model="template_message.ticket.title"></b-form-input>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="ticket_desc" class="font-weight-bold">{{ $t('ticket.description') }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-textarea id="ticket_desc" v-model="template_message.ticket.description" rows="5"
                                    max-rows="7"></b-form-textarea>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="ticket_status" class="font-weight-bold">{{ $t('ticket.status') }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-select v-model="template_message.ticket.status">
                                    <option v-for="status in ticket_statuses" :key="status.id" :value="status.id">{{
                                        $t('ticket.' + status.name) }}
                                    </option>
                                </b-form-select>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="ticket_categories" class="font-weight-bold">{{ $t('ticket.category_select')
                                }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <treeselect v-model="template_message.ticket.categories" :options="categories"
                                    :multiple="true" value-format="object" :placeholder="$t('ticket.category_select')"
                                    :flat="true" :autoSelectAncestors="true" :autoDeselectDescendants="true"
                                    :flatten-search-results="true" :normalizer="normalizer">
                                    <label slot="option-label"
                                        slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                        :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>

                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>

                                </treeselect>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="ticket_tags" class="font-weight-bold">{{ $t('ticket.tags') }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <treeselect v-model="template_message.ticket.tags" :options="tags" :multiple="true"
                                    value-format="object" :flatten-search-results="true"
                                    :placeholder="$t('ticket.tag_select')" :normalizer="normalizer">
                                    <label slot="option-label"
                                        slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                        :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>

                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>

                                </treeselect>
                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="ticket_users" class="font-weight-bold">{{ $t('default.users') }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <treeselect v-model="template_message.ticket.users" :options="users" :multiple="true"
                                    value-format="object" :placeholder="$t('ticket.select_users')" :normalizer="normalizer">
                                    <label slot="option-label"
                                        slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                        :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>

                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>

                                </treeselect>

                            </b-col>
                        </b-row>

                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="ticket_teams" class="font-weight-bold">{{ $t('default.teams') }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <treeselect v-model="template_message.ticket.teams" :options="teams" :multiple="true"
                                    value-format="object" :placeholder="$t('ticket.select_teams')" :normalizer="normalizer">
                                    <label slot="option-label"
                                        slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                                        :class="labelClassName">
                                        {{ node.raw.name }}
                                    </label>

                                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }}</div>

                                </treeselect>

                            </b-col>
                        </b-row>

                    </b-container>
                </b-card> -->


                <b-card v-if="template_message.category" class="mb-1">
                    <template #header>
                        <span class="font-weight-bold">{{ $t('macros.template_details') }}</span>
                    </template>

                    <!-- TODO -->
                    <ValidationObserver ref="header">
                        <b-card v-if="template_message.category !== 'AUTHENTICATION'" class="mb-1">
                            <template #header>
                                <span class="font-weight-bold">{{ $t('macros.header') }}</span> <span> ( {{
                                    $t('macros.optional') }} )</span>
                            </template>

                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_header_type" class="font-weight-bold">{{
                                        $t('macros.header_type') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-select v-model="template_message.type"
                                        :options="template_message_header_options" @change="resetHeader"></b-form-select>
                                </b-col>
                            </b-row>

                            <b-container fluid v-if="template_message.type === 'TEXT'" class="p-0">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="template_message_header_text" class="font-weight-bold">{{
                                            $t('macros.header') }}:</label>
                                    </b-col>
                                    <b-col sm="8">
                                            <b-form-input id="template_message_vertical" v-model="template_message.header"

                                                :maxlength="template_message_settings.header.text_limit"></b-form-input>
                                            <small class="text-muted"> {{ $t('macros.header_desc', {
                                                header_limit: template_message_settings.header.text_limit
                                            }) }}</small>
                                    </b-col>
                                </b-row>
                            </b-container>
                            <b-container fluid v-if="template_message.type === 'IMAGE'" class="p-0">
                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="template_message_media">{{ $t('simple.file') }}:</label>
                                        </b-col>
                                        <b-col sm="12" lg="4" class="mb-3">
                                            <div :class="{ 'isInvalid': !file }"
                                                class="media-upload-container d-flex justify-content-center align-items-center">
                                                <div class="position-absolute">
                                                    <div class="d-flex flex-column align-items-center text-center">
                                                        <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                        <span class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-if="!file_url">{{ $t('macros.attach_file') }}</span>
                                                        <span class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-else>{{ $t('macros.change_file') }}</span>
                                                        <span
                                                            class="media-upload-inner-container text-muted font-weight-normal">(JPEG
                                                            or PNG)</span>
                                                        <span class="media-upload-inner-container text-muted font-weight-normal">
                                                            (Max 2MB)</span>
                                                    </div>
                                                </div>
                                                <b-form-file id="template_message_media" v-model="file"
                                                    :accept="template_message_settings.header.image_accepted_types" plain
                                                    @input="addFile(file, template_message_settings.header.image_size_limit)"
                                                    class="media-upload-button h-100 w-100">
                                                </b-form-file>
                                            </div>
                                        </b-col>
                                        <b-col sm="4" v-if="file_url" class="mb-3">
                                            <div class="position-relative media-preview-container">
                                                <b-img fluid :src="file_url" class="media-preview" />
                                                <b-btn size="sm" class="preview-remove-button position-absolute" variant="danger"
                                                    @click="removeFile">
                                                    <font-awesome-icon :icon="'times'" />
                                                </b-btn>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </ValidationProvider>
                            </b-container>

                            <b-container fluid v-if="template_message.type === 'VIDEO'" class="p-0">
                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="template_message_media">{{ $t('simple.file') }}:</label>
                                        </b-col>
                                        <b-col sm="12" lg="4" class="mb-3">
                                            <div :class="{ 'isInvalid': !file }"
                                                class="media-upload-container d-flex justify-content-center align-items-center">
                                                <div class="position-absolute">
                                                    <div class="d-flex flex-column align-items-center text-center">
                                                        <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                        <span class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-if="!file_url">{{ $t('macros.attach_file') }}</span>
                                                        <span class="media-upload-inner-container text-muted font-weight-normal"
                                                            v-else>{{ $t('macros.change_file') }}</span>
                                                        <span class="media-upload-inner-container text-muted font-weight-normal">(
                                                            MP4 )</span>
                                                        <span class="media-upload-inner-container text-muted font-weight-normal">
                                                            (Max 20 MB)</span>
                                                    </div>
                                                </div>
                                                <b-form-file id="template_message_media" v-model="file"
                                                    :accept="template_message_settings.header.video_accepted_types" plain
                                                    @input="addFile(file, template_message_settings.header.video_size_limit)"
                                                    class="media-upload-button h-100 w-100">
                                                </b-form-file>
                                            </div>
                                        </b-col>
                                        <b-col sm="4" v-if="file_url" class="mb-3">
                                            <div class="position-relative media-preview-container">
                                                <video :src="file_url" class="media-preview"> </video>
                                                <font-awesome-icon :icon="'play-circle'"
                                                    class="preview-video-button position-absolute p-0" />
                                                <b-btn size="sm" class="preview-remove-button position-absolute" variant="danger"
                                                    @click="removeFile">
                                                    <font-awesome-icon :icon="'times'" />
                                                </b-btn>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </ValidationProvider>
                            </b-container>

                            <!-- <b-container fluid v-if="template_message.type === 'DOCUMENT'" class="p-0">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="template_message_media">{{ $t('simple.file') }}:</label>
                                    </b-col>
                                    <b-col sm="12" lg="4" class="mb-3">
                                        <div :class="{ 'isInvalid': !file }"
                                            class="media-upload-container d-flex justify-content-center align-items-center">
                                            <div class="position-absolute">
                                                <div class="d-flex flex-column align-items-center text-center">
                                                    <font-awesome-icon :icon="'folder-open'" class="text-primary" />
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-if="!file_url">{{ $t('macros.attach_file') }}</span>
                                                    <span class="media-upload-inner-container text-muted font-weight-normal"
                                                        v-else>{{ $t('macros.change_file') }}</span>
                                                    <span class="media-upload-inner-container text-muted font-weight-normal">(
                                                        PDF )</span>
                                                    <span class="media-upload-inner-container text-muted font-weight-normal">
                                                        (Max 2 MB)</span>
                                                </div>
                                            </div>
                                            <b-form-file id="template_message_media" v-model="file"
                                                :accept="template_message_settings.header.document_accepted_types" plain
                                                @input="addFile(file, template_message_settings.header.document_size_limit)"
                                                class="media-upload-button h-100 w-100">
                                            </b-form-file>
                                        </div>
                                    </b-col>
                                    <b-col sm="4" v-if="file_url" class="mb-3">
                                        <div class="position-relative media-preview-container d-flex">
                                            <div class="align-self-center p-1">
                                                <font-awesome-icon :icon="'file'" />
                                                <span>{{ file.name }}</span>
                                            </div>
                                            <b-btn size="sm" class="preview-remove-button position-absolute" variant="danger"
                                                @click="removeFile">
                                                <font-awesome-icon :icon="'times'" />
                                            </b-btn>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>

                            <b-container fluid v-if="template_message.type === 'LOCATION'" class="p-0">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="location" class="font-weight-bold">
                                            {{ $t('customer.location') }}:</label>
                                    </b-col>
                                    <b-col sm="6">
                                        <gmap-autocomplete @place_changed="setPlace" ref="autocomplete"
                                            :options="{ fields: ['geometry', 'formatted_address', 'address_components'] }"
                                            :class="locationClasses" class="form-control">
                                        </gmap-autocomplete>
                                    </b-col>
                                    <b-col sm="2" class="my-1">
                                        <b-button variant="danger" @click="clearAddress">{{ $t('simple.clear') }} </b-button>
                                    </b-col>
                                </b-row>

                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="location" class="font-weight-bold">
                                            {{ $t('customer.address') }}:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <b-form-input disabled :value="place ? place.formatted_address : ''"></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3"></b-col>
                                    <b-col sm="8">
                                        <gmap-map style="width: 200px; height: 104.5px;" :zoom="5" :center="center">
                                            <gmap-marker v-if="place" :position="{
                                                lat: place.geometry.location.lat(),
                                                lng: place.geometry.location.lng(),
                                            }">
                                            </gmap-marker>
                                        </gmap-map>
                                    </b-col>
                                </b-row>


                            </b-container> -->
                        </b-card>
                    </ValidationObserver>

                    <ValidationObserver ref="body">
                        <b-card class="mb-1">
                            <template #header>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="font-weight-bold">{{ $t('macros.body') }}</span>
                                    <b-button variant="info" pill class="ml-auto p-1" @click="addCustomVariable"
                                        v-if="template_message.category !== 'AUTHENTICATION' && template_message.custom_variables.length === 0">
                                        <font-awesome-icon :icon="'plus'" class="mr-1" />{{ $t('macros.custom') }}
                                    </b-button>
                                </div>
                            </template>
                            <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                <b-row class="align-items-center mb-3">
                                    <b-col sm="3">
                                        <label for="template_message_content" class="font-weight-bold">
                                            {{ $t('macros.body_text') }}<span class="text-danger">*</span>:</label>
                                    </b-col>
                                    <b-col sm="8">
                                        <div class="position-relative">
                                            <b-form-textarea name="template_message_content"
                                                ref="template_message_content"
                                                v-model="template_message.content" :disabled="isContentDisabled" rows="5"
                                                max-rows="6" :maxlength="template_message_settings.content_limit"
                                                :state="valid"></b-form-textarea>
                                            <coolpicker v-if="template_message.category !== 'AUTHENTICATION'"
                                                class="coolpicker-button" :emojiData="emojiDataAll"
                                                :emojiGroups="emojiGroups" :btnEmojiClasses="['btn-emoji']"
                                                @emojiUnicodeAdded="template_message.content += $event"
                                                twemojiPath="https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/"
                                                twemojiExtension=".svg" twemojiFolder="svg"></coolpicker>
                                        </div>
                                        <small class="text-muted"> {{ $t('macros.body_desc', {
                                            body_limit: template_message_settings.content_limit
                                        }) }}</small>
                                    </b-col>
                                </b-row>
                            </ValidationProvider>
                            <b-row class="align-items-center mb-3" v-if="template_message.category === 'AUTHENTICATION'">
                                <b-col sm="3"></b-col>
                                <b-col sm="8">
                                    <b-form-checkbox v-model="template_message.addSecurityRecommendation"
                                        class="font-weight-bold">{{ $t('macros.auth_security_desc') }}</b-form-checkbox>
                                </b-col>
                            </b-row>

                            <div class="p-1 p-lg-3" v-if="template_message.custom_variables.length > 0">
                                <b-card class="mb-3 position-relative" bg-variant="light" border-variant="info">
                                    <template #header>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="mr-auto"><span class="font-weight-bold">{{ $t('macros.custom') }}:</span></div>
                                            <b-button pill class="p-sm-0 p-md-1 mr-2" variant="outline-success"
                                                @click="addCustomVariable"
                                                v-if="template_message.category !== 'AUTHENTICATION'">
                                                <font-awesome-icon :icon="'plus'" class="mr-1" />{{ $t('default.add') }}
                                            </b-button>
                                            <b-button pill class="p-sm-0 p-md-1" variant="outline-danger"
                                                @click="removeCustomVariable"
                                                v-if="template_message.category !== 'AUTHENTICATION'">
                                                <font-awesome-icon :icon="'minus'" class="mr-1" />{{ $t('default.remove') }}
                                            </b-button>
                                        </div>
                                    </template>
                                    <b-row class="align-items-center mb-3">
                                        <b-col>
                                            <b-list-group>
                                                <b-list-group-item class="d-flex align-items-center mb-1"
                                                    v-for=" (custom_variable) in template_message.custom_variables"
                                                    :key="custom_variable">
                                                    <span>{{ custom_variable }}</span>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-col>
                                    </b-row>

                                </b-card>
                            </div>
                        </b-card>
                    </ValidationObserver>

                    <!-- TODO: Footer -->
                    <!-- <b-card class="mb-1">
                        <template #header>
                            <span class="font-weight-bold">{{ $t('macros.footer') }}</span> <span> ( {{
                                $t('macros.optional') }} )</span>
                        </template>
                        <b-row class="align-items-center mb-3" v-if="template_message.category !== 'AUTHENTICATION'">
                            <b-col sm="3">
                                <label for="template_message_footer_text" class="font-weight-bold">{{ $t('macros.footer')
                                }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-input id="template_message_footer_text" v-model="template_message.footer"
                                    :maxlength="template_message_settings.footer_limit"></b-form-input>
                                <small class="text-muted"> {{ $t('macros.footer_desc', {
                                    header_limit: template_message_settings.footer_limit
                                }) }}</small>
                            </b-col>
                        </b-row>
                        <b-row class="align-items-center mb-3" v-else>
                            <b-col sm="12" class="mb-3">
                                <b-form-checkbox v-model="iscodeExpirationMinutesActive" @change="checkExpirationMinutes"
                                    class="font-weight-bold">
                                    {{ $t('macros.expiration_warning') }}</b-form-checkbox>
                            </b-col>

                            <b-col v-if="iscodeExpirationMinutesActive">
                                <b-row>
                                    <b-col sm="2">
                                        <b-form-input value="NUM_MINUTES" disabled>
                                        </b-form-input>
                                    </b-col>
                                    <b-col sm="2">
                                        <b-form-input v-model="template_message.codeExpirationMinutes" type="number" min="1"
                                            max="90">
                                        </b-form-input>
                                    </b-col>
                                </b-row>
                                <small class="text-muted"> {{ $t('macros.expiration_desc', {
                                    header_limit: template_message_settings.footer_limit
                                }) }}</small>
                            </b-col>
                        </b-row>
                    </b-card> -->

                    

                    <ValidationObserver ref="buttons">
                    <!-- TODO: Authentication Buttons -->

                    <!-- <b-card v-if="template_message.category === 'AUTHENTICATION'" class="mb-1">
                        <template #header>
                            <span class="font-weight-bold">{{ $t('macros.buttons') }}</span> <span> ( {{
                                $t('macros.optional') }} )</span>
                        </template>
                        <b-row class="align-items-center mb-3">
                            <b-col sm="3">
                                <label for="template_message_button" class="font-weight-bold">{{ $t('macros.button_type')
                                }}:</label>
                            </b-col>
                            <b-col sm="8">
                                <b-form-select v-model="otp_button_type" :options="otpButtonOptions"></b-form-select>
                            </b-col>
                        </b-row>

                        <template v-if="otp_button_type === 'copy_code'">
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_button_text" class="font-weight-bold">{{
                                        $t('macros.button_text') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input v-model="template_message.buttons.otp.copy_code.text"
                                        :maxlength="template_message_settings.button_limit">
                                    </b-form-input>
                                    <small class="text-muted"> {{ $t('macros.button_description', {
                                        button_limit: template_message_settings.button_limit
                                    }) }}
                                    </small>
                                </b-col>
                            </b-row>
                        </template>

                        <template v-if="otp_button_type === 'one_tap'">

                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_button_text" class="font-weight-bold">{{
                                        $t('macros.button_text') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input v-model="template_message.buttons.otp.one_tap.text"
                                        :maxlength="template_message_settings.button_limit">
                                    </b-form-input>
                                    <small class="text-muted"> {{ $t('macros.button_description', {
                                        button_limit: template_message_settings.button_limit
                                    }) }}
                                    </small>
                                </b-col>
                            </b-row>
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_button_autofill" class="font-weight-bold">{{
                                        $t('macros.autofill_text') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input v-model="template_message.buttons.otp.one_tap.autofill_text">
                                    </b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_button_package_name" class="font-weight-bold">{{
                                        $t('macros.package_name') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input v-model="template_message.buttons.otp.one_tap.package_name">
                                    </b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_button_signature_hash" class="font-weight-bold">{{
                                        $t('macros.signature_hash') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input v-model="template_message.buttons.otp.one_tap.signature_hash">
                                    </b-form-input>
                                </b-col>
                            </b-row>

                        </template>
                    </b-card> -->

                    <!-- TODO: Marketing/Utility Buttons -->

                        <b-card v-if="template_message.category === 'MARKETING' || template_message.category === 'UTILITY'" class="mb-1">
                            <template #header>
                                <div><span class="font-weight-bold">{{ $t('macros.buttons') }}</span> <span> ( {{$t('macros.optional') }} )</span></div>
                            </template>
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_button" class="font-weight-bold">{{ $t('macros.button_type')
                                    }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-select id="template_message_button" v-model="button_type" :options="template_message.category === 'MARKETING' ? marketingButtonOptions :  utilityButtonOptions"
                                        @change="resetButtons"></b-form-select>
                                        <small class="text-muted" v-if="button_type === 'button_list'"> {{ $t('macros.button_list_desc') }}</small>
                                </b-col>
                            </b-row>
                            <b-row class="align-items-center mb-3">
                                <b-col>
                                    <b-btn v-if="button_type !== null"  class="mr-1" variant="info" size="sm" @click="addQuickReplyButton"> 
                                        + {{ $t('macros.quick_reply') }}
                                    </b-btn>
                      
                                    <b-btn v-if="button_type !== null" variant="info" size="sm" @click="addPhoneButton"> 
                                        + {{ $t('simple.phone_number') }}
                                    </b-btn>
                                </b-col>
                            </b-row>

                            <template v-if="button_type !== null">
                                <b-card class="mb-4" v-for=" (button, button_index) in template_message.buttons"
                                        :key="button_index">
                                    <template #header>
                                        <span class="font-weight-bold">{{ $t('macros.button') }} - {{ button_index + 1 }}</span>    
                                    </template>
                                    <b-card class="interactive-list-section mb-3 position-relative" bg-variant="light"
                                        border-variant="info"
                                        >
                                        <b-row class="align-items-center mb-3">
                                            <b-col sm="3">
                                                <label for="button_list_option" class="font-weight-bold">
                                                    {{ $t('macros.button_type') }}<span
                                                        class="text-danger">*</span>:</label>
                                                <b-form-input  id="button_list_option" 
                                                    v-model="button.type" 
                                                    disabled>
                                                </b-form-input>
                                            </b-col>
                                            <b-col :sm="button.type === 'PHONE_NUMBER' ? 4 : 8">
                                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                                    <label for="button_text" class="font-weight-bold">
                                                    {{ $t('macros.button_text') }}<span
                                                        class="text-danger">*</span>:</label>
                                                    <b-form-input 
                                                    id="button_text"
                                                    v-model="button.text"
                                                    :maxlength="template_message_settings.button_text_limit"
                                                    :state="valid"
                                                    ></b-form-input>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-if="button.type === 'PHONE_NUMBER'">
                                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                                    <label for="button_phone" class="font-weight-bold">
                                                    {{ $t('simple.phone_number') }}<span
                                                        class="text-danger">*</span>:</label>
                                                    <b-form-input 
                                                    id="button_phone"
                                                    v-model="button.phone_number"
                                                    :maxlength="button.type === template_message_settings.button_phone_text_limit"
                                                    :state="valid"
                                                    ></b-form-input>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="12">
                                                <small class="text-muted" v-if="button.type === 'PHONE_NUMBER'"> {{ $t('macros.phone_number_desc') }}</small>
                                            </b-col>
                                        </b-row>
                                        <b-btn size="sm" class="list-item-remove-button position-absolute" variant="danger" @click="removeButton(button_index)">
                                            <font-awesome-icon :icon="'trash-alt'" />
                                        </b-btn>

                                    </b-card>
                                </b-card>
                            </template>

                        </b-card>
                    </ValidationObserver>

                    <b-row class="align-items-center justify-content-between mx-2 mt-3">
                        <b-button variant="danger" to="/settings/template-messages">{{ $t('default.cancel') }} </b-button>
                        <b-button variant="success" type="submit" @click.prevent="saveTemplateMessage" :disabled="!selected_account">{{
                            $t('macros.send_approval') }}</b-button>
                    </b-row>
                </b-card>
                
            </b-col>
        </b-row>
        </v-wait>
    </b-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
import { getTicketStatuses } from '../../../utils/ticket.js';
import Treeselect from '@riophae/vue-treeselect';




import {
    TwemojiPicker
} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';

Validator.extend('small_letter', (value) => {
    return /^[a-z0-9_]+$/.test(value)
});
export default {


    components: {
        'coolpicker': TwemojiPicker,
        ValidationObserver,
        ValidationProvider,
        Treeselect
    },

    created() {

        // this.updateTicketData();
        

    },

    updated() {
    },

    data() {
        return {
            selected_account: null,

            showTicketDetails: false,
            ticket_statuses: getTicketStatuses,


            file: null,
            file_url: '',

            button_type: null,
            button_list_option : 'QUICK_REPLY',

            place: null,
            center: {
                lat: 41.043822,
                lng: 28.851220,
            },

            iscodeExpirationMinutesActive: false,


            otpButtonOptions: [
                { value: null, text: 'None' },
                { value: 'copy_code', text: 'Copy Code' },
                { value: 'one_tap', text: 'Autofill' },
            ],


            template_message: {
                name: '',
                language_code: 'en',
                category: 'MARKETING',
                allow_category_change: true,
                vertical: '',
                type: 'TEXT',
                content: '',
                // example: '',

                // addSecurityRecommendation: false,
                // codeExpirationMinutes: 0,

                custom_variables: [],

                buttons: [],

                header: '',

                // footer: '',

                // ticket: {
                //     title: '',
                //     description: '',
                //     status: 1,
                //     categories: [],
                //     tags: [],
                //     users: [],
                //     teams: [],
                // }

            },
        }
    },

    methods: {

        ...mapActions('Ticket', [
            'updateTicketData',
        ]),
        ...mapActions('Templates', [
            'createTemplateMessage',
        ]),


        resetTemplateMessage() {

            this.removeFile();

            this.place = null;

            this.template_message = {
                name: this.template_message.name,
                category: this.template_message.category,
                language_code: this.template_message.language_code,
                allow_category_change: this.template_message.allow_category_change,
                vertical: this.template_message.vertical,
                type: 'TEXT',
                content: '',
                // example: '',

                // addSecurityRecommendation: false,
                // codeExpirationMinutes: 0,

                custom_variables: [],

                buttons: [],

                header: '',

                // footer: '',

                // ticket: {
                //     title: '',
                //     description: '',
                //     status: 1,
                //     categories: [],
                //     tags: [],
                //     users: [],
                //     teams: [],
                // }


            }

        },

        resetTicketDetails() {

            this.template_message.ticket = {
                title: '',
                description: '',
                status: 1,
                categories: [],
                tags: [],
                users: [],
                teams: [],
            }
        },

        normalizer: function (node) {
            return {
                id: node.id,
                label: node.name,
                children: node.children,
            }
        },

        resetHeader() {

            this.removeFile();

            // this.place = null;

            this.template_message.header = ''
        },

        resetButtons() {
            this.template_message.buttons = []
        },

        async saveTemplateMessage() {


            const isMainValid = await this.$refs.main.validate();
            const isHeadersValid = await this.$refs.header.validate();
            const isBodyValid = await this.$refs.body.validate();
            const isButtonsValid = await this.$refs.buttons.validate();

            if (!isMainValid || !isBodyValid || !isButtonsValid || !isHeadersValid) return

            this.template_message.buttons.sort( (a,b) => a.type.localeCompare(b.type))

            if(this.file) {
                const formData = new FormData();
                formData.append('example_media', this.file);
                Object.keys(this.template_message).forEach(key => {
                    if( key === 'allow_category_change') {
                        if(this.template_message[key] === true) {
                            formData.append(key, '1');
                        } else {
                            formData.append(key, '0');
                        }
                    } else {
                        formData.append(key, this.template_message[key]);

                    }
                })

                await this.createTemplateMessage({id: this.selected_account.id, template: formData })
                .then( (response) => {
                    this.$router.push({ path: '/settings/template-messages' });
                 })

            } else {
                await this.createTemplateMessage({id: this.selected_account.id, template: this.template_message })
                    .then( (response) => {
                        this.$router.push({ path: '/settings/template-messages' });
                     })
            }
        },

        addFile(file, limit) {
            if (file) {
                if (this.checkFileSize(file, limit)) {
                    this.file_url = URL.createObjectURL(file);

                } else {
                    Vue.toasted.error(this.$t('macros.size_limit_alert', {
                        size_limit: limit,
                    }));

                    this.removeFile();
                }
            }
        },

        

        checkFileSize(file, size_limit) {
            if (((file.size / 1024) / 1024).toFixed(4) > size_limit) {
                return false
            }

            return true
        },

        removeFile() {
            this.file = null;
            this.file_url = '';
        },

        // setPlace(place) {
        //     if (!place) return
        //     this.place = place
        //     this.center = {
        //         lat: place.geometry.location.lat(),
        //         lng: place.geometry.location.lng(),
        //     }
        //     this.template_message.header.longitude = place.geometry.location.lng();
        //     this.template_message.header.latitude = place.geometry.location.lat();


        // },

        // clearAddress() {
        //     this.$refs.autocomplete.$el.value = '';
        //     this.place = null;
        //     this.template_message.header.longitude = '';
        //     this.template_message.header.latitude = '';
        // },

        addText(text) {

            let textArea = this.$refs.template_message_content

            let cursor = textArea.selectionEnd

            textArea.focus()

            this.template_message.content = this.template_message.content.substring(0, cursor) + text + this.template_message.content.substring(cursor);

        },

        addCustomVariable() {

            let len = this.template_message.custom_variables.length ? this.template_message.custom_variables.length : 0
            this.template_message.custom_variables.push(`{{${len + 1}}}`)
            let text = this.template_message.custom_variables[len]

            this.addText(text)

        },

        removeCustomVariable() {
            let removed = this.template_message.custom_variables.pop()
            this.template_message.content = this.template_message.content.replace(removed, '')
        },

        checkExpirationMinutes(value) {
            if (value === false) {
                this.template_message.codeExpirationMinutes = 0;
            } else {
                this.template_message.codeExpirationMinutes = 1;
            }
        },

        // TODO: Add check later for Phone number and url
        addQuickReplyButton() {
            if (this.totalButtonsNumber < this.template_message_settings.buttons_limit) {
                this.template_message.buttons.push(                   
                    { 
                        type: 'QUICK_REPLY',
                        text: ''
                    })
            } else {
                Vue.toasted.error(this.$t('macros.item_limit_alert', {
                    item_limit: this.template_message_settings.buttons_limit
                }));
            }
        },

        addPhoneButton() {
            if (this.totalPhoneButtonsNumber < this.template_message_settings.button_phone_limit) {
                this.template_message.buttons.push(                   
                    { 
                        type: 'PHONE_NUMBER',
                        text: '',
                        phone_number: ''
                    })
            } else {
                Vue.toasted.error(this.$t('macros.item_limit_alert', {
                    item_limit: this.template_message_settings.button_phone_limit
                }));
            }
        },

        removeButton(button_index) {
            this.template_message.buttons.splice(button_index, 1);
        },

    },

    computed: {

        // Emoji data
        emojiDataAll() {
            return EmojiAllData;
        },
        // Emoji Groups
        emojiGroups() {
            return EmojiGroups;
        },

        ...mapGetters({
            template_message_categories: 'Templates/getTemplateMessageCategories',
            template_message_settings: 'Templates/getTemplateMessageSettings',
            template_message_header_options: 'Templates/getTemplateMessageHeaderOptions',
            marketingButtonOptions: 'Templates/getMarketingButtonOptions',
            utilityButtonOptions: 'Templates/getUtilityButtonOptions',
            buttonListOptions: 'Templates/getButtonListOptions',

            //TODO: Only added for tr and en
            // https://support.gupshup.io/hc/en-us/articles/360013321939
            language_list: 'User/getLanguageList',


            categories: 'Ticket/getCategories',
            tags: 'Ticket/getTags',
            users: 'Ticket/getUsers',
            teams: 'Ticket/getTeams',

            accounts: 'Accounts/getAccounts',

        }),

        // locationClasses() {
        //     return {
        //         'is-valid': !!this.template_message.header.longitude,
        //         'is-invalid': !!!this.template_message.header.longitude,
        //     }
        // },

        template_category() {
            return this.template_message.category
        },

        isContentDisabled() {
            if (this.template_category === 'AUTHENTICATION') {
                return true
            } else {
                return false
            }
        },

        security_recommendation() {
            return this.template_message.addSecurityRecommendation
        },

        totalButtonsNumber() {
            return this.template_message.buttons.length;
        },

        totalPhoneButtonsNumber() {
            const phone_buttons = this.template_message.buttons.filter( button => button.type === 'PHONE_NUMBER')
            return phone_buttons.length;
        },

        whatsapp_accounts() {
            return this.accounts.filter(account => account.integration_id === 'whatsapp')
        }

    },

    watch: {

        template_category(newVal) {
            if (newVal === 'AUTHENTICATION') {
                this.$nextTick(() => {
                    this.addCustomVariable()
                    this.template_message.content += ' is your verification code.'
                })
            }
        },

        template_category(newVal) {
            if (newVal === 'AUTHENTICATION') {
                this.$nextTick(() => {
                    this.addCustomVariable()
                    this.template_message.content += ' is your verification code.'
                })
            }
        },

        security_recommendation(newVal) {
            this.$nextTick(() => {
                if (newVal === true) {
                    this.template_message.content += ' For your security, do not share this code.'
                } else {
                    this.template_message.content = this.template_message.content.replace(' For your security, do not share this code.', '')
                }
            })
        }




    }

}


</script>