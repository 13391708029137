<template>

    <div class="row">

        <div class="col pt-2">

            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.configurations') }}</h4>

                        <div class="ml-auto align-self-center">


                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.configurations_desc') }}</span>

                </div>

                <hr>
                <v-wait for="accounts">
                    <loading slot="waiting" />

                    <v-wait for="configurations">
                        <loading slot="waiting" />
                        <!-- Page content -->
                        <div class="px-3">
                            <span><strong>{{ $t('player.player') }}</strong></span>
                            <span class="text-muted font-weight-light"> - {{ $t('configuration.player_desc') }}</span>
                            <hr>

                            <b-container fluid>
                                <b-row>
                                    <b-col class="pr-1 col-sm-12 col-md-2" v-b-tooltip.hover
                                        :title="$t('configuration.player_mode_desc')">{{ $t('configuration.player_mode')
                                        }}</b-col>
                                    <b-col class="col-sm-12 col-md-4">
                                        <div>
                                            <b-form-group>
                                                <b-form-radio v-b-tooltip.hover
                                                    :title="$t('configuration.gamification_desc')" v-model="player_mode"
                                                    name="some-radios" value="1"><span>{{ $t('configuration.inboxzero')
                                                        }}</span></b-form-radio>
                                                <b-form-radio v-b-tooltip.hover.bottom
                                                    :title="$t('configuration.inboxzero_desc')" v-model="player_mode"
                                                    name="some-radios" value="2"><span>{{
                                                        $t('configuration.gamification')
                                                        }}</span></b-form-radio>
                                            </b-form-group>
                                        </div>
                                    </b-col>
                                </b-row>
                                <hr>
                                <b-row class="pb-3">
                                    <b-col class="col-sm-12 col-md-2 pb-2" v-b-tooltip.hover
                                        :title="$t('configuration.gamification_counter_desc')">{{
                                            $t('configuration.gamification_counter') }}</b-col>
                                    <b-col>
                                        <div>
                                            <b-form-spinbutton id="gmf-counter" v-model="gamification_counter" min="1"
                                                max="10" inline></b-form-spinbutton>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col class="col-sm-12 col-md-2 col-xl-2 pb-2" v-b-tooltip.hover
                                        :title="$t('configuration.gamification_last_reply_counter_desc')">{{
                                            $t('configuration.gamification_last_reply_counter') }}</b-col>
                                    <b-col>
                                        <div>
                                            <b-form-spinbutton id="last-reply-count"
                                                v-model="gamification_last_reply_counter" min="1" max="10"
                                                inline></b-form-spinbutton>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>

                            <hr>
                            <span><strong>{{ $t('configuration.warnings') }}</strong></span>
                            <span class="text-muted font-weight-light"> - {{ $t('configuration.warnings_desc') }}</span>
                            <hr>

                            <b-container fluid>
                                <b-row>
                                    <b-col class="col-sm-12 col-md-2 pb-2" v-b-tooltip.hover
                                        :title="$t('configuration.message_frequency_desc')">{{
                                            $t('configuration.message_frequency') }}</b-col>
                                    <b-col>
                                        <div>
                                            <b-form-spinbutton id="warn-count" v-model="warn_message_count" min="25"
                                                inline></b-form-spinbutton>
                                        </div>
                                    </b-col>
                                </b-row>

                                <hr>

                                <b-row>
                                    <div class="col-sm-12 col-md-2 pb-2" v-b-tooltip.hover
                                        :title="$t('configuration.popular_account_desc')">
                                        {{ $t('configuration.popular_account') }}
                                    </div>
                                    <b-col>
                                        <div>
                                            <b-form-spinbutton id="warn-popular" v-model="warn_popular_account_count"
                                                min="10000" max="Infinity" step="1000" inline></b-form-spinbutton>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>
                            <hr>
                            <span class="mt-3"><strong>{{
                                $t('configuration.translation_configuration') }}</strong></span>
                            <span class="text-muted font-weight-light"> - {{
                                $t('configuration.translation_desc') }}</span>
                            <hr>
                            <b-container fluid>
                                <ConfigurationCheckbox :label="$t('configuration.translation_status')"
                                    :value="translationActive" @handleChange="setTranslationActive($event)" />

                                <template v-if="translationActive === 'true' || translationActive === true">
                                    <br>
                                    <PasswordInput :label="$t('configuration.translation_api_key')"
                                        :value="translationApiKey"
                                        @handlePasswordChange="setTranslationApiKey($event)" />
                                </template>
                            </b-container>
                            <hr>
                            <!-- TODO: may change it according to new notification center settings -->
                            <span class="mt-3"><strong>{{ $t('configuration.notification_configuration')
                                    }}</strong></span>
                            <span class="text-muted font-weight-light"> - {{ $t('configuration.notification_desc')
                                }}</span>
                            <hr>

                            <b-container fluid>
                                <ConfigurationCheckbox :label="$t('configuration.notification_status')"
                                    :value="notificationActive" @handleChange="setNotificationActive($event)" />

                                <template v-if="notificationActive === 'true' || notificationActive === true">
                                    <br>
                                    <SendForSelection title="Send Notification For"
                                        :label="$t('configuration.send_for')" :value="notificationSendFor"
                                        :radioButtonOptions="sendForOptions"
                                        @handleRadioButtonChange="handleNotificationSendFor($event)"
                                        :integration_value="notificationSelectedIntegrations"
                                        @handleIntegrationChange="setNotificationSelectedIntegrations($event)"
                                        :account_value="notificationSelectedAccounts"
                                        @handleAccountChange="setNotificationSelectedAccounts($event)" />
                                    <br>


                                    <TimeSelection :time_from="notification_time_from"
                                        @handleTimeFrom="handleNotificationTimeFrom($event)"
                                        :time_to="notification_time_to"
                                        @handleTimeTo="handleNotificationTimeTo($event)" />
                                    <br>

                                    <ConfigurationCheckboxGroup 
                                        :value="notificationMessageType"
                                        @handleChange="setNotificationMessageType($event)"
                                        :options="messageTypeOptions"
                                    />
                                    
                                </template>
                            </b-container>
                            <hr>
                            <span class="mt-3"><strong>{{ $t('configuration.whatsapp_configuration')
                                    }}</strong></span>
                            <span class="text-muted font-weight-light"> - {{ $t('configuration.whatsapp_desc')
                                }}</span>
                            <hr>

                            <b-container fluid>
                                <ConfigurationCheckbox :label="$t('configuration.notification_status')"
                                    :value="whatsappActive" @handleChange="setWhatsappActive($event)" />

                                <template v-if="whatsappActive === 'true' || whatsappActive === true">
                                    <br>
                                    <SendForSelection title="Send Notification For"
                                        :label="$t('configuration.send_for')" :value="whatsappSendFor"
                                        :radioButtonOptions="sendForOptions"
                                        @handleRadioButtonChange="handleWhatsappSendFor($event)"
                                        :integration_value="whatsappSelectedIntegrations"
                                        @handleIntegrationChange="setWhatsappSelectedIntegrations($event)"
                                        :account_value="whatsappSelectedAccounts"
                                        @handleAccountChange="setWhatsappSelectedAccounts($event)" />
                                    <br>

                                    <TimeSelection :time_from="whatsapp_time_from"
                                        @handleTimeFrom="handleWhatsappTimeFrom($event)" :time_to="whatsapp_time_to"
                                        @handleTimeTo="handleWhatsappTimeTo($event)" />

                                    <br>
                                    <ConfigurationCheckboxGroup 
                                        :value="whatsappMessageType"
                                        @handleChange="setWhatsappMessageType($event)"
                                        :options="messageTypeOptions"
                                    />
                                </template>
                            </b-container>
                            <hr>
                            <span class="mt-3"><strong>{{ $t('configuration.email_configuration') }}</strong></span>
                            <span class="text-muted font-weight-light"> - SMTP</span>
                            <hr>

                            <b-container fluid>
                                <ConfigurationCheckbox :label="$t('configuration.email_configuration_smtp')"
                                    title="Email Configuration(SMTP)" :value="emailActive"
                                    @handleChange="setEmailActive($event)" />

                                <template v-if="emailActive === 'true' || emailActive === true">
                                    <br>
                                    <b-row>
                                        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover title="Email">{{
                                            $t('simple.email') }} :</b-col>
                                        <b-col sm="12" md="5">
                                            <div>
                                                <b-form-input v-model="email_address"></b-form-input>
                                            </div>

                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover
                                            title="Email host">{{
                                                $t('configuration.email_host')
                                            }}</b-col>
                                        <b-col sm="12" md="5">
                                            <div>
                                                <b-form-input v-model="email_host"></b-form-input>
                                            </div>

                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover
                                            title="email_username">{{
                                                $t('configuration.email_username') }}</b-col>
                                        <b-col sm="12" md="5">
                                            <div>
                                                <b-form-input v-model="email_username"></b-form-input>
                                            </div>

                                        </b-col>
                                    </b-row>
                                    <br>
                                    <PasswordInput :label="$t('configuration.email_password')" :value="emailPassword"
                                        @handlePasswordChange="setEmailPassword($event)" />
                                    <br>
                                    <b-row>
                                        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover
                                            title="Email port">{{
                                                $t('configuration.email_port')
                                            }}</b-col>
                                        <b-col sm="12" md="5">
                                            <div>
                                                <b-form-input v-model="email_port"></b-form-input>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row>
                                        <b-col sm="12" md="3" lg="2" class="mb-2"></b-col>
                                        <b-col sm="12" md="5">
                                            <b-btn class="d-flex align-items-center flex-nowrap"
                                                @click="handleTestEmail"
                                                :variant="emailVerified === 'sent' ? 'success' : 'danger'">
                                                <div class="text-nowrap">{{ $t('simple.send_test_email') }} - </div>
                                                <v-wait for="test_email" class="ml-1 d-flex align-items-center">
                                                    <b-spinner variant="light" small slot="waiting"></b-spinner>
                                                    <template v-if="emailVerified === 'sent'">
                                                        <span> {{ $t('simple.email_verified') }}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span> {{ $t('simple.email_not_verified') }}</span>
                                                    </template>
                                                </v-wait>
                                            </b-btn>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <SendForSelection title="Send Email For" :label="$t('configuration.send_for')"
                                        :value="emailSendFor" :radioButtonOptions="sendForOptions"
                                        @handleRadioButtonChange="handleEmailSendFor($event)"
                                        :integration_value="emailSelectedIntegrations"
                                        @handleIntegrationChange="setEmailSelectedIntegrations($event)"
                                        :account_value="emailSelectedAccounts"
                                        @handleAccountChange="setEmailSelectedAccounts($event)" />
                                    <br>

                                    <TimeSelection :time_from="email_time_from"
                                        @handleTimeFrom="handleEmailTimeFrom($event)" :time_to="email_time_to"
                                        @handleTimeTo="handleEmailTimeTo($event)" />

                                    <br>
                                    <ConfigurationCheckboxGroup 
                                        :value="emailMessageType"
                                        @handleChange="setEmailMessageType($event)"
                                        :options="messageTypeOptions"
                                    />
                                    
                                </template>
                            </b-container>

                            <hr>
                            <span><strong>{{ $t('configuration.ticket_notify') }}</strong></span>
                            <span class="text-muted font-weight-light"> - {{ $t('configuration.ticket_notify_desc')
                                }}</span>
                            <hr>

                            <b-container fluid>
                                <ConfigurationCheckbox :label="$t('configuration.ticket_notify_status')"
                                    :value="ticketReminderActive" @handleChange="setTicketReminderActive($event)" />

                                <template v-if="ticketReminderActive === 'true' || ticketReminderActive === true">
                                    <br>
                                    <b-row>
                                        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover>{{
                                            $t('ticket.ticket') }} {{ $t('ticket.status') }}</b-col>
                                        <b-col sm="12" md="5">
                                            <treeselect v-model="ticket_status" :multiple="true"
                                                :placeholder="$t('ticket.status_select')" :options="ticket_statuses"
                                                :normalizer="normalizerTicketReminder">
                                            </treeselect>
                                        </b-col>
                                    </b-row>
                                    <br>
                                    <b-row class="align-items-center">
                                        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover>
                                            {{ $t('configuration.ticket_notify_cycle') }}
                                            <b-button v-if="ticket_reminder_cycle.length > 0" size="sm"
                                                class="bg-light rounded-circle border-success"
                                                @click="addTicketReminder"><font-awesome-icon :icon="'plus'"
                                                    class="text-success" /> </b-button>
                                        </b-col>
                                        <b-col sm="12" md="5">
                                            <template v-if="ticket_reminder_cycle.length === 0">
                                                <b-button size="sm"
                                                    class="bg-light rounded-pill border-success text-success"
                                                    @click="addTicketReminder"><font-awesome-icon :icon="'plus'"
                                                        class="" /> {{ $t('default.add') }} </b-button>
                                            </template>
                                            <template v-else>
                                                <b-card class="flex-column flex-xl-row p-1" no-body
                                                    v-for="(reminder, reminder_index) in ticket_reminder_cycle"
                                                    :key="ticket_reminder_cycle.day">
                                                    <b-input-group :append="$t('configuration.ticket_notify_cycle_day')"
                                                        class="mr-1">
                                                        <b-form-input type="number" min="1" max="365"
                                                            v-model="reminder.day"></b-form-input>
                                                    </b-input-group>
                                                    <b-input-group
                                                        :append="$t('configuration.ticket_notify_cycle_hour')"
                                                        class="mt-1 mt-xl-0 mr-1">
                                                        <b-form-input type="time" min="00:00" max="23:00" step="3600"
                                                            v-model="reminder.hour"></b-form-input>
                                                    </b-input-group>
                                                    <b-button size="sm"
                                                        class="bg-light rounded-lg border-danger text-danger mt-1 mt-xl-0"
                                                        @click="removeTicketReminder(reminder_index)">
                                                        <font-awesome-icon :icon="'times'" />
                                                    </b-button>
                                                </b-card>
                                            </template>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-container>

                            <hr>
                            <span><strong>{{ $t('configuration.report_notify') }}</strong></span>
                            <span class="text-muted font-weight-light"> - {{ $t('configuration.report_notify_desc')
                                }}</span>
                            <hr>

                            <b-container fluid>
                                <ConfigurationCheckbox :label="$t('default.status')"
                                    :value="reportNotifyActive" @handleChange="setReportNotifyActive($event)" />

                                <template v-if="reportNotifyActive === 'true' || reportNotifyActive === true">
                                    <br>
                                        <b-row>
                                            <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover>{{
                                                $t('reports.reports') }}</b-col>
                                            <b-col sm="12" md="5">
                                                <treeselect v-model="report_notify_type" :multiple="true"
                                                    :placeholder="$t('reports.reports')" :options="reportNotifyTypeOptions"
                                                    :normalizer="normalizerReportNotify">
                                                </treeselect>
                                            </b-col>
                                        </b-row>
                                    <br>
                                        <b-row>
                                            <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover>{{ $t('configuration.time_range') }}</b-col>
                                            <b-col sm="12" md="5">
                                                <treeselect v-model="report_notify_interval" :multiple="true"
                                                    :placeholder="$t('default.select')" :options="reportNotifyIntervalOptions"
                                                    :normalizer="normalizerReportNotify">
                                                </treeselect>
                                            </b-col>
                                        </b-row>
                                    
                                    </template>
                            </b-container>

                        </div>
                    </v-wait>

                </v-wait>


                <hr>
                <!-- Page footer -->


                <div class="py-3 px-3">
                    <b-button class="px-3" variant="success" @click="updateConfigurations">{{ $t('default.save')
                        }}</b-button>
                </div>


            </div>


        </div>

    </div>

</template>


<script>


import { mapActions, mapGetters, mapMutations } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import { getTicketStatuses } from '../../utils/ticket.js';
import ConfigurationCheckbox from './Configurations/Checkbox.vue'
import PasswordInput from './Configurations/PasswordInput.vue'
import SendForSelection from "./Configurations/SendForSelection.vue";
import TimeSelection from "./Configurations/TimeSelection.vue";
import ConfigurationCheckboxGroup from "./Configurations/CheckboxGroup.vue";


export default {

    components: {
        Treeselect,
        ConfigurationCheckbox,
        PasswordInput,
        SendForSelection,
        TimeSelection,
        ConfigurationCheckboxGroup
    },

    async mounted() {
        // Component mounted
        console.log('Configurations component mounted.')
        await this.fetchConfigurations();

    },

    destroyed() {
        // Component destroyed
        console.log('Configurations component destroyed.');
    },

    data: function () {
        return {
            ticket_statuses: getTicketStatuses,
        }
    },

    metaInfo: function () {

        return {
            title: this.$t('page.configurations'),
        }

    },

    methods: {

        // Map actions
        ...mapActions('Configurations', [
            'fetchConfigurations',
            'updateConfigurations',
            'verifyEmailAddress',
            'appendTicketReminderCycle',
        ]),

        // Map Mutations
        ...mapMutations('Configurations', [
            'setPlayerMode',
            'setGamificationCounter',
            'setGamificationLastReplyCounter',
            'setWarnMessageCount',
            'setWarnPopularAccountCount',
            'setEmail',
            'setEmailActive',
            'setEmailHost',
            'setEmailUsername',
            'setEmailPassword',
            'setEmailPort',
            'setEmailSendFor',
            'setEmailSelectedIntegrations',
            'setEmailSelectedAccounts',
            'setEmailMessageType',
            'setEmailTimeFrom',
            'setEmailTimeTo',
            'setNotificationActive',
            'setNotificationSendFor',
            'setNotificationSelectedIntegrations',
            'setNotificationSelectedAccounts',
            'setNotificationMessageType',
            'setNotificationTimeFrom',
            'setNotificationTimeTo',
            'setWhatsappActive',
            'setWhatsappSendFor',
            'setWhatsappSelectedIntegrations',
            'setWhatsappSelectedAccounts',
            'setWhatsappMessageType',
            'setWhatsappTimeFrom',
            'setWhatsappTimeTo',
            'setTranslationActive',
            'setTranslationApiKey',
            'setEmailVerified',
            'setTicketReminderActive',
            'setTicketReminderTicketStatus',
            'setTicketReminderCycle',
            'setReportNotifyActive',
            'setReportNotifyType',
            'setReportNotifyInterval',
        ]),

        normalizerTicketReminder: function (node) {
            return {
                id: node.id,
                label: this.$t('ticket.' + node.name)
            }
        },

        normalizerReportNotify: function (node) {
            return {
                id: node.value,
                label: this.$t('reports.' + node.text)
            }
        },

        addTicketReminder() {
            const reminder = {
                day: '1',
                hour: '15:00'
            }
            this.appendTicketReminderCycle(reminder)
        },

        removeTicketReminder(index) {
            const reminders = [...this.ticket_reminder_cycle]
            reminders.splice(index, 1);
            this.setTicketReminderCycle(reminders)
        },

        async handleTestEmail() {
            const email_data = {
                email_host: this.email_host,
                email_username: this.email_username,
                email_password: this.email_password,
                email_port: this.email_port
            }

            await this.verifyEmailAddress(email_data)

        },

        handleNotificationSendFor(value) {
            if (value == 'all') {
                this.setNotificationSelectedIntegrations([])
                this.setNotificationSelectedAccounts([])
            } else if (value == 'integration') {
                this.setNotificationSelectedAccounts([])
            } else if (value == 'account') {
                this.setNotificationSelectedIntegrations([])
            }
            this.setNotificationSendFor(value);
        },

        handleWhatsappSendFor(value) {
            if (value == 'all') {
                this.setWhatsappSelectedIntegrations([])
                this.setWhatsappSelectedAccounts([])
            } else if (value == 'integration') {
                this.setWhatsappSelectedAccounts([])
            } else if (value == 'account') {
                this.setWhatsappSelectedIntegrations([])
            }
            this.setWhatsappSendFor(value);
        },

        handleEmailSendFor(value) {
            if (value == 'all') {
                this.setEmailSelectedIntegrations([])
                this.setEmailSelectedAccounts([])
            } else if (value == 'integration') {
                this.setEmailSelectedAccounts([])
            } else if (value == 'account') {
                this.setEmailSelectedIntegrations([])
            }
            this.setEmailSendFor(value);
        },

        handleNotificationTimeFrom(value) {
            this.setNotificationTimeFrom(this.$moment(this.$moment.utc(value)).format('HH:mm:ss'));
        },

        handleNotificationTimeTo(value) {
            this.setNotificationTimeTo(this.$moment(this.$moment.utc(value)).format('HH:mm:ss'));
        },

        handleWhatsappTimeFrom(value) {
            this.setWhatsappTimeFrom(this.$moment(this.$moment.utc(value)).format('HH:mm:ss'));
        },

        handleWhatsappTimeTo(value) {
            this.setWhatsappTimeTo(this.$moment(this.$moment.utc(value)).format('HH:mm:ss'));
        },

        handleEmailTimeFrom(value) {
            this.setEmailTimeFrom(this.$moment(this.$moment.utc(value)).format('HH:mm:ss'));
        },

        handleEmailTimeTo(value) {
            this.setEmailTimeTo(this.$moment(this.$moment.utc(value)).format('HH:mm:ss'));
        },
    },

    computed: {

        // Map getters
        ...mapGetters({

            user: 'User/getUser',

            // Player Mode
            playerMode: 'Configurations/getPlayerMode',

            // Gamification Count
            gamificationCounter: 'Configurations/getGamificationCounter',

            // Gamification Last Reply Count
            gamificationLastReplyCounter: 'Configurations/getGamificationLastReplyCounter',

            // Warn Message Count
            warnMessageCount: 'Configurations/getWarnMessageCount',

            // Warn Popular Account Count
            warnPopularAccountCount: 'Configurations/getWarnPopularAccountCount',


            emailAddress: 'Configurations/getEmail',
            emailActive: 'Configurations/getEmailActive',
            emailHost: 'Configurations/getEmailHost',
            emailUsername: 'Configurations/getEmailUsername',
            emailPassword: 'Configurations/getEmailPassword',
            emailPort: 'Configurations/getEmailPort',
            emailSendFor: 'Configurations/getEmailSendFor',
            emailSelectedIntegrations: 'Configurations/getEmailSelectedIntegrations',
            emailSelectedAccounts: 'Configurations/getEmailSelectedAccounts',
            emailMessageType: 'Configurations/getEmailMessageType',
            emailTimeFrom: 'Configurations/getEmailTimeFrom',
            emailTimeTo: 'Configurations/getEmailTimeTo',

            emailVerified: 'Configurations/getEmailVerified',

            notificationActive: 'Configurations/getNotificationActive',
            notificationSendFor: 'Configurations/getNotificationSendFor',
            notificationSelectedIntegrations: 'Configurations/getNotificationSelectedIntegrations',
            notificationSelectedAccounts: 'Configurations/getNotificationSelectedAccounts',
            notificationMessageType: 'Configurations/getNotificationMessageType',
            notificationTimeFrom: 'Configurations/getNotificationTimeFrom',
            notificationTimeTo: 'Configurations/getNotificationTimeTo',

            whatsappActive: 'Configurations/getWhatsappActive',
            whatsappSendFor: 'Configurations/getWhatsappSendFor',
            whatsappSelectedIntegrations: 'Configurations/getWhatsappSelectedIntegrations',
            whatsappSelectedAccounts: 'Configurations/getWhatsappSelectedAccounts',
            whatsappMessageType: 'Configurations/getWhatsappMessageType',
            whatsappTimeFrom: 'Configurations/getWhatsappTimeFrom',
            whatsappTimeTo: 'Configurations/getWhatsappTimeTo',



            sendForOptions: 'Configurations/getSendForOptions',
            messageTypeOptions: 'Configurations/getMessageTypeOptions',


            translationActive: 'Configurations/getTranslationActive',
            translationApiKey: 'Configurations/getTranslationApiKey',


            ticketReminderActive: 'Configurations/getTicketReminderActive',
            ticketReminderTicketStatus: 'Configurations/getTicketReminderTicketStatus',
            ticketReminderCycle: 'Configurations/getTicketReminderCycle',

            reportNotifyActive: 'Configurations/getReportNotifyActive',
            reportNotifyType: 'Configurations/getReportNotifyType',
            reportNotifyTypeOptions: 'Configurations/getReportNotifyTypeOptions',
            reportNotifyInterval: 'Configurations/getReportNotifyInterval',
            reportNotifyIntervalOptions: 'Configurations/getReportNotifyIntervalOptions',
        }),

        report_notify_type: {

            // getter
            get: function () {
                return this.reportNotifyType;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    this.setReportNotifyType(value);
                }
            },

        },
        report_notify_interval: {

            // getter
            get: function () {
                return this.reportNotifyInterval;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    this.setReportNotifyInterval(value);
                }
            },

        },




        ticket_status: {

            // getter
            get: function () {
                return this.ticketReminderTicketStatus;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    this.setTicketReminderTicketStatus(value);
                }
            },

        },

        ticket_reminder_cycle: {

            // getter
            get: function () {
                return this.ticketReminderCycle;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    this.setTicketReminderCycle(value);
                }
            },

        },

        

        notification_time_from() {
            const date = new Date().toISOString().slice(0, 10)
            return this.$moment(this.$moment.utc(date + ' ' + this.notificationTimeFrom)).local().format('HH:mm:ss');
        },

        notification_time_to() {
            const date = new Date().toISOString().slice(0, 10)

            return this.$moment(this.$moment.utc(date + ' ' + this.notificationTimeTo)).local().format('HH:mm:ss');
        },

        whatsapp_time_from() {
            const date = new Date().toISOString().slice(0, 10)
            return this.$moment(this.$moment.utc(date + ' ' + this.whatsappTimeFrom)).local().format('HH:mm:ss');
        },

        whatsapp_time_to() {
            const date = new Date().toISOString().slice(0, 10)
            return this.$moment(this.$moment.utc(date + ' ' + this.whatsappTimeTo)).local().format('HH:mm:ss');
        },


        // player_mode
        player_mode: {

            // getter
            get: function () {
                // Get Player Mode
                return this.playerMode;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Set Player Mode
                    this.setPlayerMode(value);
                }
            },

        },

        // Gamification Counter
        gamification_counter: {

            // getter
            get: function () {
                // Get Player Mode
                return this.gamificationCounter;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Set Player Mode
                    this.setGamificationCounter(value);
                }
            },

        },

        // Gamification Last Reply Counter
        gamification_last_reply_counter: {

            // getter
            get: function () {
                // Get Player Mode
                return this.gamificationLastReplyCounter;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Set Player Mode
                    this.setGamificationLastReplyCounter(value);
                }
            },

        },

        // warn Message Count
        warn_message_count: {

            // getter
            get: function () {
                // Get Player Mode
                return this.warnMessageCount;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Set Player Mode
                    this.setWarnMessageCount(value);
                }
            },

        },

        // Warn Popular Count
        warn_popular_account_count: {

            // getter
            get: function () {
                // Get Player Mode
                return this.warnPopularAccountCount;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Set Player Mode
                    this.setWarnPopularAccountCount(value);
                }
            },

        },

        email_address: {

            // getter
            get: function () {
                // Get Player Mode
                return this.emailAddress;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Set Player Mode
                    this.setEmail(value);
                }
            },

        },


        email_host: {

            // getter
            get: function () {
                // Get Player Mode
                return this.emailHost;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Set Player Mode
                    this.setEmailHost(value);
                }
            },

        },

        email_username: {

            // getter
            get: function () {
                // Get Player Mode
                return this.emailUsername;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Set Player Mode
                    this.setEmailUsername(value);
                }
            },

        },
        email_port: {

            // getter
            get: function () {
                // Get Player Mode
                return this.emailPort;
            },

            // setter
            set: function (value) {
                // Check if we have value?
                if (value) {
                    // Set Player Mode
                    this.setEmailPort(value);
                }
            },

        },


        email_time_from() {
            const date = new Date().toISOString().slice(0, 10)
            return this.$moment(this.$moment.utc(date + ' ' + this.emailTimeFrom)).local().format('HH:mm:ss');
        },

        email_time_to() {
            const date = new Date().toISOString().slice(0, 10)
            return this.$moment(this.$moment.utc(date + ' ' + this.emailTimeTo)).local().format('HH:mm:ss');
        },

    },

}
</script>