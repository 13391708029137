<template>

        <!-- Reports Area -->
        
        <div class="convoreport" ref="chartdiv" v-if="messageCounts.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
    



    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('xy', this.messageCounts )

                let root;


                if ( this.messageCounts.length > 0) {
                    root = am5.Root.new(this.$refs.chartdiv);
                } else {
                    root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([am5themes_Animated.new(root)]);

                let chart = root.container.children.push(am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout,
                }));

                let legend = chart.children.push(am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                }));

                let data = this.messageCounts.length ? this.messageCounts : this.fakeMessageCounts;

                let xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        baseInterval: {
                            timeUnit: this.messageCounts.length ? this.calendar_interval : 'year',
                            count: 1,
                        },
                        startLocation: 0.5,
                        endLocation: 0.5,
                        tooltip: am5.Tooltip.new(root, {}),
                        renderer: am5xy.AxisRendererX.new(root, {
                        }),
                    })
                );

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        calculateTotals: true,
                        treatZeroAs: 0.000001,
                        renderer: am5xy.AxisRendererY.new(root, {})
                    })
                );
                
                let createSeries = (name, field) => {
                        let series = chart.series.push(am5xy.LineSeries.new(root, {
                          name: name,
                          stacked: true,
                          xAxis: xAxis,
                          yAxis: yAxis,
                          valueYField: field,
                          valueXField: "date",
                        //   valueYShow: "valueYTotalPercent",
                          legendValueText: "{valueY}",
                          tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText: "[bold]{name}[/]\n {valueYTotalPercent.formatNumber('#.0')}% ({valueY})"
                          })
                        }));

                        series.data.processor = am5.DataProcessor.new(root, {
                                dateFields: ["date"],
                                dateFormat: "yyyy-MM-dd-HH"
                        });

                        series.get("tooltip").label.set("text", "[bold]{name}[/]:\n {valueYTotalPercent.formatNumber('#.0')}% ({valueY})");
          
                        series.fills.template.setAll({
                          fillOpacity: 0.85,
                          visible: true,
                        });
                  
                        series.data.setAll(data);
                }                

                createSeries(this.$t('reports.incoming'), "incoming");
                createSeries(this.$t('reports.outgoing'), "outgoing");


                legend.data.setAll(chart.series.values);

                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                    behavior: "zoomXY",
                }));
                cursor.lineY.set("visible", false);

                if( this.messageCounts.length > 0) {
                    am5plugins_exporting.Exporting.new(root, {
                        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                        dataSource: data,
                        dateFields: ["date"],
                        dateFormat: "yyyy-MM-dd",
                        filePrefix: "convoTypes",
                    });
                }


                this.root = root;


                // // Create chart instance
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
                // chart.colors.step = 3;


                // // Add data
                // chart.data = this.messageCounts;

                // // Set input format for the dates
                // chart.dateFormatter.inputDateFormat = "yyyy-MM-dd-HH";

                // // Create axes
                // let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                // dateAxis.dataFields.date = "date";
                // dateAxis.title.text = this.$t('simple.date');



                // let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                // valueAxis.title.text = this.$t('reports.percent');
                // valueAxis.calculateTotals = true;
                // valueAxis.min = 0;
                // valueAxis.max = 100;
                // valueAxis.strictMinMax = true;
                // valueAxis.renderer.labels.template.adapter.add("text", function(text) {
                //         return text + "%";
                // });

                // // Create series
                // let series = chart.series.push(new am4charts.LineSeries());
                // series.dataFields.valueY = "incoming";
                // series.dataFields.valueYShow = "totalPercent";
                // series.dataFields.dateX = "date";
                // series.name = this.$t('reports.incoming');

                // series.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>" + this.$t('reports.incoming') + ": {valueY.value}</b></span>";

                // series.tooltip.getFillFromObject = false;
                // series.tooltip.background.fill = am4core.color("#FFF");

                // series.tooltip.getStrokeFromObject = true;
                // series.tooltip.background.strokeWidth = 3;

                // series.fillOpacity = 0.85;
                // series.stacked = true;

                // // static
                // series.legendSettings.labelText = this.$t('reports.incoming_total') + ":";
                // series.legendSettings.valueText = "{valueY.sum}";

                // // hovering
                // series.legendSettings.itemLabelText = this.$t('reports.incoming') + ":";
                // series.legendSettings.itemValueText = "{valueY}";

                // let series2 = chart.series.push(new am4charts.LineSeries());
                // series2.dataFields.valueY = "outgoing";
                // series2.dataFields.valueYShow = "totalPercent";
                // series2.dataFields.dateX = "date";
                // series2.name = this.$t('reports.outgoing');

                // series2.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>" + this.$t('reports.outgoing') + ": {valueY.value}</b></span>";

                // series2.tooltip.getFillFromObject = false;
                // series2.tooltip.background.fill = am4core.color("#FFF");

                // series2.tooltip.getStrokeFromObject = true;
                // series2.tooltip.background.strokeWidth = 3;

                // series2.fillOpacity = 0.85;
                // series2.stacked = true;

                // // static
                // series2.legendSettings.labelText = this.$t('reports.outgoing_total') + ":";
                // series2.legendSettings.valueText = "{valueY.sum}";

                // // hovering
                // series2.legendSettings.itemLabelText = this.$t('reports.outgoing') + ":";
                // series2.legendSettings.itemValueText = "{valueY}";

                // // Add cursor
                // chart.cursor = new am4charts.XYCursor();

                // // add legend
                // chart.legend = new am4charts.Legend();

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoActivity";




        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

            if (this.root) {
                this.root.dispose();
            }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({

                        messageCounts: 'Report/getMessageCounts',
                        fakeMessageCounts: 'Report/getFakeMessageCounts',
                        calendar_interval: 'Report/getCalenderInterval',

                })

        },
    }
</script>