<template>
    <div>
    <b-button-toolbar key-nav slot="footer" style="text-align: right;display: flow-root;">
        <b-button-group >
            <b-btn variant="outline-success" size="sm" @click="update(1, note)" :disabled="disabled_areas.messages_confirmation"><font-awesome-icon :icon="'check'"/> Confirm</b-btn>
            <b-btn class="ml-1" variant="outline-danger" size="sm" @click="update(2, note)" :disabled="disabled_areas.messages_confirmation"><font-awesome-icon :icon="'times'" /> Cancel</b-btn>
            <b-btn
                    class="ml-1"
                    variant="outline-secondary"
                    size="sm"
                    :class="visible ? null : 'collapsed'"
                    :aria-expanded="visible ? 'true' : 'false'"
                    :aria-controls="'collapse-'+confirmation.id"
                    @click="visible = !visible"
                    :disabled="disabled_areas.messages_confirmation"
            >
                <font-awesome-icon :icon="'comment-dots'" />
                Add Note
            </b-btn>
            <b-btn v-if="schedule" :disabled="disabled_areas.messages_confirmation" variant="outline-info"><font-awesome-icon :icon="'clock'" /> <span v-b-tooltip.hover :title="$moment($moment.utc(schedule.scheduled_at)).local().format('YYYY-MM-DD HH:mm:ss')"> {{ $moment($moment.utc(schedule.scheduled_at)).local().format('YYYY-MM-DD HH:mm:ss') | moment("from", "now") }}</span></b-btn>
        </b-button-group>
        <b-collapse :id="'collapse-'+confirmation.id" v-model="visible" class="mt-2">

            <div class="form-group message-wrapper-agent float-right pt-3">
                <label for="cn">Confirmation Note: </label>
                <textarea
                        :disabled="disabled_areas.messages_confirmation"
                        id="cn"
                        v-model="note"
                        ref="textarea"
                        class="form-control"
                        rows="3"
                        placeholder="Just type out your confirmation note here!"
                ></textarea>

            </div>

        </b-collapse>
    </b-button-toolbar>

    </div>
</template>


<script>

    import {mapActions, mapGetters} from "vuex";

    export default {

        props: [
            'confirmation',
            'schedule',
            'disabled_areas'
        ],

        data: function () {
            return {
                visible: false
            }
        },

        computed: {

            // Map getters
            ...mapGetters({

                // Get account
                //account_id: 'Accounts/getAccount',
            }),
            // Text
            note: {
                // getter
                get: function () {
                    // Get Schedule Data
                    return null;
                },
                // setter
                set: function (value) {
                    // Check if we have value?
                    if(value){

                        // Update Reply Text
                        this.updateNote(value);
                    }else {
                        return null;
                    }
                },

            },
        },

        methods: {

            // Actions
            ...mapActions('Confirmation', [
                'updateConfirmation',
                'updateNote',
                'sendNote',
                'getNote',
            ]),

            // Update confirmation
            update: function(status, note){

                // Update text with emoji
                let data = {
                    id: this.confirmation.id,
                    status: status,
                    note: note,
                };

                this.updateConfirmation(data);

            },


        },

    }
</script>