<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="transfersAndTrash.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';

//     /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";





    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('xy', this.transfersAndTrash )

                let root;


                if (this.transfersAndTrash.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([
                        am5themes_Animated.new(root)
                ]);


                let data = this.transfersAndTrash.length ? this.transfersAndTrash : this.fakeTransfersAndTrash;


                let chart = root.container.children.push(am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout,
                }));


                chart.get("colors").set("colors", [
                        am5.color("#6c757d"),
                        am5.color("#facf55"),
                        am5.color("#facf55"),

                ]);

                let legend = chart.children.push(am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                }));

                let xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        baseInterval: {
                            timeUnit: this.transfersAndTrash.length ? this.calendar_interval : 'year',
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                        }),

                    })
                );

                let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {
                                strokeOpacity: 0.1
                        })
                }));

                let makeSeries = (name, fieldName) => {
                    let series = chart.series.push(
                        am5xy.ColumnSeries.new(root, {
                            name: name,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: fieldName,
                            valueXField: "date",
                            tooltip: am5.Tooltip.new(root, {})
                        })
                    );

                        series.columns.template.setAll({
                                tooltipText: "{name}, {categoryX}:{valueY}",
                                width: am5.percent(90),
                                tooltipY: 0,
                                strokeOpacity: 0
                        });

                    series.data.processor = am5.DataProcessor.new(root, {
                        dateFields: ["date"],
                        dateFormat: "yyyy-MM-dd-HH"
                    });

                    series.get("tooltip").label.set("text", "{name}: [bold]{valueY}[/]");
                    series.data.setAll(data);
                }

                makeSeries("Trash", "Trash");
                makeSeries("Transfer(s)", "Transfer(s)");

                legend.data.setAll(chart.series.values);

                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                        behavior: "zoomXY",
                }));
                cursor.lineY.set("visible", false);

                if (this.transfersAndTrash.length > 0) {
                        am5plugins_exporting.Exporting.new(root, {
                                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                                dataSource: data,
                                dateFields: ["date"],
                                dateFormat: "yyyy-MM-dd",
                                filePrefix: "convoTypes",
                        });
                }


                this.root = root;


                // // Create chart instance
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

                // //Chart Colors
                // chart.colors.list = [

                //         am4core.color("#6c757d"),
                //         am4core.color("#facf55"),
                //         am4core.color("#facf55"),

                // ];

                // // Add data
                // chart.data = this.transfersAndTrash;

                // // Create axes
                // let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                // dateAxis.dataFields.date = "date";
                // dateAxis.renderer.grid.template.location = 0;
                // dateAxis.renderer.minGridDistance = 20;
                // dateAxis.renderer.labels.template.fontSize = 10;
                // dateAxis.renderer.cellStartLocation = 0.1;
                // dateAxis.renderer.cellEndLocation = 0.9;


                // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                // valueAxis.min = 0;
                // valueAxis.title.text = this.$t('reports.process_types');

                // // Create series
                // function createSeries(field, name) {
                //         var series = chart.series.push(new am4charts.ColumnSeries());
                //         series.dataFields.valueY = field;
                //         series.dataFields.dateX = "date";
                //         series.name = name;
                //         series.tooltipText = "{name}: [bold]{valueY}[/]";
                //         series.columns.template.width = am4core.percent(75);
                //         series.legendSettings.labelText = "{name} :";
                //         series.legendSettings.valueText = "{valueY.sum}";

                // }

                // chart.maskBullets = false;

                // // createSeries Loops
                // let i = 0;
                // chart.data.forEach (elements => {
                //         i++;
                //         for (let element in elements) {
                //                 if(element != "date" && i == 1){
                //                         createSeries(element, element, false);
                //                 }
                //         }
                // });


                // // Create legend instance
                // chart.legend = new am4charts.Legend();


                // // Add cursor
                // chart.cursor = new am4charts.XYCursor();


                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoProcess";



        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

                if (this.root) {
                        this.root.dispose();
                }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({

                        transfersAndTrash: 'Report/getTransfersAndTrash',
                        fakeTransfersAndTrash: 'Report/getFakeTransfersAndTrash',
                        calendar_interval: 'Report/getCalenderInterval',

                })

        },
    }
</script>