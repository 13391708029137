var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permission_error)?_c('div',{staticClass:"alert alert-danger"},[_vm._v("\n    "+_vm._s(_vm.permission_error)+"\n")]):_c('v-wait',{attrs:{"for":"transfer"}},[_c('loading',{attrs:{"slot":"waiting"},slot:"waiting"}),_vm._v(" "),_c('form',[(_vm.users)?_c('b-form-group',{attrs:{"label":_vm.$t('default.users'),"horizontal":""}},[_c('treeselect',{attrs:{"options":_vm.users,"multiple":true,"value-format":"object","placeholder":_vm.$t('ticket.select_users'),"normalizer":_vm.user_normalizer},scopedSlots:_vm._u([{key:"cell(option-label)",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
var countClassName = ref.countClassName;
return _c('label',{class:labelClassName},[_vm._v("\n                    "+_vm._s(node.raw.ns)+"\n                ")])}},{key:"cel(value-label)",fn:function(ref){
var node = ref.node;
return _c('div',{},[_vm._v(" "+_vm._s(node.raw.ns)+" ")])}}],null,false,3197124900),model:{value:(_vm.ticket_users),callback:function ($$v) {_vm.ticket_users=$$v},expression:"ticket_users"}})],1):_vm._e(),_vm._v(" "),(_vm.teams)?_c('b-form-group',{attrs:{"label":_vm.$t('default.teams'),"horizontal":""}},[_c('treeselect',{attrs:{"options":_vm.teams,"multiple":true,"value-format":"object","placeholder":_vm.$t('ticket.select_teams'),"normalizer":_vm.team_normalizer},scopedSlots:_vm._u([{key:"cell(option-label)",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
var countClassName = ref.countClassName;
return _c('label',{class:labelClassName},[_vm._v("\n                    "+_vm._s(node.raw.name)+"\n                ")])}},{key:"cell(value-label)",fn:function(ref){
var node = ref.node;
return _c('div',{},[_vm._v(" "+_vm._s(node.raw.name)+" ")])}}],null,false,2414797448),model:{value:(_vm.ticket_teams),callback:function ($$v) {_vm.ticket_teams=$$v},expression:"ticket_teams"}})],1):_vm._e(),_vm._v(" "),_c('b-form-group',{attrs:{"label":_vm.$t('default.note'),"horizontal":""}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('ticket.input_note')},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"horizontal":""}},[_c('b-button-toolbar',{staticClass:"mt-2",attrs:{"key-nav":"","aria-label":"Toolbar with button groups"}},[_c('b-button-group',{staticClass:"mx-1"},[_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"light","title":_vm.$t('default.save')},on:{"click":_vm.updateTransfer}},[_c('font-awesome-icon',{staticClass:"plus__icon",attrs:{"icon":'check'}}),_vm._v("\n                       "+_vm._s(_vm.$t('default.save'))+"\n                    ")],1)],1),_vm._v(" "),_c('b-button-group',{staticClass:"mx-1"},[_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"light","title":_vm.$t('default.reset')},on:{"click":_vm.refreshTransfer}},[_c('font-awesome-icon',{staticClass:"plus__icon",attrs:{"icon":'redo'}}),_vm._v("\n                        "+_vm._s(_vm.$t('default.reset'))+"\n                    ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }