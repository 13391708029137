import { render, staticRenderFns } from "./ConfirmationNote.vue?vue&type=template&id=40cb98a1&"
import script from "./ConfirmationNote.vue?vue&type=script&lang=js&"
export * from "./ConfirmationNote.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports