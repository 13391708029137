<template>
    <div>
        <div class="container-fluid c-plus-messages__container">

            
        </div><!-- // .row -->
        <!-- // .container -->

    </div>
</template>

<script>

    import { mapGetters,mapActions } from 'vuex';

    export default {

        mounted: function () {
            // Component mounted
            console.log('Account component mounted.');
            // this.updateIsInboxExpanded(true);
            // this.updateIsMessagesExpanded(false);
        },

        destroyed() {
            // Component destroyed
            console.log('Account component destroyed.');
        },

        created: function() {
            // Component created
            
        },


        data: function() {
            return  {
                is_history: false,
                is_customer: false,
                is_messages: true,
            }
        },

        metaInfo: function(){

            return {
                title: this.$t('page.tags'),
            }

        },

        methods: {

            // ...mapActions('Inbox', [
            //     'updateIsInboxExpanded',
            //     'updateIsMessagesExpanded',
            // ]),

            history: function(){
                this.is_history = !this.is_history;
            },

            customer: function(){
                this.is_customer = !this.is_customer;
                this.messages();
            },

            messages: function(){
                this.is_messages = !this.is_messages;
            },

        },

    }
</script>