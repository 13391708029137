<template>

        <!-- Reports Area -->

        <div class="convoreport" ref="chartdiv" v-if="scheduleReport.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>


</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';


    import * as am5 from "@amcharts/amcharts5";
    import * as am5percent from "@amcharts/amcharts5/percent";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('percent', this.scheduleReport )

                let root;


                if (this.scheduleReport.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([
                        am5themes_Animated.new(root)
                ]);

                let chart = root.container.children.push(am5percent.PieChart.new(root, {
                        startAngle: 180,
                        endAngle: 360,
                        layout: root.verticalLayout,
                        radius: am5.percent(60),
                        innerRadius: am5.percent(50)
                }));

                let data = this.scheduleReport.length ? this.scheduleReport : this.fakeScheduleReport;

                let series = chart.series.push(am5percent.PieSeries.new(root, {
                        startAngle: 180,
                        endAngle: 360,
                        valueField: "value",
                        categoryField: "category",
                        alignLabels: false
                }));

                series.states.create("hidden", {
                        startAngle: 180,
                        endAngle: 180
                });

                series.slices.template.setAll({
                        cornerRadius: 5,
                        tooltipText: "{category}: {value}",
                });

                series.ticks.template.setAll({
                        forceHidden: true
                });

                series.data.setAll(data);

                let legend = chart.children.push(am5.Legend.new(root, {
                        centerX: am5.percent(50),
                        x: am5.percent(50),
                }));

                legend.data.setAll(series.dataItems);

                if( this.scheduleReport.length > 0) {
                    am5plugins_exporting.Exporting.new(root, {
                        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                        dataSource: data,
                        filePrefix: "convoTypes",
                    });
                }


                this.root = root;






                // // Create chart
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
                // chart.hiddenState.properties.opacity = 0; // this creates initial fade-in


                // // Need Time Based Data
                // chart.data = [

                //         {
                //                 action: this.$t('schedule.sent'),
                //                 value: this.scheduleReport[0],
                //                 "color": am4core.color("#67b7dc"),

                //         },
                //         {
                //                 action: this.$t('schedule.awaiting'),
                //                 value: this.scheduleReport[1],
                //                 "color": am4core.color("#67dc9c"),
                //         },
                //         {
                //                 action: this.$t('schedule.cancelled'),
                //                 value: this.scheduleReport[2],
                //                 "color": am4core.color("#b4a08a"),
                //         },
                // ];

                // chart.radius = am4core.percent(70);
                // chart.innerRadius = am4core.percent(50);
                // chart.startAngle = 180;
                // chart.endAngle = 360;

                // let series = chart.series.push(new am4charts.PieSeries());
                // series.dataFields.value = "value";
                // series.dataFields.category = "action";

                // series.slices.template.propertyFields.fill = "color";


                // series.slices.template.cornerRadius = 10;
                // series.slices.template.innerCornerRadius = 7;
                // series.slices.template.draggable = true;
                // series.slices.template.inert = true;
                // series.alignLabels = false;

                // series.hiddenState.properties.startAngle = 90;
                // series.hiddenState.properties.endAngle = 90;

                // chart.legend = new am4charts.Legend();


                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoSchedule";




        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

                if (this.root) {
                        this.root.dispose();
                }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {
                // Map getters
                ...mapGetters({
                        scheduleReport: 'Report/getSchedule',
                        fakeScheduleReport: 'Report/getFakeSchedule',

                })
        },
    }
</script>