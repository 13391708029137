<template>
    <h6>
                                <span v-if="parseInt(customer.is_seen_count.length) !== 0">  <b-badge size="sm" pill :variant="integration.color">

                                        <span v-if="parseInt(customer.is_seen_count.length) !== 0"
                                              :class="'badge  badge-pill align-text-bottom badge-light'"
                                              v-b-tooltip.hover :title="$t('customer.unread_count')">{{ customer.is_seen_count.length }}</span>

                                    <span> | </span>
                                    <span class="badge badge-pill badge-light align-text-bottom" v-b-tooltip.hover
                                          :title="$t('customer.active_tickets')">{{ customer.tickets_active_count.length }}</span>
                                    </b-badge>
                                </span>

        <span v-else :class="'badge badge-pill align-text-bottom badge-'+integration.color"
              v-b-tooltip.hover :title="$t('customer.active_tickets')">{{ customer.tickets_active_count.length }}</span>
    </h6>

</template>

<script>

    import {mapGetters, mapMutations} from "vuex";

    export default {

        props: ['customer', 'integration_id'],

        created() {
            this.integration = this.getIntegration;
        },

        data: function () {
            return {
                integration: [],
            }
        },

        methods: {

            ...mapMutations('Accounts', [
                'setIntegrationID'
            ]),

        },

        computed: {
            // Map getters
            ...mapGetters({
                // Get account Integration id
                getIntegration: 'Accounts/getIntegration',
            }),

        },
    }
</script>