<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="teamTimeTable.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    /* Imports */
    // import * as am4core from "@amcharts/amcharts4/core";
    // import * as am4charts from "@amcharts/amcharts4/charts";
    // import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Chart code */
    // Themes begin
    // am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {


            console.log('xy', this.teamTimeTable)

            let root;

            if (this.teamTimeTable.length > 0) {
                root = am5.Root.new(this.$refs.chartdiv);
            } else {
                root = am5.Root.new(this.$refs.fakechart)
            }
            root.setThemes([am5themes_Animated.new(root)]);

            let data = this.teamTimeTable.length ? this.teamTimeTable : this.fakeTeamTimeTable;

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelY: "none",
                    wheelY: "none",
                    layout: root.verticalLayout,
                    maxTooltipDistance: 0
                })
            );

            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: 0,
                    extraTooltipPrecision: 1,
                    renderer: am5xy.AxisRendererY.new(root, {})
                })
            );

            let xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    baseInterval: { 
                        timeUnit: this.teamTimeTable.length ? this.calendar_interval : 'year', 
                        count: 1 
                    },
                    renderer: am5xy.AxisRendererX.new(root, {})
                })
            );

            function createSeries(name, field) {
                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        name: name,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: field,
                        valueXField: "date",
                        tooltip: am5.Tooltip.new(root, {}),
                    })
                );

                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        sprite: am5.Circle.new(root, {
                            radius: 5,
                            fill: series.get("fill")
                        })
                    });
                });

                series.data.processor = am5.DataProcessor.new(root, {
                    dateFields: ["date"],
                    dateFormat: "yyyy-MM-dd-HH"
                });

                series.strokes.template.set("strokeWidth", 2);

                series.get("tooltip").label.set("text", "{valueY}")
                series.data.setAll(data);
            }



            createSeries("team_first_response", "team_first_response");

            chart.set("cursor", am5xy.XYCursor.new(root, {
                xAxis: xAxis
            }));

            xAxis.set("tooltip", am5.Tooltip.new(root, {
                themeTags: ["axis"]
            }));

            yAxis.set("tooltip", am5.Tooltip.new(root, {
                themeTags: ["axis"]
            }));





            if (this.teamTimeTable.length > 0) {
                am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                    dataSource: data,
                    dateFields: ["date"],
                    dateFormat: "yyyy-MM-dd",
                    filePrefix: "convoTypes",
                });
            }


            this.root = root;



                // Create chart instance
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

                // // Add data
                // /* First Response Time is calculated by simply subtracting the time of the customer request from the time of the initial reply. */
                // /* (Average-Minute) Time of first response - time of customer request = (# Minutes/hours/days) First Response Time */
                // chart.data = this.teamTimeTable;

                // // Create axes
                // let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                // dateAxis.renderer.grid.template.location = 0;
                // dateAxis.renderer.minGridDistance = 50;

                // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                // valueAxis.logarithmic = true;
                // valueAxis.renderer.minGridDistance = 20;

                // // Create series
                // let series = chart.series.push(new am4charts.LineSeries());
                // series.dataFields.valueY = "team_first_response";
                // series.dataFields.dateX = "date";
                // series.tensionX = 0.8;
                // series.strokeWidth = 3;
                // series.tooltipText = this.$t('reports.first_response') + ": {valueY.value}" + this.$t('reports.minutes');


                // let bullet = series.bullets.push(new am4charts.CircleBullet());
                // bullet.circle.fill = am4core.color("#fff");
                // bullet.circle.strokeWidth = 3;

                // // Add cursor
                // chart.cursor = new am4charts.XYCursor();
                // chart.cursor.fullWidthLineX = true;
                // chart.cursor.xAxis = dateAxis;
                // chart.cursor.lineX.strokeWidth = 0;
                // chart.cursor.lineX.fill = am4core.color("#000");
                // chart.cursor.lineX.fillOpacity = 0.1;


                // // Add a guide
                // let range = valueAxis.axisRanges.create();
                // range.value = 90.4;
                // range.grid.stroke = am4core.color("#396478");
                // range.grid.strokeWidth = 1;
                // range.grid.strokeOpacity = 1;
                // range.grid.strokeDasharray = "3,3";
                // range.label.inside = true;
                // range.label.text = "Average";
                // range.label.fill = range.grid.stroke;
                // range.label.verticalCenter = "bottom";

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoTeamTime";


        },

        beforeDestroy() {
            // if (this.chart) {
            //     this.chart.dispose();
            // }


            if (this.root) {
                this.root.dispose();
            }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({

                        teamTimeTable: 'Report/getTeamTimeTable',
                        fakeTeamTimeTable: 'Report/getFakeTeamTimeTable',
                        calendar_interval: 'Report/getCalenderInterval',


                })
        },
    }
</script>