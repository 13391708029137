<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="tagsReport.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

        import {mapGetters} from "vuex";

        /* Imports */
        // import * as am4core from "@amcharts/amcharts4/core";
        // import * as am4charts from "@amcharts/amcharts4/charts";
        // import am4themes_animated from "@amcharts/amcharts4/themes/animated";



        import * as am5 from "@amcharts/amcharts5";
        import * as am5xy from "@amcharts/amcharts5/xy";
        import * as am5radar from "@amcharts/amcharts5/radar";
        import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
        import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";


        /* Chart code */
        // Themes begin
        // am4core.useTheme(am4themes_animated);
        // Themes end

    export default {

        mounted() {

                console.log('xy, radar', this.tagsReport )

                let root;


                if (this.tagsReport.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }

                root.setThemes([
                        am5themes_Animated.new(root),
                ]);


                let chart = root.container.children.push(am5radar.RadarChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "none",
                        wheelY: "none",
                        startAngle: -84,
                        endAngle: 264,
                        innerRadius: am5.percent(40)
                }));

                let raw_data = this.tagsReport.length ? this.tagsReport : this.fakeTagsReport;

                let data = raw_data.filter( (ele) => ele.value !== 0)


                const cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
                        behavior: "zoomX"
                }));
                cursor.lineY.set("forceHidden", true);

                let xRenderer = am5radar.AxisRendererCircular.new(root, {
                        // minGridDistance: 30
                });

                xRenderer.grid.template.set("forceHidden", true);

                let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                        maxDeviation: 0,
                        categoryField: "tags",
                        renderer: xRenderer
                }));

                let yRenderer = am5radar.AxisRendererRadial.new(root, {});
                yRenderer.labels.template.set("centerX", am5.p50);

                let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                        maxDeviation: 0.3,
                        min: 0,
                        renderer: yRenderer
                }));

                var series = chart.series.push(am5radar.RadarColumnSeries.new(root, {
                        name: "Series",
                        sequencedInterpolation: true,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value",
                        categoryXField: "tags",
                }));

                series.columns.template.setAll({
                        cornerRadius: 5,
                        tooltipText: "{categoryX}: {valueY}"
                });

                series.columns.template.adapters.add("fill", function (fill, target) {
                        return chart.get("colors").getIndex(series.columns.indexOf(target));
                });

                series.columns.template.adapters.add("stroke", function (stroke, target) {
                        return chart.get("colors").getIndex(series.columns.indexOf(target));
                });

                xAxis.data.setAll(data);
                series.data.setAll(data);


                if( this.tagsReport.length > 0) {
                    am5plugins_exporting.Exporting.new(root, {
                        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                        dataSource: data,
                        filePrefix: "convoTypes",
                    });
                }


                this.root = root;










                // let chart = am4core.create(this.$refs.chartdiv, am4charts.RadarChart);
                // chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
                // chart.innerRadius = am4core.percent(50);
                // chart.startAngle = -80;
                // chart.endAngle = 260;

                // chart.data = this.tagsReport;

                // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                // categoryAxis.renderer.grid.template.location = 0;
                // categoryAxis.dataFields.category = "tags";
                // categoryAxis.renderer.labels.template.location = 0.5;
                // categoryAxis.renderer.grid.template.strokeOpacity = 0.08;
                // categoryAxis.renderer.tooltipLocation = 0.5;
                // categoryAxis.tooltip.disabled = true;
                // categoryAxis.renderer.labels.template.bent = true;
                // categoryAxis.renderer.labels.template.padding(0,0,0,0);
                // categoryAxis.renderer.labels.template.radius = 7;

                // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                // valueAxis.min = 0;
                // valueAxis.strictMinMax = true;
                // valueAxis.renderer.minGridDistance = 30;
                // valueAxis.renderer.grid.template.strokeOpacity = 0.08;
                // valueAxis.tooltip.disabled = true;

                // // axis break
                // let axisBreak = valueAxis.axisBreaks.create();
                // axisBreak.startValue = 500;
                // axisBreak.breakSize = 0.02;

                // // make break expand on hover
                // let hoverState = axisBreak.states.create("hover");
                // hoverState.properties.breakSize = 1;
                // hoverState.properties.opacity = 0.1;
                // hoverState.transitionDuration = 1500;

                // axisBreak.defaultState.transitionDuration = 1000;

                // let series = chart.series.push(new am4charts.RadarColumnSeries());
                // series.dataFields.categoryX = "tags";
                // series.dataFields.valueY = "value";
                // series.columns.template.tooltipText = "{valueY.value}";
                // series.columns.template.tooltipY = 0;
                // series.columns.template.strokeOpacity = 0;

                // chart.legend = new am4charts.Legend();

                // series.legendSettings.labelText = this.$t('reports.total') + ": ";
                // series.legendSettings.valueText = "{valueY.sum}";


                // chart.seriesContainer.zIndex = -1;

                // // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
                // series.columns.template.adapter.add("fill", function(fill, target) {
                //         return chart.colors.getIndex(target.dataItem.index);
                // });

                // let cursor = new am4charts.RadarCursor();
                // cursor.innerRadius = am4core.percent(50);
                // cursor.lineY.disabled = true;

                // cursor.xAxis = categoryAxis;
                // chart.cursor = cursor;

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoTags";

                },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }
                if (this.root) {
                        this.root.dispose();
                }

        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {
                // Map getters
                ...mapGetters({
                        tagsReport: 'Report/getTagReports',
                        fakeTagsReport: 'Report/getFakeTagReports',

                })
        },
    }
</script>