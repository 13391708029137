<template>
    <div class="row">

        <div class="col pt-2">


            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.users') }}</h4>

                        <div class="ml-auto align-self-center" v-if="!permission">

                            <b-button :variant="'primary'" @click="add" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add')}}
                            </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.users_desc') }}</span>

                </div>

                <hr>


                <div class="alert alert-danger text-center" style="max-width: 50%; margin: 0 auto;" v-if="permission">
                    {{ permission }}
                </div>
                <!-- Page content -->
                <div class="px-2" v-else>


                    <v-wait for="users">


                        <loading slot="waiting"/>


                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                      <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <template v-if="window_width >= 1200">
                            <b-table responsive bordered hover :items="getUsers" :fields="user_fields" :filter="filter" class="d-none d-xl-block" >

                                <!-- Avatar
                                <template slot="cell(ns)" slot-scope="data">

                                    <avatar :username="data.value" :size="32"></avatar>

                                </template> -->

                                <!-- Super -->
                                <template slot="cell(is_super)" slot-scope="data">

                                    <template v-if="data.value == '1'">
                                       <b-badge variant="success">{{ $t('simple.yes') }}</b-badge>
                                    </template>
                                    <template v-else>
                                        <b-badge variant="danger">{{ $t('simple.no') }}</b-badge>
                                    </template>


                                </template>

                                <!-- Status -->
                                <template slot="cell(status)" slot-scope="data">

                                    <template v-if="data.value == '1'">
                                       <b-badge variant="success">{{ $t('default.enable') }}</b-badge>
                                    </template>
                                    <template v-else>
                                        <b-badge variant="danger">{{ $t('default.disable') }}</b-badge>
                                    </template>


                                </template>

                                <!-- Actions -->
                                <template slot="cell(actions)" slot-scope="cell">

                                  <b-dropdown right :text="$t('simple.actions')" size="sm" class="py-1">

                                    <b-dropdown-item @click="password(cell.item.id)">{{ $t('user.password') }}</b-dropdown-item>

                                    <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>

                                  </b-dropdown>

                                </template>

                                <!-- Created at -->
                                <template slot="cell(created_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                                <!-- Updated at -->
                                <template slot="cell(updated_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                                <!-- Login at -->
                                <template slot="cell(login_at)" slot-scope="cell">

                                    <template v-if="cell.item.login_at === null">
                                        -
                                    </template>


                                    <template v-else>
                                        {{ $moment($moment.utc(cell.item.login_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                    </template>

                                </template>

                            </b-table>
                        </template>

                        <template v-else>
                            <b-table 
                                stacked="md"
                                small
                                class="d-xl-none"
                                responsive
                                bordered
                                hover
                                :items="getUsers"
                                :fields="user_fields_collapsed"
                                :filter="filter"
                            >
                            
                                <!-- Super -->
                                <template slot="cell(is_super)" slot-scope="data">
                                
                                    <template v-if="data.value == '1'">
                                       <b-badge variant="success">{{ $t('simple.yes') }}</b-badge>
                                    </template>
                                    <template v-else>
                                        <b-badge variant="danger">{{ $t('simple.no') }}</b-badge>
                                    </template>
                                
                                
                                </template>
                            
                                    <!-- Status -->
                                <template slot="cell(status)" slot-scope="data">
                                    
                                    <template v-if="data.value == '1'">
                                       <b-badge variant="success">{{ $t('default.enable') }}</b-badge>
                                    </template>
                                    <template v-else>
                                        <b-badge variant="danger">{{ $t('default.disable') }}</b-badge>
                                    </template>
                                
                                
                                </template>
                            
                                    <!-- Actions -->
                                <template slot="cell(actions)" slot-scope="cell">
                                
                                    <b-button size="sm" @click="cell.toggleDetails()">
                                        {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
                                    </b-button>
                                    
                                    <b-dropdown right :text="$t('simple.actions')" size="sm">
                                    
                                        <b-dropdown-item @click="password(cell.item.id)">{{ $t('user.password') }}</b-dropdown-item>
                                    
                                        <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>
                                    
                                    </b-dropdown>
                                
                                </template>
                            
                                <template #row-details="row">
                                        <b-table
                                            small
                                            stacked="md"
                                            class="d-xl-none"
                                            responsive
                                            bordered
                                            hover
                                            :items="[row.item]"
                                            :fields="user_fields_not_collapsed"
                                        >
                                        
                                        <!-- Created at -->
                                        <template slot="cell(created_at)" slot-scope="cell">
                                        
                                            {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                        
                                        </template>
                                    
                                        <!-- Updated at -->
                                        <template slot="cell(updated_at)" slot-scope="cell">
                                        
                                            {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                        
                                        </template>
                                    
                                        <!-- Login at -->
                                        <template slot="cell(login_at)" slot-scope="cell">
                                        
                                            <template v-if="cell.item.login_at === null">
                                                -
                                            </template>
                                        
                                            <template v-else>
                                                {{ $moment($moment.utc(cell.item.login_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                            </template>
                                        </template>
                                        </b-table>
                                </template>
                            
                            </b-table>
                        </template>

                    </v-wait>

                </div>
                    

            </div>


        </div>

    </div>
</template>

<script>

    import UserAdd from './Modal/UserAdd.vue'
    import UserEdit from './Modal/UserEdit.vue'
    import UserPassword from './Modal/UserPassword.vue'
    import UserRemove from './Modal/UserRemove.vue'

    import { create } from 'vue-modal-dialogs'
    import {mapActions, mapGetters} from "vuex";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Users component mounted.')

            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        destroyed() {
            // Component destroyed
            console.log('Users component destroyed.');
        },

        created: function() {
            // Component created
            //Fetch Users
            this.fetchUsers();
        },
        

        data: function() {
            return  {
                window_width: window.innerWidth,
                user_fields: [
                    // { key: 'ns', label: this.$t('user.ns') },
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'surname', label: this.$t('simple.surname'), sortable: true },
                    { key: 'email', label: this.$t('simple.email'), sortable: true },
                    { key: 'phone', label: this.$t('simple.phone'), sortable: true },
                    { key: 'is_super', label: this.$t('simple.super'), sortable: true },
                    { key: 'status', label: this.$t('simple.status'), sortable: true },
                    { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                    { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                    { key: 'login_at', label: this.$t('user.login_at'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                user_fields_collapsed: [
                    // { key: 'ns', label: this.$t('user.ns') },
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'surname', label: this.$t('simple.surname'), sortable: true },
                    { key: 'email', label: this.$t('simple.email'), sortable: true },
                    { key: 'is_super', label: this.$t('simple.super'), sortable: true },
                    { key: 'status', label: this.$t('simple.status'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                user_fields_not_collapsed: [
                    { key: 'phone', label: this.$t('simple.phone') },
                    { key: 'created_at', label: this.$t('simple.created') },
                    { key: 'updated_at', label: this.$t('simple.updated') },
                    { key: 'login_at', label: this.$t('user.login_at') },
                ],

                filter: null,

            }
        },

        metaInfo: function() {

            return {
                title: this.$t('page.users'),
            }
            
        },

        computed: {

            // Map getters
            ...mapGetters({
                getUsers: 'Users/getUsers',
                permission: 'Users/getPermission',
            }),


        },

        methods: {


            ...mapActions('Users', [
                'fetchUsers',
            ]),

            add: async function() {
                // Show User add modal
                const user_add = create(UserAdd);

                if (await user_add() ){
                    // Refresh account list
                    this.fetchUsers();
                }

            },

            edit: async function(id) {
                // Show User edit modal
                const user_edit = create(UserEdit, 'id');

                if (await user_edit(id) ){
                    // Refresh User list
                    this.fetchUsers();
                }

            },

            password: async function(id) {
                // Show User edit modal
                const user_password = create(UserPassword, 'id');

                if (await user_password(id) ){
                    // Refresh User list
                    this.fetchUsers();
                }

            },

            
            remove: async function(id) {
                
                // Show User remove modal
                const user_remove = create(UserRemove, 'id');

                if (await user_remove(id) ){
                    // Refresh User list
                    this.getUsers();
                }

            },

            onResize() {
                this.window_width = window.innerWidth;
            },  

        },
        
    }
</script>