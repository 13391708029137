<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('simple.edit')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="edit">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_edit">


                    <div class="form-group">

                        <label for="name">{{ $t('simple.name') }}</label>

                        <input
                            class="form-control"
                            type="text"
                            id="name"
                            name="name"
                            v-validate="'required|max:20'"
                            v-model="name"
                        >

                        <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="description">{{ $t('simple.display_name') }}</label>

                        <input 
                            class="form-control"
                            type="text"
                            id="display_name"
                            name="display_name"
                            v-validate="'required|max:50'"
                            v-model="display_name"
                        >

                        <small v-show="errors.has('display_name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('display_name') }}</small>

                    </div>

                    <div class="form-group">

                        <label for="description">{{ $t('simple.desc') }}</label>

                        <input 
                            class="form-control"
                            type="text"
                            id="description"
                            name="description"
                            v-validate="'required|max:50'"
                            v-model="description"
                        >

                        <small v-show="errors.has('description')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('description') }}</small>

                    </div>


                    <div class="form-group" v-if="permissions_ready">

                        <label for="parent_id">{{ $t('simple.permission') }}</label>

                        <treeselect

                            v-model="permission"
                            :options="permissions"
                            :multiple="true"
                            :normalizer="normalizer"
                            value-format="object"
                            placeholder="Select permissions"
                            
                        >

                        <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                            {{ node.raw.display_name }} <font-awesome-icon :icon="'question-circle'" v-b-tooltip.hover :title="node.raw.description" :size="'xs'"  class="ml-1"/>
                            
                        </label>

                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.display_name }} <font-awesome-icon :icon="'question-circle'" v-b-tooltip.hover :title="node.raw.description" :size="'xs'"  class="ml-1"/></div>

                        </treeselect>
                        
                    </div>

                </form>

            </v-wait>

        </div>

        <div class="modal-footer">

            <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

            <b-button @click="validate_edit()" variant="primary">{{ $t('simple.save') }}</b-button>
            
        </div>


        </div>
      </div>
    </div>

</template>
<script>
    import Treeselect from '@riophae/vue-treeselect'

    export default {

        components: { Treeselect },

        mounted: function () {
            // Component mounted
            console.log('Role edit Modal mounted');

            // getPermissions and after that get edit data
            this.getPermissions();

            // Get data to edit
            this.edit(this.id);
            
        },

        destroyed() {
            // Component destroyed
            console.log('Role Edit Modal destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() {
            return  {

                roles_api: '/api/v1/settings/roles/',
                permissions_api: '/api/v1/settings/permissions/',

                name: '',
                display_name: '',
                description: '',
                permission: null,
                permissions: null,

                permissions_ready: false,
                normalizer(node) {
                    return {
                        label: node.name,
                    }
                },
            }
        },


        methods: {

            // Get permissions
            getPermissions: async function(){

                // Loading start
                this.$wait.start('edit');

                // Get categories
                await axios.get(this.permissions_api)
                  .then((response) => {

                    // Set categories
                    this.permissions = response.data;

                    console.log(response);

                    this.permissions_ready = true;

                    // Loading end
                    this.$wait.end('edit');

                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    console.log(error);

                    // Loading end
                    this.$wait.end('edit');

                  });

            },

            edit: async function(id) {

                // Loading start
                this.$wait.start('edit');

                // Get tags
                await axios.get(this.roles_api + id)
                    .then((response) => {

                        // Update modal data
                        this.name = response.data.name;
                        this.display_name  = response.data.display_name;
                        this.description = response.data.description;
                        this.permission = response.data.permissions;

                        // Loading stop
                        this.$wait.end('edit');

                    })
                    .catch((error) => {

                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));

                        // Loading stop
                        this.$wait.end('edit');

                        // Close modal
                        this.$close(true);

                    });

            },

            role_edit: async function(){

                // Loading start
                this.$wait.start('edit');

                // Post request
                await axios.put(this.roles_api + this.id, {
                    name: this.name,
                    display_name: this.display_name,
                    description: this.description,
                    permissions: this.permission,
                  })
                  .then((response) => {

                      if(response.data.error){
                          // Show error
                          Vue.toasted.error(Vue.i18n.translate('permission.update_roles'),{duration:5000});
                          // Loading stop
                          this.$wait.end('edit');
                          // Close modal
                          this.$close(true);
                      }else {

                          // Show success
                          Vue.toasted.success(this.$t('simple.success_info'));

                          // Close modal
                          this.$close(true);

                          // Loading end
                          this.$wait.end('edit');
                      }

                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    //console.log(error);

                    // Close modal
                    //this.$close(true);

                    // Loading end
                    this.$wait.end('edit');

                  });

            },

            validate_edit: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        this.role_edit();
                    }
                });
            },
        },
    }
</script>