<template>
    <b-form-group>

        <!-- Text -->
        <b-form-group :label="$t('pixel.text')">

            <b-form-textarea id="text"
                             v-model="config.text"
                             :rows="3"
                             :max-rows="6"
                             @input="update">
            </b-form-textarea>

        </b-form-group>


        <!-- Font -->
        <b-form-group :label="$t('pixel.font')">

            <b-form-select v-model="config.fontFamily" @input="update">
                <option value="arial">Arial</option>
                <option value="helvetica" selected>Helvetica</option>
                <option value="myriad pro">Myriad Pro</option>
                <option value="delicious">Delicious</option>
                <option value="verdana">Verdana</option>
                <option value="georgia">Georgia</option>
                <option value="courier">Courier</option>
                <option value="comic sans ms">Comic Sans MS</option>
                <option value="impact">Impact</option>
                <option value="monaco">Monaco</option>
                <option value="optima">Optima</option>
                <option value="hoefler text">Hoefler Text</option>
                <option value="plaster">Plaster</option>
                <option value="engagement">Engagement</option>
            </b-form-select>

        </b-form-group>


        <!-- Font Size -->
        <b-form-group :label="$t('pixel.font_size') + ' : ' + config.fontSize">

            <b-form-input v-model="config.fontSize" type="range" min="1" max="120" @input="update" />

        </b-form-group>


        <!-- Line height -->
        <b-form-group :label="$t('pixel.text_line_height') + ' : ' + config.lineHeight">

            <b-form-input v-model="config.lineHeight" type="range" min="0" max="10" step="0.1" @input="update" />

        </b-form-group>

        <!-- Char spacing -->
        <b-form-group :label="$t('pixel.text_char_spacing') + ' : ' + config.charSpacing">

            <b-form-input v-model="config.charSpacing" type="range" min="-200" max="800" step="10" @input="update" />

        </b-form-group>


        <!-- Text Align -->
        <b-form-group label-for="font"
                      :label="$t('pixel.text_align')">

            <b-form-select v-model="config.textAlign" @input="update">

                <option value="left">{{ $t('pixel.text_left') }}</option>
                <option value="center">{{ $t('pixel.text_center') }}</option>
                <option value="right">{{ $t('pixel.text_right') }}</option>
                <option value="justify">{{ $t('pixel.text_justify') }}</option>
                <option value="justify-left">{{ $t('pixel.text_justify_left') }}</option>
                <option value="justify-center">{{ $t('pixel.text_justify_center') }}</option>
                <option value="justify-right">{{ $t('pixel.text_justify_right') }}</option>

            </b-form-select>

        </b-form-group>

        <!-- Text color -->
        <b-form-group :label="$t('pixel.color')">

            <b-form-input type="color" v-model="config.fill" @input="update" />

        </b-form-group>


        <!-- Text background color -->
        <b-form-group :label="$t('pixel.text_background_color')">

            <b-form-input type="color" v-model="config.textBackgroundColor" @input="update" />

        </b-form-group>

        <!-- Style -->
        <b-form-group :label="$t('pixel.style')">

            <b-button-group size="sm">

                <!-- Bold -->
                <b-button size="sm" :variant="config.fontWeight === 'bold' ? 'outline-success' : 'outline-primary'" @click="style('bold')">
                    <font-awesome-icon :icon="'bold'" />
                </b-button>

                <!-- Italic -->
                <b-button size="sm" :variant="config.fontWeight === 'bold' ? 'outline-success' : 'outline-primary'"  @click="style('italic')">
                    <font-awesome-icon :icon="'italic'" />
                </b-button>

                <!-- Underline -->
                <b-button size="sm" :variant="config.underline ? 'outline-success' : 'outline-primary'"  @click="style('underline')">
                    <font-awesome-icon :icon="'underline'" />
                </b-button>

                <!-- Linethrough -->
                <b-button size="sm" :variant="config.linethrough ? 'outline-success' : 'outline-primary'" @click="style('linethrough')">
                    <font-awesome-icon :icon="'strikethrough'" />
                </b-button>

                <!-- Overline -->
                <b-button size="sm" :variant="config.overline ? 'outline-success' : 'outline-primary'" @click="style('overline')">
                    <font-awesome-icon :icon="'font'" />
                </b-button>

            </b-button-group>

        </b-form-group>


    </b-form-group>

</template>

<script>

    export default {

        props: ['default'],

        mounted: function () {
            // Component mounted
            console.log('Editor text mounted');
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        

        data: function() {
            return  {

                config: {

                    text: this.default.text,
                    fontFamily: this.default.fontFamily,
                    fontSize: this.default.fontSize,
                    textAlign: this.default.textAlign,
                    lineHeight: this.default.lineHeight,
                    charSpacing: this.default.charSpacing,
                    textBackgroundColor: this.default.textBackgroundColor,
                    fill: this.default.fill,
                    fontWeight: this.default.fontWeight,
                    fontStyle: this.default.fontStyle,
                    underline: this.default.underline,
                    linethrough: this.default.linethrough,
                    overline: this.default.overline,


                },


            }
        },

        computed: {

        },

        methods: {

            // Update config
            update: function(){

                // Sent to parent
                this.$emit('update', this.config);
            },

            // Style
            style: function(type){

                switch (type) {

                    case 'bold':

                        // Set to reverse
                       this.config.fontWeight = this.config.fontWeight === 'bold' ? '' : 'bold';

                       // Break
                       break;

                    case 'italic':

                        // Set style to reverse
                        this.config.fontStyle = this.config.fontStyle === 'italic' ? '' : 'italic';

                        // Break
                        break;
                    case 'underline':

                        // Set style to reverse
                        this.config.underline = !this.config.underline;

                        // Break
                        break;

                    case 'linethrough':

                        // Set style to reverse
                        this.config.linethrough = !this.config.linethrough;

                        // Break
                        break;

                    case 'overline':

                        // Set style to reverse
                        this.config.overline = !this.config.overline;

                        // Break
                        break;
                }

                // Set align value
                this.update();
            },

        },
        
    }
</script>