const state = {

  is_customer: true,

  is_ticket: false,

  player: false,

  permission: '',

};

// getters
const getters = {

  // Get is customer
  getIsCustomer: state => {
    return state.is_customer;
  },

  // Get is ticket
  getIsTicket: state => {
    return state.is_ticket;
  },

  // Return Permission
  getPermission: state => {
    return state.permission;
  },

  // Return Player Option
  getPlayer: state => {
    return state.player;
  },

};

// mutations
const mutations = {

  // Set Permission
  setPermission (state, permission){
    state.permission = permission;
  },

  // Set Permission
  setPlayer (state, payload){
    state.player = payload;
  },

  setActive(state, active){

    if(active == 'customer'){

      // Set customer to true
      state.is_customer = true;

      // Set ticket to false
      state.is_ticket = false;
    }

    if(active == 'ticket'){

      // Set customer to false
      state.is_customer = false;

      // Set ticket to true
      state.is_ticket = true;
    }

  },

}

// actions
const actions = {

  // Start or Stop Gamification mode
  async gamificationActions({ dispatch, rootGetters, commit }, status){
    // Action Type
    console.log(status);
    let action_api = '/api/v1/play/gamification/action';
    let user_id = rootGetters['User/getUserID'];
      await axios.post(action_api, {
        user_id: user_id,
        status: status ? 1 : 0,
      })

          .then((response) => {

            console.log(response);

            if(status === 0){
              // Assign customer
              commit('setPlayer', false);
            }

            dispatch('User/updateUserData', status ? 1 : 0, {root:true});
            dispatch('Inbox/fetchInbox', { account_id: null, inbox_type: 'player' }, { root:true });

          })
          .catch((error) => {
            // Show error
            Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

            // Log to console
            console.log(error);
          });
  },

  // update Active Player
  updateActive({ dispatch, commit, state }, active){
    commit('setActive', active);
  },

  // Update Player Status
  updatePlayerStatus({ commit }, status){
    commit('setPlayer', status);
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}