<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="questionTypes.length" >
        </div>

        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>


</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    // /* Imports */
    // import * as am4core from "@amcharts/amcharts4/core";
    // import * as am4charts from "@amcharts/amcharts4/charts";
    // import am4themes_animated from "@amcharts/amcharts4/themes/animated";


    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";




    /* Chart code */
    // Themes begin
    // am4core.useTheme(am4themes_animated);
    // Themes end


    export default {


            created() {

            },
            mounted() {

                console.log('xy', this.questionTypes )

                let root;


                if ( this.questionTypes.length > 0) {
                    root = am5.Root.new(this.$refs.chartdiv);
                } else {
                    root = am5.Root.new(this.$refs.fakechart)
                }



                root.setThemes([am5themes_Animated.new(root)]);

                let chart = root.container.children.push(am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout,
                }));

                let legend = chart.children.push(am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                }));

                let data = this.questionTypes.length ? this.questionTypes : this.fakeQuestionTypes;

                let xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        baseInterval: {
                            timeUnit: this.questionTypes.length ? this.calendar_interval : 'year',
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                        }),

                    })
                );

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        min: 0,
                        renderer: am5xy.AxisRendererY.new(root, {})
                    })
                );


                let makeSeries = (name, fieldName) => {
                    let series = chart.series.push(
                        am5xy.ColumnSeries.new(root, {
                            name: name,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: fieldName,
                            valueXField: "date",
                            stacked: true,
                            clustered: false,
                            tooltip: am5.Tooltip.new(root, {})
                        })
                    );

                    series.data.processor = am5.DataProcessor.new(root, {
                        dateFields: ["date"],
                        dateFormat: "yyyy-MM-dd-HH"
                    });

                    series.get("tooltip").label.set("text", "{name}: [bold]{valueY}[/]");
                    series.data.setAll(data);
                }

                makeSeries(this.$t('reports.mentions'), "mentions");
                makeSeries(this.$t('reports.dm'), "dm");
                makeSeries(this.$t('reports.tracks'), "tracks");

                

                legend.data.setAll(chart.series.values);

                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                    behavior: "zoomXY",
                }));
                cursor.lineY.set("visible", false);

                if( this.questionTypes.length > 0) {
                    am5plugins_exporting.Exporting.new(root, {
                        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                        dataSource: data,
                        dateFields: ["date"],
                        dateFormat: "yyyy-MM-dd",
                        filePrefix: "convoTypes",
                    });
                }


                this.root = root;











                // let chart = null;

                // if ( this.questionTypes.length > 0) {
                //     // Create chart instance
                //     chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

                //     // Add data
                //     chart.data = this.questionTypes;
                // } else {
                //     // Create chart instance
                //     chart = am4core.create(this.$refs.fakechart, am4charts.XYChart);

                //     // Add data
                //     chart.data = this.fakeQuestionTypes;
                // }


                // // Set input format for the dates
                // chart.dateFormatter.inputDateFormat = "yyyy-MM-dd-HH";

                // // Create axes
                // let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                // dateAxis.dataFields.date = "date";

                // let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                // valueAxis.title.text = this.$t('page.questions');


                // // Create series (Mentions)
                // let series = chart.series.push(new am4charts.ColumnSeries());
                // series.dataFields.valueY = "mentions";
                // series.dataFields.dateX = "date";
                // series.name = this.$t('reports.mentions');
                // series.tooltipText = "{name}: [bold]{valueY}[/]";
                // series.stacked = true;
                // // Second series (DM-Direct Messages)
                // let series2 = chart.series.push(new am4charts.ColumnSeries());
                // series2.dataFields.valueY = "dm";
                // series2.dataFields.dateX = "date";
                // series2.name = this.$t('reports.dm');
                // series2.tooltipText = "{name}: [bold]{valueY}[/]";
                // series2.stacked = true;
                // // Third series (Tracks Messages)
                // let series3 = chart.series.push(new am4charts.ColumnSeries());
                // series3.dataFields.valueY = "tracks";
                // series3.dataFields.dateX = "date";
                // series3.name = this.$t('reports.tracks');
                // series3.tooltipText = "{name}: [bold]{valueY}[/]";
                // series3.stacked = true;


                // // Add cursor
                // chart.cursor = new am4charts.XYCursor();
                
                // dateAxis.keepSelection = true;

                // // Create legend instance
                // chart.legend = new am4charts.Legend();

                // chart.marginRight = 400;

                // // Export Menu to chart's
                // if( this.questionTypes.length > 0) {
                //     chart.exporting.menu = new am4core.ExportMenu();
                //     // File names will be myExport.png, myExport.pdf, etc
                //     chart.exporting.filePrefix = "convoTypes";
                // }

                // this.chart = chart;


        },

        beforeDestroy() {
            // if (this.chart) {
            //     this.chart.dispose();
            // }

            if (this.root) {
                this.root.dispose();
            }
        },


        data: function () {
            return {}
        },


        methods: {

        },

        computed: {

                // Map getters
                ...mapGetters({

                        questionTypes: 'Report/getQuestionTypes',
                        fakeQuestionTypes: 'Report/getFakeQuestionTypes',
                        calendar_interval: 'Report/getCalenderInterval',
                }),
        },

    }
</script>

<style scoped>


</style>