<template>

    <b-button

            v-b-popover.hover.top="$t('confirmation.show_full_conversation')"

            size="lg"
            variant="light"
            :to="{
                    name: 'messages',
                    params: { id: draft.account_id, customer_id: draft.customer_id, int: account.integration_id, type: 'questions'},
                 }">

        <font-awesome-icon :icon="'comments'" class="text-info" />


    </b-button>



</template>
<script>

    export default {

        props: ['account','draft'],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>