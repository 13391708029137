<template functional>

    <span class="message-header-data">

        <!-- Check integration -->
        <template v-if="props.integration == 'twitter'">

            <!-- Twitter  -->
            <font-awesome-icon :icon="['fab', 'twitter']" :id="'pid-' + props.id + '-' + props.post_id" />

        </template>

        <template v-if="props.integration == 'whatsapp'">

            <!-- Whatsapp  -->
            <font-awesome-icon style="font-size: 13px" :icon="['fab', 'whatsapp']" />

        </template>

        <template v-if="props.integration == 'facebook'">

            <!-- Facebook  -->
            <font-awesome-icon style="font-size: 13px" :icon="['fab', 'facebook']" :id="'pid-' + props.id + '-' + props.post_id" />
        </template>

        <template v-if="props.integration == 'instagram'">

            <!-- Facebook  -->
            <font-awesome-icon style="font-size: 13px" :icon="['fab', 'instagram']" :id="'pid-' + props.id + '-' + props.post_id" />

        </template>

        <template v-if="props.integration == 'sikayetvar'">

            <!-- Sikayetvar  -->
            <font-awesome-icon style="font-size: 13px" :icon="['fas', 'hand-holding-usd']" :id="'pid-' + props.id + '-' + props.post_id" />

        </template>

        <template v-if="props.integration == 'youtube'">

            <!-- YouTube  -->
            <font-awesome-icon style="font-size: 13px" :icon="['fab', 'youtube']" :id="'pid-' + props.id + '-' + props.post_id" />

        </template>

        <template v-if="props.integration == 'googlereview'">

            <!-- Google Review  -->
            <font-awesome-icon style="font-size: 13px" :icon="['fab', 'google']" :id="'pid-' + props.id + '-' + props.post_id" />

        </template>

        <template v-if="props.integration == 'googleplay'">

            <!-- GooglePlay  -->
            <font-awesome-icon style="font-size: 13px" :icon="['fab', 'google-play']" :id="'pid-' + props.id + '-' + props.post_id" />

        </template>

        <template v-if="props.integration == 'linkedin'">

            <!-- LinkedIn  -->
            <font-awesome-icon style="font-size: 13px" :icon="['fab', 'linkedin']" :id="'pid-' + props.id + '-' + props.post_id" />

        </template>

        <!-- Source popover -->
        <b-popover v-if="props.post_id" :target="'pid-' + props.id + '-' + props.post_id" triggers="click blur" placement="top">

            <template slot="title">{{ parent.$t('ticket.post_id') }}</template>

            <p>{{ props.post_id }}</p>

        </b-popover>

    </span>

</template>
<script>

    export default {

        props: ['integration', 'post_id', 'id'],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>