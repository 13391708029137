import {isNull} from "bootstrap-vue/esm/utils/inspect";
import {integer} from "vee-validate/dist/rules.esm";
import interval from "luxon/src/interval";

import { getTicketStatuses } from '../../utils/ticket.js'
//moment() for time
import moment from 'moment';


const state = {
    url: process.env.MIX_ELASTICSEARC_URL,
    search_api : process.env.MIX_ELASTICSEARC_URL + '/_search?size=0',
    sql_api : process.env.MIX_ELASTICSEARC_URL + '/_sql?format=json',
    username: process.env.MIX_ELASTICSEARC_USERNAME,
    password: process.env.MIX_ELASTICSEARC_PASS,
    date_range : [],
    calender_interval : 'hour',
    calender_interval_sql : '',
    account_list : null,
    account_list_sql : '',
    question_types : [],
    message_counts : [],
    customer_types : [],
    tags : [],
    report_categories : [],
    tag_processes : [],
    risk_heatmaps : [],
    category_processes : [],
    customer_genders : [],
    vip_important : [],
    schedule : [],
    transfer_result : [],
    frequency_maps : [],
    transfers_and_trash : [],
    user_touch_performance : [],
    team_touch_performance : [],
    account_activities : [],
    confirmations : [],
    primary_categories_data:[],
    secondary_categories_data:[],
    sub_categories_data:[],
    user_time_tables:[],
    team_time_tables:[],
    first_response_time_average:[],
    informations:[],
    target_key_0:'',
    target_key_1:'',
    target_key_2:'',
    target_key_3:'',
    sub_categories_active: false,
    secondary_categories_active: false,
    ticket_statuses: getTicketStatuses,
    maps : [],
    location_reports : [],
    language_incoming: [],
    language_outgoing: [],
    selected_type_for_lang: 'incoming',


    fake_data_report_view: {
        fake_question_types: [
            {
                date: "2021-01-01T00:00:00.000Z",
                dm: 90000,
                mentions: 400000,
                tracks: 40000,
            },
            {
                date: "2022-01-01T00:00:00.000Z",
                dm: 190000,
                mentions: 300000,
                tracks: 20000,
            },
            {
                date: "2023-01-01T00:00:00.000Z",
                dm: 20000,
                mentions: 200000,
                tracks: 10000,
            },

        ],

        fake_language_incoming_outgoing: [
            {
                "date": "2021-01-01T00:00:00.000Z",
                "TR": 10,
                "DE": 30,
                "EN": 30,
                "ES": 40,
                "FR": 30,
            },
            {
                "date": "2022-01-01T00:00:00.000Z",
                "DE": 30,
                "EN": 30,
                "ES": 40,
                "FR": 30,
                "IT": 30,
                "PT": 30,
                "RU": 60,
                "Unknown": 20
            },
            {
                "date": "2023-01-01T00:00:00.000Z",
                "EN": 30,
                "TR": 50,
                "UND": 10
            },
        ],

        fake_message_counts: [
            
            {
                date: "2021-01-01T00:00:00.000Z",
                incoming: 250000,
                outgoing: 100000,
                
            },
            {
                date: "2022-01-01T00:00:00.000Z",
                incoming: 350000,
                outgoing: 200000,
            },
            {
                date: "2023-01-01T00:00:00.000Z",
                incoming: 150000,
                outgoing: 100000,
            }, 
        ],

        fake_frequency_maps: [
            {
                weekday: "Monday",
                hour: "0:00",
                value: 95
            },
            {
                weekday: "Monday",
                hour: "1:00",
                value: 41
            },
            {
                weekday: "Monday",
                hour: "2:00",
                value: 25
            },
            {
                weekday: "Monday",
                hour: "3:00",
                value: 43
            },
            {
                weekday: "Monday",
                hour: "4:00",
                value: 186
            },
            {
                weekday: "Monday",
                hour: "5:00",
                value: 278
            },
            {
                weekday: "Monday",
                hour: "6:00",
                value: 730
            },
            {
                weekday: "Monday",
                hour: "7:00",
                value: 1015
            },
            {
                weekday: "Monday",
                hour: "8:00",
                value: 1200
            },
            {
                weekday: "Monday",
                hour: "9:00",
                value: 645
            },
            {
                weekday: "Monday",
                hour: "10:00",
                value: 828
            },
            {
                weekday: "Monday",
                hour: "11:00",
                value: 865
            },
            {
                weekday: "Monday",
                hour: "12:00",
                value: 805
            },
            {
                weekday: "Monday",
                hour: "13:00",
                value: 663
            },
            {
                weekday: "Monday",
                hour: "14:00",
                value: 401
            },
            {
                weekday: "Monday",
                hour: "15:00",
                value: 360
            },
            {
                weekday: "Monday",
                hour: "16:00",
                value: 481
            },
            {
                weekday: "Monday",
                hour: "17:00",
                value: 482
            },
            {
                weekday: "Monday",
                hour: "18:00",
                value: 341
            },
            {
                weekday: "Monday",
                hour: "19:00",
                value: 376
            },
            {
                weekday: "Monday",
                hour: "20:00",
                value: 349
            },
            {
                weekday: "Monday",
                hour: "21:00",
                value: 289
            },
            {
                weekday: "Monday",
                hour: "22:00",
                value: 176
            },
            {
                weekday: "Monday",
                hour: "23:00",
                value: 71
            },
            {
                weekday: "Tuesday",
                hour: "0:00",
                value: 36
            },
            {
                weekday: "Tuesday",
                hour: "1:00",
                value: 30
            },
            {
                weekday: "Tuesday",
                hour: "2:00",
                value: 27
            },
            {
                weekday: "Tuesday",
                hour: "3:00",
                value: 45
            },
            {
                weekday: "Tuesday",
                hour: "4:00",
                value: 53
            },
            {
                weekday: "Tuesday",
                hour: "5:00",
                value: 186
            },
            {
                weekday: "Tuesday",
                hour: "6:00",
                value: 770
            },
            {
                weekday: "Tuesday",
                hour: "7:00",
                value: 913
            },
            {
                weekday: "Tuesday",
                hour: "8:00",
                value: 1132
            },
            {
                weekday: "Tuesday",
                hour: "9:00",
                value: 463
            },
            {
                weekday: "Tuesday",
                hour: "10:00",
                value: 837
            },
            {
                weekday: "Tuesday",
                hour: "11:00",
                value: 832
            },
            {
                weekday: "Tuesday",
                hour: "12:00",
                value: 825
            },
            {
                weekday: "Tuesday",
                hour: "13:00",
                value: 732
            },
            {
                weekday: "Tuesday",
                hour: "14:00",
                value: 456
            },
            {
                weekday: "Tuesday",
                hour: "15:00",
                value: 304
            },
            {
                weekday: "Tuesday",
                hour: "16:00",
                value: 319
            },
            {
                weekday: "Tuesday",
                hour: "17:00",
                value: 278
            },
            {
                weekday: "Tuesday",
                hour: "18:00",
                value: 230
            },
            {
                weekday: "Tuesday",
                hour: "19:00",
                value: 477
            },
            {
                weekday: "Tuesday",
                hour: "20:00",
                value: 264
            },
            {
                weekday: "Tuesday",
                hour: "21:00",
                value: 180
            },
            {
                weekday: "Tuesday",
                hour: "22:00",
                value: 103
            },
            {
                weekday: "Tuesday",
                hour: "23:00",
                value: 47
            },
            {
                weekday: "Wednesday",
                hour: "0:00",
                value: 33
            },
            {
                weekday: "Wednesday",
                hour: "1:00",
                value: 24
            },
            {
                weekday: "Wednesday",
                hour: "2:00",
                value: 18
            },
            {
                weekday: "Wednesday",
                hour: "3:00",
                value: 21
            },
            {
                weekday: "Wednesday",
                hour: "4:00",
                value: 49
            },
            {
                weekday: "Wednesday",
                hour: "5:00",
                value: 135
            },
            {
                weekday: "Wednesday",
                hour: "6:00",
                value: 680
            },
            {
                weekday: "Wednesday",
                hour: "7:00",
                value: 1053
            },
            {
                weekday: "Wednesday",
                hour: "8:00",
                value: 1067
            },
            {
                weekday: "Wednesday",
                hour: "9:00",
                value: 613
            },
            {
                weekday: "Wednesday",
                hour: "10:00",
                value: 805
            },
            {
                weekday: "Wednesday",
                hour: "11:00",
                value: 979
            },
            {
                weekday: "Wednesday",
                hour: "12:00",
                value: 801
            },
            {
                weekday: "Wednesday",
                hour: "13:00",
                value: 641
            },
            {
                weekday: "Wednesday",
                hour: "14:00",
                value: 330
            },
            {
                weekday: "Wednesday",
                hour: "15:00",
                value: 250
            },
            {
                weekday: "Wednesday",
                hour: "16:00",
                value: 241
            },
            {
                weekday: "Wednesday",
                hour: "17:00",
                value: 297
            },
            {
                weekday: "Wednesday",
                hour: "18:00",
                value: 254
            },
            {
                weekday: "Wednesday",
                hour: "19:00",
                value: 252
            },
            {
                weekday: "Wednesday",
                hour: "20:00",
                value: 264
            },
            {
                weekday: "Wednesday",
                hour: "21:00",
                value: 174
            },
            {
                weekday: "Wednesday",
                hour: "22:00",
                value: 53
            },
            {
                weekday: "Wednesday",
                hour: "23:00",
                value: 31
            },
            {
                weekday: "Thursday",
                hour: "0:00",
                value: 15
            },
            {
                weekday: "Thursday",
                hour: "1:00",
                value: 16
            },
            {
                weekday: "Thursday",
                hour: "2:00",
                value: 15
            },
            {
                weekday: "Thursday",
                hour: "3:00",
                value: 30
            },
            {
                weekday: "Thursday",
                hour: "4:00",
                value: 78
            },
            {
                weekday: "Thursday",
                hour: "5:00",
                value: 108
            },
            {
                weekday: "Thursday",
                hour: "6:00",
                value: 520
            },
            {
                weekday: "Thursday",
                hour: "7:00",
                value: 759
            },
            {
                weekday: "Thursday",
                hour: "8:00",
                value: 1074
            },
            {
                weekday: "Thursday",
                hour: "9:00",
                value: 517
            },
            {
                weekday: "Thursday",
                hour: "10:00",
                value: 602
            },
            {
                weekday: "Thursday",
                hour: "11:00",
                value: 821
            },
            {
                weekday: "Thursday",
                hour: "12:00",
                value: 590
            },
            {
                weekday: "Thursday",
                hour: "13:00",
                value: 432
            },
            {
                weekday: "Thursday",
                hour: "14:00",
                value: 213
            },
            {
                weekday: "Thursday",
                hour: "15:00",
                value: 190
            },
            {
                weekday: "Thursday",
                hour: "16:00",
                value: 130
            },
            {
                weekday: "Thursday",
                hour: "17:00",
                value: 111
            },
            {
                weekday: "Thursday",
                hour: "18:00",
                value: 325
            },
            {
                weekday: "Thursday",
                hour: "19:00",
                value: 176
            },
            {
                weekday: "Thursday",
                hour: "20:00",
                value: 125
            },
            {
                weekday: "Thursday",
                hour: "21:00",
                value: 110
            },
            {
                weekday: "Thursday",
                hour: "22:00",
                value: 43
            },
            {
                weekday: "Thursday",
                hour: "23:00",
                value: 30
            },
            {
                weekday: "Friday",
                hour: "0:00",
                value: 16
            },
            {
                weekday: "Friday",
                hour: "1:00",
                value: 1
            },
            {
                weekday: "Friday",
                hour: "2:00",
                value: 4
            },
            {
                weekday: "Friday",
                hour: "3:00",
                value: 14
            },
            {
                weekday: "Friday",
                hour: "4:00",
                value: 31
            },
            {
                weekday: "Friday",
                hour: "5:00",
                value: 72
            },
            {
                weekday: "Friday",
                hour: "6:00",
                value: 403
            },
            {
                weekday: "Friday",
                hour: "7:00",
                value: 539
            },
            {
                weekday: "Friday",
                hour: "8:00",
                value: 649
            },
            {
                weekday: "Friday",
                hour: "9:00",
                value: 220
            },
            {
                weekday: "Friday",
                hour: "10:00",
                value: 316
            },
            {
                weekday: "Friday",
                hour: "11:00",
                value: 659
            },
            {
                weekday: "Friday",
                hour: "12:00",
                value: 589
            },
            {
                weekday: "Friday",
                hour: "13:00",
                value: 564
            },
            {
                weekday: "Friday",
                hour: "14:00",
                value: 179
            },
            {
                weekday: "Friday",
                hour: "15:00",
                value: 147
            },
            {
                weekday: "Friday",
                hour: "16:00",
                value: 163
            },
            {
                weekday: "Friday",
                hour: "17:00",
                value: 114
            },
            {
                weekday: "Friday",
                hour: "18:00",
                value: 96
            },
            {
                weekday: "Friday",
                hour: "19:00",
                value: 95
            },
            {
                weekday: "Friday",
                hour: "20:00",
                value: 77
            },
            {
                weekday: "Friday",
                hour: "21:00",
                value: 95
            },
            {
                weekday: "Friday",
                hour: "22:00",
                value: 61
            },
            {
                weekday: "Friday",
                hour: "23:00",
                value: 23
            },
            {
                weekday: "Saturday",
                hour: "0:00",
                value: 12
            },
            {
                weekday: "Saturday",
                hour: "1:00",
                value: 4
            },
            {
                weekday: "Saturday",
                hour: "2:00",
                value: 8
            },
            {
                weekday: "Saturday",
                hour: "3:00",
                value: 13
            },
            {
                weekday: "Saturday",
                hour: "4:00",
                value: 27
            },
            {
                weekday: "Saturday",
                hour: "5:00",
                value: 34
            },
            {
                weekday: "Saturday",
                hour: "6:00",
                value: 50
            },
            {
                weekday: "Saturday",
                hour: "7:00",
                value: 96
            },
            {
                weekday: "Saturday",
                hour: "8:00",
                value: 112
            },
            {
                weekday: "Saturday",
                hour: "9:00",
                value: 105
            },
            {
                weekday: "Saturday",
                hour: "10:00",
                value: 107
            },
            {
                weekday: "Saturday",
                hour: "11:00",
                value: 96
            },
            {
                weekday: "Saturday",
                hour: "12:00",
                value: 97
            },
            {
                weekday: "Saturday",
                hour: "13:00",
                value: 58
            },
            {
                weekday: "Saturday",
                hour: "14:00",
                value: 65
            },
            {
                weekday: "Saturday",
                hour: "15:00",
                value: 67
            },
            {
                weekday: "Saturday",
                hour: "16:00",
                value: 67
            },
            {
                weekday: "Saturday",
                hour: "17:00",
                value: 67
            },
            {
                weekday: "Saturday",
                hour: "18:00",
                value: 62
            },
            {
                weekday: "Saturday",
                hour: "19:00",
                value: 173
            },
            {
                weekday: "Saturday",
                hour: "20:00",
                value: 135
            },
            {
                weekday: "Saturday",
                hour: "21:00",
                value: 80
            },
            {
                weekday: "Saturday",
                hour: "22:00",
                value: 66
            },
            {
                weekday: "Saturday",
                hour: "23:00",
                value: 24
            },
            {
                weekday: "Sunday",
                hour: "0:00",
                value: 14
            },
            {
                weekday: "Sunday",
                hour: "1:00",
                value: 3
            },
            {
                weekday: "Sunday",
                hour: "2:00",
                value: 5
            },
            {
                weekday: "Sunday",
                hour: "3:00",
                value: 11
            },
            {
                weekday: "Sunday",
                hour: "4:00",
                value: 11
            },
            {
                weekday: "Sunday",
                hour: "5:00",
                value: 31
            },
            {
                weekday: "Sunday",
                hour: "6:00",
                value: 47
            },
            {
                weekday: "Sunday",
                hour: "7:00",
                value: 67
            },
            {
                weekday: "Sunday",
                hour: "8:00",
                value: 37
            },
            {
                weekday: "Sunday",
                hour: "9:00",
                value: 84
            },
            {
                weekday: "Sunday",
                hour: "10:00",
                value: 70
            },
            {
                weekday: "Sunday",
                hour: "11:00",
                value: 160
            },
            {
                weekday: "Sunday",
                hour: "12:00",
                value: 410
            },
            {
                weekday: "Sunday",
                hour: "13:00",
                value: 787
            },
            {
                weekday: "Sunday",
                hour: "14:00",
                value: 803
            },
            {
                weekday: "Sunday",
                hour: "15:00",
                value: 1050
            },
            {
                weekday: "Sunday",
                hour: "16:00",
                value: 818
            },
            {
                weekday: "Sunday",
                hour: "17:00",
                value: 1085
            },
            {
                weekday: "Sunday",
                hour: "18:00",
                value: 1097
            },
            {
                weekday: "Sunday",
                hour: "19:00",
                value: 1076
            },
            {
                weekday: "Sunday",
                hour: "20:00",
                value: 804
            },
            {
                weekday: "Sunday",
                hour: "21:00",
                value: 616
            },
            {
                weekday: "Sunday",
                hour: "22:00",
                value: 412
            },
            {
                weekday: "Sunday",
                hour: "23:00",
                value: 226
            }
        ],

        fake_account_activities: [
            {
                account: "A (GOOGLEREVIEW)",
                incoming: 0,
                outgoing: 0,
            },
            {
                account: "B (YOUTUBE)",
                incoming: 52,
                outgoing: 75,

            },
            {
                account: "C (FACEBOOK)",
                incoming: 300,
                outgoing: 200,
            },
            {
                account: "C (INSTAGRAM)",
                incoming: 150,
                outgoing: 100,
            },
            {
                account: "D (TWITTER)",
                incoming: 250,
                outgoing: 400,
            },
            {
                account: "E (WHATSAPP)",
                incoming: 350,
                outgoing: 400,
            },
            {
                account: "F (INSTAGRAM)",
                incoming: 150,
                outgoing: 400,
            },
            {
                account: "G (GOOGLEPLAY)",
                incoming: 250,
                outgoing: 200,
            },
        
        ],

        fake_customer_genders: [
            {
                date: "2021-01-01T00:00:00.000Z",
                unknown: 20796,
                male: 1830,
                female: 2000,
            },
            {
                date: "2022-01-01T00:00:00.000Z",
                unknown: 44000,
                male: 4000,
                female: 6000,
            },
            {
                date: "2023-01-01T00:00:00.000Z",
                unknown: 10000,
                male: 3000,
                female: 4000,
            }
        ],

        fake_vip_important: [
            500,
            { name: "Important", value: 1400 },
            { name: "VIP", value: 1300 },
            { name: "Both", value: 600, sets: ["Important", "VIP"] }
        ],

        fake_customer_types: [
            {
                date: "2021-01-01T00:00:00.000Z",
                "Customer Type 1": 2000,
                "Customer Type 2": 1000,
                "Customer Type 3": 3000,
                "Customer Type 4": 4000,
            },
            {
                date: "2022-01-01T00:00:00.000Z",
                "Customer Type 1": 1000,
                "Customer Type 2": 2000,
                "Customer Type 4": 2000,
            },
            {
                date: "2023-01-01T00:00:00.000Z",
                "Customer Type 3": 500,
                "Customer Type 4": 1000,
                "Customer Type 5": 2000,

            }
        ],

        fake_transfers_and_trash:  [
            {
                date: "2021-01-01T00:00:00.000Z",
                "Trash": 50,
                "Transfer(s)": 150
            },

            {
                date: "2022-01-01T00:00:00.000Z",
                "Trash": 30,
                "Transfer(s)": 100
            },

            {
                date: "2023-01-01T00:00:00.000Z",
                "Trash": 20,
                "Transfer(s)": 80
            }
        ],

        fake_confirmations: [
            {
                category: "accepted",
                value: 100
            },
            {
                category: "rejected",
                value: 50
            },
            {
                category: "awaiting",
                value: 20
            }

        ],

        fake_schedule: [
            {
                category: "Sent",
                value: 10
            },
            {
                category: "Waiting",
                value: 4
            },
            {
                category: "Canceled",
                value: 3
            }
        ],

        fake_transfer_result: [
            {
                category: "Replied",
                value: 10
            },
            {
                category: "Awaiting",
                value: 4
            },
        ],

        fake_tags: [
            {
                "tags": "Tag A",
                value: 20
            },
            {
                "tags": "Tag B",
                value: 0
            },
            {
                "tags": "Tag C",
                value: 15
            },
            {
                "tags": "Tag D",
                value: 25
            },
        ],

        fake_primary_categories_data: [
            {
                "primary_category": "Primary Category - A",
                value: 500
            },
            {
                "primary_category": "Primary Category - B",
                value: 600
            },
            {
                "primary_category": "Primary Category - C",
                value: 200
            },
            {
                "primary_category": "Primary Category - D",
                value: 0
            },
            {
                "primary_category": "Primary Category - E",
                value: 150
            }
        ],

        fake_secondary_categories_data: [
            {
                "second_category": "Secondary Category - A",
                value: 200
            },
            {
                "second_category": "Secondary Category - B",
                value: 400
            },
            {
                "second_category": "Secondary Category - C",
                value: 300
            },

        ],

        fake_sub_categories_data: [
            {
                "sub_category": "Sub Category - A",
                value: 100
            },
            {
                "sub_category": "Sub Category - B",
                value: 200
            },
            {
                "sub_category": "Sub Category - C",
                value: 0
            },


        ],

        fake_tag_processes: [
            {
                "tags": "Tag A",
                "status_open": 0,
                "status_replied": 15,
                "status_transferred": 20,
                "status_awaiting": 10,
                "status_closed": 35,
                "status_unknown": 20
            },
            {
                "tags": "Tag B",
                "status_open": 10,
                "status_replied": 5,
                "status_transferred": 30,
                "status_awaiting": 15,
                "status_closed": 10,
                "status_unknown": 10
            },
            {
                "tags": "Tag C",
                "status_open": 20,
                "status_replied": 15,
                "status_transferred": 20,
                "status_awaiting": 10,
                "status_closed": 15,
                "status_unknown": 30
            },
            {
                "tags": "Tag D",
                "status_open": 20,
                "status_replied": 35,
                "status_transferred": 20,
                "status_awaiting": 20,
                "status_closed": 15,
                "status_unknown": 20,
            },
        ],

        fake_risk_heatmaps_en: [
            {
              y: "Open",
              x: "Best",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 20
            },
            {
              y: "Replied",
              x: "Best",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 15
            },
            {
              y: "Transferred",
              x: "Best",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 25
            },
            {
              y: "Awaiting",
              x: "Best",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 15
            },
            {
              y: "Closed",
              x: "Best",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 12
            },
            {
               y: "Unknown",
               x: "Best",
               columnSettings: {
                 fill: "#0b7d03"
               },
               value: 10
            },
            {
              y: "Open",
              x: "Good",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 30
            },
            {
              y: "Replied",
              x: "Good",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 24
            },
            {
              y: "Transferred",
              x: "Good",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 25
            },
            {
              y: "Awaiting",
              x: "Good",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 15
            },
            {
              y: "Closed",
              x: "Good",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 25
            },
            {
              y: "Unknown",
              x: "Good",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 15
            },
            {
              y: "Open",
              x: "Medium",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 33
            },
            {
              y: "Replied",
              x: "Medium",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 14
            },
            {
              y: "Transferred",
              x: "Medium",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 20
            },
            {
              y: "Awaiting",
              x: "Medium",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 19
            },
            {
              y: "Closed",
              x: "Medium",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 25
            },
            {
                y: "Unknown",
                x: "Medium",
                columnSettings: {
                  fill: "#5dbe24"
                },
                value: 25
            },
            {
              y: "Open",
              x: "Bad",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 31
            },
            {
              y: "Replied",
              x: "Bad",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 24
            },
            {
              y: "Transferred",
              x: "Bad",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 25
            },
            {
              y: "Awaiting",
              x: "Bad",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 15
            },
            {
              y: "Closed",
              x: "Bad",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 15
            },
            {
                y: "Unknown",
                x: "Bad",
                columnSettings: {
                  fill: "#5dbe24"
                },
                value: 20,
            },
            {
              y: "Open",
              x: "Critical",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 12
            },
            {
              y: "Replied",
              x: "Critical",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 14
            },
            {
              y: "Transferred",
              x: "Critical",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 15
            },
            {
              y: "Awaiting",
              x: "Critical",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 25
            },
            {
              y: "Closed",
              x: "Critical",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 19
            },
            {
                y: "Unknown",
                x: "Critical",
                columnSettings: {
                  fill: "#e1d92d"
                },
                value: 19
              }
        ],

        fake_risk_heatmaps_tr: [
            {
              y: "Açık",
              x: "Best",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 20
            },
            {
              y: "Cevaplandı",
              x: "Best",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 15
            },
            {
              y: "Transfer",
              x: "Best",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 25
            },
            {
              y: "Beklemede",
              x: "Best",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 15
            },
            {
              y: "Kapalı",
              x: "Best",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 12
            },
            {
               y: "Bilinmiyor",
               x: "Best",
               columnSettings: {
                 fill: "#0b7d03"
               },
               value: 10
            },
            {
              y: "Açık",
              x: "Good",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 30
            },
            {
              y: "Cevaplandı",
              x: "Good",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 24
            },
            {
              y: "Transfer",
              x: "Good",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 25
            },
            {
              y: "Beklemede",
              x: "Good",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 15
            },
            {
              y: "Kapalı",
              x: "Good",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 25
            },
            {
              y: "Bilinmiyor",
              x: "Good",
              columnSettings: {
                fill: "#0b7d03"
              },
              value: 15
            },
            {
              y: "Açık",
              x: "Medium",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 33
            },
            {
              y: "Cevaplandı",
              x: "Medium",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 14
            },
            {
              y: "Transfer",
              x: "Medium",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 20
            },
            {
              y: "Beklemede",
              x: "Medium",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 19
            },
            {
              y: "Kapalı",
              x: "Medium",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 25
            },
            {
                y: "Bilinmiyor",
                x: "Medium",
                columnSettings: {
                  fill: "#5dbe24"
                },
                value: 25
            },
            {
              y: "Açık",
              x: "Bad",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 31
            },
            {
              y: "Cevaplandı",
              x: "Bad",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 24
            },
            {
              y: "Transfer",
              x: "Bad",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 25
            },
            {
              y: "Beklemede",
              x: "Bad",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 15
            },
            {
              y: "Kapalı",
              x: "Bad",
              columnSettings: {
                fill: "#5dbe24"
              },
              value: 15
            },
            {
                y: "Bilinmiyor",
                x: "Bad",
                columnSettings: {
                  fill: "#5dbe24"
                },
                value: 20,
            },
            {
              y: "Açık",
              x: "Critical",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 12
            },
            {
              y: "Cevaplandı",
              x: "Critical",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 14
            },
            {
              y: "Transfer",
              x: "Critical",
              columnSettings: {
                fill: "#ca0101"
              },
              value: 15
            },
            {
              y: "Beklemede",
              x: "Critical",
              columnSettings: {
                fill: "#e17a2d"
              },
              value: 25
            },
            {
              y: "Kapalı",
              x: "Critical",
              columnSettings: {
                fill: "#e1d92d"
              },
              value: 19
            },
            {
                y: "Bilinmiyor",
                x: "Critical",
                columnSettings: {
                  fill: "#e1d92d"
                },
                value: 19
              }
        ],

        fake_category_processes: [
            {
                category: "Category 1",
                status_open: 10,
                status_replied: 20,
                status_transferred: 35,
                status_awaiting: 25,
                status_closed: 30,
            },
            {
                category: "Category 2",
                status_open: 30,
                status_replied: 40,
                status_transferred: 15,
                status_awaiting: 35,
                status_closed: 40,
            },
            {
                category: "Category 3",
                status_open: 100,
                status_replied: 120,
                status_transferred: 135,
                status_awaiting: 125,
                status_closed: 130,
            },
            {
                category: "Category 4",
                status_open: 120,
                status_replied: 130,
                status_transferred: 15,
                status_awaiting: 125,
                status_closed: 150,
            },
            {
                category: "Category 5",
                status_open: 100,
                status_replied: 30,
                status_transferred: 30,
                status_awaiting: 25,
                status_closed: 50,
            },
            {
                category: "Category 6",
                status_open: 200,
                status_replied: 230,
                status_transferred: 230,
                status_awaiting: 225,
                status_closed: 250,
            },
            {
                category: "Category 7",
                status_open: 1000,
                status_replied: 300,
                status_transferred: 300,
                status_awaiting: 250,
                status_closed: 50,
            },

        ],

        fake_user_time_tables: [
            {
                category: "User 1",
                value: 40000.50,
            },
            {
                category: "User 2",
                value: 2000.30,
            },
            {
                category: "User 3",
                value: 3400.00,
            },
            {
                category: "User 4",
                value: 15000.75,
            },
            {
                category: "User 5",
                value: 21000.40,
            },
        ],

        fake_user_touch_performance: [
            {
                user: "User 7",
                value: 20000,
                pictureSettings: {
                    src: "/images/awards/1.png"
                }
            },
            {
                user: "User 3",
                value: 15000,
                pictureSettings: {
                    src: "/images/awards/2.png"
                }
            },
            {
                user: "User 1",
                value: 10000,
                pictureSettings: {
                    src: "/images/awards/3.png"
                }
            },
            {
                user: "User 2",
                value: 5000,
                pictureSettings: {
                    src: "/images/awards/4.png"
                }
            },
            {
                user: "User 5",
                value: 2500,
                pictureSettings: {
                    src: "/images/awards/5.png"
                }
            },
            {
                user: "User 6",
                value: 1000,
                pictureSettings: {
                    src: "/images/awards/6.png"
                }
            }
        ],

        fake_team_time_tables: [
            {
                team_first_response: 13000.50,
                date: "2021-01-01T00:00:00.000Z"
            },
            {
                team_first_response: 15000.75,
                date: "2022-01-01T00:00:00.000Z"
            },
            {
                team_first_response: 10000.00,
                date: "2023-01-01T00:00:00.000Z"
            }
        ],

        fake_team_touch_performance: [
            {
                team: "Team 7",
                value: 20000,
                pictureSettings: {
                    src: "/images/awards/1.png"
                }
            },
            {
                team: "Team 3",
                value: 15000,
                pictureSettings: {
                    src: "/images/awards/2.png"
                }
            },
            {
                team: "Team 1",
                value: 10000,
                pictureSettings: {
                    src: "/images/awards/3.png"
                }
            },
            {
                team: "Team 2",
                value: 5000,
                pictureSettings: {
                    src: "/images/awards/4.png"
                }
            },
            {
                team: "Team 5",
                value: 2500,
                pictureSettings: {
                    src: "/images/awards/5.png"
                }
            },
            {
                team: "Team 6",
                value: 1000,
                pictureSettings: {
                    src: "/images/awards/6.png"
                }
            }
        ],

        fake_maps: [
            {
                lat: -73.778137,
                lng: 40.641312,
            },
            {
                lat: -0.454296,
                lng: 51.470020,
            },
            {
                lat: 116.597504,
                lng: 40.072498,
            },
            {
                lat: 41.008237,
                lng: -74.0059728,
            },
            {
                lat: 52.5200066,
                lng: 13.404954,
            },
            {
                lat: 41.0082376,
                lng: 28.9783589,
            },
            {
                lat: 39.047186,
                lng: 39.047186,
            },

            {
                lat: 39.251664,
                lng: 30.094933,
            },
        ]
    },
    
};

// getters
const getters = {

    // Get First Response Time - Average
    getFirstResponseAverage: (state) => {

        // Return First Response Time - Average
        return state.first_response_time_average;
    },

    // Get Informations (Awaiting/Unresolved/Resolved)
    getInformations: (state) => {

        // Return First Response Time - Average
        return state.informations;
    },

    // Get Question Types
    getQuestionTypes: (state) => {

        // Return Question Types
        return state.question_types;
    },

    getFakeQuestionTypes: (state) => {
        return state.fake_data_report_view.fake_question_types;
    },

    // Get Message Counts
    getMessageCounts: (state) => {

        // Return Message Counts
        return state.message_counts;
    },

    getFakeMessageCounts: (state) => {
        return state.fake_data_report_view.fake_message_counts;
    },

    // Get Customer Types
    getCustomerTypes: (state) => {

        // Return Customer Types
        return state.customer_types;
    },

    getFakeCustomerTypes: (state) => {
        return state.fake_data_report_view.fake_customer_types;
    },

    // Get Tags Report
    getTagReports: (state) => {

        // Return Customer Types
        return state.tags;
    },

    getFakeTagReports: (state) => {
        return state.fake_data_report_view.fake_tags;
    },

    // Get Tag Processes Report
    getTagProcess: (state) => {

        // Return Tag Process Reports
        return state.tag_processes;
    },

    getFakeTagProcess: (state) => {
        return state.fake_data_report_view.fake_tag_processes;
    },

    // Get Category Report
    getCategoryProcess: (state) => {

        // Return Category Process Reports
        return state.category_processes;
    },

    getFakeCategoryProcess: (state) => {
        return state.fake_data_report_view.fake_category_processes;
    },

    // Get Risk Heatmap Report
    getRiskHeatmap: (state) => {

        // Return Risk Heatmap Reports
        return state.risk_heatmaps;
    },

    getFakeRiskHeatmapEn: (state) => {
        // Return Risk Heatmap Reports
        return state.fake_data_report_view.fake_risk_heatmaps_en;
    },

    getFakeRiskHeatmapTr: (state) => {
        // Return Risk Heatmap Reports
        return state.fake_data_report_view.fake_risk_heatmaps_tr;
    },

    // Get Categories Report
    getCategoryReports: (state) => {

        // Return Customer Types
        return state.report_categories;
    },

    // Get Secondary Category Report Area Active/Passive
    getSecondaryActive: (state) => {

        // Return Secondary-Category Active/Passive
        return state.secondary_categories_active;
    },

    // Get Sub Category Report Area Active/Passive
    getSubActive: (state) => {

        // Return Sub-Category Active/Passive
        return state.sub_categories_active;
    },

    // Get Primary Categories Report
    getPrimaryCategories: (state) => {

        // Return Customer Types
        return state.primary_categories_data;
    },

    getFakePrimaryCategories: (state) => {
        return state.fake_data_report_view.fake_primary_categories_data;
    },

    // Get Secondary Categories Report
    getSecondaryCategories: (state) => {

        // Return Customer Types
        return state.secondary_categories_data;
    },

    getFakeSecondaryCategories: (state) => {
        return state.fake_data_report_view.fake_secondary_categories_data;
    },

    // Get Sub-Categories Report
    getSubCategories: (state) => {

        // Return Customer Types
        return state.sub_categories_data;
    },

    getFakeSubCategories: (state) => {
        return state.fake_data_report_view.fake_sub_categories_data;
    },

    // Get Customer Genders
    getCustomerGenders: (state) => {
        // Return Customer Genders
        return state.customer_genders;
    },

    getFakeCustomerGenders: (state) => {
        return state.fake_data_report_view.fake_customer_genders;
    },

    // Get Customer Types(VIP-IMPORTANT)
    getVipImportant: (state) => {
        // Return Message Counts
        return state.vip_important;
    },

    getFakeVipImportant: (state) => {
        // Return Message Counts
        return state.fake_data_report_view.fake_vip_important;
    },

    // Get Transfers & Trash
    getTransfersAndTrash: (state) => {
        // Return Message Counts
        return state.transfers_and_trash;
    },

    getFakeTransfersAndTrash: (state) => {
        // Return Message Counts
        return state.fake_data_report_view.fake_transfers_and_trash;
    },

    // Get Transfers & Trash
    getConfirmations: (state) => {
        // Return Message Counts
        return state.confirmations;
    },

    getFakeConfirmations: (state) => {
        // Return Message Counts
        return state.fake_data_report_view.fake_confirmations;
    },

    // Get Customer Schedule Info
    getSchedule: (state) => {
        // Return Message Counts
        return state.schedule;
    },

    getFakeSchedule: (state) => {
        return state.fake_data_report_view.fake_schedule;
    },

    // Get Customer Types
    getTransferResult: (state) => {
        // Return Message Counts
        return state.transfer_result;
    },

    getFakeTransferResult: (state) => {
        // Return Message Counts
        return state.fake_data_report_view.fake_transfer_result;
    },

    // Get Users Touch Performance
    getUserTouchPerformance: (state) => {
        // Return Users Touch Performance
        return state.user_touch_performance;
    },

    getFakeUserTouchPerformance: (state) => {
        return state.fake_data_report_view.fake_user_touch_performance;
    },

    // Get Users Touch Performance
    getTeamTouchPerformance: (state) => {
        // Return Users Touch Performance
        return state.team_touch_performance;
    },

    getFakeTeamTouchPerformance: (state) => {
        return state.fake_data_report_view.fake_team_touch_performance;
    },

    // Get Account Activitie
    getAccountActivities: (state) => {
        // Return Account Activitie
        return state.account_activities;
    },

    getFakeAccountActivities: (state) => {
        // Return Account Activitie
        return state.fake_data_report_view.fake_account_activities;
    },



    // Get User Time Table Reports
    getUserTimeTable: (state) => {
        // Return Account Activitie
        return state.user_time_tables;
    },

    getFakeUserTimeTable: (state) => {
        return state.fake_data_report_view.fake_user_time_tables;
    },

    // Get Team Time Table Reports
    getTeamTimeTable: (state) => {
        // Return Account Activitie
        return state.team_time_tables;
    },

    getFakeTeamTimeTable: (state) => {
        // Return Account Activitie
        return state.fake_data_report_view.fake_team_time_tables;
    },

    // Get Frequency Map
    getFrequencyMaps: (state) => {

        // Return Frequency Map
        return state.frequency_maps;
    },

    getFakeFrequencyMaps: (state) => {
        return state.fake_data_report_view.fake_frequency_maps;
    },



    // Get Frequency Map
    getMapsReport: (state) => {

        // Return Frequency Map
        return state.maps;
    },


    getFakeMapsReport: (state) => {

        // Return Frequency Map
        return state.fake_data_report_view.fake_maps;
    },

    getLanguageIncomingOutgoing: (state) => {
        if(state.selected_type_for_lang === 'incoming') {
            return state.language_incoming;
        } else if (state.selected_type_for_lang === 'outgoing') {
            return state.language_outgoing
        } else if (state.selected_type_for_lang === 'all') {
            const result = Object.values([...state.language_incoming, ...state.language_outgoing].reduce((a, {date, ...values}) => {
                a[date] ??= { date };
                Object.entries(values).forEach(([key, value]) => {
                  a[date][key] ??= 0;
                  a[date][key] += value;
                });
                return a;
              } , {}));
            
            return result
        }
    },
    getFakeLanguageIncomingOutgoing: (state) => {
       return state.fake_data_report_view.fake_language_incoming_outgoing
    },

    getSelectedTypeForLanguage: (state) => {
        return state.selected_type_for_lang;
    },

    // Get Frequency Map
    getLocationReports: (state) => {

        // Return Frequency Map
        return state.location_reports;
    },
    // Get Credentials ElasticSearch
    getCredentials: (state) => {
        // Return Credentials Info
        return [state.username, state.password];
    },

    // Get getElasticUrl for ElasticSearch
    getElasticUrl: (state) => {
        return state.url;
    },

    getCalenderInterval: (state) => {
        return state.calender_interval
    },    
}

// mutations
const mutations = {

    // Set Date Rnge
    setDateRange (state, date_range) {
        state.date_range = date_range;
    },

    // Set Calender type
    setCalenderInterval (state, calender_interval) {
        state.calender_interval = calender_interval;
    },

    // Set Calender type
    setCalenderIntervalSql (state, calender_interval_sql) {
        state.calender_interval_sql = calender_interval_sql;
    },

    // Set Account List
    setAccountList (state, account_list) {
        state.account_list = account_list;
    },

    // Set Account List SQL
    setAccountListSql (state, account_list_sql) {
        state.account_list_sql = account_list_sql;
    },


    setSecondaryActive (state, secondary_categories_active) {
        state.secondary_categories_active = secondary_categories_active;
    },

    setSubActive (state, sub_categories_active) {
        state.sub_categories_active = sub_categories_active;
    },

    setSelectedTypeForLanguage(state, selected_type_for_lang) {
        state.selected_type_for_lang = selected_type_for_lang;
    },
    
}

// actions
const actions = {

    // get Question Types Count
    async fetchQuestionTypes({ dispatch, state }){

        // Set Created_at for Data
        dispatch('updateCalenderIntervalSql', 'inbox_created_at');

        // Start waiting
        dispatch('wait/start', 'reports', { root: true });


        let queries = {
            "query":"SELECT message_type, " + state.calender_interval_sql + ", COUNT(customer_id) AS message_type_value FROM inboxes WHERE " + state.account_list_sql + " inbox_created_at >= '" + state.date_range.gte + "T00:00:00' AND inbox_created_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY " + state.calender_interval_sql + ", message_type"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.question_types = [];

                let id = interval;


                // Fill Question Types Count with Data
                response.data.rows.forEach(buckets => {

                    let customer_type = {};

                    if(state.question_types[id] && buckets[1] === state.question_types[id].date){

                        // parse and fill Message Counts
                        if(buckets[0] === 1){
                            state.question_types[id]['dm'] = buckets[2];
                            // Mention
                        }else if(buckets[0] === 2){
                            state.question_types[id]['mentions'] = buckets[2];
                            // Track
                        }else if(buckets[0] === 3){
                            state.question_types[id]['tracks'] = buckets[2];
                        }

                    }else if( buckets[0] > 3 || buckets[0] < 1) {
                        return;
                    }else{

                        // parse and fill date
                        customer_type['date'] = buckets[1];

                        // parse and fill Message Counts
                        if(buckets[0] === 1){
                            customer_type['dm'] = buckets[2];
                            customer_type['mentions'] = 0;
                            customer_type['tracks'] = 0;
                            // Mention
                        }else if(buckets[0] === 2){
                            customer_type['mentions'] = buckets[2];
                            customer_type['dm'] = 0;
                            customer_type['tracks'] = 0;
                            // Track
                        }else if(buckets[0] === 3){
                            customer_type['tracks'] = buckets[2];
                            customer_type['dm'] = 0;
                            customer_type['mentions'] = 0;
                        }

                        // Push Data to State
                        state.question_types.push(
                            customer_type
                        );

                        let keys = Object.keys(state.question_types);
                        id = keys[keys.length - 1];
                    }


                })

                // Stop waiting
                dispatch('wait/end', 'reports', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'reports', { root: true });

            });

    },

    // get Incoming & Outgoing Messages
    async fetchMessageCounts({ dispatch, state }){

        // Set Created_at for Data
        dispatch('updateCalenderIntervalSql', 'inbox_created_at');

        // Start waiting
        dispatch('wait/start', 'reportsio', { root: true });

        let queries = {
            "query":"SELECT COUNT(draft_id) AS draft_value, " + state.calender_interval_sql + ", COUNT(questions_id) AS questions_value  FROM inboxes WHERE " + state.account_list_sql + " inbox_created_at >= '" + state.date_range.gte + "T00:00:00' AND inbox_created_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY " + state.calender_interval_sql
        }


       let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.message_counts = [];

                let id = interval;

                // Fill Message Counts with Data
                response.data.rows.forEach(buckets => {

                    let customer_type = {};
                    if(state.message_counts[id] && buckets[1] === state.message_counts[id].date){

                        // parse and fill Message Counts
                        state.message_counts[id]['incoming'] = buckets[2];
                        state.message_counts[id]['outgoing'] = buckets[0];

                    }else{

                        // parse and fill date
                        customer_type['date'] = buckets[1];

                        // parse and fill Message Counts
                        customer_type['incoming'] = buckets[2];
                        customer_type['outgoing'] = buckets[0];

                        // Push Data to State
                        state.message_counts.push(
                            customer_type
                        );

                        let keys = Object.keys(state.message_counts);
                        id = keys[keys.length - 1];
                    }

                })

                // Stop waiting
                dispatch('wait/end', 'reportsio', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'reportsio', { root: true });

            });

    },

    // get Customer Types
    async fetchCustomerTypes({ dispatch, state }){
        // Set Created_at for Data
        dispatch('updateCalenderIntervalSql', 'inbox_created_at');

        // Start waiting
        dispatch('wait/start', 'reportsCustomer', { root: true });

        let queries = {
            "query":"SELECT COUNT(DISTINCT customer_id) AS value, " + state.calender_interval_sql + ", customer_types_name AS customer_type FROM inboxes WHERE " + state.account_list_sql + " inbox_created_at >= '" + state.date_range.gte + "T00:00:00' AND inbox_created_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY " + state.calender_interval_sql + ", customer_types_name"

        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {


                // Make array empty
                state.customer_types = [];

                let id = interval;

                // Fill Customer Types with Data
                response.data.rows.forEach(buckets => {

                    let customer_type = {};
                    if(state.customer_types[id] && buckets[1] === state.customer_types[id].date){

                        // parse and fill Customer Type
                        state.customer_types[id][buckets[2]] = buckets[0];

                    }else{

                        // parse and fill date
                        customer_type['date'] = buckets[1];

                        // parse and fill Customer Type
                        customer_type[buckets[2]] = buckets[0];

                        // Push Data to State
                        state.customer_types.push(
                            customer_type
                        );

                        let keys = Object.keys(state.customer_types);
                        id = keys[keys.length - 1];
                    }

                })

                // Stop waiting
                dispatch('wait/end', 'reportsCustomer', { root: true });

            })
            .catch((error) => {
                // // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'reportsCustomer', { root: true });

            });

    },

    // get Frequency Map
    async fetchFrequencyMap({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'reportsFrequencyMap', { root: true });

       let queries = {
           "query":"SELECT COUNT(DISTINCT draft_id) AS draft_value, COUNT(DISTINCT questions_id) AS questions_value, DAY_NAME(CAST(inbox_created_at AS TIMESTAMP)) AS day, HOUR_OF_DAY(CAST(inbox_created_at AS TIMESTAMP)) AS hour, ISO_DAY_OF_WEEK(inbox_created_at) FROM inboxes WHERE " + state.account_list_sql + " inbox_created_at >= '" + state.date_range.gte + "T00:00:00' AND inbox_created_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY ISO_DAY_OF_WEEK(inbox_created_at), HOUR(CAST(inbox_created_at AS TIMESTAMP)), DAY_NAME(CAST(inbox_created_at AS TIMESTAMP))"
       }

       let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.frequency_maps = [];

                // Fill Customer Types with Data
                response.data.rows.forEach(buckets => {

                    if (buckets[0] + buckets[1] === 0 ) return;

                    let frequency_map = {};

                    // parse and fill Weekday
                    frequency_map['weekday'] = buckets[2];

                    // parse and fill Hour
                    frequency_map['hour'] = buckets[3] + ':00';

                    // parse and fill Value

                    frequency_map['value'] = buckets[0] + buckets[1];

                    // Push Data to State
                    state.frequency_maps.push(
                        frequency_map
                    );
                })

                // Stop waiting
                dispatch('wait/end', 'reportsFrequencyMap', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'reportsFrequencyMap', { root: true });

            });

    },

    // get Customer Importance Types(VIP-Important)
    async fetchVipImportant({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'importance', { root: true });

        let queries = {
            "query":"SELECT COUNT(DISTINCT customer_id) AS uniq, COUNT(CASE WHEN customer_types_important = true THEN 1 END) AS important_value, COUNT(CASE WHEN customer_types_vip = true THEN 1 END) AS vip_value, COUNT(CASE WHEN customer_types_important = true AND customer_types_vip = true THEN 1 END) AS both_value FROM inboxes WHERE " + state.account_list_sql + " inbox_created_at >= '" + state.date_range.gte + "T00:00:00' AND inbox_created_at <= '" + state.date_range.lte + "T23:59:59'"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {


                // console.log(response);

                // Make array empty
                state.vip_important = [];

                // parse and fill Customer Type
                
                if (response.data.rows[0][0] !== 0 && response.data.rows[0][1] !== 0 && response.data.rows[0][2] !== 0 && response.data.rows[0][3] !== 0 ) {
                    state.vip_important.push(response.data.rows[0][0]),
                
                    state.vip_important.push({
                        name: "Important",
                        value: response.data.rows[0][1],
                    })
                    state.vip_important.push({
                        name: "VIP",
                        value: response.data.rows[0][2],
                    })
                    
                    state.vip_important.push({
                        name: "Both",
                        value: response.data.rows[0][3],
                        sets: ["Important", "VIP"]
                    })
                }

            



                // state.vip_important[0] = response.data.rows[0][0];
                // state.vip_important[1] = response.data.rows[0][1];
                // state.vip_important[2] = response.data.rows[0][2];
                // state.vip_important[3] = response.data.rows[0][3];

                // Stop waiting
                dispatch('wait/end', 'importance', { root: true });

            })
            .catch((error) => {
                // // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'importance', { root: true });

            });

    },

    // get Customer Genders
    async fetchCustomerGenders({ dispatch, state }){
        // Set Created_at for Data
        dispatch('updateCalenderIntervalSql', 'inbox_created_at');

        // Start waiting
        dispatch('wait/start', 'reportsGender', { root: true });

        let queries = {
            "query":"SELECT COUNT(DISTINCT customer_id) AS value, " + state.calender_interval_sql + ", customer_gender_name AS customer_gender FROM inboxes WHERE " + state.account_list_sql + " inbox_created_at >= '" + state.date_range.gte + "T00:00:00' AND inbox_created_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY " + state.calender_interval_sql + ", customer_gender_name"

        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.customer_genders = [];

                let id = interval;


                // Fill Customer Types with Data
                response.data.rows.forEach(buckets => {

                    let customer_gender = {};
                    if(state.customer_genders[id] && buckets[1] === state.customer_genders[id].date){

                        state.customer_genders[id][buckets[2]] = buckets[0];

                    }else{

                        // parse and fill date
                        customer_gender['date'] = buckets[1];

                        // parse and fill Customer Type
                        customer_gender[buckets[2]] = buckets[0];

                        // Push Data to State
                        state.customer_genders.push(
                            customer_gender
                        );

                        let keys = Object.keys(state.customer_genders);
                        id = keys[keys.length - 1];
                    }

                })

                // Stop waiting
                dispatch('wait/end', 'reportsGender', { root: true });

            })
            .catch((error) => {

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'reportsGender', { root: true });

            });

    },

    // get Transfer & Trash Chart Data
    async fetchTransfersAndTrash({ dispatch, commit, state }){
        // Set Created_at for Data
        dispatch('updateCalenderIntervalSql', 'inbox_created_at');

        // Start waiting
        dispatch('wait/start', 'reportsTransferAndTrash', { root: true });

        let queries = {
            "query": "SELECT " + state.calender_interval_sql + ", COUNT(DISTINCT customer_id), COUNT(trash_id), COUNT(transfer_id) FROM inboxes WHERE " + state.account_list_sql + " inbox_created_at >= '" + state.date_range.gte.toString() + "' AND inbox_created_at <= '" + state.date_range.lte.toString() + "' AND (transfer_id IS NOT NULL OR trash_id IS NOT NULL) GROUP BY " + state.calender_interval_sql

        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.transfers_and_trash = [];

                // Fill Transfer & Trash with Data
                response.data.rows.forEach(buckets => {

                    let transfer_and_trash = {};

                        // parse and fill date
                        transfer_and_trash['date'] = buckets[0];

                        // parse and fill Trash Count
                        transfer_and_trash[Vue.i18n.translate('page.trash')] = buckets[2];

                        // parse and fill Confirmations Count
                        transfer_and_trash[Vue.i18n.translate('ticket.transfers')] = buckets[3];

                        // Push Data to State
                        state.transfers_and_trash.push(
                            transfer_and_trash
                        );

                })


                // Stop waiting
                dispatch('wait/end', 'reportsTransferAndTrash', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'reportsTransferAndTrash', { root: true });

            });

    },



    async fetchLanguageIncoming({ dispatch, commit, state, rootGetters }){

        dispatch('wait/start', 'reportsLanguageIncomeingOutgoing', { root: true });

        dispatch('updateCalenderIntervalSql', 'question_created_at');
        let query = {
            "query": `SELECT  COUNT(*) AS count, ${state.calender_interval_sql}, questions_lang FROM questions WHERE ${state.account_list_sql} question_created_at >= '${state.date_range.gte}T00:00:00' AND question_created_at <= '${state.date_range.lte}T23:59:59' GROUP BY ${state.calender_interval_sql}, questions_lang`
        }
        await axios.post(state.sql_api, query, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
        .then( (response) => {
            state.language_incoming = [];
            let id = interval;
            response.data.rows.forEach( buckets => {
                let lang_data = {};
                if(state.language_incoming[id] && buckets[1] === state.language_incoming[id].date){
                    state.language_incoming[id][buckets[2]] = buckets[0];
                }else{
                    // parse and fill date
                    lang_data['date'] = buckets[1];
                    // parse and fill                        
                    lang_data[buckets[2]] = buckets[0];
                    
                    // Push Data to State
                    state.language_incoming.push(
                        lang_data
                    );
                    let keys = Object.keys(state.language_incoming);
                    id = keys[keys.length - 1];
                }

            })
        })
        .catch ((error) => {
            console.log(error)
        })
        dispatch('wait/end', 'reportsLanguageIncomeingOutgoing', { root: true });

    },

    async fetchLanguageOutgoing({ dispatch, commit, state, rootGetters }){

        dispatch('wait/start', 'reportsLanguageIncomeingOutgoing', { root: true });

        dispatch('updateCalenderIntervalSql', 'answers_created_at');
        let query = {
            "query": `SELECT COUNT(*) AS count, ${state.calender_interval_sql}, answers_lang FROM drafts WHERE ${state.account_list_sql} answers_created_at >= '${state.date_range.gte}T00:00:00' AND answers_created_at <= '${state.date_range.lte}T23:59:59' GROUP BY ${state.calender_interval_sql}, answers_lang`
        }
        await axios.post(state.sql_api, query, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
        .then( (response) => {
            state.language_outgoing = [];
            let id = interval;
            response.data.rows.forEach( buckets => {
                let lang_data = {};
                if(state.language_outgoing[id] && buckets[1] === state.language_outgoing[id].date){
                    state.language_outgoing[id][buckets[2]] = buckets[0];
                }else{
                    // parse and fill date
                    lang_data['date'] = buckets[1];
                    // parse and fill                        
                    lang_data[buckets[2]] = buckets[0];
                    
                    // Push Data to State
                    state.language_outgoing.push(
                        lang_data
                    );
                    let keys = Object.keys(state.language_outgoing);
                    id = keys[keys.length - 1];
                }

            })
        })
        .catch ((error) => {
            console.log(error)
        })
        dispatch('wait/end', 'reportsLanguageIncomeingOutgoing', { root: true });

    },

    // get Transfer & Trash Chart Data
    async fetchConfirmations({ dispatch, commit, state }){
        // Set Created_at for Data
        dispatch('updateCalenderIntervalSql', 'inbox_created_at');

        // Start waiting
        dispatch('wait/start', 'reportsConfirmations', { root: true });

        let queries = {
            "query": "SELECT confirmation_status, COUNT(confirmation_status) FROM confirmations WHERE " + state.account_list_sql + " confirmation_created_at >= '" + state.date_range.gte.toString() + "' AND confirmation_updated_at <= '" + state.date_range.lte.toString() + "' GROUP BY confirmation_status"

        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.confirmations = [];

                // Fill Transfer & Trash with Data
                response.data.rows.forEach(buckets => {

                    // parse and fill Confirmations Count
                    if(buckets[0] === null) {
                        state.confirmations.push({
                            category: 'awaiting',
                            value: buckets[1]
                        })
                    }else if(buckets[0] === 1){
                        state.confirmations.push({
                            category: 'accepted',
                            value: buckets[1]
                        })

                    }else if(buckets[0] === 2){
                        state.confirmations.push({
                            category: 'rejected',
                            value: buckets[1]
                        })
                    }
                })

                // Stop waiting
                dispatch('wait/end', 'reportsConfirmations', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'reportsConfirmations', { root: true });

            });

    },

    // get Customer Schedule Info
    async fetchSchedule({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'scheduleReport', { root: true });

        let queries = {
            "query":"SELECT COUNT(schedule_status) FROM schedules WHERE " + state.account_list_sql + " schedule_created_at >= '" + state.date_range.gte + "T00:00:00' AND schedule_schedule_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY schedule_status ORDER BY schedule_status"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.schedule = [];


                if(response.data.rows[0]) {
                    state.schedule.push(
                        {
                            category: 'Sent',
                            value: response.data.rows[0][0],
                        }
                    )
                } else if (response.data.rows[1]) {
                    state.schedule.push(
                        {
                            category: 'Waiting',
                            value: response.data.rows[1][0],
                        },
                    )
                } else if (response.data.rows[2]) {
                    state.schedule.push({
                        category: 'Canceled',
                        value: response.data.rows[2][0],
                    })

                }
                // parse and fill Customer Type

                //Satatus[0] = Schedule Sent
                // state.schedule[0] = response.data.rows[0][0];
                // //Satatus[1] = Schedule Waiting
                // state.schedule[1] = response.data.rows[1][0];
                // //Satatus[12] = Schedule Canceled
                // state.schedule[2] = response.data.rows[2][0];

                // Stop waiting
                dispatch('wait/end', 'scheduleReport', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'scheduleReport', { root: true });

            });

    },

    // get Transfer Result
    async fetchTransferResult({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'transferResult', { root: true });

        let queries = {
            "query":"SELECT COUNT(DISTINCT customer_id), COUNT(CASE WHEN ticket_status = 3 THEN 1 END) AS ticket_value FROM inboxes WHERE " + state.account_list_sql + " ticket_created_at >= '" + state.date_range.gte + "T00:00:00' AND ticket_updated_at <= '" + state.date_range.lte + "T23:59:59' AND (transfer_id IS NOT NULL)"
        }


        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {
                // Make array empty
                state.transfer_result = [];


                // console.log('TransferResult----');
                // console.log(response.data);

                if(response.data.rows[0][0] !== 0) {
                    state.transfer_result.push(
                        {
                            category: 'Replied',
                            value: response.data.rows[0][0],
                        }
                    )
                }
                if (response.data.rows[0][1] !== 0) {
                    state.transfer_result.push(
                        {
                            category: 'Awaiting',
                            value: response.data.rows[0][1],
                        },
                    )
                }

                // console.log(state.transfer_result);

                    // // parse and fill Customer Type
                    // //Satatus[0] = All Tickets
                    // state.transfer_result[0] = response.data.rows[0][0];
                    // //Satatus[1] = Ticket Status = 3 (Transfered)
                    // state.transfer_result[1] = response.data.rows[0][1];

                


                // Stop waiting
                dispatch('wait/end', 'transferResult', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'transferResult', { root: true });

            });

    },

    // get Tag Reports
    async fetchTagReports({ dispatch, state }, selected_tag_categories){

        // Start waiting
        dispatch('wait/start', 'reportsTags', { root: true });

        let seleted_category_text = selected_tag_categories.map( category => `'${category.name}'`).join(',')
        let selected_category_query = seleted_category_text ? ` AND categories_name IN (${seleted_category_text})` : ''

        let query = {
            "query":"SELECT tags_name_arr, COUNT(tags_name_arr) FROM tickets WHERE " + state.account_list_sql + " ticket_created_at >= '" + state.date_range.gte + "T00:00:00' AND ticket_updated_at <= '" + state.date_range.lte + "T23:59:59'" + selected_category_query + " GROUP BY tags_name_arr"
        }

        // Get Data
        await axios.post(state.sql_api, query, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.tags = [];

                // Fill Customer Types with Data
                response.data.rows.forEach(buckets => {

                    let tag = {};
                        // parse and fill tag
                        if ( buckets[0] !== null) {
                            tag['tags'] = buckets[0];
                            tag['value'] = buckets[1];

                            // Push Data to State
                            state.tags.push(
                                tag
                            );
                        }



                })


                // Stop waiting
                dispatch('wait/end', 'reportsTags', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'reportsTags', { root: true });

            });

    },

    // get Category Report
    async fetchCategoryReport({ dispatch, state }, {selected_category, selected_tags}){

        // Start waiting
        dispatch('wait/start', 'reportsCategory', { root: true });

        let seleted_tags_text = selected_tags.map( tag => `'${tag.name}'`).join(',')
        let selected_tags_query = seleted_tags_text ? ` AND tags_name_arr IN (${seleted_tags_text})` : ''

        let query = {
            "query":"SELECT categories_id_arr, COUNT(categories_id_arr) FROM tickets WHERE " + state.account_list_sql + " ticket_created_at >= '" + state.date_range.gte + "T00:00:00' AND ticket_updated_at <= '" + state.date_range.lte + "T23:59:59'" + selected_tags_query + " GROUP BY categories_id_arr"
        }

        // Get Data
        await axios.post(state.sql_api, query, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.report_categories = [];


                // Fill Customer Types with Data
                response.data.rows.forEach(buckets => {

                        // parse and fill tag
                         state.report_categories[buckets[0]] = buckets[1];

                })

                // console.log(state.report_categories)

                dispatch('fetchPrimaryCategories');
                // console.log(selected_category)
                if(selected_category !== null) {
                    dispatch('categoryParsing', selected_category);
                }

                // Stop waiting
                dispatch('wait/end', 'reportsCategory', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'reportsCategory', { root: true });

            });

    },

    async fetchPrimaryCategories({state,commit,rootGetters}) {
        let categories = rootGetters['Ticket/getCategories'];
        if(categories) {
            state.primary_categories_data = [];
            categories.forEach( category => {
                let primaryCategory = {};
                if(state.report_categories[category['id']]) {
                    primaryCategory['primary_category'] = category['name'];
                    primaryCategory['value'] = state.report_categories[category['id']];

                    state.primary_categories_data.push(
                        primaryCategory
                    );
                }
            })
            // console.log(state.primary_categories_data);
        }

    },

    // Parse Category Data(Secondary/Sub Category)
    async categoryParsing ({state,commit,rootGetters}, selected_category) {
        let categories = rootGetters['Ticket/getCategories'];
        state.target_key_0 = null;
        state.target_key_1 = null;
        state.target_key_2 = null;
        state.target_key_3 = null;
        state.sub_categories_active = false;
        state.secondary_categories_active = false;






        //  Find in Categories
        if(categories){
            state.target_key_0 = categories.findIndex(messages => messages._lft <= selected_category._lft && selected_category._rgt <= messages._rgt);

            if(state.target_key_0 != -1 && typeof categories[state.target_key_0].children !== 'undefined'){
                state.target_key_1 = categories[state.target_key_0].children.findIndex(messages => messages._lft <= selected_category._lft && selected_category._rgt <= messages._rgt);

                if(state.target_key_1 != -1 && typeof categories[state.target_key_0].children[state.target_key_1].children !== 'undefined'){
                    state.target_key_2 = categories[state.target_key_0].children[state.target_key_1].children.findIndex(messages => messages._lft <= selected_category._lft && selected_category._rgt <= messages._rgt);

                    if(state.target_key_2 != -1 && typeof categories[state.target_key_0].children[state.target_key_1].children[state.target_key_2].children !== 'undefined'){
                        state.target_key_3 = categories[state.target_key_0].children[state.target_key_1].children[state.target_key_2].children.findIndex(messages => messages._lft <= selected_category._lft && selected_category._rgt <= messages._rgt);
                    }
                }
            }
        }

        
        // console.log(state.target_key_1);
        // console.log(state.target_key_2);

            // Fill Primary Categories Data with ES
            // state.primary_categories_data = [];
            // categories.forEach(buckets => {
            //     let primaryCategory = {};
            //     if(state.report_categories[buckets['id']]) {

            //         // parse and fill category
            //         primaryCategory['primary_category'] = buckets['name'];
            //         primaryCategory['value'] = state.report_categories[buckets['id']];

            //         // Push Data to array
            //         state.primary_categories_data.push(
            //             primaryCategory
            //         );
            //     }
            // })

            
            // state.secondary_categories_data = [];


            // Fill Secondary Categories Data with ES
            state.secondary_categories_data = [];
            if(categories[state.target_key_0]['children']) {
                let target_key_category = [];
                target_key_category = categories[state.target_key_0].children;
                
                state.secondary_categories_active = true;
                target_key_category.forEach(buckets => {
                    let secondCategory = {};
                    // parse and fill category
                    if(state.report_categories[buckets['id']]){
                        secondCategory['second_category'] = buckets['name'];
                        secondCategory['value'] = state.report_categories[buckets['id']];
                    // Push Data to array
                    state.secondary_categories_data.push(
                        secondCategory
                    );
                    }
                })
            }
           

        // Fill Sub-Categories Data with ES
            state.sub_categories_data = [];
            if( state.target_key_2 != null ) {
                let target_key_category2 = [];
                target_key_category2 = categories[state.target_key_0].children[state.target_key_1].children;
                
                state.sub_categories_active = true;
                // Fill Categories Data with ES
                target_key_category2.forEach(buckets => {
                    let subCategory = {};
                    if(state.report_categories[buckets['id']]) {
                        // parse and fill category
                        subCategory['sub_category'] = buckets['name'];
                        subCategory['value'] = state.report_categories[buckets['id']];
                        // Push Data to array
                        state.sub_categories_data.push(
                            subCategory
                        );
                    }
                })
            }
   
    },


    // get Tag Processes Data
    async fetchTagProcesses({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'tagProcesses', { root: true });

        let queries = {
            "query":"SELECT tags_name_arr, ticket_status, COUNT(ticket_status) FROM tickets WHERE " + state.account_list_sql + " ticket_created_at >= '" + state.date_range.gte + "T00:00:00' AND ticket_updated_at <= '" + state.date_range.lte + "T23:59:59'  GROUP BY tags_name_arr, ticket_status ORDER BY tags_name_arr"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.tag_processes = [];

                let id = interval;
                // Fill Tags and status with Data

                // console.log(response.data.rows);
                response.data.rows.forEach(buckets => {

                if(buckets[0] !== null){
                    let tag_process = {};
                    let ticket_status =  state.ticket_statuses.find(messages => messages.id == buckets[1])

                    if( state.tag_processes[id] && buckets[0] === state.tag_processes[id].tags){

                        // parse and fill ticket status
                        state.tag_processes[id][ticket_status['name']] = buckets[2];

                    }else{

                        tag_process['tags'] = buckets[0];

                        // fill name
                        tag_process[ticket_status['name']] = buckets[2];

                        // Push Data to State
                        state.tag_processes.push(
                            tag_process
                        );

                        // Calculate Array's Key
                        let keys = Object.keys(state.tag_processes);
                        id = keys[keys.length - 1];

                    }

                    // console.log(state.tag_processes);
                }
                })

                // Stop waiting
                dispatch('wait/end', 'tagProcesses', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'tagProcesses', { root: true });

            });

    },

    // get Category Processes Data(Primary/Secondary/Sub Category)
    async fetchCategoryProcesses({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'categoryProcesses', { root: true });

        let queries = {
            "query":"SELECT categories_name_arr, ticket_status, COUNT(ticket_status) FROM tickets WHERE " + state.account_list_sql + " ticket_created_at >= '" + state.date_range.gte + "T00:00:00' AND ticket_updated_at <= '" + state.date_range.lte + "T23:59:59'  GROUP BY categories_name_arr, ticket_status ORDER BY categories_name_arr"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.category_processes = [];

                let id = interval;
                // Fill category and status with Data
                response.data.rows.forEach(buckets => {

                    if(buckets[0] !== null){
                        let category_process = {};
                        let ticket_status =  state.ticket_statuses.find(messages => messages.id == buckets[1])

                        if( state.category_processes[id] && buckets[0] === state.category_processes[id].category){
                            // parse and fill category status
                            state.category_processes[id][ticket_status['name']] = buckets[2];
                        }else{

                            category_process['category'] = buckets[0];
                            // fill name
                            category_process[ticket_status['name']] = buckets[2];

                            // Push Data to State
                            state.category_processes.push(
                                category_process
                            );

                            // Calculate Array's Key
                            let keys = Object.keys(state.category_processes);
                            id = keys[keys.length - 1];

                        }
                    }
                })

                // Stop waiting
                dispatch('wait/end', 'categoryProcesses', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'categoryProcesses', { root: true });

            });

    },

    // get Category Processes Data(Primary/Secondary/Sub Category)
    async fetchRiskHeatmap({ dispatch, state }){

        // Set Created_at for Data
        dispatch('updateCalenderIntervalSql', 'ticket_updated_at');

        // Start waiting
        dispatch('wait/start', 'riskHeatmap', { root: true });

        let queries = {
            "query":"SELECT ticket_status, COUNT(ticket_status), " + state.calender_interval_sql + " FROM tickets WHERE " + state.account_list_sql + " ticket_updated_at >= '" + state.date_range.gte + "T00:00:00' AND ticket_updated_at <= '" + state.date_range.lte + "T23:59:59'  GROUP BY " + state.calender_interval_sql + ", ticket_status"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.risk_heatmaps = [];

                let colors = {
                    "critical": "#ca0101",
                    "bad": "#e17a2d",
                    "medium": "#e1d92d",
                    "good": "#5dbe24",
                    "verygood": "#0b7d03"
                };

                let id = integer;
                // Fill category and status with Data
                response.data.rows.forEach(buckets => {

                    if(buckets[0] !== null){
                        let risk_heatmap = {};
                        let ticket_status =  state.ticket_statuses.find(messages => messages.id == buckets[0]);
                        let heat_date = moment(new Date()).diff(moment(buckets[2]), 'hours');
                        console.log('ticket_status', ticket_status)


                        // If < 60 Min
                        if( heat_date <= 1 ){
                            // Check old data exist or no
                            let old_data = state.risk_heatmaps.findIndex(data => data.y === Vue.i18n.translate('ticket.' + ticket_status['name']) && data.x === "Best");

                            if( old_data >= 0 ) {
                                console.log('----old_data- Best ---');
                                console.log(old_data);
                                // calculate and fill value
                                state.risk_heatmaps[old_data]['value'] = state.risk_heatmaps[old_data]['value'] + buckets[1];


                            } else {
                                // fill info
                                risk_heatmap['y'] = Vue.i18n.translate('ticket.' + ticket_status['name']);
                                risk_heatmap['x'] = "Best";
                                risk_heatmap['value'] = buckets[1];

                                switch(buckets[0]) {
                                    case 1:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.medium,
                                        };
                                        // risk_heatmap['color'] = colors.medium;
                                        break;
                                    case 2:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.good,
                                        };
                                        // risk_heatmap['color'] = colors.good;
                                        break;
                                    case 3:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.verygood,
                                        };
                                        // risk_heatmap['color'] = colors.verygood;
                                        break;
                                    case 4:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.verygood,
                                        };
                                        // risk_heatmap['color'] = colors.verygood;
                                        break;
                                    case 5:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.verygood,
                                        };
                                        // risk_heatmap['color'] = colors.verygood;
                                        break;
                                }


                                // Push Data to State
                                state.risk_heatmaps.push(
                                    risk_heatmap
                                );

                                // Calculate Array's Key
                                let keys = Object.keys(state.risk_heatmaps);
                                id = keys[keys.length - 1];

                            }
                        }

                        // 60 Min < If < 180 Min
                        else if( 1 < heat_date &&  heat_date <= 3 ) {
                            // Check old data exist or no
                            let old_data = state.risk_heatmaps.findIndex(data => data.y === Vue.i18n.translate('ticket.' + ticket_status['name']) && data.x === "Good");

                            if( old_data >= 0 ) {
                                console.log('----old_data- Good ---');
                                console.log(old_data);
                                // calculate and fill value
                                state.risk_heatmaps[old_data]['value'] = state.risk_heatmaps[old_data]['value'] + buckets[1];

                            } else {
                                // fill info
                                risk_heatmap['y'] = Vue.i18n.translate('ticket.' + ticket_status['name']);
                                risk_heatmap['x'] = "Good";
                                risk_heatmap['value'] = buckets[1];

                                switch(buckets[0]) {
                                    case 1:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.bad,
                                        };
                                        risk_heatmap['color'] = colors.bad;
                                        break;
                                    case 2:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.medium,
                                        };
                                        // risk_heatmap['color'] = colors.medium;
                                        break;
                                    case 3:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.good,
                                        };
                                        // risk_heatmap['color'] = colors.good;
                                        break;
                                    case 4:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.verygood,
                                        };
                                        // risk_heatmap['color'] = colors.verygood;
                                        break;
                                    case 5:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.verygood,
                                        };
                                        // risk_heatmap['color'] = colors.verygood;
                                        break;
                                }

                                // Push Data to State
                                state.risk_heatmaps.push(
                                    risk_heatmap
                                );

                                // Calculate Array's Key
                                let keys = Object.keys(state.risk_heatmaps);
                                id = keys[keys.length - 1];

                            }
                        }

                        // 180 Min < If < 24 Hours
                        else if( 3 < heat_date && heat_date <= 24 ) {
                            // Check old data exist or no
                            let old_data = state.risk_heatmaps.findIndex(data => data.y === Vue.i18n.translate('ticket.' + ticket_status['name']) && data.x === "Medium");

                            if( old_data >= 0 ) {
                                console.log('----old_data- Medium ---');
                                console.log(old_data);
                                // calculate and fill value
                                state.risk_heatmaps[old_data]['value'] = state.risk_heatmaps[old_data]['value'] + buckets[1];

                            } else {
                                // fill info
                                risk_heatmap['y'] = Vue.i18n.translate('ticket.' + ticket_status['name']);
                                risk_heatmap['x'] = "Medium";
                                risk_heatmap['value'] = buckets[1];


                                switch(buckets[0]) {
                                    case 1:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.bad,
                                        };
                                        // risk_heatmap['color'] = colors.bad;
                                        break;
                                    case 2:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.bad,
                                        };
                                        // risk_heatmap['color'] = colors.bad;
                                        break;
                                    case 3:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.medium,
                                        };
                                        // risk_heatmap['color'] = colors.medium;
                                        break;
                                    case 4:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.good,
                                        };
                                        // risk_heatmap['color'] = colors.good;
                                        break;
                                    case 5:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.good,
                                        };
                                        // risk_heatmap['color'] = colors.good;
                                        break;
                                }

                                // Push Data to State
                                state.risk_heatmaps.push(
                                    risk_heatmap
                                );

                                // Calculate Array's Key
                                let keys = Object.keys(state.risk_heatmaps);
                                id = keys[keys.length - 1];

                            }
                        }

                        // 24 Hours < If < 5 Days
                        else if( 24 < heat_date && heat_date <= 120 ){
                            // Check old data exist or no
                            let old_data = state.risk_heatmaps.findIndex(data => data.y === Vue.i18n.translate('ticket.' + ticket_status['name']) && data.x === "Bad");

                            if( old_data >= 0 ) {
                                console.log('----old_data- Bad ---');
                                console.log(old_data);
                                // calculate and fill value
                                state.risk_heatmaps[old_data]['value'] = state.risk_heatmaps[old_data]['value'] + buckets[1];

                            } else {
                                // fill info
                                risk_heatmap['y'] = Vue.i18n.translate('ticket.' + ticket_status['name']);
                                risk_heatmap['x'] = "Bad";
                                risk_heatmap['value'] = buckets[1];


                                switch(buckets[0]) {
                                    case 1:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.critical,
                                        };
                                        // risk_heatmap['color'] = colors.critical;
                                        break;
                                    case 2:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.critical,
                                        };
                                        // risk_heatmap['color'] = colors.critical;
                                        break;
                                    case 3:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.bad,
                                        };
                                        // risk_heatmap['color'] = colors.bad;
                                        break;
                                    case 4:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.medium,
                                        };
                                        // risk_heatmap['color'] = colors.medium;
                                        break;
                                    case 5:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.good,
                                        };
                                        // risk_heatmap['color'] = colors.good;
                                        break;
                                }

                                // Push Data to State
                                state.risk_heatmaps.push(
                                    risk_heatmap
                                );

                                // Calculate Array's Key
                                let keys = Object.keys(state.risk_heatmaps);
                                id = keys[keys.length - 1];

                            }
                        }

                        // 25 Days < If
                        else if( 120 <= heat_date ){
                            // Check old data exist or no
                            let old_data = state.risk_heatmaps.findIndex(data => data.y === Vue.i18n.translate('ticket.' + ticket_status['name']) && data.x === "Critical");

                            if( old_data >= 0 ) {
                                console.log('----old_data- Critical ---');
                                console.log(old_data);
                                // calculate and fill value
                                state.risk_heatmaps[old_data]['value'] = state.risk_heatmaps[old_data]['value'] + buckets[1];

                            } else {
                                // fill info
                                risk_heatmap['y'] = Vue.i18n.translate('ticket.' + ticket_status['name']);
                                risk_heatmap['x'] = "Critical";
                                risk_heatmap['value'] = buckets[1];


                                switch(buckets[0]) {
                                    case 1:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.critical,
                                        };
                                        // risk_heatmap['color'] = colors.critical;
                                        break;
                                    case 2:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.critical,
                                        };
                                        // risk_heatmap['color'] = colors.critical;
                                        break;
                                    case 3:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.critical,
                                        };
                                        // risk_heatmap['color'] = colors.critical;
                                        break;
                                    case 4:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.bad,
                                        };
                                        // risk_heatmap['color'] = colors.bad;
                                        break;
                                    case 5:
                                        risk_heatmap['columnSettings'] = {
                                            fill: colors.medium,
                                        };
                                        // risk_heatmap['color'] = colors.medium;
                                        break;
                                }

                                // Push Data to State
                                state.risk_heatmaps.push(
                                    risk_heatmap
                                );

                                // Calculate Array's Key
                                let keys = Object.keys(state.risk_heatmaps);
                                id = keys[keys.length - 1];

                            }
                        }



                    }
                })

                // Stop waiting
                dispatch('wait/end', 'riskHeatmap', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'riskHeatmap', { root: true });

            });

    },

    // get User Touch Performance
    async fetchUserTouchPerformance({ dispatch, state}, selected_team){

        // Start waiting
        dispatch('wait/start', 'touchPerformance', { root: true });

        let selected_team_sql = "";

        if(selected_team){
            selected_team_sql = "team_id = '" + selected_team + "' AND ";
        } else {
            selected_team_sql = "";
        }


        let queries = {
            "query":"SELECT users_name, COUNT(id) FROM drafts WHERE " + selected_team_sql + state.account_list_sql + " answers_created_at >= '" + state.date_range.gte + "T00:00:00' AND answers_created_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY users_id,users_name ORDER BY COUNT(id) desc"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.user_touch_performance = [];

                let counter = 0;

                // console.log(response.data.rows);


                response.data.rows.forEach(buckets => {

                    let touch_performance = {};

                    // parse and fill user
                    touch_performance['user'] = buckets[0];

                    // parse and fill value
                    touch_performance['value'] = buckets[1];

                    if(counter === 0){
                        // parse and fill href
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/1.png"}
                    } else if (counter === 1){
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/2.png"}
                    } else if(counter === 2){
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/3.png"}
                    } else if(counter === 3){
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/4.png"}
                    } else if(counter === 4){
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/5.png"}
                    } else {
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/6.png"}
                    }

                    counter++;
                    // Push Data to State
                    state.user_touch_performance.push(
                        touch_performance
                    );


                })

                // Stop waiting
                dispatch('wait/end', 'touchPerformance', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'touchPerformance', { root: true });

            });

    },

    // get Account Activities
    async fetchAccountActivities({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'accountActivities', { root: true });

        let queries = {
            "query":"SELECT account_name,integration_id, COUNT(draft_id) AS draft_value, COUNT(questions_id) AS questions_value FROM inboxes WHERE " + state.account_list_sql + " inbox_created_at >= '" + state.date_range.gte + "T00:00:00' AND inbox_created_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY account_name, integration_id"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.account_activities = [];


                response.data.rows.forEach(buckets => {

                    let account_activity = {};


                    account_activity['account'] = buckets[0] + ' (' + buckets[1].toUpperCase() + ')';

                    // parse and fill value
                    account_activity['outgoing'] = buckets[2];
                    account_activity['incoming'] = buckets[3];

                    // Push Data to State
                    state.account_activities.push(
                        account_activity
                    );


                    // // parse and fill user
                    // account_activity['account'] = buckets[0] + ' - ' + buckets[1][0].toUpperCase() + buckets[1].slice(1).toLowerCase()

                    // account_activity['total'] = buckets[2] + buckets[3];

                    // // parse and fill value
                    // account_activity['subData'] = [];

                    // account_activity.subData.push({
                    //     category: 'outgoing',
                    //     value: buckets[2],
                    // },
                    // {
                    //     category: 'incoming',
                    //     value: buckets[3],

                    // }
                    // )


                    // // Push Data to State
                    // state.account_activities.push(
                    //     account_activity
                    // );


                })

                // Stop waiting
                dispatch('wait/end', 'accountActivities', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'accountActivities', { root: true });

            });

    },

    // get Account Activities
    async fetchUserTimeTable({ dispatch, state }, selected_team){

        // Start waiting
        dispatch('wait/start', 'userTimeTable', { root: true });

        let selected_team_sql = "";

        if(selected_team){
        selected_team_sql = "team_id = '" + selected_team + "' AND ";
        } else {
        selected_team_sql = "";
        }

        let queries = {
            "query":"SELECT users_name, users_surname, AVG(first_response_time_sec)/60 AS first_response_avg FROM drafts WHERE " + selected_team_sql + state.account_list_sql + " answers_created_at >= '" + state.date_range.gte + "T00:00:00' AND answers_updated_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY users_name, users_surname"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.user_time_tables = [];

                response.data.rows.forEach(buckets => {

                    let user_time_table = {};

                    if( buckets[2] !== null) {
                    // parse and fill user
                    user_time_table['category'] = buckets[0] + ' ' + buckets[1];

                    // parse and fill value
                    // if(buckets[2] === null){
                    //     user_time_table['value'] = null;
                    // } else {
                        // user_time_table['value'] = +buckets[2].toFixed(2);
                    // }

                    user_time_table['value'] = +buckets[2].toFixed(2);


                    // Push Data to State
                    state.user_time_tables.push(
                        user_time_table
                    );

                    }





                })

                // Stop waiting
                dispatch('wait/end', 'userTimeTable', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'userTimeTable', { root: true });

            });

    },

    // get Account Activities
    async fetchTeamTimeTable({ dispatch, state }, selected_team){

        // Start waiting
        dispatch('wait/start', 'teamTimeTable', { root: true });

        // Set Created_at for Data
        dispatch('updateCalenderIntervalSql', 'answers_created_at');

        let selected_team_sql = "";

        if(selected_team){
        selected_team_sql = "team_id = '" + selected_team + "' AND ";
        } else {
        selected_team_sql = "";
        }

        let queries = {
            "query":"SELECT AVG(first_response_time_sec)/60 AS first_response_avg, " + state.calender_interval_sql + " FROM drafts WHERE " + selected_team_sql + state.account_list_sql + " answers_created_at >= '" + state.date_range.gte + "T00:00:00' AND answers_updated_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY " + state.calender_interval_sql

        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.team_time_tables = [];

                // console.log(response.data.rows)

                response.data.rows.forEach(buckets => {

                    let team_time_table = {};

                    // parse and fill value
                if(buckets[0] !== null){
                    // team_time_table['team_first_response'] = null;
                // } else {
                    team_time_table['team_first_response'] = +buckets[0].toFixed(2);

                    // parse and fill user
                    team_time_table['date'] = buckets[1];


                    // Push Data to State
                    state.team_time_tables.push(
                        team_time_table
                        );
                                    
                }






                })

                // Stop waiting
                dispatch('wait/end', 'teamTimeTable', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'teamTimeTable', { root: true });

            });

    },

    // get First Response Time Average
    async fetchFirstResponseAverage({ dispatch, state }, selected_team){

        // Start waiting
        dispatch('wait/start', 'responseTime', { root: true });

        let selected_team_sql = "";

        if(selected_team){
            selected_team_sql = "team_id = '" + selected_team + "' AND ";
        } else {
            selected_team_sql = "";
        }

        let queries = {
            "query":"SELECT AVG(first_response_time_sec)/60 AS first_response_avg FROM drafts WHERE " + selected_team_sql + state.account_list_sql + " answers_created_at >= '" + state.date_range.gte + "T00:00:00' AND answers_updated_at <= '" + state.date_range.lte + "T23:59:59'"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.first_response_time_average = [];

                // parse and fill Customer Type
                state.first_response_time_average = response.data.rows[0];

                // Stop waiting
                dispatch('wait/end', 'responseTime', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'responseTime', { root: true });

            });

    },

    // get First Response Time Average
    async fetchInformations({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'fetchInformations', { root: true });

        let queries = {
            "query":"SELECT ticket_status, COUNT(ticket_status) FROM tickets WHERE " + state.account_list_sql + " ticket_updated_at >= '" + state.date_range.gte + "T00:00:00' AND ticket_updated_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY ticket_status"

        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.informations = [];
                let information = [];
                information[0] = 0;
                information[1] = 0;
                information[2] = 0;
                information[3] = 0;
                information[4] = 0;
                information[5] = 0;


                // Fill Customer Types with Data
                response.data.rows.forEach(buckets => {

                    // parse and fill Customer Type
                    if(buckets[0]){
                        if(buckets[0] === 1){
                            // Open
                            information[0] = buckets[1];
                        }
                        if(buckets[0] === 2){
                            // Replied
                            information[1] = buckets[1];
                        }
                        if(buckets[0] === 3){
                            // Transfered
                            information[2] = buckets[1];
                        }
                        if(buckets[0] === 4){
                            // Awaiting
                            information[3] = buckets[1];
                        }
                        if(buckets[0] === 5){
                            // Closed
                            information[4] = buckets[1];
                        }
                        if(buckets[0] === 6){
                            // Unknown
                            information[5] = buckets[1];
                        }

                    }

                })
                // Transferred Tickets
                state.informations[0] = information[2];
                // Unresolved Tickets
                state.informations[1] = information[0] + information[1] + information[2] + information[3] + information[5];
                // Solved Tickets
                state.informations[2] = information[4];

                //Open Tickets
                state.informations[3] = information[0];


                // Stop waiting
                dispatch('wait/end', 'fetchInformations', { root: true });


            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'fetchInformations', { root: true });

            });

    },

    // get User Touch Performance
    async fetchTeamTouchPerformance({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'fetchTeamTouch', { root: true });

        let queries = {
            "query":"SELECT team_display_name, COUNT(id) FROM drafts WHERE " + state.account_list_sql + " answers_created_at >= '" + state.date_range.gte + "T00:00:00' AND answers_created_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY team_id,team_display_name ORDER BY COUNT(id) desc"
        }

        let query = queries.query;

        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.team_touch_performance = [];

                let counter = 0;

                response.data.rows.forEach(buckets => {

                    let touch_performance = {};

                    // parse and fill user
                    touch_performance['team'] = buckets[0];

                    // parse and fill value
                    touch_performance['value'] = buckets[1];

                    if(counter === 0){
                        // parse and fill href
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/1.png"}
                    } else if (counter === 1){
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/2.png"}
                    } else if(counter === 2){
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/3.png"}
                    } else if(counter === 3){
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/4.png"}
                    } else if(counter === 4){
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/5.png"}
                    } else {
                        touch_performance['pictureSettings'] = {
                            src: "/images/awards/6.png"}
                    }

                    counter++;
                    // Push Data to State
                    state.team_touch_performance.push(
                        touch_performance
                    );


                })

                // Stop waiting
                dispatch('wait/end', 'fetchTeamTouch', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'fetchTeamTouch', { root: true });

            });

    },

    // get Map Reports
    async fetchMaps({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'fetchMaps', { root: true });

        let queries = {
            "query":"SELECT location_lat, location_lng FROM tickets WHERE " + state.account_list_sql + " ticket_created_at >= '" + state.date_range.gte + "T00:00:00' AND ticket_updated_at <= '" + state.date_range.lte + "T23:59:59'"
        }



        let query = queries.query;
        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })
            .then((response) => {

                // Make array empty
                state.maps = [];


                // Fill Customer Types with Data
                response.data.rows.forEach(buckets => {
                    if(buckets[0] !== null || buckets[1] !== null){
                        let map = {};
                        // parse and fill tag

                        // map['geometry'] = {
                        //     type: "Point",
                        //     coordinates: [ buckets[1], buckets[0]]
                        // }
                        map['lng'] = buckets[0];
                        map['lat'] = buckets[1];

                        // Push Data to State
                        state.maps.push(
                            map
                        );
                    }

                })


                // console.log('state.maps--------');
                // console.log(state.maps);

                // Stop waiting
                dispatch('wait/end', 'fetchMaps', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'fetchMaps', { root: true });

            });

    },

    // get Location Reports
    async fetchLocationReports({ dispatch, state }){

        // Start waiting
        dispatch('wait/start', 'locationReports', { root: true });

        let queries = {
            "query":"SELECT location_country, location_state, location_county, location_district, categories_name_arr, COUNT(categories_name_arr) FROM tickets WHERE " + state.account_list_sql + " ticket_created_at >= '" + state.date_range.gte + "T00:00:00' AND ticket_updated_at <= '" + state.date_range.lte + "T23:59:59' GROUP BY location_country, location_state, location_county, location_district, categories_name_arr"
        }

        let query = queries.query;
        // Get Data
        await axios.post(state.sql_api, {query}, {
            auth: {
                username: state.username,
                password: state.password
            }
        })

                .then((response) => {

                // Make array empty
                state.location_reports = [];


                // Fill Customer Types with Data
                response.data.rows.forEach(buckets => {
                    if(buckets[0] !== null || buckets[1] !== null){
                        let location = {};
                        // parse and fill tag
                        location['country'] = buckets[0];
                        location['state'] = buckets[1];
                        location['county'] = buckets[2];
                        location['district'] = buckets[3];
                        location['category'] = buckets[4];
                        location['category_entries'] = buckets[5];

                        // Push Data to State
                        state.location_reports.push(
                            location
                        );
                    }

                })

                console.log('state.locationReports--------');
                console.log(response.data);
                console.log(state.location_reports);

                // Stop waiting
                dispatch('wait/end', 'locationReports', { root: true });

            })
            .catch((error) => {
                // Show error
                // Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

                // Log to console
                console.log(error);

                // Stop waiting
                dispatch('wait/end', 'locationReports', { root: true });

            });

    },



    // Set Date Range
    async updateDateRange ({commit}, date_range) {
        commit('setDateRange', date_range);

    },

    // Set Date Range
    async updateCalenderInterval ({commit,state}, calender_interval) {
        let date1 = new Date(calender_interval[0]);
        let date2 = new Date(calender_interval[1]);
        let timeDiff = Math.abs(date2.getTime() - date1.getTime());
        let diffDays = Math.ceil(timeDiff / (1000 * 3600));
        if(diffDays < 24){
            commit('setCalenderInterval', 'hour');
        }else if(diffDays < 744){
            commit('setCalenderInterval', 'day');
        }else if(diffDays < 8760){
            commit('setCalenderInterval', 'month');
        }else if(diffDays > 8760){
            commit('setCalenderInterval', 'year');
        }
    },
    // Set Calender Interval Sql
    async updateCalenderIntervalSql ({commit,state},created_at) {

        if(state.calender_interval === 'hour'){
            commit('setCalenderIntervalSql', 'HISTOGRAM(' + created_at + ', INTERVAL 1 HOUR)');
        }else if(state.calender_interval === 'day'){
            commit('setCalenderIntervalSql', 'HISTOGRAM(' + created_at + ', INTERVAL 1 DAY)');
        }else if(state.calender_interval === 'month'){
            commit('setCalenderIntervalSql', 'HISTOGRAM(' + created_at + ', INTERVAL 1 MONTH)');
        }else if(state.calender_interval === 'year'){
            commit('setCalenderIntervalSql', 'HISTOGRAM(' + created_at + ', INTERVAL 1 YEAR)');
        }
    },

        // Set Account List
    async updateAccountList ({commit}, account_list) {
        commit('setAccountList', account_list);
        let accounts = account_list.toString();
        if(account_list.length){
            commit('setAccountListSql', 'account_id IN (' + accounts + ') AND');
        }else{
            commit('setAccountListSql', '');
        }
    },

    updateSecondaryActive({commit}, secondary_categories_active) {
        commit('setSecondaryActive', secondary_categories_active);
    },

    updateSubActive({commit}, sub_categories_active) {
        commit('setSubActive', sub_categories_active);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}