<template>
    <div>
        <b-container>
            <b-row v-if="campaign.whatsapp_template">
                <b-col class="d-flex justify-content-center ">
                    <b-card no-body style="min-width: 20rem; max-width: 20rem; min-height: 33rem;"
                        header-text-variant="white" class="rounded-lg p-1 pt-2 bg-dark" border-variant="dark">
                        <template #header>
                            <div class="d-flex align-items-center">
                                <font-awesome-icon :icon="'arrow-left'" size="lg" class="mr-2" />
                                <b-avatar class="mr-1"></b-avatar>
                                <span class="d-flex flex-column">
                                    <span class="m-0">{{ campaign.whatsapp_template.account.phone_number }}</span>
                                    <small>online</small>
                                </span>
                                <font-awesome-icon :icon="'ellipsis-v'" size="lg" class="ml-auto" />
                            </div>
                        </template>

                        <b-container fluid class="bg-light h-100 " >
                            <b-row class="mt-2">
                                <b-col sm="9">
                                    <div class="border rounded-right rounded-lg bg-white">
                                        <template v-if="campaign.whatsapp_template.template_type == 'IMAGE'">
                                            <!-- Image -->
                                            <div class="position-relative d-inline-block text-center">
                                                <a href="#" @click="image_view(campaign.attachment)">
                                                    <b-img-lazy :src="campaign.attachment" class="media-preview p-1">
                                                    </b-img-lazy>
                                                </a>
                                            </div>

                                        </template>

                                        <template v-else-if="campaign.whatsapp_template.template_type == 'VIDEO'">
                                            <!-- Video -->
                                            <div class="position-relative d-inline-block text-center">
                                                <a href="#" @click="video_view(campaign.attachment)">
                                                    <video :src="campaign.attachment" class="media-preview p-1">
                                                    </video>
                                                    <font-awesome-icon :icon="'play-circle'"
                                                        class="preview-video-button position-absolute p-0" />
                                                </a>
                                            </div>
                                        </template>

                                        <template
                                            v-else-if="campaign.whatsapp_template.template_type == 'TEXT' && campaign.whatsapp_template.header">
                                            <h6 class="pt-1 px-2 m-0">
                                                {{ campaign.whatsapp_template.header }}</h6>
                                        </template>
                                        <p class="py-1 px-2 m-0">{{ campaign_content }}</p>

                                        <hr class="m-0">

                                        <message-template-buttons v-if="campaign.whatsapp_template.buttons"
                                            :buttons="campaign.extra_data && campaign.extra_data.buttons ? campaign.extra_data.buttons  : campaign.whatsapp_template.buttons"></message-template-buttons>
                                    </div>
                                </b-col>
                            </b-row>

                        </b-container>

                        <b-card-footer class="bg-light p-1 py-2 d-flex align-items-center">
                            <font-awesome-icon :icon="'plus'" size="lg" class="mr-2" />
                            <div class=" bg-white border rounded-pill p-1 py-2 w-100 d-flex ">
                                <font-awesome-icon :icon="'sticky-note'" class="ml-auto mr-2" />
                            </div>
                            <font-awesome-icon :icon="'camera'" size="lg" class="ml-2" />
                            <font-awesome-icon :icon="'microphone'" size="lg" class="mx-2" />
                        </b-card-footer>

                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>

</template>

<script>

import ImageView from '../../Modal/ImageView.vue';
import VideoView from '../../Modal/VideoView.vue';
import { create } from 'vue-modal-dialogs'

import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['campaign'],


    methods: {


        // Show image
        image_view: async function (attachment) {

            // Show image modal
            const image_view = create(ImageView, 'attachment');

            if (await image_view(attachment)) {
                // Nothing here yet

            }
        },

        // Play video
        video_view: async function (attachment) {

            // Show video modal
            const video_view = create(VideoView, 'attachment');

            if (await video_view(attachment)) {
                // Nothing here yet

            }
        },
    },

    computed: {



        campaign_content() {
            let content = this.campaign.whatsapp_template.content
            const values = this.campaign.extra_data.content_variables
            const variables = content.match(/{{\d}}/g) ?? []

            variables.forEach((variable, index) => {
                content = content.replace(variable, values[index])
            })
            return content
        },

    }

}
</script>

<style scoped>
.card-header {
    background-color: #005e54;
}
</style>