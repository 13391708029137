<template>
  <div class="message-container container-fluid">
      <div class="plus-col-main-messages ">
        <div class="container-fluid ">
          <div class="message-list" style="width: 100%">
            <v-wait for="post">
              <loading slot="waiting"/>
              <b-row style="width: 100%">
                <div class="message-header message-header-agent">

                  <avatar :username="post.user.ns" :size="32"></avatar>
                  <span class="message-header-name">{{ post.user.ns }}</span>
                  <div class="message-header-info">
                  <b-dropdown right :text="$t('simple.actions')" size="sm">
                    <b-dropdown-item @click="removePost(post.id)">{{ $t('simple.delete') }}</b-dropdown-item>
                  </b-dropdown>
                  <message-date v-bind:username="account.username" v-bind:id="post.post_id" v-bind:type="3" v-bind:integration="account.integration_id" v-bind:date="{ ago: $moment.utc(post.created_at).fromNow(), created: post.created_at }" v-bind:created="post.created_at"></message-date>
                  </div>

                </div>
              </b-row>
              <b-row style="width: 100%">
                <div class="message message-draft shadow-sm text-preview" style="width: 100%">
                  <span>{{ post.status }}</span>
                </div>
                <div v-for="image in post.images" class="mt-2 ml-3">
                  <img :src="image.storage.public" alt="">
                </div>
                <div v-if="post.video.length > 0 && post.video[0].storage.public">
                  <video width="320" height="240" controls>
                    <source src="" type="video/mp4">
                    <source :src="post.video[0].storage.public" type="video/ogg">
                  </video>
                </div>
              </b-row>
            </v-wait>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: "ShowPosts",
  computed: {
    ...mapGetters({
        post: 'Post/getPost',
        account: 'Accounts/getAccountData',
    }),
  },
  mounted() {
     this.Post(this.$route.params.postId)
    console.log(this.account);
  },
  watch: {
    '$route' (to, from){
      this.Post(to.params.postId)
    }
  },
  methods:{

    ...mapActions('Post', [
        'Post',
        'removePost'
    ]),

  }
}
</script>

<style scoped>

</style>