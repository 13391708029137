<template>
    <!-- Draft -->
    <div class="message-container">

        <div class="message-wrapper-agent">

            <div class="message-header message-header-agent">

                <div class="d-flex flex-nowrap align-items-center">
                    <avatar :username="message.user.ns" :size="28" class="agent-message-avatar-mobile"></avatar>

                    <span class="message-header-name">{{ message.user.ns }}</span>
                </div>

                <div class="message-header-info">

                    <span v-if="message.lang !== 'Unknown'" class="message-header-data font-weight-bold text-uppercase" v-b-tooltip.hover :title="$t('simple.content') + ' ' + $t('simple.language') + ': ' + languages[message.lang.toLowerCase()]" > {{ message.lang }}</span>

                    <!--Deleted Message-->
                    <span v-if="message.activity === 'deleted'" class="message-header-data text-danger"
                        v-b-tooltip.hover :title="$t('simple.deleted_info')">
                        <font-awesome-icon :icon="'comment-slash'" />
                    </span>

                    <span v-if="message.is_whatsapp_template" class="message-header-data" v-b-tooltip.hover
                        :title="$t('macros.whatsapp_template')">
                        <font-awesome-icon :icon="'file-alt'" />
                    </span>
                    <!-- TODO: Check after campaign part -->
                    <!-- <span v-if="message.is_whatsapp_template" class="message-header-data" v-b-popover.click.top="message.whatsapp_template_id" :title="$t('ticket.post_id')" >
                        <font-awesome-icon :icon="'file-alt'" />
                    </span> -->

                    <!-- Message Sender -->
                    <message-sender
                        v-if="message.sender_id && message.sender_id !== account.id && message.sender_account"
                        v-bind:sender_account="message.sender_account"></message-sender>

                    <!-- Message Status -->
                    <message-status v-bind:message="message"></message-status>

                    <!-- Message Type Render -->
                    <message-type v-bind:integration="account.integration_id" v-bind:type="message.type"></message-type>

                    <span v-if="message.thread_message_list[0]" class="message-header-data">
                        <font-awesome-icon :class="visible ? null : 'collapsed'"
                            :aria-expanded="visible ? 'true' : 'false'" :aria-controls="'collapse-' + message.id"
                            @click="visible = !visible" :icon="'comment-dots'" />
                    </span>


                    <!-- Message Created -->
                    <message-date v-bind:username="account.username" v-bind:id="message.message_id"
                        v-bind:deleted="message.activity" v-bind:type="message.type"
                        v-bind:integration="account.integration_id"
                        v-bind:date="{ ago: $moment.utc(message.created_at).fromNow(true), created: $moment($moment.utc(message.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }"
                        v-bind:created="$moment($moment.utc(message.created_at)).local().format('YYYY-MM-DD HH:mm:ss')"></message-date>

                    <!-- Message Ticket -->
                    <message-ticket v-if="ticket" v-bind:ticket="ticket"
                        v-on:updateTicketID="$emit('updateTicketID', ticket.id)"
                        v-on:closeTicket="$emit('closeTicket', ticket.id)"
                        v-b-toggle="ticket_toggle"></message-ticket>

                    <!-- Here goes our buttons -->
                    <div class="dropdown message-header-data">

                        <b-dropdown size="sm" right variant="light" :disabled="disabled_areas.draft_options">
                            <b-dropdown-item
                                :disabled="(customer.user_assigned && customer.user_assigned !== user.id && !user.is_super) || (message.activity === 'deleted')"
                                v-on:click="updateTo(customer.id, message)"><font-awesome-icon :icon="'reply'"
                                    class="mr-2" />{{ $t('default.reply') }}</b-dropdown-item>
                            <b-dropdown-item
                                v-if="!message.activity && (!message.confirmation || message.confirmation.status === 1) && !message.schedule && (message.message_id || account.integration_id === 'googlereview') && (message.type === 2 || account.integration_id === 'sikayetvar' || account.integration_id === 'googlereview')"
                                v-on:click="message_delete(account.id, account.integration_id === 'googlereview' ? message.thread_id : message.message_id, 'draft')"><font-awesome-icon
                                    :icon="'trash'" class="mr-2" />{{ $t('simple.delete') }}</b-dropdown-item>
                        </b-dropdown>

                    </div>

                </div>

            </div>

            <div v-if="message.thread_id" class="message-draft m-0 pb-1 shadow-sm">
                <div class="message-draft-reply pt-2 pl-3 pr-3">
                    <font-awesome-icon :icon="'reply'" class="plus__icon text-warning pr-2" style="font-size: 22px;" />
                    <span class="text-black-50 ">
                        <strong>{{ message.thread_message[0] ? message.thread_customer[0].username : 'You' }}</strong>
                    </span>
                    <p>{{ message.thread_message[0] ? message.thread_message[0].content :
                        message.thread_our_message[0].content }}</p>
                    <!--<p>{{ message }}</p>-->
                </div>

                <b-collapse :id="'collapse-' + message.id" v-model="visible" class="mt-2">
                    <div class="mt-4" v-for="thread_list in message.thread_message_list">
                        <div>
                            <!--<p>{{ thread_list }}</p>-->
                            <!-- <p>{{ thread_list.customer.username }}</p>-->
                            <div class="pl-2 pb-2">
                                <b-img :src="thread_list.account[0].avatar" alt="Account image"
                                    style="display: inline-block;" class="message-header-avatar"></b-img>
                                <span class="message-header-name">{{ thread_list.account[0].username }}</span>
                            </div>
                            <p class="p-3 mr-2 ml-2 mb-4 mt-0"
                                style="border-left:solid 5px; background: #ffffff; border-left-color: #722ed1; color: #6a6c6f; ">
                                {{ thread_list.content }}</p>
                        </div>
                    </div>
                </b-collapse>

                <div class="p-3">
                    <message-attachments v-if="message.attachment_id  && message.is_whatsapp_template"
                        v-bind:attachments="message.attachments"
                        :is_whatsapp_template="message.is_whatsapp_template"></message-attachments>
                    <h6 v-if="message.whatsapp_template_header" class="py-1 px-2 bg-light"
                        :style="{ borderRadius: '10px' }">{{ message.whatsapp_template_header }}</h6>
                    <div class="message-content"  :class="isLanguageRtl ? 'text-right' : ''" :dir="isLanguageRtl ? 'rtl' : 'ltr'">{{ message.content }} </div>
                </div>
            </div>
            <div v-else-if="!message.thread_id" class="message message-draft shadow-sm">
                <message-attachments v-if="message.attachment_id  && message.is_whatsapp_template"
                    v-bind:attachments="message.attachments"
                    :is_whatsapp_template="message.is_whatsapp_template"></message-attachments>
                <h6 v-if="message.whatsapp_template_header" class="py-1 px-2 bg-light" :style="{ borderRadius: '10px' }">
                    {{ message.whatsapp_template_header }}</h6>
                <div class="message-content" :class="isLanguageRtl ? 'text-right' : ''" :dir="isLanguageRtl ? 'rtl' : 'ltr'">{{ message.content }} </div>
            </div>


            <!-- Message Attachments -->
            <message-template-buttons v-if="message.whatsapp_template_buttons "
                :buttons="message.whatsapp_template_buttons"></message-template-buttons>

            <message-attachments v-if="message.attachments && !message.is_whatsapp_template"
                v-bind:attachments="message.attachments"></message-attachments>

        </div>
        <!--<div v-if="message.schedule">
            <div style="float: right;">
                <font-awesome-icon slot="dot" :icon="'clock'" class="text-info"/>
                <strong>{{ $t("simple.scheduled") }}</strong> <span class="text-info" v-b-tooltip.hover :title="message.schedule.scheduled_at"> {{ message.schedule.scheduled_at | moment("from", "now") }}</span>
            </div>
            <hr>
        </div>-->

        <message-confirmation v-if="message.confirmation && getUser.is_super && message.confirmation.status == null"
            v-bind:confirmation="message.confirmation" v-bind:schedule="message.schedule" :disabled_areas="disabled_areas"></message-confirmation>

        <!--Break-->
        <br />

        <!--TimeLine-->
        <a-timeline mode="right" v-if="timeline">


            <a-timeline-item color="green"><strong>{{ message.user.ns }}</strong> created draft <span v-b-tooltip.hover
                    :title="$moment($moment.utc(message.created_at)).local().format('YYYY-MM-DD HH:mm:ss')"
                    class="text-success">{{ message.created_at | moment("from", "now") }}</span></a-timeline-item>

            <template v-if="message.schedule">

                <a-timeline-item color="green">
                    <font-awesome-icon slot="dot" :icon="'clock'" class="text-info" />
                    <strong>{{ message.schedule.user.ns }}</strong> {{ $t("simple.scheduled") }} <span class="text-info"
                        v-b-tooltip.hover
                        :title="$moment($moment.utc(message.schedule.scheduled_at)).local().format('YYYY-MM-DD HH:mm:ss')">{{
                        $moment($moment.utc(message.schedule.scheduled_at)).local().format('YYYY-MM-DD HH:mm:ss') |
                        moment("from", "now") }}</span>
                </a-timeline-item>

            </template>

            <template v-if="message.confirmation">

                <a-timeline-item>
                    <font-awesome-icon slot="dot" :icon="'lock'" class="plus__icon" />
                    <strong>{{ message.confirmation.user.ns }}</strong> requested verification <span class="text-dark"
                        v-b-tooltip.hover
                        :title="$moment($moment.utc(message.confirmation.created_at)).local().format('YYYY-MM-DD HH:mm:ss')">{{
                        message.confirmation.created_at | moment("from", "now") }}</span>
                </a-timeline-item>

            </template>

            <template v-if="message.confirmation && message.confirmation.status">

                <a-timeline-item v-if="message.confirmation.status === 1">
                    <font-awesome-icon slot="dot" :icon="'unlock'" class="text-success plus__icon" />
                    <strong>{{ message.confirmation.user.ns }}</strong> confirmed your request <span
                        class="text-success" v-b-tooltip.hover
                        :title="$moment($moment.utc(message.confirmation.reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss')">{{
                        $moment($moment.utc(message.confirmation.reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss') |
                        moment("from", "now") }}</span>
                </a-timeline-item>

                <a-timeline-item v-else-if="message.confirmation.status === 2">
                    <font-awesome-icon slot="dot" :icon="'lock'" class="text-danger plus__icon" />
                    <strong>{{ message.confirmation.user.ns }}</strong><span class="text-danger" v-b-tooltip.hover
                        :title="$moment($moment.utc(message.confirmation.reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss')">
                        rejected your request {{
                        $moment($moment.utc(message.confirmation.reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss') |
                        moment("from", "now") }}</span>
                </a-timeline-item>


            </template>

            <template v-if="message.activity">

                <a-timeline-item v-if="message.deleted_by_user">
                    <font-awesome-icon slot="dot" :icon="'comment-slash'" class="text-danger plus__icon" />
                    <strong>{{ message.deleted_by_user.ns }}</strong><span> {{ $t('simple.message_deleted') }}</span>
                </a-timeline-item>

                <a-timeline-item v-else>
                    <font-awesome-icon slot="dot" :icon="'comment-slash'" class="text-danger plus__icon" />
                    <strong>{{ $t('simple.unknown') }}</strong><span> {{ $t('simple.message_deleted') }}</span>
                </a-timeline-item>


            </template>



            <template v-if="message.confirmation && confirmation_note.confirmation.confirmation_note">

                <a-timeline-item>
                    <font-awesome-icon slot="dot" :icon="'exclamation-circle'" class="text-info plus__icon" />
                    <strong>Confirmation request note: </strong><span class="text-info" v-b-tooltip.hover
                        :title="$moment($moment.utc(message.confirmation.reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss')">{{
                        $moment($moment.utc(message.confirmation.reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss') |
                        moment('from', 'now') }}</span>
                    <pre>{{ confirmation_note.confirmation.confirmation_note.content }}</pre>
                </a-timeline-item>

            </template>

        </a-timeline>


    </div>
</template>

<script>

import MessageDelete from './Modal/MessageDelete.vue';
import { create } from 'vue-modal-dialogs';
import { mapActions, mapGetters } from 'vuex';
export default {

        props: [
            'account',
            'ticket',
            'message',
            'confirmation_note',
            'user',
            'date',
            'timeline',
            'page',
            'status',
            'customer',
            'disabled_areas',
            'languages'
        ],

        mounted: function () {
            // Component mounted
            //this.start_echo();
            //console.log('Content render mounted')
        },

        destroyed() {
            // Component destroyed
            //this.leave_echo(96);
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
            //console.log(timeline);
        },
        
        data: function() { return {
            visible: false
        } },

        computed: {
            // Map getters
            ...mapGetters({
            // User
            getUser: 'User/getUser',
        }),

        isLanguageRtl() {
            let reg = /^[\u0621-\u064A\u0660-\u0669 ]+$/;
            return reg.test(this.message.content);
        },

        ticket_toggle() {
            if(this.disabled_areas.ticket_edit) {
                return ['']
            }
            return ['user-info-sidebar-right-tablet', 'plus-col-accounts-sidebar','plus-col-main-list-inbox-tablet', 'plus-col-main-messages-panel-mobile']
        }

    },

    methods: {

            // Messages
            ...mapActions('Messages', [
                'updateOnConfirm',
                'fetchLastConfirm',
                'updateChangesOnMessages',
            ]),

            // Customer
            ...mapActions('Customer', [
                'assignCustomer',
            ]),

            // Messages
            ...mapActions('Reply', [
                'updateReplyTo',
                'deleteMessage',
            ]),

            updateTo: function (customer, message) {
                this.updateReplyTo({ customer: customer, message: message });
                // this.assignCustomer('set');
            },

            messageDelete: async function (account, message) {
                this.deleteMessage({ account_id: account, message_id: message });
            },

            // Remove from Social Account
            message_delete: async function (account_id, message_id, place) {

                // Show account Block modal
                const message_delete = create(MessageDelete, "account_id", "message_id", "place");

                if (await message_delete(account_id, message_id, place)) {
                    // Refresh account list
                    //this.fetchLastConfirm(customer_id);
                    await this.updateChangesOnMessages({ customer_id: this.customer.id });
                }

            },

            // Listen
            start_echo: async function (account_id) {

                Echo.private('inbox.customer.' + account_id)
                //.subscribe('InboxList')
                    .listen('InboxEvent', (e) => {
                        console.log(e);
                        console.log('new confirmation came!');
                        //this.updateOnConfirm({ customer_id: this.customer_id, page: this.pagination.current_page });
                        this.updateOnConfirm({ account_id: this.customer_id, page: this.pagination.current_page });
                    });
            },

            // Leave
            leave_echo: async function (account_id) {

                console.log('leave echo ' + account_id);


                Echo.leave('confirmation.account.' + account_id);

            },

        },
        
    }
</script>