<template>

    <form @submit.prevent="validate_edit" style="overflow: auto;height:auto">

        <!-- Identification -->
        <div class="form-group">
            <label for="identification" class="text-body">{{ $t('customer.identification') }}</label>

                <input

                    class="form-control"
                    type="text"
                    id="identification"
                    name="identification"
                    v-validate="'max:100'"
                    v-model="customer_edit.identification"
                >
                <small v-show="errors.has('identification')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('identification') }}</small>

        </div>

        <!-- Customer name -->
        <div class="form-group">

            <label for="name" class="text-body">{{ $t('customer.name') }}</label>

                <input
                    class="form-control"
                    type="text"
                    id="name"
                    name="name"
                    v-validate="'max:100'"
                    v-model="customer_edit.name"
                >

                <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

        </div>

        <!-- Customer surname -->
        <div class="form-group">

            <label for="surname" class="text-body">{{ $t('customer.surname') }}</label>

                <input

                    class="form-control"
                    type="text"
                    id="surname"
                    name="surname"
                    v-validate="'max:100'"
                    v-model="customer_edit.surname"
                >

                <small v-show="errors.has('surname')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('surname') }}</small>

        </div>

        <!-- Customer phone -->
        <div class="form-group">

            <label for="phone" class="text-body">{{ $t('customer.phone') }}</label>
            
            <input

                class="form-control"
                type="text"
                id="phone"
                name="phone"
                v-validate="'max:100'"
                v-model="customer_edit.phone"
            >
            <small v-show="errors.has('phone')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('phone') }}</small>

        </div>

        <!-- Customer Email -->
        <div class="form-group">

            <label for="email" class="text-body">{{ $t('customer.email') }}</label>
                
                <input

                    class="form-control"
                    type="email"
                    id="email"
                    name="email"
                    v-validate="'max:100'"
                    v-model="customer_edit.email"
                >
                <small v-show="errors.has('email')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('email') }}</small>

        </div>

        <!-- Customer Location -->
        <div class="form-group">

            <label for="location" class="text-body">{{ $t('customer.location') }}</label>

            <gmap-autocomplete
                    ref="autocomplete"
                    :select-first-on-enter="true"
                    id="location"
                    name="location"
                    @place_changed="setPlace"
                    class="form-control"
            ></gmap-autocomplete>

        </div>

        <div v-if="loc.country || customer_edit.location">

            {{ customer_edit.location.formatted_address }}

            <br>

        <div class="form-group">

            <label for="country" class="text-body">{{ $t('customer.country') }}</label>

            <input

                    class="form-control"
                    type="text"
                    id="country"
                    name="country"
                    v-model="customer_edit.location.country"
            >

            <small v-show="errors.has('surname')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('surname') }}</small>

        </div>

        <div class="form-group">

            <label for="country_code" class="text-body">{{ $t('customer.country_code') }}</label>

            <input

                    class="form-control"
                    type="text"
                    id="country_code"
                    name="country_code"
                    v-model="customer_edit.location.country_code"
            >

            <small v-show="errors.has('surname')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('surname') }}</small>

        </div>

            <div class="form-group">

            <label for="state" class="text-body">{{ $t('customer.state_code') }}</label>

            <input

                    class="form-control"
                    type="text"
                    id="state"
                    name="state"
                    v-model="customer_edit.location.country_code"
            >

            <small v-show="errors.has('surname')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('surname') }}</small>

        </div>

            <div class="form-group">

            <label for="postal_code" class="text-body">{{ $t('customer.postal_code') }}</label>

            <input

                    class="form-control"
                    type="text"
                    id="postal_code"
                    name="postal_code"

                    v-model="customer_edit.location.zip"
            >

            <small v-show="errors.has('surname')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('surname') }}</small>

        </div>

            <div class="form-group">

            <label for="county" class="text-body">{{ $t('customer.county') }}</label>

            <input

                    class="form-control"
                    type="text"
                    id="county"
                    name="county"

                    v-model="customer_edit.location.county"
            >

            <small v-show="errors.has('surname')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('surname') }}</small>

        </div>

            <div class="form-group">

            <label for="district" class="text-body">{{ $t('customer.district') }}</label>

            <input

                    class="form-control"
                    type="text"
                    id="district"
                    name="district"

                    v-model="customer_edit.location.district"
            >

            <small v-show="errors.has('surname')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('surname') }}</small>

        </div>
        </div>

        <!-- Customer gender  -->
        <div class="form-group">

            <label for="type" class="text-body">{{ $t('customer.gender') }}</label>

            <template v-if="customer_genders">

                <treeselect
                    v-model="customer_edit.customer_gender"
                    :options="customer_genders"
                    :multiple="false"
                    value-format="object"
                    :placeholder="$t('customer.gender_select')"
                    :normalizer="normalizer_gender"
                >
                    <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                        {{ $t(node.raw.name)  }}
                    </label>

                    <div slot="value-label" slot-scope="{ node }"> {{ $t(node.raw.name)  }} </div>

                </treeselect>

            </template>
            
        </div>


        <!-- Customer type id  -->
        <div class="form-group">

            <label for="type" class="text-body">{{ $t('customer.type') }}</label>

                <template v-if="customer_types">

                    <treeselect

                        v-model="customer_edit.customer_type"
                        :options="customer_types"
                        :multiple="false"
                        value-format="object"
                        :placeholder="$t('customer.type_select')"
                        :normalizer="normalizer"
                    >

                        <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                            {{ node.raw.name }}
                        </label>

                        <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }} </div>

                    </treeselect>

                </template>

        </div>

        <div class="form-group">

          <b-button variant="success" @click="validate_edit" :disabled="errors.any()">{{ $t('simple.save') }}</b-button>
          <b-button variant="danger" @click="updateCustomerEdit(false)">{{ $t('simple.cancel') }}</b-button>

        </div>

    </form>


</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    import Treeselect from '@riophae/vue-treeselect';

    import { gmapApi } from 'vue2-google-maps';

    export default {

        components: { Treeselect, gmapApi },
        props: ['customer'],


        mounted: function () {
            // Component mounted
            console.log('Customer Render mounted')

            this.customer_edit = {...this.customer}
        },

        destroyed() {
            // Component destroyed
            console.log('Customer Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {

                loc: {},

                moment: this.$moment,
                
                customer_edit: {}

            }

        },

        computed: {

            // Map getters
            ...mapGetters({

                // Options for treeselect
                customer_types: 'Customer/getCustomerTypes',
                customer_genders: 'Customer/getCustomerGenders',

            }),

        },
        
        methods: {

            ...mapActions('Customer', [
                'updateCustomer',
                'updateCustomerEdit',
                'updateCustomerRefresh',

                // Customer data update
                'updateCustomerIdentification',
                'updateCustomerName',
                'updateCustomerSurname',
                'updateCustomerPhone',
                'updateCustomerEmail',
                'updateCustomerLocation',
                'createCustomerLocation',
                'updateLocation',
                'updateCustomerCountry',
                'updateCustomerCountryCode',
                'updateCustomerState',
                'updateCustomerPostalCode',
                'updateCustomerCounty',
                'updateCustomerDistrict',
                'updateCustomerTypeID',
                'updateCustomerGenderID',

            ]),


            normalizer: function(node){
                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                }
            },

            normalizer_gender: function(node){

                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                }
            },

            validate_edit: async function() {
                // validate all fields
                await this.$validator.validateAll().then(async (result) => {
                    if (result) {
                        // Send it to account edit function
                        if(!this.customer.location && this.loc.country) {
                            this.updateCustomerLocationData()
                            await this.createCustomerLocation();
                            this.updateCustomerData()
                            this.updateCustomerRefresh();
                        }else if(this.customer.location && this.loc.country ){
                            this.updateCustomerLocationData()
                            await this.updateLocation();
                            this.updateCustomerData()
                            this.updateCustomerRefresh();
                        }else if(this.customer.location && !this.loc.country){
                            this.updateCustomerLocationData()
                            await this.updateLocation();
                            this.updateCustomerData()
                            this.updateCustomerRefresh();
                        }else{
                            this.updateCustomerData()
                            this.updateCustomerRefresh();
                        }
                    }
                });
            },

            updateCustomerData() {
                this.updateCustomerIdentification(this.customer_edit.identification)
                this.updateCustomerName(this.customer_edit.name);
                this.updateCustomerSurname(this.customer_edit.surname);
                this.updateCustomerPhone(this.customer_edit.phone);
                this.updateCustomerEmail(this.customer_edit.email);
                this.updateCustomerGenderID(this.customer_edit.customer_gender);
                this.updateCustomerTypeID(this.customer_edit.customer_type);
            },

            updateCustomerLocationData() {
                this.updateCustomerLocation(this.customer_edit.location.formatted_address);
                this.updateCustomerCountry(this.customer_edit.location.country);
                this.updateCustomerCountryCode(this.customer_edit.location.country_code);
                this.updateCustomerState(this.customer_edit.location.state);
                this.updateCustomerPostalCode(this.customer_edit.location.zip);
                this.updateCustomerCounty(this.customer_edit.location.country);
                this.updateCustomerDistrict(this.customer_edit.location.district);
                
            },

            setPlace: function(data){
                if(!data.name){
                    this.customer_edit.location = null
                    this.loc = {};
                    return
                } else if( data.name && data.address_components){
                    const address_components = data.address_components;
                    this.loc = {};
                    address_components.map((value, index) => {
                        value.types.map((value2, index2) => {
                            this.loc[value2] = value.long_name;
                            if (value2==='country')
                                this.loc.country_id = value.short_name;
                            if (value2==='administrative_area_level_1')
                                this.loc.state_code = value.short_name;
                        })
                    });
                    this.customer_edit.location = {}
                    this.customer_edit.location.formatted_address = data.formatted_address 
                    this.customer_edit.location.country = this.loc.country
                    this.customer_edit.location.country_code = this.loc.country_id
                    this.customer_edit.location.zip = this.loc.postal_code
                    this.customer_edit.location.state = this.loc.state_code
                    this.customer_edit.location.county = this.loc.administrative_area_level_2
                    this.customer_edit.location.district = this.loc.administrative_area_level_4
                    
                }

               
            }


        },
        
    }
</script>