<template>

       <span class="list-customer-message-text">
           <template v-if="customer.last_message">
               <font-awesome-icon v-if="customer.last_message.draft_id" style="font-size:15px"
               :icon="fail ? 'exclamation-circle' : 'check'" :class="fail ? 'text-danger' : 'text-success'"/>
             {{ content }}
           </template>
       </span>

</template>

<script>

    export default {

        props: ['customer'],

        mounted: function () {
            this.getLastMessage();
        },

        data: function () {
            return {
              content: null,
              fail: null,
            }
        },

        methods: {
          getLastMessage(){
            if(this.customer.last_message.question_id){
              this.content = this.customer.last_message.question.content;
            }else if(this.customer.last_message.draft_id){
              this.content = this.customer.last_message.draft.content;
              this.fail = !!this.customer.last_message.draft.fail;
            }else if(this.customer.last_message.answer_id){
              this.content = this.customer.last_message.answer.content;
            }
          }
        },

        computed: {},

        watch: {
            customer: function (new_customer, old_customer) {
                this.getLastMessage();
            }
        },
    }
</script>