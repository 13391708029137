<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="customerTypes.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>


    // Vuex
    import {mapGetters, mapActions} from 'vuex';


    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";



    /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('xy', this.customerTypes )


                let root;


                if (this.customerTypes.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }


                root.setThemes([am5themes_Animated.new(root)]);

                let chart = root.container.children.push(am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    layout: root.verticalLayout,
                }));


                let legend = chart.children.push(am5.Legend.new(root, {
                    centerX: am5.p50,
                    x: am5.p50
                }));

                let data = this.customerTypes.length ? this.customerTypes : this.fakeCustomerTypes;

                let xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        baseInterval: {
                            timeUnit: this.customerTypes.length ? this.calendar_interval : 'year',
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                        }),

                    })
                );

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        min: 0,
                        renderer: am5xy.AxisRendererY.new(root, {})
                    })
                );

                let makeSeries = (name, fieldName) => {
                    let series = chart.series.push(
                        am5xy.ColumnSeries.new(root, {
                            name: name,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: fieldName,
                            valueXField: "date",
                            stacked: true,
                            clustered: false,
                            tooltip: am5.Tooltip.new(root, {})
                        })
                        );

                    series.data.processor = am5.DataProcessor.new(root, {
                        dateFields: ["date"],
                        dateFormat: "yyyy-MM-dd-HH"
                    });

                    series.get("tooltip").label.set("text", "{name}: [bold]{valueY}[/]");
                    series.data.setAll(data);
                }

                let items = []
                data.forEach(obj => {
                    for ( let element in obj) {
                        if( element !== 'date') {
                            if(items.indexOf(element) === -1) {
                                items.push(element)
                            }
                        }
                    }
                });

                items.forEach( item => makeSeries(item, item))


                legend.data.setAll(chart.series.values);

                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                        behavior: "zoomXY",
                }));
                cursor.lineY.set("visible", false);

                if (this.customerTypes.length > 0) {
                        am5plugins_exporting.Exporting.new(root, {
                                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                                dataSource: data,
                                dateFields: ["date"],
                                dateFormat: "yyyy-MM-dd",
                                filePrefix: "convoTypes",
                        });
                }


                this.root = root;


                // // Create chart instance
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);


                // // Add data
                // chart.data = this.customerTypes;

                // // Create axes
                // let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                // dateAxis.dataFields.date = "date";
                // dateAxis.renderer.grid.template.location = 0;
                // dateAxis.renderer.minGridDistance = 20;
                // dateAxis.renderer.labels.template.fontSize = 10;
                // dateAxis.renderer.cellStartLocation = 0.1;
                // dateAxis.renderer.cellEndLocation = 0.9;


                // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                // valueAxis.min = 0;
                // valueAxis.title.text = this.$t('page.customertypes');

                // // Create series
                // function createSeries(field, name) {
                //         let series = chart.series.push(new am4charts.ColumnSeries());
                //         series.dataFields.valueY = field;
                //         series.dataFields.dateX = "date";
                //         series.name = name;
                //         series.tooltipText = "{name}: [bold]{valueY}[/]";
                //         series.columns.template.width = am4core.percent(75);
                //         series.stroke = am4core.color("#212020");
                //         series.stacked = true;
                //         series.legendSettings.labelText = "{name} :";
                //         series.legendSettings.valueText = "{valueY.sum}";
                // }

                // chart.maskBullets = false;

                // // createSeries Loops
                // let i = 0;
                // chart.data.forEach (elements => {
                //         i++;
                //         for (let element in elements) {
                //                 if(element != "date" && i == 1){
                //                         createSeries(element, element, false);
                //                 }
                //         }
                // });


                // // Create legend instance
                // chart.legend = new am4charts.Legend();


                // // Add cursor
                // chart.cursor = new am4charts.XYCursor();


                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoCustomer";


        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

                if (this.root) {
                        this.root.dispose();
                }

        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({
                        customerTypes: 'Report/getCustomerTypes',
                        fakeCustomerTypes: 'Report/getFakeCustomerTypes',
                        calendar_interval: 'Report/getCalenderInterval',

                })

        },
    }
</script>