import Vue from 'vue';
import Vuex from 'vuex';
import Accounts from './modules/Accounts';
import Confirmation from './modules/Confirmation';
import Customer from './modules/Customer';
import Ticket from './modules/Ticket';
import Inbox from './modules/Inbox';
import Integrations from './modules/Integrations';
import Player from './modules/Player';
import Messages from './modules/Messages';
import Notification from './modules/Notification';
import Reply from './modules/Reply';
import Report from './modules/Report';
import Trash from './modules/Trash';
import User from './modules/User';
import Users from './modules/Users';
import Teams from './modules/Teams';
import Post from './modules/Post';

import Macro from './modules/Macro';
import Pixel from './modules/Pixel';
import Configurations from './modules/Configurations';
import Templates from './modules/Templates';


// Use vuex
Vue.use(Vuex);

export const store = new Vuex.Store({
  
  state: {},
  getters : {},
  mutations: {},
  actions:{},
  
  modules : {
    Accounts,
    Customer,
    Confirmation,
    Inbox,
    Integrations,
    Player,
    Ticket,
    Messages,
    Notification,
    Reply,
    Report,
    Trash,
    User,
    Users,
    Teams,
    Macro,
    Pixel,
    Configurations,
    Post,
    Templates,
  }
});