<template>

    <span class="message-header-data pt-1" v-b-tooltip.hover :title="review">

        <font-awesome-icon :icon="'user-clock'" class="text-secondary" />

          {{$t('confirmation.reviewed')}} {{ review | moment("from", "now") }}

    </span>

</template>
<script>

    export default {

        props: ['review'],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>