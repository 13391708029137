<template>

    <b-form-group>

        <!-- Stroke  -->
        <b-form-checkbox v-model="status" @input="update_status"> {{ $t('pixel.stroke') }}</b-form-checkbox>

        <!-- Check if config status true -->
        <template v-if="status">

            <!-- Color change -->
            <b-form-group :label="$t('pixel.color')">

                <b-form-input type="color" v-model="config.stroke" @input="update" />

            </b-form-group>

            <!-- Width -->
            <b-form-group :label="$t('pixel.width') + ' : ' + config.strokeWidth">

                <b-form-input v-model.number="config.strokeWidth" type="range" min="0" max="30" @input="update" />

            </b-form-group>

            <hr>

        </template>

    </b-form-group>

</template>

<script>

    export default {

        props: ['default_stroke', 'default_width'],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        

        data: function() {
            return  {

                config: {

                    stroke: this.default_stroke,
                    strokeWidth: this.default_width,

                },

                default_config :{
                    stroke: '#000000',
                    strokeWidth: 2,
                },

                status: !!this.default_stroke,

            }
        },
        methods: {

            // Update config
            update: function(){

                // Sent to parent
                this.$emit('update', this.config);
            },

            // Update status
            update_status: function(){

                // Check if don't have status
                if(!this.status){

                    this.config.stroke = null;

                } else {

                    this.config = this.default_config;
                }

                // Sent to parent
                this.$emit('update', this.config);
            },

        },
        
    }
</script>