<template>

    <div>
        <!-- Customer Type -->
        <template v-if="customer.customer_type">

                        <!-- Check if customer type is VIP -->
            <template v-if="customer.customer_type.is_vip">
                <font-awesome-icon :icon="'user-secret'" class="text-danger" v-b-tooltip.hover :title="$t('simple.vip')"/>
            </template>


            <!-- Check if customer type is Important -->
            <template v-if="customer.customer_type.is_important">
                    <font-awesome-icon :icon="'exclamation-circle'" :class="customer.customer_type.is_vip ? 'text-primary' : 'text-danger'" v-b-tooltip.hover :title="$t('simple.important')"/>
            </template>


        </template>

    </div>


</template>

<script>

    export default {

        props: ['customer'],

        mounted: function () {
            // Component mounted
            //console.log('Customer Type Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Type Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {};

        },

        computed: {},
        
        methods: {},
        
    }
</script>