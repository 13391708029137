<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="userTimeTable.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';


    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    /* Imports */
//     import * as am4core from "@amcharts/amcharts4/core";
//     import * as am4charts from "@amcharts/amcharts4/charts";
//     import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//     /* Plugin: Annotation */
//     import * as am4plugins_annotation from "@amcharts/amcharts4/plugins/annotation";

    /* Chart code */
    // Themes begin
//     am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

                console.log('xy', this.userTimeTable)

                let root;   

                if (this.userTimeTable.length > 0) {
                        root = am5.Root.new(this.$refs.chartdiv);
                } else {
                        root = am5.Root.new(this.$refs.fakechart)
                }
                root.setThemes([am5themes_Animated.new(root)]);

                let data = this.userTimeTable.length ? this.userTimeTable : this.fakeUserTimeTable;



                let chart = root.container.children.push(am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        wheelX: "none",
                        wheelY: "none",
                }));

                let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineY.set("visible", false);


                let xRenderer = am5xy.AxisRendererX.new(root, {
                        minGridDistance: 15
                });

                xRenderer.labels.template.setAll({
                        rotation: -90,
                        centerY: am5.p50,
                        centerX: 0
                });

                xRenderer.grid.template.setAll({
                        visible: false
                });

                let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                        maxDeviation: 0.3,
                        categoryField: "category",
                        renderer: xRenderer,
                        tooltip: am5.Tooltip.new(root, {})
                }));

                let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                        min: 0,
                        maxDeviation: 0.3,
                        renderer: am5xy.AxisRendererY.new(root, {})
                }));

                let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value",
                        categoryXField: "category",
                        adjustBulletPosition: false,
                        tooltip: am5.Tooltip.new(root, {
                                labelText: "{valueY} Minutes"
                        })
                }));
                series.columns.template.setAll({
                        width: 0.5
                });

                series.bullets.push(function () {
                        return am5.Bullet.new(root, {
                                locationY: 1,
                                sprite: am5.Circle.new(root, {
                                        radius: 5,
                                        fill: series.get("fill")
                                })
                        })
                })

                xAxis.data.setAll(data);
                series.data.setAll(data);

                if (this.userTimeTable.length > 0) {
                        am5plugins_exporting.Exporting.new(root, {
                                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                                dataSource: data,
                                filePrefix: "convoTypes",
                        });
                }

                this.root = root;



                // Create chart instance
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

                // let data = [];
                // let value = 120;

                // chart.data = this.userTimeTable;


                // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                // categoryAxis.renderer.grid.template.location = 0;
                // categoryAxis.dataFields.category = "category";
                // categoryAxis.renderer.minGridDistance = 15;
                // categoryAxis.renderer.grid.template.location = 0.5;
                // categoryAxis.renderer.grid.template.strokeDasharray = "1,3";
                // categoryAxis.renderer.labels.template.rotation = -90;
                // categoryAxis.renderer.labels.template.horizontalCenter = "left";
                // categoryAxis.renderer.labels.template.location = 0.5;

                // categoryAxis.renderer.labels.template.adapter.add("dx", function(dx, target) {
                //         return -target.maxRight / 2;
                // })

                // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                // valueAxis.tooltip.disabled = true;
                // valueAxis.renderer.ticks.template.disabled = true;
                // valueAxis.renderer.axisFills.template.disabled = true;

                // let series = chart.series.push(new am4charts.ColumnSeries());
                // series.dataFields.categoryX = "category";
                // series.dataFields.valueY = "value";
                // series.tooltipText = "{valueY.value}" + this.$t('reports.minutes');
                // series.sequencedInterpolation = true;
                // series.fillOpacity = 0;
                // series.strokeOpacity = 1;
                // series.strokeDashArray = "1,3";
                // series.columns.template.width = 0.01;
                // series.tooltip.pointerOrientation = "horizontal";

                // let bullet = series.bullets.create(am4charts.CircleBullet);

                // chart.cursor = new am4charts.XYCursor();

                // chart.scrollbarY = new am4core.Scrollbar();

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoUserTime";

                // // Plugin: Annotation
                // chart.plugins.push(new am4plugins_annotation.Annotation());



        },

        beforeDestroy() {
        //     if (this.chart) {
        //         this.chart.dispose();
        //     }

                if (this.root) {
                        this.root.dispose();
                }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

                // Map getters
                ...mapGetters({

                        userTimeTable: 'Report/getUserTimeTable',
                        fakeUserTimeTable: 'Report/getFakeUserTimeTable',

                })
        },
    }
</script>