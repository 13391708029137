<template>
  <div class="row">

    <div class="col pt-2">

      <!--  Page  -->
      <div class="bg-white rounded py-2 shadow-widget border">

        <!-- Page header -->
        <div class="px-3">

          <div class="d-flex align-items-center">

            <div>
              <h4>{{ $t('page.close_tickets') }}</h4>
              <span class="text-muted font-weight-light">{{ $t('page.close_tickets_desc') }}</span>
            </div>


            <div class="ml-auto d-flex flex-column justify-content-center mt-2">

              <div class="d-flex justify-content-center mb-2">
                <b-button :variant="'danger'" @click="close_all">
                  <font-awesome-icon :icon="'times-circle'" />
                  {{ $t('ticket.close_all') }}
                </b-button>

                <b-button :variant="'primary'" @click="add" class="ml-1">
                  <font-awesome-icon :icon="'plus'" />
                  {{ $t('default.add') }}
                </b-button>
              </div>

            </div>

          </div>


        </div>

        <hr>

        <!-- Page content -->
        <div class="px-2">
            <b-row>
              <b-col>
                <b-pagination 
                  hide-goto-end-buttons 
                  size="sm" 
                  :total-rows="total" 
                  :value="current" 
                  @input="updateCloseTicketPage" 
                  :per-page="perpage">
                </b-pagination>  
              </b-col>
              <b-col md="4" class="my-2 ml-auto">
                <b-input-group>
                  <b-form-input :value="filter" @input="onFiltered" :placeholder="$t('simple.type_to_search')" />
                  <b-input-group-append>
                    <b-btn :disabled="!filter" @click="onFiltered('')">{{ $t('simple.clear') }}</b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

              <b-table stacked="lg" striped responsive bordered hover :items="getCloseTickets" :fields="user_fields"
                :per-page="perpage" id="close-tickets-table">
              
                <!-- Avatar -->
                <template slot="cell(integration)" slot-scope="data">
                
                  <b-badge :variant="'primary'">
                  
                    <font-awesome-icon :icon="['fab', data.item.integration === 'googlereview' ? 'google' : data.item.integration]" />
                  
                    {{ data.item.integration }}
                  
                  </b-badge>
                  <span>{{ data.item.account_name }}</span>
                
                </template>
              
                <!-- Created at -->
                <template slot="cell(created_at)" slot-scope="cell">
                  {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                
                </template>
              
              </b-table>

              <v-wait for="fetch_closed_tickets">

                <loading slot="waiting"/>

              </v-wait>
            
        </div>


      </div>

    </div>
  </div>
</template>


<script>


import { mapActions, mapGetters } from "vuex";
import { create } from "vue-modal-dialogs";
import TicketClose from "./Modal/TicketClose.vue";
import TicketCloseConfirm from "./Modal/TicketCloseConfirm";

export default {

  mounted: function () {
    // Component mounted
    console.log('Close Tickets component mounted.')
  },

  destroyed() {
    // Component destroyed
    console.log('Close Tickets component destroyed.');
  },

  created: function () {
    // Component created
    this.fetchClosedTickets({post_id: undefined, page: 1});
  },


  data: function () {
    return {
      user_fields: [
        { key: 'integration', label: this.$t('simple.integration') },
        { key: 'post_id', label: this.$t('ticket.post_id'), sortable: true, class: 'text-break' },
        { key: 'ticket_counts', label: this.$t('ticket.ticket_counts'), sortable: true },
        { key: 'user.ns', label: this.$t('ticket.closed_by'), sortable: true },
        { key: 'reason', label: this.$t('ticket.close_reason'), sortable: true },
        { key: 'created_at', label: this.$t('simple.created'), sortable: true },
      ],
    }
  },

  metaInfo: function () {

    return {
      title: this.$t('page.close_tickets'),
    }

  },

  methods: {

    // Map actions
    ...mapActions('Ticket', [
      'fetchClosedTickets',
      'updateCloseTicketPage',
      'updateFilter',
    ]),

    add: async function () {
      // Show Close Tickets by Post ID modal
      const ticket_close = create(TicketClose);

      if (await ticket_close()) {
        // Refresh account list
        this.fetchClosedTickets({post_id:undefined});
      }

    },


    close_all: async function () {
      // Show Confirmation For Close Tickets
      const confirm_close_ticket = create(TicketCloseConfirm, 'closeType');

      if (await confirm_close_ticket('all')) {
        // Refresh account list
        this.$close(true);
      }

    },

    onFiltered(value) {
      this.updateFilter(value)
      this.fetchClosedTickets({post_id: undefined, page: 1, q: this.filter})
    }

  },

  computed: {

    // Map getters
    ...mapGetters({
      getCloseTickets: 'Ticket/getClosedTickets',
      total: 'Ticket/getTotal',
      current: 'Ticket/getCurrent',
      perpage: 'Ticket/getPerPage',
      filter: 'Ticket/getFilter',

      
    }),

  },

}
</script>