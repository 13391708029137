<template>

                <!-- Page content -->
                <b-col class="shadow-sm px-1">

                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                      <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <b-table sticky-header
                                stacked="md"
                                responsive
                                striped
                                bordered
                                hover
                                show-empty
                                :items="locationReports"
                                :fields="location_type_fields"
                                :filter="filter"
                                class="vh-25">

                        </b-table>

                </b-col>

</template>

<script>

    import {mapGetters} from "vuex";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Customer Types component mounted.')

            console.log('table', this.locationReports)


        },

        destroyed() {
            // Component destroyed
            console.log('Customer Types component destroyed.');
        },

        created: function() {
            // Component created

        },


        data: function() {
            return  {
                location_type_fields: [
                    { key: 'country', label: this.$t('reports.country'), sortable: true },
                    { key: 'state', label: this.$t('reports.state'), sortable: true },
                    { key: 'county', label: this.$t('reports.county'), sortable: true },
                    { key: 'district', label: this.$t('reports.district'), sortable: true },
                    { key: 'category', label: this.$t('default.category'), sortable: true },
                    { key: 'category_entries', label: this.$t('reports.entries'), sortable: true },
                ],
                filter: null,
            }
        },

        metaInfo: function() {

            return {
                title: this.$t('customer.location'),
            }

        },

        methods: {},
        computed:{

            // Map getters
            ...mapGetters({

                locationReports: 'Report/getLocationReports'

            }),

        },


    }
</script>