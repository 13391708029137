import { toArray } from "ant-design-vue/lib/vc-select/util";

var isObject = (arg) => typeof arg === "object";

/**
 *
 * @param {Object} o :: the object being cleaned
 * @param {String|Number} key :: the key or index to be cleaned from `o`
 */
function removeKey(o, key) {
  isObject(o) &&
    Object.keys(o).forEach(function (k) {
      (k === key && o[k] && o[k].length === 0 && delete o[k]) ||
        (o[k] && isObject(o[k]) && removeKey(o[k], key));
    });
  return o;
}

const state = {
  ticket: false,
  customer_tickets: [],
  customer_tasks: [],
  ticket_id: false,
  categories: false,
  tags: false,
  TicketAssigned: false,

  location: false,
  location_country: false,
  location_country_code: false,
  location_county: false,
  location_state: false,
  location_district: false,
  location_zip: false,
  geo_code_lat: false,
  geo_code_lng: false,

  users: false,
  teams: false,

  note: "",

  permission: "",
  ticket_permission: "",

  open_tickets_detail: "",
  closed_tickets: [],
  closed_tickets_post_id: "",
  closed_tickets_reason: "",
  open_tickets_detail_error: "",

  // Pagination
  total: 1,
  current: 1,
  perpage: 1,
  filter: '',
};

// getters
const getters = {
  // Get category
  getCategories: (state) => {
    // Return categories
    return state.categories;
  },

  // Get category
  getTags: (state) => {
    // Return tags
    return state.tags;
  },

  // Get users
  getUsers: (state) => {
    // Return assign users
    return state.users;
  },

  // Get teams
  getTeams: (state) => {
    // Return teams
    return state.teams;
  },

  // Get ticket
  getTicket: (state) => {
    // Return ticket
    return state.ticket;
  },

  // Get ticket title
  getTicketTitle: (state) => {
    // Return title
    return state.ticket.title;
  },

  // Get ticket desc
  getTicketDesc: (state) => {
    // Return desc
    return state.ticket.desc;
  },

  // Get ticket status
  getTicketStatus: (state) => {
    // Return desc
    return state.ticket.status;
  },

  // Get ticket category
  getTicketCategories: (state) => {
    // Return ticket category
    return state.ticket.categories;
  },

  // Get ticket category
  getTicketTags: (state) => {
    // Return ticket tags
    return state.ticket.tags;
  },

  // Get ticket users
  getTransferUsers: (state) => {
    // Return assigned users
    return state.ticket.users;
  },

  // Get ticket teams
  getTransferTeams: (state) => {
    // Return assigned teams
    return state.ticket.teams;
  },

  // Get Ticket location
  getTicketLocation: (state, getters) => {
    // Return Ticket location
    return state.location;
  },

  // Get Ticket country
  getTicketCountry: (state, getters) => {
    // Return Ticket country
    return state.location_country;
  },

  // Get Ticket country code
  getTicketCountryCode: (state, getters) => {
    // Return Ticket country_code
    return state.location_country_code;
  },

  // Get Ticket state
  getTicketState: (state, getters) => {
    // Return Ticket state
    return state.location_state;
  },

  // Get Ticket postal_code
  getTicketPostalCode: (state, getters) => {
    // Return Ticket postal_code
    return state.location_zip;
  },

  // Get Ticket county
  getTicketCounty: (state, getters) => {
    // Return Ticket county
    return state.location_county;
  },

  // Get Ticket district
  getTicketDistrict: (state, getters) => {
    // Return Ticket district
    return state.location_district;
  },

  // Get ticket_id
  getTicketID: (state) => {
    // Return ticket id
    return state.ticket_id;
  },

  // Get ticket list
  getCustomerTickets: (state) => {
    // Return ticket id
    return state.customer_tickets;
  },

  // Get tasks ticket list
  getCustomerTasks: (state) => {
    // Return tasks ticket id
    return state.customer_tasks;
  },

  // Get transfer note
  getTransferNote: (state) => {
    // Return assigned note
    return state.note;
  },

  // Get ticket teams
  getTicketAssigned: (state) => {
    return state.TicketAssigned;
  },

  // Return Permission
  getPermission: (state) => {
    return state.permission;
  },

  // Return Transfer Permission
  getTicketPermission(state) {
    return state.ticket_permission;
  },

  // Return Open Tickets Detail
  getOpenTicketsDetail(state) {
    return state.open_tickets_detail;
  },

  // Return Open Tickets Detail Error
  getOpenTicketsDetailError(state) {
    return state.open_tickets_detail_error;
  },

  // Return Closed Tickets Data
  getClosedTickets(state) {
    return state.closed_tickets;
  },

  // Return Closed Tickets Post ID
  getClosedTicketsPostID(state) {
    return state.closed_tickets_post_id;
  },

  // Return Closed Tickets Reason
  getClosedTicketsReason(state) {
    return state.closed_tickets_reason;
  },

  // Return current page
  getCurrent: (state) => {
    return state.current;
  },

  // Return per page
  getPerPage: (state) => {
    return state.perpage;
  },

  // Return total
  getTotal: (state) => {
    return state.total;
  },
  // Return total
  getFilter: (state) => {
    return state.filter;
  },
};

// mutations
const mutations = {
  // Set ticket id
  setTicketID(state, ticket_id) {
    state.ticket_id = ticket_id;
  },

  // Set ticket
  setTicket(state, ticket) {
    state.ticket = ticket;
  },

  // Set customer tickets
  setCustomerTickets(state, tickets) {
    state.customer_tickets = tickets;
  },

  // Set customer tasks tickets
  setCustomerTasks(state, tasks) {
    state.customer_tasks = tasks;
  },

  // Set categories
  setCategories(state, categories) {
    state.categories = categories;
  },

  // Set tags
  setTags(state, tags) {
    state.tags = tags;
  },

  // Set Ticket location ID
  setTicketLocationID(state, ticket_location_id) {
    state.ticket.location_id = ticket_location_id;
  },

  // Set Ticket location
  setTicketLocation(state, ticket_location) {
    state.location = ticket_location;
  },

  // Set Ticket country
  setTicketCountry(state, ticket_country) {
    state.location_country = ticket_country;
  },

  // Set Ticket country code
  setTicketCountryCode(state, ticket_country_code) {
    state.location_country_code = ticket_country_code;
  },

  // Set Ticket state
  setTicketState(state, ticket_state) {
    state.location_state = ticket_state;
  },

  // Set Ticket postal code
  setTicketPostalCode(state, ticket_postal_code) {
    state.location_zip = ticket_postal_code;
  },

  // Set Ticket county
  setTicketCounty(state, ticket_county) {
    state.location_county = ticket_county;
  },

  // Set Ticket district
  setTicketDistrict(state, ticket_district) {
    state.location_district = ticket_district;
  },

  // Set Ticket GeoCode
  setTicketGeoCode(state, ticket_geo_code) {
    state.geo_code_lat = ticket_geo_code[0];
    state.geo_code_lng = ticket_geo_code[1];
  },

  // Set users
  setUsers(state, users) {
    state.users = users;
  },

  // Set users
  setTicketUpdateId(state, ticket_id) {
    state.ticket.id = ticket_id;
  },

  // Set teams
  setTeams(state, teams) {
    state.teams = teams;
  },

  // Set ticket title
  setTicketTitle(state, title) {
    state.ticket.title = title;
  },

  // Set ticket desc
  setTicketDesc(state, desc) {
    state.ticket.desc = desc;
  },

  // Set Reply Permission
  setTicketStatus(state, status) {
    state.ticket.status = status;
  },

  // Set ticket teams
  setTicketAssigned: (state, result) => {
    // Check if we have users or teams assigned
    state.TicketAssigned = result;
  },

  // Set ticket categories
  setTicketCategories(state, categories) {
    state.ticket.categories = categories;
  },

  // Set ticket tags
  setTicketTags(state, tags) {
    state.ticket.tags = tags;
  },

  // Set ticket users
  setTransferUsers(state, users) {
    state.ticket.users = users;
  },

  // Set Assigned teams
  setTransferTeams(state, teams) {
    state.ticket.teams = teams;
  },

  // Set note
  setTransferNote(state, note) {
    state.note = note;
  },

  // Set Permission
  setPermission(state, permission) {
    state.permission = permission;
  },

  // Set Transfer Permission Data
  setTicketPermission(state, payload) {
    console.log("Data of setTicketPermission : " + payload);
    state.ticket_permission = payload;
  },

  // Set Open Tickets Detail
  setOpenTicketsDetail(state, open_tickets_detail) {
    state.open_tickets_detail = open_tickets_detail;
  },

  // Set Open Tickets Detail Error
  setOpenTicketsDetailError(state, open_tickets_detail_error) {
    state.open_tickets_detail_error = open_tickets_detail_error;
  },

  // Set Closed Tickets Data
  setClosedTickets(state, closed_tickets) {
    state.closed_tickets = closed_tickets;
  },

  // Set Closed Tickets Post ID
  setClosedTicketsPostID(state, closed_tickets_post_id) {
    state.closed_tickets_post_id = closed_tickets_post_id;
  },

  // Set Closed Tickets Reason
  setClosedTicketsReason(state, closed_tickets_reason) {
    state.closed_tickets_reason = closed_tickets_reason;
  },

  // Set total
  setCurrent(state, current) {
    state.current = current;
  },

  // Set per page
  setPerPage(state, perpage) {
    state.perpage = perpage;
  },

  // Set total
  setTotal (state, total){
    state.total = total;
  },
  // Set total
  setFilter (state, filter){
    state.filter = filter;
  },
};

// actions
const actions = {
  updateTicketRefresh({ dispatch, rootGetters }) {
    let customer_id = rootGetters["Customer/getCustomerID"];

    // Update ticket
    dispatch("updateTicket");

    // Refresh messages
    dispatch("Messages/refreshMessages", customer_id, { root: true });
  },

  // Update ticket
  updateTicket({ dispatch, rootGetters, commit, state }) {
    // Start waiting
    dispatch("wait/start", "ticket", { root: true });

    let ticket_api = "/api/v1/inbox/ticket/";

    let crm_save_status = rootGetters["Integrations/getCrmSaveStatus"];

    // Get Ticket
    axios
      .put(ticket_api + state.ticket.id, {
        title: state.ticket.title,
        desc: state.ticket.desc,
        status: state.ticket.status,
        categories: state.ticket.categories,
        location_id: state.ticket.location_id ?? false,
        tags: state.ticket.tags,
        crm_save_status: crm_save_status,

        users: state.ticket.users,
        teams: state.ticket.teams,
      })

      .then((response) => {
        // Stop waiting
        dispatch("wait/end", "ticket", { root: true });

        // Dispatch to inbox
        dispatch("Inbox/updateActive", "customer", { root: true });

        // Show success
        Vue.toasted.success(Vue.i18n.translate("simple.success_info"));

        // Refetch ticket
        dispatch("fetchTicket", state.ticket.id);
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "ticket", { root: true });
      });

    // Reset Crm_Save_Status
    dispatch("Integrations/updateCrmSaveStatus", false, { root: true });

    // Stop waiting
    dispatch("wait/end", "ticket", { root: true });
  },

  // Close Ticket
  async closeTicket({ dispatch, rootGetters, commit, state }, ticket_id) {
    // Start waiting
    dispatch("wait/start", "closeTicket", { root: true });

    let ticket_api = "/api/v1/inbox/ticket/";

    // Get Ticket
    axios
      .put(ticket_api + ticket_id, {
        status: 5,
      })

      .then((response) => {
        // Stop waiting
        dispatch("wait/end", "closeTicket", { root: true });

        // Show success
        Vue.toasted.success(Vue.i18n.translate("simple.success_info"));
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "closeTicket", { root: true });
      });

    // Stop waiting
    dispatch("wait/end", "closeTicket", { root: true });
  },

  // Update ticket location
  updateLocation({ dispatch, commit, state }) {
    // Start waiting
    dispatch("wait/start", "ticket", { root: true });

    let ticket_api = "/api/v1/inbox/customer/location/update/";

    // Get Ticket
    axios
      .put(ticket_api + state.ticket.location_id, {
        location: state.location,
        country: state.location_country,
        country_code: state.location_country_code,
        state: state.location_state,
        postal_code: state.location_zip,
        county: state.location_county,
        district: state.location_district,
        lat: state.geo_code_lat,
        lng: state.geo_code_lng,
      })

      .then((response) => {
        // Stop waiting
        dispatch("wait/end", "ticket", { root: true });

        // Refetch ticket
        dispatch("fetchTicket", state.ticket.id);
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "ticket", { root: true });
      });

    // Stop waiting
    dispatch("wait/end", "ticket", { root: true });
  },

  // Create ticket location
  createTicketLocation({ dispatch, commit, state }) {
    // Start waiting
    dispatch("wait/start", "ticket", { root: true });

    let ticket_api = "/api/v1/inbox/customer/location/create";

    // Get Ticket
    axios
      .post(ticket_api, {
        location: state.location,
        country: state.location_country,
        country_code: state.location_country_code,
        state: state.location_state,
        postal_code: state.location_zip,
        county: state.location_county,
        district: state.location_district,
        lat: state.geo_code_lat,
        lng: state.geo_code_lng,
      })

      .then((response) => {
        if (response.data.success !== false) {
          // Get ticket location ID
          commit("setTicketLocationID", response.data);
        }
        dispatch("updateTicketRefresh");

        // Stop waiting
        dispatch("wait/end", "ticket", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "ticket", { root: true });
      });

    // Stop waiting
    dispatch("wait/end", "ticket", { root: true });
  },

  // Transfer ticket
  async fetchTransfer({ dispatch, commit, state }, ticket_id) {
    // Start waiting
    dispatch("wait/start", "transfer", { root: true });

    // Fetch ticket data
    await dispatch("fetchTicket", ticket_id);

    // Get ticket data
    dispatch("updateTicketData");

    // Stop waiting
    dispatch("wait/end", "transfer", { root: true });
  },

  // Transfer ticket
  async updateTransfer({ dispatch, commit, state, getters, rootGetters }) {
    // Start waiting
    dispatch("wait/start", "transfer", { root: true });

    let ticket_api = "/api/v1/inbox/transfers/";

    let account_id = rootGetters["Accounts/getAccountID"];

    let customer_id = rootGetters["Customer/getCustomerID"];

    let note = getters.getTransferNote;

    // Get Ticket
    await axios
      .put(ticket_api + state.ticket.id, {
        account_id: account_id,
        customer_id: customer_id,
        note: note,
        users: state.ticket.users,
        teams: state.ticket.teams,
      })

      .then((response) => {
        if (response.data.error) {
          commit(
            "setPermission",
            Vue.i18n.translate("permission.update-transfer")
          );
          Vue.toasted.error(Vue.i18n.translate("permission.update-transfer"), {
            duration: 5000,
          });
          console.log(Vue.i18n.translate("permission.update-transfer"));
          // Stop waiting
          dispatch("wait/end", "transfer", { root: true });
          // Refetch ticket
          dispatch("fetchTicket", state.ticket.id);
          // Reset transfer data
          dispatch("resetTransfer");
        } else {
          // Stop waiting
          dispatch("wait/end", "transfer", { root: true });

          // Show success
          Vue.toasted.success(Vue.i18n.translate("simple.success_info"));

          // Refetch ticket
          dispatch("fetchTicket", state.ticket.id);
        }
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "transfer", { root: true });
      });

    // Dispatch to inbox
    //dispatch('Inbox/updateActive', 'ticket', {root:true} );

    // Reset active tab
    dispatch("Reply/resetActiveTab", false, { root: true });

    // Stop waiting
    dispatch("wait/end", "transfer", { root: true });
  },

  // get ticket
  fetchTicket({ dispatch, commit, getters }, ticket_id) {
    // Start waiting
    dispatch("wait/start", "ticket", { root: true });
    if (!ticket_id) {
      let customer_tickets = getters.getCustomerTickets;
      ticket_id = customer_tickets[customer_tickets.length - 1].id;
    }
    commit("setTicketID", ticket_id);
    let ticket_api = "/api/v1/inbox/ticket";

    // Get Ticket
    axios
      .get(ticket_api + "?ticket_id=" + ticket_id)
      .then((response) => {
        if (response.data.error) {
          commit(
            "setPermission",
            Vue.i18n.translate("permission.read-transfer")
          );
          console.log(Vue.i18n.translate("permission.denied"));
        }

        if (response.data.location) {
          // Set Ticket location
          commit("setTicketLocation", response.data.location.formatted_address);

          // Set Ticket Country
          commit("setTicketCountry", response.data.location.country);

          // Set Ticket Country Code
          commit("setTicketCountryCode", response.data.location.country_code);

          // Set Ticket County
          commit("setTicketCounty", response.data.location.county);

          // Set Ticket State
          commit("setTicketState", response.data.location.state);

          // Set Ticket Postal Code
          commit("setTicketPostalCode", response.data.location.zip);

          // Set Ticket District
          commit("setTicketDistrict", response.data.location.district);
        }

        // Assign accounts
        commit("setTicket", response.data);
        dispatch("updateTicketAssigned");
        // Stop waiting
        dispatch("wait/end", "ticket", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "ticket", { root: true });
      });
  },

  // Get Customer Tickets with title-status
  fetchCustomerTickets({ dispatch, commit }, customer_id) {
    // Start waiting
    dispatch("wait/start", "customer_tickets", { root: true });

    let customer_tickets_api = "/api/v1/ticket/list";
    state.customer_tickets = [];
    // Get Ticket
    axios
      .get(customer_tickets_api + "?customer_id=" + customer_id)
      .then((response) => {
        if (response.data.error) {
          commit(
            "setPermission",
            Vue.i18n.translate("permission.read-transfer")
          );
          console.log(Vue.i18n.translate("permission.denied"));
        }

        // Assign accounts
        commit("setCustomerTickets", response.data);

        // Stop waiting
        dispatch("wait/end", "customer_tickets", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "customer_tickets", { root: true });
      });
  },

  // Get Customer Tasks Tickets with title-status (Waiting for Api)
  fetchCustomerTasks({ dispatch, commit }, customer_id) {
    // Start waiting
    dispatch("wait/start", "customer_tasks", { root: true });

    let customer_tasks_api = "/api/v1/tasks/ticket/list";
    state.customer_tasks = [];
    // Get Ticket
    axios
      .get(customer_tasks_api + "?customer_id=" + customer_id)
      .then((response) => {
        if (response.data.error) {
          commit(
            "setPermission",
            Vue.i18n.translate("permission.read-transfer")
          );
          console.log(Vue.i18n.translate("permission.denied"));
        }

        // Assign accounts
        commit("setCustomerTasks", response.data);

        // Stop waiting
        dispatch("wait/end", "customer_tasks", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "customer_tasks", { root: true });
      });
  },

  // Update inbox messages's ticket_id (Waiting for Api)
  async updateInboxTicket(
    { dispatch, commit, state, rootGetters },
    { ticket_id, inbox_id }
  ) {
    // Start waiting
    dispatch("wait/start", "ticket_update", { root: true });

    let inbox_ticket_update_api = "/api/v1/ticket/update-ticket-id";

    let current_page = rootGetters["Messages/getCurrent"];

    let customer_id = state.ticket.customer_id;

    // Change Ticket ID from Inboxes
    await axios
      .post(inbox_ticket_update_api, {
        inbox_id: inbox_id,
        new_ticket_id: ticket_id,
      })

      .then((response) => {
        // Stop waiting
        dispatch("wait/end", "ticket_update", { root: true });

        // Show success
        Vue.toasted.success(Vue.i18n.translate("simple.success_info"));

        // Refetch ticket
        dispatch("fetchTicket", state.ticket.id);

        // Refresh Ticket List
        dispatch("fetchCustomerTickets", customer_id);

        // Refresh Messages
        dispatch(
          "Messages/updateChangesOnMessages",
          { customer_id: customer_id, page: current_page },
          { root: true }
        );
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "ticket_update", { root: true });
      });

    // Stop waiting
    dispatch("wait/end", "ticket_update", { root: true });
  },

  // Detect Closed Tickets
  async fetchClosedTickets({ dispatch, commit }, {post_id, page, q}) {
    // Start waiting

    let query = q ? q : ''
    let current_page = page ? page : 1;

    commit("setClosedTickets", []);

    dispatch("wait/start", "fetch_closed_tickets", { root: true });

    commit("setTicketID", post_id);

    let detect_closed_ticket_api = "/api/v1/ticket/close";

    // Get Ticket
    await axios
      .get(detect_closed_ticket_api + '?page=' + current_page + '&q=' + query)
      .then((response) => {
        // Set current
        commit('setCurrent', response.data.current_page);

        // Set perpage
        commit('setPerPage', response.data.per_page);

        // Set total
        commit('setTotal', response.data.total);
        // Set Open Tickets Detail

        commit("setClosedTickets", response.data.data);

        // Stop waiting
        dispatch("wait/end", "fetch_closed_tickets", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "fetch_closed_tickets", { root: true });
      });
  },

  updateFilter({ commit }, filter) {
    commit("setFilter", filter);
  },

  // Update page
  updateCloseTicketPage({ dispatch, commit, state }, data) {
    // Update confirmations
    dispatch("fetchClosedTickets", {post_id: undefined ,page: data, q: state.filter});
  },

  // get Open tickets with Post ID
  detectOpenTickets({ dispatch, commit }, post_id) {
    // Start waiting
    dispatch("wait/start", "detect_open_tickets", { root: true });
    commit("setTicketID", post_id);
    let detect_open_ticket_api = "/api/v1/ticket/close/";

    // Get Ticket
    axios
      .get(detect_open_ticket_api + post_id)
      .then((response) => {
        // Set Open Tickets Detail
        commit("setOpenTicketsDetail", response.data);

        commit("setOpenTicketsDetailError", "");

        // Stop waiting
        dispatch("wait/end", "detect_open_tickets", { root: true });
      })
      .catch((error) => {
        // Set Open Tickets Detail Error
        commit("setOpenTicketsDetailError", error.response.data.message);

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "detect_open_tickets", { root: true });
      });
  },

  // Close Open tickets with Post ID
  closeOpenTickets({ dispatch, commit }, { post_id, reason }) {
    // Start waiting
    dispatch("wait/start", "close_open_tickets", { root: true });
    commit("setTicketID", post_id);
    let close_open_ticket_api = "/api/v1/ticket/close/";

    // Get Ticket
    axios
      .put(close_open_ticket_api + post_id, {
        reason: reason,
      })
      .then((response) => {
        // Stop waiting
        dispatch("wait/end", "close_open_tickets", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "close_open_tickets", { root: true });
      });
  },

  // Close Open tickets in ll integrations
  closeAllOpenTickets({ dispatch, commit }) {
    // Start waiting
    dispatch("wait/start", "close_open_tickets", { root: true });

    let close_open_ticket_api = "/api/v1/ticket/close-all";

    // Close All Tickets
    axios
      .post(close_open_ticket_api)
      .then((response) => {
        // Stop waiting
        dispatch("wait/end", "close_open_tickets", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "close_open_tickets", { root: true });
      });
  },

  // Set Ticket
  async updateTicketID({ dispatch, commit, state }, ticket_id) {
    // Assign ticket
    dispatch("fetchTicket", ticket_id);

    // Get ticket data
    dispatch("updateTicketData");

    // Dispatch to inbox
    dispatch("Inbox/updateActive", "ticket", { root: true });
  },

  // Set Ticket
  async updateTicketData({ dispatch, state }) {
    // Check if don't have categories
    if (!state.categories) {
      dispatch("getCategories");
    }

    // Check if don't have tags
    if (!state.tags) {
      dispatch("getTags");
    }

    // Check if don't have users
    if (!state.users) {
      dispatch("getUsers");
    }

    // Check if don't have teams
    if (!state.teams) {
      dispatch("getTeams");
    }
  },

  // Get Active Categories List
  async getCategories({ dispatch, commit }) {
    let category_api = "/api/v1/system/category";

    // Get categories
    await axios
      .get(category_api)
      .then((response) => {
        removeKey(response, "children");

        // Set categories
        commit("setCategories", response.data);
      })

      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);
      });
  },

  // Get Active Tags List
  async getTags({ dispatch, commit }) {
    let category_api = "/api/v1/system/tags";

    // Get categories
    await axios
      .get(category_api)
      .then((response) => {
        // Set categories
        commit("setTags", response.data);
      })

      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);
      });
  },

  // Get Active Users List
  async getUsers({ dispatch, commit }) {
    let teams = "/api/v1/system/userteams";

    // Get categories
    await axios
      .get(teams)
      .then((response) => {
        // Set categories
        commit("setUsers", response.data);
      })

      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);
      });
  },

  // Get Active Teams List
  async getTeams({ dispatch, commit }) {
    let teams = "/api/v1/system/teamusers";

    // Get categories
    await axios
      .get(teams)
      .then((response) => {
        // Set categories
        commit("setTeams", response.data);
      })

      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);
      });
  },

  // Set ticket title
  async updateTicketTitle({ commit }, ticket_title) {
    commit("setTicketTitle", ticket_title);
  },

  // Set ticket desc
  async updateTicketDesc({ commit }, ticket_desc) {
    commit("setTicketDesc", ticket_desc);
  },

  // Set ticket status
  async updateTicketStatus({ commit }, ticket_status) {
    commit("setTicketStatus", ticket_status);
  },

  // Set ticket category
  async updateTicketCategories({ commit }, ticket_categories) {
    commit("setTicketCategories", ticket_categories);
  },

  // Set ticket tags
  async updateTicketTags({ commit }, ticket_tags) {
    commit("setTicketTags", ticket_tags);
  },

  // Set ticket location
  async updateTicketLocation({ commit }, ticket_location) {
    commit("setTicketLocation", ticket_location);
  },

  // Set ticket country
  async updateTicketCountry({ commit }, ticket_country) {
    commit("setTicketCountry", ticket_country);
  },

  // Set ticket country code
  async updateTicketCountryCode({ commit }, ticket_country_code) {
    commit("setTicketCountryCode", ticket_country_code);
  },

  // Set ticket state
  async updateTicketState({ commit }, ticket_state) {
    commit("setTicketState", ticket_state);
  },

  // Set ticket postal code
  async updateTicketPostalCode({ commit }, ticket_postal_code) {
    commit("setTicketPostalCode", ticket_postal_code);
  },

  // Set ticket county
  async updateTicketCounty({ commit }, ticket_county) {
    commit("setTicketCounty", ticket_county);
  },

  // Set ticket district
  async updateTicketDistrict({ commit }, ticket_district) {
    commit("setTicketDistrict", ticket_district);
  },

  // Set ticket Geo Code
  async updateTicketGeoCode({ commit }, ticket_geo_code) {
    commit("setTicketGeoCode", ticket_geo_code);
  },

  // Set ticket users
  async updateTransferUsers({ commit }, users) {
    commit("setTransferUsers", users);
  },

  // Set ticket teams
  async updateTransferTeams({ commit }, teams) {
    commit("setTransferTeams", teams);
  },

  // Set transfer note
  async updateTransferNote({ commit }, note) {
    commit("setTransferNote", note);
  },

  updateTicketAssigned({ commit, state }) {
    if (state.ticket && (state.ticket.users || state.ticket.teams)) {
      // Check if we have users or teams assigned
      const result = state.ticket.users.length || state.ticket.teams.length;
      commit("setTicketAssigned", result);
    }
  },

  async updateTicketMacroData({ commit, state }, macro_ticket_data) {
    // Reset State.Ticket Data
    // commit('setTicket', {});

    let status = false;
    // Check if we have macro ticket title
    if (macro_ticket_data.ticket_title) {
      commit("setTicketTitle", macro_ticket_data.ticket_title);
      status = true;
    }
    // Check if we have macro ticket desc
    if (macro_ticket_data.ticket_desc) {
      commit("setTicketDesc", macro_ticket_data.ticket_desc);
      status = true;
    }
    // Check if we have macro ticket status
    if (macro_ticket_data.ticket_status) {
      commit("setTicketStatus", macro_ticket_data.ticket_status);
      status = true;
    }
    // Check if we have macro ticket categories
    if (macro_ticket_data.categories && macro_ticket_data.categories.length) {
      if (state.ticket.categories && state.ticket.categories.length) {
        let categories = new Set(state.categories.map((d) => d.id));
        commit("setTicketCategories", [
          ...state.ticket.categories,
          ...macro_ticket_data.categories.filter((d) => !categories.has(d.id)),
        ]);
      } else {
        commit("setTicketCategories", macro_ticket_data.categories);
      }
      status = true;
    }
    // Check if we have macro ticket tags
    if (macro_ticket_data.tags && macro_ticket_data.tags.length) {
      if (state.ticket.tags && state.ticket.tags.length) {
        let tags = new Set(state.tags.map((d) => d.id));
        commit("setTicketTags", [
          ...state.ticket.tags,
          ...macro_ticket_data.tags.filter((d) => !tags.has(d.id)),
        ]);
      } else {
        commit("setTicketTags", macro_ticket_data.tags);
      }
      status = true;
    }
    // Check if we have macro ticket users
    if (macro_ticket_data.users && macro_ticket_data.users.length) {
      if (state.ticket.users && state.ticket.users.length) {
        let users = new Set(state.users.map((d) => d.id));
        commit("setTransferUsers", [
          ...state.ticket.users,
          ...macro_ticket_data.users.filter((d) => !users.has(d.id)),
        ]);
      } else {
        commit("setTransferUsers", macro_ticket_data.users);
      }
      status = true;
    }
    // Check if we have macro ticket teams
    if (macro_ticket_data.teams && macro_ticket_data.teams.length) {
      if (state.ticket.teams && state.ticket.teams.length) {
        let teams = new Set(state.teams.map((d) => d.id));
        commit("setTransferTeams", [
          ...state.ticket.teams,
          ...macro_ticket_data.teams.filter((d) => !teams.has(d.id)),
        ]);
      } else {
        commit("setTransferTeams", macro_ticket_data.teams);
      }
      status = true;
    }

    commit("Reply/setMacrosStatus", status, { root: true });
  },

  async resetTransfer({ commit }) {
    // Reset users
    commit("setTransferUsers", []);

    // Reset teams
    commit("setTransferTeams", []);

    // Reset note
    commit("setTransferNote", false);
  },

  async clearTransfer({ commit, dispatch }) {
    // Reset users
    commit("setTicketID", false);

    // Reset teams
    commit("setTicket", []);

    // Reset Ticket Assigned
    commit("setTicketAssigned", false);

    // Reset Transfer
    dispatch("resetTransfer");
  },

  async transferCheck({ commit, getters, rootGetters }) {
    // check for active or deactivate transfers teams

    let getTeam = rootGetters["User/getUserTeams"];
    let getUser = rootGetters["User/getUser"];

    if (getters.getTransferTeams || getters.getTransferUsers) {
      let team_ticket = [];
      if (getters.getTransferTeams) {
        getters.getTransferTeams.forEach((obj) => {
          team_ticket.push(obj.id);
        });
        let a1 = team_ticket.some((val) => getTeam.indexOf(val) !== -1);
        if ((!getUser.is_super && !a1) || team_ticket) {
          this.team_result = false;
        } else if (getUser.is_super || a1 || !team_ticket.length) {
          // It means disable = true
          this.team_result = true;
        }
      }

      let user_ticket = [];
      if (getters.getTransferUsers) {
        // check for active or deactivate transfers users
        // let user_ticket = [];
        getters.getTransferUsers.forEach((obj) => {
          user_ticket.push(obj.id);
        });
        let user_loggedIn = [];
        user_loggedIn.push(getUser.id);

        let a11 = user_ticket.some((val) => user_loggedIn.indexOf(val) !== -1);
        if (getUser.is_super || a11 || !user_ticket.length) {
          this.user_result = true;
        } else if ((!getUser.is_super && !a11) || user_ticket) {
          // It means disable = true
          this.user_result = false;
        }
      }
    }
    if (this.team_result || this.user_result) {
      commit("setTicketPermission", "true");
    } else {
      commit("setTicketPermission", "false");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
