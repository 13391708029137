<template>

    <div class="row">

        <div class="col pt-2">


            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-2">

                    <div class="d-flex">

                        <h4>{{ $t('page.roles') }}</h4>

                        <div class="ml-auto align-self-center" v-if="!permission">

                            <b-button :variant="'primary'" @click="add" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add')}}
                            </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.roles_desc') }}</span>

                </div>

                <hr>

                <div class="alert alert-danger text-center" style="max-width: 50%; margin: 0 auto;" v-if="permission">
                    {{ permission }}
                </div>

                <!-- Page content -->
                <div class="px-2" v-else>


                    <v-wait for="roles">


                        <loading slot="waiting"/>

                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                      <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <template v-if="window_width >= 1200">
                            <b-table responsive bordered hover :items="roles" :fields="role_fields" :filter="filter">


                                <!-- Actions -->

                                <template slot="cell(actions)" slot-scope="cell" v-if="cell.item.name !== 'superadministrator'">

                                  <b-dropdown right :text="$t('simple.actions')" size="sm">

                                    <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>

                                    <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>


                                  </b-dropdown>

                                </template>

                                <!-- Created at -->
                                <template slot="cell(created_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                                <!-- Updated at -->
                                <template slot="cell(updated_at)" slot-scope="cell">

                                     {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>

                            </b-table>
                        </template>

                        <template v-else>
                            <b-table 
                                stacked="md"
                                class="d-xl-none"
                                small
                                responsive
                                bordered
                                hover
                                :items="roles" 
                                :fields="role_fields_collapsed" 
                                :filter="filter">

                                <!-- Actions -->

                                <template slot="cell(actions)" slot-scope="cell" v-if="cell.item.name !== 'superadministrator'">

                                    <b-button size="sm" @click="cell.toggleDetails()" class="m-1">
                                        {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
                                    </b-button>
                                
                                    <b-dropdown right :text="$t('simple.actions')" size="sm" class="m-1">
                                    
                                        <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>
                                    
                                        <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>
                                    
                                    </b-dropdown>
                                
                                </template>
                                <template #row-details="row">
                                        <b-table
                                            small
                                            stacked="md"
                                            responsive
                                            bordered
                                            hover
                                            :items="[row.item]"
                                            :fields="role_fields_not_collapsed"
                                        >
                                            <!-- Created at -->
                                            <template slot="cell(created_at)" slot-scope="cell">
                                            
                                                 {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                            </template>
                                        
                                            <!-- Updated at -->
                                            <template slot="cell(updated_at)" slot-scope="cell">
                                            
                                                 {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                            
                                            </template>
                                        </b-table>
                                </template>
                            
                            </b-table>
                        </template>
                    </v-wait>

                </div>
                    

            </div>


        </div>

    </div>

</template>


<script>

    import RoleAdd from './Modal/RoleAdd.vue'
    import RoleEdit from './Modal/RoleEdit.vue'
    import RoleDelete from './Modal/RoleDelete.vue'

    import { create } from 'vue-modal-dialogs'

    export default {

        mounted: function () {
            // Component mounted
            console.log('Role component mounted.')

            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        destroyed() {
            // Component destroyed
            console.log('Roles component destroyed.');
        },

        created: function() {
            // Component created
           this.getRoles();
        },
        

        data: function() {
            return  {
                window_width: window.innerWidth,
                roles_api: '/api/v1/settings/roles/',
                roles: [],
                permission:'',
                role_fields: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'display_name', label: this.$t('simple.display_name'), sortable: true },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true },
                    { key: 'created_at', label: this.$t('simple.created'), sortable: true },
                    { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                role_fields_collapsed: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'display_name', label: this.$t('simple.display_name'), sortable: true },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                role_fields_not_collapsed: [
                    { key: 'created_at', label: this.$t('simple.created') },
                    { key: 'updated_at', label: this.$t('simple.updated') },
                ],
                filter: null,
            }
        },
        
        metaInfo: function() {

            return {
                title: this.$t('page.roles'),
            }
            
        },

        methods: {

            getRoles: async function() {

                // Start waiting
                this.$wait.start('roles');

                // Send request
                await axios.get(this.roles_api)

                  .then((response) => {

                      if(response.data.error){
                          this.permission = Vue.i18n.translate('permission.read_roles');
                          this.roles = [];
                          // End waiting
                          this.$wait.end('roles');
                      }else {

                          // response data
                          this.roles = response.data;

                          //console.log(response);

                          // End waiting
                          this.$wait.end('roles');
                      }

                  })

                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    //console.log(error);

                    // End waiting
                    this.$wait.end('roles');

                });

            },


            add: async function() {
                // Show role add modal
                const role_add = create(RoleAdd);

                if (await role_add() ){
                    // Refresh account list
                    this.getRoles();
                }

            },

            edit: async function(id) {
                // Show role edit modal
                const role_edit = create(RoleEdit, 'id');

                if (await role_edit(id) ){
                    // Refresh role list
                    this.getRoles();
                }

            },

            
            remove: async function(id) {
                
                // Show role delete modal
                const role_delete = create(RoleDelete, 'id');

                if (await role_delete(id) ){
                    // Refresh role list
                    this.getRoles();
                }

            },

            onResize() {
                this.window_width = window.innerWidth;
            },  

        },
        
    }
</script>