<template>
    <b-row>
        <b-col sm="12" md="3" lg="2" class="mb-2" v-b-tooltip.hover :title="title">{{ label }}</b-col>
        <b-col sm="12" md="5">
            <div>
                <b-form-checkbox :checked="value" @change="handleChange" switch value="true" unchecked-value="false">
                    {{ value === "true" || value === true ?
                        'Activated' : 'Disabled' }}
                </b-form-checkbox>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: ['label', 'value', 'title'],

    methods: {
        handleChange(e) {
            this.$emit('handleChange', e);
        }
    }
}
</script>