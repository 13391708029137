<template>

    <div class="row">

        <div class="col pt-2">


            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.gallery') }}</h4>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.gallery_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-5">


                    <v-wait for="images">


                        <loading slot="waiting"/>


                        <b-card-group columns>

                                <b-card v-for="image in images"
                                        v-bind:key="image.id"
                                        :img-src="image.url.public"
                                        img-fluid
                                        img-alt="image"
                                        img-top>
                                    <p class="card-text">
                                        Created: {{ image.created_at }}  | {{ image.height }} x {{ image.width }} <template v-if="image.user"> | Uploaded by: {{ image.user.name }} </template>
                                    </p>

                                </b-card>


                        </b-card-group>


                        <!-- Pagination -->
                        <b-pagination align="center"  :total-rows="pagination.total" v-model="pagination.current_page" :per-page="pagination.per_page" @change="getImages"></b-pagination>


                    </v-wait>

                </div>
                    

            </div>


        </div>

    </div>

</template>

<script>

    export default {

        props: [],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed;
        },

        created: function() {
            // Component created

            // Get Gallery Images
            this.getImages()
        },
        

        data: function() {

            return  {

                gallery_api: '/api/v1/system/gallery',

                images: [],

                pagination: {'current_page' : 0, 'total': 0, 'per_page': 0},

            }

        },
        
        mmetaInfo: function() {

            return {
                title: this.$t('page.gallery'),
            }

        },

        methods: {

            // Get images
            getImages: async function(page = 1){

                // Check if page is equal to current
                this.pagination.current_page = page;

                // Start waiting
                this.$wait.start('images');

                // Get gallery images
                await axios.get(this.gallery_api + '?page=' + this.pagination.current_page)

                  .then((response) => {

                    // response data
                    this.images = response.data.data;

                    // Pagination
                    this.pagination.total = response.data.total;
                    this.pagination.per_page = response.data.per_page;

                    // End waiting
                    this.$wait.end('images');

                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    console.log(error);

                    // End waiting
                    this.$wait.end('images');

                  });

            },

        },
        
    }
</script>