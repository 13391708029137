<template>

    <div class="convoreport" ref="chartdiv" v-if="mapsReport.length">

        <vue-google-heatmap :points="mapsReport"
                            width="100%"
                            :height=400
                            :initial-zoom="3"
                            :lat="40.899241"
                            :lng="28.995582"
                            />

    </div>
    <div class="convoreport " ref="fakechart" v-else>
        <vue-google-heatmap :points="fakemapsReport"
                            class="map-report-overlay"
                            width="100%"
                            :height=400
                            :initial-zoom="3"
                            :lat="40.899241"
                            :lng="28.995582"
                            />
    </div>

</template>

<script>


    // import * as am5 from "@amcharts/amcharts5";
    // import * as am5map from "@amcharts/amcharts5/map";
    // import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
    // import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';


    import VueGoogleHeatmap from 'vue-google-heatmap';
    import {mapGetters} from "vuex";

    Vue.use(VueGoogleHeatmap, {
        apiKey: "AIzaSyDpIk7PKY7AM66Pbk7dZT8mOJL7Sdq7fYU"
    });

    export default {

        mounted() {


            console.log('heatmap', this.teamTouchPerformance)

            // let root;   

            // if (this.mapsReport.length > 0) {
            //     root = am5.Root.new(this.$refs.chartdiv);
            // } else {
            //     root = am5.Root.new(this.$refs.fakechart)
            // }   
            // root.setThemes([am5themes_Animated.new(root)]); 


            // let data = this.mapsReport.length ? this.mapsReport : this.fakemapsReport;    


            // let chart = root.container.children.push(am5map.MapChart.new(root, {
            //     panX: "rotateX",
            //     panY: "none",
            //     projection: am5map.geoNaturalEarth1()
            // }));

            // let polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            //     geoJSON: am5geodata_worldLow,
            //     exclude: ["AQ"]
            // }));

            // let pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));

            // pointSeries.data.setAll(data)

            // pointSeries.bullets.push(function () {
            //     return am5.Bullet.new(root, {
            //         sprite: am5.Circle.new(root, {
            //             radius: 5,
            //             fill: am5.color(0xffba00)
            //         })
            //     });
            // });


            // this.root = root;


        },

        beforeDestroy() {

            // if (this.root) {
            //     this.root.dispose();
            // }
        },

        // Data Model = {lat: value, lng: value},
        data() {
            return {
            }
        },

        methods: {},

        computed: {
            // Map getters
            ...mapGetters({
                mapsReport: 'Report/getMapsReport',
                fakemapsReport: 'Report/getFakeMapsReport',

            })
        },

    }
</script>


