<template>

    <!-- Reports Area -->
    <div class="convoreport" ref="chartdiv" v-if="riskHeatmap.length">
    </div>
    <div class="convoreport report-overlay" ref="fakechart" v-else>
    </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';



    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

    // /* Imports */
    // import * as am4core from "@amcharts/amcharts4/core";
    // import * as am4charts from "@amcharts/amcharts4/charts";
    // import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Chart code */
    // Themes begin
    // am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        mounted() {

            let root;   

            console.log('xy', this.riskHeatmap)

            let fakeRiskHeatmap = this.user.language === 'tr' ? this.fakeRiskHeatmapTr : this.fakeRiskHeatmapEn

            if (this.riskHeatmap.length > 0) {
                root = am5.Root.new(this.$refs.chartdiv);
            } else {
                root = am5.Root.new(this.$refs.fakechart)
            }   
            root.setThemes([am5themes_Animated.new(root)]); 

            let data = this.riskHeatmap.length ? this.riskHeatmap : fakeRiskHeatmap;    


            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: "none",
                    wheelY: "none",
                    layout: root.verticalLayout
                })
            );

            let yRenderer = am5xy.AxisRendererY.new(root, {
                visible: false,
                minGridDistance: 20,
                inversed: true
            });

            yRenderer.grid.template.set("visible", false);

            let yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                    renderer: yRenderer,
                    categoryField: "category"
                })
            );

            let xRenderer = am5xy.AxisRendererX.new(root, {
                visible: false,
                minGridDistance: 30,
                inversed: true
            });


            xRenderer.grid.template.set("visible", false);
            
            let xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    renderer: xRenderer,
                    categoryField: "category"
                })
            );

            let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    calculateAggregates: true,
                    stroke: am5.color(0xffffff),
                    clustered: false,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    categoryXField: "x",
                    categoryYField: "y",
                    valueField: "value"
                })
            );

            series.columns.template.setAll({
                tooltipText: "{value}",
                strokeOpacity: 1,
                strokeWidth: 2,
                cornerRadiusTL: 5,
                cornerRadiusTR: 5,
                cornerRadiusBL: 5,
                cornerRadiusBR: 5,
                width: am5.percent(100),
                height: am5.percent(100),
                templateField: "columnSettings"
            });

            let circleTemplate = am5.Template.new({});

            series.set("heatRules", [{
                target: circleTemplate,
                min: 10,
                max: 35,
                dataField: "value",
                key: "radius"
            }]);

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(
                        root,
                        {
                            fill: am5.color(0x000000),
                            fillOpacity: 0.5,
                            strokeOpacity: 0
                        },
                        circleTemplate
                    )
                });
            });

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        fill: am5.color(0xffffff),
                        populateText: true,
                        centerX: am5.p50,
                        centerY: am5.p50,
                        fontSize: 10,
                        text: "{value}"
                    })
                });
            });

            series.data.setAll(data);


            yAxis.data.setAll([
                { category: this.$t('ticket.status_open') },
                { category: this.$t('ticket.status_replied') },
                { category: this.$t('ticket.status_transferred') },
                { category: this.$t('ticket.status_awaiting') },
                { category: this.$t('ticket.status_closed') },
                { category: this.$t('ticket.status_unknown') },
            ]);

            xAxis.data.setAll([
                { category: "Critical" },
                { category: "Bad" },
                { category: "Medium" },
                { category: "Good" },
                { category: "Best" }
            ]);


            if( this.riskHeatmap.length > 0) {
                am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                    // dataSource: data,
                    filePrefix: "convoTypes",
                });
            }

            this.root = root;












            // console.log(this.riskHeatmap);
            // Create chart
            // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
            // chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

            // chart.maskBullets = false;

            // let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            // let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

            // xAxis.dataFields.category = "x";
            // yAxis.dataFields.category = "y";

            // xAxis.renderer.grid.template.disabled = true;
            // xAxis.renderer.minGridDistance = 40;

            // yAxis.renderer.grid.template.disabled = true;
            // yAxis.renderer.inversed = true;
            // yAxis.renderer.minGridDistance = 30;

            // let series = chart.series.push(new am4charts.ColumnSeries());
            // series.dataFields.categoryX = "x";
            // series.dataFields.categoryY = "y";
            // series.dataFields.value = "value";
            // series.sequencedInterpolation = true;
            // series.defaultState.transitionDuration = 3000;

            // // Set up column appearance
            // let column = series.columns.template;
            // column.strokeWidth = 2;
            // column.strokeOpacity = 1;
            // column.stroke = am4core.color("#ffffff");
            // column.tooltipText = "{x}, {y}: {value.workingValue.formatNumber('#.')}";
            // column.width = am4core.percent(100);
            // column.height = am4core.percent(100);
            // column.column.cornerRadius(6, 6, 6, 6);
            // column.propertyFields.fill = "color";

            // // Set up bullet appearance
            // let bullet1 = series.bullets.push(new am4charts.CircleBullet());
            // //bullet1.circle.propertyFields.radius = "value";
            // bullet1.circle.fill = am4core.color("#000");
            // bullet1.circle.strokeWidth = 0;
            // bullet1.circle.fillOpacity = 0.7;
            // bullet1.interactionsEnabled = false;

            // let bullet2 = series.bullets.push(new am4charts.LabelBullet());
            // bullet2.label.text = "{value}";
            // bullet2.label.fill = am4core.color("#fff");
            // bullet2.zIndex = 1;
            // bullet2.fontSize = 11;
            // bullet2.interactionsEnabled = false;

            // // Data
            // // Data Model - y= {Open/Replied/Transferred/Awaiting/Closed}
            // // x = Best( If Action Time < 60 Minutes) - Good ( If 60 Minutes<AT<180 Minutes) - Medium( If 180M <AT<24 Hours)
            // // Bad( If 24 Hours <AT< 5 Days) - Very Bad( If 5 Days<AT)
            // chart.data = this.riskHeatmap;

            // console.log('-------------------------------');
            // console.log(this.riskHeatmap);

            // let baseWidth = Math.min(chart.plotContainer.maxWidth, chart.plotContainer.maxHeight);
            // let maxRadius = baseWidth / Math.sqrt(chart.data.length) / 2 - 2; // 2 is jast a margin
            // series.heatRules.push({min: 10, max: maxRadius, property: "radius", target: bullet1.circle});


            // chart.plotContainer.events.on("maxsizechanged", function () {
            //     let side = Math.min(chart.plotContainer.maxWidth, chart.plotContainer.maxHeight);
            //     bullet1.circle.clones.each(function (clone) {
            //         clone.scale = side / baseWidth;
            //     })
            // })

            // // Export Menu to chart's
            // chart.exporting.menu = new am4core.ExportMenu();
            // // File names will be myExport.png, myExport.pdf, etc
            // chart.exporting.filePrefix = "convoHeatmap";


        },

        beforeDestroy() {
            // if (this.chart) {
            //     this.chart.dispose();
            // }

            if (this.root) {
                this.root.dispose();
            }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {

            // Map getters
        ...mapGetters({
                riskHeatmap: 'Report/getRiskHeatmap',
                fakeRiskHeatmapEn: 'Report/getFakeRiskHeatmapEn',
                fakeRiskHeatmapTr: 'Report/getFakeRiskHeatmapTr',
                user: 'User/getUser',
            })

            }
    }
</script>