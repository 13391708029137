<template>

    <v-wait for="ticket">

        <loading slot="waiting"/>

        <form @submit.prevent="validate_edit" class="form align-items-center">

            <div class="border-bottom">
              <!-- Info -->
              <span class="panel-title text-uppercase text-muted" id="ticket">
                          #{{ ticket.case_id }} </span>

              <b-tooltip target="ticket">
                <span>{{ $t('ticket.id') }}</span>
              </b-tooltip>

              <!-- Ticket Info -->
              <font-awesome-icon size="xs" id="created" :icon="'info-circle'" class="plus__icon"/>
              <b-tooltip target="created">
                <div class="font-weight-light ml-1">{{'Created Time: ' + moment(ticket.created_at).fromNow() + ' | ' + moment(moment.utc(ticket.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}</div>
                <div class="font-weight-light ml-1">{{'Last Update: ' + moment(ticket.updated_at).fromNow() + ' | ' + moment(moment.utc(ticket.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}</div>
              </b-tooltip>

              <!-- Location -->
              <ticket-location v-bind:location="ticket.location"></ticket-location>


              <!-- Status -->
              <div class="form-group mt-2 my-0">

                <!--   <label for="status" class="text-body">{{ $t('ticket.status') }}</label> -->

                  <b-form-select size="sm" v-model="ticket_status" :disabled="disabled_areas.ticket_edit">
                    <option v-for="status in statuses" :key="status.id" :value="status.id">{{ $t('ticket.' + status.name) }}</option>
                  </b-form-select>
                </div>


              </div>

            <div class="mt-2">

                <!-- Title -->
              <div class="form-group">

                <label for="title" class="text-body">{{ $t('ticket.title') }}</label>
                <input
                    :disabled="disabled_areas.ticket_edit"
                    class="form-control"
                    type="text"
                    id="title"
                    name="title"
                    v-validate="'max:25'"
                    v-model="ticket_title"
                >
                <small v-show="errors.has('title')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('title') }}</small>

              </div>

              <!-- Description -->
              <div id="desc" class="form-group">

                <label for="desc" class="text-body">{{ $t('ticket.desc') }}</label>

                <b-form-textarea
                    :disabled="disabled_areas.ticket_edit"
                    :rows="2"
                    :max-rows="5"
                    id="desc"
                    name="desc"
                    v-validate="'max:65535'"
                    v-model="ticket_desc">
                </b-form-textarea>

                <small v-show="errors.has('desc')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('desc') }}</small>

              </div>

              <!-- Categories  -->
              <div id="category" class="form-group">

                <label for="category" class="text-body">{{ $t('ticket.category') }}</label>

                <template v-if="categories">

                  <treeselect
                      :disabled="disabled_areas.ticket_edit"
                      v-model="ticket_categories"
                      :options="categories"
                      :multiple="true"
                      :disable-branch-nodes="(getIntActive === true)"
                      :flat="true"
                      :autoSelectAncestors="true"
                      :autoDeselectDescendants="true"
                      value-format="object"
                      :placeholder="$t('ticket.category_select')"
                      :flatten-search-results="true"
                      :normalizer="normalizer"
                  >
                    <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                      {{ node.raw.name }}
                    </label>

                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }} </div>

                  </treeselect>

                </template>


              </div>

              <!-- Tags  -->
              <div id="categories" class="form-group">

                <label for="categories" class="text-body">{{ $t('ticket.tags') }}</label>

                <template v-if="tags">


                  <treeselect
                      :disabled="disabled_areas.ticket_edit"
                      v-model="ticket_tags"
                      :options="tags"
                      :multiple="true"
                      value-format="object"
                      :flatten-search-results="true"
                      :placeholder="$t('ticket.tag_select')"
                      :normalizer="normalizer"
                  >
                    <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                      {{ node.raw.name }}
                    </label>

                    <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }} </div>

                  </treeselect>

                </template>

              </div>

              <!-- Ticket Location -->
              <div class="form-group mb-2">

                <label for="location" class="text-body">{{ $t('customer.location') }}</label>

                <gmap-autocomplete
                    :disabled="disabled_areas.ticket_edit"
                    ref="autocomplete"
                    id="location"
                    name="location"
                    @place_changed="setPlace"
                    enable-geolocation="true"
                    class="form-control"
                ></gmap-autocomplete>

              </div>

              <div v-if="this.loc.country || ticket.location">

                {{ ticket_location }}

                <br>

                <!-- Adress - Location Menu  -->
                <div class="accordion" role="tablist">
                  <b-card no-body class="my-2">
                    <b-button block size="sm" v-b-toggle.accordion variant="outline-secondary">{{ $t('customer.address') }}</b-button>
                    <b-collapse id="accordion" accordion="my-accordion" role="tabpanel">
                      <div class="m-2">

                        <!-- Country  -->
                        <div class="form-group">

                          <label for="country" class="text-body">{{ $t('customer.country') }}</label>

                          <input
                              :disabled="disabled_areas.ticket_edit"
                              class="form-control"
                              type="text"
                              id="country"
                              name="country"
                              v-model="ticket_country"
                          >
                        </div>
                        <!-- Country Code  -->
                        <div class="form-group">

                          <label for="country_code" class="text-body">{{ $t('customer.country_code') }}</label>

                          <input
                              :disabled="disabled_areas.ticket_edit"
                              class="form-control"
                              type="text"
                              id="country_code"
                              name="country_code"
                              v-model="ticket_country_code"
                          >


                        </div>
                        <!-- State Code  -->
                        <div class="form-group">

                          <label for="state" class="text-body">{{ $t('customer.state_code') }}</label>

                          <input
                              :disabled="disabled_areas.ticket_edit"
                              class="form-control"
                              type="text"
                              id="state"
                              name="state"
                              v-model="ticket_state"
                          >


                        </div>
                        <!-- Postal Code  -->
                        <div class="form-group">

                          <label for="postal_code" class="text-body">{{ $t('customer.postal_code') }}</label>

                          <input
                              :disabled="disabled_areas.ticket_edit"
                              class="form-control"
                              type="text"
                              id="postal_code"
                              name="postal_code"

                              v-model="ticket_postal_code"
                          >


                        </div>
                        <!-- County Code  -->
                        <div class="form-group">

                          <label for="county" class="text-body">{{ $t('customer.county') }}</label>

                          <input
                              :disabled="disabled_areas.ticket_edit"
                              class="form-control"
                              type="text"
                              id="county"
                              name="county"

                              v-model="ticket_county"
                          >


                        </div>
                        <!-- County District  -->
                        <div class="form-group">

                          <label for="district" class="text-body">{{ $t('customer.district') }}</label>

                          <input
                              :disabled="disabled_areas.ticket_edit"
                              class="form-control"
                              type="text"
                              id="district"
                              name="district"

                              v-model="ticket_district"
                          >


                        </div>

                      </div>
                    </b-collapse>
                  </b-card>
                </div>

              </div>

              <!-- Transfer Menu  -->
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-2">
                  <b-button block v-b-toggle.accordion-2 size="sm" variant="outline-secondary">{{ $t('ticket.transfer') }}</b-button>
                  <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <div class="m-2">
                      <!-- Users  -->
                      <div class="form-group">

                        <label for="categories" class="text-body">{{ $t('default.users') }}</label>

                        <template v-if="users">


                          <treeselect
                              :disabled="disabled_areas.ticket_edit"
                              v-model="ticket_users"
                              :options="users"
                              :multiple="true"
                              value-format="object"
                              :placeholder="$t('ticket.select_users')"
                              :normalizer="normalizer"
                          >
                            <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                              {{ node.raw.name }}
                            </label>

                            <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }} </div>

                          </treeselect>

                        </template>

                      </div>

                      <!-- Teams  -->
                      <div class="form-group">

                        <label for="categories" class="text-body">{{ $t('default.teams') }}</label>

                        <template v-if="teams">

                          <treeselect
                              :disabled="disabled_areas.ticket_edit"
                              v-model="ticket_teams"
                              :options="teams"
                              :multiple="true"
                              value-format="object"
                              :placeholder="$t('ticket.select_teams')"
                              :normalizer="normalizer"
                          >
                            <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                              {{ node.raw.name }}
                            </label>

                            <div slot="value-label" slot-scope="{ node }"> {{ node.raw.name }} </div>

                          </treeselect>

                        </template>

                      </div>
                    </div>
                  </b-collapse>
                </b-card>
              </div>
              <hr>
              <div class="center">

                <!-- Buttons -->
                <b-button-group size="sm">
                  <!-- if Integration Active -->
                  <b-dropdown v-if="getIntActive"
                              right
                              split
                              split-variant="success"
                              variant="outline-success"
                              menu-class="w-50"
                              :text="$t('simple.save')"
                              @click="validate_edit"
                              :disabled="disabled_areas.ticket_edit"
                              >
                    <b-dropdown-item v-if="true" @click="intTaskEdit">{{ $t('simple.crm') }}</b-dropdown-item>
                  </b-dropdown>

                  <!-- if Integration Passive -->
                  <b-button v-if="!getIntActive" variant="success" @click="validate_edit" :disabled="getIsReply || disabled_areas.ticket_edit">{{ $t('simple.save') }}</b-button>
                  <!-- Cancel Button -->
                  <b-button class="ml-1" variant="danger" @click="setCustomer(ticket.customer_id)" :disabled="getIsReply">{{ $t('simple.cancel') }}</b-button>

                </b-button-group>

              </div>

            </div>
        </form>
    </v-wait>
</template>

<script>

    import { mapGetters, mapActions, mapMutations } from 'vuex';

    import Treeselect from '@riophae/vue-treeselect';

    import { getTicketStatuses } from '../../utils/ticket.js';

    export default {

        components: { Treeselect },

        mounted: function () {
            // Component mounted
            console.log('Ticket render mounted');
        },

        destroyed() {
            // Component destroyed
            console.log('Ticket render mounted destroyed.');
            // this.updateIsInboxExpanded(true);
            // this.updateIsMessagesExpanded(false);
        },

        created: function() {
            // Component created
            this.fetchCrmIntegration();
        },

        data: function() {

            return {

                loc: {},

                moment: this.$moment,

                statuses: getTicketStatuses,

                lat: 0,
                lng: 0,

            }

        },

        methods: {
            ...mapActions('Ticket', [
                // 
                'updateTicketRefresh',

                // Ticket update data
                'updateTicketTitle',
                'updateTicketDesc',
                'updateTicketStatus',
                'updateTicketCategories',
                'updateTicketTags',
                'updateTicketLocation',
                'createTicketLocation',
                'updateLocation',
                'updateTicketCountry',
                'updateTicketCountryCode',
                'updateTicketState',
                'updateTicketPostalCode',
                'updateTicketCounty',
                'updateTicketDistrict',
                'updateTicketGeoCode',
                'updateTransferUsers',
                'updateTransferTeams',

            ]),

            // Customer
            ...mapActions('Customer', [
                'setCustomer',
            ]),

            // Integration
            ...mapActions('Integrations', [
                'fetchCrmIntegration',
                'updateCrmSaveStatus',
            ]),

            // ...mapActions('Inbox', [
            //     'updateIsInboxExpanded',
            //     'updateIsMessagesExpanded',
            // ]),

            normalizer: function(node){
                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                }
            },

            validate_edit: function() {
                // validate all fields
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        // Send it to account edit function
                        if(!this.ticket.location && this.loc.country) {
                            this.createTicketLocation();
                        }else if(this.ticket.location && this.loc.country ){
                            this.updateLocation();
                            this.updateTicketRefresh();
                        }else if(this.ticket.location && !this.loc.country){
                            this.updateLocation();
                            this.updateTicketRefresh();
                        }else{
                            this.updateTicketRefresh();
                        }
                    }
                });
            },

            // If integration is active, task can created-edited
            intTaskEdit: function() {
              if(this.getIntActive){

                // Save & Send to CRM
                this.updateCrmSaveStatus(true);

                // Ticket edit process
                this.validate_edit();

              }
            },


            setPlace: function(addressData){

                const address_components = addressData.address_components;
                this.loc = {};
                address_components.map((value, index) => {
                    value.types.map((value2, index2) => {
                        this.loc[value2] = value.long_name;
                        if (value2==='country')
                            this.loc.country_id = value.short_name;
                        if (value2==='administrative_area_level_1')
                            this.loc.state_code = value.short_name;
                    })
                });

                this.updateTicketLocation(addressData.formatted_address);
                this.updateTicketCountry(this.loc.country);
                this.updateTicketCountryCode(this.loc.country_id);
                this.updateTicketPostalCode(this.loc.postal_code);
                this.updateTicketState(this.loc.state_code);
                this.updateTicketCounty(this.loc.administrative_area_level_2);
                this.updateTicketDistrict(this.loc.administrative_area_level_4);

                // Set Geocode's(viewport) half
                this.lat = addressData.geometry.location.lat();
                this.lng = addressData.geometry.location.lng();

                this.updateTicketGeoCode([this.lat, this.lng]);

            }

        },


        computed: {

        // Map getters
        ...mapGetters({

          ticket: 'Ticket/getTicket',
          categories: 'Ticket/getCategories',
          tags: 'Ticket/getTags',
          users: 'Ticket/getUsers',
          teams: 'Ticket/getTeams',

          // Ticket data
          getTicketTitle: 'Ticket/getTicketTitle',
          getTicketDesc: 'Ticket/getTicketDesc',
          getTicketStatus: 'Ticket/getTicketStatus',
          getTicketCategories: 'Ticket/getTicketCategories',
          getTicketTags: 'Ticket/getTicketTags',
          getTicketLocation: 'Ticket/getTicketLocation',
          getTicketCountry: 'Ticket/getTicketCountry',
          getTicketCountryCode: 'Ticket/getTicketCountryCode',
          getTicketState: 'Ticket/getTicketState',
          getTicketPostalCode: 'Ticket/getTicketPostalCode',
          getTicketCounty: 'Ticket/getTicketCounty',
          getTicketDistrict: 'Ticket/getTicketDistrict',
          getTransferUsers: 'Ticket/getTransferUsers',
          getTransferTeams: 'Ticket/getTransferTeams',

          // Integration Active
          getIntActive: 'Integrations/getIntActive',
          // updateTask: 'Integrations/updateTask',

          getIsReply: 'Reply/getIsReply',

          disabled_areas: 'Accounts/getDisabledAreas',



        }),

        // Ticket title
        ticket_title: {

          // getter
          get: function () {
            return this.getTicketTitle;
          },

          // setter
          set: function (value) {
            // Update ticket title
            this.updateTicketTitle(value);
          },

        },

        // Ticket desc
        ticket_desc: {

          // getter
          get: function () {
            return this.getTicketDesc;
          },

          // setter
          set: function (value) {
            // Update ticket desc
            this.updateTicketDesc(value);
          },

        },

        // Ticket status
        ticket_status: {

          // getter
          get: function () {
            return this.getTicketStatus;
          },

          // setter
          set: function (value) {
            // Update ticket desc
            this.updateTicketStatus(value);
          },

        },

        // Ticket categories
        ticket_categories: {

          // getter
          get: function () {
            return this.getTicketCategories;
          },

          // setter
          set: function (value) {
            // Update ticket category
            this.updateTicketCategories(value);
          },

        },

        // Ticket tag
        ticket_tags: {

          // getter
          get: function () {
            return this.getTicketTags;
          },

          // setter
          set: function (value) {
            // Update ticket tag
            this.updateTicketTags(value);
          },

        },

        // Ticket Location
        ticket_location: {

          // getter
          get: function () {
            // Get Ticket Location
            return this.getTicketLocation;
          },

          // setter
          set: function (value) {
            // Update Ticket Location
            this.updateTicketLocation(value);
          },

        },

        // Ticket Location
        ticket_country: {

          // getter
          get: function () {
            // Get Ticket country
            return this.getTicketCountry;
          },

          // setter
          set: function (value) {
            // Update Ticket country
            this.updateTicketCountry(value);
          },

        },

        // Ticket Country Code
        ticket_country_code: {

          // getter
          get: function () {
            // Get Ticket country
            return this.getTicketCountryCode;
          },

          // setter
          set: function (value) {
            // Update Ticket country
            this.updateTicketCountryCode(value);
          },

        },

        // Ticket state
        ticket_state: {

          // getter
          get: function () {
            // Get Ticket state
            return this.getTicketState;
          },

          // setter
          set: function (value) {
            // Update Ticket state
            this.updateTicketState(value);
          },

        },

        // Ticket state
        ticket_postal_code: {

          // getter
          get: function () {
            // Get Ticket postal code
            return this.getTicketPostalCode;
          },

          // setter
          set: function (value) {
            // Update Ticket postal code
            this.updateTicketPostalCode(value);
          },

        },

        // Ticket county
        ticket_county: {

          // getter
          get: function () {
            // Get Ticket county
            return this.getTicketCounty;
          },

          // setter
          set: function (value) {
            // Update Ticket county
            this.updateTicketCounty(value);
          },

        },

        // Ticket district
        ticket_district: {

          // getter
          get: function () {
            // Get Ticket district
            return this.getTicketDistrict;
          },

          // setter
          set: function (value) {
            // Update Ticket district
            this.updateTicketDistrict(value);
          },

        },

        // Ticket users
        ticket_users: {

          // getter
          get: function () {
            return this.getTransferUsers;
          },

          // setter
          set: function (value) {
            // Update ticket users
            this.updateTransferUsers(value);
          },

        },

        // Ticket teams
        ticket_teams: {

          // getter
          get: function () {
            return this.getTransferTeams;
          },

          // setter
          set: function (value) {
            // Update ticket teams
            this.updateTransferTeams(value);
          },

        },

      },

    }
</script>