<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('page.variables_edit')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <v-wait for="edit">

                <loading slot="waiting"/>

                <form @submit.prevent="validate_edit">


                    <template v-for="item in this.variables">

                        <div class="form-group">

                            <label for="item.name"><b>{{item.name}}</b> - {{item.desc}}</label>

                            <input
                                    class="form-control"
                                    type="text"
                                    :id="item.name"
                                    :name="item.desc"
                                    v-validate="'required|max:15'"
                                    v-model="item.value"
                            >

                            <small v-show="errors.has('name')" class="form-text text-danger"><font-awesome-icon :icon="'times-circle'" /> {{ errors.first('name') }}</small>

                        </div>

                    </template>



                </form>

            </v-wait>

        </div>

            <div class="modal-footer">

                <b-button @click="$close(false)" variant="secondary">{{ $t('simple.cancel') }}</b-button>

                <b-button @click="validate_add()" variant="primary">{{ $t('simple.save') }}</b-button>

            </div>

        </div>
      </div>
    </div>
</template>
<script>

    import {mapMutations} from "vuex";

    export default {

        mounted: function () {
            // Component mounted
            console.log('Macros Variables Modal mounted');
        },

        destroyed() {
            console.log('Macros Variables Modal destroyed.');
        },

        data: function() {
             return  {
                variables : JSON.parse(this.cas_variables),
            }
        },

        methods: {

            // Macros Variables Setting
            ...mapMutations('Reply', [
                'setMacrosVariables',
            ]),

            validate_add: async function() {

                console.log('this.variables');
                console.log(this.variables);
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.setMacrosVariables(this.variables);
                        // Close modal
                        this.$close(true);
                    }
                });
            },
        },
    }
</script>