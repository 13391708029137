<template>

        <!-- Information Area -->
        <div class="container shadow-widget p-2">
                <b-row>
                        <b-col>
                                <b-card-group column>
                                        <v-wait for="responseTime" class="col px-1" v-b-tooltip.hover :title="$t('reports.first_response_desc')">
                                                <loading-messages slot="waiting"/>
                                        <b-card class="Information-card justify-content-center mb-1" >
                                                <b-card-header
                                                        class="information-card-header justify-content-center align-items-center"
                                                        header-bg-variant="danger"
                                                        header-text-variant="white"
                                                        >
                                                        <b>{{ $t('reports.first_response') }}</b>
                                                </b-card-header>

                                                <b-card-title >
                                                        <font-awesome-icon :style="{ color: '#e6492d' }" :icon="'clock'" class="mx-1 information-title-icon" /> 
                                                        <span class="message-header-data text-dark mx-1" v-if="firstResponseAverage[0]">
                                                                {{firstResponseAverage[0].toFixed(0)}}
                                                        </span>
                                                        <span class="message-header-data text-dark mx-1" v-else>
                                                                0
                                                        </span>
                                                </b-card-title>
                                        </b-card>
                                        </v-wait>
                                        <v-wait for="fetchInformations" class="col px-1" v-b-tooltip.hover :title="$t('reports.transferred_desc')">
                                                <loading-messages slot="waiting"/>
                                        <b-card class="Information-card justify-content-center mb-1">
                                                <b-card-header
                                                        class="information-card-header justify-content-center align-items-center"
                                                        header-bg-variant="primary"
                                                        header-text-variant="white"
                                                        >
                                                        <b>{{ $t('ticket.status_transferred') }}</b>
                                                </b-card-header>

                                                        <b-card-title>
                                                                <font-awesome-icon :style="{ color: '#1665d8' }" :icon="'reply'" class="mx-1 information-title-icon"/>  
                                                                <span class="message-header-data text-dark mx-1" v-if="informations[0]"> 
                                                                        {{informations[0]}}
                                                                </span>
                                                                <span class="message-header-data text-dark mx-1" v-else> 
                                                                        0
                                                                </span>
                                                        </b-card-title>
                                        </b-card>
                                        </v-wait>
                                        <v-wait for="fetchInformations" class="col px-1" v-b-tooltip.hover :title="$t('reports.unresolved_desc')">
                                                <loading-messages slot="waiting"/>
                                        <b-card class="Information-card justify-content-center mb-1">
                                                <b-card-header
                                                        class="information-card-header justify-content-center align-items-center"
                                                        header-bg-variant="info"
                                                        header-text-variant="white"
                                                        >
                                                        <b>{{ $t('simple.unresolved') }}</b>
                                                </b-card-header>

                                                        <b-card-title>
                                                                <font-awesome-icon :style="{ color: '#17a2b8' }" :icon="'user-times'" class="mx-1 information-title-icon" />
                                                                <span class="message-header-data text-dark mx-1" v-if="informations[1]">
                                                                        {{informations[1]}}
                                                                </span>
                                                                <span class="message-header-data text-dark mx-1" v-else>
                                                                        0                                                        
                                                                </span>
                                                        </b-card-title>
                                        </b-card>
                                        </v-wait>
                                        <v-wait for="fetchInformations" class="col px-1" v-b-tooltip.hover :title="$t('reports.solved_desc')">
                                                <loading-messages slot="waiting"/>
                                        <b-card class="Information-card justify-content-center mb-1">
                                                <b-card-header
                                                        class="information-card-header justify-content-center align-items-center"
                                                        header-bg-variant="success"
                                                        header-text-variant="white"
                                                        >
                                                        <b>{{ $t('simple.solved') }}</b>
                                                </b-card-header>

                                                <b-card-title>
                                                        <font-awesome-icon :icon="'thumbs-up'" :style="{ color: '#38b249' }" class="mx-1 information-title-icon"/>
                                                        <span class="message-header-data text-dark mx-1" v-if="informations[2]">
                                                                {{informations[2]}}
                                                        </span>
                                                        <span class="message-header-data text-dark mx-1" v-else>
                                                                0
                                                        </span>
                                                </b-card-title>
                                        </b-card>
                                        </v-wait>
                                        <v-wait for="fetchInformations" class="col px-1" v-b-tooltip.hover :title="$t('reports.open_desc')">
                                                <loading-messages slot="waiting"/>
                                        <b-card class="Information-card justify-content-center mb-1">
                                                <b-card-header
                                                        class="information-card-header justify-content-center align-items-center"
                                                        header-bg-variant="warning"
                                                        header-text-variant="white"
                                                        >
                                                        <b>{{ $t('ticket.status_open') }}</b>
                                                </b-card-header>

                                                <b-card-title>
                                                        <font-awesome-icon :icon="'envelope-open'" :style="{ color: '#facf55' }" class="mx-1 information-title-icon" />
                                                        <span class="message-header-data text-dark mx-1" v-if="informations[3]">
                                                                {{informations[3]}}
                                                        </span>
                                                        <span class="message-header-data text-dark mx-1" v-else>
                                                                0
                                                        </span>
                                                </b-card-title>
                                        </b-card>
                                        </v-wait>
                                </b-card-group>
                        </b-col>
                </b-row>

        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';



    export default {

        mounted() {
        },

        beforeDestroy() {
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {
                // Map getters
                ...mapGetters({

                        firstResponseAverage: 'Report/getFirstResponseAverage',
                        informations: 'Report/getInformations'


                })
        },
    }
</script>