<template>
    <div>
        <template v-if="window_width >= 992">

            <!-- Search - Questions -->
            <b-dropdown-item :to="search_questions_route" active-class="active"><font-awesome-icon :icon="'inbox'" /> {{ $t('page.questions') }}</b-dropdown-item>
            <!-- Search - Answers --> 
            <b-dropdown-item :to="search_answers_route" active-class="active"><font-awesome-icon :icon="'comments'" /> {{ $t('page.answers') }}</b-dropdown-item>
            <!-- Search - Customers -->
            <b-dropdown-item :to="search_customers_route" active-class="active"><font-awesome-icon :icon="'users'" /> {{ $t('reports.customers') }}</b-dropdown-item>
            <!-- Search - Tickets -->
            <b-dropdown-item :to="search_tickets_route" active-class="active"><font-awesome-icon :icon="'ticket-alt'" /> {{ $t('page.tickets') }}</b-dropdown-item>
        </template>

        <template v-else>
            <!-- Search - Questions -->
            <b-link class="plus-header-accordion-links" :to="search_questions_route" active-class="active"><font-awesome-icon :icon="'inbox'" /> {{ $t('page.questions') }}</b-link>
            <!-- Search - Answers --> 
            <b-link class="plus-header-accordion-links" :to="search_answers_route" active-class="active"><font-awesome-icon :icon="'comments'" /> {{ $t('page.answers') }}</b-link>
            <!-- Search - Customers -->
            <b-link class="plus-header-accordion-links" :to="search_customers_route" active-class="active"><font-awesome-icon :icon="'users'" /> {{ $t('reports.customers') }}</b-link>
            <!-- Search - Tickets -->
            <b-link class="plus-header-accordion-links" :to="search_tickets_route" active-class="active"><font-awesome-icon :icon="'ticket-alt'" /> {{ $t('page.tickets') }}</b-link>

        </template>
    </div>

</template>
<script>

export default {
    mounted() {
        this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
    },

    data: function () {
        return {
            window_width: window.innerWidth,
            search_questions_route: '/search/questions',
            search_answers_route: '/search/answers',
            search_customers_route: '/search/customers',
            search_tickets_route: '/search/tickets',
        }
    },

    methods: {
        onResize() {
            this.window_width = window.innerWidth;
        },  
    },


}

</script>