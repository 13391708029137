<template functional>
    <!-- Note -->
    <div class="message-container">

        <b-alert show :variant="props.trash.remover_user ? 'success' : 'danger'" class="d-inline-block">

            <template v-if="props.trash && !props.trash.remover_user">

                <div class="alert-heading">{{ props.text.moved_to_trash }}</div>


            </template>
            <template v-else>
                <div class="alert-heading">{{ props.text.trash_restored }}</div>
            </template>

            <template v-if="props.note === true">
                <p class="border-top  border-danger mt-2">{{ props.note }}</p>


            </template>

            <p class="mb-0 small border-top border-success mt-2" v-if="props.trash.remover_user">
                {{ props.text.trash_restored_by }} <b>{{ props.adder.ns }}</b> <span v-b-tooltip.hover :title="props.date.created" class="text-secondary">({{ props.date.ago }})</span>.
            </p>

            <p class="mb-0 small border-top border-danger mt-2" v-else>
                {{ props.text.trash_created_by }} <b>{{ props.adder.ns }}</b> <span v-b-tooltip.hover :title="props.date.created" class="text-secondary">({{ props.date.ago }})</span>.
            </p>
        </b-alert>

    </div>

</template>

<script>
    export default {

        mounted: function () {
            // Component mounted
            //console.log('Content render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
        },

        data: function() { return {} },

        computed: {},

        methods: {},

    }
</script>