<template>
    <div class="c-modal modal-backdrop" tabindex="-1" role="dialog">
        <div class="c-modal__dialog modal-dialog" role="document">
            <div class="c-modal__content">
                <div class="c-modal__header">
                    <h3 class="c-modal__title">
                        {{ $t('default.add')}}
                    </h3>
                    <span class="c-modal__close" @click="$close()">
                        <font-awesome-icon :icon="'times'"/>
                    </span>
                </div>
                <div class="c-modal__body">

                    <form @submit.prevent="validate_edit">

                                <!-- Name -->
                                <div class="c-field u-mb-xsmall">
                                    <label class="c-field__label" for="name">
                                        {{ $t('simple.name') }}
                                    </label>

                                    <input
                                        :class="{'c-input':true}"
                                        type="text"
                                        id="name"
                                        name="name"
                                        v-validate="'required|max:15'"
                                        v-model="name">

                                    <small v-show="errors.has('name')" class="c-field__message u-color-danger">
                                        <font-awesome-icon :icon="'times-circle'" />
                                        {{ errors.first('name') }}
                                    </small>
                                </div>

                                <!-- Dispplay Name -->
                                <div class="c-field u-mb-xsmall">
                                    <label class="c-field__label" for="display_name">
                                        {{ $t('simple.display_name') }}
                                    </label>

                                    <input
                                        :class="{'c-input':true}"
                                        type="text"
                                        id="display_name"
                                        name="display_name"
                                        v-validate="'required|max:50'"
                                        v-model="display_name">

                                    <small v-show="errors.has('display_name')" class="c-field__message u-color-danger">
                                        <font-awesome-icon :icon="'times-circle'" />
                                        {{ errors.first('display_name') }}
                                    </small>
                                </div>

                                <!-- Description -->
                                <div class="c-field u-mb-xsmall">
                                    <label class="c-field__label" for="description">
                                        {{ $t('simple.desc') }}
                                    </label>

                                    <input
                                        :class="{'c-input':true}"
                                        type="text"
                                        id="description"
                                        name="description"
                                        v-validate="'required|max:50'"
                                        v-model="description">

                                    <small v-show="errors.has('description')" class="c-field__message u-color-danger">
                                        <font-awesome-icon :icon="'times-circle'" />
                                        {{ errors.first('description') }}
                                    </small>
                                </div>

                                <!-- Accounts -->
                                <h5 class="c-search-form__label">
                                    {{ $t('team.accounts') }}
                                </h5>
                                <div class="c-search-form__section u-flex">
                                    
                                    <multiselect

                                        v-model="accounts"
                                        :options="accounts_list"
                                        :multiple="true"
                                        :clear-on-select="false"
                                        :hide-selected="true"
                                        :preserve-search="true"
                                        :placeholder="$t('team.account_select')"
                                        label="name"
                                        track-by="id"
                                    >
                                    </multiselect>

                                </div>

                            </form>

                    </div>
                    <div class="c-modal__footer">
                        <button @click="$close(false)" class="c-btn c-btn--secondary">
                            {{ $t('simple.cancel') }}
                        </button>
                        <button @click="validate_edit" class="c-btn c-button--info">
                            {{ $t('simple.save') }}
                        </button>
                    </div>
            </div><!-- // .c-modal__content -->
        </div><!-- // .c-modal__dialog -->
    </div><!-- // .c-modal -->
</template>
<script>

    export default {

        mounted: function () {
            // Component mounted
            console.log('Team Add Modal mounted');
        },
        
        destroyed() {
            // Component destroyed
            console.log('Team Add Modal destroyed.');
        },

        created: function() {
            // Component created
            console.log('Team Add component created.');

            // Get data to edit
            this.edit(this.id);

            // Get Accounts
            this.getAccounts();

        },
        data: function() {
            return  {
                name: '',
                display_name: '',
                description: '',
                accounts: [],
                accounts_list: [],
            }
        },

        methods: {

            edit: async function(id) {

                // Loading start
                this.$eventLoading.$emit('start');

                // Get tag
                await axios.get('/api/v1/management/teams/' + id)
                    .then((response) => {

                        // Update modal data
                        this.name = response.data.name;
                        this.display_name = response.data.display_name;
                        this.description  = response.data.description;
                        this.accounts   = response.data.accounts;

                        //console.log(response);

                        // Loading stop
                        this.$eventLoading.$emit('stop');

                    })
                    .catch((error) => {

                        // Show error
                        Vue.toasted.error(this.$t('simple.error_info'));

                        // Log to console
                        console.log(error);

                        // Loading stop
                        this.$eventLoading.$emit('stop');

                    });

            },

            team_edit: async function(){

                // Loading start
                this.$eventLoading.$emit('start');

                // Post request
                await axios.put('/api/v1/management/teams/' + this.id, {
                    name: this.name,
                    description: this.description,
                    display_name: this.display_name,
                    accounts: this.accounts
                  })
                  .then((response) => {

                      if(response.data.error){
                          // Show error
                          Vue.toasted.error(Vue.i18n.translate('permission.update_teams'),{duration:5000});
                          // Close modal
                          this.$close(true);
                          // Loading stop
                          this.$eventLoading.$emit('stop');
                      }else {

                          // Show success
                          Vue.toasted.success(this.$t('simple.success_info'));

                          // Close modal
                          this.$close(true);

                          // Loading stop
                          this.$eventLoading.$emit('stop');
                      }

                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                    // Log to console
                    //console.log(error);

                    // Close modal
                    //this.$close(true);

                    // Loading stop
                    this.$eventLoading.$emit('stop');

                  });

            },

            // Get accounts
            getAccounts: async function(){

                await axios.get('/api/v1/system/accounts')

                  .then((response) => {

                    // response data
                    this.accounts_list = response.data;

                    //console.log(this.accounts_list);

                    //console.log(response);
                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    //console.log(error);
                });

            },

            validate_edit: async function() {
                // validate all fields
                await this.$validator.validateAll().then((result) => {
                    if (result) {

                        // Send it to account edit function
                        this.team_edit();

                    }
                });
            },
        },
    }
</script>