<template>

    <v-wait for="customer">

        <loading slot="waiting"/>


        <div class="panel-customer" v-if="customer != false">

            <!-- Customer Short Info -->
            <b-media class="pt-2 justify-content-center align-items-center">

              <b-container>
                <b-row class="flex-column align-items-center" >
                  <div id="image-username">
                    <b-img v-if="customer.twitter || customer.facebook || customer.instagram || customer.sikayetvar || customer.youtube || customer.google_review || customer.linkedin" slot="aside"
                           class="panel-customer-avatar" alt="placeholder" :src="customer.avatar" @error="customer.avatar='/images/default_profile_normal.png'"/>
                    <b-img v-if="customer.whatsapp" slot="aside" class="panel-customer-avatar" alt="placeholder"
                           :src="'/images/default_profile_normal.png'"/>
                  </div>

                  <b-tooltip target="image-username">
                    <span class="font-weight-light ml-2">
                      {{customer.whatsapp ? customer.whatsapp.whatsapp_id : customer.full_name}}
                    </span>
                  </b-tooltip>

                    <b-dropdown size="sm" :href="customer_profile" id="username"  class="py-2"  variant="outline-secondary" menu-class="w-100" :disabled="getIsReply || disabled_areas.customer_edit">
                      <template #button-content>
                        <small class="customer-render-username">
                          @{{ customer.username !== null ? customer.username : customer.full_name }}
                        </small>
                        </template>
                      <b-dropdown-item-button @click="updateCustomerEdit(true)">{{ $t('simple.edit') }}</b-dropdown-item-button>
                      <b-dropdown-item-button>{{ $t('default.mute') }}</b-dropdown-item-button>
                      <b-dropdown-item-button>{{ $t('default.blacklist') }}</b-dropdown-item-button>
                      <b-dropdown-item-button v-if="customer.twitter"
                                              @click="block_add(customer.account_id, customer.twitter_id)">{{$t('customer.block_twitter') }}</b-dropdown-item-button>
                      <b-dropdown-item-button v-if="customer.twitter"
                                              @click="refreshCustomer({account_id: customer.account_id, customer_id: customer.id})"><font-awesome-icon :icon="'sync'" class="plus__icon"/> {{ $t('default.refresh') }}</b-dropdown-item-button>
                     <b-dropdown-item-button v-if="customer_profile"
                                             @click="newWendow"><font-awesome-icon :icon="'share-square'" class="plus__icon"/> {{$t('simple.view_profile') }} </b-dropdown-item-button>
                      <template v-if="$route.params.int == 'sikayetvar'">
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-group>
                          <b-dropdown-item-button @click="sikayetvarProcess('survey')">survey</b-dropdown-item-button>
                          <b-dropdown-item-button @click="sikayetvarProcess('thankYou')">thank you</b-dropdown-item-button>
                        </b-dropdown-group>
                      </template>
                    </b-dropdown>
                
                </b-row>
                </b-container>
            </b-media>

            <hr>

            <template v-if="!customer_edit">

                <transition name="plus-fade">

                    <!-- Customer Twitter -->
                    <customer-twitter v-if="customer.twitter" v-bind:twitter="customer.twitter"></customer-twitter>
                    <customer-instagram v-if="customer.instagram" v-bind:instagram="customer.instagram"></customer-instagram>
                    <customer-whatsapp v-if="customer.whatsapp" v-bind:whatsapp="customer.whatsapp"></customer-whatsapp>
                    <customer-linkedin v-if="customer.linkedin" v-bind:linkedin="customer.linkedin"></customer-linkedin>

                </transition>

                <transition name="plus-fade">
                    <customer-location v-if="customer.location" v-bind:location="customer.location"></customer-location>
                </transition>
                <hr>
                <transition name="plus-fade">

                    <!-- Customer Info -->
                    <customer-info v-bind:customer="customer"></customer-info>

                </transition>

            </template>
            <template v-else>

                <customer-edit v-bind:customer="customer"></customer-edit>

            </template>
        </div>
      <b-modal
          id="modal-prevent-closing"
          ref="modal"
          v-model="modal"
          title="Submit Your Name"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-table
              :items="complaint_ids"
              :fields="fields"
              select-mode="single"
              responsive="sm"
              ref="selectableTable"
              small
              sticky-header="150px"
              selectable
              @row-selected="onRowSelected"
              selected-variant="primary"
          >
          </b-table>

        </form>
      </b-modal>
    </v-wait>
</template>

<script>

    
    import BlockAdd from '../Twitter/Modal/BlockAdd.vue';


    import {create} from 'vue-modal-dialogs';

    import {mapGetters, mapActions} from 'vuex';

    export default {
        props: ['customer'],

        mounted: function () {
            // Component mounted
            console.log('Customer Render mounted')
        },

        destroyed() {
            // Component destroyed
            console.log('Customer Render destroyed.');
        },

        created: function () {
            // Component created
          console.log('Customer Render created.');
          // this.updateIsInboxExpanded(true);
          // this.updateIsMessagesExpanded(false);

        },


        data: function () {

            return {

              complaint_ids : [],
              complaint_id : null,
              processType : null,
              modal : false,
              moment: this.$moment,
              fields: [
                {
                  key: 'complaint_id',
                  label: 'complaint_id',
                  sortable: true,
                }
              ]
            }

        },

        computed: {

            // Map getters
            ...mapGetters({

                customerID: 'Customer/getCustomerID',

                customer_profile: 'Customer/getCustomerProfile',
                customer_edit: 'Customer/getCustomerEdit',
                getIsReply: 'Reply/getIsReply',

                disabled_areas: 'Accounts/getDisabledAreas',


            })
        },

        methods: {



          async sikayetvarProcess(proccess){
            this.processType = proccess
            await axios.post('/api/v1/sikayetvar/getComplaints', {
              customer_id : this.$route.params.customer_id,
              account_id : this.$route.params.id,
            })

                .then((response) => {
                  console.log(response.data.data)
                  this.complaint_ids = response.data.data
                  this.modal = true
                })
                .catch((error) => {
                  // Show error
                  Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
                  console.log(error.response)

                });
          },
          onRowSelected(complaint_id) {
            this.complaint_id = complaint_id[0].complaint_id
          },

          resetModal() {

          },
          handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
          },
          async handleSubmit() {

            if (this.complaint_id && this.processType){
              await axios.post('/api/v1/sikayetvar/complaint/thankyou', {
                type : this.processType,
                complaint_id : this.complaint_id,
                account_id : this.$route.params.id,
              })

                  .then(() => {
                    Vue.toasted.success(this.$t('simple.success_info'));
                  })
                  .catch((error) => {
                    if (error.response.data.message){
                      if (error.response.data.message.error){
                        Vue.toasted.error(error.response.data.message.error);
                      }else{
                        Vue.toasted.error(error.response.data.message);
                      }
                    }
                  });
            }else{
              Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
            }

            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
            })
          },

            ...mapActions('Customer', [
                'updateCustomerEdit',
                'refreshCustomer',
            ]),

            // ...mapActions('Inbox', [
            //     'updateIsInboxExpanded',
            //     'updateIsMessagesExpanded',
            // ]),


            normalizer: function (node) {

                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                }

            },

            normalizer_gender: function (node) {

                return {
                    id: node.id,
                    label: this.$t('customer.gender_' + node.name),
                    children: node.children,
                }


            },

            //Block from Social Account
            block_add: async function (id, user_id) {

                // Show account Block modal
                const block_add = create(BlockAdd, "id", "user_id");

                if (await block_add(id, user_id)) {
                    // Refresh account list
                    this.data();
                }

            },

          newWendow: function () {
            window.open(this.customer_profile, "_blank");
          },


        },


        watch: {
            // Right Customer Panel - Return from the edit page wihout action
            // whenever customerID changes, this function will run
            customerID: function () {

                // Leave
                this.updateCustomerEdit(false);
            },
        },


    }
</script>