<template>

    <div class="mt-1">

        <file-pond
            name="image"

            :files="files"

            ref="pond"

            allowMultiple="false"


            allowFileSizeValidation="true"
            maxFileSize="2MB"

            allowFileTypeValidation="true"
            acceptedFileTypes="image/jpeg, image/png"

            :server="{ process }"

            allowReplace="false"
            allowRevert="false"

            label-idle="Upload your file..."

            v-on:init="handleFilePondInit"
        />

    </div>

</template>
<script>
    // Import Vue FilePond
    import vueFilePond from 'vue-filepond';

    // Import validate type
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

    // Import validate size
    import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
    import {mapActions} from "vuex";

    // Create component
    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

    export default {

        components: {
            FilePond
        },

        mounted: function () {
            // Component mounted
            console.log('Gallery upload mounted');
        },
        
        destroyed() {
            // Component destroyed
            console.log('Gallery upload destroyed.');
        },

        data: function() {

            return  {

                files: [],
                image: null,

                gallery_upload_api: '/api/v1/system/gallery',
            }
        },

        methods: {

            // Images
            ...mapActions('Pixel', [
                'fetchImages',
            ]),

            handleFilePondInit: function() {
                console.log('FilePond has initialized');

                // FilePond instance methods are available on `this.$refs.pond`
            },

            process: async function (fieldName, file, metadata, load, error, progress, abort){

                // Prepare new form data
                let fd = new FormData();

                // Append to form data
                fd.append('image', file);


                // Progress bar
                var config = {

                    headers: { 'Content-Type': 'multipart/form-data' },

                    onUploadProgress: function(progressEvent) {
                      var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

                      console.log(percentCompleted);

                      progress(true, progressEvent.loaded, progressEvent.total)

                    }
                };

                // Post handle
                await axios.post(this.gallery_upload_api, fd, config)

                  .then((response) => {

                    // response data
                    console.log(response);

                    load(response.data.id);

                    // Inform parent that upload is complete
                    this.$emit('upload-complete');
                    this.fetchImages();

                    // Report parent //

                    //console.log(response);

                  })
                  .catch((axerror) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    console.log(axerror);

                    // Can call the error method if something is wrong, should exit after
                    //error('oh my goodness');
                

                  });

                // Should expose an abort method so the request can be cancelled
                return {
                    abort: () => {
                        // User tapped abort, cancel our ongoing actions here

                        // Let FilePond know the request has been cancelled
                        abort();
                    }
                }


            },

        },
    }
</script>