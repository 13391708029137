<template>
  <b-card no-body class="h-100">
    <b-container fluid>
      <b-row>
        <b-col class="pt-2">
          <!--  Page  -->
          <div class="py-2">

            <!-- Page header -->
            <div class="px-3">

              <div class="d-flex">

                <div>
                  <h4> WhatsApp {{ $t('simple.campaign_details') }} </h4>
                </div>

                <div class="ml-auto align-self-center d-flex">

                  <b-button variant="danger" class="mr-2 d-flex align-items-center" to="/settings/whatsapp-campaigns">
                    <span>{{$t('simple.back') }} </span>
                  </b-button>

                  <b-button :variant="'outline-info'" @click="refreshWhatsappCampaignDetails">
                    <font-awesome-icon :icon="'sync'" />
                    {{ $t('default.refresh') }}
                  </b-button>

                </div>

              </div>

            </div>

            <hr>

            <!-- Page content -->
            <div class="px-2">

              <v-wait for="whatsapp_campaign_details">
                <loading slot="waiting" />
                <b-container fluid>
                  <b-row>
                    <b-col  class="d-flex flex-column align-items-md-end align-items-center justify-content-center">
                      <b-card border-variant="dark" align="center" class="mb-2" style="min-width: 15rem; max-width: 20rem;">
                        <template #header>
                          <h6 class="mb-0">{{ $t('simple.status') }}</h6>
                        </template>
                        <b-card-text>{{ campaign_status  }}</b-card-text>
                      </b-card>
                      <b-card border-variant="info" align="center" class="mb-2" style="min-width: 15rem; max-width: 20rem;">
                        <template #header>
                          <h6 class="mb-0">{{ $t('simple.sent') }}</h6>
                        </template>
                        <b-card-text>{{ whatsapp_campaing_details.customer_sent }}</b-card-text>
                      </b-card>
                      <b-card border-variant="success" align="center" class="mb-2" style="min-width: 15rem; max-width: 20rem;">
                        <template #header>
                          <h6 class="mb-0">{{ $t('simple.read') }}</h6>
                        </template>
                        <b-card-text>{{ whatsapp_campaing_details.customer_read }}</b-card-text>
                      </b-card>
                    </b-col>
                    <b-col  class="d-flex flex-column  align-items-md-start align-items-center justify-content-center">
                      <b-card border-variant="dark" align="center" class="mb-2" style="min-width: 15rem; max-width: 20rem;">
                        <template #header>
                          <h6 class="mb-0">{{ $t('simple.recipients') }}</h6>
                        </template>
                        <b-card-text>{{ whatsapp_campaing_details.customer_total  }}</b-card-text>
                      </b-card>
                      <b-card border-variant="warning" header-bg-variant="transparent" align="center" class="mb-2" style="min-width: 15rem; max-width: 20rem;">
                        <template #header>
                          <h6 class="mb-0">{{ $t('simple.delivered') }}</h6>
                        </template>
                        <b-card-text>{{ whatsapp_campaing_details.customer_deliver }}</b-card-text>
                      </b-card>
                      <b-card border-variant="danger" align="center" class="mb-2" style="min-width: 15rem; max-width: 20rem;">
                        <template #header>
                          <h6 class="mb-0">{{ $t('simple.failed_at') }}</h6>
                        </template>
                        <b-card-text>{{ whatsapp_campaing_details.customer_failed }}</b-card-text>
                      </b-card>
                    </b-col>
                    <b-col>
                      <whatsapp-draft :campaign="whatsapp_campaing_details" />

                    </b-col>
                  </b-row>
                </b-container>

                <hr>

                <b-container fluid>
                  <b-row class="align-items-center mb-3">
                    <b-col sm="3">
                      <label for="campaign_name" class="font-weight-bold">
                        {{ $t('simple.campaign_name') }}:</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-input id="campaign_name" name="campaign_name"
                                    v-model="whatsapp_campaing_details.name" disabled></b-form-input>
                    </b-col>
                  </b-row>

                </b-container>

              </v-wait>
            </div>
          </div>
        </b-col>
      </b-row>

      <hr>
      <b-row class="whatsapp-campaign-details-container align-items-center">
        <b-col>
          <b-pagination hide-goto-end-buttons size="sm" :total-rows="total" :value="current"
                        @input="updatePage" :per-page="perpage">
          </b-pagination>
        </b-col>
        <b-col md="4" class="my-2 ml-auto">
          <b-input-group>
            <b-form-input v-model="search" :placeholder="$t('simple.type_to_search')" />
            <b-input-group-append>
              <b-btn :disabled="!search" @click="search = ''">{{ $t('simple.clear') }}</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-3">
        <b-col sm="12" md="6" class="whatsapp-campaign">
          <b-row class="align-items-center">
            <b-col sm="12" md="2"  class="my-sm-1" v-b-tooltip.hover title="Filter">{{
                $t('default.filter_options') }}</b-col>
            <b-col sm="12" md="5" class="my-sm-1" >
              <b-form-checkbox-group :checked="selectedFilterItems" @change="setFilterOptions"
                                     :options="filterItems"></b-form-checkbox-group>
            </b-col>
            <b-col sm="12" md="5" class="my-sm-1">
              <b-input-group-append>
                <b-btn  :disabled="!(selectedFilterItems.length > 0) " :class="!(selectedFilterItems.length > 0) ? 'btn-secondary' : 'btn-danger'" @click="refreshFilter">{{ $t('simple.clear') }}</b-btn>
              </b-input-group-append>
            </b-col>
          </b-row>
        </b-col>

      </b-row>

      <b-table stacked="md" responsive bordered hover :per-page="perpage"
               :items="whatsapp_campaign_users"
               :fields="window_width >= 1200 ? whatsapp_campaign_users_fields : whatsapp_campaign_users_fields_collapsed">

        <template slot="cell(actions)" slot-scope="cell">
          <b-button size="sm" @click="cell.toggleDetails()" v-if="window_width < 1200">
            {{ cell.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </template>
        <template #row-details="row">
          <b-table small stacked="md" class="d-xl-none" responsive bordered hover :items="[row.item]"
                   :fields="whatsapp_campaign_users_fields_not_collapsed">

            <!-- Updated at -->
            <template slot="cell(deliver_at)" slot-scope="cell">
              {{ cell.item.deliver_at ?
                $moment($moment.utc(cell.item.deliver_at)).local().format('YYYY-MM-DD HH:mm') : '-' }}
            </template>
            <!-- Updated at -->
            <template slot="cell(failed_at)" slot-scope="cell">
              {{ cell.item.failed_at ?
                $moment($moment.utc(cell.item.failed_at)).local().format('YYYY-MM-DD HH:mm') : '-' }}
            </template>
            <!-- Updated at -->
            <template slot="cell(read_at)" slot-scope="cell">
              {{ cell.item.read_at ? $moment($moment.utc(cell.item.read_at)).local().format('YYYY-MM-DD HH:mm') : '-' }}
            </template>
          </b-table>
        </template>
        <!-- Created at -->
        <template slot="cell(sent_at)" slot-scope="cell">
          {{ cell.item.sent_at ? $moment($moment.utc(cell.item.sent_at)).local().format('YYYY-MM-DD HH:mm') :
            '-' }}
        </template>
        <template slot="cell(failed_at)" slot-scope="cell">
          {{ cell.item.failed_at ?
            $moment($moment.utc(cell.item.failed_at)).local().format('YYYY-MM-DD HH:mm') : '-' }}
        </template>
        <!-- Updated at -->
        <template slot="cell(deliver_at)" slot-scope="cell">
          {{ cell.item.deliver_at ? $moment($moment.utc(cell.item.deliver_at)).local().format('YYYY-MM-DD HH:mm') : '-' }}
        </template>
        <!-- Updated at -->
        <template slot="cell(read_at)" slot-scope="cell">
          {{ cell.item.read_at ? $moment($moment.utc(cell.item.read_at)).local().format('YYYY-MM-DD HH:mm') :
            '-' }}
        </template>
      </b-table>
      <v-wait for="whatsapp_campaign_users">
        <loading slot="waiting" />
      </v-wait>
    </b-container>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import WhatsappDraft from './WhatsappDraft.vue'

export default {
  components: {
    WhatsappDraft
  },
  metaInfo: function () {
    return {
      title: 'Whatsapp Campaign Details',
    }
  },

  async mounted() {
    await this.fetchWhatsappCampaignDetails({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id })
    await this.fetchWhatsappCampaingUsers({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id })

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);

    this.resetWhatsappCampaignUsers()

  },

  data() {
    return {
      filterItems: [
        { value: 'sent_at', text: this.$t('simple.sent')},
        { value: 'deliver_at', text: this.$t('simple.delivered') },
        { value: 'read_at', text: this.$t('simple.read') },
        { value: 'failed_at', text: this.$t('simple.failed_at') },
      ],
      selectedFilterItems:[],
      window_width: window.innerWidth,
      whatsapp_campaign_users_fields: [
        { key: 'phone_number', label: this.$t('simple.phone_number'), sortable: true },
        { key: 'name', label: this.$t('customer.name'), sortable: true },
        { key: 'sent_at', label: this.$t('simple.sent'), sortable: true },
        { key: 'deliver_at', label: this.$t('simple.delivered'), sortable: true },
        { key: 'read_at', label: this.$t('simple.read'), sortable: true },
        { key: 'failed_at', label: this.$t('simple.failed_at'), sortable: true },
        { key: 'fail_reason', label: this.$t('simple.fail_reason'), sortable: true },
      ],
      whatsapp_campaign_users_fields_collapsed: [
        { key: 'phone_number', label: this.$t('simple.phone_number'), sortable: true },
        { key: 'name', label: this.$t('customer.name'), sortable: true },
        { key: 'sent_at', label: this.$t('simple.sent'), sortable: true },
        { key: 'actions', label: this.$t('simple.actions') },

      ],

      whatsapp_campaign_users_fields_not_collapsed: [
        { key: 'deliver_at', label: this.$t('simple.delivered'), sortable: true },
        { key: 'read_at', label: this.$t('simple.read'), sortable: true },
        { key: 'failed_at', label: this.$t('simple.failed_at'), sortable: true },
        { key: 'fail_reason', label: this.$t('simple.fail_reason'), sortable: true },
      ],
    }
  },

  methods: {

    ...mapActions('Templates', [
      'fetchWhatsappCampaignDetails',
      'fetchWhatsappCampaingUsers',
      'updateWhatsappCampaignUsersPage',
      'resetWhatsappCampaignUsers',
      'updateSearchWhatsappCampaignUsers'
    ]),

    updatePage(data) {
      this.updateWhatsappCampaignUsersPage({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id, page: data })
    },

    async setFilterOptions(value){
      this.selectedFilterItems = value
      await this.fetchWhatsappCampaingUsers({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id, page:1, search:"", filter:value})
    },
    async refreshFilter(){
      this.selectedFilterItems = []
      await this.fetchWhatsappCampaingUsers({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id })
    },

    onResize() {
      this.window_width = window.innerWidth;
    },

    async refreshWhatsappCampaignDetails() {
      await this.fetchWhatsappCampaignDetails({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id })
      await this.fetchWhatsappCampaingUsers({ account_id: this.$route.params.account_id, campaign_id: this.$route.params.campaign_id })

    },



  },
  computed: {
    ...mapGetters({
      whatsapp_campaing_details: 'Templates/getWhatsappCampaignDetails',
      whatsapp_campaign_users: 'Templates/getCampaignUsers',

      total: 'Templates/getCampaignUsersTotal',
      current: 'Templates/getCampaignUsersCurrent',
      getSearchWhatsappCampaignUsers: 'Templates/getSearchWhatsappCampaignUsers',
      perpage: 'Templates/getCampaignUsersPerPage',
    }),
    search:{
      get(){
        return this.getSearchWhatsappCampaignUsers;
      },
      set(newSearch) {
        if (this.getSearchWhatsappCampaignUsers !== newSearch) {
          this.updateSearchWhatsappCampaignUsers(newSearch);
          this.fetchWhatsappCampaingUsers({
            account_id: this.$route.params.account_id,
            campaign_id: this.$route.params.campaign_id,
            page: 1,
            search: newSearch,
          });
        }
      }
    },

    campaign_status() {
      let status = this.whatsapp_campaing_details.status
      return status
    }

  },
}
</script>
