<template>
    <v-wait for="accounts">
        <loading slot="waiting" />
        <!-- ALL IN ONE -->
        <!-- Player -->
        <accounts-view-player :is_account="is_account" :showDisabledAccounts="showDisabledAccounts"
            @setShowDisabledAccounts="setShowDisabledAccounts"></accounts-view-player>
        <!-- Accounts Part -->
        <b-nav vertical fill class="nav-left plus-navbar-left">
            <b-overlay :show="getPlayer" rounded="sm">
                <!-- Overlay Settings -->
                <template v-slot:overlay>
                    <div class="text-center">
                        <font-awesome-icon :icon="'handshake'" class="fa-2x logo-color" />
                        <p id="cancel-label"> {{ $t('player.play_mode') }}</p>
                        <b-button :pressed.sync="player" ref="cancel" variant="outline-dark" size="sm"
                            aria-describedby="cancel-label">
                            {{ $t('default.cancel') }}
                        </b-button>
                    </div>
                </template>
                <!-- Accounts -->
                <template v-for="account in filteredAccounts" >
                    <b-nav-item v-b-toggle="'account_' + account.id" :key="account.id" :active="is_account(account.id)" >
                        <font-awesome-icon
                            v-if="!account.status && (account.integration.id == 'facebook' || account.integration.id == 'instagram' || account.integration.id == 'twitter')"
                            style="font-size:15px" icon="exclamation-circle" class="text-danger"
                            v-b-tooltip.hover.right="account.reason" />
                        <font-awesome-icon :style="{ color: account.color }" :class="{ 'text-muted':!account.is_active }"
                            :icon="[account.integration.id !== 'sikayetvar' ? 'fab' : 'fas', account.integration.icon]" />
                        <template v-if="!account.is_active">
                            <span class="text-muted">{{ account.name }} <font-awesome-icon :icon="'eye-slash'" /></span>
                        </template>
                        <template v-else>
                            <span>{{ account.name }}</span>
                        </template>

                        <!-- New Message Icon -->
                        <span v-if="account.unread_count" class="badge badge-primary py-1">{{ $t('page.new') }}</span>
                        <!-- Confirmation Icon -->
                        <span v-if="account.confirmation_count && user.is_super" class="float-right mr-1">
                            <font-awesome-icon :style="{ color: 'DimGray' }" size="xs" :icon="'question'"
                                v-b-tooltip.hover :title="$t('confirmation.awaiting_confirmation')" />
                        </span>
                        <!-- Transfer Icon -->
                        <span v-if="account.transfer_count" class="float-right mr-1">
                            <font-awesome-icon :style="{ color: 'DarkOrange' }" size="xs" :icon="'reply'"
                                v-b-tooltip.hover :title="$t('ticket.awaiting_transfer')" />
                        </span>
                        
                    </b-nav-item>
                    <b-collapse accordion="account-menu" :visible="is_account(account.id)" 
                        v-bind:id="'account_' + account.id">
                        <!-- Questions -->
                        <li class="nav-item-secondary">
                            <router-link :to="{
                                name: 'inbox_list',
                                params: { id: account.id, int: account.integration_id, type: 'questions' }
                            }" class="nav-link" active-class="active">
                                <font-awesome-icon :icon="'inbox'" />
                                {{ $t('page.questions') }}
                            </router-link>
                            <span class="nav-count">{{ account.unread_count }}</span>
                        </li>
                        <!-- Confirmation -->
                        <li class="nav-item-secondary">
                            <router-link :to="{
                                name: 'inbox_list',
                                params: { id: account.id, int: account.integration_id, type: 'confirmations' }
                            }" class="nav-link" active-class="active">
                                <font-awesome-icon :icon="'question'" />
                                {{ $t('page.confirmations') }}
                            </router-link>
                            <span class="nav-count">{{ account.confirmation_count }}</span>
                        </li>
                        <!-- Transfers -->
                        <li class="nav-item-secondary">
                            <router-link :to="{
                                name: 'inbox_list',
                                params: { id: account.id, int: account.integration_id, type: 'transfers' }
                            }" class="nav-link" active-class="active">
                                <font-awesome-icon :icon="'reply'" />
                                {{ $t('page.transfers') }}
                            </router-link>
                            <span class="nav-count">{{ account.transfer_count }}</span>
                        </li>
                        <!-- Spam -->
                        <!--<li class="nav-item-secondary">
                                <router-link
                                    :to="{
                                        name: 'account',
                                        params: { id: account.id },
                                        query: { view: 'spam' }
                                    }"
                                    class="nav-link"
                                    active-class="active"
                                    >
                                    <font-awesome-icon :icon="'trash'" />
                                    {{  $t('page.spam') }}
                                </router-link>
                            </li>-->
                        <!-- Trash -->
                        <li class="nav-item-secondary">
                            <router-link :to="{
                                name: 'inbox_list',
                                params: { id: account.id, int: account.integration_id, type: 'trash' }
                            }" class="nav-link" active-class="active">
                                <font-awesome-icon :icon="'trash'" />
                                {{ $t('page.trash') }}
                            </router-link>
                        </li>
                        <!-- Twitter only -->
                        <template v-if="account.integration_id == 'twitter'">
                            <!-- Post list -->
                            <!--                                     <li class="nav-item-secondary">-->
                            <!--                                       <router-link-->
                            <!--                                           :to="{-->
                            <!--                                                   name: 'post_list',-->
                            <!--                                                   params: { id: account.id, int: account.integration_id }-->
                            <!--                                               }"-->
                            <!--                                           class="nav-link"-->
                            <!--                                           active-class="active"-->
                            <!--                                       >-->
                            <!--                                         <font-awesome-icon :icon="'sticky-note'"/>-->
                            <!--                                         {{ $t('simple.post') }}-->
                            <!--                                       </router-link>-->
                            <!--                                     </li>-->
                            <!-- Track list -->
                            <li class="nav-item-secondary">
                                <router-link :to="{
                                    name: 'tracks',
                                    params: { id: account.id, int: account.integration_id }
                                }" class="nav-link" active-class="active">
                                    <font-awesome-icon :icon="'anchor'" />
                                    {{ $t('page.tracks') }}
                                </router-link>
                            </li>
                            <!-- Mute List -->
                            <li class="nav-item-secondary">
                                <router-link :to="{
                                    name: 'mutes',
                                    params: { id: account.id, int: account.integration_id }
                                }" class="nav-link" active-class="active">
                                    <font-awesome-icon :icon="'microphone-slash'" />
                                    {{ $t('page.mutes') }}
                                </router-link>
                            </li>
                            <!-- Block List -->
                            <li class="nav-item-secondary">
                                <router-link :to="{
                                    name: 'blocks',
                                    params: { id: account.id, int: account.integration_id }
                                }" class="nav-link" active-class="active">
                                    <font-awesome-icon :icon="'ban'" />
                                    {{ $t('page.blocks') }}
                                </router-link>
                            </li>
                        </template>
                    </b-collapse>
                </template>
            </b-overlay>
        </b-nav>
    </v-wait>


</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {

    mounted: function () {

        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    created: function () {
        // Mounted

        //Set Account ID
        this.setAccountId(this.$route.params.id);

        // Get accounts
        if (this.$route.params.type !== 'player') {
            this.fetchAccounts(this.$route.params.id);
        }
        // Set Integration ID
        if (this.$route.params.int !== undefined) {

            this.setIntegrationID(this.$route.params.int);
        }

        // Set Integration Account Types
        if (this.integration !== undefined) {
            this.setIntegrationTypes(this.integration.types);
        }

    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },


    destroyed() {
        // Component destroyed
        console.log('Accounts View destroyed.');
    },

    data: function () {

        return {
            active_menu: '',

            window_width: window.innerWidth,

            showDisabledAccounts: true,

        }
    },

    methods: {

        // If route params is equal to route params
        is_account: function (id) {

            return this.$route.params.id === id;

        },

        leave_echo: async function (account_id) {

            console.log('leave echo ' + account_id);

            Echo.leave('inbox.account.' + account_id);

        },

        onResize() {
            this.window_width = window.innerWidth;
        },


        getSubMenu: function (id) {
            this.active_menu = id;
        },

        check_menu: function (id) {
            return id === this.active_menu;
        },

        setShowDisabledAccounts(e) {
            this.showDisabledAccounts = !this.showDisabledAccounts
        },

        ...mapActions('Accounts', [
            'fetchAccounts',
            'setAccountData',
            'setAccount',

        ]),

        ...mapActions('Player', [
            'gamificationActions'
        ]),

        ...mapMutations('Player', [
            'setPlayer'
        ]),

        // Mutations

        ...mapMutations('Accounts', [
            'setIntegrationTypes',
            'setIntegrationID',
        ]),

        ...mapMutations('Accounts',
            { setAccountId: 'setAccount' }
        ),


    },

    computed: {

        // Map getter
        ...mapGetters({

            accounts: 'Accounts/getAccounts',

            account: 'Accounts/getAccount',

            user: 'User/getUser',

            // Player Options
            getPlayer: 'Player/getPlayer',

            // Get account Integration id
            integration: 'Accounts/getIntegration',

        }),

        // Account ID
        id: {

            // getter
            get: function () {
                // Get Account ID
                return this.$route.params.id;
            },
        },

        filteredAccounts() {
            if (this.showDisabledAccounts) {
                return this.accounts
            } else {
                return this.accounts.filter(account => account.is_active)
            }
        },



        // For Player Actions
        player: {

            // getter
            get: function () {
                // Get Player Data
                return this.getPlayer;
            },

            // setter
            set: function (value) {
                this.setPlayer(value);
                console.log(value);
                this.gamificationActions(value);
            }
        },


    },

    watch: {
        // whenever id changes, this function will run
        id: function (newId, oldId) {

            if (oldId) {
                // Leave Listener
                this.leave_echo(oldId);
            }

            if (newId) {
                // Set account
                this.setAccount(newId);
            }
        },
    },


}

</script>