<template>
    <div>
        <template v-if="window_width >= 992">

            <b-nav-item :to="notifications_route"><font-awesome-icon :icon="'bell'" /> {{ $t('page.notifications') }}</b-nav-item>

        </template>

        <template v-else>

            <b-link class="plus-header-accordion-links" :to="notifications_route" active-class="active"><font-awesome-icon :icon="'bell'" /> {{ $t('page.notifications') }}</b-link>
        </template>
    </div>

</template>
<script>

export default {
    mounted() {
        this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
    },

    data: function () {
        return {
            window_width: window.innerWidth,
            notifications_route: '/notifications',
        }
    },

    methods: {
        onResize() {
            this.window_width = window.innerWidth;
        },  
    },


}

</script>