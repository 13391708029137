<template>
    <div class="row">

        <div class="col pt-2">

            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.teams') }}</h4>

                        <div class="ml-auto align-self-center" v-if="!permission">

                          <b-button :variant="'primary'" @click="addTeam" class="ml-auto">
                            <font-awesome-icon :icon="'plus'" />
                            {{ $t('default.add')}}
                          </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.teams_desc') }}</span>

                </div>

                <hr>

                <div class="alert alert-danger text-center" style="max-width: 50%; margin: 0 auto;" v-if="permission">
                    {{ permission }}
                </div>

                <!-- Page content -->
                <div class="px-2" v-else>


                    <v-wait for="teams">


                        <loading slot="waiting"/>


                        <b-row>
                            <b-col md="4" class="my-2 ml-auto">
                                <b-input-group>
                                    <b-form-input v-model="filter" :placeholder="$t('simple.type_to_search')" />
                                    <b-input-group-append>
                                      <b-btn :disabled="!filter" @click="filter = ''">{{ $t('simple.clear') }}</b-btn>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>

                        <template v-if="window_width >= 1200">
                            <b-table responsive bordered hover :items="getTeams" :fields="team_fields" :filter="filter">

                                <!-- Account -->
                                <template v-slot:cell(account)="data">
                                   <b-badge :variant="'info'">


                                     {{ data.value.name }}

                                   </b-badge>
                                </template>

                                <template v-slot:cell(show_users)="row">
                                  <b-button size="sm" @click.stop="row.toggleDetails" class="mr-2">
                                   {{ row.detailsShowing ? 'Hide' : 'Show' }} Users
                                  </b-button>
                                </template>

                                <template v-slot:row-details="row">

                                  <b-card>

                                    <template v-if="row.item.users">
                                    
                                        <b-table responsive bordered hover :items="row.item.users" :fields="user_fields">

                                            <!-- Avatar -->
                                            <template v-slot:cell(ns)="data">

                                                <avatar :username="data.value" :size="32"></avatar>

                                            </template>

                                            <!-- Roles -->
                                            <template v-slot:cell(user_roles)="data">

                                                <!-- {{ row.item }} -->
                                                {{ getUserRole(row.item.id, data.value).display_name }}

                                            </template>

                                            <!-- Actions -->
                                            <template v-slot:cell(actions)="cell">


                                              <b-dropdown right :text="$t('simple.actions')" size="sm">


                                                <b-dropdown-item @click="remove(row.item.id, getUserRole(row.item.id, cell.item.user_roles).id, cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>


                                              </b-dropdown>

                                            </template>

                                            <!-- Created at -->
                                            <template slot="cell(created_at)" slot-scope="cell">
                                            
                                                 {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                            </template>
                                        
                                            <!-- Login at -->
                                            <template slot="cell(login_at)" slot-scope="cell">

                                                <template v-if="cell.item.login_at === null">
                                                    -
                                                </template>
                                                <template v-else>
                                                    {{ $moment($moment.utc(cell.item.login_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                                </template>
                                            
                                            </template>

                                        </b-table>

                                    </template>


                                    <b-button size="sm" varian="primary" @click="add(row.item.id)">{{ $t('default.add') }} {{ $t('simple.user') }}</b-button>


                                  </b-card>
                                </template>


                                <!-- Created at -->
                                <template slot="cell(created_at)" slot-scope="cell">
                                
                                     {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                </template>
                            
                                <!-- Updated at -->
                                <template slot="cell(updated_at)" slot-scope="cell">
                                
                                     {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                
                                </template>

                            </b-table>
                        </template>
                        
                        <template v-else>
                            <b-table 
                                stacked="md"
                                small
                                responsive
                                bordered 
                                hover
                                show-empty
                                :items="getTeams" 
                                :fields="team_fields_collapsed" 
                                :filter="filter"
                                >

                                <!-- Account -->
                                <template #cell(account)="data">
                                   <b-badge :variant="'info'">
                                     {{ data.value.name }}
                                   </b-badge>
                                </template>

                                <template #cell(actions)="row">
                                    <b-button size="sm" @click="info(row.item, $event.target)" class="m-1">
                                        Show Users
                                    </b-button>
                                    <b-button size="sm" @click.stop="row.toggleDetails" class="m-1">
                                        {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
                                    </b-button>

                                </template>
                                <template #row-details="row">
                                        <b-table
                                            small
                                            stacked="md"
                                            show-empty
                                            responsive
                                            bordered
                                            hover
                                            :items="[row.item]"
                                            :fields="team_fields_not_collapsed">
                                                <!-- Created at -->
                                                <template slot="cell(created_at)" slot-scope="cell">
                                                
                                                     {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}

                                                </template>
                                            
                                                <!-- Updated at -->
                                                <template slot="cell(updated_at)" slot-scope="cell">
                                                
                                                     {{ $moment($moment.utc(cell.item.updated_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                                
                                                </template>

                                        </b-table>
                                </template>
                            </b-table>

                            <b-modal size="xl" centered no-enforce-focus ok-only :id="infoModal.id" @hide="resetInfoModal">
                                <template #modal-title>
                                    Users
                                </template>

                                <b-card>
                                <b-button size="sm" varian="primary" class="m-1" @click="add(infoModal.content.id)">{{ $t('default.add') }} {{ $t('simple.user') }}</b-button>
                                <b-table
                                small
                                stacked="lg"
                                responsive
                                bordered
                                hover
                                show-empty
                                :items="infoModal.content.users"
                                :fields="user_fields_collapsed">


                                <!-- Roles -->
                                <template #cell(user_roles)="data">
                                    <!-- {{ infoModal.content }} -->
                                    {{ getUserRole(infoModal.content.id, data.value).display_name }}
                                </template>

                                <template #cell(actions)="row">
                                    <b-button size="sm" @click.stop="row.toggleDetails" class="m-1">
                                        {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
                                    </b-button>
                                    <b-dropdown right :text="$t('simple.actions')" size="sm" class="m-1">

                                        <b-dropdown-item @click="remove(infoModal.content.id, getUserRole(infoModal.content.id, row.item.user_roles).id, row.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>

                                    </b-dropdown>

                                </template>
                                <template #row-details="row">
                                    <b-table
                                        small
                                        stacked="lg"
                                        responsive
                                        striped
                                        bordered
                                        hover
                                        :items="[row.item]"
                                        :fields="user_fields_not_collapsed">

                                        <!-- Created at -->
                                        <template slot="cell(created_at)" slot-scope="cell">
                                        
                                             {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                             
                                        </template>
                                    
                                        <!-- Login at -->
                                        <template slot="cell(login_at)" slot-scope="cell">

                                            <template v-if="cell.item.login_at === null">
                                                -
                                            </template>
                                            <template v-else>
                                                {{ $moment($moment.utc(cell.item.login_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                            </template>
                                        
                                        </template>
                                    </b-table>
                                </template>

                                </b-table>

                                </b-card>

                            </b-modal>
                        </template>

                    </v-wait>

                </div>
                    

            </div>


        </div>

    </div>
</template>

<script>

import TeamAdd from './Modal/TeamAdd.vue'
import TeamUserAdd from './Modal/TeamUserAdd.vue'
import TeamEdit from './Modal/TeamEdit.vue'
import TeamUserDelete from './Modal/TeamUserDelete.vue'

import {create} from 'vue-modal-dialogs'
import {mapActions, mapGetters} from "vuex";

export default {

        mounted: function () {
            // Component mounted
            console.log('Teams component mounted.')
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        destroyed() {
            // Component destroyed
            console.log('Teams component destroyed.');
        },

        created: function() {
           // Component created

           //Fetch Teams
           this.fetchTeams();
        },
        

        data: function() {
            return  {
                window_width: window.innerWidth,
                team_fields: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'display_name', label: this.$t('simple.display_name'), sortable: true },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true },
                    { key: 'account', label: this.$t('simple.account'), sortable: true },
                    { key: 'created_at', label: this.$t('simple.created') , sortable: true },
                    { key: 'updated_at', label: this.$t('simple.updated'), sortable: true },
                    { key: 'show_users', label: this.$t('simple.users') },
                ],
                team_fields_collapsed: [
                    { key: 'name', label: this.$t('simple.name'), sortable: true },
                    { key: 'display_name', label: this.$t('simple.display_name'), sortable: true },
                    { key: 'description', label: this.$t('simple.desc'), sortable: true },
                    { key: 'account', label: this.$t('simple.account'), sortable: true },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                team_fields_not_collapsed: [
                    { key: 'created_at', label: this.$t('simple.created') },
                    { key: 'updated_at', label: this.$t('simple.updated') },
                    // { key: 'actions', label: this.$t('simple.actions') },
                ],

                user_fields: [
                    // { key: 'ns', label: this.$t('user.ns') },
                    { key: 'name', label: this.$t('simple.name') },
                    { key: 'surname', label: this.$t('simple.surname') },
                    { key: 'email', label: this.$t('simple.email') },
                    { key: 'phone', label: this.$t('simple.phone') },
                    { key: 'user_roles', label: this.$t('simple.role') },
                    { key: 'created_at', label: this.$t('simple.created') },
                    { key: 'login_at', label: this.$t('user.login_at') },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                user_fields_collapsed: [
                    // { key: 'ns', label: this.$t('user.ns') },
                    { key: 'name', label: this.$t('simple.name') },
                    { key: 'surname', label: this.$t('simple.surname') },
                    { key: 'email', label: this.$t('simple.email') },
                    { key: 'user_roles', label: this.$t('simple.role') },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                user_fields_not_collapsed: [
                    { key: 'phone', label: this.$t('simple.phone') },
                    { key: 'created_at', label: this.$t('simple.created') },
                    { key: 'login_at', label: this.$t('user.login_at') },
                ],

                filter: null,
                infoModal: {
                    id: 'info-modal',
                    content: '',
                }
            }
        },

        metaInfo: function() {

            return {
                title: this.$t('page.teams'),
            }

        },

        computed: {

            // Map getters
            ...mapGetters({
                getTeams: 'Teams/getTeams',
                permission: 'Teams/getPermission',
            }),


        },

        methods: {

            ...mapActions('Teams', [
                'fetchTeams',
            ]),


            getUserRole: function(team_id, roles){

                // Let's go and find role in team id pivot
                return roles.find(item => item.team_id === team_id);

            },

            add: async function(team_id) {
                // Show User add modal
                const teamuser_add = create(TeamUserAdd, 'team_id');

                if (await teamuser_add(team_id) ){
                    // Refresh account list
                    await this.fetchTeams();
                }

            },

            addTeam: async function() {
                // Show User add modal
                const team_add = create(TeamAdd);

                if (await team_add() ){
                    // Refresh account list
                    await this.fetchTeams();
                }

            },

            edit: async function(id) {
                // Show User edit modal
                const team_edit = create(TeamEdit, 'id');

                if (await team_edit(id) ){
                    // Refresh User list
                    await this.fetchTeams();
                }

            },

            
            remove: async function(team_id, role_id, user_id) {
                
                console.log(team_id);
                console.log(role_id);
                console.log(user_id);

                // Show User remove modal
                const teamuser_remove = create(TeamUserDelete, 'team_id', 'role_id', 'user_id');

                if (await teamuser_remove(team_id, role_id, user_id) ){
                    // Refresh User list
                    await this.fetchTeams();
                }

            },
            
            info(item, button) {
                this.infoModal.content = item;
                this.$root.$emit('bv::show::modal', this.infoModal.id, button);
            },
            resetInfoModal() {
                this.infoModal.content = ''
            },

            onResize() {
                this.window_width = window.innerWidth;
            },  

        },



        
    }
</script>