<template>

    <div class="panel-customer-info">

        <!-- Customer info -->
        <div class="panel-title pb-2 text-uppercase text-muted">{{ $t('customer.title')}} #{{ customer.id }}</div>

        <!-- Identification -->
        <div class="pb-2" v-if="customer.identification">
            <font-awesome-icon :icon="'id-card'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.identification')"/>  <span>{{ customer.identification }}</span>
        </div>

        <!-- Name -->
        <div class="pb-2" v-if="customer.name">
            <font-awesome-icon :icon="'user-circle'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.name')"/>  <span>{{ customer.name }}</span>
        </div>

        <!-- Surname -->
        <div class="pb-2" v-if="customer.surname">
            <font-awesome-icon :icon="'user-circle'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.surname')"/>  <span>{{ customer.surname }}</span>
        </div>

        <!-- Name -->
        <div class="pb-2" v-if="customer.gender">
            <font-awesome-icon :icon="'user-circle'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.gender')"/>  <span>{{ customer.gender }}</span>
        </div>

        <!-- Phone -->
        <div class="pb-2" v-if="customer.phone">
            <font-awesome-icon :icon="'phone'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.phone')"/>  <span>{{ customer.phone }}</span>          
        </div>

        <!-- Email -->
        <div class="pb-2" v-if="customer.email">
            <font-awesome-icon :icon="'at'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.email')"/>  <span>{{ customer.email }}</span>
        </div>

        <!-- Customer gender id -->
        <div class="pb-2" v-if="customer.customer_gender">

            <font-awesome-icon :icon="gender(customer.customer_gender.id)" class="plus__icon" v-b-tooltip.hover  :title="$t('customer.gender')" :size="'lg'" />

            <span>{{ $t(customer.customer_gender.name) }}</span>

        </div>
<template v-if="customer.customer_type">
<b-container>
    <b-row>
        <!-- Customer type id -->
        <div class="pb-2" >
            
            <font-awesome-icon :icon="'user-tag'" class="plus__icon" v-b-tooltip.hover :title="$t('customer.type')"/>  <span>{{ customer.customer_type.name }}</span>
        </div>
        <div class="ml-2">
            <!-- Customer Type Render -->
            <customer-type v-bind:customer="customer"></customer-type>
        </div>

    </b-row>
</b-container>
</template>

        <!-- Customer Changes -->
        <div class="ml-n3" v-if="customer.customer_changes">

            <!-- Customer Type Render -->
            <customer-changes v-bind:customer="customer.customer_changes"></customer-changes>

        </div>

    </div>


</template>

<script>

    import { getCustomerGender } from '../../utils/customer.js'

    export default {

        props: ['customer'],

        mounted: function () {
            // Component mounted
            //console.log('Customer Info Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Info Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        
        data: function() {

            return {}

        },

        computed: {},
        
        methods: {

            gender: getCustomerGender

        },
        
    }
</script>