<template functional>
    <!-- Question detka-->
    <div class="message-container" :class="[data.staticClass, {'replyMessageOverlay' :  props.message.message_id === thread }]">
        <div v-text="getThreadId"></div>
        <div class="message-wrapper-customer">
            <div class="message-header message-header-customer">

              <div class="d-flex flex-nowrap align-items-center">
                <img v-if="props.account.integration_id == 'twitter' || props.account.integration_id == 'facebook' || props.account.integration_id == 'instagram' || props.account.integration_id == 'sikayetvar' || props.account.integration_id == 'youtube' || props.account.integration_id == 'googlereview' || props.account.integration_id == 'linkedin'" :src="props.customer.avatar" alt="Customer image" class="message-header-avatar customer-message-avatar-mobile" @error="props.customer.avatar='/images/default_profile_normal.png'" />
                <img v-if="props.account.integration_id == 'whatsapp'" :src="'/images/default_profile_normal.png'" data-holder-rendered="true" class="message-header-avatar customer-message-avatar-mobile">

                <span class="message-header-name text-info" v-b-tooltip.hover.html="{ title: props.customer.whatsapp ? `${props.customer.whatsapp.whatsapp_id} <br> ${props.customer.whatsapp.name}` : '@' + props.customer.username }">
                  {{ (props.account.integration_id === 'whatsapp' && props.customer.name) ? props.customer.name : props.customer.full_name ? props.customer.full_name : props.customer.username}}
                </span>
              </div>

                <div class="message-header-info">

                  <!--Deleted Message-->
                  <span v-if="props.message.activity === 'deleted'" class="message-header-data text-danger" v-b-tooltip.hover :title="parent.$t('simple.deleted_info')">
                    <font-awesome-icon
                        :icon="'comment-slash'"
                    />
                    </span>

                  <span v-if="props.message.activity === 'hidden'" class="message-header-data text-danger" v-b-tooltip.hover :title="parent.$t('simple.hidden_info')">
                    <font-awesome-icon
                        :icon="'eye-slash'"
                    />
                    </span>

                    <!-- Parent Post
                    <parent-post v-if="props.message.post_id && props.account.integration_id === 'facebook'" v-bind:integration="props.account.integration_id" v-bind:account_id="props.account.id" v-bind:post_id="props.message.post_id" ></parent-post>
-->
                    <span v-if="props.message.lang !== 'Unknown'" class="message-header-data font-weight-bold text-uppercase" v-b-tooltip.hover :title="parent.$t('simple.content') + ' ' + parent.$t('simple.language') + ': ' + props.languages[props.message.lang.toLowerCase()]" > {{ props.message.lang }}</span>

                    <!-- Message Type Render -->
                    <message-type v-bind:integration="props.account.integration_id" v-bind:type="props.message.type" v-bind:status_id="props.message.status_id"></message-type>

                    <!-- Message Integration Render -->
                    <message-integration v-bind:integration="props.account.integration_id" v-bind:post_id="props.message.post_id" v-bind:id="props.message.id"></message-integration>

                    <!-- Message Created -->
                    <message-date v-bind:permalink="props.message.permalink" v-bind:username="props.customer.username" v-bind:id="props.message.message_id" v-bind:type="props.message.type" v-bind:integration="props.account.integration_id" v-bind:date="props.date" v-bind:created="props.message.created_at"></message-date>

                    <!-- Message Source -->
                    <message-source v-if="props.message.source" v-bind:message_id="props.message.id" v-bind:source="props.message.source" ></message-source>

                    <!-- Message Ticket -->
                    <message-ticket v-if="props.ticket" v-bind:ticket="props.ticket" v-on="listeners"
                    v-b-toggle="ticket_toggle"
                    ></message-ticket>

                    <!-- Actions -->
                    <div class="dropdown message-header-data">

                        <b-dropdown size="sm" right variant="light" :disabled="props.disabled_areas.question_options">
                            <b-dropdown-item :disabled="(props.customer.user_assigned && props.customer.user_assigned !== props.user.id && !props.user.is_super) || (props.message.activity === 'deleted')"
                                             v-on:click="listeners.updateLast(true)">
                              <font-awesome-icon :icon="'reply'" class="mr-2" />
                              {{ parent.$t('default.reply') }}</b-dropdown-item>
                          <!-- Delete message -->
                          <b-dropdown-item
                              v-if="props.message.activity !== 'deleted' && props.message.message_id && props.message.type === 2 && props.account.integration_id !== 'sikayetvar' && props.account.integration_id !== 'googlereview' && props.account.integration_id !== 'googleplay'"
                              v-on:click="$options.methods.message_delete(props.account.id, props.message.message_id, 'question')">
                            <font-awesome-icon :icon="'trash'" class="mr-2" />{{ parent.$t('simple.delete') }}</b-dropdown-item>
                          <!-- Hide Message -->
                          <b-dropdown-item
                              v-if="props.message.activity !== 'deleted' && (!props.message.confirmation || props.message.confirmation.status === 1) && !props.message.schedule && props.message.message_id && props.message.type === 2 && props.account.integration_id !== 'sikayetvar' && props.account.integration_id !== 'googlereview' && props.account.integration_id !== 'googleplay'  && props.account.integration_id !== 'linkedin'"
                              v-on:click="$options.methods.message_hide(props.account.id, props.message.message_id, props.message.activity!=='hidden')">
                            <font-awesome-icon :icon="props.message.activity === 'hidden' ? 'eye' : 'eye-slash'" class="mr-2" />
                            {{ props.message.activity === 'hidden' ? parent.$t('simple.unhide') : parent.$t('simple.hide') }}</b-dropdown-item>
                          <!-- Complaint Status Changer -->
                          <b-dropdown-item
                              v-if="props.account.integration_id === 'sikayetvar'"
                              v-on:click="$options.methods.complaint_status_changer(props.account.id, props.message.post_id, props.message.activity!=='closed')">
                            <font-awesome-icon :icon="props.message.activity === 'closed' ? 'eye' : 'eye-slash'" class="mr-2" />
                            {{ props.message.activity === 'closed' ? parent.$t('default.reopen') : parent.$t('default.close') }}</b-dropdown-item>

                          <!-- Change Ticket -->
                          <b-dropdown-item v-on:click="$options.methods.message_ticket_edit( props.ticket.id, props.ticket.case_id, props.inbox_id, customer_tickets)">
                            <font-awesome-icon :icon="'edit'" class="mr-2" />
                            {{ parent.$t('ticket.change_ticket') }}</b-dropdown-item>


                        </b-dropdown>


                    </div>

                </div>

            </div>

            <div v-if="props.message.post_id !== null && props.message.post_id !== props.message.thread_id && (props.message.thread_message !== null || props.message.thread_our_message !== null)" class="message-customer m-0 pb-1" style="border-radius: 5px 10px 10px 10px; overflow: hidden;">
                <div class="pt-2 pl-3" style="background: #a1a5ad; padding-bottom: 1px;">
                    <font-awesome-icon :icon="'reply'" class="plus__icon text-warning pr-2"  style="font-size: 22px;" /><span class="text-black-50 "><strong>{{ props.message.thread_message ? props.message.thread_message.thread_customer.username : 'You' }}</strong></span>
                    <p>{{ props.message.thread_message ? props.message.thread_message.content : props.message.thread_our_message.content }}</p>
                    <!--<p>{{ message }}</p>-->
                </div>
                <div class="pt-3 pb-2 pl-3 mt-0">
                    <div class="message-content" :class="props.message.lang === 'AR' ? 'text-right' : ''" :dir="props.message.lang === 'AR' ? 'rtl' : 'ltr'">{{ props.message.content }} </div>
                </div>
            </div>
            <div v-else-if="!props.message.post_id || props.message.post_id === props.message.thread_id || (props.message.thread_message == null || props.message.thread_our_message == null)" class="message message-customer shadow-sm">
                <div class="message-content" :class="props.message.lang === 'AR' ? 'text-right' : ''" :dir="props.message.lang === 'AR' ? 'rtl' : 'ltr'">{{ props.message.content }} </div>

            </div>

            <!-- Message Attachments -->
            <message-attachments v-if="props.message.attachments || props.message.location" v-bind:attachments="props.message.attachments" v-bind:location="props.message.location"></message-attachments>


        </div>

    </div>
</template>

<script>


    // Vuex
    import { mapGetters,mapActions } from 'vuex';
    import { Marker,pMap,InfoWindow, gmapApi } from 'vue2-google-maps';
    import { create } from "vue-modal-dialogs";
    import MessageHide from "./Modal/MessageHide";
    import MessageDelete from "./Modal/MessageDelete";
    import ComplaintStatus from "./Modal/ComplaintStatus";
    import TicketEdit from "./Modal/MessageTicketEdit";

    export default {

        components: { Marker, Map, InfoWindow, gmapApi },

        mounted: function () {
            // Component mounted
            //console.log('Content render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
        },


        data: function() { return {
            thread : 0,
        } },

        methods: {
        // Hide from Social Account
        message_hide: async function(account_id, message_id, status) {

          // Show Message Hide modal
          const message_hide = create(MessageHide, "account_id", "message_id", "status");

          if (await message_hide(account_id, message_id, status) ){
            // Refresh account list
            //this.fetchLastConfirm(customer_id);
            //

          }

        },

        // Remove from Social Account
        message_delete: async function(account_id, message_id, place) {

          // Show account Block modal
          const message_delete = create(MessageDelete, "account_id", "message_id", "place");

          if (await message_delete(account_id, message_id, place) ){
            // Refresh account list
            //this.fetchLastConfirm(customer_id);
            //

          }

        },

        // Complaint Status Changer
        complaint_status_changer: async function(account_id, complaint_id, status) {

          // this.complaintStatusChanger({account_id: account_id, complaint_id: complaint_id, status: status});
          // Show account Block modal
          const complaint_status_changer = create(ComplaintStatus, "account_id", "complaint_id", "status");

          if (await complaint_status_changer(account_id, complaint_id, status) ){
            // Refresh account list
            //this.fetchLastConfirm(customer_id);
            //

          }

        },

        message_ticket_edit: async function(ticket_id, case_id, inbox_id, customer_tickets) {
          // Show Ticket edit modal
          const ticket_edit = create(TicketEdit, "ticket_id", "case_id", "inbox_id", "customer_tickets");

          if (await ticket_edit(ticket_id, case_id, inbox_id, customer_tickets) ){

            // this.$close(true);

          }

        },

      },

        computed: {
            // Map getters
            ...mapGetters({

                getThreadId: 'Reply/getThreadId',
                customer_tickets: 'Ticket/getCustomerTickets',

            }),

            thread: {

                // getter
                get: function () {
                    // Get Schedule Data
                    return this.getThreadId;
                    //return '1217100949245825025';
                },

            },

            ticket_toggle() {
                if(props.disabled_areas.ticket_edit) {
                    return ['']
                }
                return ['user-info-sidebar-right-tablet', 'plus-col-accounts-sidebar','plus-col-main-list-inbox-tablet', 'plus-col-main-messages-panel-mobile']
            }

            /*// Thread Id
            thread() {

                return this.$store.getters.getThreadId();

            },*/
        },

        watch: {
            // whenever question changes, this function will run
            thread: function (thread, previous_thread) {

                // Leave
                console.log(previous_thread);

                // Listen
                this.start_echo();
                return thread;
            }
        },

    }
</script>


