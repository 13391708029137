<template>

    <!-- List persons -->
  <b-collapse class="plus-col-main-list-inbox-container col col-xl-3" id="plus-col-main-list-inbox-container" v-model="expand_inbox_list">
      <template v-if="window_width >= 992" >
        <div class="plus-col-main-list-inbox d-flex flex-column" id="plus-col-main-list-inbox">
            <inbox-list-main-menu></inbox-list-main-menu>
        </div>
      </template>

      <!-- List Inbox collapsable interactive component for tablet -->
      <template v-else-if="window_width >= 576 && window_width < 992">
        <b-collapse visible class="plus-col-main-list-inbox-tablet" id="plus-col-main-list-inbox-tablet" v-model="expand_inbox_list">
          <inbox-list-main-menu></inbox-list-main-menu>


        </b-collapse>
      </template>

      <!-- List Inbox component for mobile -->
      <template v-else>
        <b-collapse class="plus-col-main-list-inbox-mobile" id="plus-col-main-list-inbox-mobile" v-model="expand_inbox_list">
          <inbox-list-main-menu></inbox-list-main-menu>
        </b-collapse>
      </template>


  </b-collapse>

  
  

</template>

<script>

    // Scrollbar
    // import PerfectScrollbar from 'perfect-scrollbar';

    // Vuex
    import {mapGetters, mapActions} from 'vuex';

    // TreeSelect
    // import Treeselect from '@riophae/vue-treeselect';
    // import {create} from "vue-modal-dialogs";
    // import MessageAdd from "../Inbox/Modal/MessageAdd";

    export default {

        // components: { Treeselect },

        created(){
          console.log('Create lifescylce InboxList is_expanded status is ' + this.is_expanded);
        },

        mounted: function () {
            // Component mounted
            console.log('Inbox List component mounted.');

            // // Mount perfect scrollbar
            // if(this.$refs.list){
            //   this.ps = new PerfectScrollbar(this.$refs.list);
            // }

            // Update Customer Type
            // this.UpdateCustomerTypes();

            // Get Inbox
            // this.fetchInbox({account_id: this.account_id, inbox_type: this.inbox_type});

            // this.eventController();

            console.log('type:::' + this.$route.params.type);

            this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
            });
        },

        updated() {
          console.log('Update lifescylce InboxList is_expanded status is ' + this.is_expanded);
        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        destroyed() {
            // Component destroyed
            console.log('Inbox List component destroyed.');
            // if (this.inbox_type === 'player') {
            //   Array.from(this.accounts).forEach(account => {
            //     this.leave_echo(account.id);
            //   });
            // }
            this.updateIsInboxExpanded(true);
            this.updateIsMessagesExpanded(false);
        },

        data: function () {
            return {
                // ps: null,
                // inbox_type: this.$route.params.type,
                window_width: window.innerWidth,
            }
        },

        methods: {


            // changeIsExpanded() {
            //   if(this.window_width < 576) {
            //     this.updateIsInboxExpanded(false);
            //     this.updateIsMessagesExpanded(true);
            //   } else {
            //     return null;
            //   }

            // },

            onResize() {
                this.window_width = window.innerWidth;
            },

            // // Scroll top
            // scrollTop: async function () {

            //     if (this.ps) {
            //       // Scroll update
            //       this.ps.update();
            //     }

            //     if (this.$refs.list) {
            //       // Scroll to top
            //       this.$refs.list.scrollTop = 0;
            //     }

            // },

            // // Listen
            // start_echo: async function (account_id) {

            //     Echo.private('inbox.account.' + account_id)
            //         .listen('InboxChange', (e) => {
            //             console.log(e);
            //             console.log('new inbox event came!');
            //             if (e['status']) {
            //                 this.updateCustomerAssignData(e)
            //                 this.updateCustomerAssign(e)
            //             }else {
            //                 this.updateInboxList({data: e, inbox_type: this.inbox_type});
            //             }
            //         });
            // },

            // // Leave Listener
            // leave_echo: async function (account_id) {

            //   console.log('leave echo ' + account_id);

            //   Echo.leave('inbox.account.' + account_id);

            // },

            // // Update message page
            // updatePage: async function () {
            //   if (this.inbox_type !== 'player') {
            //     await this.$router.push({
            //       name: 'inbox_list',
            //       params: {id: this.account_id, int: this.integration.id, type: String(this.inbox_type)}
            //     }).catch(err => {});
            //   }
            //   if (this.inbox_type === 'player') {
            //     await this.$router.push({
            //       name: String(this.inbox_type)
            //     }).catch(err => {});
            //   }
            //   await this.fetchInbox({ account_id: this.account_id, inbox_type: this.inbox_type });
            //   await this.scrollTop();
            // },

            // normalizer: function (node) {
            //     return {
            //         id: node.id,
            //         label: node.name,
            //         children: node.children,
            //     }
            // },

            // addMessage: async function() {
            //   // Show Add Message by URL modal
            //   const message_add = create(MessageAdd, 'integration');

            //   if (await message_add(this.integration.id) ){
            //     // Refresh Inbox list
            //     this.close(true);
            //   }
            // },

            // loadMore: function(direction) {
            //     this.loadMoreInbox({ account_id: this.account_id, direction: direction, inbox_type: this.inbox_type });
            // },

            // eventController: function() {
            //     if (this.inbox_type === 'player') {
            //       if (!this.accounts.length) {
            //         this.fetchAccounts().then(() => {
            //           Array.from(this.accounts).forEach(account => {
            //             this.leave_echo(account.id);
            //           });

            //           // Listen
            //           Array.from(this.accounts).forEach(account => {
            //             this.start_echo(account.id);
            //           });
            //         });
            //       }else{
            //         Array.from(this.accounts).forEach(account => {
            //           this.leave_echo(account.id);
            //         });

            //         // Listen
            //         Array.from(this.accounts).forEach(account => {
            //           this.start_echo(account.id)
            //         });
            //       }
            //     }else{
            //       // Listen
            //       this.start_echo(this.account_id);
            //     }
            // },

            // getIntegration: function(customer) {
            //     if (this.inbox_type === 'player') {
            //       if (customer.twitter !== null) {
            //         return 'twitter';
            //       }
            //       if(customer.instagram !== null){
            //         return 'instagram';
            //       }
            //       if(customer.facebook !== null){
            //         return 'facebook';
            //       }
            //       if(customer.whatsapp !== null){
            //         return 'whatsapp';
            //       }
            //       if(customer.sikayetvar !== null){
            //         return 'sikayetvar';
            //       }
            //       if(customer.youtube !== null){
            //         return 'youtube';
            //       }
            //       if(customer.googleplay !== null){
            //         return 'googleplay';
            //       }
            //     }else{
            //       return this.$route.params.int;
            //     }
            // },

            // getDestination: function(customer) {
            //     if (this.inbox_type === 'player') {
            //       let integration = this.getIntegration(customer);
            //       return {
            //         name: 'player_messages',
            //         params: { id: customer.account_id, int: integration, customer_id: customer.id }
            //       }
            //     }else{
            //       // return {
            //       //   name: this.inbox_type+'_messages',
            //       //   params: { customer_id: customer.id }
            //       // }
            //       return {
            //         name: 'messages',
            //         params: { customer_id: customer.id }
            //       }
            //     }
            // },

            // Map actions
            ...mapActions('Inbox', [
                // 'fetchInbox',
                // 'updateInboxList',
                // 'loadMoreInbox',
                // 'updateCustomerAssign',
                // 'updateCustomerSort',
                // 'updateCustomerSortID',
                // 'updateIsCancelTransfer',
                // 'updateInboxZeroPaginationStatus',
                'updateIsInboxExpanded',
                'updateIsMessagesExpanded',
                // 'updateIsPanelExpanded',
            ]),

            // // Map actions
            // ...mapActions('Customer', [
            //     'UpdateCustomerTypes',
            //     'updateCustomerAssignData',
            // ]),

            // ...mapActions('Accounts', [
            //   'fetchAccounts',
            // ]),

        },


        computed: {

            // Map getters
            ...mapGetters({

                // // Customers
                // customers: 'Inbox/getCustomers',

                // // Cancel transfer Customers
                // cancel_transfer_customers: 'Inbox/getCancelTransferCustomers',

                // // Customer ID
                // customer: 'Customer/getCustomerID',

                // // Get account ID
                // account_id: 'Accounts/getAccount',

                // accounts : 'Accounts/getAccounts',

                // // Get account Integration id
                // integration: 'Accounts/getIntegration',

                // // Next Page URL
                // next_page: 'Inbox/getNextPage',

                // // Prev Page URL
                // prev_page: 'Inbox/getPrevPage',

                // // Permission
                // getPermission: 'Inbox/getPermission',

                // // Options for tree select
                // customer_types: 'Customer/getCustomerTypes',

                // // Inbox Customer Sort
                // customer_sort: 'Inbox/getCustomerSort',

                // // Inbox Customer Sort ID
                // customer_sort_id: 'Inbox/getCustomerSortID',

                // // Get Is Cancel Transfer
                // is_cancel_transfer: 'Inbox/getIsCancelTransfer',

                // // Get Player Status
                // in_play: 'Player/getPlayer',

                // // Get Player Mode
                // player_mode: 'Configurations/getPlayerMode',

                // // Get Inbox Zero Pagination Status
                // inbox_zero_pagination_status: 'Inbox/getInboxZeroPaginationStatus',

                //
                is_inbox_expanded: 'Inbox/getIsInboxExpanded',
                is_messages_expanded: 'Inbox/getIsMessagesExpanded',



            }),

            expand_inbox_list: {
              get() { 
                return this.is_inbox_expanded; 
              },
              set(value) { 
                this.updateIsInboxExpanded(value); 
            }
           },

           expand_messages_list: {
              get() { 
                return this.is_messages_expanded; 
              },
              set(value) { 
                this.updateIsMessagesExpanded(value); 
            }
           },



            // // Permission Error
            // permission_error: {

            //     // getter
            //     get: function () {
            //         // Get Schedule Data
            //         return this.getPermission;
            //     },

            // },

            // toggle_binding_inbox() {
            //   if (this.window_width < 576) {  
            //   return {
            //       'aria-controls' : 'plus-col-main-list-inbox-container plus-col-main-messages-panel-mobile',
            //       'aria-expanded' : this.is_inbox_expanded ? 'true' : 'false',
            //       class : this.is_inbox_expanded ? null : 'collapsed',
            //     }
            //   }
            //   return {};
            // },

            // // Customer Types
            // customer_type: {

            //     // getter
            //     get: function () {
            //         // Get Customer Gender
            //         return this.customer_sort;
            //     },

            //     // setter
            //     set: function (value) {
            //         // Check if we have value?
            //         if (value) {

            //             let sort_array = [];
            //             value.forEach(obj => {
            //                 sort_array.push(obj.id);
            //             });
            //             console.log(sort_array);

            //             // Update Customer Type
            //             this.updateCustomerSortID(sort_array);
            //             this.updateCustomerSort(value);

            //             console.log('Customer Sort: ' + this.customer_sort_id);

            //             this.fetchInbox({
            //                 account_id: this.account_id,
            //                 inbox_type: this.$route.params.type,
            //                 sort: this.customer_sort_id
            //             });
            //         }
            //     },

            // },

            // // Is VIP
            // vip_sort: {

            //     // getter
            //     get: function () {
            //         // Get VIP Sort Data
            //         return this.vip_sort;

            //     },

            // },

            // // Is Canceled transfer
            // is_canceled_transfer: {

            //     // getter
            //     get: function () {
            //         // Get Schedule Data
            //         return this.is_cancel_transfer;
            //     },

            //     // setter
            //     set: function (value) {
            //         this.updateIsCancelTransfer(value);
            //     }

            // },

            // // Pagination Status for inbox Zero in Play Mode
            // pagination_activator: {

            //     // getter
            //     get: function () {
            //         // Get Schedule Data
            //         return this.inbox_zero_pagination_status;
            //     },

            //     // setter
            //     set: function (value) {
            //         this.updateInboxZeroPaginationStatus(value);
            //     }

            // },

            // // Order Customer List
            // orderedUsers: function () {
            //     return _.orderBy(this.customers.customer_type, 'is_vip', '!==', false)

            // },

            // sortedArray: function () {
            //     function sortChange(a, b) {
            //         if (a.customer_type && b.customer_type && a.customer_type.is_vip > b.customer_type.is_vip) return -1;
            //         if (a.customer_type && b.customer_type && a.customer_type.is_vip < b.customer_type.is_vip) return 1;
            //         if (a.customer_type && b.customer_type && a.customer_type.is_important > b.customer_type.is_important) return -1;
            //         if (a.customer_type && b.customer_type && a.customer_type.is_important < b.customer_type.is_important) return 1;
            //         return 0;
            //     }

            //     return this.customers.sort(sortChange); // shallow clone + sort
            // },
        },

    }
</script>