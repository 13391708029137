<template>

    <div class="container-fluid">
    <div class="row flex-column">

    <span>
        <!-- Customer Type -->
        <template v-if="location">

            <!-- Check if customer has location -->
            <template>
                <font-awesome-icon id="location" :icon="'map-marker-alt'" class="plus__icon"/>  <span class="font-weight-light ml-2">{{ $t('customer.customer_location') }}</span>
                <b-tooltip v-if="location" target="location">

                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.address') }}: </strong>{{ location.formatted_address }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.country') }}: </strong>{{ location.country }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.country_code') }}: </strong>{{ location.country_code }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.county') }}: </strong>{{ location.county }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.district') }}: </strong>{{ location.district }}</p>
                    <p class="customer-location mb-0 mt-0"><strong>{{ $t('customer.postal_code') }}: </strong>{{ location.zip }}</p>

                </b-tooltip>
                <b-tooltip v-else target="location">
                <span>{{ $t('customer.no_location') }}</span>
            </b-tooltip>

            </template>

        </template>

    </span>

    </div>
    </div>


</template>

<script>

    export default {

        props: ['location'],

        mounted: function () {
            // Component mounted
            //console.log('Customer Type Render mounted')
        },

        destroyed() {
            // Component destroyed
            //console.log('Customer Type Render mounted destroyed.');
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>