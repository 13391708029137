<template>
    <div>
        <hr>
        <b-container>
            <b-row class="align-items-center">
                <b-col cols="9">

                    <file-pond v-if="getSettings.attachments" 
                        name="files" :server="{
                            url: '/api/v1/inbox/answer/upload',
                            process: {
                                headers: {
                                    'X-CSRF-TOKEN': csrfToken
                                }
                            }
                        }" 
                        :files="myFiles" 
                        ref="pond" 
                        allowFileSizeValidation="true" 
                        allowFileTypeValidation="true"
                        
                        allowReplace="false" allowRevert="false" 
                        instantUpload="false"
                        :allowMultiple="getSettings.allowMultiple" 
                        :maxFileSize="getSettings.maxFileSize"
                        :maxFiles="getSettings.maxFiles" 
                        :acceptedFileTypes="getSettings.acceptedFileTypes"
                        :label-idle="$t('page.upload_file')" 
                        v-on:init="handleFilePondInit"
                        v-on:processfile="handleProcessFile" 
                        
                        @addfile="addFile" @addfilestart="addFileCheck"
                        @removefile="removeFileCheck" :beforeAddFile="beforeAddFile" />

                </b-col>


                <b-col cols="3" v-if="getPixelImage">
                    <div @click="pixel" class="shadow border border-danger mb-3 pointer">
                        <b-img thumbnail fluid :src="getPixelImage"></b-img>
                    </div>
                </b-col>

            </b-row>
        </b-container>


    </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import validate type
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import validate size
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Pixel Render For Edit
import PixelRender from '../Module/Pixel/PixelRender.vue';

import { create } from 'vue-modal-dialogs'

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

import { mapGetters, mapActions } from 'vuex';

export default {

    components: {
        FilePond
    },

    mounted: function () {
        // Component mounted
    },

    destroyed() {
        // Component destroyed
    },

    created: function () {
        // Component created
    },

    data: function () {

        return {

            myFiles: [],

            settings: {
                attachments: true,
                allowMultiple: null,
                maxFileSize: null,
                maxFiles: null,
                acceptedFileTypes: null
            },
            csrfToken: document.querySelector('meta[name="csrf-token"]').content

        };

    },

    computed: {

        // Map getters
        ...mapGetters({

            // Integration
            integration: 'Accounts/getIntegration',

            getAccount: 'Accounts/getAccount',

            getAccountId: 'Accounts/getAccountID',

            getCustomer: 'Customer/getCustomer',

            // Get Files
            getAttachFiles: 'Reply/getAttachFiles',

            // Get Pixel Image
            getPixelImage: 'Reply/getPixelImage',

            // Get Pixel Image Json
            getPixelImageToJson: 'Reply/getPixelImageToJson',

            // Integration type
            integration_type: 'Accounts/getIntegrationType',

        }),

        // Is Reply
        accountId: {

            // getter
            get: function () {
                // Get Schedule Data
                return this.getAccount;
            },

        },

        totalFiles: {
            // getter
            get: function () {
                // Get file array Data
                return this.getAttachFiles;
            },
        },

        getSettings: function () {

            return {
                attachments: this.settings.attachments ? this.settings.attachments : this.integration_type.attachments,
                allowMultiple: this.settings.allowMultiple ? this.settings.allowMultiple : this.integration_type.attachment_multiple,
                maxFileSize: this.settings.maxFileSize ? this.settings.maxFileSize : this.integration_type.attachment_max_file_size,
                maxFiles: this.settings.maxFiles ? this.settings.maxFiles : this.integration_type.attachment_max_file_count,
                acceptedFileTypes: this.settings.acceptedFileTypes ? this.settings.acceptedFileTypes : this.integration_type.attachment_types

            };

        }

    },

    methods: {

        // Some actions detka
        ...mapActions('Reply', [

            'updateAttachFiles',
            'setAttachFiles',

        ]),

        handleFilePondInit: function () {
            console.log('FilePond has initialized');
            // FilePond instance methods are available on `this.$refs.pond`

        },

        store() {
            //Assign all fields to formData to send via post
            const formData = new FormData();

            //Set all files to formData
            for (let i = 0; i < this.myFiles.length; i++) {
                let file = this.myFiles[i];
                formData.append("files[" + i + "]", file);
            }
            console.log(this.$refs.pond.status);
            this.$store
                .dispatch("store", formData)
                .then(response => {
                    //this.setAttachFiles(this.$refs.pond.getFiles());
                    //console.log('serverid : ' + this.$refs.pond.getFiles('serverId'));
                    this.$snotify.success("Success");
                })
                .catch(error => {

                    this.$snotify.error("Error");
                });
        },

        // setMetadata: function() {
        //     let account_id = this.getAccount.id;
        // },

        addFile: function (error, file) {


            console.log('File added');
            console.log(file);
            file.setMetadata('account_id', this.getCustomer.account_id);

            console.log('Hook New file has been added ');
            // Check files
            //this.checkFile();
            //Set Images to Array
            //this.myFiles = files(files => files.file);
            console.log(this.$refs.pond.getFiles());
            //console.log(file.getMetadata('account_id'));


            /*//Assign all fields to formData to send via post
            const formData = new FormData();

            //Set all files to formData
            for (let i = 0; i < this.myFiles.length; i++) {
                let file = this.myFiles[i];
                formData.append("files[" + i + "]", file);
            }

            this.$store
                .dispatch("Reply/updateAttachFiles", formData)
                .then(response => {
                    this.$snotify.success("Success");
                })
                .catch(error => {
                    this.$snotify.error("Error");
                    //console.log(error);

                });*/
            //console.log(this.$refs.pond.status);
        },

        addFileCheck: function (file) {

            console.log('File started to load');
            console.log(file);



            console.log('Hook New file trying to be added ');

            // Check files
            this.checkFile(file);
        },

        handleProcessFile: function (error, file) {
            console.log('done', file.serverId);
            this.updateAttachFiles(file.serverId);
            console.log('attached files: ', this.totalFiles);
        },




        checkFile: function (file) {

            let total = this.checkTotalFiles();
            // Add Pixel/Makro Photo in Counts
            if (this.getPixelImage) {
                this.settings.maxFiles = this.integration_type.attachment_max_file_count - 1;
                total = total + 1;
            }
            if (this.getPixelImage && this.getSettings.maxFiles === 1) {
                this.removeFiles();
            }

            console.log('total files' + total);

            if (this.integration.id == 'twitter') {

                console.log('file type is ' + file.fileType);

                if (file.fileType == this.integration_type.attachment_video_type || file.fileType === this.integration_type.attachment_gif_type) {

                    this.settings.acceptedFileTypes = this.integration_type.attachment_video_types;

                    this.settings.maxFiles = 1;

                    // Total files more than one
                    // Remove all files detka
                    if (total > 1) {

                        this.removeFiles();
                    }

                } else {

                    //alert('attachment type is image');

                    this.settings.acceptedFileTypes = this.integration_type.attachment_image_types;

                }


            }

            //} 

        },


        checkTotalFiles: function () {

            return this.$refs.pond.getFiles().length;

        },

        removeFiles: function () {


            return this.$refs.pond.removeFiles();

        },

        removeFileCheck: function () {

            let total = this.checkTotalFiles();

            if (!total) {

                // Reset datas
                this.settings.acceptedFileTypes = null;
                this.settings.maxFiles = null;

            }

        },


        pixel: async function () {

            // Show image modal
            const pixel_render = create(PixelRender, 'template_image_json', 'template_type');

            if (await pixel_render(this.getPixelImageToJson.toJSON(), 1)) {
                // Nothing here yet

            }
        },

        beforeAddFile(file) {
            console.log('beforeAddFile', file)

            return new Promise((resolve) => {
                if (file.fileType.includes('video')) {
                    let video = document.createElement('video')
                    video.preload = 'metadata'
                    video.onloadedmetadata = function () {
                        if (video.duration < 2) {
                            Vue.toasted.error(Vue.i18n.translate('simple.whatsapp_attachment_duration_error', {
                                seconds: 2,
                            }));
                            resolve(false)
                        }
                        resolve(true)
                    }
                    video.src = window.URL.createObjectURL(file.file)
                } else {
                    resolve(true)
                }
            })
        }
    },

    watch: {},

}
</script>