<template>

    <b-container fluid >

        <div class="row flex-md-nowrap">

            <template v-if="windowWidth >= 768">
                <div class="plus-col-main-messages-panel col">
                    <messages-main-panel :customer_id="customer_id"></messages-main-panel>
                </div>
            </template>

            <template v-else>
                <b-collapse class="plus-col-main-messages-panel mobile col" id="plus-col-main-messages-panel-mobile" v-model="expand_messages_list">
                    <messages-main-panel :customer_id="customer_id"></messages-main-panel>
                </b-collapse>
            </template>
            
            <!-- Toggleable sidebar view for lg and smaller screens -->
            <template v-if="windowWidth < 1200">
                <b-collapse class="plus-col-main-panel panel-right bg-white col-md-3 col-12" id="user-info-sidebar-right-tablet">
                    <!-- Right Panel -->
                    <right-panel :customer="customer"></right-panel>
                </b-collapse>
            </template>

            <!-- Not-toggleable view for screens bigger than lg-->
            <template v-else>
                <div class="plus-col-main-panel panel-right bg-white col-md-3 col-12">
                    <!-- Right Panel -->
                    <right-panel :customer="customer"></right-panel>
                </div>
            </template>


        </div>

    </b-container>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions, mapMutations} from 'vuex';
    // import Vue from 'vue';

    //scroll to bottom
    // import VueChatScroll from 'vue-chat-scroll';

    // Vue.use(VueChatScroll);

    // import PixelRender from '../Module/Pixel/PixelRender.vue';

    // import {create} from 'vue-modal-dialogs'

    export default {

        props: ['customer_id'],

        mounted() {
            // Component mounted
            console.log('Messages Render component mounted.');

            // Fetch messages
            this.fetchLast();

            // Listen
            this.start_echo();

            this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
            });


        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

        destroyed() {
            // Component destroyed
            console.log('Messages Render component destroyed.');
            this.leave_echo(this.customer_id);
            this.updateIsInboxExpanded(true);
            this.updateIsMessagesExpanded(false);
        },

        created() {
            // Component created
            console.log('Messages Render Messages component created.');
            // console.log("is_expanded created status is : " + this.is_expanded);
            this.fetchCustomerTickets(this.customer_id);

        },


        data: function () {
            return {

                // same_user: true,

                // timeline: false,

                // pagination: {'current_page': this.current ?? 1, 'total': this.total, 'per_page': this.perpage},

                // moment: this.$moment,

                // template_image_json: this.getPixelImageToJson,

                inbox_type: this.$route.params.type,

                windowWidth: window.innerWidth,
            }
        },

        metaInfo: function () {

            return {
                title: this.getTitle,
            }

        },

        methods: {
            // // Timeline action
            // timeline_action: function () {
            //     // Enable/disable at the same time
            //     this.timeline = !this.timeline;
            // },

            // updateTo: function (customer, message) {
            //     this.updateReplyTo({customer: customer, message: message});

            //     // Assign Customer
            //     this.assignCustomer('set');

            // },


            onResize() {
                this.windowWidth = window.innerWidth;
            },

            // Messages
            ...mapActions('Messages', [
                // 'refreshMessages',
                // 'updatePage',
                'fetchFromLastPage',
                'fetchLastConfirm',
                // 'updateMessages',
                // 'updateQA',
            ]),

            // Reply
            ...mapActions('Reply', [
                // 'resetActiveTab',
                // 'updateReplyTo',
                'resetThreadId',
            ]),

            // Inbox
            ...mapActions('Inbox', [
                // 'updateInbox',
                'updateIsInboxExpanded',
                'updateIsMessagesExpanded',
                // 'updateIsPanelExpanded',
            ]),

            // Confirmation
            ...mapActions('Confirmation', [
                // 'updateInboxConfirmation',
            ]),

            // Trash
            ...mapActions('Trash', [
                // 'createTrash',
                // 'restoreTrash',
            ]),


            // Customer
            ...mapActions('Customer', [
                // 'assignCustomer',
            ]),

            ...mapActions('Ticket', [
                // 'updateTicketID',
                'updateTicketAssigned',
                'fetchCustomerTickets',
                // 'fetchCustomerTasks',
            ]),

            // Update message page
            // updateMessagePage: async function (page) {

            //     // Current page
            //     this.pagination.current_page = page;

            //     //  Understand for pagination action(paging:this.paging)

            //     this.updatePage({customer_id: this.customer_id, page: page, same_user:this.same_user});

            // },

            // Listen
            start_echo: async function () {

                Echo.private('confirmation-' + this.account_id)
                    //.subscribe('ConfirmationEvent');
                    .listen('ConfirmationEvent', (e) => {
                        console.log(e);
                        console.log('new confirmation came!');
                        this.fetchLastConfirm(this.customer_id);
                    });

                Echo.private('inbox.customer.' + this.customer_id)
                    .listen('InboxEvent', (e) => {
                        console.log(e);
                        console.log('sent it to messages');
                        this.fetchLastConfirm(this.customer_id);
                        //this.updateMessages(e);
                        //this.updateOnConfirm({ customer_id: this.customer_id, page: this.pagination.current_page });
                        //this.updateOnConfirm({ customer_id: this.customer_id, page: this.pagination.current_page });
                    });


            },

            // Leave
            leave_echo: async function (customer_id) {

                console.log('Messages leave echo ' + customer_id);


                Echo.leave('inbox.customer.' + customer_id);
                Echo.leave('confirmation-' + this.account_id);

            },

            // pixel: async function () {

            //     // Show image modal
            //     const pixel_render = create(PixelRender, 'template_image_json', 'template_type');

            //     if(this.getPixelImageToJson) {
            //         if (await pixel_render(this.getPixelImageToJson.toJSON(), 1)) {
            //             // Nothing here yet
            //         }
            //     }else{
            //         if (await pixel_render(null, 2)) {
            //             // Nothing here yet
            //         }
            //     }
            // },

            fetchLast: function () {

                // Fetch new messages on customer change
                this.fetchFromLastPage(this.customer_id);
            },

            // trash: function () {

            //     this.createTrash();

            // },

            // restore: function () {

            //   // Restore Trash
            //   this.restoreTrash(this.customer_id);

            // },

        },


        computed: {
        // Map getters
        ...mapGetters({
          customer: 'Customer/getCustomer',
          user: 'User/getUser',
          messages: 'Messages/getMessages',
          // Get account
          account_id: 'Accounts/getAccount',
          is_inbox_expanded: 'Inbox/getIsInboxExpanded',
          is_messages_expanded: 'Inbox/getIsMessagesExpanded',
        //   account: 'Accounts/getAccountData',
        //   // Reply
        //   getIsReply: 'Reply/getIsReply',
        //   thread: 'Reply/getThreadId',
        //   // Get Pixel Image
        //   getPixelImageToJson: 'Reply/getPixelImageToJson',
        //   total: 'Messages/getTotal',
        //   current: 'Messages/getCurrent',
        //   perpage: 'Messages/getPerPage',
        //   intActive: 'Integrations/getIntActive',

        }),

        expand_inbox_list: {
              get() { 
                return this.is_inbox_expanded; 
              },
              set(value) { 
                this.updateIsInboxExpanded(value); 
            }
        },

        expand_messages_list: {
              get() { 
                return this.is_messages_expanded; 
              },
              set(value) { 
                this.updateIsMessagesExpanded(value); 
            }
        },

        // Is Reply
        // isReply: {

        //   // getter
        //   get: function () {
        //     // Get Schedule Data
        //     return this.getIsReply;
        //   },

        // },
        // Thread Id
        // thread: {

        //   // getter
        //   get: function () {
        //     // Get thread Data
        //     return this.getThreadId;
        //   },

        // },


        // getName: function () {

        //   // Prepare title
        //   let name;

        //   if (this.customer) {

        //     name = this.customer.username;
        //   }

        //   return name;
        // },

        getTitle: function () {

          // Prepare title
          let title;

          if (this.customer) {

            title = 'View & Resolve => ' + this.customer.username;
          }

          return title;
        }

      },

        watch: {
            // whenever question changes, this function will run
            customer_id: function (customer_id, previous_customer_id) {
                // Fetch Messages From Last Page
                this.fetchLast();

                this.updateTicketAssigned(true);

                // Get customer's all tickets (Waiting for Api)
                this.fetchCustomerTickets(this.customer_id);

                // Listen
                this.start_echo();

            },
        },


    }
</script>