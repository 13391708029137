<template>

    <!-- Reports Area -->
    <div class="convoreport" ref="chartdiv" v-if="categoryReports.length">
    </div>
    <div class="convoreport report-overlay" ref="fakechart" v-else>
    </div>

</template>

<script>

// Vuex
import { mapGetters, mapActions } from 'vuex';

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

/* Imports */
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
// am4core.useTheme(am4themes_animated);
// Themes end


export default {
    // works with selected.patent_id
    props: ['categoryReports'],

    mounted() {
        let root;

        console.log('xy', this.categoryReports )

        if (this.categoryReports.length > 0) {
            root = am5.Root.new(this.$refs.chartdiv);
        } else {
            root = am5.Root.new(this.$refs.fakechart)
        }
        root.setThemes([am5themes_Animated.new(root)]);

        let raw_data = this.categoryReports.length ? this.categoryReports : this.fakeSecondaryCategoryReports;

        let data = raw_data.filter((ele) => ele.value !== 0).sort((a, b) => b.value - a.value)

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
        xRenderer.labels.template.setAll({
            rotation: -90,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight: 15
        });

        xRenderer.grid.template.setAll({
            location: 1
        })

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "second_category",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1
            })
        }));

        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            sequencedInterpolation: true,
            categoryXField: "second_category",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            })
        }));

        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
        series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        xAxis.data.setAll(data);
        series.data.setAll(data);


        if (this.categoryReports.length > 0) {
            am5plugins_exporting.Exporting.new(root, {
                menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                dataSource: data,
                filePrefix: "convoTypes",
            });
        }

        this.root = root;



        // // Create chart instance
        // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
        // chart.scrollbarX = new am4core.Scrollbar();

        // // Add data
        // chart.data = this.categoryReports;

        // // Create axes
        // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        // categoryAxis.dataFields.category = "second_category";
        // categoryAxis.renderer.grid.template.location = 0;
        // categoryAxis.renderer.minGridDistance = 30;
        // categoryAxis.renderer.labels.template.horizontalCenter = "right";
        // categoryAxis.renderer.labels.template.verticalCenter = "middle";
        // categoryAxis.renderer.labels.template.rotation = 270;
        // categoryAxis.tooltip.disabled = true;
        // categoryAxis.renderer.minHeight = 110;

        // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // valueAxis.renderer.minWidth = 50;

        // // Create series
        // let series = chart.series.push(new am4charts.ColumnSeries());
        // series.sequencedInterpolation = true;
        // series.dataFields.valueY = "value";
        // series.dataFields.categoryX = "second_category";
        // series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
        // series.columns.template.strokeWidth = 0;

        // series.tooltip.pointerOrientation = "vertical";

        // series.columns.template.column.cornerRadiusTopLeft = 10;
        // series.columns.template.column.cornerRadiusTopRight = 10;
        // series.columns.template.column.fillOpacity = 0.8;

        // // Sorting
        // categoryAxis.sortBySeries = series;

        // // on hover, make corner radiuses bigger
        // let hoverState = series.columns.template.column.states.create("hover");
        // hoverState.properties.cornerRadiusTopLeft = 0;
        // hoverState.properties.cornerRadiusTopRight = 0;
        // hoverState.properties.fillOpacity = 1;

        // series.columns.template.adapter.add("fill", function(fill, target) {
        //         return chart.colors.getIndex(target.dataItem.index);
        // });

        // // Cursor
        // chart.cursor = new am4charts.XYCursor();

        // // Export Menu to chart's
        // chart.exporting.menu = new am4core.ExportMenu();
        // // File names will be myExport.png, myExport.pdf, etc
        // chart.exporting.filePrefix = "convoSecondary";


    },

    beforeDestroy() {
        // if (this.chart) {
        //     this.chart.dispose();
        // }

        if (this.root) {
            this.root.dispose();
        }
    },


    data: function () {
        return {}
    },


    methods: {},

    computed: {
        ...mapGetters({

            fakeSecondaryCategoryReports: 'Report/getFakeSecondaryCategories',

        }),
    },
}
</script>