<template>
        <b-alert variant="danger" v-if="permission_error" show>{{ permission_error }}</b-alert>
        <b-container fluid class="bg-white rounded py-2 shadow-widget border"  v-else-if="requests.length !== 0 && !permission_error">
            <b-row>
                <b-col>
                    <div class="message-actions confirmation-history-header sticky-top plus-page-header z-index-fix">
                        <div class="container-fluid py-2">
                            <h4 class="confirmation-header-text">{{ $t('page.confirmations_history') }}</h4>
                            <span class="text-muted font-weight-light">{{ $t('page.confirmations_history_desc') }}</span>
                        </div>
                        <div class="mx-3">
                            <!-- Pagination-->
                            <b-pagination v-if="total" :hide-goto-end-buttons="true" size="sm" :total-rows="total"
                                :value="current" @input="updateLogPage" :per-page="perpage"></b-pagination>
                            <!-- Refresh -->
                            <b-button :variant="'primary'" @click="refreshLogRequests" class="btn-sm">
                                <font-awesome-icon :icon="'sync'" />
                                {{ $t('default.refresh') }}
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="plus-col-main-messages pt-1 d-md-block" :class="{ 'd-none': customer_id }">
                    <!-- List messages -->
                    <div class="message-list" ref="requests"
                        v-chat-scroll="{ always: false, smooth: false, scrollonremoved: true, smoothonremoved: false }">
                        <div>
                            <v-wait for="requests" class="row">
                                <loading-messages slot="waiting" />
                                <template v-for="data in requests">
                                    <confirmation-logs v-bind:account="data.account" v-bind:confirmation="data.confirmation"
                                        v-bind:confirmation_id="data.confirmation_id" v-bind:draft="data.draft"
                                        v-bind:confirmation_note_id="data.confirmation_note_id"
                                        v-bind:confirmation_note="data.confirmation_note" v-bind:status="data.status"
                                        v-bind:review="data.reviewed_at" v-bind:user="data.user"></confirmation-logs>
                                </template>
                            </v-wait>
                        </div>
                    </div>
                </b-col>
                <b-col
                    class="col-12 col-md-3 relative plus-col-main-panel border panel-right position-initial-converter mt-1 z-index-fix"
                    v-if="customer_id && is_customer">
                    <b-btn size="sm" class="list-item-remove-button position-absolute" variant="danger" @click="resetCustomer">
                        <font-awesome-icon :icon="'times'" />
                    </b-btn>
                    <!-- Show customer info end edit -->
                    <customer-render :customer="customer"></customer-render>
                </b-col>
            </b-row>
        </b-container>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex';
export default {

    mounted: function () {
        // Component mounted
        console.log('Confirmations history component mounted.');
        this.resetCustomer()
    },

    beforeDestroy() {
        this.resetPermission()
    },

    destroyed() {
        // Component destroyed
        console.log('Confirmations history component destroyed.');
    },

    created: async function () {
        // Component created
        await this.fetchConfirmationLogs();
    },


    data: function () {
        return {}
    },

    metaInfo: function () {

        return {
            title: this.$t('page.confirmations_history'),
        }

    },
    methods: {

        // Actions
        ...mapActions('Confirmation', [
            'refreshLogRequests',
            'fetchConfirmationLogs',
            'updateLogPage',
            'resetPermission'
        ]),
        ...mapActions('Customer', [
            'resetCustomer',
        ]),

    },

    computed: {

        // Map getters
        ...mapGetters({

            requests: 'Confirmation/getLogRequests',
            confirmation_logs: 'Confirmation/getConfirmationLogs',
            total: 'Confirmation/getTotal',
            current: 'Confirmation/getCurrent',
            perpage: 'Confirmation/getPerPage',

            // Customer - Inbox Control
            is_customer: 'Inbox/getIsCustomer',
            is_ticket: 'Inbox/getIsTicket',

            customer: 'Customer/getCustomer',
            customer_id: 'Customer/getCustomerID',

            permission_error: 'Confirmation/getPermission',

        }),
    },
}
</script>
