<template>

        <!-- Reports Area -->
        <div class="convoreport" ref="chartdiv" v-if="categoryReports.length">
        </div>
        <div class="convoreport report-overlay" ref="fakechart" v-else>
        </div>

</template>

<script>

    // Vuex
    import {mapGetters, mapActions} from 'vuex';


    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";


    /* Imports */
    // import * as am4core from "@amcharts/amcharts4/core";
    // import * as am4charts from "@amcharts/amcharts4/charts";
    // import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    /* Chart code */
    // Themes begin
    // am4core.useTheme(am4themes_animated);
    // Themes end


    export default {

        props: ['categoryReports'],

        mounted() {

            // console.log(this.categoryReports);

            console.log('xy', this.categoryReports )


            let root;   
            if (this.categoryReports.length > 0) {
                root = am5.Root.new(this.$refs.chartdiv);
            } else {
                root = am5.Root.new(this.$refs.fakechart)
            }   
            root.setThemes([am5themes_Animated.new(root)]); 

            let raw_data = this.categoryReports.length ? this.categoryReports : this.fakePrimaryCategoryReports;    

            let data = raw_data.filter( (ele) => ele.value !== 0).sort( (a,b) => a.value - b.value)

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                // paddingLeft: 50,
                // paddingRight: 40,
            }));    
            let yRenderer = am5xy.AxisRendererY.new(root, {
                minGridDistance: 10
            });
            // yRenderer.grid.template.set("visible", false);

            let yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "primary_category",
                    renderer: yRenderer,
                    // paddingRight: 20
                })
            );

            let xRenderer = am5xy.AxisRendererX.new(root, {});
            xRenderer.grid.template.set("strokeDasharray", [3]);

            let xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    min: 0,
                    renderer: xRenderer
                })
            );

            let series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "primary_category",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: "value",
                    categoryYField: "primary_category",
                    sequencedInterpolation: true,
                    calculateAggregates: true,
                    maskBullets: false,
                    tooltip: am5.Tooltip.new(root, {
                        // dy: -30,
                        pointerOrientation: "vertical",
                        labelText: "{valueX}"
                    })
                })
            );

            series.columns.template.adapters.add("fill", function (fill, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", function (stroke, target) {
                return chart.get("colors").getIndex(series.columns.indexOf(target));
            });

            series.columns.template.setAll({
                strokeOpacity: 0,
                cornerRadiusBR: 10,
                cornerRadiusTR: 10,
                cornerRadiusBL: 10,
                cornerRadiusTL: 10,
                maxHeight: 40,
                fillOpacity: 0.8
            });

            series.data.setAll(data);
            yAxis.data.setAll(data);

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineX.set("visible", false);



            if( this.categoryReports.length > 0) {
                am5plugins_exporting.Exporting.new(root, {
                    menu: am5plugins_exporting.ExportingMenu.new(root, {}),
                    dataSource: data,
                    filePrefix: "convoTypes",
                });
            }

            this.root = root;









                // // Create chart instance
                // let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

                // // Add data
                // chart.data = this.categoryReports;

                // // Create axes
                // let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                // categoryAxis.dataFields.category = "primary_category";
                // categoryAxis.renderer.labels.template.hideOversized = false;
                // categoryAxis.renderer.minGridDistance = 20;
                // categoryAxis.renderer.labels.template.horizontalCenter = "right";
                // categoryAxis.renderer.labels.template.verticalCenter = "middle";
                // categoryAxis.tooltip.label.rotation = 270;
                // categoryAxis.tooltip.label.horizontalCenter = "right";
                // categoryAxis.tooltip.label.verticalCenter = "middle";
                // categoryAxis.renderer.inversed = true;
                // categoryAxis.title.text = this.$t('reports.primary_categories');
                // categoryAxis.title.fontWeight = "bold";


                // let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());


                // // Create series
                // let series = chart.series.push(new am4charts.ColumnSeries());
                // series.dataFields.valueX = "value";
                // series.dataFields.categoryY = "primary_category";
                // series.name = "value";
                // series.tooltipText = "{categoryY}: [bold]{valueX}[/]";
                // series.columns.template.fillOpacity = .8;

                // // Sorting
                // categoryAxis.sortBySeries = series;


                // let columnTemplate = series.columns.template;
                // columnTemplate.strokeWidth = 2;
                // columnTemplate.strokeOpacity = 1;
                // columnTemplate.stroke = am4core.color("#FFFFFF");

                // columnTemplate.adapter.add("fill", function(fill, target) {
                //         return chart.colors.getIndex(target.dataItem.index);
                // })

                // columnTemplate.adapter.add("stroke", function(stroke, target) {
                //         return chart.colors.getIndex(target.dataItem.index);
                // })

                // // Make a panning cursor
                // chart.cursor = new am4charts.XYCursor();
                // chart.cursor.behavior = "zoomY";
                // chart.cursor.xAxis = valueAxis;
                // chart.cursor.snapToSeries = series;

                // // Export Menu to chart's
                // chart.exporting.menu = new am4core.ExportMenu();
                // // File names will be myExport.png, myExport.pdf, etc
                // chart.exporting.filePrefix = "convoPrimary";


        },

        beforeDestroy() {
            // if (this.chart) {
            //     this.chart.dispose();
            // }

            if (this.root) {
                this.root.dispose();
            }
        },


        data: function () {
            return {}
        },


        methods: {},

        computed: {
            ...mapGetters({

                fakePrimaryCategoryReports: 'Report/getFakePrimaryCategories',

            }),

        },
    }
</script>