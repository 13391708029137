<template>

    <b-form-group>

        <!-- Shadow  -->
        <b-form-checkbox v-model="status" @input="update_status"> {{ $t('pixel.shadow') }}</b-form-checkbox>

        <!-- Check if have shadow -->
        <template v-if="status">

            <!-- Blur -->
            <b-form-group :label="$t('pixel.filter_blur') + ' : ' + config.shadow.blur">

                <b-form-input v-model="config.shadow.blur" type="range" min="0" max="100" @input="update" />

            </b-form-group>

            <!-- Color -->
            <b-form-group :label="$t('pixel.color')">

                <b-form-input type="color" v-model="config.shadow.color" @input="update" />

            </b-form-group>

            <!-- Offset X -->
            <b-form-group :label="$t('pixel.offset_x') + ' : ' + config.shadow.offsetX">

                <b-form-input v-model="config.shadow.offsetX" type="range" min="-100" max="100" @input="update" />

            </b-form-group>

            <!-- Offset Y -->
            <b-form-group :label="$t('pixel.offset_y') + ' : ' + config.shadow.offsetY">

                <b-form-input v-model="config.shadow.offsetY" type="range" min="-100" max="100" @input="update" />

            </b-form-group>

            <hr>

        </template>

    </b-form-group>

</template>
<script>

    export default {

        props: ['default_shadow'],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
            //console.log('Content render mounted destroyed.');
        },

        created: function() {
            // Component created
        },
        

        data: function() {
            return  {

                config: {

                    shadow: {

                        color: this.default_shadow ? (this.default_shadow.color ? this.default_shadow.color : '#000000') : '#000000',
                        blur: this.default_shadow ? (this.default_shadow.blur ? this.default_shadow.blur : 20) : 20,
                        offsetX: this.default_shadow ? (this.default.shadow.offsetX ? this.default.shadow.offsetX : -20)  : -20,
                        offsetY: this.default_shadow ? (this.default.shadow.offsetY ? this.default.shadow.offsetY : 20) : 20,

                    },
                },

                status: !!this.default_shadow,

                default: {
                    color: '#000000',
                    blur: 20,
                    offsetX: -20,
                    offsetY: 20,
                }

            }
        },

        methods: {

            // Update config
            update: function(){

                // Config
                //console.log(this.config);

                // Sent to parent
                this.$emit('update', this.config);
            },

            // Update status
            update_status: function(){

                // Check if don't have status
                if(!this.status){

                    this.config.shadow = null;

                } else {

                    this.config.shadow = this.default;
                }

                // Sent to parent
                this.$emit('update', this.config);

            },


        },
        
    }
</script>