<template>
    <div class="row">

        <div class="col pt-2">

            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="message-actions notification-header sticky-top plus-page-header z-index-fix">

                    <div class="container-fluid py-2">

                        <h4 class="notification-header-text">{{ $t('page.notifications') }}</h4>
                        <span class="text-muted font-weight-light">{{ $t('page.notifications_desc') }}</span>
                    </div>

                    <div class="mx-3 d-flex flex-column justify-contents-center align-items-center">

                            <!-- Pagination-->
                            <b-pagination v-if="total" :hide-goto-end-buttons="true" size="sm" :total-rows="total" :value="current" @input="updatePage" :per-page="perpage"></b-pagination>

                            <!-- Refresh -->
                            <b-button :variant="'primary'" @click="refreshNotifications" class="btn-sm">
                                <font-awesome-icon :icon="'sync'" />
                                {{ $t('default.refresh')}}
                            </b-button>

                    </div>

                    

                    

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-5">


                    <v-wait for="notifications">


                        <loading slot="waiting"/>
                        <ul class="notifications border">

                            <template v-for="notification in notifications">

                                <li class="notification">

                                    <div class="media">
                                        <div class="media-body">

                                            <template v-if="notification.data.notification_type == 'confirmation'">

                                                <strong class="notification-title">{{ $t('confirmation.title') }}</strong>

                                                <p class="notification-desc">
                                                {{
                                                    $t('confirmation.database_notify', {
                                                        id: notification.data.confirmation_id,
                                                        status: notification.data.confirmation_status,
                                                        reviewed_by: notification.data.confirmation_reviewed_by,
                                                        reviewed_at: $moment($moment.utc(notification.data.confirmation_reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                                                    })
                                                }}
                                                </p>

                                            </template>

                                            <template v-if="notification.data.notification_type == 'confirmation_request'">

                                                <strong class="notification-title">{{ $t('confirmation.request') }}</strong>

                                                <p class="notification-desc">
                                                    {{
                                                        $t('confirmation.database_request', {
                                                            id: notification.data.confirmation_id,
                                                            requested_by: notification.data.confirmation_requested_by,
                                                            created_at: $moment($moment.utc(notification.data.confirmation_created_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                                                        })
                                                    }}
                                                </p>

                                            </template>

                                            <template v-if="notification.data.notification_type == 'confirmation_restricted_word'">

                                                <strong class="notification-title">{{ $t('confirmation.restricted_word_title') }}</strong>

                                                <p class="notification-desc">
                                                {{
                                                    $t('confirmation.restricted_word_database_notify', {
                                                        id: notification.data.confirmation_id,
                                                        status: notification.data.confirmation_status,
                                                        reviewed_by: notification.data.confirmation_reviewed_by,
                                                        reviewed_at: $moment($moment.utc(notification.data.confirmation_reviewed_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                                                    })
                                                }}
                                                </p>

                                            </template>

                                            <template v-if="notification.data.notification_type == 'confirmation_restricted_word_request'">

                                                <strong class="notification-title">{{ $t('confirmation.restricted_word_title') }}</strong>

                                                <p class="notification-desc">
                                                    {{
                                                        $t('confirmation.restricted_word_database_request', {
                                                            id: notification.data.confirmation_id,
                                                            requested_by: notification.data.confirmation_requested_by,
                                                            created_at: $moment($moment.utc(notification.data.confirmation_created_at)).local().format('YYYY-MM-DD HH:mm:ss'),
                                                        })
                                                    }}
                                                </p>

                                            </template>

                                            <template v-if="notification.data.notification_type == 'transfer'">

                                                <strong class="notification-title">{{ $t('ticket.transfer') }}</strong>

                                                <p class="notification-desc">
                                                    {{
                                                    $t('ticket.transfer_database_notify', {
                                                        user: notification.data.user,
                                                        case_id: notification.data.case_id,
                                                    })
                                                    }}
                                                </p>

                                            </template>

                                            <template v-if="notification.data.notification_type == 'twitter_limit'">

                                                <strong class="notification-title">{{ $t('default.twitter_limit_title') }}</strong>

                                                <p class="notification-desc">
                                                    {{
                                                        $t('default.twitter_limit_notify',{
                                                            limit: notification.data.account.limit,
                                                            tweet_count: notification.data.account.tweet_count,
                                                        }
                                                    )
                                                    }}
                                                </p>
                                        
                                            </template>

                                            <template v-if="notification.data.notification_type == 'question'">

                                                <strong class="notification-title">{{ $t('default.new_message_arrived') }}</strong>

                                                <p class="notification-desc">
                                                    {{
                                                        $t('default.new_message_broadcast',{
                                                           customer: notification.data.username,
                                                           content: `${notification.data.content}..`,
                                                        }
                                                    )
                                                    }}
                                                </p>
                                        
                                            </template>
                                            <template v-if="notification.data.notification_type == 'vip'">

                                            <strong class="notification-title">{{ $t('default.new_vip_account_message_arrived') }}</strong>

                                            <p class="notification-desc">
                                              {{
                                                $t('default.new_vip_account_message_broadcast',{
                                                  customer: notification.data.username  ,
                                                  content:`${notification.data.content}..` ,
                                                    }
                                                )
                                              }}
                                            </p>

                                          </template>
                                            <template v-if="notification.data.notification_type == 'popular'">

                                            <strong class="notification-title">{{ $t('default.new_popular_message_arrived') }}</strong>

                                            <p class="notification-desc">
                                              {{
                                                $t('default.new_popular_message_broadcast',{
                                                  customer: notification.data.username  ,
                                                  content:`${notification.data.content}..` ,
                                                    }
                                                )
                                              }}
                                            </p>

                                          </template>


                                          <div class="notification-meta">

                                                {{ notification.data.notification_type }}
                                                <small class="timestamp"> Created: {{ $moment($moment.utc(notification.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }} | Read: {{ $moment($moment.utc(notification.read_at)).local().format('YYYY-MM-DD HH:mm:ss') }}</small>
                                            </div>

                                        </div>
                                    </div>
                                </li>

                            </template>

                        </ul>

                    </v-wait>

                </div>


            </div>


        </div>

    </div>

</template>

<script>

    // Vuex
    import { mapGetters, mapActions } from 'vuex';

    export default {

        props: ['id'],

        mounted: function () {
            // Component mounted
            console.log('Notification component mounted.');
        },

        destroyed() {
            // Component destroyed
            console.log('Notification component destroyed.');
        },

        created: function() {
            // Component created
            this.fetchNotifications(1);
        },

        data: function() {
            return  {}
        },

        metaInfo: function() {

            return {
                title: this.$t('page.notifications'),
            }

        },

        methods: {

            // Actions
            ...mapActions('Notification', [
                'refreshNotifications',
                'fetchNotifications',
                'updatePage',
            ]),

        },

        computed: {

            // Map getters
            ...mapGetters({
                notifications: 'Notification/getNotifications',
                total: 'Notification/getTotal',
                current: 'Notification/getCurrent',
                perpage: 'Notification/getPerPage',

            }),
        },


    }
</script>