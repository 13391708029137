<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('macros.template_details') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- TODO: May need a differen Edit for Auth and others -->
                    <v-wait for="template_message_edit">
                        <loading slot="waiting" />
                        <b-container>
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_account_name" class="font-weight-bold">
                                        {{ $t('simple.account') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input id="template_account_name" name="template_account_name"
                                        v-model="template_message.account_name" disabled></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="align-items-center mb-3">
                                <b-col sm="3">
                                    <label for="template_message_name" class="font-weight-bold">
                                        {{ $t('macros.template_name') }}:</label>
                                </b-col>
                                <b-col sm="8">
                                    <b-form-input id="template_message_name" name="template_message_name"
                                        v-model="template_message.name" disabled></b-form-input>
                                </b-col>
                            </b-row>
                            <ValidationObserver ref="header">
                                <b-card v-if="template_message.category !== 'AUTHENTICATION'" class="mb-1">
                                    <template #header>
                                        <span class="font-weight-bold">{{ $t('macros.header') }}</span> <span> ( {{$t('macros.optional') }} )</span>                                    
                                    </template>
                                
                                    <b-row class="align-items-center mb-3">
                                        <b-col sm="3">
                                            <label for="template_message_header_type" class="font-weight-bold">{{
                                                $t('macros.header_type') }}:</label>
                                        </b-col>
                                        <b-col sm="8">
                                            <b-form-input v-model="template_message.template_type" disabled></b-form-input>
                                        </b-col>
                                    </b-row>
                                
                                    <b-container fluid v-if="template_message.template_type === 'TEXT'" class="p-0">
                                        <b-row class="align-items-center mb-3">
                                            <b-col sm="3">
                                                <label for="template_message_header_text" class="font-weight-bold">{{
                                                    $t('macros.header') }}:</label>
                                            </b-col>
                                            <b-col sm="8">
                                                    <b-form-input id="template_message_vertical" v-model="template_message.header"
                                                        :maxlength="template_message_settings.header.text_limit"></b-form-input>
                                                    <small class="text-muted"> {{ $t('macros.header_desc', {
                                                        header_limit: template_message_settings.header.text_limit
                                                    }) }}</small>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                    <b-container fluid v-if="template_message.template_type === 'IMAGE'" class="p-0">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="template_message_media">{{ $t('simple.file') }}:</label>
                                                </b-col>
                                                <b-col sm="4" class="mb-3">
                                                    <div class="position-relative media-preview-container">
                                                        <a href="#" @click="image_view(template_message.attachment)">
                                                            <b-img fluid :src="template_message.attachment" class="media-preview" />
                                                        </a>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                    </b-container>
                                
                                    <b-container fluid v-if="template_message.template_type === 'VIDEO'" class="p-0">
                                            <b-row class="align-items-center mb-3">
                                                <b-col sm="3">
                                                    <label for="template_message_media">{{ $t('simple.file') }}:</label>
                                                </b-col>
                                                <b-col sm="4" class="mb-3">
                                                    <div class="position-relative media-preview-container">
                                                        <a href="#" @click="video_view(template_message.attachment)">
                                                            <video :src="template_message.attachment" class="media-preview"> </video>
                                                            <font-awesome-icon :icon="'play-circle'"
                                                                class="preview-video-button position-absolute p-0" />
                                                        </a>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                    </b-container>
                                </b-card>
                            </ValidationObserver>
                            <ValidationObserver ref="content">
                                <b-card class="mb-1">
                                    <template #header>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <span class="font-weight-bold">{{ $t('macros.body') }}</span>
                                        </div>
                                    </template>
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <b-row class="align-items-center mb-3">
                                            <b-col sm="3">
                                                <label for="template_message_content" class="font-weight-bold">
                                                    {{ $t('macros.body_text') }}<span class="text-danger">*</span>:</label>
                                            </b-col>
                                            <b-col sm="8">
                                                <div class="position-relative">
                                                    <b-form-textarea name="template_message_content"
                                                        v-model="template_message.content" ref="template_message_content"
                                                        rows="5" max-rows="6"
                                                        :maxlength="template_message_settings.content_limit"
                                                        :state="valid"></b-form-textarea>

                                                    <coolpicker class="coolpicker-button" :emojiData="emojiDataAll"
                                                        :emojiGroups="emojiGroups" :btnEmojiClasses="['btn-emoji']"
                                                        @emojiUnicodeAdded="template_message.content += $event"
                                                        twemojiPath="https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/"
                                                        twemojiExtension=".svg" twemojiFolder="svg"></coolpicker>
                                                </div>
                                                <small class="text-muted"> {{ $t('macros.body_desc', {
                                                    body_limit: template_message_settings.content_limit
                                                }) }}</small>
                                            </b-col>
                                        </b-row>
                                    </ValidationProvider>
                                    

                                    <div class="p-1 p-lg-3" >
                                        <b-card class="mb-3 position-relative" bg-variant="light" border-variant="info">
                                            <template #header>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="mr-auto"><span class="font-weight-bold">{{ $t('macros.custom')
                                                    }}:</span></div>
                                                    <b-btn pill class="p-sm-0 p-md-1 mr-2" variant="outline-success" size="sm"
                                                        @click="addCustomVariable">
                                                        <font-awesome-icon :icon="'plus'" class="mr-1" size="sm" />{{
                                                            $t('default.add') }}
                                                    </b-btn>
                                                    <b-button pill class="p-sm-0 p-md-1" variant="outline-danger"
                                                        v-if="custom_variables.length > 0" size="sm"
                                                        @click="removeCustomVariable">
                                                        <font-awesome-icon :icon="'minus'" class="mr-1" size="sm" />{{
                                                            $t('default.remove') }}
                                                    </b-button>
                                                </div>
                                            </template>
                                            <b-row class="align-items-center mb-3">
                                                <b-col>
                                                    <b-list-group>
                                                        <b-list-group-item class="d-flex align-items-center mb-1"
                                                            v-for=" (custom_variable) in custom_variables"
                                                            :key="custom_variable">
                                                            <span>{{ custom_variable }}</span>
                                                        </b-list-group-item>
                                                    </b-list-group>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </div>
                                </b-card>
                            </ValidationObserver>

                            

                            <ValidationObserver ref="buttons">
                                <b-card class="mb-1">
                                    <template #header>
                                        <div><span class="font-weight-bold">{{ $t('macros.buttons') }}</span> <span> ( {{$t('macros.optional') }} )</span></div>
                                    </template>
                                    <b-row class="align-items-center mb-3">
                                        <b-col>
                                            <b-btn  class="mr-1" variant="info" size="sm" @click="addQuickReplyButton"> 
                                                + {{ $t('macros.quick_reply') }}
                                            </b-btn>
                                        
                                            <b-btn variant="info" size="sm" @click="addPhoneButton"> 
                                                + {{ $t('simple.phone_number') }}
                                            </b-btn>
                                        </b-col>
                                    </b-row>
                                        <b-card class="mb-4" v-for=" (button, button_index) in template_message.buttons"
                                                :key="button_index">
                                            <template #header>
                                                <span class="font-weight-bold">{{ $t('macros.button') }} - {{ button_index + 1 }}</span>    
                                            </template>
                                            <b-card class="interactive-list-section mb-3 position-relative" bg-variant="light"
                                                border-variant="info"
                                                >
                                                <b-row class="align-items-center mb-3">
                                                    <b-col sm="3">
                                                        <label for="button_list_option" class="font-weight-bold">
                                                            {{ $t('macros.button_type') }}<span
                                                                class="text-danger">*</span>:</label>
                                                        <b-form-input  id="button_list_option" 
                                                            v-model="button.type" 
                                                            disabled>
                                                        </b-form-input>
                                                    </b-col>
                                                    <b-col :sm="button.type === 'PHONE_NUMBER' ? 4 : 8">
                                                        <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                                            <label for="button_text" class="font-weight-bold">
                                                            {{ $t('macros.button_text') }}<span
                                                                class="text-danger">*</span>:</label>
                                                            <b-form-input 
                                                            id="button_text"
                                                            v-model="button.text"
                                                            :maxlength="template_message_settings.button_text_limit"
                                                            :state="valid"
                                                            ></b-form-input>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="4" v-if="button.type === 'PHONE_NUMBER'">
                                                        <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                                            <label for="button_phone" class="font-weight-bold">
                                                            {{ $t('simple.phone_number') }}<span
                                                                class="text-danger">*</span>:</label>
                                                            <b-form-input 
                                                            id="button_phone"
                                                            v-model="button.phone_number"
                                                            :maxlength="button.type === template_message_settings.button_phone_text_limit"
                                                            :state="valid"
                                                            ></b-form-input>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="12">
                                                        <small class="text-muted" v-if="button.type === 'PHONE_NUMBER'"> {{ $t('macros.phone_number_desc') }}</small>
                                                    </b-col>
                                                </b-row>
                                                <b-btn size="sm" class="list-item-remove-button position-absolute" variant="danger" @click="removeButton(button_index)">
                                                    <font-awesome-icon :icon="'trash-alt'" />
                                                </b-btn>
                                            
                                            </b-card>
                                        </b-card>
                                </b-card>
                            </ValidationObserver>
                        </b-container>
                    </v-wait>
                </div>

                <div class="modal-footer">

                    <b-button @click="$close" variant="secondary">{{ $t('simple.cancel') }}</b-button>

                    <b-button @click="updateTemplate" variant="success">{{ $t('simple.update') }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    TwemojiPicker
} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ImageView from '../../Modal/ImageView.vue';
import VideoView from '../../Modal/VideoView.vue';
import { create } from 'vue-modal-dialogs'




import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    components: {
        'coolpicker': TwemojiPicker,
        ValidationObserver,
        ValidationProvider,
    },
    created() {
        this.template_message = {...this.template, buttons: this.template.buttons ? [...this.template.buttons] : [] }        
        this.custom_variables = this.template.content.match(/{{\d}}/g) ?? []
    },

    data() {
        return {
            custom_variables: [],

            file: null,
            file_url: '',

            template_message: {}
        }
    },
    computed: {
        ...mapGetters({
            template_message_settings: 'Templates/getTemplateMessageSettings',
            template_message_header_options: 'Templates/getTemplateMessageHeaderOptions',
            accounts: 'Accounts/getAccounts',
        }),

        // Emoji data
        emojiDataAll() {
            return EmojiAllData;
        },
        // Emoji Groups
        emojiGroups() {
            return EmojiGroups;
        },

        totalButtonsNumber() {
            return this.template_message.buttons.length;
        },

        totalPhoneButtonsNumber() {
            const phone_buttons = this.template_message.buttons.filter( button => button.type === 'PHONE_NUMBER')
            return phone_buttons.length;
        },

        whatsapp_accounts() {
            return this.accounts.filter(account => account.integration_id === 'whatsapp')
        },
    },

    methods: {
        ...mapActions('Templates', [
            'updateTemplateMessage',
            'refreshTemplateMessages'
        ]),

        addText(text) {

            let textArea = this.$refs.template_message_content

            let cursor = textArea.selectionEnd

            textArea.focus()

            this.template_message.content = this.template_message.content.substring(0, cursor) + text + this.template_message.content.substring(cursor);

        },

        addCustomVariable() {
            let len = this.custom_variables.length ?? 0
            this.custom_variables.push(`{{${len + 1}}}`)
            let text = this.custom_variables[len]

            this.addText(text)

        },

        removeCustomVariable() {
            let removed = this.custom_variables.pop()
            this.template_message.content = this.template_message.content.replace(removed, '')
        },

        addQuickReplyButton() {
            if (this.totalButtonsNumber < this.template_message_settings.buttons_limit) {
                this.template_message.buttons.push(                   
                    { 
                        type: 'QUICK_REPLY',
                        text: ''
                    })
            } else {
                Vue.toasted.error(this.$t('macros.item_limit_alert', {
                    item_limit: this.template_message_settings.buttons_limit
                }));
            }
        },

        addPhoneButton() {
            if (this.totalPhoneButtonsNumber < this.template_message_settings.button_phone_limit) {
                this.template_message.buttons.push(                   
                    { 
                        type: 'PHONE_NUMBER',
                        text: '',
                        phone_number: ''
                    })
            } else {
                Vue.toasted.error(this.$t('macros.item_limit_alert', {
                    item_limit: this.template_message_settings.button_phone_limit
                }));
            }
        },

        removeButton(button_index) {
            this.template_message.buttons.splice(button_index, 1);
        },

        async updateTemplate() {
            const isContentValid = await this.$refs.content.validate();
            const isButtonsValid = await this.$refs.buttons.validate();
            const isHeadersValid = await this.$refs.header.validate();


            if (!isContentValid || !isButtonsValid || !isHeadersValid) return

            const template = {...this.template_message, type: this.template_message.template_type}

            await this.updateTemplateMessage({ account_id: this.template_message.account_id, template_id: this.template_message.id, template: template })
                .then(() => {
                    this.$close(true);
                    this.refreshTemplateMessages({whatsapp_accounts: this.whatsapp_accounts})
                })

        },

        resetHeader() {

            this.removeFile();

            // this.place = null;

            this.template_message.header = ''
        },

        removeFile() {
            this.file = null;
            this.file_url = '';
        },

        checkFileSize(file, size_limit) {
            if (((file.size / 1024) / 1024).toFixed(4) > size_limit) {
                return false
            }

            return true
        },

        addFile(file, limit) {
            if (file) {
                if (this.checkFileSize(file, limit)) {
                    this.file_url = URL.createObjectURL(file);

                } else {
                    Vue.toasted.error(this.$t('macros.size_limit_alert', {
                        size_limit: limit,
                    }));

                    this.removeFile();
                }
            }
        },

        // Show image
        image_view: async function (attachment) {

            // Show image modal
            const image_view = create(ImageView, 'attachment');

            if (await image_view(attachment)) {
                // Nothing here yet

            }
        },

        // Play video
        video_view: async function (attachment) {

            // Show video modal
            const video_view = create(VideoView, 'attachment');

            if (await video_view(attachment)) {
                // Nothing here yet

            }
        },
    }
}
</script>