<template>
    <div class="modal fade show d-block ps--scrolling-y" tabindex="-1" role="dialog" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"> {{ $t('simple.twitter_connect') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-muted font-weight-light"> {{ $t('simple.twitter_connect_desc') }}</p>
            <span> {{ $t('simple.total_tweet_count') }} : {{ this.account.limit }} / {{ this.account.tweet_count }}</span>
              <b-progress :max="this.account.limit" height="2rem">
                <b-progress-bar :value="this.account.tweet_count" :variant='limit_status'>
                  <span class="text-dark"><strong>{{ this.account.tweet_count }} / {{ this.account.limit }}</strong></span>
                </b-progress-bar>
              </b-progress>
            <hr>
            <v-wait for="twitter_applications">
                <loading slot="waiting"/>
                <b-list-group>
                        <b-list-group-item v-for="application in twitter_applications" :key="application.url" :variant="application.theme" > 
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="mr-auto"> {{ $t('simple.twitter_connect_auth') }} - {{ application.name }}</span>
                                <b-btn variant="secondary" @click="connect(application.url)">{{ $t('simple.connect')}}</b-btn>
                            
                            </div>
                        </b-list-group-item>
                </b-list-group>
            </v-wait>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {

    created() {
        this.fetchTwitterApplications(this.id);
        this.fetchAccounts().then( res => {
          this.showAccounts();
        })

    },

    data() {
        return {
          account : {},

        }
    },

    methods: {
        ...mapActions('Accounts', [
            'fetchTwitterApplications',
            'fetchAccounts',
        ]),

        connect: async function (connect_url) {
            window.location = connect_url;
        },

        showAccounts() {
          this.account = this.accounts.find( acc => acc.id === +this.id)
        },
    },

    computed: {
        ...mapGetters({

            twitter_applications: 'Accounts/getTwitterApplications',
            accounts: 'Accounts/getAccounts',
            
        }),

        limit_status() {
          let remaining_limit = this.account.limit - this.account.tweet_count;
          if( remaining_limit === 0) {
            return 'danger'
          } else if ( remaining_limit < 1000) {
            return 'warning'
          } else {
            return 'success'
          }
        }
        
    },

}
</script>