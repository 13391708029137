// import {toBoolean} from "@amcharts/amcharts4/.internal/core/utils/Type";
import cookie from 'vue-cookies'
import router from '../../router'


const state = {
    loggedIn: !!cookie.get('access_token'),
    user: false,
    teams: [],
    user_api: '/api/v1/user',
    language_list: [
        { value: 'en', text: 'English' },
        { value: 'tr', text: 'Türkçe' },
    ]
};

// getters
const getters = {

    // Get user api
    getUserAPI: (state) => {
        return state.user_api;
    },

    // Get user
    getUser: (state) => {
        return state.user;
    },

    // Get user teams
    getUserTeams: (state) => {
        return state.teams;
    },

    // Get user id
    getUserID: (state) => {
        return state.user.id;
    },

    // Get Language List
    getLanguageList: (state) => {
        return state.language_list;
    },

    // Get User LoggedIn Status
    getLoggedIn: (state) => {
        return state.loggedIn;
    },

};

// mutations
const mutations = {

    // Set user
    setUser (state, user){
        state.user = user;
    },

    // Set user teams
    setUserTeams (state, teams){
        state.teams = teams;
    },

    // Set notifications
    setNotification (state, notification){
        state.notification = notification;
    },

    // Set LoggedIn Status
    setLoggedIn (state, loggedIn){
        state.loggedIn = loggedIn;
    },

};

// actions
const actions = {

    // Update user
    updateUser({dispatch}) {
      // Get user data
      dispatch('getUserData');
    },

    async userLogin({ dispatch, commit }, data){
        // Login
        let login_api = '/api/v1/login';

        await axios.post(login_api, {
            username: data.username,
            password: data.password,
        }).then((response) => {
            const token = response.data.access_token;

            cookie.set('access_token', token);
            dispatch('updateUser');
            dispatch('Configurations/fetchConfigurations', null, {root:true});
            console.log(response);
            window.location = router.currentRoute.query.redirect || '/';
        })
        .catch((error) => {
            // Show error
            Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
            // Log to console
            console.log(error);
        });
    },

    async userLogout({ dispatch, commit }){
        // Logout
        let logout_api = '/api/v1/logout';

        await axios.post(logout_api).then((response) => {

            cookie.remove('access_token');
            commit('setLoggedIn', false);
            console.log(response);
            window.location = '/';
        })
        .catch((error) => {
            cookie.remove('access_token');
            commit('setLoggedIn', false);

            window.location = '/';
            // Show error
            Vue.toasted.error(Vue.i18n.translate('simple.error_info'));
            // Log to console
            console.log(error);
        });
    },

    async getUserData({commit, dispatch, getters}){

    // Get categories
     await axios.get(getters.getUserAPI)
        .then((response) => {

          // Set user
          commit('setUser', response.data.user);

          // Set Language
            Vue.i18n.set(response.data.user.language);

          // Set user teams
          commit('setUserTeams', response.data.teams);

          dispatch('UserEvents');

          // Log error
          console.log(response.data.user);

          dispatch('Integrations/fetchCrmIntegration', null, {root:true});
         

          // Start listening user
          dispatch('Notification/listen_user', getters.getUserID, {root:true});

          // Set Player Status
          dispatch('Player/updatePlayerStatus', !!response.data.user.in_play, { root: true });

        })

        .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

        });
    },

    async UserEvents({ dispatch, getters}){

     // Listen
       Echo.private('users.' + getters.getUserID)
           //.subscribe('InboxList')
           .listen('UserEvent', (e) => {
                console.log(e);
                console.log('new message came!!');
                console.log('unread_counts:' + e[0]['unread_count']);
               // Start listening user
               dispatch('Accounts/updateUnreadCount', e[0], {root:true});

           });
    },

    // Update User Data
    updateUserData({ dispatch, state, commit}, data) {

        if(state.user){
            console.log(data);
            Object.assign(state.user, {'in_play': data});
            console.log(state.user);
        }

    },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}