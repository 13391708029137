<template>

    <div class="container-fluid">
        <div class="row flex-nowrap">

            <div class="plus-col-main-messages pt-1 col-lg-9">

                <!--  Page  -->
                <div class="bg-white rounded border">

                    <!-- Page header -->
                    <div class="message-actions sticky-top z-index-fix plus-page-header">

                        <b-container fluid>
                            <b-row align-v="center">

                                <b-col class="mr-auto ml-4 p-2">
                                    <div id="report">
                                        <h2 class="my-0 text-dark">
                                            <font-awesome-icon :icon="'chart-bar'"/>
                                            {{ $t('reports.reports') }}
                                        </h2>
                                    </div>
                                    <b-tooltip target="report" placement="bottom" triggers="hover">
                                        {{ $t('reports.reports_desc') }}
                                    </b-tooltip>
                                </b-col>
                                <b-col class="d-flex flex-nowrap">
                                    <!-- Date Picker -->
                                    <date-picker
                                        class="m-1 ml-auto"
                                        v-model="date_range"
                                        value-type="YYYY-MM-DD"
                                        format="YYYY-MM-DD"
                                        type="date"
                                        range
                                        :placeholder="$t('schedule.date_range')"
                                        change
                                        @change="dateRender"
                                        :disabled-date="disabledAfterNow"
                                        :shortcuts="shortcuts"
                                        :default-value="[date_range]"
                                    ></date-picker>

                                    <!-- Account & Integration filter -->
                                    <b-button v-b-toggle.reports-options-collapse class="m-1 d-lg-none">
                                        <font-awesome-icon :icon="'filter'"/>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-collapse id="reports-options-collapse" class="col-12 d-lg-none">
                                    <div class="plus-col-main-panel position-initial-converter mt-1 z-index-fix">
                                        <div class="sticky-top">
                                            <b-row>
                                                <!-- Account Selector -->
                                                <b-col cols="12">
                                                        <template v-if="categories">
                                                            <treeselect
                                                                    v-model="category"
                                                                    :options="accounts"
                                                                    :multiple="true"
                                                                    value-format="object"
                                                                    :placeholder="$t('reports.selected_accounts')"
                                                                    :flatten-search-results="true"
                                                                    :normalizer="normalizer2"
                                                                    @input="accountsRender"
                                                        
                                                            />
                                                        </template>
                                                    
                                                </b-col>
                                                
                                                <b-col class="my-3">
                                                    <b-form-select v-model="is_active" @change="activeTab(is_active)">
                                                        <b-form-select-option :value="0"> 
                                                            <font-awesome-icon :icon="'tachometer-alt'"/>
                                                            {{$t('simple.accounts')}}
                                                        </b-form-select-option>
                                                        <b-form-select-option :value="1"> 
                                                            <font-awesome-icon :icon="'user-circle'"/>
                                                            {{$t('reports.customers')}}
                                                        </b-form-select-option>
                                                        <b-form-select-option :value="2"> 
                                                            <font-awesome-icon :icon="'cogs'"/>
                                                            {{$t('reports.processes')}}
                                                        </b-form-select-option>
                                                        <b-form-select-option :value="3"> 
                                                            <font-awesome-icon :icon="'book'"/>
                                                            {{$t('page.categories')}}
                                                        </b-form-select-option>
                                                        <b-form-select-option :value="4"> 
                                                            <font-awesome-icon :icon="'ticket-alt'"/>
                                                            {{$t('ticket.status')}}
                                                        </b-form-select-option>
                                                        <b-form-select-option :value="5"> 
                                                            <font-awesome-icon :icon="'users-cog'"/>
                                                            {{$t('simple.users')}}
                                                        </b-form-select-option>
                                                        <b-form-select-option :value="6"> 
                                                            <font-awesome-icon :icon="'user-shield'"/>
                                                            {{$t('page.teams')}}
                                                        </b-form-select-option>
                                                        <b-form-select-option :value="7"> 
                                                            <font-awesome-icon :icon="'map-marked-alt'"/>
                                                            {{$t('reports.locations')}}
                                                        </b-form-select-option>
                                                    
                                                    </b-form-select>
                                                </b-col>
                                            </b-row>
                                        
                                        
                                        </div>
                                        </div>
                                </b-collapse>
                            </b-row>
                        </b-container>
                    </div>
                    <b-container fluid class="my-3">
                        <!-- Accounts -->
                        <template v-if="is_active === 0">

                            <!-- Accounts General Information Reports Area (fetchFirstResponseAverage()/fetchInformations())-->
                            <information></information>
                            <hr>

                            <!-- Mentions / DM / Key Reports Area (fetchQuestionTypes())-->
                            <h5 class="center">{{ $t('reports.mentions') }} / {{ $t('reports.dm') }} / {{
                                $t('reports.tracks') }}</h5>
                            <v-wait for="reports">
                                <loading-messages slot="waiting"/>
                            <question-types></question-types>
                            <hr>
                                </v-wait>

                            <!-- Incoming/Outgoing Messages (fetchMessageCounts())-->
                            <h5 class="center">{{ $t('reports.incoming') }} / {{ $t('reports.outgoing') }} </h5>
                            <v-wait for="reportsio">
                                <loading-messages slot="waiting"/>
                            <incoming-outgoing></incoming-outgoing>
                            <hr>
                            </v-wait>

                            <!-- Incoming/Outgoing Messages Lang-->
                            <h5 class="center"> {{ $t('simple.language') }} {{ $t('reports.incoming') }} / {{ $t('reports.outgoing') }} </h5>
                            
                            <v-wait for="reportsLanguageIncomeingOutgoing">
                                <loading-messages slot="waiting"/>
                                <b-row align-v="center" class="justify-content-center">
                                    <div class="col-12 col-md-5">
                                        <b-form-select v-model="selected_type_for_lang">
                                            <b-form-select-option value="incoming">{{ $t('reports.incoming') }}</b-form-select-option>
                                            <b-form-select-option value="outgoing">{{ $t('reports.outgoing') }}</b-form-select-option>
                                            <b-form-select-option value="all">{{ $t('reports.incoming') }} + {{ $t('reports.outgoing') }}</b-form-select-option>
                                        </b-form-select>
                                    </div>
                                </b-row>
                            <lang-incoming-outgoing></lang-incoming-outgoing>
                            <hr>
                            </v-wait>

                            <!-- Post Frequency Map (fetchFrequencyMap())-->
                            <h5 class="center">{{ $t('reports.frequency_map') }} </h5>
                            <v-wait for="reportsFrequencyMap" >
                                <loading-messages slot="waiting"/>
                            <frequency-map></frequency-map>
                            <hr>
                            </v-wait>


                            <!-- Account Activity Reports Area-->
                            <h5 class="center">{{ $t('reports.account_activities') }}</h5>
                            <v-wait for="accountActivities" >
                                <loading-messages slot="waiting"/>
                                <account-activities></account-activities>
                            </v-wait>

                            <!-- External Source Messages(Content recorded by link)
                            <h5 class="center">{{ $t('reports.external_source') }} </h5>
                            <external-source></external-source>-->

                        </template>

                        <!-- Customers -->
                        <template v-if="is_active === 1">

                            <!-- Customer Genders (fetchCustomerGenders())-->
                            <h5 class="center">{{ $t('reports.genders') }}</h5>
                            <v-wait for="reportsGender">
                                <loading-messages slot="waiting"/>
                            <question-gender></question-gender>
                            <hr>
                            </v-wait>

                            <!-- Importance Of Customer Category Reports - VIP/Important (fetchVipImportant())-->
                            <h5 class="center">{{ $t('simple.important') }} | {{ $t('simple.vip') }}</h5>
                            <v-wait for="importance" >
                                <loading-messages slot="waiting"/>
                                <importance-category></importance-category>
                                <hr>
                            </v-wait>

                            <!-- Customer Types Reports Area (fetchCustomerTypes())-->
                            <h5 class="center">{{ $t('page.customertypes') }}</h5>
                            <v-wait for="reportsCustomer">
                                <loading-messages slot="waiting"/>
                            <customer-types></customer-types>
                            </v-wait>


                        </template>

                        <!-- Processes -->
                        <template v-if="is_active === 2">
                            <!-- Transfers - Trash Reports Area (fetchTransfersAndTrash())-->
                            <h5 class="center">{{ $t('ticket.transfers') }} / {{
                                $t('page.trash') }}</h5>
                            <v-wait for="reportsTransferAndTrash">
                                <loading-messages slot="waiting"/>
                            <process-types></process-types>
                            <hr>
                            </v-wait>

                            <!-- Confirmation Result Reports Area (fetchConfirmations())-->
                            <h5 class="center">{{ $t('reports.confirmation_results') }}</h5>
                            <v-wait for="reportsConfirmations">
                                <loading-messages slot="waiting"/>
                            <confirmation-results></confirmation-results>
                            <hr>
                            </v-wait>

                            <!-- Schedule Result Reports Area (fetchSchedule())-->
                            <h5 class="center">{{ $t('reports.schedule_results') }}</h5>
                            <v-wait for="scheduleReport">
                                <loading-messages slot="waiting"/>
                            <schedule-results></schedule-results>
                            <hr>
                            </v-wait>
                            <!-- Transfer Result Reports Area(fetchTransferResult())-->
                            <h5 class="center">{{ $t('reports.transfer_results') }}</h5>
                            <v-wait for="transferResult">
                                <loading-messages slot="waiting"/>
                            <transfer-results></transfer-results>
                            </v-wait>

                        </template>

                        <!-- Categories -->
                        <template v-if="is_active === 3">
                            <!-- Tags Reports Area (fetchTagReports())-->
                            <h5 class="center">{{ $t('page.tags') }}</h5>
                            <v-wait for="reportsTags">
                                <loading-messages slot="waiting"/>
                            <tags></tags>
                            <!--  Category Selector -->
                            <b-card bg-variant="light" class="mt-4" v-if="categories">
                                <b-container fluid>
                                    <b-row class="align-items-center justify-content-center">
                                        <b-col sm="1">
                                            <font-awesome-icon class="d-none d-md-block" size="2x" :icon="'search-plus'"/>
                                        </b-col>
                                        <b-col md="5" class="mb-md-0 mb-2">
                                            <treeselect
                                                v-model="selected_tag_categories"
                                                :options="categories"
                                                :multiple="true"
                                                value-format="object"
                                                :limit="3"
                                                :placeholder="$t('ticket.category_select_report')"
                                                :flatten-search-results="true"
                                                :normalizer="normalizer"
                                                @input="tagRender"
                                            />
                                        </b-col>                                        
                                    </b-row>

                                </b-container>
                            </b-card>
                            <hr>
                            </v-wait>

                            <!-- Primary Categories Reports Area (fetchCategoryReport())-->
                            <h5 class="center">{{ $t('reports.primary_categories') }}</h5>
                            <v-wait for="reportsCategory">
                                <loading-messages slot="waiting"/>
                            <primary-categories
                                    :categoryReports="primaryCategoryReports"
                                    ></primary-categories>
                            <hr>
                            </v-wait>
                            <!-- Secondary Categories Reports Area (fetchCategoryReport())-->
                            <template v-if="secondaryActive">
                                <h5 class="center"> {{ $t('reports.secondary_categories') }}</h5>
                                <v-wait for="reportsCategory">
                                    <loading-messages slot="waiting"/>
                                <secondary-categories :categoryReports="secondaryCategoryReports"
                                                      ></secondary-categories>
                                <hr>
                                </v-wait>
                            </template>

                            <!-- SubCategories Reports Area (fetchCategoryReport())-->
                            <template v-if="subActive">
                                <h5 class="center">{{ $t('reports.sub_categories') }}</h5>
                                <v-wait for="reportsCategory">
                                    <loading-messages slot="waiting"/>
                                <sub-categories :categoryReports="subCategoriesReports"
                                                ></sub-categories>
                                </v-wait>
                            </template>

                            <!--  Category Selector -->
                            <b-card bg-variant="light" class="mt-4" v-if="categories || tags">
                                <b-container fluid>
                                    <b-row class="align-items-center justify-content-center">
                                        <b-col sm="1">
                                            <font-awesome-icon class="d-none d-md-block" size="2x" :icon="'search-plus'"/>
                                        </b-col>
                                        <b-col v-if="categories" md="5" class="mb-md-0 mb-2">
                                            <treeselect
                                                v-model="selected_category"
                                                :options="categories"
                                                value-format="object"
                                                :placeholder="$t('ticket.category_select_report')"
                                                :flatten-search-results="true"
                                                :normalizer="normalizer"
                                                :clearable="false"
                                                @input="categoryRender"
                                            />
                                        </b-col>
                                        <b-col v-if="tags" md="5">
                                            <treeselect
                                                v-model="selected_category_tags"
                                                :options="tags"
                                                :multiple="true"
                                                value-format="object"
                                                :placeholder="$t('ticket.tag_select')"
                                                :flatten-search-results="true"
                                                :normalizer="normalizer"
                                                :limit="3"
                                                :clearable="false"
                                                @input="categoryRender"
                                            />
                                        </b-col>
                                        
                                    </b-row>

                                </b-container>
                            </b-card>


                        </template>

                        <!-- Status -->
                        <template v-if="is_active === 4">
                            <!-- Tag Process Reports Area (fetchTagProcesses())-->
                            <h5 class="center">{{ $t('reports.tag_processes') }}</h5>
                            <v-wait for="tagProcesses" >
                                <loading-messages slot="waiting"/>
                            <tags-process></tags-process>
                            <hr>
                            </v-wait>
                            <!-- Risk Heatmap Reports Area (fetchRiskHeatmap())-->
                            <h5 class="center">{{ $t('reports.risk_heatmap') }}</h5>
                            <v-wait for="riskHeatmap">
                                <loading-messages slot="waiting"/>
                            <risk-heatmap></risk-heatmap>
                            <hr>
                            </v-wait>
                            <!-- Status Process Reports Area(fetchCategoryProcesses())-->
                            <h5 class="center">{{ $t('reports.selected_status') }}</h5>
                            <v-wait for="categoryProcesses">
                                <loading-messages slot="waiting"/>
                            <status-process></status-process>
                            </v-wait>
                        </template>

                    <template v-if="is_active === 5 || is_active === 6">

                        <!-- Team Selector -->
                        <b-row align-v="center" class="justify-content-center">
                            <div class="col-12 col-md-5">
                                <b-form-select @input="teamRender" v-model="selected_team">
                                    <b-form-select-option :value="null">{{$t('reports.selected_team')}}
                                    </b-form-select-option>
                                    <template v-for="team in Teams">
                                        <b-form-select-option :value="team.id">{{ team.name }}
                                        </b-form-select-option>
                                    </template>
                                </b-form-select>
                                <hr>
                            </div>
                        </b-row>

                        <!-- Users -->
                        <template v-if="is_active === 5">


                            <!-- Users Time Table Reports Area (fetchUserTimeTable())-->
                            <h5 class="center">{{ $t('reports.user_time_table') }}</h5>
                            <v-wait for="userTimeTable">
                                <loading-messages slot="waiting"/>
                            <user-time-table></user-time-table>
                            <hr>
                            </v-wait>

                            <!-- User Touch Performance Reports Area(fetchUserTouchPerformance())-->
                            <h5 class="center">{{ $t('reports.user_touch_performance') }}</h5>
                            <v-wait for="touchPerformance" >
                                <loading-messages slot="waiting"/>
                            <user-touch-performance></user-touch-performance>
                            </v-wait>

                        </template>

                        <!-- Teams -->
                        <template v-if="is_active === 6">

                            <!-- Team Time Table Reports Area-->
                            <h5 class="center">{{ $t('reports.team_time_table') }}</h5>
                            <v-wait for="teamTimeTable" >
                                <loading-messages slot="waiting"/>
                            <team-time-table></team-time-table>
                            <hr>
                            </v-wait>

                            <!-- Team Touch Performance Reports Area-->
                            <h5 class="center">{{ $t('reports.team_touch_performance') }}</h5>
                            <v-wait for="fetchTeamTouch" >
                                <loading-messages slot="waiting"/>
                                <team-touch-performance></team-touch-performance>
                            </v-wait>


                        </template>
                    </template>

                        <!-- Map -->
                        <template v-if="is_active === 7">
                            <!-- Interaction Heatmap Reports Area-->
                            <h5 class="center mt-2">{{ $t('reports.interaction_map') }}</h5>
                            <v-wait for="fetchMaps">
                                <loading-messages slot="waiting"/>
                                <heat-map-view></heat-map-view>
                                <hr>
                            </v-wait>

                            <!-- Location Reports Area-->
                            <h5 class="center">{{ $t('reports.locations') }}</h5>
                            <v-wait for="locationReports">
                                <loading-messages slot="waiting"/>
                                <location-reports></location-reports>
                            </v-wait>

                            



                        </template>
                    </b-container>
                </div>

            </div>

            <div class="plus-col-main-panel position-initial-converter mt-1 z-index-fix d-none d-lg-block">
                <div class="sticky-top plus-col-main-panel-aside">
                    <b-row>
                        <!-- Account Selector -->
                        <b-col cols="12">
                            <b-container class="message-actions sticky-top z-index-fix p-4">
                                <template v-if="categories">
                                    <treeselect
                                            v-model="category"
                                            :options="accounts"
                                            :multiple="true"
                                            value-format="object"
                                            :placeholder="$t('reports.selected_accounts')"
                                            :flatten-search-results="true"
                                            :normalizer="normalizer2"
                                            @input="accountsRender"

                                    />
                                </template>
                            </b-container>
                        </b-col>
                        <b-col>
                            <b-nav vertical class="nav-right border my-3">
                                <b-nav-item @click="activeTab(0)" :active="is_active === 0">
                                    <font-awesome-icon :icon="'tachometer-alt'"/>
                                    {{$t('simple.accounts')}}
                                </b-nav-item>
                                <b-nav-item @click="activeTab(1)" :active="is_active === 1">
                                    <font-awesome-icon :icon="'user-circle'"/>
                                    {{$t('reports.customers')}}
                                </b-nav-item>
                                <b-nav-item @click="activeTab(2)" :active="is_active === 2">
                                    <font-awesome-icon :icon="'cogs'"/>
                                    {{$t('reports.processes')}}
                                </b-nav-item>
                                <b-nav-item @click="activeTab(3)" :active="is_active === 3">
                                    <font-awesome-icon :icon="'book'"/>
                                    {{$t('page.categories')}}
                                </b-nav-item>
                                <b-nav-item @click="activeTab(4)" :active="is_active === 4">
                                    <font-awesome-icon :icon="'ticket-alt'"/>
                                    {{$t('ticket.status')}}
                                </b-nav-item>

                                <b-nav-item @click="activeTab(5)" :active="is_active === 5">
                                    <font-awesome-icon :icon="'users-cog'"/>
                                    {{$t('simple.users')}}
                                </b-nav-item>
                                <b-nav-item @click="activeTab(6)" :active="is_active === 6">
                                    <font-awesome-icon :icon="'user-shield'"/>
                                    {{$t('page.teams')}}
                                </b-nav-item>
                                <b-nav-item @click="activeTab(7)" :active="is_active === 7">
                                    <font-awesome-icon :icon="'map-marked-alt'"/>
                                    {{$t('reports.locations')}}
                                </b-nav-item>
                            </b-nav>

                        </b-col>
                    </b-row>


                </div>
            </div>
        </div>

    </div>

</template>
<script>

    import Vue from 'vue';
    import {mapActions, mapGetters, mapMutations} from "vuex";

    // import * as am4core from "@amcharts/amcharts4/core";
    // import * as $registry from "@amcharts/amcharts5/core/Registry";


    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    // Treeselect
    import Treeselect from '@riophae/vue-treeselect';

    //scroll to bottom
    import VueChatScroll from 'vue-chat-scroll';

    //moment() for time
    import moment from 'moment';


    Vue.use(VueChatScroll);


    export default {

        created: function () {
            // Mounted
            console.log('Reports selector created.');

            // Get accounts
            this.fetchAccounts();
            // Get categories
            this.getCategories();
            // Get tags
            this.getTags();
            //Fetch Teams
            this.fetchTeams();

            this.dateRender();
            this.fetchFunctions();

            this.updateSecondaryActive(false);
            this.updateSubActive(false);

        },

        components: {DatePicker, Treeselect},

        name: 'Range',

        data() {
            return {
                date_range: [ moment(new Date()).subtract(14, 'day').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
                category: null,
                selected_team: null,
                is_active: 0,
                selected_category: null,
                selected_category_tags: [],
                selected_tag_categories: [],
  

                // Date Shortcuts(Today,Yesterday,Last 7 Days,Last 28 Days,This Month,Last Month)
                shortcuts: [
                    {
                        text: this.$t('reports.today'),
                        onClick() {
                            const date = moment(new Date()).format('YYYY-MM-DD');
                            // return a Date
                            return [moment(date).toDate(),moment(date).toDate()];
                        },
                    },
                    {
                        text: this.$t('reports.yesterday'),
                        onClick() {
                            const date = moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD');
                            return [moment(date).toDate(),moment(date).toDate()];
                        },
                    },
                    {
                        text: this.$t('reports.last_7'),
                        onClick() {
                            const endOfDate = moment(new Date()).format('YYYY-MM-DD');
                            const startOfDate = moment(new Date()).subtract(7, 'day').format('YYYY-MM-DD');
                            return [moment(startOfDate).toDate(),moment(endOfDate).toDate()];

                        },
                    },
                    {
                        text: this.$t('reports.last_28'),
                        onClick() {
                            const endOfDate = moment(new Date()).format('YYYY-MM-DD');
                            const startOfDate = moment(new Date()).subtract(28, 'day').format('YYYY-MM-DD');
                            return [moment(startOfDate).toDate(),moment(endOfDate).toDate()];
                        },
                    },
                    {
                        text: this.$t('reports.this_month'),
                        onClick() {
                            const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
                            const endOfMonth   = moment(new Date, "YYYY-MM-DD");
                            return [moment(startOfMonth).toDate(),moment(endOfMonth).toDate()];
                        },
                    },
                    {
                        text: this.$t('reports.last_month'),
                        onClick() {
                            const startOfMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                            const endOfMonth   = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                            return [moment(startOfMonth).toDate(),moment(endOfMonth).toDate()];
                        },
                    },
                ],

            };
        },

        metaInfo: function() {

            return {
                title: this.$t('reports.reports'),
            }

        },

        computed: {

            // Map getters
            ...mapGetters({

                accounts: 'Accounts/getAccounts',
                getTicketCategories: 'Ticket/getTicketCategories',
                categories: 'Ticket/getCategories',
                tags: 'Ticket/getTags',
                Teams: 'Teams/getTeams',
                categoryReports: 'Report/getCategoryReports',
                primaryCategoryReports: 'Report/getPrimaryCategories',
                secondaryCategoryReports: 'Report/getSecondaryCategories',
                subCategoriesReports: 'Report/getSubCategories',
                secondaryActive: 'Report/getSecondaryActive',
                subActive: 'Report/getSubActive',
                selectedTypeForLang: 'Report/getSelectedTypeForLanguage'

            }),

            selected_type_for_lang: {
                get() {
                    return this.selectedTypeForLang
                },
                set(value) {
                    this.setSelectedTypeForLanguage(value)
                    this.fetchFunctions();
                }
            }

            // // Ticket categories
            // report_category: {

            //     // getter
            //     get: function () {
            //         // return this.getTicketCategories;
            //     },

            //     // setter
            //     set: function (value) {
            //         this.selected_category = value;
            //         // Update ticket category
            //         // this.updateTicketCategories(value);
            //     },

            // },

        },

        methods: {

            ...mapActions('Report', [
                'fetchQuestionTypes',
                'fetchMessageCounts',
                'fetchCustomerTypes',
                'fetchCustomerGenders',
                'fetchFrequencyMap',
                'fetchVipImportant',
                'fetchSchedule',
                'fetchTransferResult',
                'fetchTagReports',
                'fetchTagProcesses',
                'fetchCategoryProcesses',
                'fetchRiskHeatmap',
                'fetchCategoryReport',
                'fetchTransfersAndTrash',
                'fetchConfirmations',
                'fetchAccountActivities',
                'fetchUserTimeTable',
                'fetchTeamTimeTable',
                'fetchFirstResponseAverage',
                'fetchInformations',
                'fetchUserTouchPerformance',
                'fetchTeamTouchPerformance',
                'fetchLanguageIncoming',
                'fetchLanguageOutgoing',
                'updateDateRange',
                'updateCalenderInterval',
                'updateAccountList',
                'categoryParsing',
                'fetchMaps',
                'fetchLocationReports',
                'fetchPrimaryCategories',
                'updateSecondaryActive',
                'updateSubActive',
            ]),

            ...mapMutations('Report', [
                'setSelectedTypeForLanguage',
            ]),

            ...mapActions('Teams', [
                'fetchTeams',
            ]),

            ...mapActions('Accounts', [
                'fetchAccounts',
            ]),

            ...mapActions('Ticket', [
                'getCategories',
                'getTags'
            ]),

            // Disable future for date-picker
            disabledAfterNow(date) {
                const today = moment(moment(new Date()).format('YYYY-MM-DD')).toDate();
                return date > today;
            },

            // When the value change(v-model event)
            dateRender: function () {

                this.updateCalenderInterval(this.date_range);
                let date_range_value = {
                    "gte": this.date_range[0],
                    "lte": this.date_range[1],
                    "format": "date_optional_time",
                };
              console.log(date_range_value);
                this.updateDateRange(date_range_value);
                this.fetchFunctions();

            },

            // When the accounts change(v-model event)
            accountsRender: function () {

                let account_list = [];
                this.category.forEach(account => {
                    account_list.push(account.id);
                });

                this.updateAccountList(account_list);
                this.fetchFunctions();



            },
            // When the value change(v-model event)
            teamRender: function () {
                this.fetchFunctions();
            },

            // When the value change(v-model event)
            categoryRender: function () {
                // Fetch Category Report
                // this.categoryParsing(this.selected_category);
                this.fetchCategoryReport({ selected_category: this.selected_category, selected_tags: this.selected_category_tags});
            },

            tagRender() {
                setTimeout(() => {
                    this.fetchTagReports(this.selected_tag_categories)
                }, 0);
                
            },


            // Fetch Reports
            fetchFunctions: function () {

                // Accounts
                if(this.is_active === 0){
                    // Accounts First Response Info
                    this.fetchFirstResponseAverage(this.selected_team);
                    // Accounts General Information
                    this.fetchInformations();
                    // Mentions / DM / Key
                    this.fetchQuestionTypes();
                    // Incoming/Outgoing Messages
                    this.fetchMessageCounts();
                    // Post Frequency Map
                    this.fetchFrequencyMap();
                    // Account Activity
                    this.fetchAccountActivities();

                    this.fetchLanguageIncoming();
                    this.fetchLanguageOutgoing();
                }
                // Customers
                if(this.is_active === 1){
                    // Customer Genders
                    this.fetchCustomerGenders();
                    // Importance Of Customer Category
                    this.fetchVipImportant();
                    // Customer Types
                    this.fetchCustomerTypes();
                }
                // Processes
                if(this.is_active === 2){
                    // Transfers - Trash Reports
                    this.fetchTransfersAndTrash();
                    // Confirmation Result
                    this.fetchConfirmations();
                    // Schedule Result
                    this.fetchSchedule();
                    // Transfer Result
                    this.fetchTransferResult();
                }
                // Categories
                if(this.is_active === 3){
                    // Tags Reports
                    this.fetchTagReports(this.selected_tag_categories);
                    // Primary-Secondary-Sub Categories
                    this.fetchCategoryReport({ selected_category: this.selected_category, selected_tags: this.selected_category_tags});
                    // this.fetchPrimaryCategories();
                }
                // Status
                if(this.is_active === 4){
                    // Tag Process
                    this.fetchTagProcesses();
                    // Risk Heatmap
                    this.fetchRiskHeatmap();
                    // Status Process
                    this.fetchCategoryProcesses();
                }
                // Users
                if(this.is_active === 5){
                    // Users Time Table
                    this.fetchUserTimeTable(this.selected_team);
                    // User Touch Performance
                    this.fetchUserTouchPerformance(this.selected_team);
                }
                // Teams
                if(this.is_active === 6){
                    // Team Time Table
                    this.fetchTeamTimeTable(this.selected_team);
                    // Team Touch Performance
                    this.fetchTeamTouchPerformance();
                }
                // Map
                if(this.is_active === 7){
                    // Interaction Heatmap
                    this.fetchMaps();
                    this.fetchLocationReports();
                }
                },


            // When the value change(v-model event)
            activeTab: function (value) {
                // if (this.is_active != value) {
                //     // am4core.disposeAllCharts();
                //     // $registry.disposeAllRootElements();
                // }
                this.is_active = value;
                this.fetchFunctions();
                // console.log('Activetab changed')
                // console.log(this.is_active);

            },

            // Normalizer
            normalizer: function (node1) {
                return {
                    id: node1.id,
                    label: node1.name,
                    children: node1.children,
                }
            },
            // Normalizer
            normalizer2: function (node2) {
                return {
                    id: node2.id,
                    label: node2.name + " (" + node2.integration_id.replace(/\b\w/g, l => l.toUpperCase()) + ")",
                }
            },
        },

    };
</script>

<style scoped>

</style>