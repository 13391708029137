var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"pt-2"},[_c('div',{staticClass:"px-3"},[_c('h4',[_vm._v(_vm._s(_vm.$t('simple.create_campaign')))])])])],1),_vm._v(" "),_c('b-row',{staticClass:"px-2"},[_c('b-container',{attrs:{"fluid":""}},[_c('v-wait',{attrs:{"for":"whatsapp_campaign_setup"}},[_c('loading',{attrs:{"slot":"waiting"},slot:"waiting"}),_vm._v(" "),_c('b-row',[_c('b-col',[_c('ValidationObserver',{ref:"campaign",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('simple.campaign_details')))])]},proxy:true},{key:"footer",fn:function(){return [_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{staticClass:"d-flex"},[_c('b-button',{attrs:{"variant":"danger","to":"/settings/whatsapp-campaigns"}},[_vm._v(_vm._s(_vm.$t('default.cancel'))+" ")]),_vm._v(" "),_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"success","disabled":!_vm.idCampaignValid || invalid},on:{"click":_vm.createCampaign}},[_vm._v("\n                                                "+_vm._s(_vm.$t('simple.create_campaign'))+"  & "+_vm._s(_vm.$t('simple.edit'))+"\n                                            ")])],1)],1)]},proxy:true}],null,true)},[_vm._v(" "),_c('b-row',{staticClass:"align-items-center mb-3"},[_c('b-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"campaign_name"}},[_vm._v("\n                                            "+_vm._s(_vm.$t('simple.campaign_name'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(":")])]),_vm._v(" "),_c('b-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"rules":"required|alpha_dash|small_letter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"campaign_name","name":"campaign_name","state":valid},model:{value:(_vm.campaign_name),callback:function ($$v) {_vm.campaign_name=$$v},expression:"campaign_name"}}),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('macros.template_elementName_desc')))])]}}],null,true)})],1)],1),_vm._v(" "),_c('b-row',{staticClass:"align-items-center mb-3"},[_c('b-col',{attrs:{"sm":"2"}},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"campaign_account"}},[_vm._v("\n                                            "+_vm._s(_vm.$t('simple.account'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(":")])]),_vm._v(" "),_c('b-col',{attrs:{"sm":"8"}},[_c('treeselect',{attrs:{"options":_vm.whatsapp_accounts,"multiple":false,"value-format":"object","placeholder":_vm.$t('simple.account'),"normalizer":_vm.normalizer},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return _c('label',{},[_c('font-awesome-icon',{style:({ color: node.raw.color }),attrs:{"icon":['fab', node.raw.integration.icon]}}),_vm._v("\n                                                "+_vm._s(node.raw.name)+"\n                                            ")],1)}},{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_c('font-awesome-icon',{style:({ color: node.raw.color }),attrs:{"icon":['fab', node.raw.integration.icon]}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(node.raw.name))])],1)}}],null,true),model:{value:(_vm.campaign_account),callback:function ($$v) {_vm.campaign_account=$$v},expression:"campaign_account"}})],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }