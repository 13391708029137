<template>


    <form>
        <b-form-group label="Schedule Date:" horizontal>

            <datetime
                ref="date-picker"
                type="datetime"
                v-model="schedule_date"
                :min-datetime="schedule_start_date"
                format="yyyy-MM-dd HH:mm"
                input-class="form-control"
                class="theme-message"
            ></datetime>

        </b-form-group>

        <b-form-group horizontal>
            <b-button-toolbar key-nav  aria-label="Toolbar with button groups" class="mt-2">

                <b-button-group class="mx-1">

                    <!-- Save -->
                    <b-btn variant="light" v-b-tooltip.hover title="Save" @click="resetActiveTab"> <font-awesome-icon :icon="'check'" class="plus__icon" /> Save
                    </b-btn>

                </b-button-group>

                <b-button-group class="mx-1">

                    <!-- Reset -->
                    <b-btn variant="light" v-b-tooltip.hover title="Reset" @click="resetScheduleDate"> <font-awesome-icon :icon="'redo'" class="plus__icon" />
                        Reset
                    </b-btn>

                </b-button-group>


            </b-button-toolbar>
        </b-form-group>


    </form>

</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    import { DateTime } from "luxon";

    export default {

        mounted: function () {
            // Component mounted

        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {

                schedule_start_date: DateTime.local().plus({ minutes: 1 }).toISO()

            };

        },

        computed: {

            // Map getters
            ...mapGetters({
                // Schedule
                getScheduleDate: 'Reply/getScheduleDate',

                // User
                getUser: 'User/getUser',

                // Get Transfers Team & Users
                getTransferTeams: 'Ticket/getTransferTeams',
                getTransferUsers: 'Ticket/getTransferUsers',

                // Team
                getTeam: 'User/getUserTeams',

            }),

            // Schedule Date
            schedule_date: {

                // getter
                get: function () {
                    // Get Schedule Data
                    return this.getScheduleDate;
                },

                // setter
                set: function (value) {
                    // Check if we have value?
                    if(value){

                        // Update Active tab
                        this.updateScheduleDate(value);
                    }
                },

            },

            // Ticket teams
            user_teams: {

                // getter
                get: function () {
                    return this.getTeams;
                },


            },

        },

        methods: {

            // Some actions detka
            ...mapActions('Reply', [
                'resetActiveTab',
                'resetScheduleDate',
                'updateScheduleDate',

            ]),

        },

    }
</script>