<template>


        <b-badge size="sm" v-b-popover.hover.top="$t('confirmation.related_account')"
              class="badge badge-pill badge-secondary text-white ml-3">{{ account.name }} | {{ account.username }}</b-badge>


</template>
<script>

    export default {

        props: ['account'],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed
        },

        created: function() {
            // Component created
        },

        data: function() {

            return {};

        },

        computed: {},

        methods: {},

    }
</script>