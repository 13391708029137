<template>
    <b-dropdown right :text="$t('simple.actions')" size="sm">

        <template v-if="cell.item.is_active">
            <template>
                <template v-if="cell.item.integration === 'facebook'">
                    <b-dropdown-item class="bg-facebook-login"
                        @click="$emit('connect', cell.item.id, cell.item.integration_id, cell.item.username)"><font-awesome-icon
                            :icon="['fab', data.value.icon]" />{{ $t('simple.login_with_fb') }}</b-dropdown-item>
                </template>
                <template v-else>
                    <b-dropdown-item
                        v-if="cell.item.integration_id !== 'googleplay' && cell.item.integration_id !== 'twitter'"
                        @click="$emit('connect', cell.item.id, cell.item.integration_id, cell.item.username)">{{
                            $t('simple.connect')
                        }}
                    </b-dropdown-item>
                </template>

            </template>


            <b-dropdown-item @click="$emit('twitterConnect', cell.item.id)"
                v-if="cell.item.integration.id === 'twitter'">{{
                    $t('simple.connect')}}
            </b-dropdown-item>

            <b-dropdown-item @click="$emit('jsonModal', cell.item.id, cell.item.username)"
                v-if="cell.item.integration.id === 'googleplay'">{{ $t('page.upload_file') }}
            </b-dropdown-item>

            <b-dropdown-item @click="$emit('edit', cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>

            <b-dropdown-item v-if="cell.item.integration.id === 'whatsapp'"
                @click="$emit('whatsappProfileEdit', cell.item.id)">
                {{ $t('simple.edit_whatsapp_profile') }}

            </b-dropdown-item>

            <!-- TODO: Disable is not ready -->
            <!-- <b-dropdown-item @click="$emit('changeActive', cell.item.id)">{{ $t('default.disable') }}</b-dropdown-item> -->



            <b-dropdown-item @click="$emit('remove', cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>

        </template>
        <template v-else>
            <b-dropdown-item @click="$emit('changeActive', cell.item.id)">{{ $t('simple.activate') }}</b-dropdown-item>
        </template>



    </b-dropdown>

</template>

<script>

export default {

    props: ['cell'],

    data() {
        return {
        }
    },

    methods: {
    },


}

</script>