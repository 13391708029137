const state = {
  total: "",
  last: "",
  current: 1,
  perpage: "",

  messages: [],

  qa: false,

  message_url: "",
  detected_message_detail: "",
  detected_message_detail_error: "",


  languages: {
    "af": "Afrikaans",
    "sq": "Albanian",
    "am": "Amharic",
    "ar": "Arabic",
    "hy": "Armenian",
    "as": "Assamese",
    "ay": "Aymara",
    "az": "Azerbaijani",
    "bm": "Bambara",
    "eu": "Basque",
    "be": "Belarusian",
    "bn": "Bengali",
    "bho": "Bhojpuri",
    "bs": "Bosnian",
    "bg": "Bulgarian",
    "ca": "Catalan",
    "ceb": "Cebuano",
    "zh-cn": "Chinese(Simplified)",
    "zh": "Chinese(Simplified)",
    "zh-tw": "Chinese(Traditional)",
    "co": "Corsican",
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "dv": "Dhivehi",
    "doi": "Dogri",
    "nl": "Dutch",
    "en": "English",
    "eo": "Esperanto",
    "et": "Estonian",
    "ee": "Ewe",
    "fil": "Filipino(Tagalog)",
    "fi": "Finnish",
    "fr": "French",
    "fy": "Frisian",
    "gl": "Galician",
    "ka": "Georgian",
    "de": "German",
    "el": "Greek",
    "gn": "Guarani",
    "gu": "Gujarati",
    "ht": "Haitian Creole",
    "ha": "Hausa",
    "haw": "Hawaiian",
    "he": "Hebrew",
    "iw": "Hebrew",
    "hi": "Hindi",
    "hmn": "Hmong",
    "hu": "Hungarian",
    "is": "Icelandic",
    "ig": "Igbo",
    "ilo": "Ilocano",
    "id": "Indonesian",
    "ga": "Irish",
    "it": "Italian",
    "ja": "Japanese",
    "jv": "Javanese",
    "jw": "Javanese",
    "kn": "Kannada",
    "kk": "Kazakh",
    "km": "Khmer",
    "rw": "Kinyarwanda",
    "gom": "Konkani",
    "ko": "Korean",
    "kri": "Krio",
    "ku": "Kurdish",
    "ckb": "Kurdish (Sorani)",
    "ky": "Kyrgyz",
    "lo": "Lao",
    "la": "Latin",
    "lv": "Latvian",
    "ln": "Lingala",
    "lt": "Lithuanian",
    "lg": "Luganda",
    "lb": "Luxembourgish",
    "mk": "Macedonian",
    "mai": "Maithili",
    "mg": "Malagasy",
    "ms": "Malay",
    "ml": "Malayalam",
    "mt": "Maltese",
    "mi": "Maori",
    "mr": "Marathi",
    "mni-mtei": "Meiteilon (Manipuri)",
    "lus": "Mizo",
    "mn": "Mongolian",
    "my": "Myanmar (Burmese)",
    "ne": "Nepali",
    "no": "Norwegian",
    "ny": "Nyanja (Chichewa)",
    "or": "Odia (Oriya)",
    "om": "Oromo",
    "ps": "Pashto",
    "fa": "Persian",
    "pl": "Polish",
    "pt": "Portuguese (Portugal, Brazil)",
    "pa": "Punjabi",
    "qu": "Quechua",
    "ro": "Romanian",
    "ru": "Russian",
    "sm": "Samoan",
    "sa": "Sanskrit",
    "gl": "Scots Gaelic",
    "nso": "Sepedi",
    "sr": "Serbian",
    "st": "Sesotho",
    "sn": "Shona",
    "sd": "Sindhi",
    "si": "Sinhala (Sinhalese)",
    "sk": "Slovak",
    "sl": "Slovenian",
    "so": "Somali",
    "es": "Spanish",
    "su": "Sundanese",
    "sw": "Swahili",
    "sv": "Swedish",
    "tl": "Tagalog (Filipino)",
    "tg": "Tajik",
    "ta": "Tamil",
    "tt": "Tatar",
    "te": "Telugu",
    "th": "Thai",
    "ti": "Tigrinya",
    "ts": "Tsonga",
    "tr": "Turkish",
    "tk": "Turkmen",
    "ak": "Twi(Akan)",
    "uk": "Ukrainian",
    "ur": "Urdu",
    "ug": "Uyghur",
    "uz": "Uzbek",
    "vi": "Vietnamese",
    "cy": "Welsh",
    "xh": "Xhosa",
    "yi": "Yiddish",
    "yo": "Yoruba",
    "zu": "Zulu",
    "unknown": "Unknown"
  }
};

// getters
const getters = {
  // Get messages
  getMessages: (state, getters) => {
    // Return messages
    return state.messages;
  },
  
  getLanguages: (state, getters) => {
    // Return messages
    return state.languages;
  },

  getQA: (state, getters, rootGetters) => {
    // Check if we have some messages
    if (!state.qa && state.messages) {
      // Get some results detka
      let result = state.messages
        .filter(function (message) {
          if (!message.question && !message.answer) {
            return false;
          }

          return true;
        })
        .map(function (message) {
          if (message.question) {
            return message.question;
          }

          if (message.answer) {
            return message.answer;
          }
        });

      // Check if have questions
      let qa = result ? result : false;

      // Return Questions
      return qa;
    } else {
      // Return false
      return state.qa;
    }
  },

  getActiveQA: (state, getters, rootGetters) => {
    // Check if have question
    if (!state.qa) {
      // let customer = rootGetters['Customer/getCustomer'];

      // return customer.question;
      // Get questions amd answers
      let qas = getters.getQA;

      if (qas) {
        // Check if we have some results?
        let result = qas[qas.length - 1];

        // check if have result
        let qa = result ? result : false;

        // Return qa answer
        return qa;
      } else {
        // Return false
        return false;
      }
    } else {
      // Return false
      return state.qa;
    }
  },

  // Return last message
  getMessageLast: (state, getters) => {
    // Check if we have messages?
    if (state.messages) {
      if (state.message) {
        // Return last message from message
        return state.message;
      } else {
        // Return last message
        return state.messages[state.messages.length - 1];
      }
    } else {
      // Return false detka
      return false;
    }
  },

  // Return total
  getTotal: (state, getters) => {
    return state.total;
  },

  // Return current
  getCurrent: (state, getters) => {
    return state.current;
  },

  // Return last
  getLast: (state, getters) => {
    return state.last;
  },

  // Return per page
  getPerPage: (state, getters) => {
    return state.perpage;
  },

  // Get Message URL
  getMessageUrl: (state, getters) => {
    return state.message_url;
  },

  // Get Detected Message Detail
  getDetectedMessageDetail: (state, getters) => {
    return state.detected_message_detail;
  },

  // Get Detected Message Detail Error
  getDetectedMessageDetailError: (state, getters) => {
    return state.detected_message_detail_error;
  },
};

// mutations
const mutations = {
  // Set total
  setTotal(state, total) {
    state.total = total;
  },

  // Set current
  setCurrent(state, current) {
    state.current = current;
  },

  // Set last
  setLast(state, last) {
    state.last = last;
  },

  // Set per page
  setPerPage(state, perpage) {
    state.perpage = perpage;
  },

  // Set message
  setMessage(state, message) {
    state.message = message;
  },

  // Set messages
  setMessages(state, messages) {
    state.messages = messages;
  },

  // Push message
  pushMessages(state, message) {
    state.messages.push(message);
  },

  // Set qa
  setQA(state, qa) {
    state.qa = qa;
  },

  // Set Message Url
  setMessageUrl(state, message_url) {
    state.message_url = message_url;
  },

  // Set Detected Message Detail
  setDetectedMessageDetail(state, detected_message_detail) {
    state.detected_message_detail = detected_message_detail;
  },

  // Set Detected Message Detail Error
  setDetectedMessageDetailError(state, detected_message_detail_error) {
    state.detected_message_detail_error = detected_message_detail_error;
  },

  setActive(state, active) {
    if (active == "customer") {
      // Set customer to true
      state.is_customer = true;

      // Set ticket to false
      state.is_ticket = false;
    }

    if (active == "ticket") {
      // Set customer to false
      state.is_customer = false;

      // Set ticket to true
      state.is_ticket = true;
    }
  },
};

// actions
const actions = {
  // Refresh Messages
  async refreshMessages({ dispatch, state }, customer_id) {
    // Refresh messages
    dispatch("fetchMessages", {
      customer_id: customer_id,
      page: state.current,
    });
  },

  // Get inbox from last page
  async fetchFromLastPage({ dispatch, commit, state }, customer_id) {
    // Start waiting
    dispatch("wait/start", "messages", { root: true });

    let inbox_messages_last_api = "/api/v1/inbox/messages/last";

    // Clear pagination data
    dispatch("clearPagination");

    // Reset Transfer
    dispatch("Ticket/clearTransfer", null, { root: true });

    // Assign customer
    await dispatch("Customer/setCustomer", customer_id, { root: true });

    // Get Accounts
    await axios
      .get(inbox_messages_last_api + "?customer_id=" + customer_id)
      .then((response) => {
        // Assign customer
        dispatch("fetchMessages", {
          customer_id: customer_id,
          page: response.data,
        });

        // Stop waiting
        // dispatch('wait/end', 'messages', { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "messages", { root: true });
      });
  },

  // Get inbox messages
  async fetchMessages({ dispatch, commit, state, rootGetters }, data) {
    // Start waiting
    dispatch("wait/start", "messages", { root: true });

    let inbox_messages_api = "/api/v1/inbox/messages";

    // Clear pagination data
    dispatch("clearPagination");

    // Assign customer
    // dispatch('Customer/setCustomer', data.customer_id, {root:true} );

    // update Seen
    dispatch("Inbox/updateSeen", data.customer_id, { root: true });

    // Get Accounts
    await axios
      .get(
        inbox_messages_api +
          "?customer_id=" +
          data.customer_id +
          "&page=" +
          data.page ?? state.current
      )
      .then((response) => {
        // Check DM Status
        // if(response.data.data[0].customer.twitter) {
        //   dispatch('Customer/checkCustomerDmStatus', data.customer_id, {root: true});
        // }

        // Assign messages
        commit("setMessages", response.data.data);

        let customer = rootGetters["Customer/getCustomer"];
        // Set question to false
        commit("setQA", [customer.question]);

        // Set current
        commit("setCurrent", response.data.current_page);

        // Set last
        commit("setLast", response.data.last_page);

        // Set perpage
        commit("setPerPage", response.data.per_page);

        // Set total
        commit("setTotal", response.data.total);

        // Stop waiting
        dispatch("wait/end", "messages", { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        dispatch("wait/end", "messages", { root: true });
      });
  },

  // Get inbox from last page
  async fetchLastConfirm({ dispatch, commit, state }, customer_id) {
    // Start waiting
    //dispatch('wait/start', 'messages', { root: true });

    let inbox_messages_last_api = "/api/v1/inbox/messages/last";

    // Clear pagination data
    dispatch("clearPagination");

    // Get Accounts
    await axios
      .get(inbox_messages_last_api + "?customer_id=" + customer_id)
      .then((response) => {
        // Assign customer
        dispatch("updateChangesOnMessages", {
          customer_id: customer_id,
          page: response.data,
        });

        // Stop waiting
        //dispatch('wait/end', 'messages', { root: true });
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);

        // Stop waiting
        //dispatch('wait/end', 'messages', { root: true });
      });
  },

  // Get inbox messages
  async updateChangesOnMessages({ dispatch, commit, state }, data) {
    let inbox_messages_api = "/api/v1/inbox/messages";

    // Clear pagination data
    dispatch("clearPagination");

    // Get Accounts
    await axios
      .get(
        inbox_messages_api +
          "?customer_id=" +
          data.customer_id +
          "&page=" +
          data.page ?? state.total
      )
      .then((response) => {
        // Refresh customer (Why? After sending reply to refresh customer)
        // dispatch('Customer/setCustomer', data.customer_id, {root:true} );

        // Assign messages
        commit("setMessages", response.data.data);

        // Set question to false
        commit("setQA", false);

        // Set current
        commit("setCurrent", response.data.current_page);

        // Set last
        commit("setLast", response.data.last_page);

        // Set perpage
        commit("setPerPage", response.data.per_page);

        // Set total
        commit("setTotal", response.data.total);
      })
      .catch((error) => {
        // Show error
        Vue.toasted.error(Vue.i18n.translate("simple.error_info"));

        // Log to console
        console.log(error);
      });
  },

  // Detect Message By URL
  async detectMessageByUrl(
    { dispatch, commit, state, rootGetters },
    confirmation
  ) {
    // Start waiting
    dispatch("wait/start", "detect_message_by_url", { root: true });

    let detect_messages_api = "/api/v1/system/get_message/";

    let integration = rootGetters["Accounts/getIntegrationID"];

    let account_id = rootGetters["Accounts/getAccountID"];

    // Get Accounts
    await axios
      .get(
        detect_messages_api +
          account_id +
          "?integration=" +
          integration +
          "&url=" +
          state.message_url +
          "&confirm=" +
          confirmation
      )
      .then((response) => {
        if (confirmation === true) {
          // Show success
          Vue.toasted.success(Vue.i18n.translate("simple.success_info"));
        } else {
          // Assign messages
          commit("setDetectedMessageDetail", response.data);
        }
        // Stop waiting
        dispatch("wait/end", "detect_message_by_url", { root: true });
      })
      .catch((error) => {
        // Log to console
        console.log(error);

        // Assign messages
        commit(
          "setDetectedMessageDetailError",
          error.response.data === "this_message_exist"
            ? Vue.i18n.translate("simple.this_message_exist")
            : "Error"
        );

        // Stop waiting
        dispatch("wait/end", "detect_message_by_url", { root: true });
      });
  },

  updateOnConfirm({ dispatch, commit, state }, data) {
    // Update message list
    dispatch("updateChangesOnMessages", data);
  },

  // Get inbox messages
  updateActive({ dispatch, commit, state }, active) {
    // Assign customer
    commit("setActive", active);
  },

  // Update qa
  updateQA({ commit }, qa) {
    // Assign message
    commit("setQA", qa);
  },

  clearPagination({ dispatch, commit, state }) {
    // Set total to false
    commit("setTotal", false);

    // Set current to false
    commit("setCurrent", false);

    // Set current to false
    commit("setLast", false);

    // Set per page to false
    commit("setPerPage", false);
  },

  clearMessages({ commit }) {
    // Clear Messages
    commit("setMessages", []);
  },

  updatePage({ dispatch, commit, state }, data) {
    // Update message list
    dispatch("fetchMessages", data);
  },

  // Get inbox messages
  updateMessages({ commit, state }, message) {
    if (state.last == state.current) {
      // Assign customer
      commit("pushMessages", message);
    } else {
      // Inform user
      Vue.toasted.success("Hey, you have new message over here!");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
