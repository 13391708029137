<template>

    <v-wait for="imagesData">

        <loading slot="waiting"/>

        <div class="row">
            <div class="col-sm-6 mb-2" v-if="images.length" v-for="image in images" v-bind:key="image.id">
                <div v-if="image.preview" class="card">

                    <!-- Image preview -->
                    <a @click="image_select(image)">
                        <img class="card-img-top" :src="image.preview.public">
                    </a>

                </div>

            </div>

            <template v-if="!images.length">
                {{ $t('simple.records_not_found') }}
            </template>

            <!-- Pagination-->
            <b-pagination v-if="total" class="m-1" :hide-goto-end-buttons="true" size="sm" :total-rows="total" :value="current" @input="getImages" :per-page="perpage"></b-pagination>

        </div>

    </v-wait>

</template>

<script>

    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {

        props: [],

        mounted: function () {
            // Component mounted
        },

        destroyed() {
            // Component destroyed;
        },

        created: function() {
            // Component created

            // Get Gallery Images
            this.fetchImages();
        },
        

        data: function() {

            return  {

                 // images: [],

                // gallery_api: '/api/v1/system/gallery',
                //
                // total: 0,
                //
                // current: 1,
                //
                // per_page: false,

            }

        },
        
        methods: {

            // Images
            ...mapActions('Pixel', [
                'fetchImages',
            ]),

            // Image select
            image_select: function(image){

                // Send to parent with image url public
                this.$emit('image-select', image.url.public);
            },

            // // Get images
            // getImages: function(page){
            //
            //     // Check if we have page set or set it to default?
            //     let current_page = page ? page : 1;
            //
            //     // Start waiting
            //     this.$wait.start('images');
            //
            //     // Get gallery images
            //     axios.get(this.gallery_api + '?page=' + current_page)
            //
            //       .then((response) => {
            //
            //         // response data
            //         this.images = response.data.data;
            //
            //         // Set current
            //         this.current = response.data.current_page;
            //
            //         // Per page
            //         this.perpage = response.data.per_page;
            //
            //         // Total
            //         this.total = response.data.total;
            //
            //         // End waiting
            //         this.$wait.end('images');
            //
            //       })
            //       .catch((error) => {
            //
            //         // End waiting
            //         this.$wait.end('images');
            //
            //         // Show error
            //         Vue.toasted.error(this.$t('simple.error_info'));
            //
            //         // Log to console
            //         console.log(error);
            //
            //       });
            //
            // },

        },

        computed: {

            // Map getters
            ...mapGetters({

                // Images
                getImages: 'Pixel/getImages',

                total: 'Pixel/getTotal',
                current: 'Pixel/getCurrent',
                perpage: 'Pixel/getPerPage',

            }),

            // Images
            ...mapMutations('Pixel', [
                'setImages',
            ]),

            // Is Reply
            images: {

                // getter
                get: function () {
                    // Get Image Data
                    return this.getImages;
                },
                set: function(value){
                    this.setImages(value);
                }

            },
        },
        
    }
</script>