<template>
    <div class="row">

        <div class="col pt-2">


            <!--  Page  -->
            <div class="bg-white rounded py-2 shadow-widget border">

                <!-- Page header -->
                <div class="px-3">

                    <div class="d-flex">

                        <h4>{{ $t('page.announcements') }}</h4>

                        <div class="ml-auto align-self-center">

                            <b-button :variant="'primary'" @click="add" class="ml-auto">
                                <font-awesome-icon :icon="'plus'" />
                                {{ $t('default.add')}}
                            </b-button>

                        </div>

                    </div>

                    <span class="text-muted font-weight-light">{{ $t('page.announcements_desc') }}</span>

                </div>

                <hr>

                <!-- Page content -->
                <div class="px-5">


                    <v-wait for="announcements">


                        <loading slot="waiting"/>


                        <b-table responsive bordered hover :items="announcements" :fields="announcement_fields" class="vh-25">

                            <!-- Status -->
                            <template slot="status" slot-scope="data">


                                <template v-if="data.value">

                                    <b-button :size="'sm'" :variant="'success'" @click="announcement_edit(data.item.id, false)">{{ $t('default.active') }}</b-button>


                                </template>
                                <template v-else>

                                    <b-button :size="'sm'" :variant="'danger'" @click="announcement_edit(data.item.id, true)">{{ $t('default.inactive') }}</b-button>

                                </template>

                            </template>

                            <!-- Actions -->
                            <template slot="cell(actions)" slot-scope="cell">

                              <b-dropdown right :text="$t('simple.actions')" size="sm">

                                <b-dropdown-item @click="edit(cell.item.id)">{{ $t('simple.edit') }}</b-dropdown-item>

                                <b-dropdown-item @click="remove(cell.item.id)">{{ $t('simple.delete') }}</b-dropdown-item>
                                  
                              </b-dropdown>

                            </template>

                            <!-- Created at -->
                            <template slot="cell(created_at)" slot-scope="cell">

                                 {{ $moment($moment.utc(cell.item.created_at)).local().format('YYYY-MM-DD HH:mm:ss') }}
                                 
                            </template>

                        </b-table>


                        <!-- Pagination -->
                        <b-pagination align="center"  :total-rows="pagination.total" v-model="pagination.current_page" :per-page="pagination.per_page" @change="getAnnouncements"></b-pagination>


                    </v-wait>

                </div>
                    

            </div>


        </div>

    </div>

</template>

<script>

    import AnnouncementAdd from './Modal/AnnouncementAdd.vue'
    import AnnouncementEdit from './Modal/AnnouncementEdit.vue'
    import AnnouncementDelete from './Modal/AnnouncementDelete.vue'


    import { create } from 'vue-modal-dialogs'

    export default {

        mounted: function () {
            // Component mounted
            console.log('Announcement component mounted.')
        },

        destroyed() {
            // Component destroyed
            console.log('Announcement component destroyed.');
        },

        created: function() {
            // Component created
            this.getAnnouncements();
        },
        

        data: function() {
            return  {
                announcements_api: '/api/v1/settings/announcements/',
                announcements: [],
                announcement_fields: [
                    { key: 'name', label: this.$t('simple.name') },
                    { key: 'description', label: this.$t('simple.desc') },
                    { key: 'status', label: this.$t('simple.status') },
                    { key: 'created_at', label: this.$t('simple.created') },
                    { key: 'actions', label: this.$t('simple.actions') },
                ],
                pagination: {'current_page' : 0, 'total': 0, 'per_page': 0},
            }
        },
        
        metaInfo: function() {

            return {
               title: this.$t('page.announcements'),
            }
            
        },

        methods: {

            getAnnouncements: async function(page = 1){

                // Check if page is equal to current
                this.pagination.current_page = page;

                // Start waiting
                this.$wait.start('announcements');

                // Get announcements
                await axios.get(this.announcements_api + '?page=' + this.pagination.current_page)
                  .then((response) => {
                    // response data
                    this.announcements = response.data.data;

                    //console.log(response);

                    // Pagination
                    this.pagination.total = response.data.total;
                    this.pagination.per_page = response.data.per_page;

                    // End waiting
                    this.$wait.end('announcements');

                  })
                  .catch((error) => {
                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));
                    
                    // Log to console
                    console.log(error);

                    // End waiting
                    this.$wait.end('announcements');
                });

            },

            announcement_edit: async function(id, status){

                // Post request
                await axios.put(this.announcements_api  + id, {
                    status: status,
                    status_update: true,
                  })
                  .then((response) => {

                    // Show success
                    Vue.toasted.success(this.$t('simple.success_info'));

                    // Refresh Announcement list
                    this.getAnnouncements();

                  })
                  .catch((error) => {

                    // Show error
                    Vue.toasted.error(this.$t('simple.error_info'));

                  });

            },

            add: async function() {
                // Show Announcement add modal
                const announcement_add = create(AnnouncementAdd);

                if (await announcement_add() ){
                    // Refresh Announcement list
                    this.getAnnouncements();
                }

            },

            edit: async function(id) {
                // Show Announcement edit modal
                const announcement_edit = create(AnnouncementEdit, 'id');

                if (await announcement_edit(id) ){
                    // Refresh Announcement list
                    this.getAnnouncements();
                }

            },

            remove: async function(id) {
                
                // Show announcement remove modal
                const announcement_remove = create(AnnouncementDelete, 'id');

                if (await announcement_remove(id) ){
                    // Refresh announcement list
                    this.getAnnouncements();
                }

            },

        },
    }
</script>