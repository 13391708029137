const state = {

  fields: { label: 'name', value: 'id', children: 'children' },

  macros: false,

  macros_options: false,

  // Macros API
  macros_api: '/api/v1/system/macros',

  macros_setting_api: '/api/v1/settings/macros',

  macros_categories_api: '/api/v1/settings/macros/categories/',

  // Macros items
  macro_items: [],
  // Macros categories
  macros_categories: [],

};

// getters
const getters = {

  // Get field names
  getFields: (state) => {
    // Return fields
    return state.fields;
  },

  // Get category
  getMacrosOptions: (state) => {
    // Return categories
    return state.macros_options;
  },

  // Get Macros Categories
  getMacrosCategories: (state) => {
    // Return categories
    return state.macros_categories;
  },

  // Get Macros Items
  getMacrosItems: (state) => {
    // Return categories
    return state.macro_items;
  },

};

// mutations
const mutations = {

  // Set macros options
  setMacrosOptions (state, macros_options) {
    state.macros_options = macros_options;
  },

  // Set macros options
  setMacrosCategories (state, macros_categories) {
    state.macros_categories = macros_categories;
  },

  // Set macros options
  setMacrosItems (state, macros_items) {
    state.macro_items = macros_items;
  },

};

// actions
const actions = {

  // Update Macro
  async updateMacrosData({ dispatch, state }){

    // Check if don't have macros
    if(!state.macros_options){

      dispatch('fetchMacros');

    }

  },

  async fetchMacrosCategories({state, dispatch, commit}){


    // Start waiting
    dispatch('wait/start', 'macros_categories', { root: true });

    // Get integrations
    await axios.get(state.macros_categories_api)

        .then((response) => {

          // Response data
          // Set categories
          commit('setMacrosCategories', response.data);

          // Stop waiting
          dispatch('wait/end', 'macros_categories', { root: true });

        })
        .catch((error) => {

          // Show error
          Vue.toasted.error(this.$t('simple.error_info'));

          // Log to console
          console.log(error);

          // Stop waiting
          dispatch('wait/end', 'macros_categories', { root: true });

        });
  },


  async fetchMacros({ dispatch, commit}){

    // Get categories
    await axios.get(state.macros_api)
        .then((response) => {

          // Set categories
          commit('setMacrosOptions', response.data);

          // Log to console
          console.log('Macros Data: ', response.data);

        })

        .catch((error) => {
          // Show error
          Vue.toasted.error(Vue.i18n.translate('simple.error_info'));

          // Log to console
          console.log(error);

        });
  },

  async fetchMacrosItems({state, dispatch, commit}) {

    // Start waiting
    dispatch('wait/start', 'macros', { root: true });

    // Send request
    await axios.get(state.macros_setting_api)

        .then((response) => {


            response.data.forEach(buckets => {

                // console.log(buckets);
                // console.log(state.macros_categories);

                let macros = state.macros_categories.find(messages => messages.id == buckets['macros_category_id']);


                // parse and fill user
                buckets['description'] = macros.description;
                buckets['macros_category'] = macros.name;

            });

          // Set items
          commit('setMacrosItems', response.data);

          console.log('macro items', response.data);

          // Stop waiting
          dispatch('wait/end', 'macros', { root: true });

        })

        .catch((error) => {
          // Show error
          Vue.toasted.error(this.$t('simple.error_info'));

          // Log to console
          //console.log(error);


          // Stop waiting
          dispatch('wait/end', 'macros', { root: true });

        });

  },



};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};